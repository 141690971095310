import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { t } from '../../../../../../../service/localization/i18n';
import { removeAllSpecialCharacters } from '../../../../../../regex/regex';

const FeeCodeLayout = ({ rowData }) => {
  if (rowData?.InvoiceType === 'Private') {
    const privateService = rowData?.LineCount === 1 ? (rowData?.LineDetails ? rowData?.LineDetails[0]?.Name : '') : t('Multiple');
    return <div>{privateService}</div>;
  }

  return (
    <>
      <Tooltip target={`.feeCodeTooltip_${rowData.FeeCode?.replaceAll(removeAllSpecialCharacters, '')}`} position="top" showDelay={1000}>
        {rowData.FeeCodeText}
      </Tooltip>
      <span className={`feeCodeTooltip_${rowData.FeeCode?.replaceAll(removeAllSpecialCharacters, '')}`}>{rowData.FeeCode}</span>
    </>
  );
};

export default FeeCodeLayout;
