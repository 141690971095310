import React from 'react';
import { Dialog } from 'primereact/dialog';
import SimilarClaimsList from './SimilarClaimsList';
import { t } from '../../../service/localization/i18n';

// interface ISimilarClaimsListDialog {
//   visible: boolean
//   onHide: () => void
//   patientsList: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }[]
//   initPatient?: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }
// }

const SimilarClaimsListDialog = ({ visible, onHide, patientsList, initPatient, claim }) => {
  return (
    <Dialog
      header={`${t('similar_claims')}`}
      visible={visible}
      breakpoints={{ '968px': '95vw' }}
      style={{ minWidth: '360px', minHeight: '320px' }}
      onHide={onHide}
    >
      <SimilarClaimsList patientsList={patientsList} initPatient={initPatient} claim={claim} />
    </Dialog>
  );
};

export default SimilarClaimsListDialog;
