import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { getCodesByType } from '../../../../actions/claims.action.creators';
import { consultationAnaesthesia, consultationPainManagement } from '../../helpers/codesCheckList';
import { stringFormat } from '../../../../../utils/stringFormat';
import { getCategoryType } from '../../../../helpers/getCategoryType';
import { addMissingCodesToRecentList } from '../../helpers/updateRecentCodesList';
import { t } from '../../../../../../service/localization/i18n';

const WSBCConsultationDialog = () => {
  const [isGettingCode, setIsGettingCode] = useState(false);
  const { watch, onSubmit, localState, setLocalState, setValue } = useFormContext();
  const speciality = watch(inputs.speciality.name);
  const invoiceType = watch(inputs.payor.name);
  const feeCodes = watch(inputs.feeCodes.codeType);
  const feeCodesOnly = feeCodes?.map((i) => i?.value);
  const isConsultationAnaesthesia = consultationAnaesthesia.some((code) => feeCodesOnly?.includes(code));
  const isConsultationPainManagement = consultationPainManagement.some((code) => feeCodesOnly?.includes(code));
  const codeForUpdate = isConsultationAnaesthesia ? '19934' : isConsultationPainManagement ? '19935' : '';
  const codeForMessage = isConsultationAnaesthesia ? '01015' : isConsultationPainManagement ? '01115 (01013)' : '';

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      wsbcConsultationDialog: false
    }));
  };

  const proseedAsIs = () => {
    onHide();
    const updatedFormState = {
      ...watch(),
      ignoreValidation: true
    };

    onSubmit(updatedFormState);
  };

  const proseedSubmission = () => {
    setIsGettingCode(true);
    getCodesByType({ codeType: inputs.feeCodes.codeType, query: codeForUpdate, speciality }).then((results) => {
      setIsGettingCode(false);
      const newFeeCode = results?.find((i) => i.value === codeForUpdate);

      if (!newFeeCode) return onHide();

      // Add code to quick pick list if it's missing
      const feeType = getCategoryType(inputs.feeCodes.codeType, invoiceType);
      const selectedCodes = { [feeType]: [newFeeCode] };
      addMissingCodesToRecentList({ selectedCodes });

      const updatedFeeCodes = feeCodes?.map((i) => {
        const shodCodeBeChanged =
          consultationAnaesthesia.some((code) => code === i.value) || consultationPainManagement.some((code) => code === i.value);
        return shodCodeBeChanged ? newFeeCode : i;
      });

      const updatedFeeCodesOnly = updatedFeeCodes?.map((i) => i.value);
      const updatedFeeDescriptions = updatedFeeCodes?.map((i) => i.text);

      const updatedFormState = {
        ...watch(),
        [inputs.feeCodes.name]: updatedFeeCodesOnly,
        [inputs.feeCodes.codeType]: updatedFeeCodes,
        [inputs.feeCodes.codeDescription]: updatedFeeDescriptions,
        ignoreValidation: true
      };

      onHide();
      onSubmit(updatedFormState);

      setValue(inputs.feeCodes.name, updatedFeeCodesOnly);
      setValue(inputs.feeCodes.codeDescription, updatedFeeDescriptions);
      setValue(inputs.feeCodes.codeType, updatedFeeCodes);
    });
  };

  const actionButtons = (
    <>
      <Button id={elementIDs.yes} label={t('Yes')} loading={isGettingCode} onClick={proseedSubmission} />
      <Button id={elementIDs.no} className="p-button-outlined" label={t('No')} disabled={isGettingCode} onClick={proseedAsIs} />
    </>
  );

  return (
    <Dialog
      id={elementIDs.wsbcConsultationDialog}
      header={t('Warning')}
      visible={localState.wsbcConsultationDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg">{stringFormat(t('Do_you_want_to_replace_consultation_code'), codeForMessage, codeForUpdate)}</div>
    </Dialog>
  );
};

export default WSBCConsultationDialog;
