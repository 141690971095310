import { store } from '../../../../../store';
import { inputs } from './inputs';
import { getInvoicesCatalogMostRecentCodes, setClaimsData } from '../../../actions/claims.action.creators';
import { defaultInputValues } from './updateInitialState';

export const onRemovePatient = (setValue) => {
  const resetInputs = {
    ...defaultInputValues(),
    [inputs.patient.name]: [],
    [inputs.claimNumber.name]: ''
  };

  Object.entries(resetInputs).forEach(([name, value]) => setValue(name, value));
  store.dispatch(setClaimsData({}));
  store.dispatch(getInvoicesCatalogMostRecentCodes({}));
};
