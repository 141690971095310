import React from 'react';
import { isTimeValid, timeDiff } from '../../../modules/utils/formatTime';

const TimeWithDuration = ({ startTime, endTime }) => {
  const isStartValid = isTimeValid(startTime);
  const isEndValid = isTimeValid(endTime);
  let time = `${startTime} — ${endTime}`;

  if (isStartValid && isEndValid) {
    const durationInMinutes = timeDiff(startTime, endTime);
    time += ` (${durationInMinutes} min)`;
  }

  return <>{time}</>;
};

export default TimeWithDuration;
