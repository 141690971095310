import React, { useContext } from 'react';

import Context from '../../../../../Context';
import { getRadioButtonValue } from '../helpers/getRadioButtonValue';
import { inputs } from '../../../../../modules/claims/components/WSBCReport/helpers/inputs';
import { estimatedTimeDropdownOptions } from '../../../../../modules/claims/components/WSBCReport/helpers/dropdownOptions';
import { getRehabilitation } from '../../../../../modules/claims/components/WSBCReport/helpers/formTypeActions';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const ReturnToWork = () => {
  const { data } = useContext(Context);
  const estimatedReturn = estimatedTimeDropdownOptions.find((i) => i.value === data[inputs.estimatedReturn.name])?.label;

  const restrictionsLayout = () => {
    const restriction = data[inputs.restrictions.name];

    if (restriction?.length > 35) {
      return (
        <td colSpan="2">
          <span className="font-semibold">{t('If_no_what_are_the_current_physical_and_or_psychological_restrictions')}</span>
          <div className="flex wordBreakText coloredText">{restriction}</div>
        </td>
      );
    }

    return (
      <>
        <td className="font-semibold captionPrint" style={{ width: '67%' }}>
          {t('If_no_what_are_the_current_physical_and_or_psychological_restrictions')}
        </td>
        <td>
          <div className="flex justify-content-end wordBreakText text-justify coloredText">{restriction}</div>
        </td>
      </>
    );
  };

  return (
    <div className="pt-3 text-sm">
      <div className="font-semibold demographicsInfoTableHeaderPrint">{t('Return_To_Work')}</div>
      <table className="demographicsInfoTablePrint">
        <tbody>
          <tr>
            <td className="font-semibold captionPrint" style={{ width: '67%' }}>
              {t('Is_the_worker_now_medically_capable_of_working_full_time')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRadioButtonValue(data[inputs.capable.name])}</div>
            </td>
          </tr>

          {/* <tr>
          <td className='font-semibold captionPrint' style={{ width: '67%' }}>
            {t('If_no_what_are_the_current_physical_and_or_psychological_restrictions')}
          </td>
          <td>
            <div className='flex justify-content-end text-right wordBreakText text-justify coloredText'>
              {data[inputs.restrictions.name]}
            </div>
          </td>
        </tr> */}

          {/* <tr>
          <td colSpan='2'>
            <span className='font-semibold '>
              {t('If_no_what_are_the_current_physical_and_or_psychological_restrictions')}
            </span>
            <div className='flex justify-content-end wordBreakText text-justify coloredText'>
              {data[inputs.restrictions.name]}
            </div>
          </td>
        </tr> */}

          <tr>{restrictionsLayout()}</tr>

          <tr>
            <td className="font-semibold captionPrint" style={{ width: '67%' }}>
              {t('Estimated_time_before_the_worker_will_be_able_to_return')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{estimatedReturn}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold captionPrint" style={{ width: '67%' }}>
              {t('Is_the_worker_now_ready_for_a_rehabilitation_program')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRehabilitation(data[inputs.rehabilitation.name])?.label}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold captionPrint" style={{ width: '67%' }}>
              {t('Do_you_wish_to_consult_with.2')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRadioButtonValue(data[inputs.consult.name])}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold captionPrint" style={{ width: '67%' }}>
              {`${t('Estimate_date_of_Maximal_Medical_Recovery')} (yyyy/mm/dd)`}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">
                {data[inputs.recoveryDate.name] ? moment(data[inputs.recoveryDate.name]).format('YYYY/MM/DD') : ''}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReturnToWork;
