import moment from 'moment';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from './inputs';

// Format data for save/submit API request

export const dataForSubmit = (data) => {
  const patient = data[inputs.patient.name][0];
  const birthDay = patient[inputs.birthDay.name];

  const dataForRequest = {
    ...data,
    [inputs.patientGuid.name]: patient?.PatientGuid,
    [inputs.firstName.name]: patient?.FirstName,
    [inputs.lastName.name]: patient?.LastName,
    [inputs.midName.name]: patient?.MidName,
    [inputs.gender.name]: patient?.Sex,
    [inputs.birthDay.name]: birthDay ? moment(birthDay).format('YYYY-MM-DD') : null
  };

  delete dataForRequest[inputs.patient.name];
  delete dataForRequest[commonInputs.fantom.name];

  return dataForRequest;
};
