import moment from 'moment';
import { claimsDefaultFilters, claimsDefaultSortBy, initStateForPagination } from '../../../../modules/config/defaultValuesConfig';

export const defaultRequestParams = {
  page: initStateForPagination.page,
  pageSize: initStateForPagination.pageSize,
  sortby: claimsDefaultSortBy.sortOrder,
  query: {
    ...claimsDefaultFilters
    // Period: '',
    // PeriodFrom: '',
    // PeriodTo: '',
    // FeeCode: '01164'
  }
};
