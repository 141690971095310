import React from 'react';

const TimeLayout = ({ rowData }) => {
  const startTime = rowData?.StartTime;
  const endTime = rowData?.FinishTime;

  return <div className="w-max">{`${startTime} — ${endTime}`}</div>;
};

export default TimeLayout;
