import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';

import { Skeleton } from 'primereact/skeleton';
import { Chip } from 'primereact/chip';
import { Patient } from '../../../../../../../components/Inputs';

import { getInvoicesCatalogMostRecentCodes, setPatientPickerQueryValue, updateWSBCRecord } from '../../../../../actions/claims.action.creators';
import { setShowNewPatientSuccessPage } from '../../../../../../patients/actions/patients.action.creators';
import { inputs, requiredInputs } from '../../../helpers/inputs';
import { routes } from '../../../../../../../routes/routes';
import { onRemovePatient } from '../../../helpers/onRemovePatient';
import { getPatientClaimsData } from '../../../helpers/getPatientClaimsData';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const PatientPicker = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gettingWSBCReport } = useSelector((state) => state.claims);
  const { setValue, errors, control, watch, localState, isNew, clearErrors, setLocalState } = useFormContext();
  const name = inputs.patient.name;
  const selectedPatient = watch(name)[0];

  const onChange = (patient, field) => {
    // if (patient?.length > 1) {
    //   return dispatch(setToastMessage({ type: 'warn', message: `${t("Maximum_number_of_entries_is_X")} 1` }));
    // };

    // Remove patient
    if (!patient?.length || patient === null) return onRemovePatient(setValue);

    field.onChange(patient);

    if (patient[0]?.PatientGuid && patient[0]?.PatientGuid !== selectedPatient?.PatientGuid) {
      // Get most recent codes list
      dispatch(getInvoicesCatalogMostRecentCodes({ patientGuid: patient[0].PatientGuid }));

      // Get patient claims
      if (isNew) getPatientClaimsData({ patientGuid: patient[0].PatientGuid, errors, clearErrors, setValue });
    }
  };

  const onAddClick = (e) => {
    dispatch(setPatientPickerQueryValue(e.query)); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen

    setLocalState((prevState) => ({ ...prevState, resetWSBCR: false })); // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen

    dispatch(updateWSBCRecord({ formState: watch(), localState: { ...localState, resetWSBCR: false } }));
    dispatch(setShowNewPatientSuccessPage({ showSuccessPage: false, from: 'WSBCReport' }));
    history.push(`${routes.createPatient.path}/new`);
  };

  const editLayout = (field) => {
    if (gettingWSBCReport) return <Skeleton width="100%" height="1.4rem" />;
    const patientName = field.value?.length ? patientFullNameWithAge(field.value?.[0]) : '';
    if (!patientName) return null;

    return (
      <div id={`${elementIDs.patientChip}_${field.value[0].PatientGuid?.toUpperCase()?.replaceAll('-', '')}`}>
        <Chip className="mr-2" label={patientName} removable={isNew} icon="pi pi-user" />
      </div>
    );
  };

  const patientInput = (field) => {
    if (!isNew) {
      return (
        <div id={field.name} className="pb-4 font-bold text-lg">
          {editLayout(field)}
        </div>
      );
    }

    return (
      <Patient
        id={field.name}
        name={field.name}
        ref={field.ref}
        required={!!requiredInputs[name]}
        multiple
        maxEntry={1}
        autoFocus
        disabled={!isNew}
        showAddPatientButton={isNew}
        isInvalid={!!errors[name]?.message}
        errorMessage={errors[name]?.message}
        value={field.value}
        onChange={(e) => onChange(e.value, field)}
        onAddClick={onAddClick} // Comment to show dialog for adding new patient
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => patientInput(field)} />;
};

export default PatientPicker;
