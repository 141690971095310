import constants from '../reducers/constants';

export const setRoles = (payload) => ({
  type: constants.SET_ROLES,
  payload
});

export const clearRoles = () => ({
  type: constants.CLEAR_ROLES,
  payload: []
});

export const setRoleDetails = (payload) => ({
  type: constants.SET_ROLE_DETAILS,
  payload
});

export const clearRoleDetails = () => ({
  type: constants.CLEAR_ROLE_DETAILS,
  payload: {}
});
