import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { steps } from '../../../../../helpers/steps';
import { useDuplicateCodes } from '../../../hooks/useDuplicateCodes';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const DuplicateDxButton = () => {
  const { localState } = useFormContext();
  const { onDuplicateCode, isDisabledDuplicateCodesButton } = useDuplicateCodes();
  const disabled = isDisabledDuplicateCodesButton(inputs.icd9);

  const onClick = () => {
    onDuplicateCode(inputs.icd9);
  };

  if (localState.step === steps.groupDxPicker) {
    return (
      <Button
        className="p-button-outlined w-full"
        label={t('Duplicate_Dx')}
        tooltip={t('Duplicate_dx_for_same_patient')}
        tooltipOptions={{ showDelay: 500, position: 'top' }}
        disabled={disabled}
        onClick={onClick}
      />
    );
  }

  return null;
};

export default DuplicateDxButton;
