import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useEClaimPolicy } from '../../hooks/useEClaimPolicy';
import EClaimPolicy from '../../../EClaimPolicy/EClaimPolicy';

const EClaimPolicyForm = () => {
  const { localState } = useFormContext();
  const { onCloseEClaimPolicy, onCreateEClaimPolicy, onEditEClaimPolicy } = useEClaimPolicy();
  const eClaimPolicy = localState.eClaimPolicy;

  if (eClaimPolicy.showEClaimPolicyForm) {
    return (
      <EClaimPolicy
        {...eClaimPolicy}
        onCancel={onCloseEClaimPolicy}
        onCreate={(data) => onCreateEClaimPolicy(data)}
        onEdit={(data) => onEditEClaimPolicy(data)}
      />
    );
  }

  return null;
};

export default EClaimPolicyForm;
