import React from 'react';
import cx from 'classnames';
import './FormWithCatalogsWrap.scss';

const RightCardWrap = ({ children, hideRightCard }) => {
  return (
    <div className={cx('right_card_desktop_catalog_container catalogsRoot', hideRightCard ? 'hidden' : '')}>
      <div className="card right_card_catalogs_card">{children}</div>
    </div>
  );
};

export default RightCardWrap;
