import { commonInputs } from '../../../../../modules/config/commonInputsConfig';
import { t } from '../../../../../service/localization/i18n';

export const inputs = {
  thread: {
    name: 'Parent',
    label: t('Thread')
  },

  author: {
    name: 'Author',
    label: t('Author')
  },

  adressee: {
    name: 'Adressee',
    label: t('Adressee')
  },

  date: {
    name: 'NoteDate',
    label: t('Date')
  },

  title: {
    name: 'Title',
    label: t('Title')
  },

  note: {
    name: 'Comments',
    label: t('Note')
  },

  noteGuid: {
    name: 'NoteGuid'
  },

  entityGuid: {
    name: 'EntityGuid'
  },

  dcnGuid: {
    name: 'DCNGuid'
  },

  parentGuid: {
    name: 'Parent'
  },

  practitionerGuid: commonInputs.practitionerGuid
};
