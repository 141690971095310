export const initDoc = (doc) => {
  return {
    DCNGuid: doc?.DCNGuid || '',
    AddressLine: doc?.AddressLine || '',
    FullAddress: doc?.FullAddress || `${doc?.AddressLine} ${doc?.City} ${doc?.Province} ${doc?.Zip}`,
    City: doc?.City || '',
    Email: doc?.Email || '',
    Phone: doc?.Phone || '',
    Province: doc?.Province || '',
    StreetName: doc?.StreetName || '',
    StreetNo: doc?.StreetNo || '',
    Zip: doc?.Zip || '',
    UserStatus: doc?.UserStatus || '-1'
  };
};
