import React from 'react';
import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';

const TravelTooltipLayout = () => {
  // return (
  //   <div id={elementIDs.travelInfoDialog}>
  //     <p>{t(`YES_charges`)}</p>
  //     <ul style={{ listStyleType: 'disc' }}>
  //       <li className='pb-2'>{t("had_to_travel_to_see_the_patient")}</li>
  //       <li className='pb-2'>{t("had_to_leave_immediately")}</li>
  //       <li>{t("first_patient_examined")}</li>
  //     </ul>
  //     <p>
  //       {t("The_out-of-office_hours_premium_listings")}
  //     </p>
  //   </div>
  // )

  return (
    <div id={elementIDs.travelInfoDialog}>
      <p>{t(`Select_this_button_if_all_is_true`)}</p>

      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-1">{t('This_services_qualifies_for_OOFHP')}</li>
        <li className="pb-1">{t('The_MD_had_to_travel_to_see_the_patient')}</li>
        <li className="pb-1">{t('The_MD_had_to_leave_immediately')}</li>
        <li>{t('It_was_the_first_patient_examined_and_treated_at_any_special_visit')}</li>
      </ul>

      <p>{t('We_will')}</p>

      <ul style={{ listStyleType: 'disc' }}>
        <li>{t('Add_a_call_out_fee_code_if_applicable')}</li>
      </ul>

      <p>{t('Call_Out_is_applicable_only_if_OOFHP_is_applicable')}</p>
    </div>
  );
};

export default TravelTooltipLayout;
