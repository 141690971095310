import { inputs } from './inputs';
import { store } from '../../../../../store';
import { getDefaultValues, feeCodeInit } from './defaultValues';
import { formatFormTypeValue } from './formTypeActions';
import { isEmpty } from 'lodash';
import { defaultAnatomicCode } from '../../../../config/defaultValuesConfig';
import { isChiropractor, isPhysician, isReceptionist } from '../../../../config/specialitiesConfig';
import moment from 'moment';

export const updateInitialStateWithClaimData = ({ errors, clearErrors, setValue, shouldDirty, claim }) => {
  const DCNGuid = store.getState().clinic.details.DCNGuid;
  const user = store.getState().user.details;
  const speciality = user.Speciality || 0;
  const setDirty = shouldDirty ? { shouldDirty: true } : {};

  let inputsForUpdate = {
    ...defaultInputValues(),
    [inputs.claimGuid.name]: DCNGuid
  };

  // Set new input values
  if (claim && !isEmpty(claim)) {
    const currentSpeciality = isReceptionist(user.PractitionerNumber) ? claim.Reports[0][inputs.speciality.name] : speciality;
    const formType =
      claim.Reports?.length && claim.Reports[0][inputs.formType.name] ? claim.Reports[0][inputs.formType.name] : formTypeDefaultValue(speciality);
    const formTypeValue = `${formatFormTypeValue(`${formType}`, currentSpeciality)}`;
    const feeCode = feeCodeInit(speciality, formTypeValue);

    const commonInputsForUpdate = {
      [inputs.claimGuid.name]: claim.ClaimGuid,
      [inputs.claimNumber.name]: claim.NewClaimNumber || (claim[inputs.claimNumber.name] === 'N/A' ? '' : claim[inputs.claimNumber.name]),
      [inputs.injuryDate.name]: moment(claim.InjuryDay).toDate(),
      [inputs.icd9.name]: claim.ICD9Code,
      [inputs.icd9.codeDescription]: claim.ICD9Text,
      [inputs.aoi.name]: claim.AreaOfInjury,
      [inputs.aoi.codeDescription]: claim[inputs.aoi.codeDescription],
      [inputs.noi.name]: claim.NatureOfInjury,
      [inputs.noi.codeDescription]: claim[inputs.noi.codeDescription],
      [inputs.anatomicCode.name]: claim?.AnatomicCode || defaultAnatomicCode,

      [inputs.formType.name]: formType,
      [inputs.feeCodes.name]: feeCode
    };

    if (!claim.Reports?.length) inputsForUpdate = { ...inputsForUpdate, ...commonInputsForUpdate };

    if (claim.Reports?.length) {
      const report = claim.Reports[0];

      inputsForUpdate = {
        ...commonInputsForUpdate,
        [inputs.icd9.codeDescription]: report[inputs.icd9.codeDescription],
        [inputs.employer.name]: report[inputs.employer.name],
        [inputs.phone.name]: report[inputs.phone.name],
        [inputs.address.name]: report[inputs.address.name],
        [inputs.city.name]: report[inputs.city.name],
        [inputs.isPrimary.name]: report[inputs.isPrimary.name],
        [inputs.termPrimary.name]: report[inputs.termPrimary.name],
        [inputs.firstTreatment.name]: report[inputs.firstTreatment.name],
        [inputs.priorProblems.name]: report[inputs.priorProblems.name]
      };
    }
  }

  Object.entries(inputsForUpdate).forEach(([inputName, inputValue]) => {
    if (inputValue && errors[inputName]) clearErrors(inputName); // Clear error message
    setValue(inputName, inputValue, setDirty); // Update input value
  });
};

export const defaultInputValues = () => {
  const defaultVal = getDefaultValues();

  return {
    [inputs.injuryDate.name]: defaultVal[inputs.injuryDate.name],
    [inputs.icd9.name]: defaultVal[inputs.icd9.name],
    [inputs.icd9.codeDescription]: defaultVal[inputs.icd9.codeDescription],
    [inputs.noi.name]: defaultVal[inputs.noi.name],
    [inputs.noi.codeDescription]: defaultVal[inputs.noi.codeDescription],
    [inputs.aoi.name]: defaultVal[inputs.aoi.name],
    [inputs.aoi.codeDescription]: defaultVal[inputs.aoi.codeDescription],
    [inputs.anatomicCode.name]: defaultVal[inputs.anatomicCode.name],

    [inputs.formType.name]: defaultVal[inputs.formType.name],
    [inputs.employer.name]: defaultVal[inputs.employer.name],
    [inputs.phone.name]: defaultVal[inputs.phone.name],
    [inputs.address.name]: defaultVal[inputs.address.name],
    [inputs.city.name]: defaultVal[inputs.city.name],
    [inputs.firstTreatment.name]: defaultVal[inputs.firstTreatment.name],
    [inputs.priorProblems.name]: defaultVal[inputs.priorProblems.name],
    [inputs.isPrimary.name]: defaultVal[inputs.isPrimary.name],
    [inputs.termPrimary.name]: defaultVal[inputs.termPrimary.name],
    [inputs.feeCodes.name]: defaultVal[inputs.feeCodes.name]
  };
};

const formTypeDefaultValue = (speciality) => {
  // Phisitian
  if (isPhysician(speciality)) return '10';
  // Chiropractic
  if (isChiropractor(speciality)) return '0';
};
