import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WSBCReportPrint from './WSBCReport/WSBCReportPrint';
import PrivateInvoicePrint from './PrivateInvoice/PrivateInvoicePrint';

import { printTypes } from './helpers/printTypes';
import { setPrintData } from '../../../modules/core/actions/core.action.creators';

const Print = () => {
  const dispatch = useDispatch();
  const { print } = useSelector((state) => state.core);
  const types = printTypes();

  // Action on close print window
  document.getElementsByTagName('body')[0].onafterprint = function () {
    dispatch(setPrintData());
  };

  const printLayout = () => {
    switch (print.type) {
      case types.privateInvoice.type:
        return <PrivateInvoicePrint data={print.printData || {}} />;

      case types.wsbcReport.type:
        return <WSBCReportPrint data={print.printData || {}} />;

      default:
        return null;
    }
  };

  return <>{printLayout()}</>;
};

export default Print;
