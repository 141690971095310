import { useFormContext } from 'react-hook-form';
import { inputs } from '../../../helpers/inputs';
import { isEqual, sortBy } from 'lodash';

export const useGroupTemplate = () => {
  const { watch, localState, isGroup } = useFormContext();
  const patients = watch(inputs.patient.name);
  const feeCodes = watch(inputs.feeCodes.codeType);
  const selectedTemplate = localState.selectedTemplate;
  const firstStep = selectedTemplate?.FirstStep;
  const templatePatientsGuids = firstStep ? firstStep[inputs.patient.name]?.map((i) => i.PatientGuid) : [];
  const templateFeeCodesOnly = firstStep ? firstStep[inputs.feeCodes.codeType]?.map((i) => i.value) : [];
  const currentFeeCodesOnly = feeCodes?.map((i) => i.value);
  const currentPatientsGuids = patients?.map((i) => i.PatientGuid);
  const isSamePatients = isEqual(sortBy(templatePatientsGuids), sortBy(currentPatientsGuids));
  const isSameFeeCodes = isEqual(sortBy(templateFeeCodesOnly), sortBy(currentFeeCodesOnly));

  const isDuplicateTemplate = isSamePatients || isSameFeeCodes;
  const showSaveTemplateButton = isGroup;

  return {
    isDuplicateTemplate,
    showSaveTemplateButton
  };
};
