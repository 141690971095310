import React from 'react';

const RatioPercent = ({ ratioPercent }) => {
  const color = ratioPercent < 0 ? 'pink' : 'green';
  const icon = ratioPercent < 0 ? 'pi pi-arrow-down' : 'pi pi-arrow-up';

  return (
    <div className={`text-${color}-500`}>
      <span className="font-medium">{`${ratioPercent}%`}</span>
      {ratioPercent !== 0 && <i className={`${icon} text-xs ml-2`} />}
    </div>
  );
};

export default RatioPercent;
