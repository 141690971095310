import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';

import AsterisksForTimeInput from './AsterisksForTimeInput';
import RecommendedTime from '../../../../../modules/Layouts/RecommendedTime';
import { WrappedTimeInput } from '../../../../../../../../../components/Inputs';

import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { isTimeValid } from '../../../../../../../../utils/formatTime';
import { colors } from '../../../../../../../../config/stylesConfig';
import { isRecommendetTime } from '../../../../../helpers/isRecommendetTime';
import { t } from '../../../../../../../../../service/localization/i18n';
import moment from 'moment';

const WarnIcont = ({ rowOptions }) => {
  const { watch, localState } = useFormContext();
  const { isTimeOverMidnight } = useValidateGoupPicker();

  const prevRecord = localState.groupRecords[0];
  const firstRowStartTime = prevRecord ? prevRecord[inputs.startTime.name] : '';

  if (!firstRowStartTime) return null;

  const id = rowOptions.rowData[inputs.patient.name][0]?.PatientGuid;

  if (isTimeOverMidnight(rowOptions.rowData)) {
    return (
      <>
        <Tooltip target={`.record_warn_tooltip_${id}`} position="top" />
        <div
          className={`record_warn_tooltip_${id}`}
          data-pr-tooltip={String.format(t('Did_you_enter_future_dates_patient'), moment(watch(inputs.serviceDate.name)).format('MMMM D'))}
        >
          <i className="pi pi-exclamation-triangle text-lg" style={{ paddingTop: '3px', color: colors.danger }} />
        </div>
      </>
    );
  }

  return null;
};

const StartTimeEditor = ({ rowOptions }) => {
  const ref = useRef(null);
  const { onTimeChange } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const name = inputs.startTime.name;
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const feeCodes = rowOptions.rowData[inputs.feeCodes.codeType];
  const startTime = rowOptions.rowData[inputs.startTime.name];
  const value = rowOptions.rowData[name];
  const isValidTime = isTimeValid(value);
  const errorMessage = getErrorMessage(rowId, name);
  const isRecommendet = isRecommendetTime({ feeCodes });

  const onChange = (time) => {
    if (time === value) return;
    onTimeChange(time, name, rowOptions);
  };

  const footerContent = !isTimeValid(startTime) && isRecommendet ? <RecommendedTime feeCode={feeCodes?.[0]?.value} /> : null;

  return (
    <div className="flex align-items-start" style={{ rowGap: '0.1rem', columnGap: '0.1rem' }}>
      <WrappedTimeInput
        ref={ref}
        name={name}
        value={value}
        hideLabel
        errorMessage={!isValidTime && errorMessage}
        style={{ height: rowInputHeight, width: '92px' }}
        id={`${name}_${rowOptions.rowIndex}`}
        footerContent={footerContent}
        isInvalid={!isValidTime && !!errorMessage}
        onChange={(time) => onChange(time)}
      />

      <AsterisksForTimeInput rowOptions={rowOptions} inputName={name} />
      <WarnIcont rowOptions={rowOptions} />
    </div>
  );
};

export default StartTimeEditor;
