import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../../Inputs';
import { InputText } from 'primereact/inputtext';

import { inputs } from '../helpers/inputs';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';

const Title = () => {
  const name = inputs.title.name;
  const label = inputs.title.label;
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const errorMessage = errors?.[name]?.message;
  const maxLength = 80;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: t('Mandatory_field.1') }}
      render={({ field }) => (
        <InputWrapper name={name} label={label} errorMessage={errorMessage} required showCounter inputValue={field.value} countLimit={maxLength}>
          <InputText {...field} maxLength={maxLength} autoFocus className={cx({ 'p-invalid': !!errorMessage })} type="text" />
        </InputWrapper>
      )}
    />
  );
};

export default Title;
