import React from 'react';

import { Divider } from 'primereact/divider';

import Context from '../../../../Context';
import BillingInformation from './Blocks/BillingInformation';
import ClinicalInformation from './Blocks/ClinicalInformation';
import DemographicsInfo from './Blocks/DemographicsInfo';
import Header from './Blocks/Header';
import InjuryCodes from './Blocks/InjuryCodes';
import ReturnToWork from './Blocks/ReturnToWork';

import './WSBCReportPrint.scss';

const WSBCReportPrint = ({ data }) => {
  return (
    <div id="invoice-content" className="printContent w-full wsbcReportPrint">
      <Context.Provider value={{ data }}>
        <Header />
        <Divider className="my-1" />

        <DemographicsInfo />

        <InjuryCodes />

        <ClinicalInformation />

        <ReturnToWork />

        <BillingInformation />
      </Context.Provider>
    </div>
  );
};

export default WSBCReportPrint;
