import React from 'react';
import CardTitle from './CardTitle';
import HeaderInfo from './HeaderInfo';
import { Divider } from 'primereact/divider';

const HeaderInfoSection = () => {
  return (
    <div>
      <div className="flex align-items-center justify-content-center w-full">
        <CardTitle />
      </div>

      <div className="text-base pt-4">
        <HeaderInfo />
        <Divider />
      </div>
    </div>
  );
};

export default HeaderInfoSection;
