// import React from 'react';
// import { Controller, useFormContext } from 'react-hook-form';

// import { Checkbox } from 'primereact/checkbox';
// import InputErrorMessage from '../../../../../../../components/Messages/InputErrorMessage';

// import { AUTORIZATION_FORM } from '../../../../../../../config';
// import { elementIDs } from '../../../../../../config/elementIDsConfig';
// import { inputs } from '../../../helpers/inputs';
// import { t } from '../../../../../../../service/localization/i18n';
// import cx from 'classnames';

// const AuthorizationFormCheckbox = () => {
//   const name = inputs.authorizationForm.name;
//   const { control, errors } = useFormContext();

//   return (
//     <Controller
//       name={name}
//       control={control}
//       rules={{ required: t('Mandatory_field.1') }}
//       render={({ field, fieldState }) => (
//         <>
//           <div className='p-field-checkbox pt-2 pb-2'>
//             <Checkbox
//               className={cx('mr-3', { 'p-invalid': fieldState.error })}
//               type="checkbox"
//               id={elementIDs.authorizationFormCheckbox}
//               inputId={name}
//               checked={field.value}
//               onChange={(e) => field.onChange(e.checked)}
//             />
//             <label htmlFor={name}>
//               <a
//                 target='_blank'
//                 rel="noreferrer"
//                 href={AUTORIZATION_FORM}
//                 className="underline pr-2"
//               >
//                 {inputs.authorizationForm.label}
//               </a>
//               {t('Signed').toLowerCase()}
//             </label>
//           </div>
//           <InputErrorMessage message={errors[name]?.message} />
//         </>
//       )}
//     />
//   )
// }

// export default AuthorizationFormCheckbox

import React from 'react';
import { AUTORIZATION_FORM } from '../../../../../../../config';
import { inputs } from '../../../helpers/inputs';

const AuthorizationFormCheckbox = () => {
  return (
    <div className="pb-3">
      <a target="_blank" rel="noreferrer" href={AUTORIZATION_FORM} className="underline pr-2">
        {inputs.authorizationForm.label}
      </a>
    </div>
  );
};

export default AuthorizationFormCheckbox;
