import React from 'react';
import { useHistory } from 'react-router-dom';

import DesktopRowExpansionTemplate from './DesktopRowExpansionTemplate';
import CustomAvatar from '../../../common/components/CustomAvatar';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import { mobileRowToggle } from '../../../utils/mobileRowToggle';
import { viewPatientProfile } from './helpers/rowActionsMenuItems';
import DeceasedBadge from '../../../../components/Misc/Badges/Deceased/DeceasedBadge';
import { t } from '../../../../service/localization/i18n';

const MobileRowTemplate = (props) => {
  const history = useHistory();

  let triggerTime;
  const avatarLetter = `${props.rowData.LastName.charAt(0).toUpperCase()}${props.rowData.FirstName.charAt(0).toUpperCase()}`;
  const _isExpanded = props.expandedRows ? Object.keys(props.expandedRows).some((i) => i === props.rowData[props.dataKey]) : {};

  const onExpand = () => {
    props.setSelectedRow([props.rowData]); // highlight expanded row
    mobileRowToggle({
      rowData: props.rowData,
      expandedRows: props.expandedRows,
      dataKey: props.dataKey,
      setExpandedRows: props.setExpandedRows
    });
  };

  return (
    <div className="w-full">
      {/* HEADER */}
      <div className="flex align-items-start justify-content-between w-full">
        <div className="flex">
          <div onClick={() => viewPatientProfile(props.rowData, history)}>
            <CustomAvatar id={props.rowData.PatientGuid} label={avatarLetter} />
          </div>

          <div
            className="flex flex-column justify-content-start align-items-start px-3 gap-1"
            onClick={() => props.longPressEvent(triggerTime, props.rowData)}
            onTouchStart={() => (triggerTime = new Date().getTime())}
            onTouchEnd={() => {
              let thisMoment = new Date().getTime();
              triggerTime = thisMoment - triggerTime;
            }}
          >
            {/* Patient name */}
            <div className="font-bold white-space-normal">{props.rowData?.FullName}</div>
            <div>
              {props.rowData.DOBText} ({props.rowData.Age}
              {props.rowData.Sex}){props.rowData.IsDeceased ? `, ${t('DCD')}` : ''}
            </div>
            {/* <div>{props.rowData?.IsDeceased && <DeceasedBadge />}</div> */}
          </div>
        </div>

        <div className="flex flex-column justify-content-between align-items-center">
          <div>{props.rowActionButtons(props.rowData)}</div>

          {/* Expand row */}
          {props.showExpand && (
            <Button
              className="p-button-text p-button-rounded mt-2"
              icon={_isExpanded ? 'pi pi-angle-down' : 'pi pi-angle-right'}
              onClick={onExpand}
            />
          )}
        </div>
      </div>

      {/* EXPAND CONTENT */}
      <div className={_isExpanded ? 'rowExpandedMobile' : 'rowCollapsedMobile'}>
        <div className="pt-1">
          <Divider />

          <DesktopRowExpansionTemplate rowData={props.rowData} mobileView={true} />
        </div>
      </div>
    </div>
  );
};

export default MobileRowTemplate;
