import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getEClaimFormName } from '../../../../../utils/getEClaimFormName';
import { getEOBLink } from '../../../../../utils/getLink';
import moment from 'moment';

const EOBLink = () => {
  const { watch } = useFormContext();
  const eClaim = watch();
  const eobLink = getEOBLink(eClaim);
  const formattedDateRecived = moment(eClaim.Received).format('MMM D, YYYY');

  if (!eClaim.Received) return null;

  return (
    <div className="flex flex-wrap justify-content-start gap-3">
      <div className="eclaim_step_two_left_gap"></div>
      <a target="_blank" rel="noreferrer" href={eobLink} className="underline pointer">
        {getEClaimFormName(eClaim?.RemittanceCode)}
        {eClaim.SequenceNumber ? ` #${eClaim.SequenceNumber}` : ''}
      </a>
      <span>{formattedDateRecived}</span>
    </div>
  );
};

export default EOBLink;
