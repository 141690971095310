import React, { useState, useEffect, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';

import LegoView from '../../_lego/LegoView';
import PaymenstDialog from './PaymenstDialog';
import ClaimService from '../../../service/ClaimService';
import RowExpantionTable from './RowExpantionTable';
import MobileRowExpansion from './MobileRowExpansion';
import MobileRowExpansionSkeleton from './MobileRowExpansionSkeleton';
import ClaimsViewPaginatorLeft from './ClaimsViewPaginatorLeft';
import RowClaimHistory from './RowClaimHistory';
import ClaimSearch from './ClaimSearch';
import ClaimFilters from './ClaimFilters';
import DeleteConfirmation from '../../common/components/DeleteConfirmation';
import SubMenu from '../../_lego/LegoSubMenu';
import CommonConfirmDialog from '../../common/components/CommonConfirmDialog';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';
import BatchRecordsWarnDialogForOnePatient from '../../common/components/Dialogs/BatchRecordsWarnDialogForOnePatient';
import DebitRequestDialog from '../../../components/Dialogs/DebitRequestDialog/DebitRequestDialog';

import { ViewModel } from '../../_lego/LegoViewModel';
import { removeDeepRow } from '../../core/actions/core.action.creators';
import { dataTableFieldFilters } from '../helpers/dataTableFieldFilters';
import { extraFields } from '../helpers/extraFields';
import { rowActions, rowActionsMenuItems, onArchiveClick, onArchiveEClaim } from '../helpers/rowActions';
import { confirmDialogNames } from '../helpers/confirmDialogNames';
import { loadData, loadData4Scroll } from '../helpers/loadData';
import { startCols, endCols } from '../helpers/dataTableColumns';
import { clearFilters, clearFiltersExceptFreetext } from '../helpers/clearFilters';
import { cardInitialState } from '../helpers/cardInitialState';
import { addNewOverlayItemsList } from '../helpers/addNewOverlayItemsList';
import { defaultPageSize, showInCardPageSize } from '../../config/defaultValuesConfig';
import {
  setClaimFilters,
  //setDataTableExpandedRows,   // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  deleteClaim,
  setClaimInfo,
  getClaims4Scroll,
  setDataTableExpandedRows,
  getClaimInfo,
  getClaimList
} from '../actions/claims.action.creators';
import { addNewTeleplan } from '../helpers/addNewInvoice';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { t } from '../../../service/localization/i18n';
import * as mapper from '../helpers/mapper';
import classes from './ClaimView.module.scss';
import { isEmpty, isEqual } from 'lodash';
import AddClaimNoteDialog from './AddClaimNoteDialog';
import useSpacebarHandler from '../../hooks/useSpacebarHandler';
import PatientBillingHistoryDialog from '../../../components/Widgets/PatientBillingHistory/PatientBillingHistoryDialog';
import { patientFullName, patientFullNameWithAge } from '../../utils/patientFullName';
import PatientForm from '../../patients/components/PatientForm/PatientForm';
import SimilarClaimsListDialog from '../../../components/Widgets/SimilarClaimsList/SimilarClaimsListDialog';

const ClaimView = ({ patientDetails, showOnPatientDetailsScreen = false, showInCard = false, showSkeleton = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const userList = useSelector((state) => state.clinic.members) || [];
  const filterOptions = useSelector((state) => state.clinic.filters) || [];

  const claimsData = useSelector((state) => ({
    page: state.claims.page || 0,
    rows: state.claims.pageSize,
    totalRecords: state.claims.totalRecords || 0,
    totalRecordsAlter: state.claims.totalRecordsAlter || 0,
    totalBilled: state.claims.totalBilled || 0,
    totalPaid: state.claims.totalPaid || 0,
    totalDue: state.claims.totalDue || 0,
    first: state.claims.first || 0,
    filters: state.claims.filters || { Period: '-2' },
    claim_list: state.claims.claim_list,
    claim_details: state.claims.claim_details,
    complete_list: state.claims.complete_list,
    isFetching: state.claims.isFetchingClaims,
    isSavingClaims: state.claims.isSavingClaims,
    isArchiving: state.claims.isArchiving,
    isFetchingClaimHistory: state.claims.isFetchingClaimHistory,
    selectedCodes: state.claims.selectedCodes,
    sortby: state.claims.sortby,
    dataTableExpandedRows: state.claims.dataTableExpandedRows // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  }));

  const { clientWidth, isLandscape, isMobile, isMobileOnly, isTablet, iPadProInLandscape } = useSelector((state) => state.core.window);
  const { readonly, layoutMode } = useSelector((state) => state.core);

  const [popupVisible, setPopupVisible] = useState(false);
  const [deleteClaimConfirm, setDeleteClaimConfirm] = useState(false);
  const [claimDataForAction, setClaimDataForAction] = useState({});
  const [popupHeader, setHeader] = useState('');
  const [claim_history, setClaimHistory] = useState([]);
  const [checked, setChecked] = useState(false);
  const [expandedRowMobile, setExpandedRowMobile] = useState('');
  const [showDebitConfirm, setShowDebitConfirm] = useState(false);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);
  const [showArchiveEClaimConfirm, setShowArchiveEClaimConfirm] = useState(false);
  const [showAddClaimNoteDialog, setShowAddClaimNoteDialog] = useState(false);
  const [updatePatientDialog, setUpdatePatientDialog] = useState(false);
  const [viewPaymentsDialog, setViewPaymentsDialog] = useState(false);
  const [showBillingHistoryDialog, setShowBillingHistoryDialog] = useState(false);
  const [showSimilarClaimsDialog, setShowSimilarClaimsDialog] = useState(false);
  const [showEditPatientDialog, setShowEditPatientDialog] = useState(false);
  const [initialStateForCard, setInitialStateForCard] = useState(cardInitialState); // [KS] Do not use Redux for invoice card on patient’s profile. Use service instead, using promise.
  //   // CMO-810 - Discard filter on Invoices table after we click on View Patient on any claim's line because the filter gets applied to Invoices card on Patient's profile

  const growl = useRef(null);
  const rotateDeviceToast = useRef(null);
  useSpacebarHandler();

  // const claimsData = showInCard ? initialStateForCard : claimsDataInit;

  // const itemsNumberForTable = claimsData.totalRecords < claimsData.rows ? claimsData.totalRecords : claimsData.rows;
  const itemsNumber = Array.from({ length: showInCard ? showInCardPageSize : defaultPageSize }, () => 'item');
  const ruralBase = filterOptions.find((x) => x.FieldName === 'SCCode');
  const ruralOptions = ruralBase ? ruralBase.options : [];
  const DCNGuid = user.DCNGuid;
  const docList = userList.map((x) => {
    if (x.Role === 'Practitioner') return { value: x.DoctorGuid, label: `${x.FirstName} ${x.LastName}` };
  });
  const dataScrollerClass =
    (claimsData.totalRecordsAlter !== undefined &&
      claimsData.totalRecordsAlter !== claimsData.totalRecords &&
      isMobileOnly &&
      showOnPatientDetailsScreen) ||
    (showOnPatientDetailsScreen && isMobileOnly)
      ? ''
      : claimsData.totalRecordsAlter !== undefined && claimsData.totalRecordsAlter !== claimsData.totalRecords && isMobileOnly
      ? classes.dataScrollerContentWithRemoveBtn
      : classes.dataScrollerContent;

  const statuses = {
    invoiceTypes: mapper.invoiceTypes,
    remPeriods: mapper.remPeriods,
    reportPeriods: mapper.reportPeriods,
    reportTypes0: mapper.reportTypes0,
    teleplanStatuses: mapper.teleplanStatuses,
    privateStatuses: mapper.privateStatuses,
    eclaimStatuses: mapper.eclaimStatuses,
    allStatuses: mapper.allStatuses,
    shortStatuses: mapper.allStatuses?.map((x) => {
      return { value: x.value, label: x.label?.replace('Statuses', '')?.trim(), cloudTag: x.cloudTag };
    })
  };

  const setConfirmDialog = (data, name) => {
    setClaimDataForAction(data);
    // eslint-disable-next-line default-case
    switch (name) {
      case confirmDialogNames.delete:
        setDeleteClaimConfirm(true);
        break;

      case confirmDialogNames.debit:
        setShowDebitConfirm(true);
        break;

      case confirmDialogNames.archive:
        setShowArchiveConfirm(true);
        break;

      case confirmDialogNames.archiveEClaim:
        setShowArchiveEClaimConfirm(true);
        break;

      case confirmDialogNames.addClaimNote:
        setShowAddClaimNoteDialog(true);
        break;

      case confirmDialogNames.billingHistory:
        setShowBillingHistoryDialog(true);
        break;

      case confirmDialogNames.similarClaims:
        setShowSimilarClaimsDialog(true);
        break;

      case confirmDialogNames.editPatient:
        setShowEditPatientDialog(true);
        break;

      // case confirmDialogNames.updatePHN:
      //   setUpdatePatientDialog(true);
      //   break

      case confirmDialogNames.updatePatientProfile:
        setUpdatePatientDialog(true);
        break;

      case confirmDialogNames.viewPayments:
        dispatch(
          getClaimInfo(data?.InvoiceGuid, (responseData) => {
            setClaimDataForAction(responseData);
          })
        );
        setViewPaymentsDialog(true);
        break;

      default:
        break;
    }
  };

  const onHideConfirmDialog = (name) => {
    setClaimDataForAction({});
    // eslint-disable-next-line default-case
    switch (name) {
      case confirmDialogNames.delete:
        setDeleteClaimConfirm(false);
        break;

      case confirmDialogNames.debit:
        //logit to hide dialog is inside DebitRequestDialog component
        break;

      case confirmDialogNames.archive:
        setShowArchiveConfirm(false);
        break;

      case confirmDialogNames.addClaimNote:
        setShowAddClaimNoteDialog(false);
        break;

      case confirmDialogNames.archiveEClaim:
        setShowArchiveEClaimConfirm(false);
        break;

      case confirmDialogNames.billingHistory:
        setShowBillingHistoryDialog(false);
        break;

      case confirmDialogNames.similarClaims:
        setShowSimilarClaimsDialog(false);
        break;

      case confirmDialogNames.editPatient:
        setShowEditPatientDialog(false);
        break;

      // case confirmDialogNames.updatePHN:
      //   setUpdatePatientDialog(false);
      //   break

      case confirmDialogNames.updatePatientProfile:
        setUpdatePatientDialog(false);
        break;

      default:
        break;
    }
  };

  const onAcceptDeleteClaim = () => {
    dispatch(
      deleteClaim({
        invoiceGuid: claimDataForAction?.InvoiceGuid,
        patientGuid: patientDetails?.PatientGuid
      })
    );
  };

  const actionButtonsParams = {
    isMobile,
    isTablet,
    clientWidth,
    history,
    readonly,
    showOnPatientDetailsScreen,
    isFetching: claimsData.isFetching,
    patientGuid: patientDetails?.PatientGuid,
    setConfirmDialog,
    setShowDebitConfirm,
    dispatch
  };

  const loadDataParams = {
    claimsData,
    DCNGuid,
    dispatch,
    PatientGuid: patientDetails?.PatientGuid,
    showInCard,
    showOnPatientDetailsScreen,
    setInitialStateForCard
  };

  ViewModel.PatientGuid = patientDetails?.PatientGuid;
  ViewModel.showAlpha = false;
  ViewModel.showChips = true;
  ViewModel.showArchivedButton = true;
  ViewModel.showExpandButton = true;
  ViewModel.showExportButton = true;
  ViewModel.showAddNewOverlay = true;
  ViewModel.addNewOverlayItems = addNewOverlayItemsList({ history, patient_details: patientDetails, isSplitButton: false });
  ViewModel.isFetching = claimsData.isFetching || showSkeleton;
  ViewModel.showSkeleton = showSkeleton; // CMO-1625 - New skeleton for patients profile
  ViewModel.dataPage = claimsData;
  ViewModel.dropdownFilters = statuses;
  ViewModel.pageSize = claimsData.rows;
  ViewModel.sortby = claimsData.sortby;
  ViewModel.sortField = claimsData.sortby.split('_')[0];
  ViewModel.sortOrder = claimsData.sortby.split('_')[1] === 'asc' ? 1 : -1;
  ViewModel.filters = claimsData.filters;
  ViewModel.dataTableExpandedRows = !showInCard ? claimsData.dataTableExpandedRows : {}; // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  //ViewModel.setDataTableExpandedRows = setDataTableExpandedRows;  // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  ViewModel.showInCard = showInCard;
  ViewModel.showOnPatientDetailsScreen = showOnPatientDetailsScreen;
  ViewModel.dataScrollerClass = dataScrollerClass;
  ViewModel.localStoragePageSizeKey = localStorageKeys.invoicesPageSize;
  ViewModel.dataKey = 'InvoiceGuid';
  ViewModel.filtersTitle = t('Filter records');
  ViewModel.dataSourceInplace = claimsData.isFetching || showSkeleton ? itemsNumber : claimsData.claim_list;
  ViewModel.dataSourceAppend = claimsData.complete_list;
  ViewModel.dataTotalRows = claimsData.totalRecords;
  ViewModel.scrollUpPosition = { bottom: '9em', right: isMobile ? '5em' : '9em' };
  ViewModel.preSelected = !isEmpty(claimsData.claim_details) ? [claimsData.claim_details] : [];
  ViewModel.resetPreSelected = () => dispatch(setClaimInfo({}));
  ViewModel.onAddClick = () => addNewTeleplan({ history, patient_details: patientDetails });
  ViewModel.clearFilters = () => clearFilters(dispatch, claimsData.filters.RecordType, statuses.allStatuses);
  ViewModel.clearFiltersExceptFreetext = () => clearFiltersExceptFreetext(dispatch, claimsData.filters.freetext);
  ViewModel.actions = rowActions(actionButtonsParams);
  ViewModel.contextMenu = (data) => rowActionsMenuItems({ ...actionButtonsParams, data });
  ViewModel.dataOnPageInplace = (e) => loadData(e, loadDataParams);
  ViewModel.dataOnPageAppend = (e) => loadData4Scroll(e, loadDataParams);
  ViewModel.rowTemplateInplace = (e) => rowExpansionTable(e);
  ViewModel.rowTemplateAppend = (e) => rowExpansionTemplate(e);
  ViewModel.extraFields = extraFields(ruralOptions, userList, claimsData.filters);
  ViewModel.dataFieldFilters = dataTableFieldFilters(docList, statuses);
  ViewModel.setClaimFilters = (filter) => (dispatch, state) => setClaimFilters(filter)(dispatch, state);
  ViewModel.onExportClick = () => ClaimService.getClaimReport(exportDone, dispatch);
  ViewModel.columns = patientDetails?.PatientGuid
    ? startCols(isMobile, clientWidth, claimsData.isFetching).concat(
        endCols(
          isMobile,
          isTablet,
          isLandscape,
          clientWidth,
          setHeader,
          setClaimHistory,
          setPopupVisible,
          claimsData.isFetching,
          history,
          dispatch
        ).slice(1)
      )
    : startCols(isMobile, clientWidth, claimsData.isFetching).concat(
        endCols(isMobile, isTablet, isLandscape, clientWidth, setHeader, setClaimHistory, setPopupVisible, claimsData.isFetching, history, dispatch)
      );
  ViewModel.filterComponent = () => (
    <ClaimFilters filters={ViewModel.filters} selectedCodes={claimsData.selectedCodes} dataSource={filterOptions} statuses={statuses} />
  );
  ViewModel.searchPanelComponent = () => (
    <ClaimSearch
      filters={claimsData.filters}
      statuses={statuses}
      isMobile={isMobile}
      isMobileOnly={isMobileOnly}
      isLandscape={isLandscape}
      showOnPatientDetailsScreen={showOnPatientDetailsScreen}
    />
  );
  ViewModel.onLazyLoad = (e) => {
    const realPage = 1 + parseInt(e.first / e.rows, 10);
    // if (claimsData.page > realPage) return; //WHITE SCREEN IF NO getClaims4Scroll REQUEST

    dispatch(
      getClaims4Scroll({
        page: realPage,
        pageSize: e.rows,
        filter: JSON.stringify(claimsData.filters),
        query: `pid=${patientDetails?.PatientGuid}&sortby=${claimsData.sortby}&q=${JSON.stringify(claimsData.filters)}`
      })
    );
  };
  ViewModel.mobileRowExpansionSkeleton = () => <MobileRowExpansionSkeleton />;
  ViewModel.paginatorLeft = () =>
    claimsData.totalPaid && (
      <ClaimsViewPaginatorLeft
        isTablet={isTablet}
        isMobile={isMobile}
        totalDue={claimsData.totalDue}
        totalPaid={claimsData.totalPaid}
        totalBilled={claimsData.totalBilled}
      />
    );

  // [KS] save current page size to localStorage
  // CMO-782 - Patient table navigation resets number of items on page after opening and closing patient's profile
  useEffect(() => {
    !showInCard &&
      !showOnPatientDetailsScreen &&
      claimsData.rows !== showInCardPageSize &&
      localStorage.setItem(localStorageKeys.invoicesPageSize, JSON.stringify(claimsData.rows));
  }, [claimsData.rows, showOnPatientDetailsScreen, showInCard]);

  // component will unmount
  useEffect(() => {
    return () => {
      dispatch(setDataTableExpandedRows({})); //[KS] CMO-1405 - Keeping claim's panel open by default
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const persitsReport = async (fileName) => {
    const result = await ClaimService.shareReport(DCNGuid, fileName);
    if (result) alert('Report shared');
    return false; // do not open any link
  };

  const exportDone = (e) => {
    if (e && e.LinkToCsv && growl) {
      growl?.current?.show({
        severity: 'info',
        summary: 'You data export is ready',
        sticky: true,
        detail: (
          <div>
            <a href={e.LinkToCsv} style={{ textDecoration: 'underline' }} target="_blank">
              Report (CSV)
            </a>
            <br />
            <a href={e.LinkToXls} style={{ textDecoration: 'underline' }} target="_blank">
              Report (Excel)
            </a>
            <br />
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                persitsReport(e.LinkToShare);
              }}
              style={{ textDecoration: 'underline' }}
            >
              Share on <strong>Daysheet Uploads</strong>
            </a>
          </div>
        )
      });
    }
  };

  const closeHostoryDialog = () => {
    setPopupVisible(false);
    setClaimHistory([]);
  };

  const onUpdatePHN = () => {
    //update patients list
  };

  const rowHistoryFooter = (
    <div>
      <Button label="Close" onClick={closeHostoryDialog} />
    </div>
  );

  // MOBILE VIEW - EXPANDED
  const rowExpansionTemplate = (data) => {
    return (
      <MobileRowExpansion
        data={data}
        isFetching={claimsData.isFetching}
        expandedRow={expandedRowMobile}
        actionButtonsParams={actionButtonsParams}
        setExpandedRow={setExpandedRowMobile}
        setHeader={setHeader}
        setClaimHistory={setClaimHistory}
        setPopupVisible={setPopupVisible}
        showOnPatientDetailsScreen={showOnPatientDetailsScreen}
      />
    );
  };

  // DESKTOP VIEW - EXPANDED REVIEW
  const rowExpansionTable = (data) => {
    return <RowExpantionTable data={data} isFetching={claimsData.isFetching} />;
  };

  const executeScroll = (refToRow) => {
    refToRow.scrollIntoView();
    // [VB]: using cookie CMO-74
    //removeLastRow('last_row');
    dispatch(removeDeepRow());
  };

  const onDeletePayment = () => {
    dispatch(
      getClaimList({
        page: claimsData.page,
        pageSize: claimsData.rows,
        filter: JSON.stringify(claimsData.filters),
        query: `pid=${patientDetails?.PatientGuid}&sortby=${claimsData.sortby}&q=${JSON.stringify(claimsData.filters)}`
      })
    );
  };

  const patientForBillingHistory = {
    PatientGuid: claimDataForAction?.PatientGuid,
    FirstName: claimDataForAction?.FirstName,
    LastName: claimDataForAction?.LastName,
    MidName: claimDataForAction?.MidName,
    BirthDay: claimDataForAction?.BirthDay,
    label: patientFullName(claimDataForAction)
  };

  const patientForSimilarClaims = {
    PatientGuid: claimDataForAction?.PatientGuid,
    FirstName: claimDataForAction?.FirstName,
    LastName: claimDataForAction?.LastName,
    MidName: claimDataForAction?.MidName,
    BirthDay: claimDataForAction?.BirthDay,
    label: patientFullName(claimDataForAction)
  };

  const onEditPatient = () => {
    dispatch(
      getClaimList({
        page: claimsData.page,
        pageSize: claimsData.rows,
        filter: JSON.stringify(claimsData.filters),
        query: `pid=${patientDetails?.PatientGuid}&sortby=${claimsData.sortby}&q=${JSON.stringify(claimsData.filters)}`
      })
    );
    onHideConfirmDialog(confirmDialogNames.editPatient);
  };

  return (
    <div className="form-group cm-editable" key={'clist'} id="claimsRoot">
      {(claimsData.isFetchingClaimHistory || claimsData.isSavingClaims || claimsData.isArchiving) && <CircularProgress />}

      {showInCard ? (
        <LegoView id="claims" {...ViewModel} />
      ) : (
        <SubMenu
          isMobile={isMobile}
          layoutMode={layoutMode}
          showOnPatientDetailsScreen={showOnPatientDetailsScreen}
          iPadProInLandscape={iPadProInLandscape}
        >
          <TabView>
            <TabPanel header={t('Invoices.1')} headerClassName={showOnPatientDetailsScreen ? '' : 'extra-large-text'}>
              <LegoView id="claims" {...ViewModel} />
            </TabPanel>

            <TabPanel header={t('Payments.1')} headerClassName={showOnPatientDetailsScreen ? '' : 'extra-large-text'}>
              <div className="pt-4 pl-4">Coming soon...</div>
              {/* <LegoView id="payments" {...PaymentsViewModel} /> */}
            </TabPanel>
          </TabView>
        </SubMenu>
      )}

      {!isLandscape && isTablet && !checked && <Toast ref={rotateDeviceToast} baseZIndex={1000000} />}

      <AddClaimNoteDialog
        rowData={claimDataForAction}
        visible={showAddClaimNoteDialog}
        onHide={() => onHideConfirmDialog(confirmDialogNames.addClaimNote)}
      />

      <Dialog
        contentClassName={isMobileOnly ? 'pb-0' : ''}
        header={popupHeader}
        footer={rowHistoryFooter}
        visible={popupVisible}
        onHide={closeHostoryDialog}
      >
        {RowClaimHistory(claim_history, isMobileOnly)}
      </Dialog>

      <DeleteConfirmation
        visible={deleteClaimConfirm}
        header={t('Delete_Claim')}
        message={String.format(t('Are_you_sure_you_want_to_delete_invoice'), claimDataForAction?.RecordNo, claimDataForAction?.FullName)}
        accept={onAcceptDeleteClaim}
        reject={() => onHideConfirmDialog(confirmDialogNames.delete)}
        onHide={() => onHideConfirmDialog(confirmDialogNames.delete)}
      />

      <DebitRequestDialog
        PatientGuid={patientDetails?.PatientGuid}
        claimRecord={claimDataForAction}
        showDebitConfirm={showDebitConfirm}
        setShowDebitConfirm={setShowDebitConfirm}
        setClaimDataForAction={setClaimDataForAction}
      />

      <CommonConfirmDialog
        visible={showArchiveConfirm}
        header={t('Archive_Claim')}
        bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claimDataForAction?.RecordNo, claimDataForAction?.FullName)}
        accept={() => {
          onArchiveClick(claimDataForAction, dispatch, patientDetails?.PatientGuid);
          onHideConfirmDialog(confirmDialogNames.archive);
        }}
        reject={() => onHideConfirmDialog(confirmDialogNames.archive)}
        onHide={() => onHideConfirmDialog(confirmDialogNames.archive)}
      />

      <CommonConfirmDialog
        visible={showArchiveEClaimConfirm}
        header={t('Archive_eClaim')}
        bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claimDataForAction?.RecordNo, claimDataForAction?.FullName)}
        accept={() => {
          onArchiveEClaim({ eClaim: claimDataForAction, patientGuid: patientDetails?.PatientGuid });
          onHideConfirmDialog(confirmDialogNames.archiveEClaim);
        }}
        reject={() => onHideConfirmDialog(confirmDialogNames.archiveEClaim)}
        onHide={() => onHideConfirmDialog(confirmDialogNames.archiveEClaim)}
      />

      <BatchRecordsWarnDialogForOnePatient
        data={claimDataForAction}
        visible={updatePatientDialog}
        showOnInvoicesPage={true}
        showOnPatientDetailsScreen={showOnPatientDetailsScreen}
        onHide={() => onHideConfirmDialog(confirmDialogNames.updatePatientProfile)}
      />

      <PaymenstDialog
        data={claimDataForAction}
        showPaymentsDialog={viewPaymentsDialog}
        setShowPaymentsDialog={setViewPaymentsDialog}
        onDeletePayment={onDeletePayment}
      />

      <PatientBillingHistoryDialog
        claim={claimDataForAction}
        visible={showBillingHistoryDialog}
        initPatient={patientForBillingHistory}
        patientsList={[patientForBillingHistory]}
        onHide={() => onHideConfirmDialog(confirmDialogNames.billingHistory)}
      />

      <SimilarClaimsListDialog
        claim={claimDataForAction}
        visible={showSimilarClaimsDialog}
        initPatient={patientForSimilarClaims}
        patientsList={[patientForSimilarClaims]}
        onHide={() => onHideConfirmDialog(confirmDialogNames.similarClaims)}
      />

      <Dialog
        header={`${t('Edit_Profile')}: ${patientFullNameWithAge(claimDataForAction)}`}
        visible={showEditPatientDialog}
        breakpoints={{
          '1800px': '60vw',
          '1700px': '65vw',
          '1600px': '70vw',
          '1500px': '75vw',
          '1400px': '80vw',
          '1300px': '87vw',
          '1200px': '95vw',
          '960px': '95vw'
        }}
        style={{ width: '55vw', minHeight: '40vh' }}
        onHide={() => onHideConfirmDialog(confirmDialogNames.editPatient)}
      >
        <div style={{ marginTop: '-1.5rem' }}>
          <PatientForm
            showInCard={false}
            showPrompt={false}
            showInDialog
            isEdit
            onClose={() => onHideConfirmDialog(confirmDialogNames.editPatient)}
            onEdit={onEditPatient}
          />
        </div>
      </Dialog>

      <Toast ref={growl} sticky={true} />
    </div>
  );
};

export default memo(ClaimView, isEqual);
