import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getPreferences } from '../../../../preferences/actions/preferences.action.creators';
import { bonusFeeCodes, NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { localStateInitValues } from '../helpers/teleplanInvoiceInitialState';
import { validationSchema } from '../helpers/validationSchema';
import { getDefaultValues, feeInit, initInvoiceType } from '../helpers/defaultValues';
import { inputs } from '../helpers/inputs';
import { useSubmit } from './useSubmit';
import { differenceBy, isEmpty } from 'lodash';
import { routes } from '../../../../../routes/routes';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { getCatalogCategories, getInvoicesCatalogMostRecentCodes, setCatalogCategories } from '../../../actions/claims.action.creators';
import { defaultUnits } from '../config/defaultValues';
import { isSupplementary } from '../../../../config/specialitiesConfig';
import { getPatientInfo, getPrefs, getRecentCodes } from '../../../../../service/Lookup';
import { getInitialValuesForEdit } from '../helpers/getInitialValuesForEdit';
import { getPractitionerIdFromUserPrefs } from '../../../../utils/getPractitioner';
import { getPromiseAll } from '../../../../utils/getPromiseAll';
import { getMostRecentCodes } from '../helpers/getMostRecentCodes';
import { addMissingCodesToRecentList } from '../helpers/updateRecentCodesList';
import { v4 as uuidv4, validate } from 'uuid';

export const useDefaultValues = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { teleplanInvoice } = useSelector((state) => state.claims);
  const id = params.id;
  const isNew = location.pathname.includes('new');
  const firstVisit = location.pathname.includes('first-visit');
  const pos = location.pathname.includes(routes.teleplanPOS.path);
  const isGroup = location.pathname.includes(routes.teleplanGroup.path);

  // This condition should match with media query for mobile view in EClaim.scss
  // window.innerWidth < 1024 is corresponds to @media screen and (max-width: 1023px)
  const isMobile = window.innerWidth < 1024;

  const [localState, setLocalState] = useState(localStateInitValues);

  const methods = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema({ isNew, firstVisit, isGroup, step: localState.step }))
  });

  const errors = methods.formState.errors;
  const isDirty = methods.formState.isDirty;

  useEffect(() => {
    // Edit invoice
    if (!isNew) {
      return getInitialValuesForEdit({ invoiceGuid: params.id, setLocalState, reset: methods.reset });
    }

    // Create invoice
    if (isNew) {
      // Empty form
      if (isEmpty(teleplanInvoice.formState)) return setEmptyForm();

      // Pre-filled form
      if (!isEmpty(teleplanInvoice.formState)) return setPrefilledForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setEmptyForm = async (params = {}) => {
    const { usePatientId = true, isFirstVisit = false } = params;
    const patientId = usePatientId ? id : null;
    const firstVisitValue = isFirstVisit || firstVisit;

    // Set form to dirty if patient is prefilled
    patientId && methods.setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });
    const practitionerId = getPractitionerIdFromUserPrefs();

    // Reset the form state, indicating whether it's the first visit
    firstVisitValue && resetFormState({ isFirstVisit: firstVisitValue });

    // Prepare requests for patient information and preferences
    const patientInfoRequest = patientId ? { patientInfo: getPatientInfo(patientId) } : {};
    const requests = {
      ...patientInfoRequest,
      recentCodes: getRecentCodes(patientId),
      prefs: getPrefs(user.DCNGuid, practitionerId)
    };

    // Loading for recent codes
    setLocalState((prevState) => ({ ...prevState, loading: true, gettingRecentCodes: true }));

    // Execute all requests concurrently and merge results into a single object
    const results = await getPromiseAll(requests);

    // Extract practitioner preferences, patient info, and recent codes
    const practitionerPrefs = results?.prefs?.prefs?.find((i) => i.label === 'default') || {};
    const patientInfo = patientId ? [results.patientInfo] : [];

    // Initialize form values and state
    const initValues = getDefaultValues({
      currentPractitionerPrefs: practitionerPrefs,
      firstVisit: firstVisitValue,
      isGroup,
      isNew
    });
    const initState = { ...initValues, [inputs.patient.name]: patientInfo };
    const practitionerGuid = initState[inputs.practitioner.name];
    const speciality = initState[inputs.speciality.name];
    const isValidPractitioner = validate(practitionerGuid);

    // CMO-3067 - Teleplan - get catalogs for N/A practitoner - NaN in the api request
    if (practitionerGuid !== NA_DoctorGuid) {
      // CMO-2980 - Change fee catalogs when the user changes the profile in Teleplan form
      const categoriesResult = await getCatalogCategories(speciality);
      if (categoriesResult) setCatalogCategories(categoriesResult);
    }

    // Reset the form with initial values
    methods.reset(initState, { keepDirty: true });

    const listOfPatients = patientId ? [{ PatientGuid: patientId, RecentCodes: results.recentCodes }] : [];

    // Update resent codes list
    addMissingCodesToRecentList({ formState: initState, recentCodesList: results.recentCodes });

    // Update local state with practitioner preferences and list of patients
    setLocalState((prevState) => ({
      ...prevState,
      practitionerPrefs,
      listOfPatients,
      isEmptyPractitioner: !isValidPractitioner // Hide inputs if selected practitioner is N/A
    }));

    // Loading for recent codes
    setLocalState((prevState) => ({ ...prevState, loading: false, gettingRecentCodes: false }));
  };

  const setPrefilledForm = () => {
    const patient = teleplanInvoice.formState[inputs.patient.name][0];

    setLocalState((prevState) => ({ ...prevState, resetTeleplanInvoice: true }));

    // Get most recent catalog codes
    getMostRecentCodes(teleplanInvoice.formState, { patientGuid: patient?.PatientGuid, setLocalState });

    // Get preferences for current practitioner
    if (isEmpty(teleplanInvoice.localState.practitionerPrefs)) {
      const practitionerGuid =
        teleplanInvoice.formState[inputs.practitioner.name] === NA_DoctorGuid ? user.DCNGuid : teleplanInvoice.formState[inputs.practitioner.name];

      // Create new teleplan from patient profile
      dispatch(
        getPreferences(user.DCNGuid, practitionerGuid, (response) => {
          const practitionerPrefs = response?.find((i) => i.label === 'default');
          if (isGroup) addPatientForGroupTable(teleplanInvoice.formState, practitionerPrefs);
          setLocalState((prevState) => ({ ...prevState, practitionerPrefs }));
          methods.reset({
            ...getDefaultValues({ currentPractitionerPrefs: practitionerPrefs, isNew }),
            [inputs.patient.name]: teleplanInvoice.formState[inputs.patient.name]
          });
        })
      );
    } else {
      // Create new teleplan when user click on add new patient and goes back
      methods.reset(teleplanInvoice.formState);

      if (isGroup) {
        addPatientForGroupTable(teleplanInvoice.formState, teleplanInvoice.localState.practitionerPrefs);
      } else {
        setLocalState(teleplanInvoice.localState);
      }

      // CMO-2315 - New Teleplan POS -> after used added a patient from this screen, the screen status remains clean
      methods.setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });
    }
  };

  const addPatientForGroupTable = (invoice, preferences) => {
    const prefs = preferences.content;
    const speciality = Number(invoice[inputs.speciality.name]) || 0;
    const records = teleplanInvoice.localState.groupRecords;
    const currentListOfPatients = teleplanInvoice.localState.listOfPatients;
    const newPatient = differenceBy(teleplanInvoice.formState[inputs.patient.name], currentListOfPatients, 'PatientGuid')[0];

    if (!newPatient) return setLocalState(teleplanInvoice.localState);

    const initialInvoiceType = initInvoiceType({ preferences: prefs, isGroup });
    const isSupplemental = isSupplementary(speciality, initialInvoiceType);
    const currentFeeCodes = invoice[inputs.feeCodes.codeType];

    const feeCodes = isNew && isSupplemental ? feeInit(speciality)[inputs.feeCodes.codeType] : currentFeeCodes;

    const dxCodes = () => {
      if (teleplanInvoice.localState.applyToAllDx) {
        const firstItemNotInBonusCodes = records?.find((i) => {
          const feeCode = i[inputs.feeCodes.codeType][0].value;
          return !bonusFeeCodes.includes(feeCode);
        });

        return {
          [inputs.icd9.name]: firstItemNotInBonusCodes[inputs.icd9.name] || [],
          [inputs.icd9.codeDescription]: firstItemNotInBonusCodes[inputs.icd9.codeDescription] || [],
          [inputs.icd9.codeType]: firstItemNotInBonusCodes[inputs.icd9.codeType] || []
        };
      }

      return {
        [inputs.icd9.name]: [],
        [inputs.icd9.codeDescription]: [],
        [inputs.icd9.codeType]: []
      };
    };

    let updatedGroupRecords = records;
    const recordWithNewPatient = {
      ...methods.watch(),
      ...dxCodes(),
      [inputs.patient.name]: [newPatient],
      [inputs.units.name]: defaultUnits
    };

    const listOfPatients = teleplanInvoice.formState[inputs.patient.name]?.map((i) => {
      const recentCodes = teleplanInvoice.localState.listOfPatients.find((x) => x.PatientGuid === i.PatientGuid)?.RecentCodes;
      return { PatientGuid: i[inputs.patientGuid.name], RecentCodes: recentCodes || [] };
    });

    const updatedRecords = [];

    if (feeCodes?.length) {
      for (const code of feeCodes) {
        const updatedRecord = {
          ...recordWithNewPatient,
          [inputs.groupRowId.name]: uuidv4(),
          [inputs.feeCodes.name]: [code.value],
          [inputs.feeCodes.codeDescription]: [code.label],
          [inputs.feeCodes.codeType]: [code]
        };

        if (listOfPatients.length === 1) {
          if (bonusFeeCodes.includes(code.value)) {
            updatedRecords.unshift(updatedRecord);
          } else {
            updatedRecords.push(updatedRecord);
          }
        } else {
          if (!bonusFeeCodes.includes(code.value)) updatedRecords.push(updatedRecord);
        }
      }
    }

    updatedGroupRecords = [...records, ...updatedRecords];

    setLocalState({
      ...teleplanInvoice.localState,
      listOfPatients,
      groupRecords: updatedGroupRecords
    });

    // Get most recent catalog codes
    getMostRecentCodesForGroup(newPatient);
  };

  const getMostRecentCodesForGroup = (patient) => {
    dispatch(
      getInvoicesCatalogMostRecentCodes({
        loading: false,
        updateRedux: false,
        patientGuid: patient.PatientGuid,
        callback: (mostRecentCodes) => {
          setLocalState((prevState) => {
            const updatedList = prevState.listOfPatients.map((i) => {
              if (i.PatientGuid === patient.PatientGuid) return { ...i, RecentCodes: mostRecentCodes };
              return i;
            });

            const updatedGroupRecords = prevState.groupRecords.map((i) => {
              if (
                i[inputs.patient.name] &&
                (i[inputs.patient.name][0]?.PatientGuid === patient.PatientGuid || i[inputs.patient.name]?.PatientGuid === patient.PatientGuid)
              ) {
                return {
                  ...i,
                  [inputs.patient.name]: [{ ...patient, [inputs.recentCodes.name]: mostRecentCodes }],
                  [inputs.units.name]: defaultUnits,
                  [inputs.groupRowId.name]: uuidv4()
                };
              }

              return i;
            });

            return {
              ...prevState,
              groupRecords: updatedGroupRecords,
              listOfPatients: updatedList
            };
          });
        }
      })
    );
  };

  const resetFormState = (props = {}) => {
    const { initValues = {}, practitionerPrefs, isFirstVisit = false, isGroupView = false, keepDirty = false } = props;
    const initState = getDefaultValues({
      initValues: initValues,
      currentPractitionerPrefs: practitionerPrefs || localState.practitionerPrefs,
      firstVisit: isFirstVisit,
      isGroup: isGroupView,
      isNew
    });

    methods.reset(initState);
    addMissingCodesToRecentList({ formState: initState });

    // Hack to keep dirty first step
    keepDirty && methods.setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });
  };

  const resetForm = ({ isFirstVisit = false } = {}) => {
    // Reset form state to avoid displaying old data until setEmptyFrom async function will be completed
    methods.reset();

    // Reset local states and save current practitioner prefs
    setLocalState(localStateInitValues);

    // Async reset form state
    setEmptyForm({ usePatientId: false, isFirstVisit });

    // Get recent codes list
    getMostRecentCodes(methods.watch(), { setLocalState });
  };

  const status = Number(methods.watch(inputs.status.name));

  // [KS] CMO-992 - Rename buttons on Edit of the Declined and Archived Claim screen
  const declinedStatus = status === 3 || status === -3 || status === 6 || status === 9;
  const isUnlimitedEntriesForFee = isNew;
  const buttonActions = useSubmit({ ...methods, localState, setLocalState, declinedStatus, isNew, isGroup, errors });

  return {
    pos,
    isNew,
    isGroup,
    isMobile,
    firstVisit,
    declinedStatus,
    errors,
    isDirty,
    localState,
    setLocalState,
    resetFormState,
    resetForm,
    isUnlimitedEntriesForFee,
    ...buttonActions,
    ...methods
  };
};
