import React from 'react';
import CatalogsHeader from '../Modules/Header/CatalogsHeader';
import TabsView from '../Modules/Tabs/TabsView';

const DesktopCatalogs = () => {
  return (
    <>
      <CatalogsHeader />
      <TabsView />
    </>
  );
};

export default DesktopCatalogs;
