import { commonInputs } from '../../../../../config/commonInputsConfig';
import { inputs } from '../../claimDetailsHelpers/inputs';

export const catalogsRolodex = () => {
  let commonItems = [
    {
      name: inputs().mostRecent.name,
      label: inputs().mostRecent.label,
      codeType: inputs().mostRecent.codeType
    },

    {
      name: inputs().privateService.name,
      label: inputs().privateService.label,
      codeType: inputs().privateService.codeType,
      descriptionName: inputs().privateService.descriptionName,
      maxEntries: inputs().privateService.maxEntries
    }
  ];

  return commonItems;
};

export const mostRecent = () => {
  let commonItems = [
    {
      name: commonInputs.serviceDate.name,
      label: commonInputs.serviceDate.label,
      codeType: commonInputs.serviceDate.codeType,
      showDivider: true,
      required: true
    },
    {
      name: inputs().privateService.name,
      label: inputs().privateService.label,
      codeType: inputs().privateService.codeType,
      descriptionName: inputs().privateService.descriptionName,
      maxEntries: inputs().privateService.maxEntries,
      showDivider: false,
      required: true,
      viewMoreButton: true
    }
  ];

  return commonItems;
};
