import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';
import { inputs } from '../../helpers/inputs';
import { formatDate } from '../../../../../utils/formatDate';
import { steps } from '../../helpers/steps';

const TimeGoesOverMidnightDialog = () => {
  const { watch, localState, setLocalState } = useFormContext();

  const onHideOverMidnightDialog = () => {
    setLocalState((prevState) => ({ ...prevState, isTimeOverMidnightDialog: false }));
  };

  const onNext = () => {
    onHideOverMidnightDialog();
    // Go to dx group step
    setLocalState((prevState) => ({ ...prevState, step: steps.groupDxPicker }));
  };

  const overMidnightDialogFooter = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Ignore_and_Proceed')} onClick={onNext} autoFocus />

        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('Cancel')} onClick={onHideOverMidnightDialog} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.timeGoesOverMidnightDialog}
      header={t('Warning')}
      visible={localState.isTimeOverMidnightDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={overMidnightDialogFooter()}
      onHide={onHideOverMidnightDialog}
    >
      {String.format(t('We_detected_a_case_of_service_that_might_have_happened_on_the_next_day'), formatDate(watch(inputs.serviceDate.name)))}
    </Dialog>
  );
};

export default TimeGoesOverMidnightDialog;
