import { t } from '../../service/localization/i18n';

export const stautsLabels = {
  outstanding: t('Outstanding'),
  inProcess: t('In Process'),
  onHold: t('On_Hold'),
  inProcessDebit: t('In_Process_Debit'),
  accepted: t('Accepted'),
  rebilled: t('Rebilled'),
  declined: t('Declined'),
  adjusted: t('Adjusted'),
  draft: t('Draft'),
  archived: t('Archived'), // statuses: 9, -3
  error: t('Error'),
  paid: t('Paid'),
  notPaid: t('Not Paid'),
  processed: t('Processed'),
  drUser: t('DR User'),
  drTeleplan: t('DR Teleplan'),
  acknowledgment: t('Acknowledgment')
};

export const statuses = {
  '-1': stautsLabels.archived,
  '-3': stautsLabels.archived,
  0: stautsLabels.outstanding,
  1: stautsLabels.inProcess,
  2: stautsLabels.paid,
  3: stautsLabels.declined,
  4: stautsLabels.draft,
  6: stautsLabels.notPaid,
  9: stautsLabels.archived,
  10: stautsLabels.error,
  17: stautsLabels.error,
  21: stautsLabels.rebilled,
  104: stautsLabels.onHold,
  200: stautsLabels.adjusted,
  201: stautsLabels.drUser,
  202: stautsLabels.drTeleplan,
  203: stautsLabels.inProcessDebit,
  666: stautsLabels.acknowledgment
};

export const activePractitionerStatuses = [0, 1, 2];
