/* eslint-disable import/no-anonymous-default-export */

// DONT FORGET TO RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo", "Demo" or "DEMO" prefix

export default {
  IS_FETCHING_DEMOS: 'IS_FETCHING_DEMOS',
  FETCH_DEMOS_DATA_SUCCESS: 'FETCH_DEMOS_DATA_SUCCESS',
  SET_DEMO_DETAILS: 'SET_DEMO_DETAILS',
  FILTER_BY_PATIENT_FOR_DEMOS: 'FILTER_BY_PATIENT_FOR_DEMOS',
  SET_DEMO_ITEM_INDEX: 'SET_DEMO_ITEM_INDEX'
};
