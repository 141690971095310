import actions from './clinic.action.types';
import axios from 'axios';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase, setAuthHeader } from '../../../config';
import { addFullAddress, reformatMembers } from './responseAction';
import clinic from '../../../mocks/db/clinic/clinic.json';
import clinicFilters from '../../../mocks/db/clinic/clinicFilters.json';
import clinicLocations from '../../../mocks/db/clinic/clinicLocations.json';
import { store } from '../../../store';

export const getClinicInfo = async (dcnguid) => {
  if (process.env.REACT_APP_CYPRESS) {
    return Promise.resolve(addFullAddress(clinic.details));
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}`);
    return addFullAddress(results?.data);
  } catch (error) {
    console.log('!!!getClinicInfo', error);
  }
};

export const getMembers = async (dcnguid) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return Promise.resolve(reformatMembers(clinic.members));
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}/users`);
    return reformatMembers(results.data.List);
  } catch (error) {
    console.log('!!!getMembers', error);
  }
};

export const getLocations = async (dcnguid) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    const mockedLocations = clinicLocations.map((i) => addFullAddress(i));
    return Promise.resolve(mockedLocations);
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}/list`);
    const locations = results.data.Offices.map((i) => addFullAddress(i));
    const rooms = results.data.Rooms;
    return { locations, rooms };
  } catch (error) {
    console.log('!!!getLocations', error);
  }
};

export const getFilters = async (dcnguid) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return Promise.resolve(clinicFilters);
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/clinic/${dcnguid}/filters`);
    return results;
  } catch (error) {
    console.log('!!!getFilters', error);
  }
};

export const editClinic = (data, callback) => (dispatch, state) => {
  setAuthHeader(state);
  dispatch({ type: actions.CLINIC_EDITING, results: true });

  axios
    .put(`${getApiBase()}/clinic/${data.DCNGuid}`, data)
    .then((results) => {
      dispatch({ type: actions.CLINIC_EDITING, results: false });
      const formattedResuts = addFullAddress(results?.data);
      callback && callback(formattedResuts);
      return dispatch({
        type: actions.FETCH_CLINIC_SUCCESS,
        results: formattedResuts
      });
    })
    .catch((reason) => {
      dispatch({ type: actions.CLINIC_EDITING, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const editPractitioner = (data, callback) => (dispatch, state) => {
  const currentState = state();
  const members = currentState.clinic.members;
  const speciality = currentState.user.details.Speciality;

  setAuthHeader(state);
  delete data.FullAddress;
  delete data.PayeeNumbers;

  dispatch({ type: actions.PRACTITIONER_EDITING, results: true });

  axios
    .put(`${getApiBase()}/users/${data.DCNGuid}/${data.DoctorGuid}`, data)
    .then((results) => {
      dispatch({ type: actions.PRACTITIONER_EDITING, results: false });
      const formattedResuts = addFullAddress(results?.data);
      const updateMembers = members.map((i) => (i.DoctorGuid === results.data.DoctorGuid ? results.data : i));
      const reformatted = reformatMembers(updateMembers, speciality);

      callback && callback(formattedResuts);

      return dispatch({
        type: actions.FETCH_MEMBERS_SUCCESS,
        results: reformatted
      });
    })
    .catch((reason) => {
      dispatch({ type: actions.PRACTITIONER_EDITING, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const setClinicInfo = (data) => {
  return store.dispatch({
    type: actions.FETCH_CLINIC_SUCCESS,
    results: data
  });
};

export const setMembers = (membersList) => {
  return store.dispatch({
    type: actions.FETCH_MEMBERS_SUCCESS,
    results: membersList
  });
};

export const setLocations = (locations = []) => {
  return store.dispatch({
    type: actions.FETCH_LOCATIONS_SUCCESS,
    results: locations
  });
};

export const setRooms = (rooms = []) => {
  return store.dispatch({
    type: actions.FETCH_ROOMS_SUCCESS,
    results: rooms
  });
};

export const setFilters = (filters) => {
  return store.dispatch({
    type: actions.FETCH_CLINIC_FILTERS_SUCCESS,
    results: filters
  });
};
