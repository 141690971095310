//if filters take label as values rather than status values and input field is dropdown, use setDropdownValueWithKeyboard()
//example:
// statuses - [{ value: '1', label: t("In Process"), cloudTag: t("In Process") }]
// filters - {StatusText: "In Process"}
export const setDropdownValueWithKeyboard = (e, statuses) => {
  const value =
    e.originalEvent.code === 'ArrowDown' || e.originalEvent.code === 'ArrowUp'
      ? statuses.find((i) => i.value === e.target.value).label
      : e.originalEvent.target.textContent;

  return value;
};
