import React, { useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import HighlightSearchQuery from '../../../../Misc/HighlightSearchQuery/HighlightSearchQuery';

import { useTable } from '../../hooks/useTable';
import { usePatientBillingHistoryContext } from '../../hooks/usePatientBillingHistoryContext';
import { patientFullNameWithNicknameAndAge } from '../../../../../modules/utils/patientFullName';
import { t } from '../../../../../service/localization/i18n';

const PatientFilter = () => {
  const { fetchData } = useTable();
  const { state, setState } = usePatientBillingHistoryContext();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);

  const search = (e) => {
    setQuery(e.query);
    let _filtered;
    if (!e.query.trim().length) {
      setIsSuggestionsFound(true);
      _filtered = [...state.patientsList];
    } else {
      const criteria = e.query.toLowerCase();
      _filtered = state.patientsList.filter((i) => {
        return (
          i.FirstName?.toLowerCase().startsWith(criteria) ||
          i.LastName?.toLowerCase().startsWith(criteria) ||
          i.MidName?.toLowerCase().startsWith(criteria) ||
          i.PHN?.toLowerCase().startsWith(criteria)
        );
      });
      const isFound = _filtered?.length;
      setIsSuggestionsFound(isFound);
    }

    setSuggestions(_filtered);
  };

  const itemTemplate = (data) => {
    const phn = data.PHN || t('N_A');
    return (
      <>
        <div className="flex">
          <HighlightSearchQuery query={query} text={patientFullNameWithNicknameAndAge(data)} />
        </div>
        <div className="flex gap-1">
          {`${'PHN'}:`}
          <HighlightSearchQuery query={query} text={phn} />
        </div>
      </>
    );
  };

  const onChange = (patient) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        patient
      }
    }));

    const patientGuid = patient?.PatientGuid;
    patientGuid && patientGuid?.toLowerCase() !== state.PatientGuid?.toLowerCase() && fetchData({ patientGuid });
  };

  return (
    <div className="flex flex-column">
      <AutoComplete
        dropdown
        autoHighlight
        field="label"
        value={state.filters.patient}
        suggestions={suggestions}
        completeMethod={search}
        itemTemplate={itemTemplate}
        onChange={(e) => onChange(e.value)}
      />
      {!isSuggestionsFound && <span className="p-error text-sm">{t('No_results_found')}</span>}
    </div>
  );
};

export default PatientFilter;
