export const getCodeDescriptionOnly = (description) => {
  const regex = /\s*(?:\bas\b|:)\s*/;
  const suspect = regex.test(description);
  if (suspect) {
    return description.split(/\s*(?:\bas\b|:)\s*/)[1];
  } else return description;
};

//for AOI and NOI only one code is allowed so we can use Array[0] safely
export const formatCodeDescription = (codes, descriptions, part, index = 0) => {
  if (codes && descriptions) {
    if (Array.isArray(codes) && Array.isArray(descriptions)) {
      // when user selected code from the list - either quick pick or dropdown
      const code = codes[index];
      const description = descriptions[index];
      const text = description.indexOf(code) === 0 ? description : `${code}:${description}`;
      const tokens = text.split(':');
      return tokens[part];
    } else {
      // prefilled on page load
      return part === 0 ? codes : descriptions;
    }
  }
  return null;
};
