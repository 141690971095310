import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const ConflictsBetweenGroupAndIndividualTimesDialog = () => {
  const { localState, setLocalState, proceedToGroupServices } = useFormContext();

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, conflictsBetweenGroupAndIndividualTimesDialog: false }));
  };

  const onAccept = () => {
    onHideDialog();
    proceedToGroupServices();
  };

  const dialogFooter = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onAccept} autoFocus />

        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onHideDialog} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.conflictsBetweenGroupAndIndividualTimesDialog}
      header={t('Warning')}
      visible={localState.conflictsBetweenGroupAndIndividualTimesDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={dialogFooter()}
      onHide={onHideDialog}
    >
      {t('The_Group_Start_and_End_Time_will_override_individual_times')}
    </Dialog>
  );
};

export default ConflictsBetweenGroupAndIndividualTimesDialog;
