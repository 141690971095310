import React from 'react';
import TextAreaInput from './Common/TextAreaInput';
import { inputs } from '../../../helpers/inputs';

const Restrictions = () => {
  return (
    <div className="pb-2">
      <TextAreaInput input={inputs.restrictions} maxLength={240} rows={1} />
    </div>
  );
};

export default Restrictions;
