export const getExactMatchesFirst = (searchQuery, results = [], key = 'value') => {
  if (!searchQuery) return results;

  const searchQueryLower = searchQuery.toLowerCase();

  const exactMatches = results.filter((result) => result[key].toLowerCase().startsWith(searchQueryLower));

  const otherMatches = results.filter(
    (result) => !result[key].toLowerCase().startsWith(searchQueryLower) || result[key].toLowerCase().indexOf(`.${searchQueryLower}`) > -1
  );

  return [...exactMatches, ...otherMatches];
};
