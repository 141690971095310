import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidation } from '../../../hooks/useValidation';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const GroupPreviewActionButtons = () => {
  const history = useHistory();
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { showSubmitButton } = useValidation();
  const { onCreateGroupInvoices, setLocalState } = useFormContext();

  const onBack = () => {
    setLocalState((prevState) => ({ ...prevState, step: prevState.prevStep }));
  };

  return (
    <div className="stickyActionButtons py-3 w-full">
      <Button
        id={elementIDs.create}
        type="button"
        className="teleplan_preview_action_button"
        // className={cx('teleplan_preview_action_button', showSubmitButton ? 'p-button-outlined' : '')}
        loading={isSavingClaims}
        label={t('Create')}
        onClick={() => onCreateGroupInvoices(false)}
      />

      {showSubmitButton && (
        <Button
          id={elementIDs.create}
          type="button"
          className="teleplan_preview_action_button p-button-outlined"
          loading={isSavingClaims}
          label={t('Submit')}
          onClick={() => onCreateGroupInvoices(true)}
        />
      )}

      <Button id={elementIDs.back} type="button" className="p-button-outlined teleplan_preview_action_button" label={t('Back')} onClick={onBack} />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined teleplan_preview_action_button"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default GroupPreviewActionButtons;
