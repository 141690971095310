import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPatients } from '../../../actions/patients.action.creators';
import { InputSwitch } from 'primereact/inputswitch';

const IsDeceasedFilter = () => {
  const currentField = 'IsDeceased';

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.patients);
  const [value, setValue] = useState(filters[currentField] || '');

  // Update local state when chip is deleted
  useEffect(() => {
    setValue(filters[currentField]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[currentField]]);

  const onChange = (e) => {
    const _value = e.value;
    const updatedFilters = { ...filters, [currentField]: _value };
    setValue(_value);
    dispatch(getPatients({ filters: updatedFilters, page: 0 }));
  };

  return <InputSwitch checked={value} onChange={onChange} />;
};

export default IsDeceasedFilter;
