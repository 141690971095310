import { getPatientClaimData } from '../../../actions/claims.action.creators';
import { inputs } from './inputs';
import { updateInitialStateWithClaimData } from './updateInitialState';
import { store } from '../../../../../store';

export const getPatientClaimsData = ({ patientGuid, errors, clearErrors, setValue }) => {
  store.dispatch(
    getPatientClaimData(patientGuid, (patientClaims) => {
      // Update initial state
      if (patientClaims?.WCB?.length > 0) {
        const WSBCClaim = patientClaims.WCB[0];

        setValue(inputs.patientClaims.name, patientClaims);
        return updateInitialStateWithClaimData({
          claim: WSBCClaim,
          errors,
          clearErrors,
          setValue
        });
      }

      if (patientClaims?.WCB?.length === 0) {
        setValue(inputs.claimNumber.name, ''); // reset claim number
        updateInitialStateWithClaimData({ errors, clearErrors, setValue }); // reset initial data (Injury Date, AOI, NOI and Anatomic Code)
        return;
      }
    })
  );
};
