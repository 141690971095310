import React from 'react';
import { useFormContext } from 'react-hook-form';

import InjuryDate from '../../modules/TeleplanInvoiceForm/Inputs/InjuryDate';
import ClaimNumber from '../../modules/TeleplanInvoiceForm/Inputs/ClaimNumber';
import AnatomicCode from '../../modules/TeleplanInvoiceForm/Inputs/AnatomicCode';

import { inputs } from '../../helpers/inputs';
import { invoiceTypes } from '../../helpers/defaultValues';

export const PayorOptionalSectionPOS = () => {
  const { watch } = useFormContext();

  const claimNumber = <ClaimNumber />;

  const wsbcLayout = (
    <>
      {claimNumber}
      <InjuryDate />
      <AnatomicCode />
    </>
  );

  const layout = () => {
    const invoiceType = watch(inputs.payor.name);
    if (invoiceType === invoiceTypes.wsbc) return wsbcLayout;
    if (invoiceType === invoiceTypes.icbc) return claimNumber;
    return null;
  };

  return <>{layout()}</>;
};

export default PayorOptionalSectionPOS;
