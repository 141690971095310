import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { concat, differenceBy, filter, find, flatMap, get, includes, intersectionBy, isArray, map, omit, some } from 'lodash';
import { bonusFeeCodes, bonusFeeCodesOnly, icd9CodeMaxEntry, referralCodeMaxEntry } from '../../../../../../config/defaultValuesConfig';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { isTimeValid, timeDiff } from '../../../../../../utils/formatTime';
import { defaultUnits } from '../../../config/defaultValues';
import { useSettings } from './useSettings';
import { inputs } from '../../../helpers/inputs';
import { calculateUnits } from '../../../helpers/calculateUnits';
import { dxCodeL23 } from '../../../../../../config/codes';
import { useValidateGoupPicker } from './useValidateGroupPicker';
import { getReferralValueByFeeCode } from '../../../helpers/validationSchema';
import { getCategoryType } from '../../../../../helpers/getCategoryType';
import { bmiSurgicalAssistCodes } from '../../../helpers/codesCheckList';
import { addMissingCodesToRecentList } from '../../../helpers/updateRecentCodesList';
import { isCodeRecentlyUsed } from '../../../../../../utils/isCodeRecentlyUsed';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';

export const useGroupPickerInputs = () => {
  const dispatch = useDispatch();
  const { watch, localState, setLocalState, setValue } = useFormContext();
  const {
    setRequiredInput,
    isRequiredInput,
    isCommentRequired,
    isRecordDuplicated,
    setAllRequiredInputs,
    getAllDuplicatedRecords,
    requiredInputsForDuplicateRecord,
    updatedValuesForDuplicateRecord
  } = useValidateGoupPicker();
  const { isConsecutiveTime } = useSettings();

  const onCommentChange = (comment, rowOptions) => {
    setLocalState((prevState) => {
      // Create a copy of the updated records array with the comment changed
      const updatedRecords = prevState.groupRecords.map((record, index) => {
        if (index === rowOptions.rowIndex) {
          return { ...record, [inputs.comment.name]: comment };
        }
        return record;
      });

      // If the comment has a length greater than 1, update only the records array
      if (comment?.length > 1 && rowOptions.rowData[inputs.comment.name]?.length) {
        return { ...prevState, groupRecords: updatedRecords };
      } else {
        // If the comment is empty or has length 1, update both records and required inputs
        const rowId = rowOptions.rowData[inputs.groupRowId.name];
        const isRequired = isRequiredInput(rowId, inputs.comment.name) || isCommentRequired({ rowData: rowOptions.rowData });
        const message = !comment?.length && isRequired ? t('Mandatory_field.1') : '';

        // Update the required inputs for the specific row
        const groupRecordsRequiredInputs = {
          ...prevState.groupRecordsRequiredInputs,
          [rowId]: {
            ...prevState.groupRecordsRequiredInputs[rowId],
            [inputs.comment.name]: {
              required: isRequired,
              errorMessage: message,
              showAsterisk: isRequired
            }
          }
        };

        // Return the updated state with both records and required inputs
        return { ...prevState, groupRecords: updatedRecords, groupRecordsRequiredInputs };
      }
    });
  };

  const onUnitsChange = (units, rowIndex) => {
    setLocalState((prevState) => {
      const updatedRecords = prevState.groupRecords.map((record, index) => {
        if (index === rowIndex) return { ...record, [inputs.units.name]: units };
        return record;
      });

      return { ...prevState, groupRecords: updatedRecords };
    });
  };

  const onTimeChange = (time, inputName, rowOptions, groupRecords, groupRecordsRequiredInputs) => {
    const updatedRecords = [];
    const endKey = inputs.endTime.name;
    const starKey = inputs.startTime.name;
    const durationKey = inputs.duration.name;
    const rowIndex = rowOptions.rowIndex;
    const isValidTime = isTimeValid(time);
    const groupRecordsList = groupRecords || localState.groupRecords;
    const requiredInputs = groupRecordsRequiredInputs || localState.groupRecordsRequiredInputs;

    // Reset start and fimish time on first step if any time is changed in the group time picker
    watch(starKey) && setValue(starKey, '');
    watch(endKey) && setValue(endKey, '');

    // detect if changes happened due to removed record
    const isRowDeleted = !rowOptions.rowData;

    for (let i = 0; i < groupRecordsList.length; i++) {
      const record = groupRecordsList[i];
      const currentUnits = record[inputs.units.name];
      const feeCode = record[inputs.feeCodes.codeType][0];
      const duration = record[durationKey] || 0;

      if (i < rowIndex) {
        updatedRecords.push(groupRecordsList[i]);
      }

      if (i === rowIndex && !isRowDeleted) {
        // Start time
        if (inputName === starKey) {
          if (isValidTime) {
            const endTime = duration ? addDuration(time, duration) : '';
            const units = calculateUnits(feeCode, time, endTime, currentUnits);
            updatedRecords.push({ ...record, [inputName]: time, [endKey]: endTime, [inputs.units.name]: units });
          } else {
            updatedRecords.push({ ...record, [inputName]: time });
          }
        }

        // End time
        if (inputName === endKey) {
          const units = calculateUnits(feeCode, record[starKey], time, currentUnits);
          const calculatedDuration = timeDiff(record[starKey], time);
          updatedRecords.push({
            ...record,
            [inputName]: time,
            [inputs.units.name]: units,
            [durationKey]: calculatedDuration
          });
        }
      }

      if (i > rowIndex || (i === rowIndex && isRowDeleted)) {
        const prevRowEndTime = updatedRecords[i - 1][endKey];
        const currentRowRecord = groupRecordsList?.[i];
        const currentRowUnits = currentRowRecord[inputs.units.name];
        const currentRowEndTime = duration ? addDuration(prevRowEndTime, duration) : '';
        const currentRowFeeCode = currentRowRecord?.[inputs.feeCodes.codeType]?.[0];
        const currentRowFeeCodeOnly = currentRowFeeCode?.length ? currentRowFeeCode?.value : undefined;

        // if (isConsecutiveTime) {
        //   if (prevRowEndTime && isValidTime && !bonusFeeCodes.includes(currentRowFeeCode)) {
        //     const units = calculateUnits(currentRowFeeCode, prevRowEndTime, currentRowEndTime, currentUnits);
        //     updatedRecords.push({ ...record, [starKey]: prevRowEndTime, [endKey]: currentRowEndTime, [inputs.units.name]: units });
        //   } else {
        //     updatedRecords.push(groupRecordsList[i]);
        //   }
        // } else {
        //   updatedRecords.push(groupRecordsList[i]);
        // }

        // isConsecutiveTime for CMO-3027 - Batch->allow user to turn off consecutive timing
        if (!isConsecutiveTime || bonusFeeCodes.includes(currentRowFeeCodeOnly) || !prevRowEndTime || !isValidTime) {
          updatedRecords.push(groupRecordsList[i]);
        } else {
          const calculatedCurrentRowUnits = calculateUnits(currentRowFeeCode, prevRowEndTime, currentRowEndTime, currentRowUnits);
          updatedRecords.push({ ...record, [starKey]: prevRowEndTime, [endKey]: currentRowEndTime, [inputs.units.name]: calculatedCurrentRowUnits });
        }
      }
    }

    // Update all times in chain if same duration is on and updates duration in first row
    if (rowIndex === 0 && localState.applyToAllTime) return applySameDuration(updatedRecords);

    setLocalState((prevState) => ({
      ...prevState,
      groupRecords: updatedRecords,
      groupRecordsRequiredInputs: requiredInputs
    }));
  };

  const onRefToByChange = (value, rowOptions) => {
    setLocalState((prevState) => {
      // Check if input required
      const rowId = rowOptions.rowData[inputs.groupRowId.name];
      const isRequired = value !== 'N' && !rowOptions.rowData[inputs.referral.codeType].length;

      let groupRecordsRequiredInputs = setRequiredInput({
        groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
        rowId,
        inputName: inputs.referral.name,
        isRequired: isRequired,
        isAsterisk: isRequired
      });

      const updatedRecords = prevState.groupRecords.map((record, index) => {
        const updatedRecord = {
          ...record,
          [inputs.referral.name]: value === 'N' ? [] : record[inputs.referral.name],
          [inputs.referral.codeDescription]: value === 'N' ? [] : record[inputs.referral.codeDescription],
          [inputs.referral.codeType]: value === 'N' ? [] : record[inputs.referral.codeType],
          [inputs.refToBy.name]: value
        };

        // Reset required Dx input for each row
        if (prevState.applyToAllReferral) {
          groupRecordsRequiredInputs = setAllRequiredInputs({
            groupRecordsRequiredInputs,
            inputName: inputs.referral.name,
            isRequired,
            isAsterisk: isRequired
          });
        }

        if (prevState.applyToAllReferral || rowOptions.rowIndex === index) return updatedRecord;

        return record;
      });

      return {
        ...prevState,
        groupRecords: updatedRecords,
        latestReferral: false,
        applyToAllReferral: prevState.applyToAllReferral,
        groupRecordsRequiredInputs
      };
    });
  };

  const onFeeChange = (value, rowOptions) => {
    const feeCodesOnly = value?.map((i) => i.value);
    const isBMISurgicalAssist = bmiSurgicalAssistCodes.some((i) => feeCodesOnly?.includes(i));

    if (isBMISurgicalAssist) {
      // CMO-3202 - Fee code 13003
      setLocalState((prevState) => ({ ...prevState, bmiSurgicalAssistForGroupDialog: true }));
    } else {
      handleFeeChange(value, rowOptions);
    }
  };

  const handleFeeChange = (codes, rowOptions) => {
    const record = rowOptions.rowData;
    const rowIndex = rowOptions.rowIndex;
    const rowId = record[inputs.groupRowId.name];
    const patientGuid = record[inputs.patient.name][0]?.PatientGuid;

    // Check if user tries to add a bonus fee code (98010, 98011, 98012, 98990) twice
    if (codes?.length && isBonusFeeCodeDuplicated(codes)) {
      return dispatch(
        setToastMessage({
          type: 'warn',
          message: String.format(t('The_code_has_already_been_added'), codes[0]?.value)
        })
      );
    }

    // Check if adding more than one code
    if (codes?.length > 1) {
      dispatch(
        setToastMessage({
          type: 'warn',
          message: `${t('Maximum_number_of_entries_is_X')} ${1}`
        })
      );
      return;
    }

    const feeCodes = watch(inputs.feeCodes.codeType);
    const uniqFee = differenceBy(codes, feeCodes, 'value');

    // Add fee code to the first step state if it is unique
    if (uniqFee?.length) {
      const invoiceType = watch(inputs.payor.name);

      // Add code to quick pick list if it's missing
      const feeType = getCategoryType(inputs.feeCodes.codeType, invoiceType);
      const selectedCodes = { [feeType]: codes };
      addMissingCodesToRecentList({ selectedCodes });

      const feeCodes = watch(inputs.feeCodes.codeType);
      const feeCodesOnly = map(feeCodes, 'value');
      const feeCodesDescription = map(feeCodes, 'label');
      const uniqFeeCodesOnly = map(uniqFee, 'value');
      const uniqFeeCodesDescription = map(uniqFee, 'label');

      const mergedFeeCodes = concat(feeCodesOnly, uniqFeeCodesOnly);
      const mergedFeeCodesDescription = concat(feeCodesDescription, uniqFeeCodesDescription);
      const mergedFeeCodesWithType = concat(feeCodes, uniqFee);

      setValue(inputs.feeCodes.name, mergedFeeCodes);
      setValue(inputs.feeCodes.codeDescription, mergedFeeCodesDescription);
      setValue(inputs.feeCodes.codeType, mergedFeeCodesWithType);
    }

    setLocalState((prevState) => {
      // Check if input required
      const isRequired = !codes?.length;
      let groupRecordsRequiredInputs = setRequiredInput({
        groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
        rowId,
        inputName: inputs.feeCodes.name,
        isRequired: isRequired,
        isAsterisk: isRequired
      });

      let updatedRecords = updateFeeCodesForRecord(prevState.groupRecords, codes, rowIndex);

      // On add code
      if (codes?.length) {
        // Find duplicated items based on patient ID and code value
        const duplicatedItems = getAllDuplicatedRecords(updatedRecords);
        const isDuplicated = isRecordDuplicated(patientGuid, codes[0]?.value);

        if (isDuplicated) {
          // If a fee code was added, update the location code for current record
          updatedRecords = map(updatedRecords, (i) => {
            if (i[inputs.groupRowId.name] === rowId) {
              return { ...i, ...updatedValuesForDuplicateRecord };
            }

            return i;
          });
        }

        // Update required inputs for duplicated records
        duplicatedItems.forEach((i) => {
          groupRecordsRequiredInputs = {
            ...groupRecordsRequiredInputs,
            [i[inputs.groupRowId.name]]: requiredInputsForDuplicateRecord
          };
        });
      }

      // On remove code
      if (!codes?.length) {
        const removedFeeCodeRecord = find(prevState.groupRecords, { [inputs.groupRowId.name]: rowId });
        const removedFeeCode = removedFeeCodeRecord?.[inputs.feeCodes.codeType]?.[0]?.value;

        // If a fee code was removed, reset the location code for the duplicated record
        // if there are no more duplicates with the same fee code and patient
        updatedRecords = resetValuesForDupicatedRecord(patientGuid, removedFeeCode, prevState.groupRecords);
        updatedRecords = updateFeeCodesForRecord(updatedRecords, codes, rowIndex);

        // Remove row requirements for duplicated records
        groupRecordsRequiredInputs = removeRowRequirementsForDuplicatedRecords(record);

        // Remove code from first step
        const commonValues = intersectionBy(feeCodes, flatMap(updatedRecords, inputs.feeCodes.codeType), 'value');

        const filteredFeeCodes = filter(feeCodes, (code) =>
          includes(
            commonValues.map((code) => code.value),
            code.value
          )
        );

        // Update fee codes on the first step
        const filteredFeeCodesOnly = map(filteredFeeCodes, 'value');
        const filteredFeeCodesDescription = map(filteredFeeCodes, 'label');
        setValue(inputs.feeCodes.name, filteredFeeCodesOnly);
        setValue(inputs.feeCodes.codeDescription, filteredFeeCodesDescription);
        setValue(inputs.feeCodes.codeType, filteredFeeCodes);
      }

      return { ...prevState, groupRecords: updatedRecords, groupRecordsRequiredInputs };
    });
  };

  const onDxChange = (codes, rowOptions) => {
    const { rowIndex, rowData } = rowOptions;

    if (codes?.length > icd9CodeMaxEntry) {
      dispatch(
        setToastMessage({
          type: 'warn',
          message: `${t('Maximum_number_of_entries_is_X')} ${icd9CodeMaxEntry}`
        })
      );
      return;
    }

    const _codes = codes?.map((i) => i.value);
    const _codesDescription = codes?.map((i) => i.label);

    setLocalState((prevState) => {
      // Check if input required
      const isRequired = !codes?.length;
      let groupRecordsRequiredInputs = setRequiredInput({
        groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
        rowId: rowData[inputs.groupRowId.name],
        inputName: inputs.icd9.name,
        isRequired,
        isAsterisk: isRequired
      });

      const updatedRecords = prevState.groupRecords.map((record, index) => {
        // Add dx codes for all patients
        if (prevState.applyToAllDx) {
          // Reset required Dx input for each row
          groupRecordsRequiredInputs = setAllRequiredInputs({
            groupRecordsRequiredInputs,
            inputName: inputs.icd9.name,
            isRequired,
            isAsterisk: isRequired
          });

          const isBonusCode = isBonusFeeCodeOnly(record);
          let updatedData = {
            ...record,
            [inputs.icd9.name]: _codes || [],
            [inputs.icd9.codeDescription]: _codesDescription || [],
            [inputs.icd9.codeType]: codes || []
          };

          // CMO-2648 - Group Teleplan -> add L23 dx to 98010, 98011, 98012, 98990
          // Do not add latest dx codes for 98010, 98011, 98012, 98990 codes
          if (isBonusCode) {
            updatedData = {
              ...record,
              [inputs.icd9.name]: [dxCodeL23.value],
              [inputs.icd9.codeDescription]: [dxCodeL23.label],
              [inputs.icd9.codeType]: [dxCodeL23]
            };
          }

          return updatedData;
        }

        // Add dx codes for current patient
        if (index === rowIndex) {
          const updatedData = {
            ...record,
            [inputs.icd9.name]: _codes || [],
            [inputs.icd9.codeDescription]: _codesDescription || [],
            [inputs.icd9.codeType]: codes || []
          };
          return updatedData;
        }

        return record;
      });

      return {
        ...prevState,
        groupRecords: updatedRecords,
        latestDx: false,
        applyToAllDx: prevState.applyToAllDx,
        groupRecordsRequiredInputs
      };
    });
  };

  const onReferralChange = (codes, rowOptions) => {
    if (codes?.length > referralCodeMaxEntry) {
      dispatch(
        setToastMessage({
          type: 'warn',
          message: `${t('Maximum_number_of_entries_is_X')} ${referralCodeMaxEntry}`
        })
      );
      return;
    }

    const rowId = rowOptions.rowData[inputs.groupRowId.name];
    const currentRefToBy = rowOptions.rowData[inputs.refToBy.name];
    const _codes = codes?.map((i) => i.value);
    const _codesDescription = codes?.map((i) => i.label);

    setLocalState((prevState) => {
      const refToBy = codes?.length ? getRefToBy(codes[0], currentRefToBy) : 'N';

      // Check if input required
      const isRequired = !codes?.length && refToBy !== 'N';
      let groupRecordsRequiredInputs = setRequiredInput({
        groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
        rowId,
        inputName: inputs.referral.name,
        isRequired: isRequired,
        isAsterisk: isRequired
      });

      const updatedRecords = prevState.groupRecords.map((record, index) => {
        const updatedRecord = {
          ...record,
          [inputs.referral.name]: _codes || [],
          [inputs.referral.codeDescription]: _codesDescription || [],
          [inputs.referral.codeType]: codes || [],
          [inputs.refToBy.name]: refToBy
        };

        // Reset required Dx input for each row
        if (prevState.applyToAllReferral) {
          groupRecordsRequiredInputs = setAllRequiredInputs({
            groupRecordsRequiredInputs,
            inputName: inputs.referral.name,
            isRequired,
            isAsterisk: isRequired
          });
        }

        if (prevState.applyToAllReferral || rowOptions.rowIndex === index) return updatedRecord;

        return record;
      });

      return {
        ...prevState,
        groupRecords: updatedRecords,
        latestReferral: false,
        applyToAllReferral: prevState.applyToAllReferral,
        groupRecordsRequiredInputs
      };
    });
  };

  const updateFeeCodesForRecord = (groupRecords, codes, rowIndex) => {
    return map(groupRecords, (record, index) => {
      if (index === rowIndex) {
        const feeCode = isArray(codes) ? codes[0] : {};
        const startTime = record[inputs.startTime.name];
        const endTime = record[inputs.endTime.name];
        const currentUnits = feeCode ? record[inputs.units.name] : 1; // Reset units to 1 if fee code is removed
        const codesOnly = map(codes, 'value');
        const codesDescriptionOnly = map(codes, 'label');
        const units = calculateUnits(feeCode, startTime, endTime, currentUnits);
        const updatedData = {
          ...record,
          [inputs.feeCodes.name]: codesOnly || [],
          [inputs.feeCodes.codeDescription]: codesDescriptionOnly || [],
          [inputs.feeCodes.codeType]: codes || [],
          [inputs.units.name]: units
        };

        return updatedData;
      }

      return record;
    });
  };

  const clearAllDxCodes = () => {
    setLocalState((prevState) => {
      const resetedDx = prevState.groupRecords.map((i) => {
        const isBonusCode = isBonusFeeCodeOnly(i);
        if (isBonusCode) return i;

        return {
          ...i,
          [inputs.icd9.name]: [],
          [inputs.icd9.codeDescription]: [],
          [inputs.icd9.codeType]: []
        };
      });

      return { ...prevState, groupRecords: resetedDx };
    });
  };

  const clearAllReferralCodes = () => {
    setLocalState((prevState) => {
      const resetedDx = prevState.groupRecords.map((i) => {
        const currentRefToBy = i[inputs.refToBy.name];
        const feeCodesOnly = map(i[inputs.feeCodes.codeType], (code) => code.value);
        const refToBy = currentRefToBy === 'N' ? getReferralValueByFeeCode(feeCodesOnly) : 'N';

        return {
          ...i,
          [inputs.referral.name]: [],
          [inputs.referral.codeDescription]: [],
          [inputs.referral.codeType]: [],
          [inputs.refToBy.name]: refToBy
        };
      });

      return { ...prevState, groupRecords: resetedDx };
    });
  };

  const isBonusFeeCodeDuplicated = (codes) => {
    return some(codes, (code) => {
      const isBonusCode = includes(bonusFeeCodes, code.value);
      const isDup = some(localState.groupRecords, (record) => {
        return get(record, [inputs.feeCodes.codeType, 0, 'value']) === code.value;
      });

      return isBonusCode && isDup;
    });
  };

  const isBonusFeeCodeOnly = (record) => {
    const feeCode = get(record, [inputs.feeCodes.codeType, 0, 'value']);
    return bonusFeeCodesOnly.includes(feeCode);
  };

  const isBonusFeeCode = (record) => {
    const feeCode = get(record, [inputs.feeCodes.codeType, 0, 'value']);
    return bonusFeeCodes.includes(feeCode);
  };

  const autofillL23Code = (record, rowIndex) => {
    const isBonusCode = isBonusFeeCodeOnly(record);
    // CMO-2648 - Group Teleplan -> add L23 dx to 98010, 98011, 98012, 98990
    // Do not add latest dx codes for 98010, 98011, 98012, 98990 codes
    if (isBonusCode) {
      return setLocalState((prevState) => {
        const updatedRecords = prevState.groupRecords.map((record, recordIndex) => {
          if (rowIndex === recordIndex) {
            return {
              ...record,
              [inputs.icd9.name]: [dxCodeL23.value],
              [inputs.icd9.codeDescription]: [dxCodeL23.label],
              [inputs.icd9.codeType]: [dxCodeL23]
            };
          }

          return record;
        });

        return { ...prevState, groupRecords: updatedRecords };
      });
    }
  };

  const autofillLatestDxCodes = () => {
    // Reset all dx codes
    clearAllDxCodes();

    localState.groupRecords.forEach((i, index) => {
      // CMO-2648 - Group Teleplan -> add L23 dx to 98010, 98011, 98012, 98990
      // Do not add latest dx codes for 98010, 98011, 98012, 98990 codes
      const isBonusCode = isBonusFeeCodeOnly(i);
      if (isBonusCode) return autofillL23Code(i, index);

      // Get used recent codes (order: 1)
      const recentDxCodes = localState.listOfPatients?.find((x) => x.PatientGuid === i[inputs.patient.name][0].PatientGuid)?.RecentCodes?.icd9 || [
        {}
      ];
      const filteredRecentDxCodes = recentDxCodes?.filter((x) => isCodeRecentlyUsed(x?.order));

      if (filteredRecentDxCodes?.length) {
        const closestCodes = filteredRecentDxCodes.reduce((code, currentItem) => {
          const currentDate = moment(currentItem.dateAdded);
          const delta = currentDate.diff(moment());

          if (code === undefined || delta < code.delta) {
            code = { ...currentItem, delta };
          }

          return code;
        });

        let closestItems =
          closestCodes.delta === 0 ? [closestCodes] : filteredRecentDxCodes.filter((item) => item.dateAdded === closestCodes.dateAdded);

        // CMO-26680 - Do not add L23 to Dx to any codes excetp 98010, 98011, 98012, 98990 on any Teleplan screens
        closestItems = closestItems.filter((i) => i.value !== dxCodeL23.value);

        if (closestItems.length > 3) {
          closestItems = closestItems.slice(0, 3);
        }

        const codesOnly = closestItems?.map((i) => i.value);
        const codesDescriptionOnly = closestItems?.map((i) => i.label);

        setLocalState((prevState) => {
          // Check if input required
          const isRequired = !closestItems?.length;
          const groupRecordsRequiredInputs = setRequiredInput({
            groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
            rowId: i[inputs.groupRowId.name],
            inputName: inputs.icd9.name,
            isRequired,
            isAsterisk: isRequired
          });

          const updatedRecords = prevState.groupRecords.map((record, recordIndex) => {
            if (index === recordIndex) {
              return {
                ...record,
                [inputs.icd9.name]: codesOnly || [],
                [inputs.icd9.codeDescription]: codesDescriptionOnly || [],
                [inputs.icd9.codeType]: closestItems || []
              };
            }

            return record;
          });

          return { ...prevState, groupRecords: updatedRecords, groupRecordsRequiredInputs };
        });
      }
    });
  };

  const autofillLatestReferralCodes = () => {
    // Reset all dx codes
    clearAllReferralCodes();

    localState.groupRecords.forEach((i, index) => {
      // Get used recent codes (order: 1)
      const recentReferralCodes = localState.listOfPatients?.find((x) => x.PatientGuid === i[inputs.patient.name][0].PatientGuid)?.RecentCodes
        ?.referral || [{}];
      const filteredRecentReferralCodes = recentReferralCodes?.filter((x) => isCodeRecentlyUsed(x?.order));

      if (filteredRecentReferralCodes?.length) {
        const closestCodes = filteredRecentReferralCodes.reduce((code, currentItem) => {
          const currentDate = moment(currentItem.dateAdded);
          const delta = currentDate.diff(moment());

          if (code === undefined || delta < code.delta) {
            code = { ...currentItem, delta };
          }

          return code;
        });

        let closestItems =
          closestCodes.delta === 0 ? [closestCodes] : filteredRecentReferralCodes?.filter((item) => item.dateAdded === closestCodes.dateAdded);

        if (closestItems?.length > 1) {
          closestItems = closestItems.slice(0, 1);
        }

        const codesOnly = closestItems?.map((i) => i.value);
        const codesDescriptionOnly = closestItems?.map((i) => i.label);
        const refToBy = () => {
          const feeCodesOnly = map(i[inputs.feeCodes.codeType], (i) => i.value);
          if (!closestItems?.length) return getReferralValueByFeeCode(feeCodesOnly);
          if (closestItems?.length) return getRefToBy(closestItems[0], i[inputs.refToBy.name]);
        };

        setLocalState((prevState) => {
          // Check if input required
          const isRequired = !closestItems?.length;
          const groupRecordsRequiredInputs = setRequiredInput({
            groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
            rowId: i[inputs.groupRowId.name],
            inputName: inputs.referral.name,
            isRequired,
            isAsterisk: isRequired
          });

          const updatedRecords = prevState.groupRecords.map((record, recordIndex) => {
            if (index === recordIndex) {
              return {
                ...record,
                [inputs.referral.name]: codesOnly || [],
                [inputs.referral.codeDescription]: codesDescriptionOnly || [],
                [inputs.referral.codeType]: closestItems || [],
                [inputs.refToBy.name]: refToBy()
              };
            }

            return record;
          });

          return { ...prevState, groupRecords: updatedRecords, groupRecordsRequiredInputs };
        });
      }
    });
  };

  const setInitialRefToBy = () => {
    setLocalState((prevState) => {
      const updatedRecords = map(prevState.groupRecords, (i) => {
        const feeCodesOnly = map(i[inputs.feeCodes.codeType], (code) => code.value);
        const currentRefToBy = i[inputs.refToBy.name];
        const refToBy = currentRefToBy === 'N' ? getReferralValueByFeeCode(feeCodesOnly) : currentRefToBy;

        return {
          ...i,
          [inputs.refToBy.name]: refToBy
        };
      });

      return { ...prevState, groupRecords: updatedRecords };
    });
  };

  const getRefToBy = (referralCode, refToBy) => {
    const defaultValue = refToBy === 'N' ? 'B' : refToBy;
    if (!referralCode) return 'N';
    if (Number(referralCode.order) === 1) return referralCode.reftoby || defaultValue;
    return defaultValue;
  };

  const setLatestDx = () => {
    autofillLatestDxCodes();
    setLocalState((prevState) => ({ ...prevState, latestDx: true, applyToAllDx: false }));
  };

  const setSameDx = () => {
    clearAllDxCodes();
    setLocalState((prevState) => ({ ...prevState, latestDx: false, applyToAllDx: true }));
  };

  const setLatestReferral = () => {
    autofillLatestReferralCodes();
    setLocalState((prevState) => ({ ...prevState, latestReferral: true, applyToAllReferral: false }));
  };

  const setSameReferral = () => {
    clearAllReferralCodes();
    setLocalState((prevState) => ({ ...prevState, latestReferral: false, applyToAllReferral: true }));
  };

  const applySameDurationForAllRecords = (groupRecords) => {
    const records = groupRecords || localState.groupRecords;
    const startTime = records[0][inputs.startTime.name];
    const endTime = records[0][inputs.endTime.name];
    const isStartTimeValid = isTimeValid(startTime);
    const isEndTimeValid = isTimeValid(endTime);
    let updatedRecords = [];

    if (isStartTimeValid && isEndTimeValid) {
      for (let i = 0; i < records.length; i++) {
        const record = records[i];
        const feeCode = record[inputs.feeCodes.codeType][0];

        if (i === 0) {
          updatedRecords.push(records[i]);
        }

        if (i > 0) {
          const currentUnits = record[inputs.units.name];
          const duration = records[0][inputs.duration.name];

          if (isConsecutiveTime) {
            const prevEndTime = updatedRecords[i - 1][inputs.endTime.name];
            const updatedEndTime = addDuration(prevEndTime, duration);
            const units = calculateUnits(feeCode, prevEndTime, updatedEndTime, currentUnits);
            updatedRecords.push({
              ...record,
              [inputs.startTime.name]: prevEndTime,
              [inputs.endTime.name]: updatedEndTime,
              [inputs.duration.name]: duration,
              [inputs.units.name]: units
            });
          } else {
            const currentStartTime = record[inputs.startTime.name];
            const updatedCurrentEndTime = addDuration(currentStartTime, duration);
            const calculatedUnits = calculateUnits(feeCode, currentStartTime, updatedCurrentEndTime, currentUnits);
            updatedRecords.push({
              ...record,
              [inputs.startTime.name]: currentStartTime,
              [inputs.endTime.name]: updatedCurrentEndTime,
              [inputs.duration.name]: duration,
              [inputs.units.name]: calculatedUnits
            });
          }
        }
      }
    } else {
      updatedRecords = records.map((i, index) => {
        if (index === 0) return i;
        return {
          ...i,
          [inputs.startTime.name]: '',
          [inputs.endTime.name]: '',
          [inputs.units.name]: defaultUnits,
          [inputs.duration.name]: 0
        };
      });
    }

    return updatedRecords;
  };

  const applySameDuration = (groupRecords) => {
    const updatedGroupRecords = applySameDurationForAllRecords(groupRecords);
    setLocalState((prevState) => ({ ...prevState, groupRecords: updatedGroupRecords }));
  };

  const addDuration = (time, duration) => {
    const calculatedDuration = moment(time, 'HH:mm').add(duration, 'minutes').format('HH:mm');
    return calculatedDuration;
  };

  const calculateTotalForGroupPicker = () => {
    let total = 0;

    if (localState.groupRecords?.length) {
      for (let item of localState.groupRecords) {
        const feeCode = item[inputs.feeCodes.codeType][0];
        const units = item[inputs.units.name];
        total += feeCode?.amount * units;
      }
    }

    return total.toFixed(2);
  };

  const disabledDxInput = (rowOptions) => {
    const feeCodeKey = inputs.feeCodes.codeType;
    const feeCode = rowOptions.rowData[feeCodeKey][0]?.value;
    const getRowsWithBonusFeeCodes = localState.groupRecords.filter((i) => bonusFeeCodesOnly.includes(i[feeCodeKey][0]?.value));

    return bonusFeeCodesOnly.includes(feeCode) || (rowOptions.rowIndex > getRowsWithBonusFeeCodes.length && localState.applyToAllDx);
  };

  const disabledReferralInput = (rowOptions) => {
    return rowOptions.rowIndex > 0 && localState.applyToAllReferral;
  };

  const removeRowRequirementsForDuplicatedRecords = (record) => {
    const recordRowId = record[inputs.groupRowId.name];
    const feeCode = record[inputs.feeCodes.codeType][0]?.value;
    const patientGuid = record[inputs.patient.name][0]?.PatientGuid;

    const duplicatedItems = filter(localState.groupRecords, (i) => {
      return feeCode === i[inputs.feeCodes.codeType][0]?.value && patientGuid === i[inputs.patient.name][0]?.PatientGuid;
    });
    let groupRecordsRequiredInputs = omit(localState.groupRecordsRequiredInputs, recordRowId);

    if (duplicatedItems.length === 2) {
      const duplicatedRowIds = duplicatedItems.map((i) => get(i, inputs.groupRowId.name));
      groupRecordsRequiredInputs = omit(groupRecordsRequiredInputs, duplicatedRowIds);
    }

    return groupRecordsRequiredInputs;
  };

  const resetValuesForDupicatedRecord = (patientGuid, feeCode, groupRecords) => {
    // Use provided groupRecords or fallback to localState.groupRecords
    let groupRecordsList = groupRecords || localState.groupRecords;

    // Check if patientGuid or feeCode is missing, return if either is missing
    if (!patientGuid || !feeCode) return groupRecordsList;

    // Data to reset for duplicated records
    const dataForReset = {
      [inputs.submission.name]: watch(inputs.submission.name),
      [inputs.isDuplicated.name]: false
    };

    // Filter duplicated items based on the provided conditions
    const duplicatedItems = filter(groupRecordsList, (i) => {
      return (
        i[inputs.isDuplicated.name] && feeCode === i[inputs.feeCodes.codeType][0]?.value && patientGuid === i[inputs.patient.name][0]?.PatientGuid
      );
    });

    if (duplicatedItems.length) {
      const duplicateId = duplicatedItems[0][inputs.groupRowId.name];

      // If there is exactly one duplicated item
      if (duplicatedItems.length === 1) {
        groupRecordsList = map(groupRecordsList, (i) => {
          // If the current record is the duplicated one and its submission code is 'D'
          if (i[inputs.groupRowId.name] === duplicateId && i[inputs.submission.name] === 'D') {
            return { ...i, ...dataForReset };
          }
          return i;
        });
      }

      // If there are multiple duplicated records and the original was deleted
      if (duplicatedItems.length > 1) {
        groupRecordsList = map(groupRecordsList, (i) => {
          // Update the first record in the duplicated list
          if (i[inputs.groupRowId.name] === duplicateId) return { ...i, ...dataForReset };
          return i;
        });
      }
    }

    // Return the updated groupRecordsList
    return groupRecordsList;
  };

  const toggleCatalogsForGroup = (input, rowOptions) => {
    setLocalState((prevState) => ({
      ...prevState,
      batchCatalogsDialog: { showDialog: true, rowOptions: { ...rowOptions, input } }
    }));
  };

  const getRowsWithSamePatient = (patientGuid) => {
    return localState.groupRecords?.filter((i) => i?.[inputs.patient.name]?.[0]?.PatientGuid === patientGuid);
  };

  return {
    setSameDx,
    onDxChange,
    setLatestDx,
    onFeeChange,
    onTimeChange,
    onUnitsChange,
    isBonusFeeCode,
    disabledDxInput,
    clearAllDxCodes,
    autofillL23Code,
    onCommentChange,
    onRefToByChange,
    setSameReferral,
    onReferralChange,
    applySameDuration,
    setLatestReferral,
    setInitialRefToBy,
    isBonusFeeCodeOnly,
    autofillLatestDxCodes,
    disabledReferralInput,
    clearAllReferralCodes,
    toggleCatalogsForGroup,
    getRowsWithSamePatient,
    autofillLatestReferralCodes,
    calculateTotalForGroupPicker,
    resetValuesForDupicatedRecord,
    applySameDurationForAllRecords,
    removeRowRequirementsForDuplicatedRecords
  };
};
