import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CodePicker } from '../../../../../../../components/Inputs';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs, requiredInputs } from '../../../helpers/inputs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useToggleCatalogs } from '../../../hooks/useToggleCatalogs';

const NOICode = () => {
  const name = inputs.noi.name;
  const description = inputs.noi.codeDescription;
  const { errors, control, isMobile, watch } = useFormContext();
  const { code, onChange, onFocus } = useCodePickerActions(inputs.noi);
  const { toggleCatalogs } = useToggleCatalogs();
  const speciality = watch(inputs.speciality.name);

  const noiInput = (field) => {
    return (
      <InputWrapper
        className="codePickerWrap"
        name={name}
        label={inputs.noi.label}
        required={!!requiredInputs[name]}
        errorMessage={errors[name]?.message}
        description={watch(description)}
      >
        <CodePicker
          name={name}
          ref={field.ref}
          id={elementIDs.noiAutocomplete}
          inputId={elementIDs.noiAutocompleteInput}
          isInvalid={!!errors[name]?.message}
          value={code}
          maxEntry={1}
          showCatalogsButton={isMobile}
          searchParams={{ speciality, codeType: inputs.noi.codeType, getCodesFor: 'teleplan' }}
          toggleCatalogs={() => toggleCatalogs('mobile', name)}
          onFocus={onFocus}
          onChange={onChange}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => noiInput(field)} />;
};

export default NOICode;
