import React from 'react';

/**
 * A component that aligns its children to the right and applies a right margin.
 *
 * @param props - The component props.
 * @returns The rendered component.
 */
export default function RightAlignedCurrency(props) {
  return (
    <div className="flex justify-content-end" style={{ marginRight: '40%', ...props.style }}>
      {props.children}
    </div>
  );
}
