import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { getPatients } from '../../actions/patients.action.creators';
import { patientsDefaultSortBy } from '../../../config/defaultValuesConfig';
import { t } from '../../../../service/localization/i18n';

const DefaultSortOrderButton = () => {
  const dispatch = useDispatch();

  const onDefaultSort = () => {
    dispatch(getPatients({ sortBy: `${patientsDefaultSortBy.sortOrder}` }));
  };

  return (
    <Button
      className="p-button-text p-button-rounded"
      tooltip={t('Default_sort_order.1')}
      tooltipOptions={{ position: 'top' }}
      icon="pi pi-sort-amount-down"
      onClick={onDefaultSort}
    />
  );
};

export default DefaultSortOrderButton;
