import { encodeText } from '../../../../utils/decodeText';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from './inputs';
import moment from 'moment';

export const dataForSubmit = (data) => {
  const disabledDate = data[inputs.disabledDate.name];
  const injuryDate = data[inputs.injuryDate.name];
  const recoveryDate = data[inputs.recoveryDate.name];
  const serviceDate = data[inputs.serviceDate.name];

  const dataForRequest = {
    ...data,
    [inputs.patientGuid.name]: data[inputs.patient.name][0].PatientGuid,
    [inputs.disabledDate.name]: disabledDate ? moment(disabledDate).format('YYYY-MM-DD') : null,
    [inputs.injuryDate.name]: injuryDate ? moment(injuryDate).format('YYYY-MM-DD') : null,
    [inputs.recoveryDate.name]: recoveryDate ? moment(recoveryDate).format('YYYY-MM-DD') : null,
    [inputs.serviceDate.name]: serviceDate ? moment(serviceDate).format('YYYY-MM-DD') : null,

    // encode text values
    [inputs.employer.name]: encodeText(data[inputs.employer.name]),
    [inputs.address.name]: encodeText(data[inputs.address.name]),
    [inputs.city.name]: encodeText(data[inputs.city.name]),
    [inputs.firstTreatment.name]: encodeText(data[inputs.firstTreatment.name]),
    [inputs.priorProblems.name]: encodeText(data[inputs.priorProblems.name]),
    [inputs.clinicalInformation.name]: encodeText(data[inputs.clinicalInformation.name]),
    [inputs.restrictions.name]: encodeText(data[inputs.restrictions.name]),
    [inputs.icd9.codeDescription]: encodeText(data[inputs.icd9.codeDescription])
  };

  delete dataForRequest[inputs.patient.name];
  delete dataForRequest[inputs.patientClaims.name];
  delete dataForRequest[commonInputs.fantom.name];

  return dataForRequest;
};
