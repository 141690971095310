import React, { useContext } from 'react';
import Context from '../../../../../../../Context';
import { useSelector } from 'react-redux';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const BillingInformation = () => {
  const { members } = useSelector((state) => state.clinic);
  const { data } = useContext(Context);
  const practitioner = members.find((i) => i.DoctorGuid === data[inputs.practitioner.name]);

  return (
    <table className="mt-3 text-sm demographicsInfoTable">
      <tbody>
        <tr>
          <td style={{ width: '50%' }}>
            <div className="flex flex-column">
              <span className="font-semibold">{t('Payee_number.1')}</span>
              <span className="coloredText">{practitioner?.PayeeNumber || ''}</span>
            </div>
          </td>
          <td style={{ width: '50%' }}>
            <div className="flex flex-column">
              <span className="font-semibold">{t('Practitioner_number')}</span>
              <span className="coloredText">{practitioner?.PractitionerNumber || ''}</span>
            </div>
          </td>
        </tr>

        <tr>
          <td style={{ width: '50%' }}>
            <div className="flex flex-column">
              <span className="font-semibold">{t('Payee_name')}</span>
              <span className="coloredText">{practitioner?.PayeeNumber || ''}</span>
            </div>
          </td>
          <td style={{ width: '50%' }}>
            <div className="flex flex-column">
              <span className="font-semibold">{t('Practitioner_name')}</span>
              <span className="coloredText">{`${practitioner?.LastName}, ${practitioner?.FirstName}`}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BillingInformation;
