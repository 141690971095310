import React from 'react';

const Logout = () => {
  return (
    <div className="login-body">
      <div className="login-topbar"></div>
      <div className="flex justify-content-center align-items-center text-white" style={{ height: '80vh' }}>
        <h1>Thank you for using our web application.</h1>
      </div>
      <div className="flex justify-content-center align-items-center text-white">
        <h2>
          Go to
          <a href="https://secure.claimmanager.ca/default.aspx" className="text-white">
            Claim Manager Online
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Logout;
