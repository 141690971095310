import React from 'react';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../../../components/Inputs';

import { rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { inputs } from '../../../../../helpers/inputs';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import cx from 'classnames';

const UnitsEditor = ({ rowOptions }) => {
  const { onUnitsChange } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const name = inputs.units.name;
  const value = rowOptions.rowData[name];
  const isValidUnit = Number(value) >= 1;
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const errorMessage = getErrorMessage(rowId, name);
  const isInvalid = !isValidUnit && !!errorMessage;

  const onBlur = (value) => {
    if (!value) return onUnitsChange('1', rowOptions.rowIndex);
  };

  return (
    <InputWrapper hideLabel name={name} errorMessage={!isValidUnit && errorMessage} style={{ height: rowInputHeight, width: '50px' }}>
      <InputText
        className={cx({ 'p-invalid': isInvalid })}
        style={{ width: 'inherit' }}
        keyfilter="num"
        value={value}
        onChange={(e) => onUnitsChange(e.target.value, rowOptions.rowIndex)}
        onBlur={(e) => onBlur(e.target.value)}
      />
    </InputWrapper>
  );
};

export default UnitsEditor;
