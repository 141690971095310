import React from 'react';

import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

import { t } from '../../../service/localization/i18n';
import { specPHN } from '../../../modules/config/defaultValuesConfig';
import { commonInputs } from '../../../modules/claims/../config/commonInputsConfig';

const GenericPHN = ({ control }) => {
  const name = commonInputs.submitWithGenericPHN.name;

  const submitWithGenericPHNInput = (field) => {
    return (
      <div className="flex align-items-center w-full p-field-checkbox">
        <Checkbox className="mr-3" id={field.name} checked={!!Number(field.value)} onChange={(e) => field.onChange(e.checked ? '1' : '0')} />
        <div className="flex justify-content-between w-full">
          <label htmlFor="city1">{`${commonInputs.submitWithGenericPHN.label} ${specPHN}`}</label>

          <Tooltip target=".submitWithGenericPHNTooltip" position="left" style={{ width: '300px' }} />
          <i
            id={`${field.name}_description`}
            className="pi pi-question-circle submitWithGenericPHNTooltip"
            data-pr-tooltip={t('Check_off_this_box_if_the_patient_is_not_eligible')}
          />
        </div>
      </div>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => submitWithGenericPHNInput(field)} />;
};

export default GenericPHN;
