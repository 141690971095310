const ViewModel = {
  clientHeight: 600,
  showSearch: false,
  showNew: false,
  showASP: false,
  showAlpha: false,
  columns: [],
  actions: [],
  isMobile: false,
  // table params
  dataPage: {},
  dataSourceInplace: [],
  dataSourceAppend: [],
  dataSelection: [],
  dataFieldFilters: [],
  dataRows: 0,
  dataQuery: '',
  dataKey: '',
  dataTotalRows: 0,
  dataFirst: '',
  dataSortField: '',
  dataSortOrder: 0,
  dataOnPageInplace: null,
  dataOnPageAppend: null,
  onSort: null,
  onRowSelect: null,
  onRowToggle: null,
  onSelectionChange: null,
  rowTemplateInplace: null,
  rowTemplateAppend: null,
  rowClassName: null,
  scrollUpPosition: {}
};
const ColModel = {
  field: '',
  header: '',
  sortable: false,
  filterType: '',
  filterOptions: [],
  filter: {},
  filterElement: {},
  className: null,
  onClickHandler: null
};
export { ViewModel, ColModel };
