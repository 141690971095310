import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';

const SafeHtmlContent = ({ htmlContent, ...props }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      // Sanitize the HTML content
      const cleanHtml = DOMPurify.sanitize(htmlContent); // Directly set the sanitized HTML content
      contentRef.current.innerHTML = cleanHtml;
    }
  }, [htmlContent]);

  return <div {...props} ref={contentRef}></div>;
};

export default SafeHtmlContent;
