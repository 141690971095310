import React from 'react';
import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';

const CCFPPTooltipLayout = () => {
  // return (
  //   <div id={elementIDs.ccfppInfoDialog}>
  //     <p>{t(`YES_indicates`)}</p>
  //     <ul style={{ listStyleType: 'disc' }}>
  //       <li className='pb-2'>{t("add_CCFPP_note_to_the_claim")}</li>
  //       <li>{t("calculate_the_timing")}</li>
  //     </ul>
  //     <p>
  //       {t("Select_No_to_stat")}
  //     </p>
  //   </div>
  // )

  return (
    <div id={elementIDs.ccfppInfoDialog}>
      <p>{t(`Select_this_button_if_all_is_true`)}</p>

      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-1">{t('This_service_qualifies_for_OOFHP')}</li>
        <li>{t('The_service_is_provided_to_immediately_subsequent_patient')}</li>
      </ul>

      <p>{t('We_will')}</p>

      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-1">{t('calculate_the_timing_units_without_the_delay')}</li>
        <li>{t('add_CCFPP_note_to_the_claim')}</li>
      </ul>

      <p>{t('When_CCFPP_note_is_missing_Teleplan_deducts_one_unit')}</p>

      <p>{t('CCFPP_is_applicable_only_if_OOFHP_is_applicable')}</p>
    </div>
  );
};

export default CCFPPTooltipLayout;
