import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store';
import { HashRouter } from 'react-router-dom';
import { customPrompt } from './components/Dialogs/Prompt/customPrompt';
window.store = store;

if (!String.format) {
  String.format = function (format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}

if (!Array.prototype.toReversed) {
  Array.prototype.toReversed = function () {
    return this.slice().reverse();
  };
}

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter getUserConfirmation={(massage, callback) => customPrompt(massage, callback, store.dispatch)}>
          <App></App>
        </HashRouter>
      </PersistGate>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
);

// registerServiceWorker();
