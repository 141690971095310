import { Tooltip } from 'primereact/tooltip';

import Duration from '../GroupPicker/GroupPickerTable/rowTemplates/Duration';
import CommonNotes from '../GroupPicker/GroupPickerTable/rowTemplates/CommonNotes';
import UnitsEditor from '../GroupPicker/GroupPickerTable/rowTemplates/UnitsEditor';
import DxCodeEditor from '../GroupPicker/GroupPickerTable/rowTemplates/DxCodeEditor';
import DeleteRecord from '../GroupPicker/GroupPickerTable/rowTemplates/DeleteRecord';
import DuplicateRow from '../GroupPicker/GroupPickerTable/rowTemplates/DuplicateRow';
import ClearDxForRow from '../GroupPicker/GroupPickerTable/rowTemplates/ClearDxForRow';
import CommentEditor from '../GroupPicker/GroupPickerTable/rowTemplates/CommentEditor';
import RecentDxCodes from '../GroupPicker/GroupPickerTable/rowTemplates/RecentDxCodes';
import RefToByEditor from '../GroupPicker/GroupPickerTable/rowTemplates/RefToByEditor';
import FeeCodeLayout from '../GroupPicker/GroupPickerTable/rowTemplates/FeeCodeLayout';
import FeeCodeEditor from '../GroupPicker/GroupPickerTable/rowTemplates/FeeCodeEditor';
import StartTimeEditor from '../GroupPicker/GroupPickerTable/rowTemplates/StartTimeEditor';
import ClearTimeForRow from '../GroupPicker/GroupPickerTable/rowTemplates/ClearTimeForRow';
import FinishTimeEditor from '../GroupPicker/GroupPickerTable/rowTemplates/FinishTimeEditor';
import ReferralCodeEditor from '../GroupPicker/GroupPickerTable/rowTemplates/ReferralCodeEditor';
import RecentReferralCodes from '../GroupPicker/GroupPickerTable/rowTemplates/RecentReferralCodes';
import ClearReferralForRow from '../GroupPicker/GroupPickerTable/rowTemplates/ClearReferralForRow';

import { dxCodeEditorWidth, feeCodeEditorWidth, referralCodeEditorWidth } from '../../../config/teleplanGroupStyles';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

export const useGroupPickerColumns = () => {
  const feeCodesColumn = {
    header: inputs.feeCodes.label,
    frozen: false,
    body: (rowData, { rowIndex }) => <FeeCodeLayout rowOptions={{ rowData, rowIndex }} />,
    style: { minWidth: '120px', maxWidth: '120px' }
  };

  const timeColumns = [
    {
      header: (
        <div className="flex align-items-center gap-3">
          {inputs.feeCodes.label}
          <Tooltip target={`.fee_codes_column_tooltip`} position="top">
            <ul className="flex flex-column gap-3 max-w-20rem list-disc">
              <li>{t('Use_catalog_icon_in_the_fee_item')}</li>
              <li>{t('Set_the_fee_codes_for_all_patients_in_the_batch')}</li>
            </ul>
          </Tooltip>
          <i className="pi pi-question-circle pointer fee_codes_column_tooltip" />
        </div>
      ),
      frozen: false,
      body: (rowData, { rowIndex }) => <FeeCodeEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: feeCodeEditorWidth, maxWidth: feeCodeEditorWidth }
    },

    {
      header: inputs.startTime.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <StartTimeEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '130px', maxWidth: '130px' }
    },

    {
      header: inputs.endTime.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <FinishTimeEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '130px', maxWidth: '130px' }
    },

    {
      header: inputs.units.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <UnitsEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '75px', maxWidth: '75px' }
    },

    {
      header: t('Duration'),
      frozen: false,
      body: (rowData, { rowIndex }) => <Duration rowOptions={{ rowData, rowIndex }} />,
      // style: { minWidth: '340px' }
      style: { minWidth: '535px', maxWidth: '535px' }
    },

    {
      header: '',
      frozen: true,
      alignFrozen: 'right',
      body: (rowData, { rowIndex }) => <DuplicateRow rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '60px', maxWidth: '60px' }
    },

    // {
    //   header: '',
    //   frozen: true,
    //   alignFrozen: 'right',
    //   body: (rowData, { rowIndex }) => <ClearTimeForRow rowOptions={{ rowData, rowIndex }} />,
    //   // body: (rowData, { rowIndex }) => <ClearTimeTemplate rowOptions={{ rowData, rowIndex }} />,
    //   style: { minWidth: '60px', maxWidth: '60px' }
    // },

    {
      header: '',
      frozen: true,
      alignFrozen: 'right',
      body: (rowData, { rowIndex }) => <DeleteRecord rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '60px', maxWidth: '60px' }
    }
  ];

  const dxColumns = [
    feeCodesColumn,

    {
      header: inputs.icd9.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <DxCodeEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: dxCodeEditorWidth, maxWidth: dxCodeEditorWidth }
    },

    {
      header: t('Recent_codes'),
      frozen: false,
      body: (rowData, { rowIndex }) => <RecentDxCodes rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '575px', maxWidth: '575px' }
    },

    {
      header: '',
      frozen: true,
      alignFrozen: 'right',
      body: (rowData, { rowIndex }) => <ClearDxForRow rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '60px', maxWidth: '60px' }
    }
  ];

  const referralColumns = [
    feeCodesColumn,

    {
      header: inputs.refToBy.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <RefToByEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '130px', maxWidth: '130px' }
    },

    {
      header: inputs.referral.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <ReferralCodeEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: referralCodeEditorWidth, maxWidth: referralCodeEditorWidth }
    },

    {
      header: t('Recent_codes'),
      frozen: false,
      body: (rowData, { rowIndex }) => <RecentReferralCodes rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '650px', maxWidth: '650px' }
    },

    {
      header: '',
      frozen: true,
      alignFrozen: 'right',
      body: (rowData, { rowIndex }) => <ClearReferralForRow rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '60px', maxWidth: '60px' }
    }
  ];

  const commentColumns = [
    feeCodesColumn,

    {
      header: inputs.comment.label,
      frozen: false,
      body: (rowData, { rowIndex }) => <CommentEditor rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '750px', width: '750px' }
    },

    {
      header: t('Common_notes'),
      frozen: false,
      body: (rowData, { rowIndex }) => <CommonNotes rowOptions={{ rowData, rowIndex }} />,
      style: { minWidth: '150px', maxWidth: '150px' }
    }
  ];

  return { dxColumns, timeColumns, referralColumns, commentColumns };
};
