export const defaultValues = {
  loadingPoliciesList: false,
  policiesList: [],
  eClaimPolicy: {
    showEClaimPolicyForm: false,
    policyInfo: {}
  },
  deleteEClaimPolicy: {
    showDialog: false,
    policy: {},
    policies: []
  }
};
