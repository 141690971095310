export const dxCodeL23 = {
  amount: 0,
  category: 'icd9_OTHER',
  category_label: 'ADDITIONAL DIAGNOSTIC CODES',
  label: 'L23: LFP PAYMENT MODEL',
  level: '1',
  min_per_unit: null,
  portion: null,
  text: 'LFP PAYMENT MODEL',
  value: 'L23'
};
