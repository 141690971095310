import React, { useEffect } from 'react';

import { TabPanel, TabView } from 'primereact/tabview';
import CatalogsSubTabs from './CatalogsSubTabs';
import SearchPanel from '../Search/SearchPanel';

import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { useCatalogContentSizeDesktop } from '../../hooks/useCatalogContentSizeDesktop';
import { useTabs } from '../../hooks/useTabs';

const CatalogTabs = () => {
  const { catalogTabs, hideCatalogsHeader, activeTabIndex, onTabChange, setCatalogsState, catalogsState, hideTabs } = useCatalogsContext();
  const { handleTabChange, onChangeTab } = useTabs();
  const sizes = useCatalogContentSizeDesktop({ hideTabs });

  // Change tab if its should be changet outside the catalogs component
  // For example when need change tab if correspond field is in focus
  useEffect(() => {
    if (onTabChange && activeTabIndex >= 0 && catalogTabs) onChangeTab(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const catalogTabsLayout = catalogTabs.map((tab) => {
    const getLayout = () => {
      if (tab.tabLayout) {
        const showSearch = catalogsState.activeTab.showSearchPanelForCustomTabLayout;
        const scrollHeight = showSearch ? sizes.tabLayoutWithSearchPanelHeight : sizes.tabLayoutWithoutSearchPanelHeight;
        return (
          <div className="inline" id={`${catalogsState.activeTab?.name}_catalogContent`}>
            {showSearch && <SearchPanel />}
            {tab.tabLayout && tab.tabLayout({ catalogsState, setCatalogsState, scrollHeight })}
          </div>
        );
      }
      return <CatalogsSubTabs />;
    };

    return (
      <TabPanel className="h-full" header={tab.label} key={tab.codeType}>
        {getLayout()}
      </TabPanel>
    );
  });

  const getActiveTabIndex = () => {
    return catalogTabs?.findIndex((i) => i.name === catalogsState.activeTab.name);
  };

  return (
    <TabView
      className={hideCatalogsHeader ? 'catalog_tab_without_header_view' : 'catalog_tab_view'}
      activeIndex={getActiveTabIndex()}
      onTabChange={handleTabChange}
    >
      {catalogTabsLayout}
    </TabView>
  );
};

export default CatalogTabs;
