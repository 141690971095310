import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import CommonCard from '../../../../common/components/CommonCard';
import ClaimView from '../../../../claims/components/ClaimView';
import { onTabChange } from '../helpers/onTabChange';
import { patientDetailsTabs } from './../helpers/patientDetailsTabs';
import { updateClaimsState } from '../../../../claims/actions/claims.action.creators';
import { addNewOverlayItemsList } from '../../../../claims/helpers/addNewOverlayItemsList';
import { addNewTeleplan } from '../../../../claims/helpers/addNewInvoice';
import { t } from '../../../../../service/localization/i18n';

const InvoicesCard = ({ patient_details, claimsPage, isFetchingClaims, showSkeleton }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFetchingPatients } = useSelector((state) => state.patients);

  const viewMore = () => {
    //set first page for invoices data table
    //Patient profile - Money - Invoices - table show empty data
    claimsPage !== 1 && dispatch(updateClaimsState({ page: 0 }));

    onTabChange(patientDetailsTabs.money, history);
  };

  const invoicesHeaderActionButton = (
    <SplitButton
      className="p-button-rounded p-button-text"
      label={t('New_claim')}
      disabled={isFetchingPatients}
      model={addNewOverlayItemsList({ history, patient_details, isSplitButton: true })}
      onClick={() => addNewTeleplan({ history, patient_details })}
    />
  );

  return (
    <>
      <CommonCard
        title={`${t('Invoices.1')}/${t('Claims')}`}
        footerButtonLable={t('View_More')}
        showFooter={true}
        isFetching={isFetchingClaims}
        headerActionButton={invoicesHeaderActionButton}
        footerButtonOnClick={viewMore}
      >
        <ClaimView id="claims" showInCard={true} showSkeleton={showSkeleton} showOnPatientDetailsScreen={true} patientDetails={patient_details} />
      </CommonCard>
    </>
  );
};

export default InvoicesCard;
