import React from 'react';
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from 'react-hook-form';
import { InputWrapper } from '../../../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { t } from '../../../../../service/localization/i18n';
import { verifyAccessWord } from '../../../actions/patients.action.creators';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { integersAndLettersOnly } from '../../../../regex/regex';

const VerifyAccessWordDialog = ({ visible, onHide, patientGuid }) => {
  const dispatch = useDispatch();
  const { control, formState, handleSubmit, reset } = useForm({ defaultValues: { AccessWord: '' } });
  const errorMessage = formState.errors?.AccessWord?.message;

  const onSubmit = async (data) => {
    try {
      data.AccessWord = data?.AccessWord?.toLowerCase() || ''; // BE requrement convert AccessWord to lowercase
      const responce = await verifyAccessWord({ ...data, patientGuid });
      if (responce) {
        dispatch(setToastMessage({ type: 'success', message: "The patient's access word is correct" }));
      } else {
        dispatch(setToastMessage({ type: 'error', message: "The patient's access word is incorrect" }));
      }
      onCancel();
    } catch (error) {
      onCancel();
    }
  };

  const onCancel = () => {
    reset();
    onHide();
  };

  return (
    <Dialog
      header="Patient verification"
      visible={visible}
      breakpoints={{ '1366px': '30vw', '960px': '40vw', '768px': '95vw' }}
      style={{ width: '20vw' }}
      onHide={onCancel}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="AccessWord"
          control={control}
          rules={{ validate: (value) => !!value || t('Mandatory_field.1') }}
          render={({ field }) => (
            <InputWrapper name="AccessWord" label="Access Word" errorMessage={errorMessage}>
              <InputText autoFocus keyfilter={integersAndLettersOnly} type="text" className={errorMessage ? 'p-invalid' : ''} {...field} />
            </InputWrapper>
          )}
        />

        <div className="flex justify-content-end w-full gap-3">
          <Button type="button" className="p-button-text p-button-outlined flex-grow-1 sm:flex-grow-0" label={t('Cancel')} onClick={onCancel} />
          <Button type="submit" className="flex-grow-1 sm:flex-grow-0" label={t('Submit')} loading={formState?.isSubmitting} />
        </div>
      </form>
    </Dialog>
  );
};

export default VerifyAccessWordDialog;
