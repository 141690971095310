import React from 'react';
import { Button } from 'primereact/button';
import { icons } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';

const IconButton = ({ onClick, disabled }) => {
  return (
    <div className="pb-1">
      <Button
        className="p-button-text p-button-rounded"
        icon={icons.delete}
        disabled={disabled}
        tooltip={t('Delete_service')}
        tooltipOptions={{ position: 'top' }}
        onClick={onClick}
      />
    </div>
  );
};

const TextButton = ({ onClick, disabled }) => {
  return (
    <Button
      className="p-button-outlined w-max"
      icon={icons.delete}
      disabled={disabled}
      label={t('Delete_service')}
      tooltip={t('Delete_service')}
      tooltipOptions={{ position: 'top' }}
      onClick={onClick}
    />
  );
};

const Delete = ({ fields, onClick }) => {
  const disabled = fields?.length <= 1;

  return (
    <>
      <div className="hidden lg:block">
        <IconButton disabled={disabled} onClick={onClick} />
      </div>

      <div className="block lg:hidden">
        <TextButton disabled={disabled} onClick={onClick} />
      </div>
    </>
  );
};

export default Delete;
