import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { getCategoryType } from '../helpers/getCategoryType';
import { commonInputs } from '../../config/commonInputsConfig';
import { isCodeRecentlyUsed } from '../../utils/isCodeRecentlyUsed';
import { inputs } from './PrivateDetails/claimDetailsHelpers/inputs';
import { getFromLocalStorage } from '../../utils/getFromLocalStorage';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { formatMostResentService } from './PrivateDetails/claimDetailsHelpers/formatMostResentService';
import { isEmpty } from 'lodash';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';
import classes from './QuickPickItem.module.scss';
import { elementIDs } from '../../config/elementIDsConfig';

const QuickPickItem = React.forwardRef(
  (
    {
      item,
      isNew,
      record,
      onCodeSelect,
      onCodeDelete,
      timePikerLayout,
      setIsMaxCodeEntries,
      currentPractitionerPrefs,
      catalogIndex,
      setCatalogIndex,
      catalogsRolodex,
      setFocusFieldParams,
      useLabel = false,
      showDivider = true,
      showTitile = true,
      showInCatalogs = false,
      displayQuickPickInColumn = false
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const { dirty } = useSelector((state) => state.core);
    const { catalogMostRecentCodes, isFetchingInvoicesMostRecentCodes } = useSelector((state) => state.claims);
    const { isMobileOnly } = useSelector((state) => state.core.window);
    const mostRecentItem = catalogMostRecentCodes[getCategoryType(item?.codeType, record.InvoiceType)];

    const [switchReferredByButtonLabel, setSwitchReferredByButtonLabel] = useState(
      getFromLocalStorage(localStorageKeys.displayReferredByNames) ? t('Show_codes') : t('Show_names')
    );

    const isCodeSelected = (code, field) => {
      if (!isEmpty(record)) {
        const values = Array.isArray(record[field]) ? record[field] : record[field]?.split(',');
        return values?.find((i) => i?.toUpperCase()?.trim() === code?.toUpperCase()?.trim());
      }
    };

    const onCodeClick = (data, focusField) => {
      !!isCodeSelected(data.value, focusField.name)
        ? onCodeDelete(data, focusField, record, dispatch, isNew, dirty, currentPractitionerPrefs)
        : onCodeSelect({ data, focusField, record, currentPractitionerPrefs, setIsMaxCodeEntries, dispatch, isNew, dirty });
    };

    const onSwitchReferral = () => {
      const key = localStorageKeys.displayReferredByNames;
      setSwitchReferredByButtonLabel(getFromLocalStorage(key) ? t('Show_names') : t('Show_codes'));
      localStorage.setItem(key, !getFromLocalStorage(key));
    };

    const onViewMore = () => {
      const suspect = catalogsRolodex(record, isNew)?.find((i) => i.codeType === item?.codeType);
      const suspectIndex = catalogsRolodex(record, isNew)?.findIndex((i) => i.codeType === item?.codeType);
      suspectIndex && setCatalogIndex(suspectIndex);
      suspect && setFocusFieldParams(suspect);
    };

    const chips = (
      <div id={`${item.codeType}_items`} className={displayQuickPickInColumn ? 'p-ml--2' : ''}>
        {mostRecentItem?.map((i, index) => {
          const tooltip = () => {
            if (item.showSwitchButton) {
              // CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick
              const codeValue = !i?.label || i?.label === 'N/A' ? i?.label : i?.value;
              return getFromLocalStorage(localStorageKeys.displayReferredByNames) ? codeValue : i?.label;
            }

            if (!item.showSwitchButton) {
              // tooltip for serviceDate
              if (item?.codeType === commonInputs.serviceDate.codeType) return i?.weekday;

              // tooltip for privateServices
              if (item?.codeType === inputs().privateService.codeType) {
                return i?.unit ? `${i?.duration} ${i?.unit}` : '';
              }

              return i?.label;
            }
          };

          const codeLabel = () => {
            if (item.showSwitchButton) {
              // CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick
              const label = !i?.label || i?.label === 'N/A' ? i?.value : i?.label;
              return getFromLocalStorage(localStorageKeys.displayReferredByNames) ? label : i?.value;
            }

            if (!item.showSwitchButton) {
              // Private record services codes
              // CMO-1767 - New/Edit Invoice->Quick Pick->add duration and time to the services' name
              if (getCategoryType(item?.codeType, record.InvoiceType) === 'feecodes_pp') {
                const formatted = formatMostResentService(i);
                return formatted?.text;
              }

              // Teleplan record codes
              if (getCategoryType(item?.codeType, record.InvoiceType) !== 'feecodes_pp') {
                return useLabel ? i?.label : i?.value;
              }
            }
          };

          return (
            <Button
              id={`${elementIDs.quickPickCodeItem}_${index}`}
              className={cx(
                `${elementIDs.quickPickCodeItem}_${item.name}_${i?.value?.replaceAll(/[^A-Z0-9]+/gi, '')}`, // Need for cypress tests
                'p-button-rounded mx-1 mb-1 py-1 px-2',
                !!isCodeSelected(i?.value, item.name) ? '' : isCodeRecentlyUsed(i?.order) ? 'p-button-outlined' : 'p-button-text',
                i?.isWeekend ? 'p-button-danger' : ''
              )}
              key={`${i?.value}_${index}`}
              label={codeLabel()}
              // tooltip={tooltip()}
              tooltipOptions={{ position: 'top', showDelay: 1000 }}
              onClick={() => onCodeClick(i, item)}
            />
          );
        })}
      </div>
    );

    return (
      <div className={cx('mt-2 mb-1', classes.root)} key={`${item.name}`}>
        <div className={cx('align-items-start', isMobileOnly || displayQuickPickInColumn ? '' : 'flex')}>
          {showTitile && (
            <div>
              <div className={cx('large-text', isMobileOnly ? classes.lableMobile : classes.lableDesktop)}>
                {`${item.label}:`}
                {item.required && <span className="ml-1 p-error">*</span>}
              </div>
            </div>
          )}

          {isFetchingInvoicesMostRecentCodes ? (
            <>
              {showInCatalogs ? (
                <div style={{ minHeight: '33px' }}>
                  <Skeleton height="25px" width="300px" />
                </div>
              ) : (
                <Skeleton height="25px" />
              )}
            </>
          ) : (
            <div ref={ref} className={isMobileOnly ? 'mt-2' : ''}>
              {mostRecentItem?.length ? (
                <>
                  {chips}

                  {/* CMO-1676 - Display names instead of numbers in Referring practitioner on Quick Pick */}
                  {item.showSwitchButton && !showInCatalogs && (
                    <Button
                      id={`${item.codeType}_switch`}
                      className="p-button-rounded p-button-text p-button-info mx-1 mb-1 py-1 px-2"
                      label={switchReferredByButtonLabel}
                      icon="pi pi-sync"
                      iconPos="right"
                      onClick={onSwitchReferral}
                    />
                  )}

                  {/* CMO-1704 - Add "Show more" button for quick pick code catalogs */}
                  {item.viewMoreButton && catalogIndex === 0 && (
                    <Button
                      id={`${item.codeType}_viewMore`}
                      className="p-button-rounded p-button-text p-button-info mx-1 mb-1 py-1 px-2"
                      label={t('Open_Catalog')}
                      iconPos="right"
                      onClick={onViewMore}
                    />
                  )}
                </>
              ) : (
                <div className="mx-0 mb-1 py-1 px-3">{t('No_recent_codes')}</div>
              )}

              {item?.codeType === commonInputs.serviceDate.codeType && timePikerLayout && (
                <div className="flex">{timePikerLayout({ className: 'col-6 py-0 mt-3' })}</div>
              )}
            </div>
          )}
        </div>

        {showDivider && <Divider className="my-3" />}
      </div>
    );
  }
);

export default QuickPickItem;
