import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { invoiceTypes } from '../../helpers/defaultValues';
import { submissionCodes } from '../../../../helpers/mapper';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const SubmitionCodeDialog = () => {
  const { watch, onSubmit, localState, setLocalState } = useFormContext();
  const invoiceType = watch(inputs.payor.name);
  const submissionCode = submissionCodes.find((i) => i.value === watch(inputs.submission.name))?.label;
  const isWSBC = invoiceType === invoiceTypes.wsbc;

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      submitionCodeDialog: false
    }));
  };

  const proseedSubmission = () => {
    onHide();
    onSubmit(watch());
  };

  const actionButtons = (
    <>
      <Button label={t('Proceed')} onClick={proseedSubmission} />
      <Button className="p-button-outlined" label={t('Cancel')} onClick={onHide} />
    </>
  );

  const recomendationsForMSPandICBC = (
    <>
      <p>{`Recommendations:`}</p>

      <p>{`a) If you are resubmitting a declined claim, select submission code X and add a note.`}</p>

      <p>
        {`b) If this is a new claim, submit it with submission code A after receiving an approval for the late claim. The approval form is`}
        <a className="ml-1 underline" href="https://www2.gov.bc.ca/assets/gov/health/forms/2943fil.pdf" target="_blank" rel="noreferrer">
          {`here`}
        </a>
        .
      </p>
    </>
  );

  const recomendationsForWSBC = <p>{`Recommendations: Use submission code W.`}</p>;

  return (
    <Dialog
      header={t('Warning')}
      visible={localState.submitionCodeDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg">
        <p>{`This claim is over 90 days old. Selected submission code is ${submissionCode}.`}</p>

        {isWSBC ? recomendationsForWSBC : recomendationsForMSPandICBC}
      </div>
    </Dialog>
  );
};

export default SubmitionCodeDialog;
