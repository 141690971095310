import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { inputs } from '../../../helpers/inputs';
import { isTimeValid } from '../../../../../../utils/formatTime';
import {
  commentInputRequirements,
  endTimeInputRequirements,
  getReferralValueByFeeCode,
  invalidAgeMessage,
  startTimeInputRequirements
} from '../../../helpers/validationSchema';
import { filter, find, flatMap, flatten, get, groupBy, isEmpty, map, mapValues, some, uniqBy } from 'lodash';
import { checkIfCodeValid4Age, getPsychiatryCodesWithWrongDuration } from '../../../helpers/claimFormValidation';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';
import { isRecommendetTime } from '../../../helpers/isRecommendetTime';
import { getAgeAtDOS } from '../../../../../../utils/getAge';

export const useValidateGoupPicker = () => {
  const dispatch = useDispatch();
  const { watch, localState, setLocalState } = useFormContext();
  const { toastMessage } = useSelector((state) => state.core);

  const getAllUniqFeeCodes = () => {
    const allFeeCodes = localState.groupRecords.map((i) => i[inputs.feeCodes.codeType]);
    const flatAllFeeCodes = flatten(allFeeCodes);
    const uniqFeeCodes = uniqBy(flatAllFeeCodes, 'value');
    return uniqFeeCodes;
  };

  const isReferralStepRequired = () => {
    const feeCodesOnly = map(watch(inputs.feeCodes.codeType), (i) => i.value);
    const refToBy = getReferralValueByFeeCode(feeCodesOnly);
    return refToBy === 'B' || refToBy === 'T';
  };

  const isCommentRequired = ({ rowData }) => {
    const feeCodes = rowData[inputs.feeCodes.codeType];
    const startTime = rowData[inputs.startTime.name];
    const endTime = rowData[inputs.endTime.name];
    const submissionCode = rowData[inputs.submission.name];
    const invoiceType = rowData[inputs.payor.name];
    const saveAsDraft = rowData[inputs.saveAsDraft.name];
    const serviceDate = rowData[inputs.serviceDate.name];
    const units = rowData[inputs.units.name];
    const speciality = rowData[inputs.speciality.name];
    const isRequired = commentInputRequirements({
      submissionCode,
      invoiceType,
      saveAsDraft,
      serviceDate,
      startTime,
      endTime,
      feeCodes,
      step: localState.step,
      units,
      speciality
    });
    return isRequired;
  };

  const isCommentStepRequired = () => {
    const isRequired = localState.groupRecords?.some((i) => isCommentRequired({ rowData: i }));
    const isCommentInputRequired = some(localState.groupRecordsRequiredInputs, (item) => {
      const commentItem = item[inputs.comment.name];
      return commentItem && commentItem.required === true;
    });

    return isRequired || isCommentInputRequired;
  };

  const setCommentInputRequired = () => {
    localState.groupRecords.forEach((i) => {
      setLocalState((prevState) => {
        const groupRecordsRequiredInputs = setRequiredInput({
          groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
          rowId: i[inputs.groupRowId.name],
          inputName: inputs.comment.name,
          isRequired: isCommentRequired({ rowData: i }) && !i[inputs.comment.name],
          isAsterisk: isCommentRequired({ rowData: i })
        });
        return {
          ...prevState,
          groupRecordsRequiredInputs
        };
      });
    });
  };

  const isCodeInputValid = (inputName) => {
    const someOf =
      inputName === inputs.referral.name
        ? localState.groupRecords?.some((i) => i[inputs.refToBy.name] !== 'N' && !i[inputName]?.length)
        : localState.groupRecords?.some((i) => !i[inputName]?.length);

    if (someOf) {
      localState.groupRecords.forEach((i) => {
        const rowId = i[inputs.groupRowId.name];
        const isRequired =
          inputName === inputs.referral.name ? i[inputs.refToBy.name] !== 'N' && i[inputName]?.length === 0 : i[inputName]?.length === 0;

        setLocalState((prevState) => {
          const groupRecordsRequiredInputs = setRequiredInput({
            groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
            rowId,
            inputName,
            isRequired,
            isAsterisk: isRequired
          });

          return {
            ...prevState,
            groupRecordsRequiredInputs
          };
        });
      });

      const messages = {
        [inputs.feeCodes.name]: t('Please_fill_required_fee_fields'),
        [inputs.icd9.name]: t('Please_fill_required_dx_fields'),
        [inputs.referral.name]: t('Please_fill_required_referral_fields')
      };

      dispatch(setToastMessage({ type: 'warn', message: messages[inputName] }));
      return false;
    }

    return true;
  };

  const isFeeValid = () => {
    return isCodeInputValid(inputs.feeCodes.name);
  };

  const isDxValid = () => {
    return isCodeInputValid(inputs.icd9.name);
  };

  const isReferralValid = () => {
    return isCodeInputValid(inputs.referral.name);
  };

  const isCommentValid = () => {
    const recordsWithMissingComment = filter(localState.groupRecords, (i) => {
      const rowId = i[inputs.groupRowId.name];
      const isRequiredComment = isCommentRequired({ rowData: i });
      const isRequired = isRequiredInput(rowId, inputs.comment.name) || isRequiredComment;
      return isRequired && !i[inputs.comment.name];
    });

    if (!isEmpty(recordsWithMissingComment)) {
      const updatedGroupRecordsRequiredInputs = mapValues(localState.groupRecordsRequiredInputs, (record, key) => {
        const comment = find(localState.groupRecords, [inputs.groupRowId.name, key])?.[inputs.comment.name];
        const updatedInputs = mapValues(record, (input) => {
          if (input.isRequired && !comment) {
            return { ...input, errorMessage: t('Mandatory_field.1') };
          }
          return input;
        });

        return updatedInputs;
      });

      setLocalState((prevState) => ({
        ...prevState,
        groupRecordsRequiredInputs: updatedGroupRecordsRequiredInputs
      }));

      dispatch(setToastMessage({ type: 'warn', message: t('Please_fill_required_comment_fields') }));
      return false;
    }

    return true;
  };

  const isUnitsValid = () => {
    // Set error messages for inputs
    // Set toast message for invalid units
    const name = inputs.units.name;
    const records = localState.groupRecords;
    const message = t('One_of_the_claims_resulted_in_zero_units');
    const reply = records?.every((i) => Number(i[name]) >= 1); // Check if all units are greater than or equal to 1

    // If any of the units are less than 1, then show required inputs and a warning toast message
    if (!reply) {
      records?.forEach((i) => {
        const rowId = i[inputs.groupRowId.name];
        const isRequired = Number(i[name]) < 1;
        const errorMessage = isRequired ? t('At_least_1') : '';

        // Update the local state with required input information
        setLocalState((prevState) => {
          const groupRecordsRequiredInputs = setRequiredInput({
            groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
            rowId,
            inputName: name,
            isRequired,
            isAsterisk: isRequired,
            message: errorMessage
          });

          return { ...prevState, groupRecordsRequiredInputs };
        });

        // Show a warning toast message if required units are invalid and the message hasn't been shown already
        isRequired && toastMessage.message !== message && dispatch(setToastMessage({ type: 'warn', message }));
      });
    }

    return reply;
  };

  const showInvalidUnitsDialog = () => {
    return localState.groupRecords?.some((i) => {
      const feeCodes = i[inputs.feeCodes.codeType];
      const feeCode = feeCodes?.[0];
      const units = Number(i[inputs.units.name]);
      const maxUnits = feeCode?.max_unit ? Number(feeCode?.max_unit) : undefined;
      return maxUnits && maxUnits !== -1 && (units > maxUnits || units === 0);
    });
  };

  const showAsterisksForTimeInput = (inputName, feeCodes, rowId) => {
    if (!feeCodes?.length) return;

    const emergency = watch(inputs.emergency.name);
    const saveAsDraft = watch(inputs.saveAsDraft.name);
    const speciality = watch(inputs.speciality.name);
    const invoiceType = watch(inputs.payor.name);

    const isRecommendet = isRecommendetTime({ feeCodes });
    const isRequiredTime = showAsterisk(rowId, inputName);
    const codesOnly = [feeCodes[0].value];
    const feeCodeRequiresTimeToCalculateUnits = !!feeCodes[0]?.min_per_unit;
    const isStartEndTimeRequired =
      inputName === inputs.startTime.name
        ? startTimeInputRequirements(emergency, codesOnly, saveAsDraft, speciality, invoiceType)
        : endTimeInputRequirements(emergency, codesOnly, saveAsDraft, speciality, invoiceType);

    return !isRecommendet && (isRequiredTime || isStartEndTimeRequired || feeCodeRequiresTimeToCalculateUnits);
  };

  const showAsterisk = (rowId, inputName) => {
    const isRequired = get(localState, 'groupRecordsRequiredInputs', {})[rowId]?.[inputName]?.showAsterisk ?? false;
    return isRequired;
  };

  const setAsterisks = () => {
    const duplicatedItems = getAllDuplicatedRecords();

    const updatedRequiredInputs = duplicatedItems?.reduce((requiredInputs, item) => {
      const rowId = item[inputs.groupRowId.name];
      return {
        ...requiredInputs,
        [rowId]: requiredInputsForDuplicateRecord
      };
    }, {});

    const newGroupRecordsRequiredInputs = {
      ...localState.groupRecordsRequiredInputs,
      ...updatedRequiredInputs
    };

    // Update the local state with the new required inputs
    setLocalState((prevState) => ({
      ...prevState,
      groupRecordsRequiredInputs: newGroupRecordsRequiredInputs
    }));
  };

  const getAllDuplicatedRecords = (groupRecords) => {
    const groupRecordsList = groupRecords || localState.groupRecords;
    const groupedByCondition = groupBy(groupRecordsList, (i) => {
      const patientGuid = get(i, [inputs.patient.name, 0, 'PatientGuid']);
      const feeCode = get(i, [inputs.feeCodes.codeType, 0, 'value']);
      return `${patientGuid}_${feeCode}`;
    });

    const duplicatedItemsGroups = filter(groupedByCondition, (items) => items.length > 1);
    const duplicatedItems = flatMap(duplicatedItemsGroups, (items) => items);

    return duplicatedItems;
  };

  const isRecordDuplicated = (patientGuid, feeCode) => {
    return some(localState.groupRecords, (i) => {
      return feeCode === i[inputs.feeCodes.codeType][0]?.value && patientGuid === i[inputs.patient.name][0]?.PatientGuid;
    });
  };

  const isTimeValidForRow = (inputName, time, feeCodes, rowId) => {
    if (!feeCodes?.length) return;

    const isEmergency = watch(inputs.emergency.name);
    const isSaveAsDraft = watch(inputs.saveAsDraft.name);
    const specialty = watch(inputs.speciality.name);
    const invoiceType = watch(inputs.payor.name);

    const isRequiredTime = isRequiredInput(rowId, inputName);
    const isValidTime = isTimeValid(time);
    const firstFeeCodeValue = [feeCodes[0].value];
    const feeCodeRequiresTimeToCalculateUnits = !!feeCodes[0]?.min_per_unit;
    const isStartEndTimeRequired =
      inputName === inputs.startTime.name
        ? startTimeInputRequirements(isEmergency, firstFeeCodeValue, isSaveAsDraft, specialty, invoiceType)
        : endTimeInputRequirements(isEmergency, firstFeeCodeValue, isSaveAsDraft, specialty, invoiceType);

    if (isRequiredTime || isStartEndTimeRequired || feeCodeRequiresTimeToCalculateUnits) return !isValidTime;
    return false;
  };

  const isTimeValidForFee = (inputName) => {
    return localState.groupRecords.every((i) => {
      const feeCodes = i[inputs.feeCodes.codeType];
      const rowId = i[inputs.groupRowId.name];
      const isRecommendet = isRecommendetTime({ feeCodes });
      return isRecommendet || !isTimeValidForRow(inputName, i[inputName], feeCodes, rowId);
    });
  };

  const setRequiredInput = ({ groupRecordsRequiredInputs, rowId, inputName, isRequired, message, isAsterisk }) => {
    const errorMessage = message || (isRequired ? t('Mandatory_field.1') : '');

    return {
      ...groupRecordsRequiredInputs,
      [rowId]: {
        ...groupRecordsRequiredInputs[rowId],
        [inputName]: {
          required: isRequired,
          errorMessage,
          showAsterisk: isAsterisk
        }
      }
    };
  };

  const setAllRequiredInputs = ({ groupRecordsRequiredInputs, isRequired, isAsterisk, inputName }) => {
    let requiredInputs = groupRecordsRequiredInputs;

    Object.keys(requiredInputs).forEach((rowId) => {
      Object.keys(requiredInputs[rowId]).forEach(() => {
        requiredInputs = setRequiredInput({
          groupRecordsRequiredInputs: requiredInputs,
          rowId,
          inputName,
          isRequired: isRequired,
          isAsterisk: isAsterisk
        });
      });
    });

    return requiredInputs;
  };

  const getErrorMessage = (rowId, inputName) => {
    return get(localState, ['groupRecordsRequiredInputs', rowId, inputName, 'errorMessage'], null);
  };

  const isRequiredInput = (rowId, inputName) => {
    return get(localState, 'groupRecordsRequiredInputs', {})[rowId]?.[inputName]?.required ?? false;
  };

  const setRequiredTimeForInputs = (inputName, time, feeCodes, rowId) => {
    const isRecommendet = isRecommendetTime({ feeCodes });
    const isRequired = !isRecommendet && isTimeValidForRow(inputName, time, feeCodes, rowId);

    setLocalState((prevState) => {
      const groupRecordsRequiredInputs = setRequiredInput({
        groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
        rowId,
        inputName,
        isRequired,
        isAsterisk: isRequired
      });
      return {
        ...prevState,
        groupRecordsRequiredInputs
      };
    });
  };

  const checkIsTimeValid = (inputName) => {
    localState.groupRecords.forEach((i, index) => {
      const feeCodes = i[inputs.feeCodes.codeType];
      const rowId = i[inputs.groupRowId.name];
      setRequiredTimeForInputs(inputName, i[inputName], feeCodes, rowId, index);
    });

    const isValid = isTimeValidForFee(inputName);

    if (!isValid) {
      const message = t('Please_fill_required_time_fields');
      toastMessage.message !== message && dispatch(setToastMessage({ type: 'warn', message }));
      return false;
    }

    return true;
  };

  const setIsValidForAgeErrors = () => {
    localState.groupRecords.forEach((i) => {
      const feeCode = i[inputs.feeCodes.codeType]?.[0]?.value;
      const serviceDate = i[inputs.serviceDate.name];
      const patient = i[inputs.patient.name]?.[0];
      const age = patient ? getAgeAtDOS(patient.BirthDay, serviceDate) : null;
      const isValidAge = feeCode ? checkIfCodeValid4Age(feeCode, age) : true;

      if (!isValidAge) {
        setLocalState((prevState) => {
          const groupRecordsRequiredInputs = setRequiredInput({
            groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
            rowId: i[inputs.groupRowId.name],
            inputName: inputs.feeCodes.name,
            message: invalidAgeMessage([feeCode]),
            isRequired: true,
            isAsterisk: true
          });

          return {
            ...prevState,
            groupRecordsRequiredInputs
          };
        });
      }
    });
  };

  const checkIsAgeValid = () => {
    setIsValidForAgeErrors();

    const isValid4Age = localState.groupRecords.some((i) => {
      const feeCode = i[inputs.feeCodes.codeType]?.[0]?.value;
      const serviceDate = i[inputs.serviceDate.name];
      const patient = i[inputs.patient.name]?.[0];
      const age = patient ? getAgeAtDOS(patient.BirthDay, serviceDate) : null;
      const isValidAge = feeCode ? checkIfCodeValid4Age(feeCode, age) : true;
      return !isValidAge;
    });

    return !isValid4Age;
  };

  const getNotMatchDurationForPsychiatryCodesList = () => {
    const notMatchDurationForFeeCodesList = localState.groupRecords.filter((i) => {
      const feeCodes = i[inputs.feeCodes.codeType];
      const startTime = i[inputs.startTime.name];
      const endTime = i[inputs.endTime.name];
      const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });
      return psychiatryCodesList?.length;
    });

    return notMatchDurationForFeeCodesList;
  };

  const isStartTimeValid = () => checkIsTimeValid(inputs.startTime.name);
  const isEndTimeValid = () => checkIsTimeValid(inputs.endTime.name);

  const isTimeOverMidnight = (record) => {
    const prevRecord = localState.groupRecords[0];
    const firstRowStartTime = prevRecord ? prevRecord[inputs.startTime.name] : '';
    const startTime = record[inputs.startTime.name];
    const isValidTime = isTimeValid(startTime) && isTimeValid(firstRowStartTime);

    // Do not use timeDiff function to calculate duration!
    const duration = isValidTime ? moment(startTime, 'HH:mm').diff(moment(firstRowStartTime, 'HH:mm'), 'minutes') : 0;
    return duration < 0;
  };

  const requiredInputsForDuplicateRecord = {
    [inputs.startTime.name]: { required: true, showAsterisk: true, errorMessage: '' },
    [inputs.endTime.name]: { required: true, showAsterisk: true, errorMessage: '' },
    [inputs.comment.name]: { required: true, showAsterisk: true, errorMessage: '' }
  };

  const updatedValuesForDuplicateRecord = {
    [inputs.submission.name]: 'D',
    [inputs.isDuplicated.name]: true
  };

  return {
    isDxValid,
    isFeeValid,
    isUnitsValid,
    showAsterisk,
    setAsterisks,
    isEndTimeValid,
    isCommentValid,
    isRequiredInput,
    checkIsAgeValid,
    isReferralValid,
    getErrorMessage,
    setRequiredInput,
    isStartTimeValid,
    isCommentRequired,
    isTimeOverMidnight,
    isRecordDuplicated,
    setAllRequiredInputs,
    isCommentStepRequired,
    showInvalidUnitsDialog,
    isReferralStepRequired,
    getAllDuplicatedRecords,
    showAsterisksForTimeInput,
    setCommentInputRequired,
    getNotMatchDurationForPsychiatryCodesList,
    updatedValuesForDuplicateRecord,
    requiredInputsForDuplicateRecord
  };
};
