import { claimsDefaultFilters } from '../../config/defaultValuesConfig';
import { setClaimFiltersWithRequest, setSelectedCodes, statusOptionsAction } from '../actions/claims.action.creators';

export const clearFilters = (dispatch, recordType, allStatuses) => {
  dispatch(setClaimFiltersWithRequest({}));
  dispatch(setSelectedCodes([]));
  if (recordType && recordType !== '-2') {
    dispatch(statusOptionsAction(allStatuses));
  }
};

export const clearFiltersExceptFreetext = (dispatch, freetext) => {
  dispatch(setClaimFiltersWithRequest({ ...claimsDefaultFilters, showArchived: 'True', freetext }));
};
