import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getInvoicesCatalogMostRecentCodes } from '../../../actions/claims.action.creators';
import { validationSchema } from '../helpers/validationSchema';
import { localStateInitValues } from '../helpers/wsbcReportInitialState';
import { getDefaultValues } from '../helpers/defaultValues';
import { inputs } from '../helpers/inputs';
import { useSubmit } from './useSubmit';
import { getPatientClaimsData } from '../helpers/getPatientClaimsData';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const useDefaultValues = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { wsbcRecord } = useSelector((state) => state.claims);

  const [localState, setLocalState] = useState(localStateInitValues);

  // This condition should match with media query for mobile view in WSBCReport.scss
  // window.innerWidth < 1024 is corresponds to @media screen and (max-width: 1023px)
  const isMobile = window.innerWidth < 1024;

  const isNew = !params.id;

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema)
  });

  const setEmptyForm = () => {
    dispatch(getInvoicesCatalogMostRecentCodes({}));
  };

  const setPrefilledForm = () => {
    const patient = wsbcRecord.formState[inputs.patient.name][0];
    // Get most recent codes list
    dispatch(getInvoicesCatalogMostRecentCodes({ patientGuid: patient?.PatientGuid }));

    // Get patient claims
    patient?.PatientGuid &&
      getPatientClaimsData({
        patientGuid: patient?.PatientGuid,
        errors: methods.formState.errors,
        clearErrors: methods.clearErrors,
        setValue: methods.setValue
      });

    methods.reset(wsbcRecord.formState);
    setLocalState(wsbcRecord.localState);

    // CMO-2315 - New Teleplan POS -> after used added a patient from this screen, the screen status remains clean
    methods.setValue(commonInputs.fantom.name, `${Math.random()}`, { shouldDirty: true });

    return;
  };

  const { onSubmit } = useSubmit({ reset: methods.reset, setLocalState, isNew });

  return {
    isNew,
    localState,
    setLocalState,
    isMobile,
    onSubmit,
    setEmptyForm,
    setPrefilledForm,
    errors: methods.formState.errors,
    isDirty: methods.formState.isDirty,
    ...methods
  };
};
