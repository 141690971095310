import { isEmpty } from 'lodash';

export const updatePreference = (preferences, newValue, prefsCode) => {
  // Params example:

  // preferences = {
  //   content: [
  //     { value: 'en', label: '1' },
  //     { value: 'deeppurple', label: '666' }
  //   ],
  //   label: "display",
  //   value: "54b5bc29-24c9-482f-88c8-4f4b101bb803",
  // }

  // newValue = "purple"

  // prefsCode = "666"

  const themePreference = preferences?.content?.find((i) => i.label === prefsCode);
  const content = isEmpty(themePreference)
    ? [...preferences?.content, { value: newValue, label: prefsCode }]
    : preferences?.content?.map((i) => (i.label === prefsCode ? { ...i, value: newValue } : i));

  return {
    ...preferences,
    content
  };
};
