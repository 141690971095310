import { Divider } from 'primereact/divider';
import MailSidebar from './MailSidebar';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';

const MailsLayout = ({ children }) => {
  return (
    <div className="flex justify-content-center w-full" style={{ height: 'calc(100vh - 50px)' }}>
      <div className="w-full" style={{ maxWidth: '1600px' }}>
        <PageWrap>
          <div className="card w-full m-0" style={{ height: 'calc(100vh - 75px)' }}>
            <div className="flex flex-column md:flex-row gap-2 md:gap-0 h-full">
              <div className="w-full md:w-3 xl:w-2">
                <MailSidebar />
              </div>
              <Divider className="hidden md:block" layout="vertical" />
              <div className="md:w-9 xl:w-10">{children}</div>
            </div>
          </div>
        </PageWrap>
      </div>
    </div>
  );
};

export default MailsLayout;
