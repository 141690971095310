import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Card } from 'primereact/card';
import Stepper from '../Layouts/Stepper';
import SomethingWentWrong from './SomethingWentWrong';
import SuccessResult from './SuccessResult';
import ErrorResult from './ErrorResult';

import { inputs } from '../../helpers/inputs';
import { getEClaimResult } from '../../../../actions/claims.action.creators';
import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { isEClaimStatusError } from '../../helpers/getEClaimStatus';
import { t } from '../../../../../../service/localization/i18n';

const ResultsPreview = () => {
  const dispatch = useDispatch();
  const { getValues, setLocalState, localState } = useFormContext();
  const claimGuid = getValues(inputs.claimGuid.name);
  const [loading, setLoading] = useState(false);
  const eClaimResult = localState.eClaimResult;
  const replyType = eClaimResult.ReplyType;
  const isError = isEClaimStatusError(replyType);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getEClaimResult(claimGuid);
        setLocalState((prevState) => ({ ...prevState, eClaimResult: result }));
        // setEClaimResult(result);
        setLoading(false);
        // Handle the result or perform any other necessary actions
      } catch (error) {
        setLoading(false);
        dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-content-center preview_content_root no_printContent">
      <div className="flex flex-column justify-content-center align-items-center preview_root_container">
        <Card className="w-full container">
          <div className="flex flex-column text-xl text-center">
            <div className="pb-5">
              <Stepper />
            </div>
            {!eClaimResult && !loading && <SomethingWentWrong />}
            {!eClaimResult && loading && <div>Loading...</div>}
            {!loading && eClaimResult && !isError && <SuccessResult eClaimResult={eClaimResult} />}
            {!loading && eClaimResult && isError && <ErrorResult eClaimResult={eClaimResult} />}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ResultsPreview;
