import React from 'react';
import { useFormContext } from 'react-hook-form';

import SuccessPage from '../../../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { sumBy } from 'lodash';
import { inputs } from '../../helpers/inputs';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { localStateInitValues } from '../../helpers/initialState';

const Success = () => {
  const { localState, getValues, setEmptyForm, setLocalState } = useFormContext();
  const patients = getValues(inputs.patient.name);
  const sendNow = localState.sendNow;
  const services = getValues(inputs.services.name);
  const InsurerNameEn = getValues(inputs.primaryPolicy.name)?.InsurerNameEn;
  const patientName = patients?.[0] ? patientFullNameWithAge(patients[0]) : '';
  const totalAmount = currencyFormat(sumBy(services, 'lessMSP'));
  const saveTypeText = sendNow ? 'submited' : 'saved';

  const successMessage = `You ${saveTypeText} claim(s) for ${totalAmount} for ${patientName} to ${InsurerNameEn}`;

  const billSameEClaim = () => {
    setLocalState(localStateInitValues);
    setEmptyForm();
  };

  return (
    <SuccessPage successMessage={successMessage}>
      <SuccessActionButtons patientsList={patients} billSameEClaim={billSameEClaim} />
    </SuccessPage>
  );
};

export default Success;
