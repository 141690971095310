import { store } from '../../../../../store';
import { inputs } from './inputs';
import { getServiceDate } from '../../../../utils/getDate';
import { getInitPractitionerGuid } from '../../../../utils/getPractitioner';
import { getDefaultPrefs, getPresetPrefs } from '../../../helpers/getDefaultPrefs';
import { hideEmergency, showRuralCodeInput, hideTimeInputs, showFacility, showSubFacility, showMultipleServiceDate } from './inputsConditions';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { getSpeciality, isSupplementary } from '../../../../config/specialitiesConfig';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { defaultBMI, defaultLocationCode } from '../../../../config/defaultValuesConfig';
import { defaulFacilitytCode } from '../config/defaultValues';
import { find, isEmpty } from 'lodash';
import { convertInvoiceType } from './convertInvoiceType';
import { getSubmissionCode } from './getSubmissionCode';
import * as dropdownOptions from './dropdownOptions';

export const getDefaultValues = ({ currentPractitionerPrefs = {}, initValues = {}, isNew, firstVisit, isGroup, speciality }) => {
  // isNew - REQUIRED!!!
  // firstVisit - REQUIRED!!!

  const clinic = store.getState().clinic.details;
  const practitioners = store.getState().clinic.members;

  const initPractitionerGuid = initPractitioner(initValues);
  const currentPractitioner = find(practitioners, (i) => i.DoctorGuid?.toUpperCase() === initPractitionerGuid?.toUpperCase());
  const currentSpeciality = speciality ?? Number(currentPractitioner?.Speciality);
  const practitionerPrefs = currentPractitionerPrefs?.content;
  const invoiceType = initInvoiceType({
    speciality: currentSpeciality,
    initValues,
    preferences: practitionerPrefs,
    isGroup,
    practitionerGuid: initPractitionerGuid
  });
  const feeCodes = feeInit(currentSpeciality, initValues);
  const dxCodes = dxInit(currentSpeciality, initValues);
  const aoiCodes = aoiInit({ initValues });
  const noiCodes = noiInit({ initValues });
  const referralCodes = referralCodesInit({ speciality: currentSpeciality, initValues, invoiceType });
  const initEmergency = emergencyInit({ preferences: practitionerPrefs, speciality: currentSpeciality, isGroup, isNew, invoiceType });
  const initTravel = travelInit({ preferences: practitionerPrefs, speciality: currentSpeciality, isGroup, isNew, invoiceType });
  const initCCFPP = ccfppInit({ preferences: practitionerPrefs, speciality: currentSpeciality, isGroup, isNew, invoiceType });
  const status = initValues[inputs.status.name] || '0';
  const serviceDate = serviseDateInit({ speciality: currentSpeciality, initValues, invoiceType }); // [KS] CMO-1423 - Persist service date during session,
  const submissionCode = submissionCodeInit({ initValues, invoiceType, status, serviceDate, isNew });

  const commonValues = {
    // === no inputs values === //
    [inputs.dcnguid.name]: initValues[inputs.dcnguid.name] || clinic.DCNGuid,
    [inputs.claimGuid.name]: initValues[inputs.claimGuid.name] || '',
    [inputs.invoiceGuid.name]: initValues[inputs.invoiceGuid.name] || '',
    [inputs.recordNumber.name]: initValues[inputs.recordNumber.name] || '',
    [inputs.sequenceNum.name]: initValues[inputs.sequenceNum.name] || '',
    [inputs.speciality.name]: initValues[inputs.speciality.name] || currentSpeciality,
    [inputs.status.name]: status,
    [inputs.statusText.name]: initValues[inputs.statusText.name] || 'Outstanding',
    [inputs.patientClaims.name]: initValues[inputs.patientClaims.name] || {},
    [inputs.sendNow.name]: initValues[inputs.sendNow.name] || false,
    [inputs.practitionerNumber.name]: practitionerNumberInit(initValues),
    // ========================= //

    [inputs.practitioner.name]: initPractitionerGuid,
    [inputs.facility.name]: facilityInit(practitionerPrefs, currentSpeciality, initValues),
    [inputs.subFacility.name]: subFacilityInit(practitionerPrefs, currentSpeciality, initValues),
    [inputs.payeeNumber.name]: payeeNumberInit(practitionerPrefs, initPractitionerGuid, initValues),
    [inputs.ruralCode.name]: ruralCodeInit(practitionerPrefs, currentSpeciality, initValues),
    [inputs.locationCode.name]: locationCodeInit(practitionerPrefs, currentSpeciality, initValues),

    [inputs.patient.name]: initValues[inputs.patient.name] || [],
    [inputs.payor.name]: invoiceType,
    [inputs.serviceDate.name]: serviceDate,

    [inputs.claimNumber.name]: initValues[inputs.claimNumber.name] || '',
    [inputs.injuryDate.name]: initValues[inputs.injuryDate.name] || null,
    [inputs.anatomicCode.name]: initValues[inputs.anatomicCode.name] || defaultAnatomicCodeForWSBC,

    ...aoiCodes,
    ...noiCodes,
    ...feeCodes,
    ...dxCodes,

    [inputs.reasonFor.name]: initValues[inputs.reasonFor.name] || [],
    [inputs.bmi.name]: initValues[inputs.bmi.name] || defaultBMI,

    [inputs.emergency.name]: initValues[inputs.emergency.name] || initEmergency,
    [inputs.travel.name]: initValues[inputs.travel.name] || initTravel,
    [inputs.ccfpp.name]: initValues[inputs.ccfpp.name] || initCCFPP,

    [inputs.callTime.name]: timeInit({ initValues, field: inputs.callTime.name, speciality: currentSpeciality, invoiceType }),
    [inputs.startTime.name]: timeInit({ initValues, field: inputs.startTime.name, speciality: currentSpeciality, invoiceType }),
    [inputs.endTime.name]: timeInit({ initValues, field: inputs.endTime.name, speciality: currentSpeciality, invoiceType }),

    [inputs.refToBy.name]: refToByInit({ preferences: practitionerPrefs, speciality: currentSpeciality, isGroup, initValues, invoiceType }),

    [inputs.referral.name]: referralCodes[inputs.referral.name],
    [inputs.referral.codeDescription]: referralCodes[inputs.referral.codeDescription],
    [inputs.referral.codeType]: referralCodes[inputs.referral.codeType],

    [inputs.submission.name]: submissionCode,
    [inputs.comment.name]: initValues[inputs.comment.name] || '',
    [commonInputs.submitWithGenericPHN.name]: initValues[commonInputs.submitWithGenericPHN.name] || '0',
    [inputs.saveAsDraft.name]: initValues[inputs.saveAsDraft.name] || false
  };

  // Inputs for Group Picker
  if (isGroup) {
    return {
      ...commonValues,
      [inputs.units.name]: 1,
      [inputs.percent.name]: 100
    };
  }

  // Inputs for First Visit
  if (firstVisit) {
    return {
      ...commonValues,
      ...inputsForFirstVisit,
      [inputs.patientGuid.name]: clinic.DCNGuid
    };
  }

  // Inputs for edit invoice
  if (!isNew) {
    return {
      ...commonValues,
      [inputs.units.name]: initValues[inputs.units.name] || 0,
      [inputs.percent.name]: initValues[inputs.percent.name] || 0,
      [inputs.feeAmount.name]: initValues[inputs.feeAmount.name] || 0,
      [inputs.feeTotal.name]: initValues[inputs.feeTotal.name] || 0,
      [inputs.feePaid.name]: initValues[inputs.feePaid.name] || 0,
      [inputs.mspCodes.name]: initValues[inputs.mspCodes.name] || [],
      [inputs.dateCreated.name]: initValues[inputs.dateCreated.name] || '',
      [inputs.dateUpdated.name]: initValues[inputs.dateUpdated.name] || '',
      [inputs.sentDate.name]: initValues[inputs.sentDate.name] || '',
      TotalNotes: initValues.TotalNotes || 0
    };
  }

  return commonValues;
};

export const inputsForFirstVisit = {
  [inputs.phnProvince.name]: 'BC',
  [inputs.phn.name]: '',
  [inputs.birthDay.name]: null,
  [inputs.lastName.name]: '',
  [inputs.firstName.name]: '',
  [inputs.midName.name]: '',
  [inputs.nickname.name]: '',
  [inputs.accessWord.name]: '',
  [inputs.gender.name]: '',
  [inputs.addressLine.name]: '',
  [inputs.city.name]: '',
  [inputs.province.name]: 'BC',
  [inputs.zip.name]: ''
};

export const defaultAnatomicCodeForWSBC = dropdownOptions.anatomicCodesDropdownOptions[0].value;

export const invoiceTypes = {
  msp: dropdownOptions.findPayorCode('M').label,
  wsbc: dropdownOptions.findPayorCode('W').label,
  icbc: dropdownOptions.findPayorCode('I').label
};

const initPractitioner = (initValues) => {
  if (!isEmpty(initValues)) return initValues[inputs.practitioner.name];
  return getInitPractitionerGuid();
};

const practitionerNumberInit = (initValues) => {
  if (!isEmpty(initValues)) {
    return initValues[inputs.practitionerNumber.name] || '';
  }

  const practitioners = store.getState().clinic.members;
  const currentPractitionerId = initPractitioner(initValues);
  const practitionerNum = practitioners?.find((i) => i.DoctorGuid.toUpperCase() === currentPractitionerId?.toUpperCase())?.PractitionerNumber || '';

  return practitionerNum;
};

export const serviseDateInit = ({ speciality, initValues, invoiceType }) => {
  const showMultipleDates = showMultipleServiceDate(speciality, invoiceType);
  const initServiceDate = initValues?.[inputs.serviceDate.name];

  if (!isEmpty(initValues) && initServiceDate) {
    return showMultipleDates && !Array.isArray(initServiceDate) ? [initServiceDate] : initServiceDate;
  }

  const date = getServiceDate();
  return showMultipleDates ? [date] : date;
};

export const initInvoiceType = ({ initValues, preferences, isGroup, practitionerGuid }) => {
  if (!isEmpty(initValues) && initValues[inputs.payor.name]) {
    return initValues[inputs.payor.name];
  }

  const defaultValue = invoiceTypes.msp;
  const members = store.getState().clinic.members;
  const currentPractitioner = members?.find((i) => i.DoctorGuid?.toUpperCase() === practitionerGuid?.toUpperCase());
  const prefsValue = getPresetPrefs(preferences, prefsCodes.invoiceType);
  const canBillWCB = currentPractitioner?.canBillWCB;
  const canBillICBC = currentPractitioner?.canBillICBC;
  const isDefaultValue = isGroup || (!canBillWCB && !canBillICBC) || (!canBillWCB && prefsValue === 'W') || (!canBillICBC && prefsValue === 'I');

  // Only MSP avalible for group (batch) form
  if (isDefaultValue) return defaultValue;

  const invoiceType = prefsValue ? convertInvoiceType(prefsValue, true) : null;
  const initValue = invoiceType || defaultValue;
  return initValue;
};

export const feeInit = (speciality, initValues) => {
  const feeCodeType = inputs.feeCodes.codeType;

  if (!isEmpty(initValues)) {
    return {
      [inputs.feeCodes.name]: initValues[inputs.feeCodes.name] || [],
      [inputs.feeCodes.codeDescription]: initValues[inputs.feeCodes.codeDescription] || [],
      [feeCodeType]: initValues[feeCodeType] || []
    };
  }

  const suspect = getSpeciality(speciality);

  if (suspect[feeCodeType]?.length) {
    const feeCodes = suspect[feeCodeType];
    return {
      [inputs.feeCodes.name]: feeCodes?.map((i) => i.value),
      [inputs.feeCodes.codeDescription]: feeCodes?.map((i) => i.label),
      [feeCodeType]: feeCodes
    };
  }

  return {
    [inputs.feeCodes.name]: [],
    [inputs.feeCodes.codeDescription]: [],
    [feeCodeType]: []
  };
};

export const dxInit = (speciality, initValues) => {
  const icd9CodeType = inputs.icd9.codeType;

  if (!isEmpty(initValues)) {
    return {
      [inputs.icd9.name]: initValues[inputs.icd9.name] || [],
      [inputs.icd9.codeDescription]: initValues[inputs.icd9.codeDescription] || [],
      [icd9CodeType]: initValues[icd9CodeType] || []
    };
  }

  const suspect = getSpeciality(speciality);
  if (suspect[icd9CodeType]?.length) {
    const dx = suspect[icd9CodeType];
    return {
      [inputs.icd9.name]: dx?.map((i) => i.value),
      [inputs.icd9.codeDescription]: dx?.map((i) => i.label),
      [icd9CodeType]: dx
    };
  }

  return {
    [inputs.icd9.name]: [],
    [inputs.icd9.codeDescription]: [],
    [icd9CodeType]: []
  };
};

export const aoiInit = ({ initValues } = {}) => {
  if (!isEmpty(initValues)) {
    return {
      [inputs.aoi.name]: initValues[inputs.aoi.name] || [],
      [inputs.aoi.codeDescription]: initValues[inputs.aoi.codeDescription] || [],
      [inputs.aoi.codeType]: initValues[inputs.aoi.codeType] || []
    };
  }

  return {
    [inputs.aoi.name]: [],
    [inputs.aoi.codeDescription]: [],
    [inputs.aoi.codeType]: []
  };
};

export const noiInit = ({ initValues } = {}) => {
  if (!isEmpty(initValues)) {
    return {
      [inputs.noi.name]: initValues[inputs.noi.name] || [],
      [inputs.noi.codeDescription]: initValues[inputs.noi.codeDescription] || [],
      [inputs.noi.codeType]: initValues[inputs.noi.codeType] || []
    };
  }

  return {
    [inputs.noi.name]: [],
    [inputs.noi.codeDescription]: [],
    [inputs.noi.codeType]: []
  };
};

export const referralCodesInit = ({ speciality, initValues, invoiceType }) => {
  if (!isEmpty(initValues)) {
    return {
      [inputs.referral.name]: initValues[inputs.referral.name] || [],
      [inputs.referral.codeDescription]: initValues[inputs.referral.codeDescription] || [],
      [inputs.referral.codeType]: initValues[inputs.referral.codeType] || []
    };
  }

  const _isSupplementary = isSupplementary(speciality, invoiceType);
  const emptyValues = {
    [inputs.referral.name]: [],
    [inputs.referral.codeDescription]: [],
    [inputs.referral.codeType]: []
  };

  if (_isSupplementary) return emptyValues;

  return emptyValues;
};

const timeInit = ({ initValues, field, speciality, invoiceType }) => {
  const hideInputs = hideTimeInputs(speciality, invoiceType);

  if (hideInputs) return '';
  if (initValues) return initValues[field];
  return '';
};

const submissionCodeInit = ({ initValues, invoiceType, status, serviceDate, isNew }) => {
  const currentServiceDate = initValues?.[inputs.serviceDate.name] || serviceDate;
  const initSubmissionCode = initValues?.[inputs.submission.name];
  const submission = getSubmissionCode({
    invoiceType,
    status,
    serviceDate: currentServiceDate,
    isNew,
    submissionCode: initSubmissionCode
  });

  return submission;
};

export const facilityInit = (preferences, speciality, initValues) => {
  if (!isEmpty(initValues)) {
    return initValues[inputs.facility.name] || '';
  }

  const isFacility = showFacility(speciality);

  if (isFacility) {
    const facility = getDefaultPrefs(preferences, prefsCodes.presetFacilityNum);
    return !facility || facility === defaulFacilitytCode ? '' : facility;
  }

  return '';
};

export const subFacilityInit = (preferences, speciality, initValues) => {
  if (!isEmpty(initValues) && initValues[inputs.subFacility.name]) {
    return initValues[inputs.subFacility.name];
  }

  const isFacility = showSubFacility(speciality);

  if (isFacility) {
    const subFacility = getDefaultPrefs(preferences, prefsCodes.presetFacilityNum);
    return !subFacility || subFacility === defaulFacilitytCode ? '' : subFacility;
  }

  return '';
};

export const payeeNumberInit = (preferences, practitionerGuid, initValues) => {
  if (!isEmpty(initValues)) {
    return initValues[inputs.payeeNumber.name] || '';
  }

  const practitioners = store.getState().clinic.members;
  const practitioner = find(practitioners, { DoctorGuid: practitionerGuid });
  const payeeNumbers = practitioner?.PayeeNumbers;

  // Extracting the default value
  const defaultValue =
    practitioner?.PayeeNumber ||
    (Array.isArray(payeeNumbers) && payeeNumbers.length > 0 ? payeeNumbers[0] : payeeNumbers ? payeeNumbers.split(',')[0] : '');

  const presetPayee = getDefaultPrefs(preferences, prefsCodes.presetPayee);
  const prefsValue = getDefaultPrefs(preferences, prefsCodes.defaultPayee);

  if (presetPayee === '1' && (prefsValue === '-1' || prefsValue === '0' || !prefsValue)) return defaultValue || '';
  return prefsValue || '';
};

export const ruralCodeInit = (preferences, speciality, initValues) => {
  if (initValues && initValues[inputs.ruralCode.name]) {
    const ruralCode = initValues[inputs.ruralCode.name];
    const ruralCodeValue = typeof ruralCode === 'string' ? ruralCode : ruralCode?.value;
    return dropdownOptions.ruralCodesDropdownOptions.find((i) => i.value === ruralCodeValue);
  } else {
    const defaultCode = 'N/A';
    const defaultPrefs = showRuralCodeInput(speciality) ? getDefaultPrefs(preferences, prefsCodes.defaultRural) || defaultCode : defaultCode;
    return dropdownOptions.ruralCodesDropdownOptions.find((i) => i.value === defaultPrefs);
  }
};

export const locationCodeInit = (preferences, speciality, initValues) => {
  const defaultLocation = defaultLocationCode(speciality).value;

  if (!isEmpty(initValues)) {
    return initValues[inputs.locationCode.name] || defaultLocation;
  }

  const prefsLocation = getDefaultPrefs(preferences, prefsCodes.defaultLocation);
  const presetLocation = getPresetPrefs(preferences, prefsCodes.presetLocation);

  if (presetLocation === '1' && prefsLocation !== 'A') return prefsLocation;
  return defaultLocation;
};

const invoiceTypeCondition = (speciality) => {
  if (
    Number(speciality) === 44 ||
    Number(speciality) === 14 ||
    Number(speciality) === 4 ||
    Number(speciality) === 5 ||
    Number(speciality) === 80 ||
    Number(speciality) === -6
  )
    return true;

  return false;
};

export const emergencyInit = ({ preferences, speciality, isGroup, isNew, invoiceType }) => {
  const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
  if (ishideEmergency) return false;

  const initValue =
    getPresetPrefs(preferences, prefsCodes.presetEmergency) === '1' ? getDefaultPrefs(preferences, prefsCodes.defaultEmergency) : false;

  return initValue;
};

export const travelInit = ({ preferences, speciality, isGroup, isNew, invoiceType }) => {
  const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
  if (ishideEmergency) {
    // [KS] CMO-1239 - Hide Emergency fields for midwives
    return false;
  } else {
    let initValue = getPresetPrefs(preferences, prefsCodes.presetTravel) === '1' ? getDefaultPrefs(preferences, prefsCodes.defaultTravel) : false;

    if (emergencyInit({ preferences, speciality, isGroup, isNew, invoiceType })) {
      return initValue;
    } else {
      initValue = false;
    }

    return initValue;
  }
};

const ccfppInit = ({ preferences, speciality, isGroup, isNew, invoiceType }) => {
  const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
  if (ishideEmergency) {
    // [KS] CMO-1239 - Hide Emergency fields for midwives
    return false;
  } else {
    let initValue = getPresetPrefs(preferences, prefsCodes.presetCCFPP) === '1' ? getDefaultPrefs(preferences, prefsCodes.defaultCCFPP) : false;

    if (emergencyInit({ preferences, speciality, isGroup, isNew, invoiceType })) {
      return initValue;
    } else {
      initValue = false;
    }

    return initValue;
  }
};

export const refToByInit = ({ preferences, speciality, isGroup, initValues, invoiceType }) => {
  if (!isEmpty(initValues)) {
    return initValues[inputs.refToBy.name] || 'N';
  }

  const _isSupplementary = isSupplementary(speciality, invoiceType);

  if (isGroup || _isSupplementary) return 'N';

  const defaultPref =
    getDefaultPrefs(preferences, prefsCodes.defaultReferralDirection) === ('N/A' || 'N')
      ? 'N'
      : getDefaultPrefs(preferences, prefsCodes.defaultReferralDirection);
  let initValue = getPresetPrefs(preferences, prefsCodes.presetReferral) === '1' ? defaultPref?.charAt(0) : 'N';

  return initValue;
};
