import React from 'react';
import { FormProvider } from 'react-hook-form';

import Steps from './modules/Steps/Steps';
import Dialogs from './modules/Dialogs/Dialogs';

import { inputs } from './helpers/inputs';
import { useDefaultValues } from './hooks/useDefaultValues';
import { useFormErrorAutoscroll } from '../../../hooks/useFormErrorAutoscroll';

import './EClaimPolicy.scss';

// interface {
// ==== If need open form in dialog and you have full data for policy info data, then just pass this data to props ==== //
// policyInfo?: {},

// ==== If need open form in dialog and you dont have full data for policy info, then just pass policyGuid (RowGuid) and patientGuid. Form will make acync request for full policy info data ====== //
// policyGuid: string,
// patientGuid: string,
// showInDialog: boolean,

// isEdit?: boolean, // define if form in edit mode. If component called not in Nav.js (for example if called in the dialog)
// onCancel?: () => void,
// onCreate?: (formData) => void,
// onEdit?: (formData) => void,
// }

const EClaimPolicy = (props) => {
  const initValues = useDefaultValues(props);

  // Autoscroll and set focus to invalid input
  useFormErrorAutoscroll({ errors: initValues.errors, inputs, setFocus: initValues.setFocus });

  return (
    <FormProvider {...initValues}>
      <Steps />
      <Dialogs />
    </FormProvider>
  );
};

export default EClaimPolicy;
