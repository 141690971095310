import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Practitioner from './Practitioner';
import InputWrapper from '../InputWrapper/InputWrapper';

import { prefsCodes } from '../../../modules/config/prefsCodesConfig';
import { getPresetPrefs } from '../../../modules/claims/helpers/getDefaultPrefs';
import { savePreferences } from '../../../modules/preferences/actions/preferences.action.creators';

// interface {
//   name: string // Input name
//   label: string // Input label
//   value: string, // pass valid Practitioner guid
//   practitionerGuid: string

//   ref?: ref,
//   errorMessage?: string
//   practitionersList?: { label: string, DoctorGuid: string }[] // List of practitioners
//   isInvalid?: boolean
//   required?: boolean
//   naOption?: boolean, // detect if need to add "N/A" opption
//   allOption?: boolean, // The label for the "N/A" option in the selection list, if provided.
//   multipleOption?: boolean, // detect if need to add "Multiple practitioners" opption
//   onSelect?: (e) => void,
//   onBlur?: (e) => void,
//   onChange?: (e) => void,
//   autoFocus?: boolean
//   disabled?: boolean
// }

const WrappedPractitioner = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const name = props.name;
  const label = props.label;
  const user = useSelector((state) => state.user.details);
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const defaultCode = prefsCodes.defaultPractitioner;
  const presetCode = prefsCodes.presetPractitioner;

  const isLocked = () => {
    const currentUserDefaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');
    const prefs = currentUserDefaultPrefs?.content;
    return getPresetPrefs(prefs, defaultCode)?.toUpperCase() === props.practitionerGuid?.toUpperCase();
  };

  const onSavePrefs = () => {
    const prefs = {
      content: [
        { value: '1', label: presetCode },
        { value: props.practitionerGuid, label: defaultCode }
      ],
      label: 'default',
      value: props.practitionerGuid
    };

    dispatch(savePreferences(user.UserGuid, prefs));
  };

  return (
    <InputWrapper
      name={name}
      label={label}
      required={props.required}
      errorMessage={props.errorMessage}
      isLocked={isLocked()}
      onSavePrefs={props.disabled ? undefined : onSavePrefs}
    >
      <Practitioner {...props} ref={ref} isInvalid={props.isInvalid} />
    </InputWrapper>
  );
});

export default WrappedPractitioner;
