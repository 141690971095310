import React from 'react';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { BS_TOLL_FREE, VANCOUVER_PHONE, VICTORIA_1, VICTORIA_2 } from '../../../config';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';
import moment from 'moment';

// interface IMismatchDialog {
//   patientData: {
//     PHN: string,
//     PHNProvince: string,
//     BirthDay: string | Date,
//     FirstName: string,
//     LastName: string,
//     MidName: string,
//     Sex: string
//   },
//   eligibility: {
//     NAME: string,
//     GENDER: string
//   },
//   visible: boolean,
//   isNew: boolean,
//   differentPatientsDemographics: boolean,
//   closeDialog: () => void,
//   useEligibilityDemographics: () => void,

//   header?: string,
// }

const MismatchDialog = (props) => {
  const getMonth = moment(props.patientData?.BirthDay).month() + 1;
  const month = getMonth > 9 ? getMonth : `0${getMonth}`;
  const gender = props.patientData?.Sex === 'M' ? t('Male.2') : props.patientData?.Sex === 'F' ? t('Female.2') : '';
  const yourRecords =
    props.patientData?.LastName || props.patientData?.FirstName
      ? `${props.patientData?.LastName} ${props.patientData?.FirstName} ${props.patientData?.MidName ? props.patientData?.MidName : ''}, ${gender}`
      : `${gender}`;
  const phn = props.patientData?.PHN ? `${props.patientData?.PHN} #,` : '';

  const footer = (
    <>
      {props.differentPatientsDemographics ? (
        <div>
          <Button id={elementIDs.ignore} type="button" label={t('Ignore')} autoFocus onClick={props.closeDialog} />

          <Button
            id={elementIDs.useEligibilityDemographics}
            className="p-button-outlined"
            type="button"
            label={t('Use_eligibility_demographics')}
            onClick={() => {
              props.useEligibilityDemographics(props.eligibility);
              props.closeDialog();
            }}
          />
        </div>
      ) : (
        <Button id={elementIDs.dialogClose} type="button" label={t('Close')} onClick={props.closeDialog} />
      )}
    </>
  );

  const header = () => {
    if (!props.header) {
      const nameMismatch = props.differentPatientsDemographics ? t('Name_Mismatch') : t('Match_Found');
      return `${t('MSP_Spelling_Report')}: ${nameMismatch}`;
    }

    return props.header;
  };

  const differentPatientsDemographicsLayout = () => {
    if (props.differentPatientsDemographics) {
      return (
        <div className="mb-3">
          <div className="my-1">{t('MSP_eligibility_report_seems_to_contain_different_patients_demographics')}</div>
          <div className="mt-3 mb-1">
            {`${t('Eligibility_report')}: `}
            <span className="font-bold">{`${props.eligibility?.NAME}, ${props.eligibility?.GENDER}`}</span>
          </div>
          <div className="mb-3">
            {`${t('Your_records')}: `}
            <span className="font-bold">{yourRecords?.toUpperCase()}</span>
          </div>
          <div className="my-1">{`${t('Eligibility_report_might_contain_obsolete_demographics_data')}:`}</div>
        </div>
      );
    }

    return <div className="mb-3">{t('Wrong_spelling_can_cause_declined_claims')}</div>;
  };

  return (
    <Dialog
      id={elementIDs.nameMismatchDialog}
      header={header()}
      footer={footer}
      visible={props.visible}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      modal
      baseZIndex={1000000}
      onHide={props.closeDialog}
    >
      <div id={elementIDs.nameMismatchDialogRootContent} className="mt-2">
        {differentPatientsDemographicsLayout()}

        <div>
          <div className="mb-1">
            {`${t('Vancouver')}: `}
            <a href={`tel:${VANCOUVER_PHONE}`}>{VANCOUVER_PHONE}</a>
          </div>

          <div className="mb-1">
            {`${t('Victoria')} `}
            <a href={`tel:${VICTORIA_1}`}>{VICTORIA_1}</a>
            {` ${t('or')} `}
            <a href={`tel:${VICTORIA_2}`}>{VICTORIA_2}</a>
          </div>

          <div className="mb-1">
            {`${t('BC_toll_free')}: `}
            <a href={`tel:${BS_TOLL_FREE}`}>{BS_TOLL_FREE}</a>
          </div>

          {props.isNew ? (
            <div>{String.format(t('Enter_PHN_MM_YYYY'), '1', '4', '1')}</div>
          ) : (
            <div>{`${'Enter'}: 1, 4, ${phn} ${month}#, ${moment(props.patientData.BirthDay).year()}#, 1`}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default MismatchDialog;
