import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import RefToByRadio from './Common/RefToByRadio';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';

const RefToBy = (props) => {
  const name = inputs.refToBy.name;
  const { control, setValue, errors, clearErrors } = useFormContext();
  const { isLocked, onSaveRefToByPreferences } = usePreferences();
  const refToBy = useWatch({ control, name });
  const defaultCode = prefsCodes.defaultReferralDirection;

  const onChange = (value, field) => {
    field.onChange(value);
    if (value === 'N') {
      // Clear error message for Comment input
      errors[inputs.referral.name] && clearErrors(inputs.referral.name);

      setValue(inputs.referral.name, []);
      setValue(inputs.referral.codeDescription, []);
      setValue(inputs.referral.codeType, []);
    }
  };

  const referralInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.refToBy.label}
        isLocked={isLocked(refToBy, defaultCode)}
        onSavePrefs={onSaveRefToByPreferences}
        hideLabel={props.hideLabel}
        style={{ height: '55px' }}
        dir="row"
      >
        <RefToByRadio value={field.value} onChange={(value) => onChange(value, field)} />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => referralInput(field)} />;
};

export default RefToBy;
