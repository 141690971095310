import SectionTitle from './SectionTitle';
import ClinicalInformation from '../Inputs/ClinicalInformation';
import { t } from '../../../../../../../service/localization/i18n';

const ClinicalSection = () => {
  return (
    <>
      <SectionTitle title={t('Clinical')} className="mb-3" />

      <ClinicalInformation />
    </>
  );
};

export default ClinicalSection;
