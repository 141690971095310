import { inputs } from './inputs';
import { decodeText } from '../../../../utils/decodeText';

export const formatWSBCRInitialValues = (initialValues) => {
  const disabledDate = initialValues[inputs.disabledDate.name];
  const injuryDate = initialValues[inputs.injuryDate.name];
  const recoveryDate = initialValues[inputs.recoveryDate.name];
  const serviceDate = initialValues[inputs.serviceDate.name];

  return {
    ...initialValues,
    [inputs.employer.name]: decodeText(initialValues[inputs.employer.name]),
    [inputs.address.name]: decodeText(initialValues[inputs.address.name]),
    [inputs.city.name]: decodeText(initialValues[inputs.city.name]),
    [inputs.priorProblems.name]: decodeText(initialValues[inputs.priorProblems.name]),
    [inputs.firstTreatment.name]: decodeText(initialValues[inputs.firstTreatment.name]),
    [inputs.clinicalInformation.name]: decodeText(initialValues[inputs.clinicalInformation.name]),
    [inputs.restrictions.name]: decodeText(initialValues[inputs.restrictions.name]),
    [inputs.icd9.codeDescription]: decodeText(initialValues[inputs.icd9.codeDescription]),

    [inputs.injuryDate.name]: injuryDate ? new Date(injuryDate) : null,
    [inputs.disabledDate.name]: disabledDate ? new Date(disabledDate) : null,
    [inputs.recoveryDate.name]: recoveryDate ? new Date(recoveryDate) : null,
    [inputs.serviceDate.name]: serviceDate ? new Date(serviceDate) : null,

    [inputs.feeCodes.name]: initialValues[inputs.feeCodes.name]?.trim()
  };
};
