import { useEffect } from 'react';

export const useStorage = (key) => {
  // Clean up expired values on component mount
  useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      const { expiration } = JSON.parse(storedValue);
      if (expiration && expiration <= Date.now()) {
        localStorage.removeItem(key);
      }
    }
  }, [key]);

  const getLocalStorageValue = (initialValue) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      const { data, expiration } = JSON.parse(storedValue);
      if (!expiration || expiration > Date.now()) {
        return data;
      }
      // Remove the expired value from storage
      localStorage.removeItem(key);
    }
    return initialValue;
  };

  const setValueInStorage = (newValue, expirationTime) => {
    const expiration = expirationTime ? Date.now() + expirationTime : null;
    const data = expiration ? { data: newValue, expiration } : { data: newValue };
    localStorage.setItem(key, JSON.stringify(data));
  };

  const localStorageValue = getLocalStorageValue();

  return [localStorageValue, setValueInStorage];
};
