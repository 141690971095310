import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import QuickPick from '../../../modules/EClaimCatalogs/QuickPick/QuickPick';
import Services from '../../../modules/EClaimForm/Layouts/Services';
import ServicePicker from '../../../modules/ServicePicker/ServicePicker';
import CoordinationOfBenefits from '../../../modules/EClaimForm/Layouts/CoordinationOfBenefits';
import Terms from '../../../modules/EClaimForm/Layouts/Terms';
import StepTwoActionButtons from '../../../modules/ActionButtons/StepTwoActionButtons';
import StepTwoWrap from '../../../modules/Steps/StepTwoWrap';

import { inputs } from '../../../helpers/inputs';
import { useConditions } from '../../../hooks/useConditions';
import { useStorage } from '../../../../../../hooks/useStorage';
import { localStorageKeys } from '../../../../../../config/localStorageKeysConfig';

const StepTwo = () => {
  const completeSetupKey = localStorageKeys.doNotShowEClaimCompleteSetupDialog;
  const { control, watch, setLocalState } = useFormContext();
  const { isCompleteSetupRequired } = useConditions();
  const practitionerGuid = watch(inputs.practitioner.name);
  const fieldArrayMethods = useFieldArray({ control, name: inputs.services.name });
  const [doNotShowCompleteSetupDialog] = useStorage(completeSetupKey);

  // Show "Complete setup" dialog on practitioner change
  useEffect(() => {
    if (!doNotShowCompleteSetupDialog && isCompleteSetupRequired) {
      setLocalState((prevState) => ({ ...prevState, completeSetupDialog: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerGuid]);

  return (
    <StepTwoWrap>
      <QuickPick />
      <Services />
      <ServicePicker fieldArrayMethods={fieldArrayMethods} />
      <CoordinationOfBenefits />
      <Terms />
      <StepTwoActionButtons fieldArrayMethods={fieldArrayMethods} />
    </StepTwoWrap>
  );
};

export default StepTwo;
