import React from 'react';
import EClaimPolicyForm from '../EClaimPolicyForm/EClaimPolicyForm';
import { Dialog } from 'primereact/dialog';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { useEClaimPolicy } from '../../hooks/useEClaimPolicy';

const EClaimPolicyFormDialog = () => {
  const { state } = usePatientPoliciesContext();
  const { onCloseEClaimPolicy } = useEClaimPolicy();
  const showEClaimPolicyForm = state.eClaimPolicy?.showEClaimPolicyForm;

  return (
    <Dialog
      visible={showEClaimPolicyForm}
      breakpoints={{ '1024px': '100vw' }}
      // style={{ width: '90vw', height: "100vh", maxHeight: "100%" }}
      style={{ maxWidth: '1600px', height: '100vh' }}
      baseZIndex={1000000}
      showHeader={false}
      dismissableMask
      // maximizable
      onHide={onCloseEClaimPolicy}
    >
      <EClaimPolicyForm />
    </Dialog>
  );
};

export default EClaimPolicyFormDialog;
