import React from 'react';
import { useFormContext } from 'react-hook-form';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Success from '../Layouts/Success';
import GroupComments from '../../views/TeleplanGroup/GroupPicker/GroupSteps/GroupComments';
import GroupServicesPicker from '../../views/TeleplanGroup/GroupPicker/GroupSteps/GroupServicesPicker';
import GroupDxPicker from '../../views/TeleplanGroup/GroupPicker/GroupSteps/GroupDxPicker';
import GroupReferral from '../../views/TeleplanGroup/GroupPicker/GroupSteps/GroupReferral';
import GroupPreview from '../../views/TeleplanGroup/GroupPreview/GroupPreview';

import { steps } from '../../helpers/steps';

const Steps = () => {
  const { localState } = useFormContext();

  return (
    <>
      {/* Show Teleplan Invoice form */}
      {localState.step === steps.form && <StepOne />}

      {/* Show Records page */}
      {localState.step === steps.records && <StepTwo />}

      {/* Show Preview page */}
      {localState.step === steps.preview && <StepThree />}

      {/* Show Success page */}
      {localState.step === steps.success && <Success />}

      {/* Show Group Data table page for adding time */}
      {localState.step === steps.groupServicesPicker && <GroupServicesPicker />}

      {/* Show Group Data table page for Dx codes */}
      {localState.step === steps.groupDxPicker && <GroupDxPicker />}

      {/* Show Group Data table page for Referral codes */}
      {localState.step === steps.groupReferralPicker && <GroupReferral />}

      {/* Show Group Data table page for adding comments */}
      {localState.step === steps.groupComments && <GroupComments />}

      {/* Show Group Preview page */}
      {localState.step === steps.groupPreview && <GroupPreview />}
    </>
  );
};

export default Steps;
