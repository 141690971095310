import { calculateRowTotal } from './calculateRowTotal';

export const onEditorValueChange = (params, value, field, records, setRecords, setIsReviewDirty) => {
  let data = [...records];
  let currentRow = { ...data[params.rowIndex] };
  currentRow[field] = field === 'salesTax' || field === 'discountUnits' ? value : Number(value);

  const newTotals = calculateRowTotal(currentRow);
  currentRow.feeAmount = newTotals.feeAmount;
  currentRow.gst = newTotals.gst;
  currentRow.pst = newTotals.pst;
  currentRow.total = newTotals.total;
  currentRow.balanceDue = newTotals.balanceDue;

  const updatedData = data.map((i) => (i.id === currentRow.id ? currentRow : i));
  setRecords(updatedData);
  setIsReviewDirty(true);
};
