import React from 'react';
import { isTimeValid, timeDiff } from '../../../../../utils/formatTime';

const EndTimeInputDescription = ({ startTime, endTime }) => {
  const isStartValid = isTimeValid(startTime);
  const isEndValid = isTimeValid(endTime);

  if (isStartValid && isEndValid) {
    const durationInMinutes = timeDiff(startTime, endTime);
    return <div className="w-full text-right text-sm">{`${durationInMinutes} min`}</div>;
  }

  return null;
};

export default EndTimeInputDescription;
