import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { t } from '../../../../../../service/localization/i18n';

const RecommendedTime = ({ feeCode }) => {
  return (
    <>
      <Tooltip target={`.recommendedTimeTooltip`} position="top">
        {feeCode && `Code ${feeCode}: Time is required except some use cases.`}
      </Tooltip>

      <div className="p-error recommendedTimeTooltip">{t('advised')}</div>
    </>
  );
};

export default RecommendedTime;
