import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { commentInputRequirements } from '../helpers/validationSchema';
import moment from 'moment';

export const useValidation = () => {
  const { watch, errors, clearErrors, getValues, localState } = useFormContext();
  const today = moment();
  const serviceDate = watch(inputs.serviceDate.name);
  const serviceDateToArray = Array.isArray(serviceDate) ? serviceDate : [serviceDate];
  // Check if any date in the serviceDateToArray array is a future date from today
  const hasFutureDate = serviceDateToArray.some((date) => moment(date).isAfter(today, 'day'));
  const showSubmitButton = !hasFutureDate; // VER-384 - Teleplan claims (not reports)->hide Submit button

  const clearCommentError = ({ submissionCode, invoiceType, saveAsDraft, serviceDate, startTime, endTime, feeCodes, units, speciality }) => {
    const commentError = errors[inputs.comment.name];
    const currentFeeCodes = getValues(inputs.feeCodes.codeType);
    const currentStartTime = getValues(inputs.startTime.name);
    const currentEndTime = getValues(inputs.endTime.name);
    const currentServiceDate = getValues(inputs.serviceDate.name);
    const currentInvoiceType = getValues(inputs.payor.name);
    const currentSaveAsDraft = getValues(inputs.saveAsDraft.name);
    const currentSubmissionCode = getValues(inputs.submission.name);
    const currentUnits = getValues(inputs.units.name);
    const currentSpeciality = getValues(inputs.speciality.name);

    const isCommentRequired = commentInputRequirements({
      submissionCode: submissionCode || currentSubmissionCode,
      invoiceType: invoiceType || currentInvoiceType,
      saveAsDraft: saveAsDraft || currentSaveAsDraft,
      serviceDate: serviceDate || currentServiceDate,
      startTime: startTime || currentStartTime,
      endTime: endTime || currentEndTime,
      feeCodes: feeCodes || currentFeeCodes,
      step: localState.step,
      units: units || currentUnits,
      speciality: speciality || currentSpeciality
    });

    if (commentError && !isCommentRequired) clearErrors(inputs.comment.name);
  };

  return { showSubmitButton, clearCommentError };
};
