import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import PatientChipsLayout from '../Chips/PatientChipsLayout';
import { ToggleCatalogs } from '../ActionButtons/ToggleCatalogs';

import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { catalogsHeaderHeight } from '../../config/styles';
import { Button } from 'primereact/button';
import { t } from '../../../../service/localization/i18n';

const CatalogsHeader = () => {
  const history = useHistory();
  const { hideCatalogsHeader, hideToggleCatalogsButton, showCatalogs, toggleCatalogs, onClose, showCloseButton } = useCatalogsContext();

  if (hideCatalogsHeader) return null;

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  };

  return (
    <div className="flex justify-content-between align-items-start catalogs_card_header" style={{ height: `${catalogsHeaderHeight}px` }}>
      <PatientChipsLayout />
      <div className="flex gap-2">
        <ToggleCatalogs hideToggleCatalogsButton={hideToggleCatalogsButton} showCatalogs={showCatalogs} toggleCatalogs={toggleCatalogs} />
        {showCloseButton && <Button className="p-button-text p-button-outlined" label={t('Close')} onClick={handleClose} />}
      </div>
    </div>
  );
};

export default CatalogsHeader;
