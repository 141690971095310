import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ServiceDateWithFormControl } from '../../../../../../../components/Inputs';

const ServiceDate = () => {
  const { control, isView } = useFormContext();

  return <ServiceDateWithFormControl control={control} disabled={isView} selectionMode="multiple" />;
};

export default ServiceDate;
