// Local state to control the form, catalogs, steps, dialogs, etc. No need to use redux.
export const localStateInitValues = {
  saveAndClaim: false,
  step: 1,
  saving: {
    save: false,
    saveAndClaim: false
  },
  showCatalogs: {
    desktop: true,
    mobile: false
  }
};
