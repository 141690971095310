import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import BillTo from './BillTo';
import PrivateAddress from './PrivateAddress';
import ClaimInputField from './ClaimInputField';
import Context from '../../../../Context';
import { onBillToUpdate } from './claimDetailsHelpers/onClaimUpdate';
import { editPatient } from '../../../patients/actions/patients.action.creators';
import { inputs } from './claimDetailsHelpers/inputs';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

const BillToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const dispatch = useDispatch();
  const { privateRecord } = useContext(Context);

  const onBillToAddressSave = (data, closeDialog) => {
    dispatch(
      editPatient(data, (newData) => {
        onBillToUpdate([newData], dispatch);
        dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
        closeDialog();
      })
    );
  };

  const additionalAddressData = () => {
    const billToValue = privateRecord[inputs().billTo.name];
    const fullName = billToValue ? `${billToValue[0]?.LastName}, ${billToValue[0]?.FirstName}` : null;

    return fullName;
  };

  const additionalInputs = (
    <ClaimInputField label={inputs().billTo.label} required={true}>
      <BillTo value={privateRecord[inputs().billTo.name]} />
    </ClaimInputField>
  );

  return (
    <div id={elementIDs.billToAddressWrap}>
      <PrivateAddress
        value={privateRecord[inputs().billTo.name] ? privateRecord[inputs().billTo.name][0] : ''}
        label={inputs().billToAddress.label}
        showInDialog={true}
        additionalInputs={additionalInputs}
        showAddressDialog={showAddressDialog}
        setShowAddressDialog={setShowAddressDialog}
        additionalAddressData={additionalAddressData()}
        onSave={onBillToAddressSave}
      />
    </div>
  );
};

export default BillToAddress;
