import React from 'react';
import { useFormContext } from 'react-hook-form';

import Catalogs from '../../../../../../components/Catalogs/Catalogs';
import FirstVisitPatientChip from './Layouts/FirstVisitPatientChip';

import { useCatalogTabs } from '../../hooks/useCatalogTabs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useToggleCatalogs } from '../../hooks/useToggleCatalogs';
import { inputs } from '../../helpers/inputs';
import { usePatient } from '../../hooks/usePatient';
import { useChipRemove } from '../../hooks/useChipRemove';
import { useCatalogs } from '../../hooks/useCatalogs';

const TeleplanCatalogs = () => {
  const { onChipRemove } = useChipRemove();
  const { toggleCatalogs } = useToggleCatalogs();
  const { getSelectedCodes, onSelectCodeFromCatalogsTable, catalogsTableValues, onLetterClick } = useCatalogs();
  const { catalogTabs, onTabChange } = useCatalogTabs();
  const { watch, isNew, firstVisit, localState, isGroup, pos } = useFormContext();
  const { onPatientChange } = usePatient();
  const patients = watch(inputs.patient.name);

  return (
    <Catalogs
      id={elementIDs.teleplanDesktopCatalogs}
      patients={patients}
      hideCatalogsHeader={isGroup}
      hideToggleCatalogsButton={pos}
      removablePatientChip={isNew}
      activeTabIndex={localState.catalogIndex}
      catalogTabs={catalogTabs}
      showCatalogs={localState.showCatalogs}
      showCloseButton
      catalogsTableValues={catalogsTableValues}
      // Handlers
      onTabChange={onTabChange}
      toggleCatalogs={toggleCatalogs}
      onPatientChipRemove={onPatientChange}
      getSelectedCodes={getSelectedCodes}
      onCodeChipClick={onChipRemove}
      onLetterClick={onLetterClick}
      onSelectCodeFromCatalogsTable={onSelectCodeFromCatalogsTable}
      // Layouts
      patientChipsLayout={firstVisit && <FirstVisitPatientChip />}
    />
  );
};

export default TeleplanCatalogs;
