import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import PageTitle from '../Layouts/Titles/PageTitle';
import classes from './Table.module.scss';

const TableTitle = (props) => {
  return (
    <div className={props.pageLabel ? classes.tableOptionsWrap : ''}>
      {/* Page title */}
      {props.pageLabel && <PageTitle>{props.pageLabel}</PageTitle>}

      <div className={classes.tableOptions}>
        {/* Info */}
        {props.tableDescription && (
          <>
            <Tooltip className={classes.questionIcontTooltip} target=".questionIcon" />
            <i className="pi pi-question-circle questionIcon" data-pr-tooltip={props.tableDescription} data-pr-position="left" />
          </>
        )}
      </div>
    </div>
  );
};

export default TableTitle;
