export const steps = {
  form: 1,
  records: 2,
  preview: 3,
  success: 4,
  groupServicesPicker: 5,
  groupDxPicker: 6,
  groupReferralPicker: 7,
  groupComments: 8,
  groupPreview: 9
};
