import { getClaimList, getClaims4Scroll, formatted } from '../actions/claims.action.creators';
import ClaimService from '../../../service/ClaimService';
import { claimsDefaultFilters, defaultPageSize } from '../../config/defaultValuesConfig';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { currencyFormat } from '../../utils/currencyFormat';

export const loadData = (e, { claimsData, DCNGuid, dispatch, PatientGuid, showInCard, setInitialStateForCard, showOnPatientDetailsScreen }) => {
  const params = { ...e };
  if (e) {
    params.DCNGuid = DCNGuid;
    params.filters = e.filters;
    if (params.sortField && params.sortOrder) {
      const _order = params.sortOrder === 1 || params.sortOrder === 'desc' ? 'desc' : 'asc';
      const _sortby = mapSortField(params.sortField, _order);
      params.sortby = _sortby;
    } else {
      params.sortby = e.sortby ? e.sortby : claimsData.sortby;
    }

    fetchClaimsForTable(params, claimsData, false, dispatch, PatientGuid, showInCard, setInitialStateForCard, showOnPatientDetailsScreen);
  }
};

export const loadData4Scroll = (e, { claimsData, DCNGuid, dispatch, PatientGuid, showInCard, setInitialStateForCard }) => {
  const params = { ...e };
  if (e) {
    if (params.first === 0 && params.page === 0) {
      params.page = 1;
    }
    if (!params.page) params.page = 0;
    if (!params.rows) params.rows = 10;
    if (params.first && params.first > 0) {
      params.page = params.first / params.rows;
    }
    params.DCNGuid = DCNGuid;
    params.filters = claimsData.filters;
    if (params.sortField && params.sortOrder) {
      const _order = params.sortOrder === 1 || params.sortOrder === 'desc' ? 'desc' : 'asc';
      const _sortby = mapSortField(params.sortField, _order);
      params.sortby = _sortby;
    } else {
      params.sortby = e.sortby ? e.sortby : claimsData.sortby;
    }
  }

  let needRefresh = claimsData.totalRecords === 0 || (claimsData.totalRecords > 0 && claimsData.complete_list.length <= claimsData.totalRecords);
  if (params.filters && params.params && params.filters !== params.params.filter) needRefresh = true;
  if (needRefresh) {
    fetchClaimsForTable(params, claimsData, true, dispatch, PatientGuid, showInCard, setInitialStateForCard);
  }
};

const fetchClaimsForTable = (e, claimsData, forScroll, dispatch, PatientGuid, showInCard, setInitialStateForCard, showOnPatientDetailsScreen) => {
  if (e) {
    let combined = { ...claimsData.filters, ...e.filters };
    if (e.query !== undefined) {
      combined = Object.assign({}, combined, { freetext: e.query });
    }

    // CMO-2509 - Patient profile->Invoices/claims widget->remove 6 months filter from the list of claims in this widget
    if (showOnPatientDetailsScreen) combined = { ...combined, ...claimsDefaultFilters, showArchived: combined.showArchived };

    const filter = JSON.stringify(combined);

    const query =
      `pid=${PatientGuid}` +
      (e.sortField ? `&sortby=${e.sortField}_${e.sortOrder}` : e.sortby ? `&sortby=${e.sortby}` : '') +
      (filter ? `&q=${filter}` : '');
    if (forScroll) {
      dispatch(
        getClaims4Scroll({
          page: e.page + 1,
          pageSize: e.rows || 10,
          filter,
          query
        })
      );
    } else {
      // if (showInCard) {
      //   // [KS] Do not use Redux for invoice card on patient’s profile. Use service instead, using promise.
      //   // CMO-810 - Discard filter on Invoices table after we click on View Patient on any claim's line because the filter gets applied to Invoices card on Patient's profile

      //   setInitialStateForCard({ ...claimsData, isFetching: true });
      //   ClaimService.getClaimList({
      //     page: e.page + 1,
      //     pageSize: e.rows || 10,
      //     filter,
      //     query
      //   }, dispatch)
      //     .then(results => {
      //       setInitialStateForCard({
      //         ...claimsData,
      //         claim_list: formatted(results.data.claimList),
      //         statusList: results.data.statusList,
      //         page: results.data.page,
      //         active_item: results.data.active_item,
      //         active_offset: 0,
      //         first: (results.data.page - 1) * (results.data.pageSize) + 1,
      //         totalPages: results.data.totalPages,
      //         totalRecords: results.data.totalRecords,
      //         totalRecordsAlter: results.data.totalRecordsAlter,
      //         totalBilled: currencyFormat(results.data.totalBilled),
      //         totalPaid: currencyFormat(results.data.totalPaid),
      //         totalDue: currencyFormat(results.data.totalDue),
      //         rows: results.data.pageSize,
      //         query: results.data.query,
      //         filters: JSON.parse(results.data.query),
      //         sortby: results.data.sortby,
      //         PatientGuid: results.data.PatientGuid,
      //         isFetching: false
      //       })
      //     });
      // } else {
      //   dispatch(getClaimList({
      //     page: e.page + 1,
      //     pageSize: e.rows || 10,
      //     filter,
      //     query
      //   }));
      // }

      const localStoragePageSize = localStorage.getItem(localStorageKeys.invoicesPageSize);
      const pagesize = localStoragePageSize && Number(localStoragePageSize) >= defaultPageSize ? localStoragePageSize : defaultPageSize;

      // const pagesize = defaultPageSize;

      dispatch(
        getClaimList({
          page: e.page + 1,
          pageSize: showInCard || e.isPaginator ? e.rows : pagesize,
          filter,
          query
        })
      );
    }
  }
};

const mapSortField = (field, order) => {
  switch (field) {
    case 'FullName':
      return `PLastName_${order}`;
    case 'ICD9Code':
      return `DiagnosticCodeID_${order}`;
    case 'FeeCode':
      return `FeeItemCode_${order}`;
    case 'TreatedBy':
      return `d.LastName_${order}`;
    default:
      return `${field}_${order}`;
  }
};
