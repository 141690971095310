import { InputTextarea } from 'primereact/inputtextarea';
import { t } from '../../../../../service/localization/i18n';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { rowHeight, noteRowHeight } from './columns';

export const rowExpansionTemplate = (data, { records, isMobileWithLowRezolution, showNoteInput, setRecords, onAddNote, setIsReviewDirty }) => {
  if (data?.id === '-1') return;

  return (
    <div className="flex align-items-center" style={isMobileWithLowRezolution ? {} : { marginLeft: '55px', minHeight: rowHeight }}>
      <div className="flex col-12 p-0 align-items-center" style={{ minHeight: noteRowHeight }}>
        <div style={{ width: '55px' }}>{`${t('Note')}: `}</div>

        <div id={elementIDs.columnNoteBody} className="p-editable-column flex col-10 p-0">
          {!showInput(data, showNoteInput) && (
            <div className="pointer" onClick={() => onAddNote(data, true)}>
              {data.note || t('Click_to_add_note')}
            </div>
          )}

          {showInput(data, showNoteInput) && (
            <InputTextarea
              id={elementIDs.noteInput}
              autoFocus
              rows={1}
              cols={30}
              autoResize
              placeholder={t('Add_note')}
              value={data.note || ''}
              style={{ height: rowHeight, width: '100%' }}
              onBlur={() => onAddNote(data, false)}
              onChange={(e) => setNoteValue(e, data.id, records, setRecords, setIsReviewDirty)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const showInput = (params, showNoteInput) => {
  let show = false;
  Object.keys(showNoteInput).forEach((i) => {
    if (i === params.id && showNoteInput[i] === true) show = true;
  });
  return show;
};

export const setNoteValue = (e, id, records, setRecords, setIsReviewDirty) => {
  const updatedRecords = records.map((i) => {
    if (i.id === id) {
      return { ...i, note: e.target.value };
    }
    return i;
  });
  setIsReviewDirty(true);
  setRecords(updatedRecords);
};
