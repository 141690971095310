import React from 'react';
import { currencyFormat } from '../../../../../modules/utils/currencyFormat';
import { t } from '../../../../../service/localization/i18n';

const TotalSection = ({ data }) => {
  const pstAmount = data ? data.PstTotal : 0;
  const gstAmount = data ? data.GstTotal : 0;
  const subTotal = data ? data.FeeAmount - data.DiscountTotal : 0;
  const grandTotal = data ? data.FeeTotal : 0;
  const balanceDue = data ? data.FeeTotal - data.FeePaid : 0;

  return (
    <>
      <div className="invoice-details">
        <div className="invoice-label font-normal">{t('Subtotal').toUpperCase()}</div>
        <div className="invoice-value flex justify-content-end font-normal">{currencyFormat(subTotal)}</div>

        <div className="invoice-label font-normal">{`${t('PST')} (BC) @ 7%`}</div>
        <div className="invoice-value flex justify-content-end font-normal">{currencyFormat(pstAmount)}</div>

        <div className="invoice-label font-normal">{`${t('GST')} (BC) @ 5%`}</div>
        <div className="invoice-value flex justify-content-end font-normal">{currencyFormat(gstAmount)}</div>

        <div className="invoice-label">{t('Total').toUpperCase()}</div>
        <div className="invoice-value flex justify-content-end">{currencyFormat(grandTotal)}</div>

        {data?.Payments?.length > 0 && (
          <>
            <div className="invoice-label font-normal">{t('Amount_received')}</div>
            <div className="invoice-value flex justify-content-end font-normal">{currencyFormat(data?.FeePaid)}</div>
          </>
        )}

        <div className="invoice-label">{t('Balance_due')}</div>
        <div className="invoice-value flex justify-content-end">{currencyFormat(balanceDue)}</div>
      </div>
    </>
  );
};

export default TotalSection;
