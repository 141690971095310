import React, { useContext } from 'react';
import Context from '../../../../../../../Context';
import { isChiropractor, isPhysician } from '../../../../../../config/specialitiesConfig';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const Header = () => {
  const { data } = useContext(Context);
  const speciality = data[inputs.speciality.name];
  const title = isPhysician(speciality) ? `${t('Physicians_report')} 8/11` : isChiropractor(speciality) ? `${t('Chiropractors_report')} 8C/11C` : '';

  return (
    <div className="flex justify-content-between w-full">
      <span className="text-2xl font-semibold wsbcMobileLabel">{t('WSBC')}</span>
      <span className="text-2xl font-semibold wsbcDesktopLabel">{t('WorkSafeBC')}</span>
      <span className="text-2xl font-semibold">{`${title}`}</span>
    </div>
  );
};

export default Header;
