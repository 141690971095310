/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import ClaimService from '../../../../../../../service/ClaimService';
import { useClaimsListContext } from '../../../hooks/useClaimsListContext';
import { getClaimReport } from '../../../../../../claims/actions/claims.action.creators';
import { t } from '../../../../../../../service/localization/i18n';

const ExportButton = (props) => {
  const toast = useRef(null);
  const { state } = useClaimsListContext();
  const user = useSelector((state) => state.user.details);
  const DCNGuid = user.DCNGuid;

  const onExport = async () => {
    const response = await getClaimReport({ sortby: state.sortby, query: state.query });

    if (response) {
      toast?.current?.show({
        severity: 'info',
        summary: 'You data export is ready',
        sticky: true,
        detail: (
          <div>
            <a href={response.LinkToCsv} style={{ textDecoration: 'underline' }} target="_blank">
              Report (CSV)
            </a>
            <br />
            <a href={response.LinkToXls} style={{ textDecoration: 'underline' }} target="_blank">
              Report (Excel)
            </a>
            <br />
            <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                persitsReport(response.LinkToShare);
              }}
              style={{ textDecoration: 'underline' }}
            >
              Share on <strong>Daysheet Uploads</strong>
            </a>
          </div>
        )
      });
    }
  };

  const persitsReport = async (fileName) => {
    const result = await ClaimService.shareReport(DCNGuid, fileName);
    if (result) alert('Report shared');
    return false; // do not open any link
  };

  return (
    <>
      <Button {...props} text icon="pi pi-file-export" tooltip={t('Export')} tooltipOptions={{ position: 'top' }} onClick={onExport} />
      <Toast ref={toast} sticky />
    </>
  );
};

export default ExportButton;
