import { t } from '../../../../../service/localization/i18n';
import { stringFormat } from '../../../../utils/stringFormat';

export const bmiSurgicalAssistCodes = ['13003'];
export const bmiSurgicalAssistBaseCodes = ['00193', '00195', '00196', '00197'];
export const codesThatRequiresBMI = ['01169', ...bmiSurgicalAssistCodes];

// checkIfCodeValid4Location
export const ccection = ['36075', '36076', '36077', '36078'];
export const hospital = ['G', 'I', 'F', 'E', 'P', 'Z'];

export const midwifeConsultativeCodes = ['36085', '36086'];
export const validMidwifeConsultativeHospital = ['N', 'T'];
//['B','C','E','F','I','J','K','M','R','U','V','Z'];

export const midwifeInductionCodes = ['36094', '36095'];
export const validMidwifeInductionHospital = ['E', 'G', 'I'];

// VER-550 - Midwives->code 36081->note required for 10 units and above
export const requiresNoteCodesForMidwives = ['36081'];

export const midwifePhase4HomeBirthCodes = ['36045'];
export const validmidwifePhase4HomeBirthHospital = ['R'];

export const anesProceduralCodes = ['01080'];
// CMO-2979 - Teleplan -> change in validation of some duplicate fee codes
// Basic Teleplan - step 2 - duplicate row - start/end time, notes inputs should not be required
export const feeCodesThatNotRequiresMandatoryValues = ['01012', '01073', '01050'];

// CMO-2995 - Make changes to WSBC billing ->Expedited button, 01015 to 19934, anatomic code
export const consultationAnaesthesia = ['01015'];
export const consultationPainManagement = ['01115', '01013'];
export const isWSBCConsultationCodes = [...consultationAnaesthesia, ...consultationPainManagement];

// VER-407
export const psychiatryIndividualOutPatientCodes = [
  {
    value: '00630',
    duration: 30,
    minDuration: 30,
    maxDuration: 44,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00630', '30 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00630', '44 min')
  },
  {
    value: '00631',
    duration: 45,
    minDuration: 45,
    maxDuration: 59,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00631', '45 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00631', '59 min')
  },
  {
    value: '00632',
    duration: 60,
    minDuration: 60,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00632', '60 min'),
    maxDurationMessage: ''
  }
];

// VER-407
export const psychiatryFamilyTherapylCodes = [
  {
    value: '00633',
    duration: 30,
    minDuration: 30,
    maxDuration: 44,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00633', '30 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00633', '44 min')
  },
  {
    value: '00635',
    duration: 45,
    minDuration: 45,
    maxDuration: 59,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00635', '45 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00635', '59 min')
  },
  {
    value: '00636',
    duration: 60,
    minDuration: 60,
    maxDuration: 74,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00636', '60 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00636', '74 min')
  },
  {
    value: '00638',
    duration: 75,
    minDuration: 75,
    maxDuration: 89,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00638', '75 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00638', '89 min')
  },
  {
    value: '00639',
    duration: 90,
    minDuration: 90,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00639', '90 min'),
    maxDurationMessage: ''
  }
];

// VER-407
export const psychiatryIndividualTreatmentCodes = [
  {
    value: '60630',
    duration: 30,
    minDuration: 30,
    maxDuration: 44,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60630', '30 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60630', '44 min')
  },
  {
    value: '60631',
    duration: 45,
    minDuration: 45,
    maxDuration: 59,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60631', '45 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60631', '59 min')
  },
  {
    value: '60632',
    duration: 60,
    minDuration: 60,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60632', '60 min'),
    maxDurationMessage: ''
  }
];

// VER-407
export const psychiatryFamilyTelehealthCodes = [
  {
    value: '60633',
    duration: 30,
    minDuration: 30,
    maxDuration: 44,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60633', '30 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60633', '44 min')
  },
  {
    value: '60635',
    duration: 45,
    minDuration: 45,
    maxDuration: 59,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60635', '45 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60635', '59 min')
  },
  {
    value: '60636',
    duration: 60,
    minDuration: 60,
    maxDuration: 74,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60636', '60 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60636', '74 min')
  },
  {
    value: '60638',
    duration: 75,
    minDuration: 75,
    maxDuration: 89,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60638', '75 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60638', '89 min')
  },
  {
    value: '60639',
    duration: 90,
    minDuration: 90,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '60639', '90 min'),
    maxDurationMessage: ''
  }
];

// VER-753 Remove recommended time for psychiatry codes
export const psychiatryIndividualInPatientCodes = [];

// VER-407
export const psychiatryIndividualInPatientCodesTbd = [
  {
    value: '00650',
    duration: 30,
    minDuration: 30,
    maxDuration: 44,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00650', '30 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00650', '44 min')
  },
  {
    value: '00651',
    duration: 45,
    minDuration: 45,
    maxDuration: 59,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00651', '45 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00651', '59 min')
  },
  {
    value: '00652',
    duration: 60,
    minDuration: 60,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00652', '60 min'),
    maxDurationMessage: ''
  }
];

// VER-408
export const psychiatryExtendedAdultConsultCodes = [
  {
    value: '00611',
    duration: 69,
    minDuration: 69,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00611', '69 min'),
    maxDurationMessage: ''
  }
];

// VER-409
export const groupPhysiotherapyCodes = [
  {
    value: '00663',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00663', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00664',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00664', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00665',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00665', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00666',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00666', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00667',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00667', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00668',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00668', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00669',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00669', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00670',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00670', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00671',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00671', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00672',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00672', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00673',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00673', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00674',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00674', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00675',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00675', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00676',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00676', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00677',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00677', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00678',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00678', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00679',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00679', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00680',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00680', '30 min'),
    maxDurationMessage: ''
  },
  {
    value: '00681',
    duration: 30,
    minDuration: 30,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00681', '30 min'),
    maxDurationMessage: ''
  }
];

// VER-410
export const psychiatryTelehealthMiscCodes = [
  {
    value: '00624',
    duration: 8,
    minDuration: 8,
    maxDuration: 60,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y_or_major_portion'), '00624', '15 min', '8 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00624', '60 min')
  },
  {
    value: '00645',
    duration: 8,
    minDuration: 8,
    maxDuration: 60,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y_or_major_portion'), '00645', '15 min', '8 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '00645', '60 min')
  },
  {
    value: '60624',
    duration: 8,
    minDuration: 8,
    maxDuration: 60,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y_or_major_portion'), '60624', '15 min', '8 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60624', '60 min')
  },
  {
    value: '60645',
    duration: 8,
    minDuration: 8,
    maxDuration: 60,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y_or_major_portion'), '60645', '15 min', '8 min'),
    maxDurationMessage: stringFormat(t('Code_X_max_duration_Y'), '60645', '60 min')
  }
];

// VER-411
export const psychiatryEmergencyCareCodes = [
  {
    value: '00081',
    duration: 15,
    minDuration: 15,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00081', '15 min'),
    maxDurationMessage: ''
  },
  {
    value: '00082',
    duration: 15,
    minDuration: 15,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00082', '15 min'),
    maxDurationMessage: ''
  },
  {
    value: '00083',
    duration: 15,
    minDuration: 15,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00083', '15 min'),
    maxDurationMessage: ''
  },
  {
    value: '00084',
    duration: 15,
    minDuration: 15,
    maxDuration: 1439,
    minDurationMessage: stringFormat(t('Code_X_min_duration_Y'), '00084', '15 min'),
    maxDurationMessage: ''
  }
];

export const ignoreDurationForPsychiatryCodesList = ['00624', '00645'];

export const durationForPsychiatryCodesList = [
  psychiatryIndividualOutPatientCodes,
  psychiatryFamilyTherapylCodes,
  psychiatryIndividualTreatmentCodes,
  psychiatryFamilyTelehealthCodes,
  psychiatryIndividualInPatientCodes,
  psychiatryExtendedAdultConsultCodes,
  psychiatryTelehealthMiscCodes,
  psychiatryEmergencyCareCodes,
  groupPhysiotherapyCodes
];

export const age_over_18 = ['00611'];
export const age_over_75 = ['00613', '00614', '60613', '60614'];
export const age_below_18 = ['00622', '00626', '60622', '60626', '50550', '50555'];

// checkIfCodeNeedsReferralBy
export const feeCodesNeedsReferralBy = [
  // WIN-345 - Serenity->Teleplan->add validation for 10002 code->referring doctor is needed
  '10002',

  '01108',
  '01109',
  '01013',
  '01015',
  '01115',
  '01016',
  '01116',
  '01155',
  '00210',
  '00407',
  '00408',
  '00410',
  '00624',
  '02007',
  '02008',
  '02010',
  '10001',
  '18110',
  '19150',
  '19151',
  '19330',
  '19934',
  '19935',
  '01706',
  '01107',
  '00645',
  '60645',

  // CMO-3031 - Psychiatry validation - mandatory referral
  '00605',
  '00607',
  '00608',
  '00609',
  '00610',
  '00611',
  '00613',
  '00614',
  '00615',
  '00622',
  '00623',
  '00625',
  '00626',
  '00627',
  '00630',
  '00631',
  '00632',
  '00633',
  '00635',
  '00636',
  '00638',
  '00639',
  '00641',
  '00645',
  '00650',
  '00651',
  '00652',
  '00663',
  '00664',
  '00665',
  '00666',
  '00667',
  '00668',
  '00669',
  '00670',
  '00671',
  '00672',
  '00673',
  '00674',
  '00675',
  '00676',
  '00677',
  '00678',
  '00679',
  '00680',
  '00681',
  '60607',
  '60608',
  '60613',
  '60614',
  '60622',
  '60624',
  '60625',
  '60626',
  '60630',
  '60631',
  '60632',
  '60633',
  '60635',
  '60636',
  '60638',
  '60639',

  // [KS] CMO-1450 - Consultation in office requires referral 'by'. 00110 does not ask for referral, but must
  '12110',
  '00110',
  '00116',
  '12210',
  '15310',
  '16110',
  '17110',
  '13210',
  '15210',
  '16210',
  '17210',
  '18210',
  '01810',

  // midwifes
  '36079',
  '36080',
  '36081',
  '36082',
  '36083',
  '36087'
];

// checkIfCodeNeedsReferralTo
export const feeCodesNeedsReferralTo = ['03333'];

export const feeCodeNeedsEmergency = [
  '01200',
  '01201',
  '01202', // call-out
  '01205',
  '01206',
  '01207', // non-opertive
  '01210',
  '01211',
  '01212', // operative
  '01215',
  '01216',
  '01217', // anesthiology
  '19405',
  '19406',
  '19407', // anesthiology - WCB
  '36075',
  '36076',
  '36077' // midwifes surgical assist
];

// feeCodeConflicts
// surg assist codes
export const spec47 = ['00193', '00195', '00196', '00197', '70019', '70020'];
export const spec18 = [
  '01172',
  '01173',
  '01174',
  '01175',
  '01176',
  '01177',
  '01178',
  '01179',
  '01180',
  '01181',
  '01015',
  '01115',
  '01016',
  '01116',
  '01013',
  '01088',
  '01090',
  '01091',
  '01094',
  '01095',
  '00017',
  '01102',
  '01158',
  '01005',
  '01105',
  '01106',
  '01110',
  '01111',
  '01112'
];

// VER-415 - Add validations for new codes 01118 and 01119
export const inpatientExtendedConsultation = ['01118', '01119'];

// feeCodeNeedsOnlyStartTime
export const start_time_needed = [
  //ec_start_time_needed
  '14018',
  '01821',
  '01822',
  '01823',
  '01831',
  '01832',
  '01833',

  //ec_weekend
  '01841',
  '01842',
  '01843',
  '01217',
  '19407',

  // midwife consult
  ...midwifeConsultativeCodes
];

export const time_recommended = ['00650', '00651', '00652'];

// feeCodeNeedsTime
export const time_required = [
  '01005',
  '01047',
  '01048',
  '01049',
  '01088',
  '01090',
  '01105',
  '01106',
  '01110',
  '01111',
  '01112',
  '01172',
  '01173',
  '01174',
  '01175',
  '01176',
  '01177',
  '01178',
  '01179',
  '01180',
  '01181',
  '01205',
  '01206',
  '01207',
  '01215',
  '01216',
  '01217',
  '19507',
  '19405',
  '19406',
  '19407',
  '00193',
  '00198',
  '70020',
  '00081',
  '00082',
  '01870',
  '01871',
  '13015',
  '70019',
  '70020',
  '01169',

  // psychiatry
  '00605',
  '00611',
  '00630',
  '60630',
  '00633',
  '60633',
  '00631',
  '60631',
  '00635',
  '60635',
  '00632',
  '60632',
  '00636',
  '60636',
  '00638',
  '60638',
  '00639',
  '60639',
  '00624',
  '00645',
  '00663',
  '00664',
  '00665',
  '00666',
  '00667',
  '00668',
  '00669',
  '00670',
  '00671',
  '00672',
  '00673',
  '00674',
  '00675',
  '00676',
  '00677',
  '00678',
  '00679',
  '00680',
  '00681',
  '13763',
  '13764',
  '13765',
  '13766',
  '13767',
  '13768',
  '13769',
  '13770',
  '13771',
  '13772',
  '13773',
  '13774',
  '13775',
  '13776',
  '13777',
  '13778',
  '13779',
  '13780',
  '13781',
  '60624',
  '60645',

  // new opioid treatment codes and nurse
  '13013',
  '13014',
  '00115',

  // midwifes
  '36079',
  '36080',
  '36081',
  '36082',
  '36083',
  '36087',
  '36075',
  '36076',
  '36077',
  '36094',
  '36095',

  // telehealth
  '10002',
  '10003',
  '10004',

  // pediatrics
  '00550',
  '00551',
  '00511',
  '00585',
  '00514',
  '00513',
  '00515',
  '00552',
  '00553',
  '00554',
  '50515',
  '50516',
  '50511',
  '50514',
  '50517',
  '50518',
  '50518',
  '50545',

  // group visits
  '78763',
  '78764',
  '78765',
  '78766',
  '78767',
  '78768',
  '78769',
  '78770',
  '78771',
  '78772',
  '78773',
  '78774',
  '78775',
  '78776',
  '78777',
  '78778',
  '78779',
  '78780',
  '78781',

  // Inpatient extended consultation
  ...inpatientExtendedConsultation
];

// feeCodesNOTNeedWeekendValidation
// List of codes that do not require emergency or out of office hours codes to be added
export const feeCodesNoNeedWeekendValidation = [
  '00103',
  '00111',
  '00112',
  '00115',
  '03005',
  '01705',
  '08005',
  '77005',
  '79005',
  '94005',
  '01870',
  '00113',
  '00105',
  '00123',
  '14018',
  '14021',
  '14022',
  '14023',
  '01108',
  '01109',
  '01049',
  '00205',
  '02005',
  '02505',
  '00305',
  '33005',
  '30005',
  '33205',
  '33305',
  '33405',
  '33505',
  '33605',
  '33655',
  '33705',
  '32005',
  '31005',
  '00405',
  '04005',
  '00505',
  '00605',
  '06005',
  '07005',
  '07001',
  '07003',
  '07805',
  '10001',
  '10002',
  '10005',
  '00114',
  '01048',
  '01810',
  '01811',
  '01821',
  '01831',
  '01841',
  '01812',
  '01822',
  '01832',
  '01842',
  '01813',
  '01823',
  '01833',
  '01843',
  '01850',
  '01851',
  '01860',
  '01861',
  '01862',
  '01205',
  '01206',
  '01207',
  '01200',
  '01201',
  '01202',
  '01210',
  '01211',
  '01212',
  '01215',
  '01216',
  '01217'
];

export const feeCodesNeedsComment = ['00638', '00639', '00681'];

//checkOffHours

// Day from 08:00 to 18:00, workdays (weekdays)
export const ec_workdays = ['01811', '01812', '01813', '01047'];

// Evening from 18:00 to 23:00, workdays (weekdays)
export const ec_evening = ['00113', '01200', '01205', '01210', '01215', '01821', '01822', '01823', '36075', '50571'];

// Night from 23:00 to 08:00, both workdays, weekends and holidays
export const ec_night = ['00105', '01049', '01201', '01206', '01211', '01216', '01831', '01832', '01833', '36076', '50573'];

// 08:00 to 23:00 on Saturday, Sunday and statutory Holidays
export const ec_weekend = ['00123', '01202', '01207', '01212', '01217', '01841', '01842', '01843', '36077', '50572'];

// Saturday, Sunday and statutory Holidays // 00:00 to 24:00
export const ex_weekend_whole_day = ['01109'];

// Evening from 18:00 to 23:00, workdays (weekdays) and 08:00 to 23:00 on Saturday, Sunday and statutory Holidays
export const ec_evening_and_ec_weekend = ['01048'];

// Workday any time from 0000 to 2400
export const whole_workdays = ['01108'];

// Day from 08:00 to 18:00, workdays, weekends and holidays
export const ec_day_mw = ['36085'];

// Evening from 18:00 to 8:00, workdays, weekends and holidays
export const ec_night_mw = ['36086'];
