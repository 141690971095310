import { invoicesTypesLabels } from '../../claims/helpers/mapper';

export const filteredCounters = (counters, invoiceType) => {
  // Patient Pay (Private)
  if (invoiceType === invoicesTypesLabels.patientPay) {
    return counters?.filter((i) => {
      return i.InvoiceType === invoiceType && (i.StatusTextNormialized === 'draft' || i.StatusTextNormialized === 'outstanding');
    });
  }

  // Teleplan
  if (invoiceType === invoicesTypesLabels.teleplan) {
    return counters?.filter((i) => {
      return (
        i.InvoiceType === invoiceType &&
        (i.StatusTextNormialized === 'declined' ||
          i.StatusTextNormialized === 'inprocess' ||
          i.StatusTextNormialized === 'outstanding' ||
          i.StatusTextNormialized === 'draft')
      );
    });
  }

  return [];
};
