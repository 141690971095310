import { defaultPageSize, showInCardPageSize } from '../../../modules/config/defaultValuesConfig';
import { t } from '../../../service/localization/i18n';

const showInCard = false;
const defaultRowOption = showInCard ? showInCardPageSize : defaultPageSize;
const rowOptions = [10, defaultRowOption, 50];

export const tableProps = {
  // ====== PRIME REACT DATA TABLE PROPS ====== //
  stripedRows: true, // boolean // Whether to displays rows with alternating colors
  resizableColumns: true, // boolean // When enabled, columns can be resized using drag and drop
  dragSelection: true, // boolean // When enabled, a rectangle that can be dragged can be used to make a range selection
  scrollable: true, // boolean // When specified, enables horizontal and/or vertical scrolling
  showExpand: true, // boolean // When enabled, displays expand button for each row
  showSelection: false, // boolean // Show multiple selection button for desktop view
  scrollHeight: 'flex', // string // Height of the scroll viewport
  selectionMode: 'multiple', // string // Specifies the selection mode, valid values are "single", "multiple", "radiobutton" and "checkbox"
  frozenWidth: '215px', // string // Width of the frozen part in scrollable DataTable.
  ref: null, // ref for data table
  id: null, // string // Unique identifier of the element
  value: null, // array // An array of objects to display
  header: null, // JSX | object // Header content of the table. It can be JSX element or object with parameters for existing JSX
  footer: null, // any // Footer content of the table
  columnsForToggle: null, // array // An array of objects to display columns that can be toggled.
  columns: null, // array // An array of objects to display columns. For more details for object values see Column Component Properties in the Prime React docs (https://www.primefaces.org/primereact/datatable/)
  emptyMessage: t('No_records_found'), // Text to display when there is no data

  // filters
  globalFilter: null, // string // Value of the global filter to use in filtering
  filterDisplay: 'row', // string // Layout of the filter elements, valid values are "row" and "menu"

  // row action buttons
  rowActionButtons: null, // JSX // Tamplate for row actions
  rowActionButtonsMenuModel: null, // array // NOT required // An array of menuitems. Need for data table context menu

  // expanded rows
  expandedRows: null, // array | object // A collection of rows or a map object row data keys that are expanded
  setExpandedRows: null, // function // Callback for updating expandedRows list
  rowExpansionTemplate: null, // function // Function that receives the row data as the parameter and returns the expanded row content
  isExpanded: false, // boolean // Defines if all rows are expanded
  setIsExpanded: null, // callback // Callback to update 'isExpanded' value

  // sorting
  onSort: null, // Callback to invoke on sort
  sortField: null, // Name of the field to sort data by default
  sortOrder: null, // Order to sort the data by default
  defaultSortTemplate: null, // JSX // Button for default sort order

  // selection mode
  dataKey: null, // string // A property to uniquely identify a record in data
  selection: null, // any // Selected row in single mode or an array of values in multiple mode

  // paginator
  lazy: true, // boolean // Defines if data is loaded and interacted with in lazy manner
  paginator: true, // boolean // When specified as true, enables the pagination
  first: null, // number // Index of the first row to be displayed
  totalRecords: null, // number // Number of total records, defaults to length of value when not defined
  rows: null, // number // Number of rows to display per page
  rowsPerPageOptions: rowOptions, // array //	Array of integer values to display inside rows per page dropdown
  // pageLinkSize: isMobile || showInCard ? 2 : 4,
  // currentPageReportTemplate: `${props.first || '{first}'} to {last} of ${props.totalRecords || '{totalRecords}'}`,
  paginatorTemplate: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown', // string | object // Template of the paginator. For details, refer to the template section of the paginator documentation for further options
  paginatorLeft: null, // JSX // Content for the left side of the paginator
  paginatorRight: null, // JSX // Content for the right side of the paginator
  onPage: null, // function // Callback to invoke on pagination
  // ========================= //

  // ====== CUSTOM PROPS ====== //
  showInCard: showInCard, // boolean // Defines if data table should be displayed in the widget card (for example display in the home page widget or patient profile widget)
  fetchingData: false, // boolean // Displays a loader to indicate data load is in progress
  name: null, // string // Name for data table. Define in the 'dataTablesParams.js' file
  filters: null, // array // Array with applied filters. Check 'Filters data example' below
  sidebarFilters: null, // JSX // Layout for sidebar filters
  onChipRemove: null, // function // Callback for removing chip
  clearAllFilters: null, // function // Callback for clearing all filters/chips
  preSelected: null, // array // An array of higlighted item. Need for autoscrollig data table to selected row item. If passed 'preSelected', then pass 'setPreSelected' callback to reset preSelected value
  setPreSelected: null, // callback // Required if passed 'preSelected'. Need for reseting 'preSelected' value after autoscroll to higlighted element. If passed 'preSelected', then pass 'setPreSelected' callback to reset preSelected value
  columnsForPDF: null, // array // A list of columns to download data table in PDF format
  recordsForExcel: null // array // An array of objects to export data table in excel format
  // ========================= //
};

// Filters data example
// const testFilters = [
//   { field: 'FirstName', label: 'First Name', value: 'Harry' },
//   { field: 'Phone', label: 'Phone', value: '(604) 211-0123' },
//   { field: 'Number', label: 'Number', value: '57392' },
// ];
