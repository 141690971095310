import React from 'react';

import AccidentType from '../Inputs/AccidentType';
import PrescriberType from '../Inputs/PrescriberType';
import AccidentDate from '../Inputs/AccidentDate';
import FirstName from '../Inputs/FirstName';
import LastName from '../Inputs/LastName';

import { useConditions } from '../../../hooks/useConditions';
import cx from 'classnames';

const CoordinationOfBenefits = () => {
  const { accidentDateRequired, prescriberFirstNameRequired, prescriberLastNameRequired } = useConditions();

  return (
    <div className="flex flex-column align-items-center gap-3 w-full">
      <div className="flex flex-column lg:flex-row align-items-center gap-3 w-full">
        <div className="eclaim_step_two_left_gap"></div>

        <div className="flex flex-column sm:flex-row gap-3 w-full">
          <div className="eclaim_step_two_input_wrap">
            <PrescriberType />
          </div>
          <div className={cx('eclaim_step_two_input_wrap', { hidden: !prescriberFirstNameRequired })}>
            <FirstName />
          </div>
          <div className={cx('eclaim_step_two_input_wrap', { hidden: !prescriberLastNameRequired })}>
            <LastName />
          </div>

          <div className={cx('eclaim_step_two_input_wrap', { hidden: prescriberFirstNameRequired })}>
            <AccidentType />
          </div>
          <div className={cx('eclaim_step_two_input_wrap', { hidden: !accidentDateRequired || prescriberFirstNameRequired })}>
            <AccidentDate />
          </div>
        </div>

        <div className="eclaim_step_two_right_gap"></div>
      </div>

      <div className="flex flex-column lg:flex-row align-items-center lg:gap-3 w-full">
        <div className="eclaim_step_two_left_gap"></div>

        <div className="flex flex-column sm:flex-row gap-3 w-full">
          <div className={cx('eclaim_step_two_input_wrap', { hidden: !prescriberFirstNameRequired })}>
            <AccidentType />
          </div>
          <div className={cx('eclaim_step_two_input_wrap', { hidden: !accidentDateRequired || !prescriberFirstNameRequired })}>
            <AccidentDate />
          </div>
        </div>

        <div className="eclaim_step_two_right_gap"></div>
      </div>
    </div>
  );
};

export default CoordinationOfBenefits;
