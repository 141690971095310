import React from 'react';
import { Controller } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs/index';

import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { useLocation } from '../../../hooks/useLocation';
import { useValidation } from '../../../hooks/useValidation';
import cx from 'classnames';

const Location = () => {
  const name = inputs.location.name;
  const { control, isView } = useFormContext();
  const { locationValidation } = useValidation();
  const { isLocked, onSavePreferences } = usePreferences();
  const { getLocationDropdownOptions, onLocationChange } = useLocation();
  const options = getLocationDropdownOptions();
  const disabled = isView;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => locationValidation(value) }}
      render={({ field, fieldState: { error } }) => {
        const value = field.value;
        return (
          <InputWrapper
            required={!disabled}
            name={name}
            label={inputs.location.label}
            errorMessage={error?.message}
            isLocked={isLocked(value?.RowGuid, prefsCodes.eClaimLocation)}
            onSavePrefs={disabled ? undefined : () => onSavePreferences(value?.RowGuid, prefsCodes.eClaimLocation)}
          >
            <Dropdown
              style={{ width: 'inherit' }}
              className={cx({ 'p-invalid': error?.message })}
              id={elementIDs.locationPickerDropdown}
              inputRef={field.ref}
              type="text"
              optionLabel="LocationName"
              value={value}
              options={options}
              disabled={disabled}
              scrollHeight={dropdownScrollHeight()}
              onChange={(e) => onLocationChange(e, field)}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

export default Location;
