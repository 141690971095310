import React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import ProvincePicker from './ProvincePicker';

// interface IGender {
//   name: string;
//   label: string;
//   value: string,
//   onChange: () => void,
//   input?: {
//     name: string,
//     label: string,
//     codeType?: string,
//   },
//   required?: boolean,
//   errorMessage?: string,
//   optionLabel?: "label" | "value",
//   ref?: any
// };

const Province = React.forwardRef((props, ref) => {
  const name = props.name || props.input.name;

  return (
    <InputWrapper name={name} label={props.label || props.input.label} required={props.required} errorMessage={props.errorMessage}>
      <ProvincePicker
        {...props}
        ref={ref}
        name={name}
        value={props.value}
        optionLabel={props.optionLabel}
        errorMessage={props.errorMessage}
        onChange={props.onChange}
      />
    </InputWrapper>
  );
});

export default Province;
