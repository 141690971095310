export const mobileRowToggle = (props) => {
  const {
    rowData,
    expandedRows,
    setExpandedRows,
    dataKey // dataKey than used in data table
  } = props;

  const isExpanded = expandedRows ? Object.keys(expandedRows).some((i) => i === rowData[dataKey]) : {};

  if (isExpanded) {
    const _expandedRows = { ...expandedRows };
    delete _expandedRows[rowData[dataKey]];
    setExpandedRows(_expandedRows);
  }

  if (!isExpanded) {
    const _expandedRow = { [rowData[dataKey]]: true };
    setExpandedRows({ ...expandedRows, ..._expandedRow });
  }
};
