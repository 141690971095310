import React, { useState, useEffect, useCallback, useContext } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import Context from '../../../Context';

import cx from 'classnames';
import _ from 'lodash';
import { t } from '../../../service/localization/i18n';

const MoneyInput = ({ classes, initialValue, setFieldFilter, invalidFields, field, fieldType, ensureNumeric, filters }) => {
  const { sortby } = useContext(Context);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSetFieldFilter = (e) => {
    setValue(e.value);
    debouncedRequest(e, field, fieldType, filters, sortby);
  };

  const debouncedRequest = useCallback(
    _.debounce((e, field, fieldType, filters, sortby) => {
      setFieldFilter(e, field, fieldType, filters, sortby);
    }, 1500),
    []
  );

  return (
    <InputNumber
      className={cx(classes, invalidFields[field])}
      inputId="locale-user"
      mode="decimal"
      minFractionDigits={2}
      id={'flt_' + field}
      value={value ? value : null}
      placeholder={t('Search')}
      onKeyDown={(e) => ensureNumeric(e, field)}
      onChange={(e) => onSetFieldFilter(e)}
    />
  );
};

export default MoneyInput;
