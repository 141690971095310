import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';
import { inpatientExtendedConsultation } from '../../helpers/codesCheckList';

const IECDialog = () => {
  const { localState, setLocalState, watch } = useFormContext();

  if (!localState.iecDialog) return null;

  const feeCodes = watch(inputs.feeCodes.codeType);
  const locationCode = watch(inputs.locationCode.name);
  const feeCodesOnly = feeCodes?.filter((code) => code.value);
  const iecCodes = feeCodesOnly?.filter((value) => inpatientExtendedConsultation.includes(value));
  const isValidLocation = locationCode === 'E' || locationCode === 'I';
  const message = !isValidLocation
    ? 'Service location should be E or I'
    : `Selected code(s) ${iecCodes} is paid ony in addition to an in-person consultation (01015)`;

  const onHide = () => {
    setLocalState((prevState) => ({ ...prevState, iecDialog: false }));
  };

  const emergencyDialogFooter = () => {
    return <Button label={t('Close')} onClick={onHide} autoFocus />;
  };

  return (
    <Dialog
      header={t('Missing_information')}
      visible={localState.iecDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={emergencyDialogFooter()}
      onHide={() => onHide()}
    >
      {message}
    </Dialog>
  );
};

export default IECDialog;
