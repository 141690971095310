import React from 'react';
import { useFormContext } from 'react-hook-form';

import ApplyToAllReferral from './ApplyToAllReferral';
import ApplyToAllTime from './ApplyToAllTime';
import ApplyToAllDx from './ApplyToAllDx';

import { steps } from '../../../../../helpers/steps';

const ApplyToAllButton = () => {
  const { localState } = useFormContext();

  // Do not show for comments
  if (localState.step === steps.groupComments) return null;

  // Apply to all time
  if (localState.step === steps.groupServicesPicker) return <ApplyToAllTime />;

  // Apply to all dx
  if (localState.step === steps.groupDxPicker) return <ApplyToAllDx />;

  // Apply to all referral
  if (localState.step === steps.groupReferralPicker) return <ApplyToAllReferral />;
};

export default ApplyToAllButton;
