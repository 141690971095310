import React, { useEffect } from 'react';
import { InputMask } from 'primereact/inputmask';
import cx from 'classnames';

// interface IPHN {
//   ref: Ref<HTMLInputElement>,
//   value: string,
//   name: string,
//   onChange: (e) => void,
//   onFocus?: (e) => void,
//   onBlur?: (e) => void,
//   province?: string,
//   className?: string,
//   disabled?: boolean,
//   autoFocus?: boolean,
// }

const PHN = React.forwardRef((props, ref) => {
  // To set autoFocus need to pass ref
  // useEffect(() => {
  //   props.autoFocus && ref?.current?.focus();
  // }, [props.autoFocus, ref]);

  // const onFocus = (e) => {
  //   props.value === 'N/A' && e.target.select();
  // };

  useEffect(() => {
    if (ref && ref.current) {
      setTimeout(() => {
        const length = props.value?.length;
        const element = ref.current.getElement?.();
        element?.focus?.();
        element?.setSelectionRange?.(length, length);
      }, 100);
    }
  }, []);

  const mask = props.province === 'BC' ? '9999 999 999' : '9999 9999 9999'; // CMO-2526 - Rules for PHN input
  const placeholder = props.province === 'BC' ? 'XXXX XXX XXX' : 'XXXX XXXX XXXX'; // CMO-2526 - Rules for PHN input

  return (
    <InputMask
      className={cx('w-full', props.className || '')}
      id={props.name}
      name={props.name}
      ref={ref}
      disabled={props.disabled}
      // autoFocus={props.autoFocus} // App crashes. Do not use autoFocus in PHN input! Fixed in Primeract v10.0.0
      autoClear={false}
      mask={mask}
      placeholder={placeholder}
      value={props.value?.trim() || ''}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
});

export default PHN;
