import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { t } from '../../../service/localization/i18n';

const DeleteDialog = ({ visible, message, onHide, accept }) => {
  const onDelete = () => {
    accept();
    onHide();
  };

  const footer = () => {
    return (
      <div className="flex justify-content-end gap-2">
        <Button autoFocus outlined label={t('No')} onClick={onHide} />
        <Button className={`p-button-danger`} label={t('Yes')} onClick={onDelete} />
      </div>
    );
  };

  return (
    <Dialog
      header="Delete Confirmation"
      visible={visible}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      onHide={onHide}
      footer={footer}
    >
      <div className="flex align-items-center gap-3">
        <i className="pi pi-exclamation-triangle" style={{ fontSize: '1.5em' }} />
        {message}
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
