import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { t } from '../../../../../../../service/localization/i18n';

const ReferralCodeLayout = ({ rowData }) => {
  if (rowData?.InvoiceType === 'Private') {
    return <div></div>;
  }

  return (
    <>
      <Tooltip target={`.refNumberTooltip_${rowData.RefNumber}`} position="top" showDelay={1000}>
        {rowData.RefName}
      </Tooltip>
      <span className={`refNumberTooltip_${rowData.RefNumber}`}>{rowData.RefNumber || t('N_A')}</span>
    </>
  );
};

export default ReferralCodeLayout;
