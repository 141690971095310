import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Chip } from 'primereact/chip';
import { Skeleton } from 'primereact/skeleton';
import { Patient as Patients } from '../../../../../../../components/Inputs/index';

import { inputs } from '../../../helpers/inputs';
import { usePatient } from '../../../hooks/usePatient';
import { useValidation } from '../../../hooks/useValidations';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';

const EditLayout = ({ value }) => {
  const { localState } = useFormContext();

  if (localState.isLoading) return <Skeleton borderRadius="25px" width="200px" height="31.5px" />;

  const patientName = value?.length ? patientFullNameWithAge(value[0]) : '';
  if (!patientName) return null;

  return (
    <div id={`${elementIDs.patientChip}_${value[0].PatientGuid?.toUpperCase()?.replaceAll('-', '')}`}>
      <Chip className="mr-2" label={patientName} removable={false} icon="pi pi-user" />
    </div>
  );
};

const PatientPicker = () => {
  const name = inputs.patient.name;
  const { errors, control, isNew, isNewForPatient, getValues } = useFormContext();
  const { patientValidation } = useValidation();
  const { onPatientChange } = usePatient();

  const onChange = (patients) => {
    const currentPatient = getValues(inputs.patient.name);
    const value = Array.isArray(patients) ? patients : [...currentPatient, patients];
    onPatientChange(value);
  };

  const patientInput = (field) => {
    if (!isNew || isNewForPatient) return <EditLayout value={field.value} />;

    return (
      <Patients
        name={field.name}
        ref={field.ref}
        required={true}
        multiple
        forceSelection={false}
        disabled={!isNew}
        maxEntry={1}
        // showAddPatientButton={isNew}
        showAddPatientButton={false}
        isInvalid={!!errors[name]?.message}
        errorMessage={errors[name]?.message}
        value={field.value}
        onChange={(e) => onChange(e.value)}
      />
    );
  };

  return (
    <Controller name={name} control={control} rules={{ validate: (value) => patientValidation(value) }} render={({ field }) => patientInput(field)} />
  );
};

export default PatientPicker;
