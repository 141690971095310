import React, { createContext, useState } from 'react';
import { cashFlowRequestParams } from '../../../reducers/cashFlow.reducer';
import { t } from '../../../../../service/localization/i18n';

export const ClaimsTableContext = createContext();

const ClaimsTableProvider = ({ requestParams, ...props }) => {
  const [state, setState] = useState({
    ...cashFlowRequestParams,
    ...requestParams,
    emptyMessage: props.emptyMessage || t('No_records_found'),
    selectedItem: null,
    loading: false,
    PatientGuid: null,
    totalRecords: 0,
    totalPages: 1,
    totalBilled: 0,
    totalPaid: 0,
    totalDue: 0,
    totalRecordsAlter: 0,
    claimList: []
  });

  return <ClaimsTableContext.Provider value={{ state, setState }}>{props.children}</ClaimsTableContext.Provider>;
};

export default ClaimsTableProvider;
