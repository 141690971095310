import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { columnValues } from '../helpers/columnValues';

const TextInput = ({ columnKey, filters, setFilters }) => {
  const currentColumn = columnValues[columnKey];
  const currentField = currentColumn.filter;

  const [value, setValue] = useState('');

  // Update local state when chip is deleted
  useEffect(() => {
    const currentValue = filters?.find((i) => i.field === currentField)?.value || '';
    setValue(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onChange = (e) => {
    const _value = e.target.value;
    let updatedFilters = filters;

    if (_value) {
      const isFilterExist = filters.some((i) => i.field === currentField);

      if (isFilterExist) {
        updatedFilters = filters?.map((i) => {
          if (i.field === currentField) return { ...i, value: _value };
          return i;
        });
      }

      if (!isFilterExist) {
        updatedFilters = [...filters, { label: currentColumn.header, value: _value, field: currentColumn.field }];
      }
    }

    if (!_value) {
      updatedFilters = filters?.filter((i) => i.field !== currentField);
    }

    setFilters(updatedFilters);
  };

  return <InputText type="search" value={value} placeholder={currentColumn.placeholder} onChange={onChange} />;
};

export default TextInput;
