import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedNameInput } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const MidName = () => {
  const name = inputs.midName.name;
  const { control } = useFormContext();

  const nameInput = (field) => {
    return <WrappedNameInput {...field} input={inputs.midName} onChange={(value) => field.onChange(value)} />;
  };

  return <Controller name={name} control={control} render={({ field }) => nameInput(field)} />;
};

export default MidName;
