import { provinces_list } from '../common/contants';

export const autocompleteSearchProvince = (e, setfilteredProvinces) => {
  let _filteredProvinces;
  if (!e.query.trim().length) {
    _filteredProvinces = [...provinces_list];
  } else {
    _filteredProvinces = provinces_list.filter((province) => {
      return province.label.toLowerCase().startsWith(e.query.toLowerCase());
    });
  }

  setfilteredProvinces(_filteredProvinces);
};
