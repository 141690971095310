import React from 'react';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../../helpers/inputs';
import { Tooltip } from 'primereact/tooltip';

const ResultsHeader = ({ eClaimResult }) => {
  const { getValues } = useFormContext();
  const patients = getValues(inputs.patient.name);
  const patientName = patients?.length ? patientFullNameWithAge(patients[0]) : '';
  const insurerNameEn = eClaimResult.InsurerNameEN;

  return (
    <div className="flex flex-column gap-3">
      <div>
        <span>{`${insurerNameEn} - `}</span>
        <span className="font-bold">{`${patientName}`}</span>
      </div>

      <div className="text-base">
        <Tooltip target={`.cm_reference`} position="top">
          {`Claim number on your CM database`}
        </Tooltip>
        <span className="cm_reference">{`CM Reference #: ${eClaimResult.ClaimNumber}`}</span>
      </div>
    </div>
  );
};

export default ResultsHeader;
