import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import ChartCardTitle from './ChartCardTitle';

const ChartCard = ({ children, title, loading, isChartDataEmpty }) => {
  return (
    <div className="card mb-0">
      {
        typeof title === 'string' ? (
          <ChartCardTitle title={title} />
        ) : typeof title === 'function' ? (
          title() // If title is a function, execute it to get the JSX
        ) : (
          title
        ) // If it's already JSX, render it directly
      }

      {isChartDataEmpty && !loading ? (
        <div className="h-15rem flex flex-column align-items-center justify-content-center">
          <img src="assets/images/meditating_cat_doctor_112.gif" alt="...meditating..." width="108" height="112" />
          <div className="text-center text-xl">You don't have data for the selected period</div>
        </div>
      ) : (
        <div className="mt-3">{loading ? <Skeleton height="375px" /> : children}</div>
      )}
    </div>
  );
};

export default ChartCard;
