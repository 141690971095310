import { useCatalogsContext } from './useCatalogsContext';

export const useTabs = () => {
  const { catalogsState, setCatalogsState, onTabChange, catalogTabs } = useCatalogsContext();
  const activeSubTabIndex = catalogsState?.activeSubTabIndex;
  const activeSubtab = catalogsState?.currentCategory?.tabs?.[activeSubTabIndex];

  const onChangeTab = (tabIndex) => {
    const activeTab = catalogTabs[tabIndex];
    const codeList = activeTab.defaultTableData || [];

    setCatalogsState((prevState) => ({
      ...prevState,
      trail: [],
      tableData: { page: 0, codeList },
      searchValue: '',
      activeTab,
      activeSubTabIndex: 0
    }));
  };

  const handleTabChange = (e) => {
    onChangeTab(e.index);
    if (onTabChange) onTabChange(e);
  };

  const onSubTabChange = (e) => {
    if (e.index !== activeSubTabIndex) {
      setCatalogsState((prevState) => ({
        ...prevState,
        trail: [],
        tableData: { page: 0 },
        searchValue: '',
        setFocusToSearch: true,
        activeSubTabIndex: e.index
      }));
    }
  };

  return { handleTabChange, onSubTabChange, onChangeTab, activeSubtab };
};
