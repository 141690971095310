import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { Chip } from 'primereact/chip';

import { inputs } from '../../../helpers/inputs';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';

const FirstVisitPatientChip = () => {
  const { control, setValue } = useFormContext();
  const patients = useWatch({ control, name: inputs.patient.name });
  const lastName = useWatch({ control, name: inputs.lastName.name });
  const firstName = useWatch({ control, name: inputs.firstName.name });
  const midName = useWatch({ control, name: inputs.midName.name });
  const birthDay = useWatch({ control, name: inputs.birthDay.name });
  const gender = useWatch({ control, name: inputs.gender.name });

  const patientName = patientFullNameWithAge({
    FirstName: firstName,
    LastName: lastName,
    MidName: midName,
    BirthDay: birthDay,
    Sex: gender
  });

  const onChipRemove = () => {
    lastName && setValue(inputs.lastName.name, '');
    firstName && setValue(inputs.firstName.name, '');
    midName && setValue(inputs.midName.name, '');
    birthDay && setValue(inputs.birthDay.name, '');
    gender && setValue(inputs.gender.name, '');
    patients.length && setValue(inputs.patient.name, []);
  };

  if (!lastName && !firstName) {
    return (
      <div id={elementIDs.noPatientSelected} className="catalogs_no_patient_selected">
        {t('No_patient_selected')}
      </div>
    );
  }

  return (
    <div id={`firstVisit_${elementIDs.patientChip}`}>
      <Chip className="mr-2 w-max" label={patientName} removable icon="pi pi-user" onRemove={onChipRemove} />
    </div>
  );
};

export default FirstVisitPatientChip;
