import classes from '../components/ClaimView.module.scss';
import colClasses from '../../../styles/cm_col.module.scss';
import { rowTemplate } from './rowTemplate';
import { legoFilters } from '../../_lego/helpers/legoFilters';

const sortable = true;
const useFilter = true;

export const startCols = (isMobile, clientWidth, isFetching) => {
  const _classes = !isMobile && clientWidth > 1600 ? colClasses.cm_col_14em : colClasses.cm_col_11em;
  return [
    {
      id: legoFilters.invoices.ServiceDate.filter,
      field: legoFilters.invoices.ServiceDate.filter,
      header: legoFilters.invoices.ServiceDate.label,
      sortable,
      title: 'Date of Service',
      body: (a) => rowTemplate(a, 'dos', isFetching),
      filter: useFilter,
      filterElement: {},
      className: _classes
    }
  ];
};

export const endCols = (isMobile, isTablet, isLandscape, clientWidth, setHeader, setClaimHistory, setPopupVisible, isFetching, history, dispatch) => {
  let columns = [];

  const inputClasses = '';
  // const inputClasses = (isTablet && !isLandscape && clientWidth <= 1024) ? classes.filterByInputPortrait
  //   : (isTablet && isLandscape && clientWidth >= 1024 && clientWidth < 1366)
  //     ? classes.filterByInputLandscape : classes.filterByInput;

  const _dropsownClasses =
    (isTablet && !isLandscape && clientWidth <= 1024) || (isTablet && isLandscape && clientWidth >= 1024 && clientWidth < 1366)
      ? classes.filterByDropdownPortrait
      : (isTablet && isLandscape && clientWidth >= 1366) || (!isTablet && clientWidth < 1600)
        ? classes.filterByDropdownLandscape
        : classes.filterByDropdown;

  const patientsColumnClasses = !isMobile && clientWidth > 1600 ? colClasses.cm_col_19em : colClasses.cm_col_16em;

  columns = [
    {
      id: legoFilters.invoices.FullName.filter,
      field: legoFilters.invoices.FullName.filter,
      header: legoFilters.invoices.FullName.label,
      sortable,
      body: (a) => rowTemplate(a, 'fullname', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: patientsColumnClasses,
      inputStyle: classes.filterByPatient
    },
    {
      id: legoFilters.invoices.InvoiceType.filter,
      field: legoFilters.invoices.InvoiceType.filter,
      header: legoFilters.invoices.InvoiceType.label,
      sortable,
      body: (a) => rowTemplate(a, 'type', isFetching, history, dispatch, setHeader, setClaimHistory, setPopupVisible),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: _dropsownClasses
    },
    {
      id: legoFilters.invoices.ICD9Code.filter,
      field: legoFilters.invoices.ICD9Code.filter,
      header: legoFilters.invoices.ICD9Code.label,
      sortable,
      body: (a) => rowTemplate(a, 'icd9', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    },
    {
      id: legoFilters.invoices.FeeCode.filter,
      field: legoFilters.invoices.FeeCode.filter,
      header: legoFilters.invoices.FeeCode.label,
      sortable,
      body: (a) => rowTemplate(a, 'feecode', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    },
    {
      id: legoFilters.invoices.FeeAmount.filter,
      field: legoFilters.invoices.FeeAmount.filter,
      header: legoFilters.invoices.FeeAmount.label,
      sortable,
      body: (a) => rowTemplate(a, 'fee', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    },
    {
      id: legoFilters.invoices.FeePaid.filter,
      field: legoFilters.invoices.FeePaid.filter,
      header: legoFilters.invoices.FeePaid.label,
      sortable,
      body: (a) => rowTemplate(a, 'paid', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    },
    {
      id: legoFilters.invoices.FeeDue.filter,
      field: legoFilters.invoices.FeeDue.filter,
      header: legoFilters.invoices.FeeDue.label,
      sortable,
      body: (a) => rowTemplate(a, 'due', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    },
    {
      id: legoFilters.invoices.StatusText.filter,
      field: legoFilters.invoices.StatusText.filter,
      header: legoFilters.invoices.StatusText.label,
      sortable,
      body: (a) => rowTemplate(a, 'status', isFetching, history, dispatch, setHeader, setClaimHistory, setPopupVisible),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: _dropsownClasses
    },
    {
      id: legoFilters.invoices.RecordNo.filter,
      field: legoFilters.invoices.RecordNo.filter,
      header: legoFilters.invoices.RecordNo.label,
      sortable,
      body: (a) => rowTemplate(a, 'recordnumber', isFetching, history, dispatch),
      filter: useFilter,
      filterElement: {},
      className: colClasses.cm_col_1em,
      inputStyle: inputClasses
    }
  ];

  return columns;
};
