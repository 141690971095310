import reportActions from '../actions/reports.action.types';

const initialState = {};

export default function reportsReducer(state = initialState, action) {
  const { results } = action;

  switch (action.type) {
    default:
      return state;
  }
}
