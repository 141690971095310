import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import GroupPickerTable from '../GroupPickerTable/GroupPickerTable';
import RotateYoutDeviseMessage from '../../Layouts/RotateYoutDeviseMessage';
import GroupPickerContentWrap from '../../../../modules/Layouts/GroupPickerContentWrap';
import GroupReferralPickerActionButtons from '../../ActionButtons/GroupReferralPickerActionButtons';

import { useGroupPickerColumns } from '../../hooks/useGroupPickerColumns';
import { closeToastMessage } from '../../../../../../../core/actions/core.action.creators';
import { useGroupPickerInputs } from '../../hooks/useGroupPickerInputs';

const GroupReferral = () => {
  const dispatch = useDispatch();
  const { localState } = useFormContext();
  const { referralColumns } = useGroupPickerColumns();
  const { autofillLatestReferralCodes, setInitialRefToBy } = useGroupPickerInputs();
  const { toastMessage, window } = useSelector((state) => state.core);

  // Close toast messages
  useEffect(() => {
    toastMessage.message && dispatch(closeToastMessage(true));
    if (localState.latestReferral) autofillLatestReferralCodes();
    setInitialRefToBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.listOfPatients]);

  if (window.showNewTeleplanButtons) return <RotateYoutDeviseMessage />;

  return (
    <GroupPickerContentWrap>
      <GroupPickerTable columns={referralColumns} />
      <GroupReferralPickerActionButtons />
    </GroupPickerContentWrap>
  );
};

export default GroupReferral;
