import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import CreateNote from '../../../components/Widgets/Notes/CreateNote/CreateNote';
import { createClaimNote, setClaimInfo, updateClaimList } from '../actions/claims.action.creators';
import { t } from '../../../service/localization/i18n';
import { isEmpty } from 'lodash';

const AddClaimNoteDialog = ({ visible, onHide, rowData }) => {
  const dispatch = useDispatch();
  const claimList = useSelector((state) => state.claims.claim_list);
  const claimDetails = useSelector((state) => state.claims.claim_details);

  const onSubmit = async (note) => {
    const result = await createClaimNote(note);
    if (result) {
      const updatedClaimList = claimList.map((claim) => {
        if (claim.InvoiceGuid === rowData.InvoiceGuid) {
          return { ...claim, TotalNotes: claim.TotalNotes + 1 };
        }
        return claim;
      });

      !isEmpty(claimDetails) && dispatch(setClaimInfo({ ...claimDetails, TotalNotes: claimDetails.TotalNotes + 1 }));
      dispatch(updateClaimList(updatedClaimList));
    }
  };

  return (
    <Dialog
      header={t('Add_New_Note')}
      visible={visible}
      blockScroll
      breakpoints={{ '1600px': '60vw', '1366px': '70vw', '960px': '95vw', '768px': '95vw' }}
      style={{ width: '50vw' }}
      onHide={onHide}
    >
      <CreateNote entityGuid={rowData.InvoiceGuid} onSubmit={onSubmit} onCancel={onHide} />
    </Dialog>
  );
};

export default AddClaimNoteDialog;
