import React from 'react';

import { Tooltip } from 'primereact/tooltip';

import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { isTimeValid, parseMilitaryTime } from '../../../../../../utils/formatTime';
import { calculationOfWholeNumberOfUnits, calculateRefactoryPeriod } from '../../../helpers/calculateUnits';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const UnitsCalculationBodyTemplate = ({ rowData }) => {
  const { isRequiredInput } = useUpdateRecords();
  const startTime = parseMilitaryTime(rowData?.service_start);
  const endTime = parseMilitaryTime(rowData?.service_finish);
  const isStartValid = isTimeValid(startTime);
  const isEndValid = isTimeValid(endTime);
  const isUnitsRequired = isRequiredInput(rowData.id, 'units');

  if (isUnitsRequired) {
    return <span className="text-sm p-error">0 units</span>;
  }

  if (isStartValid && isEndValid) {
    // VER-376 - Show calculation of whole number of units + leftover time
    const unitsCalculationText = calculationOfWholeNumberOfUnits({ startTime, endTime, rowData });
    const refactoryPeriod = calculateRefactoryPeriod({ rowData });

    if (!unitsCalculationText) return null;

    return (
      <>
        <Tooltip target={`.unitsCalculationTooltip_${rowData.fee_code}`} position="top">
          {refactoryPeriod && `${t('Refractory_period')} ${refactoryPeriod} min`}
        </Tooltip>
        <span className={cx('text-sm', `unitsCalculationTooltip_${rowData.fee_code}`)}>{`${unitsCalculationText}`}</span>
      </>
    );
  }

  return <span>{`${t('N_A')}`}</span>;
};

export default UnitsCalculationBodyTemplate;
