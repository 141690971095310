import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import UsernameStep from './Steps/UsernameStep';
import PasswordStep from './Steps/PasswordStep';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

import { useSingIn } from './hooks/useSignIn';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SignIn = () => {
  const history = useHistory();
  const methods = useSingIn();

  useEffect(() => {
    history.replace('/');
  }, []);

  return (
    <AuthFormWrap label="Welcome to Claim Manager">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(methods.onSubmit)} name="SignIn">
          <div className="flex flex-column gap-3">
            {methods.signInState.step === 1 && <UsernameStep />}
            {methods.signInState.step === 2 && <PasswordStep />}
          </div>
        </form>
      </FormProvider>
    </AuthFormWrap>
  );
};

export default SignIn;
