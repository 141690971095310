import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { CodePicker } from '../../../../../../../components/Inputs';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { noiInputRequirements } from '../../../helpers/validationSchema';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { noiCodeMaxEntry } from '../../../../../../config/defaultValuesConfig';
import { useToggleCatalogs } from '../../../hooks/useToggleCatalogs';

const NOICode = () => {
  const name = inputs.noi.name;
  const description = inputs.noi.codeDescription;
  const { errors, control, watch, isMobile } = useFormContext();
  const { onFocus, onChange } = useCodePickerActions(inputs.noi);
  const { toggleCatalogs } = useToggleCatalogs();
  const noiCodes = watch(inputs.noi.codeType);
  const speciality = watch(inputs.speciality.name);
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const required = noiInputRequirements(invoiceType, saveAsDraft);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper
          className="codePickerWrap"
          name={name}
          label={inputs.noi.label}
          required={required}
          errorMessage={errors[name]?.message}
          description={watch(description)}
        >
          <CodePicker
            name={name}
            ref={field.ref}
            id={elementIDs.noiAutocomplete}
            inputId={elementIDs.noiAutocompleteInput}
            isInvalid={!!errors[name]?.message}
            value={noiCodes}
            maxEntry={noiCodeMaxEntry}
            showCatalogsButton={isMobile}
            searchParams={{ speciality, codeType: inputs.noi.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={() => toggleCatalogs('mobile', name)}
            onFocus={onFocus}
            onChange={onChange}
          />
        </InputWrapper>
      )}
    />
  );
};

export default NOICode;
