import React, { useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import MenuLayout from './MenuLayout';
import MSPEligibility from '../../../../components/Widgets/MSPEligibility';
import { t } from '../../../../service/localization/i18n';

const CheckMSPCoverage = () => {
  const [showDialog, setShowDialog] = useState(false);

  const items = useMemo(() => {
    return [
      {
        label: <div className="font-bold">{`Check MSP coverage`}</div>,
        items: [
          {
            label: 'Check MSP',
            icon: 'pi pi-search',
            command: () => setShowDialog(true)
          }
        ]
      }
    ];
  }, []);

  const onHide = () => {
    setShowDialog(false);
  };

  return (
    <>
      <MenuLayout model={items} />
      <Dialog header={t('MSP_Eligibility')} visible={showDialog} style={{ maxWidth: '900px' }} onHide={onHide}>
        <MSPEligibility onHide={onHide} />
      </Dialog>
    </>
  );
};

export default CheckMSPCoverage;
