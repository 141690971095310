import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import ActionButtons from '../../../patients/components/Patients/rowTemplates/ActionButtons';
import { rowActionsMenuItems } from '../../helpers/rowActionsMenuItems';
import { formatDate } from '../../../utils/formatDate';
import { deleteRoleById, updateRole } from '../../actions/roles.action.creators';
import TableCard from '../../../../components/Wraps/PageWrap/TableCard';
import DeleteDialog from '../../../../components/Dialogs/DeleteDialog/DeleteDialog';
import { getRoles } from '../../actions/roles.action.creators';
import { setRoles, clearRoles } from '../../actions/actions';
import PageWrap from '../../../../components/Wraps/PageWrap/PageWrap';

const Roles = () => {
  const [selectedRows, setSelectedRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.roles);

  const fetchRoles = useCallback(async () => {
    setLoading(true);
    const result = await getRoles();
    if (result) {
      dispatch(setRoles(result));
      setLoading(false);
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchRoles();

    return () => {
      dispatch(clearRoles());
    };
  }, [fetchRoles, dispatch]);

  const handleDelete = async (id) => {
    await Promise.all(selectedRows.map((role) => deleteRoleById(role.RoleId)));

    // await Promise.all(
    //   selectedRows.map((role) =>
    //     updateRole(
    //       {
    //         ...role,
    //         Status: 0
    //       },
    //       role.RoleId
    //     )
    //   )
    // );
    fetchRoles();
    setShowDialog(false);
  };

  const rowActionButtonsMenuModel = (rowData) => {
    const actionProps = {
      rowData,
      history,
      setSelectedRows,
      onDelete: () => setShowDialog(true),
      isDefault: rowData.RoleId < 100
    };

    return rowActionsMenuItems(actionProps);
  };

  const rowActionButtons = (rowData) => {
    return <ActionButtons rowData={rowData} setSelectedRows={setSelectedRows} rowActionButtonsMenuModel={rowActionButtonsMenuModel} />;
  };

  const bodyDate = (rowData) => {
    return formatDate(rowData.AddedDate);
  };

  const header = (
    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
      <span className="text-xl text-900 font-bold">Roles</span>
      <Button icon="pi pi-plus" label="Create role" onClick={() => history.push('/create-role')} />
    </div>
  );

  const statusTemplate = (rowData) => {
    let statusText = '';
    let statusColor = '';

    switch (rowData.Status) {
      case 1:
        statusText = 'Active';
        statusColor = 'green';
        break;
      case 0:
        statusText = 'Inactive';
        statusColor = 'red';
        break;
      case -1:
        statusText = 'Deleted';
        statusColor = 'gray';
        break;
      default:
        break;
    }

    return (
      <span className={`p-chip p-m-0`} style={{ backgroundColor: statusColor, color: 'white' }}>
        {statusText}
      </span>
    );
  };

  const defaultBodyTemplate = (rowData) => {
    return <span>{rowData.RoleId < 100 ? 'Yes' : 'No'}</span>;
  };

  const authorBodyTemplate = (rowData) => {
    return <span>{rowData.Author ? rowData.Author : 'System'}</span>;
  };

  return (
    <PageWrap>
      <TableCard>
        <DataTable value={roles} scrollable scrollHeight="flex" header={header} loading={loading} selectionMode="single">
          <Column body={statusTemplate} field="Status" header="Status" style={{ minWidth: '150px', maxWidth: '150px' }} />
          <Column field="RoleName" header="Role Name" style={{ minWidth: '150px', maxWidth: '150px' }} />
          <Column body={bodyDate} field="AddedDate" header="Date Created" style={{ minWidth: '150px', maxWidth: '150px' }} />
          <Column header="Author" body={authorBodyTemplate} style={{ minWidth: '150px', maxWidth: '150px' }} />
          <Column field="Protected" header="Default" body={defaultBodyTemplate} style={{ minWidth: '150px', maxWidth: '150px' }}></Column>
          <Column body={rowActionButtons} alignFrozen="right" frozen />
        </DataTable>
      </TableCard>
      <DeleteDialog
        visible={showDialog}
        message="Do you really want to delete the selected role?"
        onHide={() => setShowDialog(false)}
        accept={handleDelete}
      />
    </PageWrap>
  );
};

export default Roles;
