import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../../components/Inputs';

import cx from 'classnames';

// interface = {
//   input: {
//     name: string,
//     label: string
//     placeholder?: string
//   },
//   disabled?: boolean
//   required?: boolean
//   maxLength?: number
// };

const TextInput = ({ input, disabled = false, required = false, maxLength = 999 }) => {
  const name = input.name;
  const { errors, control } = useFormContext();
  const errorMessage = required ? errors[name]?.message : '';

  const lengthCounter = (field) => {
    return (
      <div className="flex justify-content-end w-full text-500">
        <div id={`${field.name}_symbolCounter`}>{`${field.value.length}/${maxLength}`}</div>
      </div>
    );
  };

  const textInputInput = (field) => {
    return (
      <InputWrapper name={name} label={input.label} required={required} errorMessage={errorMessage} footerContent={lengthCounter(field)}>
        <InputText
          ref={field.ref}
          className={cx({ 'p-invalid': errors[name]?.message && required })}
          id={field.name}
          value={field.value}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={input.placeholder || ''}
          onChange={(e) => field.onChange(e.target.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => textInputInput(field)} />;
};

export default TextInput;
