import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useValidation } from '../../../hooks/useValidations';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const MemberID = () => {
  const name = inputs.memberID.name;
  const { memberIDValidation } = useValidation();
  const { control, errors } = useFormContext();
  const errorMessage = errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => memberIDValidation(value) }}
      render={({ field }) => (
        <InputWrapper name={field.name} label={inputs.memberID.label} required errorMessage={errorMessage}>
          <InputText
            ref={field.ref}
            id={field.name}
            className={cx({ 'p-invalid': errorMessage }, 'flex')}
            style={{ width: 'inherit' }}
            type="text"
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default MemberID;
