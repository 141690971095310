import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getReferralCodesByLetter } from '../../../actions/claims.action.creators';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { isOverMaxEntries } from '../helpers/isOverMaxEntries';
import { useCodePickerActions } from './useCodePickerActions';
import { inputs } from '../helpers/inputs';
import { chain } from 'lodash';
import { t } from '../../../../../service/localization/i18n';

export const useCatalogs = () => {
  const dispatch = useDispatch();
  const { watch, localState, isNew } = useFormContext();
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const { onChange } = useCodePickerActions(localState?.focusFieldParams);

  const getSelectedCodes = (activeTab) => {
    if (!activeTab?.codeType) return [];
    return watch(activeTab?.codeType)?.toReversed() || [];
  };

  const onLetterClick = async ({ letter, setCatalogsState }) => {
    if (letter) {
      const results = await getReferralCodesByLetter({ letter });
      if (results) {
        setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList: results },
          emptyMessage: `${t('No_records_found')}`
        }));
      }
    } else {
      setCatalogsState((prevState) => ({
        ...prevState,
        tableData: { codeList: catalogMostRecentCodes[inputs.referral.codeType] },
        emptyMessage: `${t('No_records_found')}`
      }));
    }
  };

  const catalogsTableValues = (catalogsState) => {
    if (catalogsState.trail[0]?.label === 'All') {
      const grouped = chain(catalogsState.tableData?.codeList)
        .groupBy('category')
        .map((i) => [{ value: i[0].category_label, isCategory: true }, ...i])
        .value()
        .flat(1);

      return grouped;
    }

    return catalogsState.tableData?.codeList;
  };

  const onSelectCodeFromCatalogsTable = (code, { catalogsState, setCatalogsState, slideMenuRef }) => {
    const name = catalogsState.activeTab.name;
    const codeType = catalogsState.activeTab.codeType;
    const selectedCodes = watch(codeType) || [];
    const codesList = [...selectedCodes, code];

    // Show a warning message if the user tries to add more codes than the maximum number of entries
    if (isOverMaxEntries(codesList, name, isNew)) {
      return dispatch(
        setToastMessage({
          type: 'warn',
          message: `${t('Maximum_number_of_entries_is_X')} ${catalogsState.activeTab.maxEntries}`
        })
      );
    }

    onChange({ value: codesList });
  };

  return {
    onLetterClick,
    getSelectedCodes,
    catalogsTableValues,
    onSelectCodeFromCatalogsTable
  };
};
