import React, { useCallback, useEffect } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { useRequests } from '../../hooks/useRequests';
import { catalogsSearchWidth } from '../../config/styles';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import { debounce } from 'lodash';
import cx from 'classnames';

const CatalogsSearch = () => {
  const { onSearchCodes } = useRequests();
  const { catalogsState, setCatalogsState, slideMenuRef, searchRef } = useCatalogsContext();
  const showRightIcon = catalogsState.searchLoader || catalogsState.searchValue;
  const searchParams = { activeTab: catalogsState.activeTab, catalogsState, setCatalogsState, slideMenuRef: slideMenuRef };
  const searchActionButton = catalogsState?.activeTab?.searchActionButton;

  // CMO-2351 - Default focus in Catalogs in new Teleplan claim
  useEffect(() => {
    if (catalogsState.setFocusToSearch) {
      searchRef?.current?.focus();
      setCatalogsState((prevState) => ({ ...prevState, setFocusToSearch: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (query) => {
    setCatalogsState((prevState) => ({ ...prevState, searchValue: query }));
    debouncedRequest(query);
  };

  const debouncedRequest = useCallback(
    debounce((query) => onSearchCodes({ ...searchParams, query }), 1000),
    []
  );

  const onClearSearch = () => {
    const updatedSearchParams = { ...searchParams, catalogsState: { ...searchParams.catalogsState, searchValue: '' } };
    onSearchCodes({ ...updatedSearchParams, query: '' });
  };

  const onEnterClick = (e) => {
    e.key === 'Enter' && onSearchCodes({ ...searchParams, query: catalogsState.searchValue });
  };

  const clearIconLayout = () => {
    if (catalogsState.searchLoader) return <i className="pi pi-spin pi-spinner" />;
    if (catalogsState.searchValue && !catalogsState.searchLoader) {
      return <i id={elementIDs.clearSearchButton} className="pi pi-times pointer" onClick={onClearSearch} />;
    }
    return null;
  };

  const searchInput = (
    <>
      <i className="pi pi-search" />
      <InputText
        ref={searchRef}
        id={elementIDs.catalogsSearch}
        value={catalogsState.searchValue}
        autoFocus // VER-736 - Catalogs->place cursor into the search field
        placeholder={t('Search_for_codes')}
        className="searchInput"
        onChange={(e) => onSearch(e.target.value)}
        onKeyPress={onEnterClick}
      />
      {clearIconLayout()}
    </>
  );

  return (
    <>
      {/* Desktop view */}
      <div className="p-inputgroup hidden lg:flex" style={{ maxWidth: `${catalogsSearchWidth}px`, minWidth: '170px' }}>
        <span
          className={cx('p-input-icon-left w-full', showRightIcon ? 'p-input-icon-right' : '')}
          style={{ maxWidth: `${catalogsSearchWidth}px`, minWidth: '170px' }}
        >
          {searchInput}
        </span>

        {searchActionButton && (
          <Button
            id={elementIDs.catalogsSearchButton}
            type="button"
            style={{ minWidth: '30px' }}
            tooltip={searchActionButton?.tooltip}
            tooltipOptions={{ position: 'top' }}
            icon="pi pi-plus"
            onClick={searchActionButton?.onClick}
          />
        )}
      </div>

      {/* Mobile view */}
      <div className="p-inputgroup w-100 min-w-100 max-w-100 flex lg:hidden">
        <span className={cx('p-input-icon-left', showRightIcon ? 'p-input-icon-right' : '')}>{searchInput}</span>
      </div>
    </>
  );
};

export default CatalogsSearch;
