import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AutoComplete } from 'primereact/autocomplete';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useEmployersList } from '../../../hooks/useEmployersList';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const Employer = () => {
  const name = inputs.employer.name;
  const { errors, control, clearErrors, setValue, setDirty, watch } = useFormContext();
  const [filteredEmployers, setFilteredEmployers] = useState(null);
  const { employers } = useEmployersList();

  // Reset employers info when employers name is removed
  useEffect(() => {
    if (!watch(inputs.employer.name)) {
      Object.entries(inputsForUpdate()).forEach(([inputName, inputValue]) => {
        setValue(inputName, inputValue, setDirty); // Update input value
      });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(inputs.employer.name)]);

  const searchEmployer = (e) => {
    let _filteredEmployers;
    if (!e.query.trim().length) {
      _filteredEmployers = [...employers];
    } else {
      _filteredEmployers = employers.filter((employer) => {
        return employer[name].toLowerCase().startsWith(e.query.toLowerCase());
      });
    }

    setFilteredEmployers(_filteredEmployers);
  };

  const onChange = (e, field) => {
    const value = e.value[name] ? e.value[name] : e.value;
    field.onChange(value);
  };

  const onSelect = (value) => {
    Object.entries(inputsForUpdate(value)).forEach(([inputName, inputValue]) => {
      if (inputValue && errors[inputName]) clearErrors(inputName); // Clear error message
      setValue(inputName, inputValue, setDirty); // Update input value
    });
  };

  const inputsForUpdate = (value) => {
    return {
      [inputs.employer.name]: value ? value[inputs.employer.name] : '',
      [inputs.phone.name]: value ? value[inputs.phone.name] : '',
      [inputs.address.name]: value ? value[inputs.address.name] : '',
      [inputs.city.name]: value ? value[inputs.city.name] : ''
    };
  };

  const textInputInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.employer.label} required errorMessage={errors[name]?.message}>
        <AutoComplete
          inputRef={field.ref}
          className={cx({ 'p-invalid': errors[name]?.message })}
          inputClassName="w-full"
          id={field.name}
          field={name}
          autoHighlight
          dropdown
          value={field.value}
          suggestions={filteredEmployers}
          completeMethod={searchEmployer}
          onChange={(e) => onChange(e, field)}
          onSelect={(e) => onSelect(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => textInputInput(field)} />;
};

export default Employer;
