import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GroupPickerTable from '../GroupPickerTable/GroupPickerTable';
import RotateYoutDeviseMessage from '../../Layouts/RotateYoutDeviseMessage';
import GroupPickerContentWrap from '../../../../modules/Layouts/GroupPickerContentWrap';
import GroupCommentsActionButtons from '../../ActionButtons/GroupCommentsActionButtons';

import { useGroupPickerColumns } from '../../hooks/useGroupPickerColumns';
import { closeToastMessage } from '../../../../../../../core/actions/core.action.creators';
import { useValidateGoupPicker } from '../../hooks/useValidateGroupPicker';

const GroupComments = () => {
  const dispatch = useDispatch();
  const { setCommentInputRequired } = useValidateGoupPicker();
  const { commentColumns } = useGroupPickerColumns();
  const { toastMessage, window } = useSelector((state) => state.core);

  // Close toast messages
  useEffect(() => {
    toastMessage.message && dispatch(closeToastMessage(true));

    // Set required comment inputs
    setCommentInputRequired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.showNewTeleplanButtons) return <RotateYoutDeviseMessage />;

  return (
    <GroupPickerContentWrap>
      <GroupPickerTable columns={commentColumns} />
      <GroupCommentsActionButtons />
    </GroupPickerContentWrap>
  );
};

export default GroupComments;
