import React from 'react';
import { useFormContext } from 'react-hook-form';
import PatientBillingHistoryDialog from '../../../../../../components/Widgets/PatientBillingHistory/PatientBillingHistoryDialog';
import { inputs } from '../../helpers/inputs';
import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';

const BillingHistoryDialog = () => {
  const { localState, setLocalState, getValues } = useFormContext();
  const patients = getValues(inputs.patient.name);

  const onHide = () => {
    setLocalState((prevState) => ({ ...prevState, billingHistoryDialog: localStateInitValues.billingHistoryDialog }));
  };

  return (
    <PatientBillingHistoryDialog
      visible={localState.billingHistoryDialog.showDialog}
      onHide={onHide}
      patientsList={patients}
      initPatient={localState.billingHistoryDialog.options?.initPatient}
    />
  );
};

export default BillingHistoryDialog;
