import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Divider } from 'primereact/divider';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import TableCard from '../../../../components/Wraps/PageWrap/TableCard';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import PageTitle from '../../../../components/Layouts/Titles/PageTitle';

import { calculateLast } from '../../../utils/calculate';
import { initStateForPagination, rowsPerPageDefaultOptions } from '../../../config/defaultValuesConfig';
import { getFiles, updateStatus } from '../../actions/upload.action.creators';
import { t } from '../../../../service/localization/i18n';

import './Inbox.scss';

const Inbox = () => {
  const clinic = useSelector((state) => state.clinic.details);
  const user = useSelector((state) => state.user.details);
  const readOnly = user.PractitionerNumber?.length > 1;
  const DCNGuid = clinic.DCNGuid;
  const [state, setState] = useState({
    ...initStateForPagination,
    fileList: [],
    loading: false
  });
  const last = calculateLast({ page: state.page, pageSize: state.pageSize, totalRecords: state.totalRecords });

  const fetchData = async (params) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const results = await getFiles(params);
    if (results) {
      setState((prevState) => ({
        ...prevState,
        ...results,
        loading: false
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => {
    fetchData({ DCNGuid, pageSize: state.pageSize, pageNumber: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPage = (e) => {
    const params = { DCNGuid, pageSize: e.rows, pageNumber: e.page + 1 };
    fetchData(params);
  };

  const setStatus = async (e, rowGuid, status) => {
    let newStatus = 1;
    switch (status) {
      case 2:
        newStatus = e.checked ? 2 : 1;
        break;
      case 3:
        newStatus = e.checked ? 3 : 2;
        break;
      default:
        break;
    }

    await updateStatus({ DCNGuid, RowGuid: rowGuid, Status: newStatus });
    fetchData({ DCNGuid, pageNumber: state.page, pageSize: state.pageSize });
  };

  const linkBodyTemplate = (item) => {
    return (
      <a href={item.FileUrl} target="_blank" rel="noreferrer">
        Download
      </a>
    );
  };

  const directionTemplate = (item) =>{
    if (item.Status === 10) {
      return <span>Shared</span>;
    }
    else {
      return <span>Uploaded</span>;
    }
  }
  const downloadedBodyTemplate = (item) => {
    if (item.Status === 10) {
      return (<div></div>);
    }
    const checked = item.Downloaded === 1;
    const fieldId = `downloadedChkBox_${item.RowGuid}`;
    return (
      <Checkbox
        className="mr-3"
        type="checkbox"
        inputId={fieldId}
        checked={checked}
        onChange={(e) => setStatus(e, item.RowGuid, 2)}
        disabled={readOnly}
      />
    );
  };

  const processedBodyTemplate = (item) => {
    if (item.Status === 10) {      
      return (<div></div>);
    }
    const checked = item.Processed === 1;
    const fieldId = `processedChkBox_${item.RowGuid}`;
    return (
      <Checkbox
        className="mr-3"
        type="checkbox"
        inputId={fieldId}
        checked={checked}
        onChange={(e) => setStatus(e, item.RowGuid, 3)}
        disabled={readOnly}
      />
    );
  };

  return (
    <TableCard>
      {state.loading && <CircularProgress />}
      <PageTitle>{t('Daysheet_uploads')}</PageTitle>
      <Divider className="mb-1" />
      <div className="inbox_data_table_scroll_wrap">
        <DataTable
          first={state.first}
          value={state.fileList}
          rows={state.pageSize}
          totalRecords={state.totalRecords}
          lazy
          paginator
          scrollable
          stripedRows
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate={`${state.first} to ${last} of ${state.totalRecords}`}
          rowsPerPageOptions={rowsPerPageDefaultOptions}
          scrollHeight="flex"
          responsiveLayout="scroll"
          onPage={onPage}
        >
          <Column field="UserName" header="Uploaded By" />
          <Column field="FileName" header="File Name" />
          <Column field="Status" header="Type" body={directionTemplate}/>
          <Column field="FileUrl" header="Link" body={linkBodyTemplate} />
          <Column field="Downloaded" header="Downloaded" body={downloadedBodyTemplate} />
          <Column field="Processed" header="Processed" body={processedBodyTemplate} />
        </DataTable>
      </div>
    </TableCard>
  );
};

export default Inbox;
