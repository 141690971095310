import React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import NameInput from './NameInput';

// interface IGender {
//   input: {
//     name: string,
//     label: string,
//   },
//   value: string,
//   placeholder: string,
//   onChange: () => void,
//   onBlur?: () => void,
//   required?: boolean,
//   errorMessage?: string,
//   ref?: any
// };

const WrappedNameInput = (props) => {
  const name = props.input.name;
  const label = props.input.label;

  return (
    <InputWrapper name={name} label={label} required={props.required} errorMessage={props.errorMessage}>
      <NameInput name={name} {...props} />
    </InputWrapper>
  );
};

export default WrappedNameInput;
