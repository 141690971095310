import React from 'react';
import EClaimPolicyFormDialog from './EClaimPolicyFormDialog';
import DeleteEClaimPolicyConfirm from './DeleteEClaimPolicyConfirm';

const Dialogs = () => {
  return (
    <>
      <EClaimPolicyFormDialog />
      <DeleteEClaimPolicyConfirm />
    </>
  );
};

export default Dialogs;
