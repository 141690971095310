import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const PreviewActionButtons = () => {
  const history = useHistory();

  return (
    <div className="flex justify-content-center stickyActionButtons pt-4 w-full">
      <div className="flex w-full gap-3" style={{ maxWidth: '300px' }}>
        <Button id={elementIDs.cancel} className="w-full" type="button" label={t('Close')} onClick={() => history.goBack()} />
      </div>
    </div>
  );
};

export default PreviewActionButtons;
