import React from 'react';
import ExportButton from './ExportButton';
import ViewOnMoneyPageButton from './ViewOnMoneyPageButton';
import { useClaimsListContext } from '../../../hooks/useClaimsListContext';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { t } from '../../../../../../../service/localization/i18n';

const PaginatorRight = () => {
  const { state } = useClaimsListContext();

  return (
    <div className="flex gap-3 align-items-center">
      <div className="hidden md:flex md:flex-column gap-1">{`${t('A_R')}: ${currencyFormat(state.totalDue)}`}</div>
      <ExportButton className="hidden lg:flex" />
      <ViewOnMoneyPageButton />
    </div>
  );
};

export default PaginatorRight;
