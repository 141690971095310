import React from 'react';

import { Dropdown } from 'primereact/dropdown';

import { genderList } from '../../../modules/config/dropdownListsConfig';
import cx from 'classnames';

// interface IGender {
//   name: string,
//   value: string,
//   onChange: () => void,
//   ref?: any
// };

const Gender = React.forwardRef((props, ref) => {
  const handleChange = (e) => {
    props.onChange && props.onChange(e.target.value);
  };

  return (
    <Dropdown
      className={cx({ 'p-invalid': props.errorMessage }, props.className || '')}
      style={{ minWidth: '80px' }}
      id={props.name}
      name={props.name}
      inputRef={ref}
      optionLabel={props.optionLabel || 'label'} // availible options "label" or "value"
      options={genderList}
      disabled={props.disabled}
      value={props.value}
      onChange={handleChange}
    />
  );
});

export default Gender;
