import { inputs, requiredInputs } from './inputs';
import { NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';
import * as yup from 'yup';

const minDate = '01/01/1990';

export const validationSchema = yup.object().shape(
  {
    [inputs.practitioner.name]: yup.string().when(inputs.practitioner.name, {
      is: (value) => !value || value === NA_DoctorGuid,
      then: yup.string().min(NA_DoctorGuid.length + 1, t('Mandatory_field.1')),
      otherwise: yup.string().notRequired()
    }),

    [inputs.patient.name]: yup.array().min(1, t('Mandatory_field.1')).required(t('Mandatory_field.1')),

    [requiredInputs[inputs.injuryDate.name]]: yup
      .date()
      .min(minDate, `${t('Year_minimum_value_is')} ${minDate}`)
      .max(moment().startOf('day').toDate(), `${t('Maximum_date_value_is')} ${moment().format('MM/DD/YYYY')}`)
      .required(t('Mandatory_field.1'))
      .nullable(),
    [requiredInputs[inputs.employer.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.address.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.city.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.isPrimary.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.firstTreatment.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.priorProblems.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.disabled.name]]: yup.string().required(t('Mandatory_field.1')),

    // Not required
    [inputs.disabledDate.name]: yup
      .date()
      .min(minDate, `${t('Year_minimum_value_is')} ${minDate}`)
      .nullable(),

    [requiredInputs[inputs.clinicalInformation.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.capable.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.estimatedReturn.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.rehabilitation.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.consult.name]]: yup.string().required(t('Mandatory_field.1')),

    // Not required
    [inputs.recoveryDate.name]: yup.date().min(yup.ref(inputs.injuryDate.name), t('The_date_is_earlier_than_injury_date')).nullable(),

    [requiredInputs[inputs.serviceDate.name]]: yup
      .date()
      .min(yup.ref(inputs.injuryDate.name), t('The_date_is_earlier_than_injury_date'))
      .max(moment().startOf('day').toDate(), `${t('Maximum_date_value_is')} ${moment().format('MM/DD/YYYY')}`)
      .required(t('Mandatory_field.1'))
      .nullable(),

    // CMO-2431 - Why do I see this warning on Save and Review WSBC report
    // Not required
    // [inputs.recoveryDate.name]: yup
    //   .date()
    //   .test('min-validation', t('The_date_is_earlier_than_injury_date'), function (value) {
    //     const minimumDate = this.resolve(yup.ref(inputs.injuryDate.name));

    //     if (value && minimumDate && daysToMilliseconds(value) < daysToMilliseconds(minimumDate)) {
    //       const message = String.format(t("The_x_date_is_earlier_than_injury_date"), t('Maximal_medical_recovery')?.toLowerCase());
    //       const currentMessage = store.getState().core.toastMessage.message;
    //       currentMessage !== message && store.dispatch(setToastMessage({ type: "warn", message }));
    //       // Return false to indicate that the test NOT passed
    //       return false;
    //     }

    //     // Return true to indicate that the test passed
    //     return true;
    //   })
    //   .nullable(),

    // [requiredInputs[inputs.serviceDate.name]]: yup
    //   .date()
    //   .test('min-validation', t('The_date_is_earlier_than_injury_date'), function (value) {
    //     if (!value) return false; // Return false to indicate that the test NOT passed

    //     const minimumDate = this.resolve(yup.ref(inputs.injuryDate.name));

    //     if (value && minimumDate && daysToMilliseconds(value) < daysToMilliseconds(minimumDate)) {
    //       const message = String.format(t("The_x_date_is_earlier_than_injury_date"), inputs.serviceDate.label?.toLowerCase());
    //       const currentMessage = store.getState().core.toastMessage.message;
    //       currentMessage !== message && store.dispatch(setToastMessage({ type: "warn", message }));
    //       // Return false to indicate that the test NOT passed
    //       return false;
    //     }

    //     // Return true to indicate that the test passed
    //     return true;
    //   })
    //   .required(t("Mandatory_field.1"))
    //   .nullable(),

    [requiredInputs[inputs.feeCodes.name]]: yup.string().required(t('Mandatory_field.1')),
    [requiredInputs[inputs.icd9.name]]: yup.string().required(t('Mandatory_field.1')).nullable(),
    [requiredInputs[inputs.noi.name]]: yup.string().required(t('Mandatory_field.1')).nullable(),
    [requiredInputs[inputs.aoi.name]]: yup.string().required(t('Mandatory_field.1')).nullable()
  },
  [
    // Add Cyclic deps here because when require itself
    [inputs.practitioner.name, inputs.practitioner.name]
  ]
);
