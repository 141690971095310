import React from 'react';
import PolicyCard from '../../../../Misc/PolicyCard/PolicyCard';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { commonInputs } from '../../../../../modules/config/commonInputsConfig';

const PolicyItem = ({ policy }) => {
  const { state, setState } = usePatientPoliciesContext();

  const onEdit = (item) => {
    setState((prevState) => ({
      ...prevState,
      eClaimPolicy: {
        policyInfo: {
          ...item,
          [commonInputs.patient.name]: [state.patient]
        },
        showEClaimPolicyForm: true,
        isEdit: true
      }
    }));
  };

  const onDelete = (item) => {
    setState((prevState) => ({
      ...prevState,
      deleteEClaimPolicy: {
        ...prevState.deleteEClaimPolicy,
        showDialog: true,
        policy: item
      }
    }));
  };

  return <PolicyCard policy={policy} onEdit={onEdit} onDelete={onDelete} />;
};

export default PolicyItem;
