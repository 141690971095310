import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { WrappedTimeInput } from '../../../../../../../../components/Inputs';

import { icons } from '../../../../../../../config/stylesConfig';
import { inputs } from '../../../../helpers/inputs';
import { t } from '../../../../../../../../service/localization/i18n';
import { validateOffHours } from '../../../../helpers/validationSchema';
import cx from 'classnames';

const CommonTimeInput = ({ id, input, required, onChange, className = '', footerContent }) => {
  const name = input.name;
  const { control, errors, getValues } = useFormContext();
  const label = input.label;

  const onBlur = (time) => {
    if (time && name === inputs.startTime.name) {
      const feeCodes = getValues(inputs.feeCodes.name);
      const serviceDate = getValues(inputs.serviceDate.name);
      // CheckOffHours warn message
      validateOffHours(feeCodes, time, serviceDate);
    }
  };

  const labelOptionalContent = (
    <>
      <Tooltip target=".groupStartTimeTooltip" position="top" />
      <i
        className={cx('groupStartTimeTooltip cursor-pointer', icons.info)}
        data-pr-tooltip={t('This_time_will_apply_to_all_patients_in_the_group')}
      />
    </>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <WrappedTimeInput
          {...field}
          id={id}
          label={label}
          required={required}
          className={className}
          isInvalid={!!errors[field.name]?.message}
          errorMessage={errors[field.name]?.message}
          footerContent={footerContent}
          labelOptionalContent={labelOptionalContent}
          onChange={(value) => onChange(value, field)}
          onBlur={onBlur}
        />
      )}
    />
  );
};

export default CommonTimeInput;
