import React from 'react';
import { useController } from 'react-hook-form';
import WrappedDatePicker from './WrappedDatePicker';
import { t } from '../../../service/localization/i18n';

// interface = {
//   input: {
//     name: string,
//     label: string
//     placeholder?: string
//   },
//  yearRange?: string,
//  required?: boolean,
//  disabled?: boolean,
//  status?: number | string,
//  onChange?: (value: Date) => void,
//  handleBlur?: (value: Date) => void
// };

const DatePickerWithFormControl = ({ input, required = false, onChange, ...props }) => {
  const name = input.name;
  const {
    field,
    formState: { errors }
  } = useController({
    name,
    control: props.control,
    rules: {
      validate: (value) => validation(value)
    }
  });
  const value = field.value;
  const selectionMode = Array.isArray(value) ? 'multiple' : 'single';
  const errorMessage = errors[name]?.message;

  const validation = (value) => {
    if (required) {
      if (selectionMode === 'multiple') {
        return (!!value && value.length) || t('Mandatory_field.1');
      } else {
        return !!value || t('Mandatory_field.1');
      }
    }

    return true;
  };

  const handleChange = (value, field) => {
    if (onChange) return onChange(value);
    field.onChange(value);
  };

  const handleBlur = (value, field) => {
    if (props.onBlur) props.onBlur(value, field);
  };

  return (
    <WrappedDatePicker
      input={input}
      ref={field.ref}
      value={value}
      status={props.status}
      required={required}
      errorMessage={errorMessage}
      selectionMode={selectionMode}
      disabled={props.disabled}
      yearRange={props.yearRange}
      onChange={(value) => handleChange(value, field)}
      onBlur={(value) => handleBlur(value, field)}
    />
  );
};

export default DatePickerWithFormControl;
