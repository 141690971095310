import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { setMissingCodesToRecentList } from '../../../actions/claims.action.creators';
import { catalogTabs } from '../helpers/catalogTabs';
import { t } from '../../../../../service/localization/i18n';

export const useCodePickerActions = (input) => {
  const multiple = true;
  const dispatch = useDispatch();
  const { setValue, setLocalState, localState, errors, clearErrors, watch } = useFormContext();
  const [code, setCode] = useState(multiple ? [] : '');

  useEffect(() => {
    if (!multiple) return setCode(watch(input.name));
    if (multiple) {
      if (watch(input.name)) {
        return setCode([
          {
            value: watch(input.name),
            text: watch(input.codeDescription) || ''
          }
        ]);
      }

      return setCode([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(input.name)]);

  const onChange = (e) => {
    if (multiple) {
      if (e.value?.length > 1) return dispatch(setToastMessage({ type: 'warn', message: `${t('Maximum_number_of_entries_is_X')} 1` }));

      // Empty value
      if (!e.value?.length) return resetCode(input, setCode);

      // On select from dropdown list
      if (e.value?.length) {
        dispatch(setMissingCodesToRecentList({ codes: { [input.codeType]: [e.value[0]] } }));
        if (e.value[0].value && errors[input.name]) clearErrors(input.name); // Clear error message
        setValue(input.name, e.value[0].value);
        setValue(input.codeDescription, e.value[0].label);
        return;
      }

      // Type into input field
      return setCode(e.value);
    }

    if (!multiple) {
      // Empty value
      if (!e.value) return resetCode(input, setCode);

      // On select from dropdown list
      if (e.value.value) return onCodeSelect(e.value);

      // Type into input field
      return setCode(e.value);
    }
  };

  const onCodeSelect = (code) => {
    dispatch(setMissingCodesToRecentList({ codes: { [input.codeType]: [code] } }));
    if (code.value && errors[input.name]) clearErrors(input.name); // Clear error message
    setValue(input.name, code.value);
    setValue(input.codeDescription, code.label);
  };

  const resetCode = (input, setCode) => {
    setValue(input.name, '');
    setValue(input.codeDescription, '');
    setCode('');
  };

  const onFocus = (name) => {
    if (localState.showCatalogs.desktop) {
      const index = catalogTabs.findIndex((i) => i.name === name);
      setLocalState((prevState) => ({
        ...prevState,
        catalogIndex: index,
        focusFieldParams: catalogTabs[index],
        searchValue: ''
      }));
    }
  };

  return { code, onChange, onFocus, multiple, onCodeSelect };
};
