import React, { useState } from 'react';

import CodesDescriptionTemplate from '../../Layouts/CodesDescriptionTemplate';
import { InputTextarea } from 'primereact/inputtextarea';

import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';

const RowExpansionTemplate = ({ data }) => {
  const { handleCommentChange, onBlurComment, isRequiredInput } = useUpdateRecords();
  const [editing, setEditing] = useState(false);

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  const addNote = () => {
    const isRequired = isRequiredInput(data.id, 'note');

    if (isRequired) {
      return <span className="p-error">{t('Required')}</span>;
    }

    return data.note || t('Click_to_add_note');
  };

  return (
    <div id={elementIDs.expansionTemplate} className="rowExpansionTemplate w-full flex" key={`rph_${data.id}`}>
      {/* <CodesDescriptionTemplate record={data} showDivider={false} /> */}
      <div className="hidden lg:flex" style={{ minWidth: '40px', width: '40px' }}></div>

      <div className="flex col-12 p-0 align-items-center w-full" style={{ minHeight: '45px' }}>
        <div className="flex align-items-center w-full" onClick={handleClick} onBlur={handleBlur}>
          <div id={elementIDs.teleplanRecordsTableNoteLabel} className="codeDescriptionValue">
            {`${t('Note')}:`}
          </div>

          {editing && (
            <InputTextarea
              id={elementIDs.noteInput}
              autoFocus
              rows={1}
              cols={30}
              autoResize
              placeholder={t('Add_note')}
              value={data.note || ''}
              style={{ height: '3em', width: '100%' }}
              onChange={(e) => handleCommentChange(e.target.value, data)}
              onBlur={(e) => onBlurComment(e.target.value, data)}
            />
          )}

          {!editing && (
            <span id={elementIDs.teleplanRecordsTableNoteValue} style={{ color: 'var(--primary-color)' }}>
              {addNote()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default RowExpansionTemplate;
