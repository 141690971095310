import React from 'react';
import AddNewPolicy from '../ActionButtons/AddNewPolicy';

const Header = () => {
  return (
    <div className="flex align-items-center justify-content-end w-full pb-3 px-0 md:pl-5 md:pr-6 gap-0 md:gap-3">
      <AddNewPolicy />
    </div>
  );
};

export default Header;
