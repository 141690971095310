export const formatBMI = (value) => {
  const bmi = Number(value);
  // Parse the value as a float
  let floatValue = parseFloat(bmi);

  // Check if there's a decimal
  if (floatValue % 1 === 0) {
    // If no decimal, add ".0"
    return floatValue.toFixed(1);
  } else {
    // If there's a decimal, keep it as is
    return bmi;
  }
};
