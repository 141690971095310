import { columnValues } from './columnValues';

export const columnsForPDF = [
  {
    field: columnValues.firstName.field,
    header: columnValues.firstName.header
  },

  {
    field: columnValues.lastName.field,
    header: columnValues.lastName.header
  },

  {
    field: columnValues.number.field,
    header: columnValues.number.header
  },

  {
    field: columnValues.phone.field,
    header: columnValues.phone.header
  },

  {
    field: columnValues.email.field,
    header: columnValues.email.header
  }
];

export const recordsForExcel = (practitionersList) => {
  const formattedList = practitionersList?.map((i) => {
    return {
      [columnValues.firstName.header]: i[columnValues.firstName.field],
      [columnValues.lastName.header]: i[columnValues.lastName.field],
      [columnValues.number.header]: i[columnValues.number.field],
      [columnValues.phone.header]: i[columnValues.phone.field],
      [columnValues.email.header]: i[columnValues.email.field]
    };
  });

  return formattedList;
};
