import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';

import { getEligibility } from '../../../modules/patients/actions/patients.action.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';
import moment from 'moment';

// interface ICheckEligibilityOnly {
//   patientData: {
//     PHN: string,
//     PHNProvince: string,
//     BirthDay: string | Date,
//     PatientGuid?: string // add only in edit mode
//   },
//   isPHNValid: boolean
//   onClick: (responseData) => void
//   className?: string
// }

const CheckEligibilityOnly = (props) => {
  const dispatch = useDispatch();
  const { checkingPatientDuplicates, isFetchingPHNValidation, isFetchingPatientEligibility, checkEligibilityOnly, checkNameAndEligibility } =
    useSelector((state) => state.patients);

  const birthDay = props.patientData.BirthDay ? moment(props.patientData.BirthDay).format('MM-DD-YYYY') : null;

  const checkMSPDisabled = !props.patientData.PHN || props.patientData.PHN === 'N/A' || !birthDay || !props.isPHNValid;
  const disabled =
    checkNameAndEligibility ||
    isFetchingPatientEligibility ||
    checkMSPDisabled ||
    isFetchingPHNValidation ||
    checkingPatientDuplicates ||
    props.patientData.PHNProvince !== 'BC';

  const handleClick = () => {
    const patientData = {
      ...props.patientData,
      DOB: birthDay,
      eligibilityOnly: true
    };

    delete patientData.PHNProvince;
    !patientData.PatientGuid && delete patientData.PatientGuid;

    dispatch(getEligibility(patientData, (responseData) => props.onClick(responseData)));
  };

  return (
    <Button
      id={elementIDs.checkEligibilityOnly}
      className={props.className || 'p-button-outlined p-button-content'}
      label={t('Eligibility')}
      type="button"
      tooltip={t('Enter_PHN_and_DOB')}
      tooltipOptions={{ position: 'top', showDelay: 750 }}
      disabled={disabled}
      loading={checkEligibilityOnly}
      autoFocus={false}
      onClick={handleClick}
    />
  );
};

export default CheckEligibilityOnly;
