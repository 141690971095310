import React from 'react';
import Filters from './Filters';
import Charts from './Charts';
import Dialogs from './Dialogs';

import './index.scss';

const CashFlow = () => {
  return (
    <div className="flex flex-column md:flex-row justify-content-between align-items-start gap-3 w-full" style={{ maxWidth: '1500px' }}>
      <Filters />
      <Charts />
      <Dialogs />
    </div>
  );
};

export default CashFlow;
