import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import GroupPickerTable from '../GroupPickerTable/GroupPickerTable';
import RotateYoutDeviseMessage from '../../Layouts/RotateYoutDeviseMessage';
import GroupPickerContentWrap from '../../../../modules/Layouts/GroupPickerContentWrap';
import GroupServicesPickerActionButtons from '../../ActionButtons/GroupServicesPickerActionButtons';

import { useGroupPickerColumns } from '../../hooks/useGroupPickerColumns';
import { closeToastMessage, setToastPosition } from '../../../../../../../core/actions/core.action.creators';
import { useValidateGoupPicker } from '../../hooks/useValidateGroupPicker';
import { steps } from '../../../../helpers/steps';

const GroupServicesPicker = () => {
  const dispatch = useDispatch();
  const { setLocalState } = useFormContext();
  const { timeColumns } = useGroupPickerColumns();
  const { setAsterisks } = useValidateGoupPicker();
  const { toastMessage, toastMessagePosition, window } = useSelector((state) => state.core);

  useEffect(() => {
    setLocalState((prevState) => ({ ...prevState, prevStep: steps.groupServicesPicker }));

    // Set asterisks for inputs that have duplicated rows
    setAsterisks();

    // Close toast messages
    toastMessage.message && dispatch(closeToastMessage(true));
    toastMessagePosition !== 'bottom-right' && dispatch(setToastPosition('bottom-right'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (window.showNewTeleplanButtons) return <RotateYoutDeviseMessage />;

  return (
    <GroupPickerContentWrap>
      <GroupPickerTable columns={timeColumns} />
      <GroupServicesPickerActionButtons />
    </GroupPickerContentWrap>
  );
};

export default GroupServicesPicker;
