import { salesTaxList } from './salesTaxList';

export const calculateGroupTotal = (data) => {
  let total = data?.reduce((a, b) => Number(a) + Number(b), 0);
  return Number(total?.toFixed(2));
};

export const calculateInvoicePreviewTotal = (records) => {
  if (records) {
    const amount = records?.map((i) => i.feeAmount);
    const discount = records?.map((i) => getDiscountCurrency(i));
    const subtotal = calculateGroupTotal(amount) - calculateGroupTotal(discount);
    const total = subtotal + totaTax('pst', records) + totaTax('gst', records);

    return { subtotal, total };
  }
  return { subtotal: null, total: null };
};

export const totaTax = (field, records) => {
  const tax = salesTaxList.find((i) => i.name === field)[`${field}_percent`];
  const gstRows = records
    ?.filter((i) => i.salesTax === field || i.salesTax === 'gst_pst')
    ?.map((i) => {
      return (i.amount - getDiscountCurrency(i)) * tax;
    });
  return calculateGroupTotal(gstRows);
};

export const getDiscountCurrency = (rowData) => {
  const _discount = rowData['discountUnits'] === 'percent' ? (rowData['amount'] * rowData['discount']) / 100 : rowData['discount'];
  return Number(_discount);
};
