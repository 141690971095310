import React from 'react';

import { Tooltip } from 'primereact/tooltip';

import { isTimeValid, parseMilitaryTime, timeDiff } from '../../../../../../utils/formatTime';
import { calculationOfWholeNumberOfUnits, calculateRefactoryPeriod } from '../../../helpers/calculateUnits';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const DurationBodyTemplate = ({ rowData }) => {
  const startTime = parseMilitaryTime(rowData?.service_start);
  const endTime = parseMilitaryTime(rowData?.service_finish);
  const isStartValid = isTimeValid(startTime);
  const isEndValid = isTimeValid(endTime);

  if (isStartValid && isEndValid) {
    const durationInMinutes = timeDiff(startTime, endTime);
    // VER-376 - Show calculation of whole number of units + leftover time
    // const unitsCalculationText = calculationOfWholeNumberOfUnits({ startTime, endTime, rowData });
    // const refactoryPeriod = calculateRefactoryPeriod({ rowData });

    return (
      <div
        className={cx(
          'flex flex-column justify-content-center align-items-end lg:align-items-start h-full'
          // unitsCalculationText ? 'pt-3' : ''
        )}
      >
        <span>{`${durationInMinutes} min`}</span>
        {/* 
        <Tooltip target={`.unitsCalculationTooltip_${rowData.fee_code}`} position="top">
          {refactoryPeriod && `${t('Refractory_period')} ${refactoryPeriod} min`}
        </Tooltip>
        <span className={cx('text-sm text-gray-500 pointer', `unitsCalculationTooltip_${rowData.fee_code}`)}>{unitsCalculationText}</span> */}
      </div>
    );
  }

  return <span>{`${t('N_A')}`}</span>;
};

export default DurationBodyTemplate;
