import SectionTitle from './SectionTitle';
import ServiceDate from '../Inputs/ServiceDate';
import Practitioner from '../Inputs/Practitioner';
import FeeCode from '../Inputs/FeeCode';
import { t } from '../../../../../../../service/localization/i18n';

const ServiceDetailsSection = () => {
  return (
    <>
      <SectionTitle title={t('Service_Details')} className="mb-3" />

      <ServiceDate />

      <Practitioner />

      <FeeCode />
    </>
  );
};

export default ServiceDetailsSection;
