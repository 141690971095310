import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { savePreferences } from '../../../../preferences/actions/preferences.action.creators';
import { getDefaultPrefs, getPresetPrefs } from '../../../helpers/getDefaultPrefs';
import { inputs } from '../helpers/inputs';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { NA_DoctorGuid, multipleDoctorGuid } from '../../../../config/defaultValuesConfig';
import { unionBy } from 'lodash';

export const usePreferences = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { watch, localState, setLocalState } = useFormContext();
  const practitioner = watch(inputs.practitioner.name);
  const emergency = watch(inputs.emergency.name);
  const travel = watch(inputs.travel.name);
  const ccfpp = watch(inputs.ccfpp.name);
  const refToBy = watch(inputs.refToBy.name);
  const prefs = localState?.practitionerPrefs?.content;

  const convertedEmergency = emergency ? '1' : '0';
  const convertTravel = travel ? '1' : '0';
  const convertCCFPP = ccfpp ? '1' : '0';

  const defaultEmergency = prefsCodes.defaultEmergency;
  const presetEmergency = prefsCodes.presetEmergency;

  const defaultTravel = prefsCodes.defaultTravel;
  const presetTravel = prefsCodes.presetTravel;

  const defaultCCFPP = prefsCodes.defaultCCFPP;
  const presetCCFPP = prefsCodes.presetCCFPP;

  const isLocked = (value, defaultCode) => {
    return getPresetPrefs(prefs, defaultCode)?.toUpperCase() === value?.toUpperCase();
  };

  const onSavePrefs = (preferences = []) => {
    const id = practitioner === NA_DoctorGuid || practitioner === multipleDoctorGuid ? user.DCNGuid : practitioner;
    const prefs = { content: preferences, label: 'default', value: practitioner };

    dispatch(
      savePreferences(id, prefs, (response) => {
        setLocalState((prevState) => {
          const currentPrefs = prevState.practitionerPrefs?.content || [];
          const updatedPrefs = response?.content || [];
          const unionPrefs = unionBy(currentPrefs, updatedPrefs, 'label'); // Add prefs that are missing in the currentPrefs
          const content = unionPrefs?.map((i) => updatedPrefs?.find((x) => x.label === i.label) || i);

          return {
            ...prevState,
            practitionerPrefs: {
              ...prevState.practitionerPrefs,
              content
            }
          };
        });
      })
    );
  };

  const onSaveRefToByPreferences = () => {
    const defaultCode = prefsCodes.defaultReferralDirection;
    const presetCode = prefsCodes.presetReferral;

    const preferences = [
      { value: '1', label: presetCode },
      { value: refToBy, label: defaultCode }
    ];

    onSavePrefs(preferences);
  };

  const isLockedPrefsForEmergency = () => {
    const isLockedEmergency = getDefaultPrefs(prefs, defaultEmergency);
    const isLockedTravel = getDefaultPrefs(prefs, defaultTravel);
    const isLockedCCFPP = getDefaultPrefs(prefs, defaultCCFPP);

    if (isLockedTravel) {
      if (emergency && travel) return true;
      return false;
    }

    if (isLockedCCFPP) {
      if (emergency && ccfpp) return true;
      return false;
    }

    if (isLockedEmergency && !isLockedTravel && !isLockedCCFPP) {
      if (travel || ccfpp) return false;
      return isLocked(convertedEmergency, defaultEmergency);
    }

    return isLocked(convertedEmergency, defaultEmergency);
  };

  const onSavePreferencesForEmergency = () => {
    const preferences = [
      { value: '1', label: presetEmergency },
      { value: convertedEmergency, label: defaultEmergency },

      { value: '1', label: presetTravel },
      { value: convertTravel, label: defaultTravel },

      { value: '1', label: presetCCFPP },
      { value: convertCCFPP, label: defaultCCFPP }
    ];

    onSavePrefs(preferences);
  };

  return { isLocked, onSavePrefs, isLockedPrefsForEmergency, onSavePreferencesForEmergency, onSaveRefToByPreferences };
};
