import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PrivateInvoicePreview from './PrivateDetails/PrivateInvoicePreview/PrivateInvoicePreview';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { setFilterByUserForInvoicesViewPanel, getClaimInfo } from '../actions/claims.action.creators';

const ViewPrivateInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { isSavingClaims, isFetchingClaimHistory, isFetchingClaims } = useSelector((state) => state.claims);
  const [claimRecord, setClaimRecord] = useState({});

  // set initial claim details
  useEffect(() => {
    dispatch(
      getClaimInfo(id, (responseData) => {
        if (responseData?.Status === -1) history.goBack(); // if invoice was deleted
        if (responseData?.Status !== -1) setClaimRecord(responseData);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //component will unmount
  useEffect(() => {
    return () => {
      dispatch(setFilterByUserForInvoicesViewPanel(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-3">
      {(isFetchingClaimHistory || isSavingClaims || isFetchingClaims) && <CircularProgress />}

      <PrivateInvoicePreview data={claimRecord} />
    </div>
  );
};

export default ViewPrivateInvoice;
