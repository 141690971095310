import { updatePrivateRecord } from '../../../actions/claims.action.creators';
import { inputs } from './inputs';

export const onPayToUpdate = (data, dispatch) => {
  dispatch(updatePrivateRecord(data));
};

export const onBillToUpdate = (value, dispatch) => {
  dispatch(updatePrivateRecord({ [inputs().billTo.name]: value || [] }));
};
