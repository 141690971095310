import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import DatePicker from './Common/DatePicker';

import { inputs, requiredInputs } from '../../../helpers/inputs';
import { formatFormTypeValue, onFormTypeChange } from '../../../helpers/formTypeActions';
import { serviceDateFormat } from '../../../../../../utils/serviceDateFormat';
import { setMissingCodesToRecentList } from '../../../../../actions/claims.action.creators';

const ServiceDate = () => {
  const name = inputs.serviceDate.name;
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const formType = watch(inputs.formType.name);
  const speciality = watch(inputs.speciality.name);
  const status = watch(inputs.status.name);

  const onChange = (value) => {
    if (value) {
      // Add date to the quick pick catalogs if it's not in the list
      const formattedDate = serviceDateFormat(value);
      dispatch(setMissingCodesToRecentList({ codes: { [inputs.serviceDate.codeType]: [formattedDate] } }));

      // Update FormType input
      onFormTypeChange({
        value: formatFormTypeValue(formType, speciality),
        setValue,
        speciality: speciality,
        serviceDate: value
      });
    }
  };

  return <DatePicker required={!!requiredInputs[name]} input={inputs.serviceDate} onChange={onChange} status={status} />;
};

export default ServiceDate;
