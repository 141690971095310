import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputMask } from 'primereact/inputmask';
import { columnValues } from '../helpers/columnValues';
import { getPatients } from '../../../actions/patients.action.creators';
import { debounce } from 'lodash';
import { findDiff } from '../../../../../Helper';

const DateInput = ({ columnKey }) => {
  const hint = 'MM/DD/YYYY';
  const currentColumn = columnValues[columnKey];
  const currentField = currentColumn.filter;

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.patients);
  const [value, setValue] = useState(filters[currentField] || '');

  // Update local state when chip is deleted
  useEffect(() => {
    setValue(filters[currentField] || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[currentField]]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRequest = useCallback(
    debounce((params) => dispatch(getPatients(params)), 1000),
    []
  );

  const onChange = (e) => {
    const suspects = ['MMDDYYYY', hint]; //accept only full date
    const _value = findDiff(e.target.value, hint);
    const real_value = findDiff(hint, e.target.value);
    const filter_value = filters[currentField] ? filters[currentField].replace(/\//g, '') : '';
    setValue(e.target.value);
    if (_value && suspects.indexOf(_value) > -1) {
      //prevent onBlur API request
      if (real_value !== filter_value && e.target.value !== filters[currentField]) {
        const updatedFilters = { ...filters, [currentField]: e.target.value };
        debouncedRequest({ filters: updatedFilters });
      }
    }
  };

  return (
    <InputMask
      type="search"
      id={columnKey}
      value={value}
      mask="99/99/9999"
      slotChar={hint}
      placeholder={currentColumn.placeholder}
      autoClear={false}
      onChange={onChange}
    />
  );
};

export default DateInput;
