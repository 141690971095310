import { useEffect, useState } from 'react';

const useModules = (initialModules) => {
  const [modules, setModules] = useState(initialModules);

  useEffect(() => {
    if (initialModules) {
      setModules(initialModules);
    }
  }, [initialModules]);

  const updateModulePermission = (moduleId, newPermissions) => {
    setModules((prevModules) => prevModules.map((module) => (module.Module === moduleId ? { ...module, Permissions: newPermissions } : module)));
  };

  return { modules, updateModulePermission };
};

export default useModules;
