import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const BMISurgicalAssistDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      bmiSurgicalAssistDialog: false
    }));
  };

  const emergencyDialogFooter = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHide} autoFocus />;
  };

  return (
    <Dialog
      id={elementIDs.bmiSurgicalAssistDialog}
      header={t('Missing_information')}
      visible={localState.bmiSurgicalAssistDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={emergencyDialogFooter()}
      onHide={() => onHide()}
    >
      {t('Code_13003_is_calculated_as')}
    </Dialog>
  );
};

export default BMISurgicalAssistDialog;
