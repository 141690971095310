import { useEffect } from 'react';
import { filter, includes, keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setToastMessage } from '../core/actions/core.action.creators';
import { t } from '../../service/localization/i18n';

// interface {
//   errors: {
//     [key: string]: {
//       message: string;
//       type: string;
//       ref: {
//         name: string;
//       };
//     };
//   }

//   inputs: {
//     [key: string]: {
//       name: string;
//       label: string;
//     };
//   }

//   setFocus: () => void
// }

const findErrorFields = (errors, inputs) => {
  const errorKeys = keys(errors);
  const matchingItems = filter(inputs, (item) => includes(errorKeys, item.name));
  return matchingItems;
};

export const useFormErrorAutoscroll = ({ errors, inputs, setFocus }) => {
  const dispatch = useDispatch();
  const { toastMessage } = useSelector((state) => state.core);

  // Autoscroll and set focus to invalid input
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      const commonMessage = t('Please_complete_the_mandatory_fields');

      if (!toastMessage.message.includes(commonMessage)) {
        const errorInputs = findErrorFields(errors, inputs)
          ?.map((i) => i.label)
          ?.join()
          ?.replaceAll(',', ', ');
        const message = `${commonMessage}: ${errorInputs}`;
        dispatch(setToastMessage({ type: 'warn', message: message, lifeTime: 25000 }));
      }

      setFocus(firstError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setFocus]);
};
