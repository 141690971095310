import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import CardTitle from '../../Layouts/CardTitle';
import { OverlayPanel } from 'primereact/overlaypanel';

import { getPractitionerNameWithSpec } from '../../../../../../utils/getPractitioner';
import { patientFullNameWithAge } from '../../../../../../utils/patientFullName';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';
import { usePractitioner } from '../../../hooks/usePractitioner';
import { t } from '../../../../../../../service/localization/i18n';

const TableHeader = () => {
  const { watch } = useFormContext();
  const { practitionerOptions } = usePractitioner();
  const ref = useRef(null);
  const patients = watch(inputs.patient.name);
  const practitionerGuid = watch(inputs.practitioner.name);

  return (
    <div className="records_table_header flex gap-1 lg:gap-3">
      <div className="label_wrap">
        <CardTitle />
      </div>

      <div className="label_wrap">
        <span id={elementIDs.teleplanRecordsTablePatientLabel} className="mr-1 font-normal">
          {`${t('Patient.1')}: `}
        </span>

        <span id={elementIDs.teleplanRecordsTablePatientName}>{patients?.length ? patientFullNameWithAge(patients[0]) : null}</span>

        {patients?.length > 1 && (
          <Button
            className="p-button-link pl-2 py-0"
            label={patients?.length > 1 ? `+ ${patients?.length - 1} ${t('more')}` : ''}
            onClick={(e) => ref.current.toggle(e)}
          />
        )}
      </div>

      <div className="label_wrap">
        <span id={elementIDs.teleplanRecordsTablePractitionerLabel} className="font-normal">
          {`${t('Practititoner')}: `}
        </span>
        <span id={elementIDs.teleplanRecordsTablePractitionerName} className="font-bold">
          {getPractitionerNameWithSpec(practitionerGuid, practitionerOptions)}
        </span>
      </div>

      {/* Overlay for the patients */}
      <OverlayPanel className="overlaypanel-demo patientsListOverlay" ref={ref} id="overlay_panel">
        <ul className="m-0 pl-0">
          {patients
            ?.filter((x, idx) => idx !== 0)
            .map((i, index) => {
              return (
                <li className="mb-2" key={i?.PatientGuid}>
                  {`${index + 1}. ${patientFullNameWithAge(i)}`}
                </li>
              );
            })}
        </ul>
      </OverlayPanel>
    </div>
  );
};

export default TableHeader;
