import { inputs } from './inputs';
import { decodeText } from '../../../../utils/decodeText';
import { parseMSPCodes } from '../../../../utils/parser';

export const formatInitialValues = (initialValues) => {
  let errorCodes = [];
  const mspCodes = initialValues[inputs.mspCodes.name];
  if (mspCodes) errorCodes = parseMSPCodes(mspCodes);

  return {
    ...initialValues,
    [inputs.employer.name]: decodeText(initialValues[inputs.employer.name]),
    [inputs.address.name]: decodeText(initialValues[inputs.address.name]),
    [inputs.city.name]: decodeText(initialValues[inputs.city.name]),
    [inputs.priorProblems.name]: decodeText(initialValues[inputs.priorProblems.name]),
    [inputs.firstTreatment.name]: decodeText(initialValues[inputs.firstTreatment.name]),
    [inputs.clinicalInformation.name]: decodeText(initialValues[inputs.clinicalInformation.name]),
    [inputs.restrictions.name]: decodeText(initialValues[inputs.restrictions.name]),
    [inputs.icd9.codeDescription]: decodeText(initialValues[inputs.icd9.codeDescription]),
    [inputs.mspCodes.name]: errorCodes,

    [inputs.injuryDate.name]: initialValues[inputs.injuryDate.name] ? new Date(initialValues[inputs.injuryDate.name]) : null,
    [inputs.disabledDate.name]: initialValues[inputs.disabledDate.name] ? new Date(initialValues[inputs.disabledDate.name]) : null,
    [inputs.recoveryDate.name]: initialValues[inputs.recoveryDate.name] ? new Date(initialValues[inputs.recoveryDate.name]) : null,
    [inputs.serviceDate.name]: initialValues[inputs.serviceDate.name] ? new Date(initialValues[inputs.serviceDate.name]) : null,

    [inputs.feeCodes.name]: initialValues[inputs.feeCodes.name]?.trim()
  };
};
