import React from 'react';

import ResultsMoney from './ResultsMoney';
import ResultsHeader from './ResultsHeader';
import ResultsPreviewActionButtons from '../ActionButtons/ResultsPreviewActionButtons';
import Link from '../../../../../../components/Buttons/Link/Link';

import { eClaimTypes } from '../../helpers/eClaimTypes';
import { getEOBLink } from '../../../../../utils/getLink';

const SuccessResult = ({ eClaimResult }) => {
  const url = getEOBLink(eClaimResult);
  const insurerNameEn = eClaimResult.InsurerNameEN;
  const replyType = eClaimResult.ReplyType;
  const replyNum = eClaimResult.ReplyNum;
  const formName = eClaimTypes[replyType];

  return (
    <div className="flex flex-column gap-7">
      {/* ResultsHeader */}
      <ResultsHeader eClaimResult={eClaimResult} />

      {/* Body */}
      <div className="flex flex-column gap-4">
        <div>
          <span>{`You received `}</span>
          <span className="font-bold">{`${formName} `}</span>
          <Link href={url}>
            <span className="font-bold">{`#${replyNum}`}</span>
          </Link>
        </div>

        <ResultsMoney eClaimResult={eClaimResult} />
      </div>

      {/* Buttons */}
      <ResultsPreviewActionButtons eClaimResult={eClaimResult} />

      {/* Link */}
      <div>
        <span>{`Please click `}</span>
        <Link href={url}>{`here`}</Link>
        <span>{` to see the reply from the ${insurerNameEn}`}</span>
      </div>
    </div>
  );
};

export default SuccessResult;
