import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWatch, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { isSupplementary } from '../../../../../config/specialitiesConfig';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { isRebillStatus } from '../../../../../utils/getStatus';
import { inputs } from '../../helpers/inputs';
import { steps } from '../../helpers/steps';
import { useValidation } from '../../hooks/useValidation';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';

const SubmitButton = () => {
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { isCreatingPatient } = useSelector((state) => state.patients);
  const { showSubmitButton } = useValidation();
  const { isNew, watch, onSubmitNewInvoice, onSubminExistingInvoice, isMobile } = useFormContext();
  const loading = isSavingClaims && watch(inputs.sendNow.name);
  const disabled = (isSavingClaims && !watch(inputs.sendNow.name)) || isCreatingPatient;

  const onSubminExisting = () => {
    // Format submit data
    const formatedInvoice = {
      ...watch(),
      [inputs.sendNow.name]: true //sendNow - onSubmit = true, onSave = false
    };

    onSubminExistingInvoice(formatedInvoice);
  };

  const onSubmit = () => {
    return isNew ? onSubmitNewInvoice() : onSubminExisting();
  };

  if (!showSubmitButton) return null;

  return (
    <Button
      id={elementIDs.submit}
      type="button"
      className="teleplan_preview_action_button p-button-outlined"
      label={t('Submit')}
      loading={loading}
      disabled={disabled}
      tooltip={isMobile ? '' : t('Submit_your_claim_to_insurer')}
      tooltipOptions={{ position: 'top', showDelay: 1000 }}
      onClick={onSubmit}
    />
  );
};

const CreateButton = () => {
  const { isCreatingPatient } = useSelector((state) => state.patients);
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { showSubmitButton } = useValidation();
  const { watch, isMobile, onCreateNewInvoice } = useFormContext();
  const loading = isSavingClaims && !watch(inputs.sendNow.name);
  const disabled = (isSavingClaims && watch(inputs.sendNow.name)) || isCreatingPatient;

  return (
    <Button
      id={elementIDs.create}
      type="button"
      className="teleplan_preview_action_button"
      // className={cx('teleplan_preview_action_button', showSubmitButton ? 'p-button-outlined' : '')}
      loading={loading}
      disabled={disabled}
      label={t('Create')}
      tooltip={isMobile ? '' : t('Save_your_claim_without_submitting_it')}
      tooltipOptions={{ position: 'top', showDelay: 1000 }}
      onClick={onCreateNewInvoice}
    />
  );
};

const SaveAndCloseButton = () => {
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { watch, onSubminExistingInvoice, isDirty, onClose } = useFormContext();
  const status = Number(watch(inputs.status.name));
  const rebillStatus = isRebillStatus(status);
  const autoEdit = isDirty || rebillStatus || status === 1;

  const onSaveExisting = () => {
    if (!autoEdit) return onClose();

    // Format submit data
    const formatedInvoice = {
      ...watch(),
      [inputs.sendNow.name]: false //sendNow - onSubmit = true, onSave = false
    };

    onSubminExistingInvoice(formatedInvoice);
  };

  return (
    <Button
      id={elementIDs.saveAndClose}
      type="submit"
      className="teleplan_preview_action_button"
      loading={isSavingClaims}
      label={t('Save_and_Close')}
      onClick={onSaveExisting}
    />
  );
};

const BackButton = () => {
  const { isNew, setLocalState, control } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });

  const onBack = () => {
    const step = isNew && !isSupplementary(speciality, invoiceType) ? steps.records : steps.form;
    setLocalState((prevState) => ({ ...prevState, step }));
  };

  return (
    <Button id={elementIDs.back} type="button" className="p-button-outlined teleplan_preview_action_button" label={t('Back')} onClick={onBack} />
  );
};

const CancelButton = () => {
  const history = useHistory();

  return (
    <Button
      id={elementIDs.cancel}
      type="button"
      className="p-button-outlined teleplan_preview_action_button"
      label={t('Cancel')}
      onClick={() => history.goBack()}
    />
  );
};

const NewClaimLayout = () => {
  return (
    <>
      <CreateButton />
      <SubmitButton />
      <BackButton />
      <CancelButton />
    </>
  );
};

const EditClaimLayout = () => {
  return (
    <>
      <SaveAndCloseButton />
      <SubmitButton />
      <BackButton />
      <CancelButton />
    </>
  );
};

const PreviewPanelActionButtons = () => {
  const { isNew } = useFormContext();

  return (
    <div className="stickyActionButtons w-full py-3">
      {/* Create new claim */}
      {isNew && <NewClaimLayout />}

      {/* Edit claim */}
      {!isNew && <EditClaimLayout />}
    </div>
  );
};

export default PreviewPanelActionButtons;
