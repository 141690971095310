import React from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import QuickPickItemsBlock from './QuickPickItemsBlock';
import { inputs } from '../../../helpers/inputs';
import { quickPickItems } from '../../../helpers/quickPickItems';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const QuickPick = () => {
  return (
    <ScrollPanel className="h-full">
      <div id={elementIDs.quickPickPanel} className="mt-3 pr-1">
        {quickPickItems.map((i) => {
          return <QuickPickItemsBlock key={`${i.name}`} item={i} showDivider={i.name !== inputs.noi.name} />;
        })}
      </div>
    </ScrollPanel>
  );
};

export default QuickPick;
