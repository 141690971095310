import React from 'react';
import { Button } from 'primereact/button';
import { t } from '../../service/localization/i18n';

const RemoveFiltersForMoreRecords = ({ clearFiltersExceptFreetext, totalAlter, totalFound }) => {
  return (
    <Button
      className="p-button-text p-button-danger"
      label={String.format(t('Remove_filters_to_see_more_records'), totalAlter - totalFound)}
      onClick={clearFiltersExceptFreetext}
    />
  );
};

export default RemoveFiltersForMoreRecords;
