import React, { useRef } from 'react';
import CommonCard from '../../../common/components/CommonCard';
import { t } from '../../../../service/localization/i18n';
import ClaimView from '../../../claims/components/ClaimView';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setDefaultClaimsTableValues } from '../../../claims/actions/claims.action.creators';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { addNewTeleplan } from '../../../claims/helpers/addNewInvoice';
import { addNewOverlayItemsList } from '../../../claims/helpers/addNewOverlayItemsList';

const RecentClaims = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isGettingPrefs } = useSelector((state) => state.preferences);
  const { isFetchingClaims } = useSelector((state) => state.claims);
  const { isMobileOnly } = useSelector((state) => state.core.window);
  const addNewInvoiceMenuRef = useRef();

  const claimsViewMore = () => {
    history.push(`${routes.invoices.path}`);
    dispatch(setDefaultClaimsTableValues());
  };

  const invoicesHeaderActionButton = () => {
    if (isMobileOnly)
      return (
        <Button
          className="p-button-outlined p-button-rounded"
          icon="pi pi-plus"
          tooltip={t('Add_new_invoice')}
          tooltipOptions={{ position: 'top' }}
          disabled={isGettingPrefs}
          aria-controls="add_new_invoice_menu"
          aria-haspopup
          onClick={(e) => addNewInvoiceMenuRef.current.toggle(e)}
        />
      );

    return (
      <SplitButton
        className="p-button-rounded p-button-text"
        label={t('New_claim')}
        disabled={isGettingPrefs}
        model={addNewOverlayItemsList({ history, isSplitButton: true })}
        onClick={() => addNewTeleplan({ history })}
      />
    );
  };

  return (
    <CommonCard
      title={t('Recent_Billing')}
      showFooter={true}
      isFetching={isFetchingClaims}
      footerButtonLable={t('View_More')}
      footerButtonOnClick={claimsViewMore}
      headerActionButton={invoicesHeaderActionButton()}
    >
      <ClaimView id="claims" showInCard={true} showOnOverview={true} />
    </CommonCard>
  );
};

export default RecentClaims;
