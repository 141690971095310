import actions from './reports.action.types';
import { store } from '../../../store';
import { getApiBase, setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import forchart from '../../../mocks/db/reports/forchart.json';
import chartByPayeeNumber from '../../../mocks/db/reports/chartByPayeeNumber.json';
import axios from 'axios';

export const getReports = ({ reportType = '1' } = {}) => {
  const currentState = store.getState();
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  setAuthHeader(currentState);

  return axios
    .get(`${getApiBase()}/reports/${DCNGuid}/${reportType}`)
    .then((results) => {
      return results.data;
      // return forchart; // mocked data
      // return chartByPayeeNumber; // mocked data
    })
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};
