import React, { useContext } from 'react';
import Context from '../../../../../Context';
import { inputs } from '../../../../../modules/claims/components/WSBCReport/helpers/inputs';
import { t } from '../../../../../service/localization/i18n';

const InjuryCodes = () => {
  const { data } = useContext(Context);

  return (
    <div className="pt-2 text-sm">
      <div className="font-semibold demographicsInfoTableHeaderPrint">{t('Injury_codes_and_description')}</div>
      <table className="demographicsInfoTablePrint">
        <tbody>
          <tr>
            <td colSpan="3">
              <div className="flex">
                <span className="font-semibold">{t('Diagnosis')}:</span>
                <span className="wordBreakText pl-3 coloredText">{data[inputs.icd9Text.name]}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex">
                <span className="font-semibold pr-2">{`${t('CSA_BP_AP')}:`}</span>
                <span className="coloredText">{`${data[inputs.aoi.name]}, ${data[inputs.anatomicCode.name]}`}</span>
              </div>
            </td>

            <td>
              <div className="flex">
                <span className="font-semibold pr-2">{`${t('Nature_of_Injury.3')}:`}</span>
                <span className="coloredText">{data[inputs.noi.name]}</span>
              </div>
            </td>

            <td style={{ width: '33%' }}>
              <div className="flex">
                <span className="font-semibold pr-2">{`${t('ICD9')}:`}</span>
                <span className="coloredText">{data[inputs.icd9.name]}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InjuryCodes;
