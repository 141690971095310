import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import CallTime from '../Inputs/CallTime';
import EndTime from '../Inputs/EndTime';
import StartTime from '../Inputs/StartTime';

import { inputs } from '../../../helpers/inputs';
import { hideTimeInputs } from '../../../helpers/inputsConditions';

const TimeSection = ({ inputsClassName }) => {
  const { control } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const emergency = useWatch({ control, name: inputs.emergency.name });
  const travel = useWatch({ control, name: inputs.travel.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const hideInputs = hideTimeInputs(speciality, invoiceType);

  if (hideInputs) return null;

  return (
    <div className="teleplan_two_inputs_in_a_row">
      {emergency && travel && <CallTime className={inputsClassName} />}
      <StartTime className={inputsClassName} />
      <EndTime className={inputsClassName} />
    </div>
  );
};

export default TimeSection;
