import React, { useEffect, useState } from 'react';
import { PatientPoliciesContext } from '../../../../modules/contexts/contexts';
import { getPatientData, getPoliciesList } from '../helpers/fetchers';
import { defaultValues } from '../config/defaultValues';

const PatientPoliciesProvider = (props) => {
  const patient = props.patient;
  const patientGuid = patient ? patient?.PatientGuid : props.patientGuid;

  const [state, setState] = useState({
    ...defaultValues,
    patientGuid,
    patient
  });

  // Get patient info if `patient` prop is not provided
  useEffect(() => {
    if (!patient && patientGuid) getPatientData(patientGuid, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  useEffect(() => {
    if (state.patientGuid) getPoliciesList(state.patientGuid, setState);
  }, [state.patientGuid]);

  return <PatientPoliciesContext.Provider value={{ state, setState }}>{props.children}</PatientPoliciesContext.Provider>;
};

export default PatientPoliciesProvider;
