import moment from 'moment';
import { decodeText } from '../../../../../modules/utils/decodeText';
import { getPractitionerNameWithSpec } from '../../../../../modules/utils/getPractitioner';
import { store } from '../../../../../store';
import { inputs } from './inputs';
import { defaultGuid } from '../../../../../modules/config/defaultValuesConfig';

export const getDefaultData = ({ note, entityGuid } = {}) => {
  if (note) {
    return {
      ...note,
      [inputs.title.name]: decodeText(note[[inputs.title.name]]),
      [inputs.note.name]: decodeText(note[[inputs.note.name]])
    };
  }

  const user = store.getState().user.details;
  const authorName = getPractitionerNameWithSpec(user);
  return {
    // [inputs.thread.name]: "",
    // [inputs.adressee.name]: null,
    [inputs.author.name]: authorName,
    [inputs.date.name]: moment().toDate(),
    [inputs.title.name]: '',
    [inputs.note.name]: '',
    [inputs.noteGuid.name]: defaultGuid,
    [inputs.parentGuid.name]: defaultGuid,
    [inputs.entityGuid.name]: entityGuid,
    [inputs.dcnGuid.name]: user?.DCNGuid,
    [inputs.practitionerGuid.name]: user.UserGuid
  };
};
