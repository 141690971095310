import { inputs } from './inputs';
import { patientFullNameWithAge } from '../../../../utils/patientFullName';
import { t } from '../../../../../service/localization/i18n';

export const dataTableActionTooltip = (text, rowData) => {
  const patientName = patientFullNameWithAge(rowData[inputs.patient.name][0]);
  const feeCodes = rowData[inputs.feeCodes.codeType];
  const feeCode = feeCodes?.length ? feeCodes[0]?.value : t('No_fee_code').toLowerCase();
  const tooltip = String.format(text, patientName, feeCode);

  return tooltip;
};
