import React from 'react';

// interface ILinkProps {
//   children: any,
//   href: string,
//   target?: string
// }

const Link = (props) => {
  return (
    <a className="cursor-pointer underline" href={props.href} target={props.target || '_blank'} rel="noreferrer">
      {props.children}
    </a>
  );
};

export default Link;
