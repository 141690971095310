// interface Requests {
//   [key: string]: () => void;
// }

export const getPromiseAll = async (requests) => {
  const results = await Promise.all(Object.entries(requests).map(([key, request]) => request.then((response) => ({ [key]: response })))).then(
    (results) => Object.assign({}, ...results)
  );

  return results;
};
