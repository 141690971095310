import { patientFullNameWithAge } from '../../utils/patientFullName';
import { formatDate } from '../../utils/formatDate';
import { getAge } from '../../utils/getAge';
import moment from 'moment';

export const formatPatientInfoForClaims = (patient) => {
  return {
    ...patient,
    FirstName: patient?.FirstName?.toUpperCase(),
    LastName: patient?.LastName?.toUpperCase(),
    PatientGuid: patient?.PatientGuid,
    PHN: patient?.PHN || '',
    Sex: patient?.Sex,
    DOB: moment(patient?.BirthDay).format('MM/DD/YYYY'),
    Age: getAge(patient?.BirthDay),
    BirthDay: formatDate(patient?.BirthDay),
    // value: patient?.PatientGuid,
    label: patientFullNameWithAge(patient)
  };
};
