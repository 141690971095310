import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { steps } from '../../../../../helpers/steps';
import { t } from '../../../../../../../../../service/localization/i18n';

const SettingsButton = () => {
  const { localState, setLocalState } = useFormContext();

  const onClick = () => {
    setLocalState((prevState) => ({
      ...prevState,
      groupTabelSettingsDialog: true
    }));
  };

  return (
    <>
      {/* Display the settings button only when the current step is the 'groupServicesPicker' and ConsecutiveTime is the only existing setting for now */}
      {localState.step === steps.groupServicesPicker && (
        <Button
          className="p-button-text p-button-rounded"
          icon="pi pi-cog"
          tooltip={t('Settings')}
          tooltipOptions={{ position: 'top' }}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default SettingsButton;
