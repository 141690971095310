import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { trimLeftZeros } from '../../../../../../utils/trimLeftZeros';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const HeaderInfoSection = () => {
  const { gettingWSBCReport } = useSelector((state) => state.claims);

  const { isNew, watch } = useFormContext();
  const status = watch(inputs.invoiceStatus.name);
  const statusText = watch(inputs.statusText.name);
  const recordNumber = watch(inputs.recordNumber.name);
  const sequenceNum = watch(inputs.sequenceNum.name) || t('N_A').toLowerCase();
  const declinedStatus = watch(inputs.invoiceStatus.name) === 3 || watch(inputs.invoiceStatus.name) === 6;

  const numbersLayout = (
    <>
      <div className="flex mr-3">
        <span className="mr-2">{t('Office.1')}:</span>
        {gettingWSBCReport ? (
          <Skeleton width="45px" height="17px" />
        ) : (
          <b id={elementIDs.recordNumber}>{isNew ? t('N_A').toLowerCase() : trimLeftZeros(recordNumber)}</b>
        )}
      </div>

      <div className="flex mr-3">
        <span className="mr-2">{t('Sequence.1')}:</span>
        {gettingWSBCReport ? (
          <Skeleton width="45px" height="17px" />
        ) : (
          <b id={elementIDs.sequenceNumber}>{isNew ? t('N_A').toLowerCase() : sequenceNum}</b>
        )}
      </div>
    </>
  );

  const declinedStatusLayout = (
    <div>
      <div className="text-lg w-full">{t('Based_on_Declined_Claim')}</div>

      <div className="flex py-3">{numbersLayout}</div>

      {watch(inputs.mspCodes.name)?.map((i, index) => {
        return (
          <div id={`${elementIDs.mspCodeError}_${index}`} className="my-3 p-error" key={index}>
            {i}
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      {declinedStatus && !isNew && <Message className="w-full justify-content-start" severity="warn" content={declinedStatusLayout} />}

      {!declinedStatus && (
        <div className="flex" style={{ minHeight: '20px' }}>
          <div className="mr-3">
            <span id={elementIDs.statusChip} className={cx('customer-badge-mobile py-1', `cm_status_${status}`)}>
              {isNew ? t('New') : statusText}
            </span>
          </div>

          {numbersLayout}
        </div>
      )}
    </>
  );
};

export default HeaderInfoSection;
