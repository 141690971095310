import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Context from '../../../../Context';
import SuccessPage from '../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { routes } from '../../../../routes/routes';
import { defaultGuid } from '../../../config/defaultValuesConfig';
import { resetPrivateForm } from './claimDetailsHelpers/resetPrivateForm';
import { setDirty } from '../../../core/actions/core.action.creators';
import { currencyFormat } from '../../../utils/currencyFormat';
import {
  updateClaimsState,
  setDefaultClaimsTableValues,
  setPrivateRecord,
  setResetClaimForm,
  getInvoicesCatalogMostRecentCodes
} from '../../actions/claims.action.creators';
import { inputs } from './claimDetailsHelpers/inputs';
import { commonInputs } from '../../../config/commonInputsConfig';
import { addNewTeleplan, addNewWSBCR } from '../../helpers/addNewInvoice';
import { getPreferences } from '../../../preferences/actions/preferences.action.creators';
import { updateUserDefaultPrefs } from './claimDetailsHelpers/updateUserDefaultPrefs';
import { t } from '../../../../service/localization/i18n';

const Success = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setStep, isNew, privateRecord, setNewClaim, claimIsDeleted, setClaimIsDeleted, currentUserDefaultPrefs } = useContext(Context);

  const _patientsSuccess = Array.isArray(privateRecord[commonInputs.patients.descriptionName])
    ? privateRecord[commonInputs.patients.descriptionName]?.map((i) => `${i.LastName}, ${i.FirstName}`)
    : [];

  useEffect(() => {
    dispatch(setResetClaimForm(true)); // Reset form if user navigate usin main nav menu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //component will unmount
  useEffect(() => {
    return () => {
      setClaimIsDeleted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPatientProfileFormRest = () => {
    resetPrivateForm();

    // [KS] CMO-1164 - Create claim - success page - reset data table filters when user click on "Open patient's profile" button
    dispatch(setDefaultClaimsTableValues());

    //set first page
    //CMO-761 - Patient's profile->Create New invoice->Save->Open patient's profile->Invoices card does not display the 1st page
    dispatch(updateClaimsState({ page: 0 }));
  };

  const billSamePrivatePatient = () => {
    dispatch(
      getPreferences(privateRecord.DCNGuid, privateRecord.DoctorGuid, (data) => {
        const defaultPrefs = data?.find((i) => i.label === 'default');
        dispatch(
          setPrivateRecord({
            ...privateRecord,
            ClaimNumber: '',
            RecordNo: '',
            [inputs().invoiceNumber.name]: '',
            InvoiceGuid: defaultGuid,
            CurrentUserPrefs: updateUserDefaultPrefs({
              defaultPrefs,
              currentUserDefaultPrefs,
              doctorGuid: privateRecord?.DoctorGuid
            })
          })
        );

        if (!isNew) history.replace(routes.newPrivateRecord.path);
        dispatch(setDirty());
        setStep(0);
      })
    );
  };

  const billOtherPrivatePatient = () => {
    if (!isNew) history.replace(routes.newPrivateRecord.path);
    dispatch(getInvoicesCatalogMostRecentCodes({}));
    setNewClaim();
    setStep(0);
  };

  const billSameTeleplanPatient = (pos) => {
    // [KS] Use commented code if no need to reset practitioner (in this case don't forget to comment "addNewTeleplan" function)
    // const currentUser = getItemFromArray(users, user.details?.UserGuid, "DoctorGuid");
    // dispatch(getInvoicesCatalogMostRecentCodes({patientGuid: privateRecord[commonInputs.patients.descriptionName][0]?.PatientGuid)});
    // dispatch(shouldResetForm(false));
    // dispatch(setClaimRecord({
    //   ...blankRecord(currentUser, currentPractitionerPrefs),
    //   ServiceDate: privateRecord?.ServiceDate,
    //   [commonInputs.patients.name]: privateRecord[commonInputs.patients.descriptionName]?.map(i => i.PatientGuid),
    //   [commonInputs.patients.descriptionName]: privateRecord[commonInputs.patients.descriptionName],
    // }));
    // history.replace(`${routes.newClaimRecord.path}`);

    addNewTeleplan({
      history,
      patient_details: privateRecord[commonInputs.patients.descriptionName][0],
      replaceRoute: true,
      pos
    });
    resetPrivateForm(false);
  };

  const billOtherTeleplanPatient = (pos) => {
    const path = pos ? `${routes.teleplanPOS.path}` : `${routes.newClaimRecord.path}`;
    history.replace(path);
    resetPrivateForm();
  };

  const openPatientProfile = (PatientGuid) => {
    goToPatientProfileFormRest();
    history.replace(`${routes.patientDetails.path}/${PatientGuid}`);
  };

  const newPatient = () => {
    resetPrivateForm();
    history.replace(`${routes.createPatient.path}/new`);
  };

  const goToPrevPage = () => {
    resetPrivateForm();
    history.goBack();
  };

  const wsbcReportWithSamePatient = () => {
    addNewWSBCR({ history, patient_details: privateRecord[commonInputs.patients.descriptionName][0], replaceRoute: true });
    resetPrivateForm();
  };

  const wsbcReportWithOtherPatient = () => {
    resetPrivateForm();
    addNewWSBCR({ history, replaceRoute: true });
  };

  const statusText = () => {
    if (claimIsDeleted) return t('Deleted').toLowerCase();
    if (!claimIsDeleted) {
      if (isNew) return t('Created').toLowerCase();
      if (!isNew) return t('Updated').toLowerCase();
    }
  };

  const successMessage = () => {
    return (
      <>
        {`${String.format(t('You_created_record_for'), statusText(), 1, currencyFormat(privateRecord.FeeTotal))} ${t('for')} `}
        {_patientsSuccess?.join()?.replaceAll(',', ', ')}
      </>
    );
  };

  const onSuccessMessageClick = () => history.replace(`${routes.privetInvoice.path}/${privateRecord?.InvoiceGuid}`);

  return (
    <SuccessPage successMessage={successMessage()} onSuccessMessageClick={claimIsDeleted ? undefined : onSuccessMessageClick}>
      <SuccessActionButtons
        patientsList={privateRecord[commonInputs.patients.descriptionName]}
        wsbcReportWithSamePatient={wsbcReportWithSamePatient}
        wsbcReportWithOtherPatient={wsbcReportWithOtherPatient}
        billSameTeleplanPatient={billSameTeleplanPatient}
        billSameTeleplanPatientPOS={billSameTeleplanPatient}
        billSamePrivatePatient={billSamePrivatePatient}
        billOtherTeleplanPatient={billOtherTeleplanPatient}
        billOtherTeleplanPatientPOS={billOtherTeleplanPatient}
        billOtherPrivatePatient={billOtherPrivatePatient}
        openPatientProfile={openPatientProfile}
        goToPrevPage={goToPrevPage}
        newPatient={newPatient}
      />
    </SuccessPage>
  );
};

export default Success;
