import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const options = [
  { value: 'N/A', label: t('N_A') },
  { value: 'MD', label: t('Physician') },
  { value: 'OPT', label: t('Optometrist') },
  { value: 'OPHTH', label: t('Ophthalmologist') }
];

const PrescriberTypeInfo = () => {
  return (
    <>
      <Tooltip className="max-w-20rem" target=".prescriberTypeInfo" position="top">
        {t('prescriber_type_info_tooltip')}
      </Tooltip>
      <i className="pi pi-question-circle prescriberTypeInfo" />
    </>
  );
};

export const PrescriberType = () => {
  const name = inputs.prescriberType.name;
  const { control, errors, isView, clearErrors, watch, setValue } = useFormContext();
  const firstNameKey = inputs.firstName.name;
  const lastNameKey = inputs.lastName.name;
  const prescriberFirstName = watch(firstNameKey);
  const prescriberLastName = watch(lastNameKey);

  const onChange = (value, field) => {
    field.onChange(value);

    // Clear error for First and Last name input
    const firstNameError = errors[firstNameKey]?.message;
    const lastNameError = errors[lastNameKey]?.message;
    if (value === 'N/A') {
      prescriberFirstName && setValue(firstNameKey, '');
      prescriberLastName && setValue(lastNameKey, '');
      if (firstNameError || lastNameError) {
        clearErrors([firstNameKey, lastNameKey]);
      }
    }
  };

  const accidentTypeInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.prescriberType.label} labelOptionalContent={<PrescriberTypeInfo />}>
        <Dropdown
          id={elementIDs.accidentTypeDropdown}
          inputRef={field.ref}
          type="text"
          value={field.value}
          field="label"
          options={options}
          disabled={isView}
          scrollHeight={dropdownScrollHeight()}
          onChange={(e) => onChange(e.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => accidentTypeInput(field)} />;
};

export default PrescriberType;
