import moment from 'moment';
import { t } from '../../../../../service/localization/i18n';
import { formatPatientInfoForClaims } from '../../../../patients/helpers/formatPatientInfoForClaims';
import { defaultGuid, multipleDoctorGuid, NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { trimLeftZeros } from '../../../../utils/trimLeftZeros';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { getDefaultPrefs } from '../../../helpers/getDefaultPrefs';
import { addTermsToDueDate } from './addTermsToDueDate';
import { formatServices } from './formatServices';
import { inputs } from './inputs';
import { termsOptions } from './termsOptions';
import { getServiceDate } from '../../../../utils/getDate';

export const updatePreferencesForPrivateRecord = (privateRecord, currentUserDefaultPrefs) => {
  return {
    ...privateRecord,
    [commonInputs.patients.descriptionName]: privateRecord?.Patients, // Patient
    ServiceDate: serviseDateInit(privateRecord),
    CurrentUserPrefs: currentUserDefaultPrefs
  };
};

export const privateBlankRecord = ({
  user = false,
  currentUserDefaultPrefs = false,
  currentPractitionerPrefs = false,
  privateRecord = false,
  responceUpdate = false,
  payToInit,
  clinic
}) => {
  return {
    DCNGuid: privateRecord ? privateRecord.DCNGuid : user?.DCNGuid,
    ClaimGuid: privateRecord ? privateRecord.ClaimGuid : defaultGuid,
    InvoiceGuid: privateRecord ? privateRecord.InvoiceGuid : defaultGuid,
    ServiceDate: serviseDateInit(privateRecord), // [KS] CMO-1423 - Persist service date during session
    RecordNo: privateRecord ? trimLeftZeros(privateRecord.RecordNo) : '',
    ClaimNumber: privateRecord ? privateRecord.ClaimNumber : '',
    [commonInputs.patients.descriptionName]: patientsInit(privateRecord), // Patient
    [commonInputs.practitioner.id]: initPractitioner(privateRecord, user, currentUserDefaultPrefs).id,
    [commonInputs.practitioner.name]: initPractitioner(privateRecord, user, currentUserDefaultPrefs).treatedBy,
    [commonInputs.practitioner.licenceNumber]: licenceNumberInit(user, privateRecord),
    InvoiceType: 'P',
    Status: privateRecord ? privateRecord.Status : 0,
    StatusText: privateRecord ? privateRecord.StatusText : t('Outstanding'),
    Speciality: privateRecord ? privateRecord.Specialty : user?.Speciality || 0,
    PractitionerNumber: privateRecord ? privateRecord.PractitionerNumber : user?.PractitionerNumber || '',
    DateCreated: privateRecord ? privateRecord.DateCreated : '',
    DateUpdated: privateRecord ? privateRecord.DateUpdated : '',
    SDate: privateRecord ? privateRecord.SDate : '',
    FeePaid: privateRecord ? privateRecord.FeePaid : 0,
    FeeAmount: privateRecord ? privateRecord.FeeAmount : 0,
    PstTotal: privateRecord ? privateRecord.PstTotal : 0,
    GstTotal: privateRecord ? privateRecord.GstTotal : 0,
    DiscountTotal: privateRecord ? privateRecord.DiscountTotal : 0,
    FeeTotal: privateRecord ? privateRecord.FeeTotal : 0,
    NoOfServiceUnits: privateRecord ? privateRecord.NoOfServiceUnits : 0,

    //FOR PRIVATE INVOICES
    [commonInputs.location.name]: privateRecord ? privateRecord[commonInputs.location.name] || '' : clinic?.details?.ClinicName,
    [inputs().privateService.name]: serviceGuidInit(privateRecord),
    [inputs().privateService.descriptionName]: serviceListInit(privateRecord, responceUpdate),
    [inputs().payTo.name]: privateRecord ? privateRecord[inputs().payTo.name] : payToInit,
    [inputs().billTo.name]: privateRecord ? [formatPatientInfoForClaims(privateRecord[inputs().billTo.name])] : [],
    [inputs().terms.name]: privateRecord ? privateRecord[inputs().terms.name] : termsInit()?.net,
    [inputs().invoiceDate.name]: invoiceDateInit(privateRecord),
    [inputs().dueDate.name]: dueDateInit(privateRecord),
    [inputs().invoiceNumber.name]: privateRecord ? trimLeftZeros(privateRecord[inputs().invoiceNumber.name]) || '' : '',
    [inputs().messageOnInvoice.name]: privateRecord ? privateRecord[inputs().messageOnInvoice.name] : '',

    //INPUTS WITH PREFERENCES
    CurrentUserPrefs: currentPractitionerPrefs || null
    //==========================================//
  };
};

export const termsInit = () => termsOptions[0];

const dueDateInit = (privateRecord) => {
  const dueInitValue = privateRecord
    ? moment(privateRecord[inputs().dueDate.name]).format('YYYY-MM-DD')
    : addTermsToDueDate(termsInit()?.net, moment(new Date()).format('YYYY-MM-DD'));
  return dueInitValue || null;
};

const invoiceDateInit = (privateRecord) => {
  const initDate = privateRecord
    ? moment(privateRecord[inputs().invoiceDate.name]).format('YYYY-MM-DD')
    : moment(new Date()).format('YYYY-MM-DD') || null;
  return initDate;
};

const licenceNumberInit = (user, privateRecord) => {
  const initValue = privateRecord
    ? privateRecord[commonInputs.practitioner.licenceNumber]
    : user
      ? user[commonInputs.practitioner.licenceNumber]
      : '';
  return initValue;
};

const serviceGuidInit = (privateRecord) => {
  return privateRecord ? privateRecord[inputs().privateService.descriptionName]?.map((i) => i.value) : [];
};

const serviseDateInit = (privateRecord) => {
  if (privateRecord.ServiceDate) {
    return moment(privateRecord.ServiceDate).toDate();
  }

  return getServiceDate();
};

const patientsInit = (privateRecord) => {
  const formattedPatientInfo = formatPatientInfoForClaims(privateRecord?.PatientDetails);

  delete formattedPatientInfo[inputs().payTo.name];
  delete formattedPatientInfo[inputs().billTo.name];
  delete formattedPatientInfo[inputs().privateService.descriptionName];

  const initList = privateRecord ? [formattedPatientInfo] : [];
  return initList;
};

const serviceListInit = (privateRecord, responceUpdate) => {
  const formattedServices =
    privateRecord &&
    privateRecord[inputs().privateService.descriptionName]?.map((i) => {
      return {
        ...i,
        treatedBy: i.TreatedBy ? i.TreatedBy : i.treatedBy,
        id: i.value
      };
    });

  return privateRecord ? formatServices({ ...privateRecord, ServicesList: formattedServices }, responceUpdate) : [];
};

const initPractitioner = (privateRecord, user, currentUserDefaultPrefs) => {
  if (privateRecord) {
    return {
      id: privateRecord.DoctorGuid,
      treatedBy: `${privateRecord.DLastName}, ${privateRecord.DFirstName}`
    };
  }

  if (!privateRecord) {
    const prefsPractitionerGuid = getDefaultPrefs(currentUserDefaultPrefs?.content, prefsCodes.defaultPractitioner);
    if (prefsPractitionerGuid) {
      return {
        id: prefsPractitionerGuid,
        treatedBy:
          prefsPractitionerGuid === NA_DoctorGuid
            ? t('N_A')
            : prefsPractitionerGuid === multipleDoctorGuid
              ? t('Multiple')
              : `${user?.LastName}, ${user?.FirstName}`
      };
    }

    if (!prefsPractitionerGuid && user) {
      return {
        id: user?.DoctorGuid,
        treatedBy: `${user?.LastName}, ${user?.FirstName}`
      };
    }

    return {
      id: NA_DoctorGuid,
      treatedBy: t('N_A')
    };
  }
};
//===========================================================//
