import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { InputWrapper } from '../../../../../../../components/Inputs';
import { ClaimNumber as ClaimNumberInput } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { updateInitialStateWithClaimData } from '../../../helpers/updateInitialState';
import { t } from '../../../../../../../service/localization/i18n';

const ClaimNumber = () => {
  const name = inputs.claimNumber.name;
  const { control, setValue, isNew, localState, errors, clearErrors, watch, isDirty } = useFormContext();

  const onChange = (e) => {
    const _claimNumber = e.value?.value || e.value;
    const shouldDirty = e.onCreateNewClaim || !watch(inputs.patientClaims.name)?.WCB?.length ? { shouldDirty: true } : {};
    setValue(e.field, _claimNumber, shouldDirty);
  };

  const onUpdateInitialState = (claim) => {
    updateInitialStateWithClaimData({
      claim,
      errors,
      clearErrors,
      setValue,
      shouldDirty: !isDirty
    });
  };

  const claimNumberInput = (field) => {
    return (
      <InputWrapper name={field.name} label={t('Claim_Number')} footerContent={clearButton}>
        <ClaimNumberInput
          id={field.name}
          name={field.name}
          value={field.value}
          invoiceType="WSBC"
          calimNumbersList={watch(inputs.patientClaims.name) || {}}
          updateInitialState={(claim) => onUpdateInitialState(claim)}
          onChange={(claim) => onChange(claim)}
        />
      </InputWrapper>
    );
  };

  const clearButton = () => {
    return (
      <div className="flex justify-content-end w-full">
        <Button
          id={elementIDs.clearClaimDataButton}
          className="p-button-text py-0 px-1 small-text w-auto"
          label={t('Clear_claim_data')}
          type="button"
          tabIndex={-1}
          onClick={onClear}
        />
      </div>
    );
  };

  const onClear = () => {
    updateInitialStateWithClaimData({ errors, clearErrors, setValue });
    setValue(name, ''); // reset claim nubmer
    if (!isNew) setValue(inputs.claimGuid.name, localState.initialDataForEdit?.ClaimGuid); // reset claim guid on edit mode
  };

  return <Controller name={name} control={control} render={({ field }) => claimNumberInput(field)} />;
};

export default ClaimNumber;
