import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import CardTitle from '../../../modules/Layouts/CardTitle';

import { useGroupTemplate } from '../hooks/useGroupTemplate';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';

const PreviewTitle = () => {
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { setLocalState } = useFormContext();
  const { showSaveTemplateButton } = useGroupTemplate();

  const onCreateGroupInvoicesTemplate = () => {
    setLocalState((prevState) => ({ ...prevState, createGroupTemplateDialog: true }));
  };

  return (
    <div className="flex justify-content-between pb-2 text-2xl font-bold">
      <div style={{ width: '31px' }}></div>

      <CardTitle />

      {showSaveTemplateButton ? (
        <Button
          id={elementIDs.createTemplate}
          type="button"
          className="p-button-text p-button-outlined p-button-rounded"
          icon="pi pi-save"
          loading={isSavingClaims}
          tooltip={t('Create_Template')}
          tooltipOptions={{ position: 'top' }}
          onClick={() => onCreateGroupInvoicesTemplate(false)}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PreviewTitle;
