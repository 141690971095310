import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch, useFormContext } from 'react-hook-form';

import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';

import Practitioner from '../Inputs/Practitioner';
import PayeeNumber from '../Inputs/PayeeNumber';
import RuralCode from '../Inputs/RuralCode';
import LocationCode from '../Inputs/LocationCode';
import FacilitySection from './FacilitySection';

import { inputs } from '../../../helpers/inputs';
import { NA_DoctorGuid } from '../../../../../../config/defaultValuesConfig';
import { locationCodesDropdownOptions, ruralCodesDropdownOptions } from '../../../helpers/dropdownOptions';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { getSpeciality } from '../../../../../../config/specialitiesConfig';
import { showRuralCodeInput } from '../../../helpers/inputsConditions';
import { t } from '../../../../../../../service/localization/i18n';
import { validate } from 'uuid';

const PanelTitile = ({ collapsed }) => {
  const practitioners = useSelector((state) => state.clinic.members);
  const { isGettingPrefs } = useSelector((state) => state.preferences);
  const { watch, control, localState } = useFormContext();
  const payee = useWatch({ control, name: inputs.payeeNumber.name });
  const rural = useWatch({ control, name: inputs.ruralCode.name });
  const speciality = useWatch({ control, name: inputs.speciality.name });

  const getPayeeCode = () => (payee ? `#${payee}` : '');

  const getRuralCode = () => {
    const showRural = showRuralCodeInput(speciality);
    if (!showRural) return '';

    if (rural?.value && rural?.value !== 'N/A') {
      const ruralOption = ruralCodesDropdownOptions.find((i) => i.value === rural?.value);
      return ruralOption ? ruralOption.label : t('No_rural_code');
    }
    return t('No_rural_code');
  };

  const getPractitionerName = () => {
    const practitioner = practitioners?.find((i) => i.UserGuid === watch(inputs.practitioner.name));
    return practitioner ? `${practitioner.LastName}, ${practitioner.FirstName}` : 'N/A';
  };

  const getLocationLabel = () => {
    const locationOption = locationCodesDropdownOptions.find((i) => i.value === watch(inputs.locationCode.name));
    return locationOption ? locationOption.label : '';
  };

  const getFacilityLabel = () => (watch(inputs.facility.name) ? `${t('Fac')} ${watch(inputs.facility.name)}` : '');

  const getMemberSpecTitle = () => {
    const memeberSpecTitle = getSpeciality(speciality)?.label;
    return memeberSpecTitle ? `(${memeberSpecTitle})` : '';
  };

  const practitionerName = getPractitionerName();
  const location = getLocationLabel();
  const memberSpecTitle = getMemberSpecTitle();

  const getCodesDescription = () => {
    const payeeCode = getPayeeCode();
    const ruralCode = getRuralCode();
    const facility = getFacilityLabel();

    // Define an array to hold the items
    const items = [];

    // Push items to the array if they are available
    if (payeeCode) {
      items.push(payeeCode);
    }

    if (ruralCode) {
      items.push(ruralCode);
    }

    if (facility) {
      items.push(facility);
    }

    // Join the items with commas and create the final string
    return items.join(', ');
  };

  if (collapsed) {
    return (
      <>
        <div id={elementIDs.practitionerName} className="pt-1">
          {`${practitionerName} ${memberSpecTitle}`}
        </div>

        <div id={elementIDs.payeeAndRuralCodes} className="pt-3 font-light small-text">
          {localState.isLoading || isGettingPrefs ? <Skeleton width="200px" /> : `${getCodesDescription()}`}
        </div>

        <div id={elementIDs.locationText} className="pt-2 mt-1 font-light small-text">
          {localState.isLoading || isGettingPrefs ? <Skeleton width="200px" /> : location}
        </div>
      </>
    );
  }

  return (
    <div id={elementIDs.practitionerName} className="pt-1">
      {practitionerName}
    </div>
  );
};

const PractitionerSection = () => {
  const { watch, localState } = useFormContext();
  const [collapsed, setCollapsed] = useState(true);
  const practitionerGuid = watch(inputs.practitioner.name);

  // collapse init value
  useEffect(() => {
    if (practitionerGuid === NA_DoctorGuid) {
      // Expand panel if practitioner is N/A
      setCollapsed(false);
    } else {
      // Collapse panel if practitioner is valid
      validate(practitionerGuid) && setCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practitionerGuid]);

  const panelTitle = <PanelTitile collapsed={collapsed} />;

  return (
    <Panel
      id={elementIDs.teleplanPractitionerPanel}
      className="mt-3 mb-5 practitioner_section_panel"
      collapsed={collapsed}
      toggleable
      header={panelTitle}
      onToggle={(e) => setCollapsed(e.value)}
    >
      <div className="pt-3">
        <Practitioner />

        {!localState.isEmptyPractitioner && (
          <>
            <FacilitySection />

            <PayeeNumber />

            <RuralCode />

            <LocationCode />
          </>
        )}
      </div>
    </Panel>
  );
};

export default PractitionerSection;
