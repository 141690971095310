import React from 'react';
import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';

const EmergencyTooltipLayout = () => {
  // return (
  //   <div id={elementIDs.emergencyInfoDialog}>
  //     <p>{t("YES_premiums")}</p>
  //     <ul style={{ listStyleType: 'disc' }}>
  //       <li className='pb-2'>{t("the_physician_is_specially_called")}</li>
  //       <li>{t("the_service_is_emergency")}</li>
  //     </ul>
  //     <p>
  //       {t("The_applicable_fee_for_out-of-office_hours")}
  //     </p>
  //   </div>
  // )

  return (
    <div id={elementIDs.emergencyInfoDialog}>
      <p>{t('Select_this_button_if_all_is_true')}</p>

      <ul style={{ listStyleType: 'disc', rowGap: '5px' }}>
        <li className="pb-1">{t('The_MD_is_specially_called_to_see_the_patient')}</li>
        <li>{t('The_service_is_emergency_or_non_elective')}</li>
      </ul>

      <p>{t('We_will')}</p>

      <ul style={{ listStyleType: 'disc', rowGap: '5px' }}>
        <li>{t('Add_continuing_care_fee_items_and_calculate_the_units_when_applicable')}</li>
      </ul>

      <p>{t('If_MD_was_called_to_leave_immediately')}</p>

      <p>{t('Do_not_select_any_OOFHP_codes_manually')}</p>
    </div>
  );
};

export default EmergencyTooltipLayout;
