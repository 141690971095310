import { InputNumber } from 'primereact/inputnumber';
import { t } from '../../../../../service/localization/i18n';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { rowHeight } from './columns';
import { onEditorValueChange } from './onEditorValueChange';
import { elementIDs } from '../../../../config/elementIDsConfig';
import cx from 'classnames';

export const feeEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Fee')}</span>
          <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ height: rowHeight }}>
            {currencyFormat(records[params.rowIndex]?.amount)}
          </div>
        </div>
      )}

      {records[params.rowIndex]?.id !== '-1' && (
        <>
          <span className="p-column-title">{t('Fee')}</span>
          <InputNumber
            id={elementIDs.feeInput}
            inputId="currency-us"
            value={records[params.rowIndex]?.amount}
            mode="currency"
            currency="USD"
            locale="en-US"
            style={{ height: rowHeight }}
            inputStyle={{ width: '8em' }}
            autoFocus
            onChange={(e) => onEditorValueChange(params, e.value, 'amount', records, setRecords, setIsReviewDirty)}
          />
        </>
      )}
    </div>
  );
};
