import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useClaimNotes from '../../ClaimDetails/useClaimNotes';
import NoteDetails from '../../../../mails/components/NoteDetails';
import { rowActionsMenuItems, viewProfile } from '../../ClaimNotesTable/helpers/rowActionsMenuItems';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../../routes/routes';
import { editClaimNote, getClaimNoteDetails } from '../../../actions/claims.action.creators';
import { setClaimNoteDetails } from '../../../../notes/actions/actions';

const ClaimNoteDetails = ({ isReply = false, isEdit = false }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { onReplyNote, fetchAllNotes, onDeleteNote } = useClaimNotes();
  const claimNotes = useSelector((state) => state.notes.claimNotes);
  const note = useSelector((state) => state.notes.claimNoteDetails);
  const [loading, setLoading] = useState(false);

  const getNoteDetails = async () => {
    setLoading(true);
    const result = await getClaimNoteDetails(id);
    setLoading(false);
    if (result) dispatch(setClaimNoteDetails(result));
  };

  const fetchNotes = async (fetchProps) => {
    setLoading(true);
    await fetchAllNotes(fetchProps);
    setLoading(false);
  };

  const handleDeleteNote = async (noteGuid) => {
    setLoading(true);
    await onDeleteNote(noteGuid);
    setLoading(false);
    history.replace(routes.claimNotesInbox.path);
  };

  const onEditNote = async (note) => {
    await editClaimNote(note);
    await getNoteDetails();
  };

  const onReplyClick = () => history.push(`${routes.claimNotesInbox.path}/reply`);

  return (
    <NoteDetails
      inboxType="claim"
      note={note}
      notes={claimNotes}
      loading={loading}
      isReply={isReply}
      isEdit={isEdit}
      onViewVlaimProfile={viewProfile}
      onEditNote={onEditNote}
      fetchNotes={fetchNotes}
      onReplyClick={onReplyClick}
      onSaveReply={onReplyNote}
      onDeleteNote={handleDeleteNote}
      getNoteDetails={getNoteDetails}
      setNoteDetails={setClaimNoteDetails}
      rowActionsMenuItems={rowActionsMenuItems}
    />
  );
};

export default ClaimNoteDetails;
