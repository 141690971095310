import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';

import Steps from './modules/Steps/Steps';
import Dialogs from './modules/Dialogs/Dialogs';

import { updateWSBCRecord } from '../../actions/claims.action.creators';
import { useFormErrorAutoscroll } from '../../../hooks/useFormErrorAutoscroll';
import { getInitialValues } from './helpers/getInitialValues';
import { inputs } from './helpers/inputs';
import { useDefaultValues } from './hooks/useDefaultValues';
import { isEmpty } from 'lodash';

import './WSBCReport.scss';

const WSBCReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const initValues = useDefaultValues();
  const { wsbcRecord } = useSelector((state) => state.claims);

  // Autoscroll and set focus to invalid input
  useFormErrorAutoscroll({ errors: initValues.errors, inputs, setFocus: initValues.setFocus });

  useEffect(() => {
    // Edit WSBCR
    if (!initValues.isNew) {
      return getInitialValues({ reportId: params.id, setLocalState: initValues.setLocalState, reset: initValues.reset });
    }

    // Create WSBCR
    if (initValues.isNew) {
      // Empty form
      if (isEmpty(wsbcRecord.formState)) return initValues.setEmptyForm();

      // Pre-filled form
      if (!isEmpty(wsbcRecord.formState)) return initValues.setPrefilledForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues.isNew]);

  // Unmount component
  useEffect(() => {
    return () => {
      // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
      initValues?.formState?.resetWSBCR && dispatch(updateWSBCRecord({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...initValues}>
      <Steps />
      <Dialogs />
    </FormProvider>
  );
};

export default WSBCReport;
