import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { setClaimFiltersWithRequest } from '../../claims/actions/claims.action.creators';
import { setClaimFilters, setSelectedCodes } from '../../claims/actions/claims.action.creators';

import classes from './InputWithReset.module.scss';
import cx from 'classnames';

const InputWithReset = ({
  children,
  fieldId,
  filterValue,
  customPeriod,
  setCustomPeriod,
  onFocus,
  setDiffError,
  invalidFields,
  setInvalidFields,
  showReset = true
}) => {
  const dispatch = useDispatch();

  const showResetButton = filterValue === '' || filterValue === '-1' || filterValue === '-2' || filterValue === undefined ? false : true;

  const resetInput = () => {
    switch (fieldId) {
      case 'Period':
        if (customPeriod.from?.length > 0 || customPeriod.to?.length > 0) {
          setCustomPeriod({ from: '', to: '' });
          dispatch(setClaimFilters('', 'PeriodFrom'));
          dispatch(setClaimFilters('', 'PeriodTo'));
        }
        dispatch(setClaimFilters('', 'ServiceDate'));
        dispatch(setClaimFilters('-2', fieldId));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        if (invalidFields.from || invalidFields.to) setInvalidFields({ ...invalidFields, from: false, to: false });
        break;

      case 'PeriodFrom':
        setDiffError(false);
        setCustomPeriod({ ...customPeriod, from: '' });
        setInvalidFields({ ...invalidFields, from: false });
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;

      case 'PeriodTo':
        setDiffError(false);
        setCustomPeriod({ ...customPeriod, to: '' });
        setInvalidFields({ ...invalidFields, to: false });
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;

      case 'SubmissionPeriod':
        if (customPeriod.submissionFrom?.length > 0 || customPeriod.submissionTo?.length > 0) {
          setCustomPeriod({ submissionFrom: '', submissionTo: '' });
          dispatch(setClaimFilters('', 'SubmissionPeriodFrom'));
          dispatch(setClaimFilters('', 'SubmissionPeriodTo'));
        }
        dispatch(setClaimFilters('', 'SubmissionDate'));
        dispatch(setClaimFilters('-2', fieldId));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        if (invalidFields.submissionFrom || invalidFields.submissionTo)
          setInvalidFields({
            ...invalidFields,
            submissionFrom: false,
            submissionTo: false
          });
        break;

      case 'RemittancePeriod':
        if (customPeriod.remittanceFrom?.length > 0 || customPeriod.remittanceTo?.length > 0) {
          setCustomPeriod({ remittanceFrom: '', remittanceTo: '' });
          dispatch(setClaimFilters('', 'RemittancePeriodFrom'));
          dispatch(setClaimFilters('', 'RemittancePeriodTo'));
        }
        dispatch(setClaimFilters('', 'RemittanceDate'));
        dispatch(setClaimFilters('-2', fieldId));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        if (invalidFields.remittanceFrom || invalidFields.remittanceTo)
          setInvalidFields({
            ...invalidFields,
            remittanceFrom: false,
            remittanceTo: false
          });
        break;

      case 'DoctorGuid':
        dispatch(setClaimFilters('', 'DoctorName'));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;

      case 'FeeCode':
        dispatch(setSelectedCodes([], fieldId));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;

      case 'ICD9Code':
        dispatch(setSelectedCodes([], fieldId));
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;

      default:
        dispatch(setClaimFiltersWithRequest('', fieldId));
        break;
    }
  };

  return (
    <>
      {showReset ? (
        <div className="flex" onFocus={onFocus}>
          {children}
          {showResetButton ? (
            <Button className={cx('p-button-text p-button-danger', classes.clearButton)} icon="pi pi-undo" tabIndex={-1} onClick={resetInput} />
          ) : (
            <div className={classes.blankClearButton} />
          )}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default InputWithReset;
