import { Dropdown } from 'primereact/dropdown';
import { salesTaxList } from './salesTaxList';
import { onEditorValueChange } from './onEditorValueChange';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { t } from '../../../../../service/localization/i18n';
import { rowHeight } from './columns';
import { elementIDs } from '../../../../config/elementIDsConfig';

export const salesTaxEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  const value = salesTaxList?.find((i) => i.name === params?.rowData?.salesTax);

  return (
    <div className="flex align-items-center" style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}>
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className="flex align-items-center" style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Tax').toUpperCase()}</span>
          <div className="flex align-items-center" style={{ height: rowHeight }}>
            {value?.text}
          </div>
        </div>
      )}

      {records[params.rowIndex]?.id !== '-1' && (
        <>
          <span className="p-column-title">{t('Tax').toUpperCase()}</span>
          <Dropdown
            className="salesTaxEditor"
            id={elementIDs.salesTaxInput}
            value={value}
            optionLabel="label"
            options={salesTaxList}
            style={{ height: rowHeight }}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => onEditorValueChange(params, e.value?.name, 'salesTax', records, setRecords, setIsReviewDirty)}
          />
        </>
      )}
    </div>
  );
};
