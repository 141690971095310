import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from './CircularProgress/CircularProgress';

const Loader = () => {
  const { loading } = useSelector((state) => state.core);
  const { deletingClaim, savingReceivePayment, deletingPayment, sendingByEmail } = useSelector((state) => state.claims);
  const showLoader = loading || savingReceivePayment || deletingPayment || deletingClaim || sendingByEmail;

  return <>{showLoader && <CircularProgress />}</>;
};

export default Loader;
