import React from 'react';
import { Tooltip } from 'primereact/tooltip';

const CommonChip = ({ data, onDelete, key, displayFieldValue = 'value', showTooltip = false }) => {
  return (
    <div key={key ? key : data?.value}>
      {showTooltip && <Tooltip target=".code" position="top" />}

      <div className="p-chips flex px-0 pb-2 code" data-pr-tooltip={data?.label}>
        <ul className="p-chips-multiple-container block py-0 px-1">
          <li className="p-chips-token p-highlight">
            <span className="p-chips-token-label font-normal">{data[displayFieldValue]}</span>
            <span className="p-chips-token-icon pi pi-times-circle" onClick={() => onDelete(data)} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CommonChip;
