export const patientDetailsTabs = {
  profile: 0,
  appointments: 'appointments',

  //Money
  money: 1,
  invoices: 'invoices',
  payments: 'payments',

  //EMR
  lab: 'lab',
  charts: 'charts',
  reports: 'reports',

  //Notes
  newNote: 'new_note',
  notes: 'notes'
};
