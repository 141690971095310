import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { defaultValues } from '../../config/defaultValues';
import { useEClaimPolicy } from '../../hooks/useEClaimPolicy';
import { t } from '../../../../../service/localization/i18n';

const DeleteEClaimPolicyConfirm = () => {
  const { state, setState } = usePatientPoliciesContext();
  const { onDeleteEClaimPolicy } = useEClaimPolicy();

  const onHide = () => {
    setState((prevState) => ({
      ...prevState,
      deleteEClaimPolicy: defaultValues.deleteEClaimPolicy
    }));
  };

  const onAccept = () => {
    const policyForDeletion = state.deleteEClaimPolicy.policy;
    onDeleteEClaimPolicy(policyForDeletion);
    onHide();
  };

  return (
    <ConfirmDialog
      visible={state.deleteEClaimPolicy.showDialog}
      message="Are you sure you want to proceed?"
      header={t('Confirmation')}
      icon="pi pi-exclamation-triangle"
      acceptClassName="p-button-danger"
      rejectClassName="p-button-outlined"
      onHide={onHide}
      accept={onAccept}
      reject={onHide}
    />
  );
};

export default DeleteEClaimPolicyConfirm;
