import React from 'react';
import TextAreaInput from './Common/TextAreaInput';
import { inputs } from '../../../helpers/inputs';

export const priorProblemsMaxLength = 160;

const PriorProblems = () => {
  return <TextAreaInput input={inputs.priorProblems} maxLength={priorProblemsMaxLength} />;
};

export default PriorProblems;
