import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getPresetPrefs } from '../../../helpers/getDefaultPrefs';
import { NA_DoctorGuid, multipleDoctorGuid } from '../../../../config/defaultValuesConfig';
import { savePrefs } from '../../../../preferences/actions/preferences.action.creators';
import { inputs } from '../helpers/inputs';
import { unionBy } from 'lodash';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { t } from '../../../../../service/localization/i18n';

export const usePreferences = () => {
  const dispatch = useDispatch();
  const { localState, setLocalState, getValues } = useFormContext();

  const isLocked = (value, defaultCode) => {
    const prefs = localState?.practitionerPrefs?.content;
    const currentPrefs = getPresetPrefs(prefs, defaultCode);
    if (!currentPrefs) return false;
    return currentPrefs?.toUpperCase() === value?.toUpperCase();
  };

  const onSavePreferences = async (value, defaultCode) => {
    const practitioner = getValues(inputs.practitioner.name);
    const preferences = [{ value, label: defaultCode }];
    const prefs = { content: preferences, label: 'default', value: practitioner };

    const results = await savePrefs(prefs);

    if (results) {
      setLocalState((prevState) => {
        const currentPrefs = prevState.practitionerPrefs?.content || [];
        const updatedPrefs = results?.content || [];
        const unionPrefs = unionBy(currentPrefs, updatedPrefs, 'label'); // Add prefs that are missing in the currentPrefs
        const content = unionPrefs?.map((i) => updatedPrefs?.find((x) => x.label === i.label) || i);

        return {
          ...prevState,
          practitionerPrefs: {
            ...prevState.practitionerPrefs,
            content
          }
        };
      });
    }
  };

  return { isLocked, onSavePreferences };
};
