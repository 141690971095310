import React from 'react';

import { Divider } from 'primereact/divider';

import Context from '../../../../../../Context';
import BillingInformation from './Sections/BillingInformation';
import ClinicalInformation from './Sections/ClinicalInformation';
import DemographicsInfo from './Sections/DemographicsInfo';
import Header from './Sections/Header';
import InjuryCodes from './Sections/InjuryCodes';
import ReturnToWork from './Sections/ReturnToWork';

const WSBCReportPreviewContent = ({ data = {}, loading }) => {
  const contextValue = { data };

  if (loading) {
    return <span className="text-2xl">Loading...</span>;
  }

  return (
    <Context.Provider value={contextValue}>
      <Header />
      <Divider />
      <DemographicsInfo />
      <InjuryCodes />
      <ClinicalInformation />
      <ReturnToWork />
      <BillingInformation />
    </Context.Provider>
  );
};

export default WSBCReportPreviewContent;
