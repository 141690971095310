import React from 'react';
import { useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useValidation } from '../../../hooks/useValidation';
import { useConditions } from '../../../hooks/useConditions';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const LastName = () => {
  const { watch, register, errors, isView } = useFormContext();
  const { prescriberLastNameValidation } = useValidation();
  const { prescriberLastNameRequired } = useConditions();
  const prescriberType = watch(inputs.prescriberType.name);
  const disabled = isView || prescriberType === 'N/A';

  if (!prescriberLastNameRequired) return null;

  return (
    <InputWrapper
      required={!isView && prescriberLastNameRequired}
      name={inputs.lastName.name}
      label={inputs.lastName.label}
      errorMessage={errors[inputs.lastName.name]?.message}
    >
      <InputText
        id={inputs.lastName.name}
        disabled={disabled}
        maxLength={40}
        className={cx({ 'p-invalid': errors[inputs.lastName.name]?.message })}
        {...register(inputs.lastName.name, {
          validate: (value) => prescriberLastNameValidation(value)
        })}
      />
    </InputWrapper>
  );
};

export default LastName;
