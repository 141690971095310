import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import './SelectionCard.scss';

// interface ISelectionCard {
//   children?: React.ReactNode,
//   isSelected?: boolean
//   menuModel?: {
//      label: string,
//      icon?: string,
//      command?: (e) => void
//   }[]
// }

const SelectionCard = ({ children, isSelected, menuModel }) => {
  const menu = useRef(null);

  return (
    <div className={isSelected ? 'selection_card_active' : 'selection_card_inactive'}>
      {children}

      {menuModel && (
        <div className="flex align-items-start h-full">
          <Menu model={menuModel} popup ref={menu} id={`popup_menu`} />
          <Button
            className="p-button-rounded p-button-text"
            type="button"
            icon="pi pi-ellipsis-v"
            aria-controls={`popup_menu`}
            aria-haspopup
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )}
    </div>
  );
};

export default SelectionCard;
