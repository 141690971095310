import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { localStateInitValues } from '../helpers/eClaimInitialState';
import { getDefaultValues } from '../helpers/defaultValues';
import { useSubmit } from './useSubmit';
import { getInsurancePolicyInfo } from '../../../actions/claims.action.creators';
import { inputs } from '../helpers/inputs';
import { getPatientInfo } from '../../../../../service/Lookup';
import { getPromiseAll } from '../../../../utils/getPromiseAll';

export const useDefaultValues = ({ policyInfo = {}, ...props }) => {
  const params = useParams();
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const currentUserDefaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'default');
  const policyGuid = props?.policyGuid || params.policyGuid;
  const patientGuid = props?.patientGuid || params.patientGuid;
  const isNew = !policyGuid && !patientGuid;
  const isEdit = props.isEdit || (policyGuid && patientGuid);
  const isNewForPatient = !policyGuid && (patientGuid || policyInfo?.[inputs.patient.name]?.length);
  const initValues = getDefaultValues({ initValues: policyInfo });

  const [localState, setLocalState] = useState({
    ...localStateInitValues,
    showCatalogs: { ...localStateInitValues.showCatalogs, desktop: !isEdit }
  });

  const methods = useForm({ defaultValues: initValues });

  useEffect(() => {
    getDefaulValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaulValues = async () => {
    const policyInfoPatients = policyInfo?.[inputs.patient.name] || [];
    const patients = Array.isArray(policyInfoPatients) ? policyInfoPatients : [policyInfoPatients];

    // Do not make patient info request if there is no patientGuid provided in the props or URL parameters
    const shouldGetPatientInfo = patientGuid && !policyGuid && !patients?.length;

    // Do not make request for policy info if no policyGuid and patientGuid
    const shouldGetInsurancePolicyInfo = policyGuid && patientGuid;

    if (!shouldGetPatientInfo && !shouldGetInsurancePolicyInfo) return;

    const insurancePolicyInfoRequest = shouldGetInsurancePolicyInfo
      ? {
          insurancePolicyInfo: getInsurancePolicyInfo({ policyGuid, patientGuid })
        }
      : {};

    const patientID = patientGuid || policyInfo?.[inputs.patientGuid.name];
    const patientInfoRequest = shouldGetPatientInfo ? { patientInfo: getPatientInfo(patientID) } : {};

    const requests = {
      ...insurancePolicyInfoRequest,
      ...patientInfoRequest
    };

    setLocalState((prevState) => ({ ...prevState, isLoading: true }));
    // Execute all requests concurrently and merge results into a single object
    const results = await getPromiseAll(requests);
    setLocalState((prevState) => ({ ...prevState, isLoading: false }));

    const patientInfo = patientGuid ? [results.patientInfo] : [];
    const insurancePolicyInfo = policyGuid && patientGuid ? results.insurancePolicyInfo : initValues;
    const updatedValues = {
      ...insurancePolicyInfo,
      [inputs.patient.name]: patientInfo
    };

    // Update form state
    methods.reset(updatedValues);
  };

  const context = {
    ...props,
    isNew,
    isEdit,
    isNewForPatient,
    currentUserDefaultPrefs,
    localState,
    setLocalState,
    errors: methods.formState.errors,
    isDirty: methods.formState.isDirty,
    ...methods
  };

  const onSubmitActions = useSubmit(context);

  return { ...context, ...onSubmitActions };
};
