import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';

import TextInput from './TextInput';
import DateInput from './DateInput';
import ProvinceFilter from './ProvinceFilter';
import IsDeceasedFilter from './IsDeceasedFilter';
import FacilityNameFilter from './FacilityNameFilter';
import CommonInputField from '../../../../common/components/CommonInputField';
import CircularProgress from '../../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { columnValues } from '../helpers/columnValues';
import { toggleFilters } from '../../../../core/actions/core.action.creators';
import { t } from '../../../../../service/localization/i18n';
import classes from './SidebarFilters.module.scss';
import cx from 'classnames';

const SidebarFilters = ({ filters }) => {
  const dispatch = useDispatch();
  const { showFiltersSidebar } = useSelector((state) => state.core);
  const { isFetchingPatients, totalRecords } = useSelector((state) => state.patients);
  const fieldWrapStyles = { height: '80px' };

  const closeSidebar = () => dispatch(toggleFilters(!showFiltersSidebar));

  const actionButtonst = () => {
    if (filters.length) {
      return (
        <div className="flex">
          <Button className="w-full" label={String.format(t('Show_x_records'), totalRecords)} disabled={isFetchingPatients} onClick={closeSidebar} />
        </div>
      );
    }

    return <Button className="p-button-outlined w-full" label={t('Close')} onClick={closeSidebar} />;
  };

  return (
    <div className={cx('sidebarFilters', classes.rootWrap)}>
      <form className="p-fluid mt-3">
        <CommonInputField label={columnValues.lastName.header} style={fieldWrapStyles}>
          <TextInput columnKey="lastName" />
        </CommonInputField>

        <CommonInputField label={columnValues.province.header} style={fieldWrapStyles}>
          <ProvinceFilter showInSidebar />
        </CommonInputField>

        <CommonInputField label={columnValues.phn.header} style={fieldWrapStyles}>
          <TextInput columnKey="phn" />
        </CommonInputField>

        <CommonInputField label={columnValues.dob.header} style={fieldWrapStyles}>
          <DateInput columnKey="dob" />
        </CommonInputField>

        <CommonInputField label={t('MRP')} style={fieldWrapStyles}>
          <TextInput columnKey="mrpNumber" />
        </CommonInputField>

        <CommonInputField label={t('FacilityName')} style={fieldWrapStyles}>
          <FacilityNameFilter />
        </CommonInputField>

        <CommonInputField label={t('Deceased')} style={fieldWrapStyles} direction="row">
          <IsDeceasedFilter />
        </CommonInputField>
      </form>

      <div className={classes.actionButtonsWrap}>{actionButtonst()}</div>

      {isFetchingPatients && <CircularProgress />}
    </div>
  );
};

export default SidebarFilters;
