import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Card } from 'primereact/card';
import TitleLayout from './TitleLayout';
import StepsWrap from '../../modules/Steps/StepsWrap';

import EOBLink from './EOBLink';
import Policies from './Policies';
import StepTwoWrap from '../../modules/Steps/StepTwoWrap';
import Services from '../../modules/EClaimForm/Layouts/Services';
import ServicePicker from '../../modules/ServicePicker/ServicePicker';
import PreviewActionButtons from '../../modules/ActionButtons/PreviewActionButtons';
import CoordinationOfBenefits from '../../modules/EClaimForm/Layouts/CoordinationOfBenefits';

import { inputs } from '../../helpers/inputs';

const PreviewEClaim = () => {
  const { control } = useFormContext();
  const fieldArrayMethods = useFieldArray({ control, name: inputs.services.name });

  return (
    <StepsWrap>
      <Card className="w-full container">
        <div className="flex flex-column gap-4 eClaimForm">
          <TitleLayout />
          <Policies />
          <StepTwoWrap>
            <Services />
            <ServicePicker fieldArrayMethods={fieldArrayMethods} />
            <CoordinationOfBenefits />
            <EOBLink />
            <PreviewActionButtons />
          </StepTwoWrap>
        </div>
      </Card>
    </StepsWrap>
  );
};

export default PreviewEClaim;
