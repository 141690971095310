import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { useGroupPickerInputs } from '../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { localStorageKeys } from '../../../../../config/localStorageKeysConfig';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useStorage } from '../../../../../hooks/useStorage';
import { t } from '../../../../../../service/localization/i18n';

const ApplyToAllReferralDialog = () => {
  const key = localStorageKeys.doNotShowApplyToAllReferralDialog;
  const { localState, setLocalState } = useFormContext();
  const { setLatestReferral, setSameReferral } = useGroupPickerInputs();
  const [checked, setChecked] = useState(false);
  const [localStorageValue, setValueInStorage] = useStorage(key);

  useEffect(() => {
    setChecked(localStorageValue);
  }, [localStorageValue]);

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, applyToAllReferralDialog: { showDialog: false, option: '' } }));
  };

  const onAccept = () => {
    onHideDialog();
    if (localState.applyToAllReferralDialog.option === 'sameReferral') return setSameReferral();
    if (localState.applyToAllReferralDialog.option === 'latestReferral') return setLatestReferral();
  };

  const handleCheck = (checked) => {
    setChecked(checked);
    setValueInStorage(checked);
  };

  const footerLayout = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onAccept} autoFocus />

        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onHideDialog} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.applyToAllReferralDialog}
      header={t('Warning')}
      visible={localState.applyToAllReferralDialog.showDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={footerLayout()}
      onHide={onHideDialog}
    >
      {t('Your_entered_data_might_be_lost_or_changed')}

      <div className="p-field-checkbox pt-4">
        <Checkbox className="mr-3" type="checkbox" inputId="dontShowThisAgainToday" checked={checked} onChange={(e) => handleCheck(e.checked)} />
        <label htmlFor="dontShowThisAgainToday">{t('Dont_show_this_again_today')}</label>
      </div>
    </Dialog>
  );
};

export default ApplyToAllReferralDialog;
