// import { replace } from 'lodash';
// import { isNumeric } from './isNumeric';

// export function formatPHN(num) {
//   if (num && isNumeric(num[0])) {
//     const subject = replace(num, /[\s_]/g, '');
//     return subject.substring(0, 4) + ' ' + subject.substring(4, 7) + ' ' + subject.substring(7, 10);
//   }

//   return num;
// }

import { replace } from 'lodash';
import { isNumeric } from './isNumeric';

export function formatPHN(num, province) {
  if (num && isNumeric(num[0])) {
    const cleanedNum = replace(num, /[\s_]/g, '');

    if (province === 'BC') {
      return cleanedNum.substring(0, 4) + ' ' + cleanedNum.substring(4, 7) + ' ' + cleanedNum.substring(7, 10);
    } else {
      return cleanedNum.substring(0, 4) + ' ' + cleanedNum.substring(4, 8) + ' ' + cleanedNum.substring(8, 12);
    }
  }

  return num;
}
