import React from 'react';
import ClaimsDataTable from './modules/table/ClaimsDataTable';
import ClaimsTableProvider from './ClaimsTableProvider/ClaimsTableProvider';

// interface IClaimsTable {

// }

const ClaimsTable = (props) => {
  return (
    <ClaimsTableProvider {...props}>
      <ClaimsDataTable />
    </ClaimsTableProvider>
  );
};

export default ClaimsTable;
