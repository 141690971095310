import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { usePayor } from '../../../hooks/usePayor';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const Payor = () => {
  const name = inputs.payor.name;
  const { control } = useFormContext();
  const { options, onChange, isLocked, onSavePreferences } = usePayor();

  const payorInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.payor.label} isLocked={isLocked} onSavePrefs={onSavePreferences}>
        <Dropdown id={elementIDs.payorDropdown} type="text" value={field.value} options={options} onChange={(e) => onChange(e, field)} />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => payorInput(field)} />;
};

export default Payor;
