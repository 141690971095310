import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import ModulesTable from './ModulesTable/ModulesTable';
import EntitiesTable from './EntitiesTable/EntitiesTable';
import { t } from '../../../../service/localization/i18n';
import { getRoleById } from '../../actions/roles.action.creators';
import { setRoleDetails, clearRoleDetails } from '../../actions/actions';

const RoleDetails = () => {
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.roles.roleDetails);

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  const fetchRole = useCallback(async () => {
    setLoading(true);
    const result = await getRoleById(id);
    if (result) dispatch(setRoleDetails(result));
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchRole();

    return () => {
      dispatch(clearRoleDetails());
    };
  }, [fetchRole, dispatch]);

  return (
    <div className="flex col-12 p-0 justify-content-center">
      {!loading ? (
        <div className="containerXL subMenuRoot mt-4 px-3 w-full">
          <h2>Role Details</h2>
          <h3>{role?.RoleName}</h3>
          <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
            <TabPanel header="Modules" headerClassName="extra-large-text">
              <ModulesTable modules={role.Modules} />
            </TabPanel>
            <TabPanel header="Entities" headerClassName="extra-large-text">
              <EntitiesTable entities={role?.Entities} />
            </TabPanel>
          </TabView>
          <div className="flex gap-2 justify-content-center">
            <Button onClick={() => history.push('/roles')} label="Close" outlined />
            <Button label="Edit" onClick={() => history.push(`/edit-role/${id}`)} />
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default RoleDetails;
