import { isTimeValid, timeDiff } from '../../../../utils/formatTime';

// interface {
//   feeCode: {
//     min_per_unit: number,
//     max_unit: number,
//     portion: number
//   },
//   startTime: string,
//   endTime: string
// }

const delaysList = [15, 45];

export const calculateUnits = (feeCode, startTime, endTime, curentUnits) => {
  if (!feeCode) return curentUnits;
  const isValidTime = isTimeValid(startTime) && isTimeValid(endTime);
  const duration = timeDiff(startTime, endTime);
  let units = curentUnits;

  if (!feeCode.min_per_unit) units = 1;

  if (isValidTime && duration > 0 && feeCode.min_per_unit > 0) {
    units = Math.floor(duration / feeCode.min_per_unit);
    const extra = duration % feeCode.min_per_unit;

    if (extra >= feeCode.portion) {
      units++;
    }

    if (feeCode.max_unit && feeCode.max_unit !== -1 && units > feeCode.max_unit) {
      units = feeCode.max_unit;
    }
  }

  return units;
};

export const calculationOfWholeNumberOfUnits = ({ startTime, endTime, rowData }) => {
  // VER-376 - Show calculation of whole number of units + leftover time
  const isValidTime = isTimeValid(startTime) && isTimeValid(endTime);
  let delay = Number(rowData.delay);
  const isDelayOneOf = delaysList.includes(delay);
  console.log('calculationOfWholeNumberOfUnits: ', rowData);
  if (rowData.cc_flag === '1') delay = delay - rowData.per_unit; // VER-698

  if (!rowData || !rowData.per_unit || !isValidTime || (!isDelayOneOf && delay > 0)) return '';
  const min_per_unit = Number(rowData.per_unit);
  const getDuration = timeDiff(startTime, endTime);
  const duration = delay && delay > 0 ? getDuration - delay + 15 : getDuration;
  const safe_max = rowData.max_unit ?? -1;
  const max_units = safe_max === -1 ? 3600 : safe_max;
  const whole_units = Math.floor(Number(duration) / min_per_unit);
  const real_units = Math.min(whole_units, max_units);
  const remainder = Number(duration) % min_per_unit;
  let result = '';

  if (rowData.max_unit === 1) return '';

  if (duration >= min_per_unit) {
    result = `${min_per_unit} min x ${real_units}`;
  }

  if (remainder > 0) {
    if (duration > min_per_unit) {
      result += ` + ${remainder} min`;
    } else {
      result += `${remainder} min`;
    }

    if (remainder >= rowData.portion) {
      result += ` x 1`;
    } else {
      result += ` x 0`;
    }
  }

  return result;
};

export const calculateRefactoryPeriod = ({ rowData }) => {
  const delay = Number(rowData.delay);
  const isDelayOneOf = delaysList.includes(delay);

  if (!isDelayOneOf) return '';

  const refactoryPeriod = Number(rowData.delay) - Number(rowData.portion);
  return refactoryPeriod;
};
