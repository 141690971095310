import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BillingTotals from './BillingTotals';
import NumberOfClaims from './NumberOfClaims';
import NumberOfPatients from './NumberOfPatients';
import ChartByType from './ChartByType';
import ChartByPayeeNumber from './ChartByPayeeNumber';
import ChartByStatus from './ChartByStatus';
import ChartByPractitionerNumber from './ChartByPractitionerNumber';

import { getCashFlow, setCashFlowData } from '../../actions/cashFlow.axtion.creators';

const Charts = () => {
  const dispatch = useDispatch();
  const requestParams = useSelector((state) => state.cashFlow.requestParams);

  const fetchData = async () => {
    const responseData = await getCashFlow(requestParams);
    dispatch(setCashFlowData(responseData));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="charts-wrap">
      <BillingTotals />
      <NumberOfClaims />
      <NumberOfPatients />
      <ChartByType />
      <ChartByStatus />
      <ChartByPayeeNumber />
      <ChartByPractitionerNumber />
    </div>
  );
};

export default Charts;
