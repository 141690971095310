import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FilterMatchMode } from '../../../config/enums';

import { loginAsAffiliate, logout, updateUser } from '../../actions/auth.actions.creators';
import { getSpeciality } from '../../../config/specialitiesConfig';
import { t } from '../../../../service/localization/i18n';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';

const LoginAsView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState(null);
  const [filters, setFilters] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { affiliates, logoutRequest, gettingAffiliates, loginAsAffiliateRequest } = useSelector((state) => state.user);
  const emptyMessage = gettingAffiliates ? `${t('Loading')}...` : t('No_data_found');

  const handleLogout = () => {
    try {
      dispatch(logout());
    } catch (e) {
      console.log(e);
    }

    sessionStorage.removeItem('bureau');
    sessionStorage.removeItem('bau');

    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  };

  const onLogin = async (rowData) => {
    const params = {
      DCNGuid: rowData.DCNGuid,
      UserGuid: rowData.DoctorGuid,
      Key: rowData.ImpersonateKey
    };
 
    const user = await loginAsAffiliate(params);
    if (user) dispatch(updateUser(user));
    // Navigate to dashboard
    history.push('/');
  };

  const onRowSelect = (rowData) => {
    setSelectedRow(rowData);
    onLogin(rowData);
  };

  const nameTemplate = (rowData) => {
    return `${rowData['LastName']}, ${rowData['FirstName']}`;
  };

  const specTemplate = (rowData) => {
    const spec = rowData['Speciality'];
    const specDetails = getSpeciality(spec) || { label: '' };
    return `${specDetails.label.toUpperCase()}`;
  };
  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const tableHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText style={{ minWidth: '300px' }} value={globalFilterValue} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
        </span>
      </div>
    );
  };

  return (
    <>
      {loginAsAffiliateRequest && <CircularProgress />}

      <div style={{ height: 'calc(90vh - 100px)' }}>
        <DataTable
          value={affiliates?.length ? affiliates : []}
          scrollable
          scrollHeight="flex"
          responsiveLayout="scroll"
          emptyMessage={emptyMessage}
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => onRowSelect(e.value)}
          sortField="LastName"
          sortOrder={1}
          header={tableHeader}
          filters={filters}
          globalFilterFields={['DataCenterNumber', 'PractitionerNumber', 'LastName', 'FirstName']}
        >
          <Column field="DataCenterNumber" header={t('DataCenterNumber')} sortable style={{ minWidth: '205px', maxWidth: '300px', height: '55px' }} />

          <Column field="LastName" header={t('Name')} sortable body={nameTemplate} style={{ minWidth: '350px', maxWidth: '350px', height: '55px' }} />

          <Column
            field="Speciality"
            header={t('Speciality')}
            sortable
            style={{ minWidth: '205px', maxWidth: '300px', height: '55px' }}
            body={specTemplate}
          />
        </DataTable>
      </div>

      <div className="flex justify-content-end pt-4">
        <Button type="button" label={t('Logout')} loading={logoutRequest} disabled={loginAsAffiliateRequest} onClick={handleLogout} />
      </div>
    </>
  );
};

export default LoginAsView;
