import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table from '../../../../components/Table/Table';
import SidebarFilters from './filters/SidebarFilters';
import ActionButtons from './rowTemplates/ActionButtons';
import MobileRowTemplate from './MobileRowTemplate';
import DefaultSortOrderButton from './DefaultSortOrderButton';
import DesktopRowExpansionTemplate from './DesktopRowExpansionTemplate';
import PageWithCard from '../../../../components/Wraps/PageWrap/PageWithCard';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';
import CommonConfirmDialog from '../../../common/components/CommonConfirmDialog';

import { formatDataForChips } from '../../../../components/Table/helpers/formatDataForChips';
import { rowActionsMenuItems } from './helpers/rowActionsMenuItems';
import { columns } from './helpers/columns';
import { columnsForPDF, recordsForExcel } from './helpers/exportData';
import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { defaultPageSize, showInCardPageSize, dataTablesParams } from '../../../config/defaultValuesConfig';
import { localStorageKeys } from '../../../config/localStorageKeysConfig';
import { getPatients, deletePatient, setPatientIdForHighlightInTable } from '../../actions/patients.action.creators';
import { debounce, isEmpty } from 'lodash';
import { columnValues } from './helpers/columnValues';
import { tableProps } from '../../../../components/Table/helpers/tableProps';
import { getFromLocalStorage } from '../../../utils/getFromLocalStorage';
import { t } from '../../../../service/localization/i18n';
import './Patients.scss';

const Patients = ({ showInCard = false, customPageSize }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const patientsState = useSelector((state) => state.patients);

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [filters, setFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(patientsState?.filters?.freetext || '');
  const [selectedRows, setSelectedRows] = useState(null);
  const [deletePatientConfirm, setDeletePatientConfirm] = useState(false);
  const [deletePatientConfirmWithRecords, setDeletePatientConfirmWithRecords] = useState(false);
  const [cannotDeleteRecordDialog, setCannotDeleteRecordDialog] = useState(false);

  const dt = useRef(null);
  const dataKey = 'PatientGuid';
  const showExpand = true;
  const preSelected = !isEmpty(patientsState.patientForHighlight) ? [patientsState.patientForHighlight] : [];
  const defaultSortTemplate = <DefaultSortOrderButton />;

  // Get patients list
  useEffect(() => {
    // Scroll page to the top
    !showInCard && window.scrollTo({ top: 0 });

    let pageSize = customPageSize ? customPageSize : patientsState.pageSize;
    if (!showInCard && patientsState.pageSize === showInCardPageSize) {
      if (getFromLocalStorage(localStorageKeys.patientsPageSize)) pageSize = getFromLocalStorage(localStorageKeys.patientsPageSize);
      if (!getFromLocalStorage(localStorageKeys.patientsPageSize)) pageSize = defaultPageSize;
    }

    dispatch(getPatients({ pageSize }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // [KS] save current page size to localStorage
  useEffect(() => {
    !showInCard &&
      patientsState.pageSize !== showInCardPageSize &&
      localStorage.setItem(localStorageKeys.patientsPageSize, JSON.stringify(patientsState.pageSize));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsState.pageSize, showInCard]);

  // Add chips
  useEffect(() => {
    const currentFilters = patientsState.filters;
    const lfpPanel = Number(currentFilters.LfpPanel);
    const updatedFilters = {
      ...currentFilters,
      LfpPanel: lfpPanel === 1 ? t('LFP') : lfpPanel === 0 ? t('Non_LFP') : lfpPanel === -1 ? '' : ''
    };
    const chipsData = formatDataForChips(updatedFilters, columnValues);
    setFilters(chipsData);
  }, [patientsState.filters]);

  // Expand all rows
  useEffect(() => {
    if (isExpanded) {
      let _expandedRows = {};
      patientsState.patientList.forEach((i) => (_expandedRows[`${i[dataKey]}`] = true));

      setExpandedRows(_expandedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientsState.patientList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGlobalSearch = useCallback(
    debounce((params) => {
      dispatch(getPatients({ filters: params.filters, page: params.page }));
    }, 1000),
    []
  );

  const onAddPatientClick = () => history.push(`${routes.createPatient.path}/new`);

  const rowActionButtonsMenuModel = (rowData) => {
    const actionProps = {
      rowData,
      history,
      setDeletePatientConfirm,
      setSelectedRows
    };

    return rowActionsMenuItems(actionProps);
  };

  const rowActionButtons = (rowData) => {
    return <ActionButtons rowData={rowData} setSelectedRows={setSelectedRows} rowActionButtonsMenuModel={rowActionButtonsMenuModel} />;
  };

  const onDelete = (rows) => {
    setSelectedRows(rows);
    setDeletePatientConfirm(true);
  };

  const onAcceptDeletePatient = () => {
    dispatch(
      deletePatient(selectedRows.PatientGuid, false, (results) => {
        if (results?.data?.ResponseCode !== 204) {
          if (results?.data?.ResponseCode === 400) setCannotDeleteRecordDialog(true);
          if (results?.data?.ResponseCode === 423) setDeletePatientConfirmWithRecords(true);
        }
      })
    );
  };

  const onAcceptDeletePatientWithRecords = () => {
    dispatch(deletePatient(selectedRows.PatientGuid, true));
  };

  const onChipRemove = (chip) => {
    let currentFilters = patientsState.filters;
    delete currentFilters[chip.field];
    if (chip.field === 'LfpPanel') currentFilters = { ...currentFilters, LfpPanel: '-1' };
    dispatch(getPatients({ filters: currentFilters }));
  };

  const clearAllFilters = () => {
    dispatch(getPatients({ filters: {}, page: 0 }));
  };

  const onSort = (e) => {
    const sortOrderValue = e.sortOrder === 1 ? 'asc' : 'desc';
    dispatch(getPatients({ sortBy: `${e.sortField}_${sortOrderValue}` }));
  };

  const onPage = (e) => {
    dispatch(getPatients({ page: e.page, pageSize: e.rows }));
  };

  const onGlobalSearch = (e) => {
    const _filters = { ...patientsState.filters, freetext: e.target.value };
    const requestParams = { filters: _filters, page: 0 };

    setGlobalFilter(e.target.value);
    debouncedGlobalSearch(requestParams);
  };

  const setPreSelected = () => {
    dispatch(setPatientIdForHighlightInTable({}));
  };

  const tableHeader = {
    addNewButton: {
      display: true,
      label: t('Add_patient'),
      onClick: onAddPatientClick
    },

    filterButton: {
      display: true
    },

    deleteButton: {
      display: false, // [KS] 30.08.2022 - GROUP ACTIONS ARE NOT SUPPORTED BY BACK END ATM
      onClick: onDelete
    },

    globalSearch: {
      display: true,
      placeholder: t('Search_for_patient'),
      onChange: (e) => onGlobalSearch(e)
    }
  };

  const sidebarFiltersLayout = <SidebarFilters filters={filters} />;

  const mobileRowTemplate = (rowProps) => {
    return (
      <MobileRowTemplate
        {...rowProps}
        dataKey={dataKey}
        showExpand={showExpand}
        expandedRows={expandedRows}
        setExpandedRows={setExpandedRows}
        rowActionButtons={rowActionButtons}
      />
    );
  };

  const rowExpansionTemplate = (rowData) => {
    return <DesktopRowExpansionTemplate rowData={rowData} />;
  };

  const dataTableProps = {
    ...tableProps,
    ref: dt,
    id: elementIDs.patientsDataTable,
    pageLabel: t('Patients'),
    name: dataTablesParams.patients.name,
    dataKey,
    value: patientsState.patientList,
    first: patientsState.patientList?.length ? patientsState.first : 0,
    totalRecords: patientsState.totalRecords,
    rows: showInCard ? showInCardPageSize : patientsState.pageSize,
    sortField: patientsState.sortby.split('_')[0],
    sortOrder: patientsState.sortby.split('_')[1] === 'asc' ? 1 : -1,
    fetchingData: patientsState.isFetchingPatients,
    showExpand,
    globalFilter: globalFilter,
    header: tableHeader,
    columns: columns(),
    columnsForToggle: columns()?.filter((i) => i.id !== columnValues.lastName.id),
    columnsForPDF,
    recordsForExcel: recordsForExcel(patientsState.patientList),
    expandedRows,
    filters,
    sidebarFilters: sidebarFiltersLayout,
    selectionMode: 'checkbox',
    defaultSortTemplate,
    preSelected,
    showInCard,
    isExpanded,
    setIsExpanded,
    setPreSelected,
    setExpandedRows,
    onSort,
    setFilters,
    onPage,
    onChipRemove,
    clearAllFilters,
    rowActionButtons,
    mobileRowTemplate,
    rowExpansionTemplate,
    rowActionButtonsMenuModel,
    setGlobalFilter: onGlobalSearch
  };

  const contentView = (
    <>
      <Table {...dataTableProps} />

      <DeleteConfirmation
        visible={deletePatientConfirm}
        header={t('Delete_record')}
        message={String.format(t('Are_you_sure_you_want_to_delete_patient'), selectedRows?.Summary)}
        accept={onAcceptDeletePatient}
        onHide={() => setDeletePatientConfirm(false)}
        reject={() => setDeletePatientConfirm(false)}
      />

      <DeleteConfirmation
        visible={deletePatientConfirmWithRecords}
        header={t('Delete_record')}
        message={t('This_profile_has_some_outstanding_claims_They_will_also_be_deleted')}
        accept={onAcceptDeletePatientWithRecords}
        onHide={() => setDeletePatientConfirmWithRecords(false)}
        reject={() => setDeletePatientConfirmWithRecords(false)}
      />

      <CommonConfirmDialog
        visible={cannotDeleteRecordDialog}
        multipleButtons={false}
        header={t('Delete_record')}
        bodyText={t('This_record_cannot_be_deleted_because_it_has_a_billing_history')}
        accept={() => setCannotDeleteRecordDialog(false)}
        reject={() => setCannotDeleteRecordDialog(false)}
      />
    </>
  );

  return showInCard ? (
    contentView
  ) : (
    <div className="flex justify-content-center w-full">
      <div className="w-full" style={{ maxWidth: '1500px' }}>
        <PageWithCard>{contentView}</PageWithCard>
      </div>
    </div>
  );
};

export default Patients;
