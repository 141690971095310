import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { getRoleById, createRole, updateRole } from '../../actions/roles.action.creators';
import ModulesTable from '../RoleDetails/ModulesTable/ModulesTable';
import EntitiesTable from '../RoleDetails/EntitiesTable/EntitiesTable';
import useModules from '../../hooks/useModules';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import useEntities from '../../hooks/useEntities';
import { setRoleDetails, clearRoleDetails } from '../../actions/actions';
import { initialEntities, initialModules } from '../../helpers/initialRoleData';
import InputWrapper from '../../../../components/Inputs/InputWrapper/InputWrapper';
import cx from 'classnames';
import { t } from '../../../../service/localization/i18n';

const CreateEditRole = ({ isEdit }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModulesChange, setIsModuleChange] = useState(false);
  const [isEntityChange, setIsEntityChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.roles.roleDetails);

  const history = useHistory();

  const fetchRole = useCallback(async () => {
    setLoading(true);
    const result = await getRoleById(id);
    setLoading(false);
    if (result) {
      dispatch(setRoleDetails(result));
      reset({ roleName: result.RoleName || '' });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && id) {
      fetchRole();
    }

    return () => {
      dispatch(clearRoleDetails());
    };
  }, [isEdit, id, fetchRole, dispatch]);

  const handleModuleChange = () => {
    setIsModuleChange(true);
  };

  const handleEntityChange = () => {
    setIsEntityChange(true);
  };

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    control
  } = useForm({ defaultValues: { roleName: '' } });

  const { modules, updateModulePermission } = useModules(isEdit ? role.Modules : initialModules);
  const { entities, updateEntityPermission } = useEntities(isEdit ? role.Entities : initialEntities);

  // Handle form submission
  const onSubmit = async (formData) => {
    const roleData = {
      ...role,
      RoleName: formData.roleName,
      Modules: modules,
      Entities: entities
    };

    if (isEdit) {
      const result = await updateRole(roleData, id);
      if (result) dispatch(setToastMessage({ type: 'success', message: 'Role was updated' }));
    } else {
      const result = await createRole(roleData);
      if (result) dispatch(setToastMessage({ type: 'success', message: 'Role was created' }));
    }
    history.push('/roles');
  };

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="flex col-12 p-0 justify-content-center">
      {modules && entities ? (
        <div className="containerXL subMenuRoot mt-4 px-3 w-full">
          <h2>{isEdit ? 'Edit role' : 'Create role'}</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="roleName"
              control={control}
              rules={{ required: t('Mandatory_field.1') }}
              render={({ field, fieldState }) => (
                <InputWrapper label="Role Name" required errorMessage={fieldState.error?.message}>
                  <InputText
                    className={cx({ 'p-invalid': fieldState.error?.message })}
                    value={field.value}
                    style={{ maxWidth: '25rem' }}
                    id="roleName"
                    autoFocus
                    onChange={field.onChange}
                  />
                </InputWrapper>
              )}
            />
            <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
              <TabPanel header="Modules" headerClassName="extra-large-text">
                <ModulesTable
                  modules={modules}
                  allowEdit={true}
                  updateModulePermission={updateModulePermission}
                  onModuleChange={handleModuleChange}
                />
              </TabPanel>
              <TabPanel header="Entities" headerClassName="extra-large-text">
                <EntitiesTable
                  entities={entities}
                  allowEdit={true}
                  updateEntityPermission={updateEntityPermission}
                  onEntityChange={handleEntityChange}
                />
              </TabPanel>
            </TabView>
            <div className="flex gap-2 justify-content-center">
              {(isDirty || isModulesChange || isEntityChange) && <Button label="Save & Close" type="submit" />}
              <Button onClick={() => history.push('/roles')} label={isModulesChange || isEntityChange ? 'Cancel' : 'Close'} outlined />
            </div>
          </form>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default CreateEditRole;
