import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { integersOnly } from '../../../../../../regex/regex';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useValidation } from '../../../hooks/useValidation';

const Units = () => {
  const name = inputs.units.name;
  const { control, watch, setValue } = useFormContext();
  const { clearCommentError } = useValidation();

  const onChange = (value, field) => {
    const units = Number(value);
    const percent = units > 1 ? 100 : watch(inputs.percent.name);

    field.onChange(units);
    setValue(inputs.percent.name, percent);

    // Clear error message for commen input
    clearCommentError({ units });
  };

  const unitsInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.units.label}>
        <InputText
          id={elementIDs.unitsInput}
          style={{ width: 'inherit' }}
          type="text"
          value={field.value}
          keyfilter={integersOnly}
          onChange={(e) => onChange(e.target.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => unitsInput(field)} />;
};

export default Units;
