import React from 'react';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '../../../../../../components/Misc/Loader/CircularProgress/CircularProgress';

const TeleplanLoader = () => {
  const { localState } = useFormContext();

  const showLoader = localState.loadingSpinner || localState.getingReferralCodesByLetter || localState.isLoadingCatalogsData;

  return <>{showLoader && <CircularProgress />}</>;
};

export default TeleplanLoader;
