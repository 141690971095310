import React from 'react';
import { useFormContext } from 'react-hook-form';
import ConsecutiveTime from './ConsecutiveTime';
import { steps } from '../../../../../helpers/steps';

const GroupPickerTableSettings = () => {
  const { localState } = useFormContext();

  return (
    <>
      {/* Show ConsecutiveTime only on Services step */}
      {/* WIN-82 - Batch screen: Remove Consecutive time on all steps except first one */}
      {localState.step === steps.groupServicesPicker && <ConsecutiveTime />}
    </>
  );
};

export default GroupPickerTableSettings;
