import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Skeleton } from 'primereact/skeleton';
import PolicyCard from '../../../../../../../components/Misc/PolicyCard/PolicyCard';

import { commonInputs } from '../../../../../../config/commonInputsConfig';
import { useValidation } from '../../../hooks/useValidation';
import { useInsurancePolicy } from '../../../hooks/useInsurancePolicy';
import { t } from '../../../../../../../service/localization/i18n';

const PolicyItem = ({ policy, input }) => {
  const { isView } = useFormContext();
  const { onSelect, onEdit, onDelete, isSelected } = useInsurancePolicy(input);
  const isPolicySelected = isSelected(policy);

  return (
    <PolicyCard
      policy={policy}
      hideActions={isView}
      isSelected={isPolicySelected}
      onSelect={isView ? undefined : onSelect}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

const PoliciesList = ({ input }) => {
  const { localState } = useFormContext();
  const options = localState.policiesList;

  if (localState.isLoading) {
    return <Skeleton className="policy_skeleton" />;
  }

  // If no policies
  if (!options?.length) {
    return <div className="flex justify-content-center align-items-center w-full text-sm text-500">{t('No_policy_is_entered_for_this_patient')}</div>;
  }

  return (
    <>
      {options?.map((policyItem) => {
        const { isPrimary } = commonInputs;
        const primaryKey = isPrimary.name;
        const key = `${policyItem.RowGuid?.toLocaleUpperCase()?.replaceAll('-', '')}`;

        if (!input?.[primaryKey] || policyItem?.[primaryKey] === input?.[primaryKey]) {
          return <PolicyItem key={key} policy={policyItem} input={input} />;
        }

        return null;
      })}
    </>
  );
};

const InsurancePolicySelector = ({ input }) => {
  const { insurancePolicyValidation } = useValidation();
  const { control } = useFormContext();

  return (
    <Controller
      name={input.name}
      control={control}
      rules={{ validate: (value) => insurancePolicyValidation(value, input) }}
      render={({ field }) => (
        <div className="flex flex-wrap justify-content-center gap-3">
          <PoliciesList input={input} />
        </div>
      )}
    />
  );
};

export default InsurancePolicySelector;
