import React from 'react';
import { useFormContext } from 'react-hook-form';
import DatePickerWithFormControl from '../../../../Inputs/DatePicker/DatePickerWithFormControl';
import { inputs } from '../helpers/inputs';

const Date = () => {
  const { control } = useFormContext();

  return (
    <div className="w-full sm:w-15rem">
      <DatePickerWithFormControl input={inputs.date} control={control} required />
    </div>
  );
};

export default Date;
