import React from 'react';

import { InputText } from 'primereact/inputtext';
import AsterisksForCommentInput from './AsterisksForCommentInput';
import { InputWrapper } from '../../../../../../../../../components/Inputs';

import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { inputs } from '../../../../../helpers/inputs';
import cx from 'classnames';

const CommentEditor = ({ rowOptions }) => {
  const name = inputs.comment.name;
  const value = rowOptions.rowData[name];
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const { onCommentChange } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const errorMessage = getErrorMessage(rowId, name);

  const onChange = (comment) => {
    onCommentChange(comment, rowOptions);
  };

  return (
    <div className="flex w-full pr-1 gap-2">
      <InputWrapper name={name} hideLabel errorMessage={errorMessage} style={{ height: rowInputHeight, width: '100%' }}>
        <span className="p-input-icon-right w-full">
          <InputText
            className={cx({ 'p-invalid': !!errorMessage })}
            name={name}
            value={value}
            style={{ width: 'inherit' }}
            onChange={(e) => onChange(e.target.value)}
          />
          {value && <i className="pi pi-times pointer" onClick={() => onChange('')} />}
        </span>
      </InputWrapper>

      <AsterisksForCommentInput rowOptions={rowOptions} />
    </div>
  );
};

export default CommentEditor;
