import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const options = [
  { value: 'N/A', label: t('N_A') },
  { value: 'WPA', label: t('Workplace') },
  { value: 'MVA', label: t('Motor_Vehicle') },
  { value: 'SCHOOL', label: t('School') },
  { value: 'SPT', label: t('Sporting') },
  { value: 'OTHER', label: t('Other') }
];

const AccidentTypeInfo = () => {
  return (
    <>
      <Tooltip className="max-w-20rem" target=".accedentTypeInfo" position="top">
        {t('accident_type_info_tooltip')}
      </Tooltip>
      <i className="pi pi-question-circle accedentTypeInfo" />
    </>
  );
};

export const AccidentType = () => {
  const name = inputs.accidentType.name;
  const { control, errors, isView, clearErrors, watch, setValue } = useFormContext();
  const accidentDateKey = inputs.accidentDate.name;

  const onChange = (value, field) => {
    field.onChange(value);

    if (value === 'N/A') {
      const accidentDate = watch(accidentDateKey);
      if (accidentDate) setValue(accidentDateKey, null);
      if (errors[accidentDateKey]?.message) clearErrors(accidentDateKey);
    }
  };

  const accidentTypeInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.accidentType.label} labelOptionalContent={<AccidentTypeInfo />}>
        <Dropdown
          id={elementIDs.accidentTypeDropdown}
          inputRef={field.ref}
          type="text"
          value={field.value}
          field="label"
          options={options}
          disabled={isView}
          scrollHeight={dropdownScrollHeight()}
          onChange={(e) => onChange(e.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => accidentTypeInput(field)} />;
};

export default AccidentType;
