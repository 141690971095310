import { useFormContext } from 'react-hook-form';
import { useCodePickerActions } from './useCodePickerActions';

export const useChipRemove = () => {
  const { localState, watch } = useFormContext();
  const { onChange } = useCodePickerActions(localState?.focusFieldParams);

  const onChipRemove = (code) => {
    const name = localState?.focusFieldParams.codeType;
    const codes = watch(name).filter((i) => i.value !== code.value);
    onChange({ value: codes });
  };

  return { onChipRemove };
};
