import { salesTaxList } from './salesTaxList';

export const calculateRowTotal = (currentRow) => {
  const currentTax = salesTaxList?.find((i) => i.name === currentRow.salesTax);
  const feeAmount = Number(currentRow.amount) * Number(currentRow.quantity);
  const total_fee =
    currentRow.discountUnits === 'percent'
      ? Number(feeAmount) * (1 - Number(currentRow.discount) / 100)
      : Number(feeAmount) - Number(currentRow.discount);
  const total_gst = total_fee * currentTax?.gst_percent;
  const total_pst = total_fee * currentTax?.pst_percent;
  const total = Number(total_fee) + Number(total_gst) + Number(total_pst);
  const balanceDue = total - Number(currentRow.inStoreCredit);

  return {
    feeAmount: Number(feeAmount.toFixed(2)),
    gst: Number(total_gst.toFixed(2)),
    pst: Number(total_pst.toFixed(2)),
    total: Number(total.toFixed(2)),
    balanceDue: Number(balanceDue.toFixed(2))
  };
};
