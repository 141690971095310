import React, { useRef, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment';

const DateChangeArrows = () => {
  const op = useRef(null);
  const { watch, setValue } = useFormContext();
  const periodFrom = watch('PeriodFrom');
  const periodTo = watch('PeriodTo');
  const [selectedRange, setSelectedRange] = useState(null); // To track the selected quick pick range

  // Calculate date difference dynamically
  const calculateDateDifference = () => {
    if (periodFrom && periodTo) {
      const diff = moment(periodTo).diff(moment(periodFrom), 'days');
      return diff + 1; // Add 1 to include the last day in the range
    }
    return 0;
  };

  // Set initial selected range based on the current date difference
  useEffect(() => {
    const initialDateDifference = calculateDateDifference();
    setSelectedRange(initialDateDifference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodFrom, periodTo]);

  // Handlers for arrow buttons
  const onLeftArrowClick = () => {
    const dateDifference = calculateDateDifference();
    if (periodFrom && periodTo && dateDifference > 0) {
      const newPeriodFrom = moment(periodFrom).subtract(dateDifference, 'days').toDate();
      const newPeriodTo = moment(periodTo).subtract(dateDifference, 'days').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
    }
  };

  const onRightArrowClick = () => {
    const dateDifference = calculateDateDifference();
    if (periodFrom && periodTo && dateDifference > 0) {
      const newPeriodFrom = moment(periodFrom).add(dateDifference, 'days').toDate();
      const newPeriodTo = moment(periodTo).add(dateDifference, 'days').toDate();
      setValue('PeriodFrom', newPeriodFrom);
      setValue('PeriodTo', newPeriodTo);
    }
  };

  const dateDifference = calculateDateDifference();
  const dateDifferenceLabel = dateDifference === 1 ? 'day' : 'days';

  // Handler for quick pick buttons in the OverlayPanel
  // const onQuickPick = (days) => {
  //   if (periodFrom) {
  //     const newPeriodTo = moment(periodFrom).add(days, 'days').toDate();
  //     setValue('PeriodTo', newPeriodTo);
  //     op.current.hide(); // Hide the OverlayPanel after selection
  //   }
  // };

  const onQuickPick = (days) => {
    const currentDate = moment(); // Get the current date

    // Set PeriodTo to the current date
    const newPeriodTo = currentDate.toDate();

    // Subtract the selected number of days from the current date for PeriodFrom
    const newPeriodFrom = currentDate.subtract(days, 'days').toDate();

    // Update the form values for PeriodFrom and PeriodTo
    setValue('PeriodFrom', newPeriodFrom);
    setValue('PeriodTo', newPeriodTo);

    // Hide the OverlayPanel after selection
    op.current.hide();
  };

  return (
    <div className="flex justify-content-between align-items-center w-full">
      {/* Left Arrow */}
      <Button
        icon="pi pi-chevron-left"
        rounded
        text
        tooltip={`Previous ${dateDifference} ${dateDifferenceLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onLeftArrowClick}
      />

      {/* Date Difference */}
      <div className="pointer" type="button" onClick={(e) => op.current.toggle(e)}>{`${dateDifference} ${dateDifferenceLabel}`}</div>

      {/* Right Arrow */}
      <Button
        icon="pi pi-chevron-right"
        rounded
        text
        tooltip={`Next ${dateDifference} ${dateDifferenceLabel}`}
        tooltipOptions={{ position: 'top' }}
        onClick={onRightArrowClick}
      />

      {/* Overlay Panel with Quick Pick Buttons */}
      <OverlayPanel ref={op} style={{ maxWidth: '400px' }}>
        <div>
          <h6 className="text-center">Select Date Range</h6>
          <div className="flex flex-wrap justify-content-center gap-3">
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 1} label="1 Day" onClick={() => onQuickPick(1)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 7} label="7 Days" onClick={() => onQuickPick(7)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 14} label="14 Days" onClick={() => onQuickPick(14)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 30} label="30 Days" onClick={() => onQuickPick(30)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 60} label="60 Days" onClick={() => onQuickPick(60)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 90} label="90 Days" onClick={() => onQuickPick(90)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 180} label="180 Days" onClick={() => onQuickPick(180)} />
            <Button form="cash-flow-filters-form" rounded text={selectedRange !== 365} label="365 Days" onClick={() => onQuickPick(365)} />
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DateChangeArrows;
