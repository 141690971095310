import axios from 'axios';
import { getApiBase } from '../config';
import { store } from '../store';
import { logout } from '../modules/auth/actions/auth.actions.creators';

const setAuthHeader = (currentState) => {
  const token = currentState && currentState.user && currentState.user.details ? currentState.user.details.JwtToken : '';
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response.status;
        if (status === 403) {
          store.dispatch(logout());
        }
        return Promise.reject(error);
      }
    );
  }
};

const fetchPatients = ({ dcnguid, filter, sort, page, pagesize }) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState && currentState.user && currentState.user.details ? currentState.user.details.DCNGuid : dcnguid;
  const url =
    `${getApiBase()}/patients/` +
    DCNGuid +
    '/' +
    (page ? page : '1') +
    '/' +
    (pagesize ? pagesize : '1') +
    '/?' +
    (filter ? 'q=' + filter : '') +
    (sort ? '&sortby=' + sort : '');
  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      // dispatch(setToastMessage( { type: "error", message: reason?.response?.data?.ExceptionMessage }));
      return { error: reason };
    });
};

const PatientService = {
  fetchPatients
};
export default PatientService;
