import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import CommonTimeInput from './Common/CommonTimeInput';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { callTimeInputRequirements } from '../../../helpers/validationSchema';

const CallTime = ({ className = '' }) => {
  const { control, isGroup } = useFormContext();
  const emergency = useWatch({ control, name: inputs.emergency.name });
  const travel = useWatch({ control, name: inputs.travel.name });
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const required = !isGroup && callTimeInputRequirements(emergency, travel, saveAsDraft, speciality, invoiceType);

  const onChange = (time, field) => {
    field.onChange(time);
  };

  return <CommonTimeInput className={className} id={elementIDs.callTimeInput} input={inputs.callTime} required={required} onChange={onChange} />;
};

export default CallTime;
