import React from 'react';
import PatientFilter from '../../inputs/PatientFilter';
import PeriodChip from './PeriodChip';

const TableHeader = () => {
  return (
    <div className="flex flex-column md:flex-row justify-content-between align-items-center gap-4">
      <div className="max-w-20rem w-full">
        <PatientFilter />
      </div>
      <PeriodChip />
    </div>
  );
};

export default TableHeader;
