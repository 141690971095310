import { useFormContext } from 'react-hook-form';
import { getDefaultPrefs } from '../../../../../helpers/getDefaultPrefs';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';

export const useSettings = () => {
  const { localState } = useFormContext();

  const isConsecutiveTime = () => {
    const consecutiveTime = getDefaultPrefs(localState.practitionerPrefs?.content, prefsCodes.consecutiveTime);
    return consecutiveTime && consecutiveTime === '1';
  };

  return {
    isConsecutiveTime: isConsecutiveTime()
  };
};
