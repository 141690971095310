import React from 'react';
import AddNoteDialog from './AddNoteDialog';
import DeleteNoteDialog from './DeleteNoteDialog';

const Dialogs = () => {
  return (
    <>
      <AddNoteDialog />
      <DeleteNoteDialog />
    </>
  );
};

export default Dialogs;
