import constants from './constants';

const initialState = {
  claimNotes: {},
  patientNotes: {},
  claimNoteDetails: {},
  patientNoteDetails: {},
  totalUnreadNotes: []
};

export default function noteReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_CLAIM_NOTES:
      return {
        ...state,
        claimNotes: action.payload
      };
    case constants.CLEAR_CLAIM_NOTES:
      return {
        ...state,
        claimNotes: action.payload
      };
    case constants.SET_PATIENT_NOTES:
      return {
        ...state,
        patientNotes: action.payload
      };
    case constants.CLEAR_PATIENT_NOTES:
      return {
        ...state,
        patientNotes: action.payload
      };
    case constants.SET_CLAIM_NOTE_DETAILS:
      return {
        ...state,
        claimNoteDetails: action.payload
      };
    case constants.CLEAR_CLAIM_NOTE_DETAILS:
      return {
        ...state,
        claimNoteDetails: action.payload
      };
    case constants.SET_PATIENT_NOTE_DETAILS:
      return {
        ...state,
        patientNoteDetails: action.payload
      };
    case constants.CLEAR_PATIENT_NOTE_DETAILS:
      return {
        ...state,
        patientNoteDetails: action.payload
      };
    case constants.GET_TOTAL_UNREAD_NOTES:
      return {
        ...state,
        totalUnreadNotes: action.payload
      };

    default:
      return state;
  }
}
