import axios from 'axios';
import actions from './preferences.action.types';
import { getApiBase, setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getPreferencesResponseAction, getPreferencesResponseActionForTests, getPrefersForTests, parsedDataTablePrefs } from './responseActions';
import { unionBy } from 'lodash';
import { t } from '../../../service/localization/i18n';
import { store } from '../../../store';
import { NA_DoctorGuid, multipleDoctorGuid } from '../../config/defaultValuesConfig';

export const getPrefs = async (dcnguid, userGuid) => {
  if (process.env.REACT_APP_CYPRESS) {
    return Promise.resolve(getPrefersForTests(userGuid));
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/preferences/${dcnguid}/${userGuid}`);
    return { ...results.data, prefs: parsedDataTablePrefs(results.data.prefs) };
  } catch (error) {
    console.log('!!!getPrefs', error);
  }
};

export const savePrefs = (prefs) => {
  // interface ISavePrefs {
  // content: {
  //   value: string, // preference value (i.e. "1", "MSP", "1542875f-6b30-4aff-bd59-4c9016d33830")
  //   label: string, // preference code (i.e. "154")
  // }[],
  //   label: "default" | "display" | "system",
  //   value: string // provide practitionerGuid
  // }

  const doNotSave = prefs?.value === NA_DoctorGuid || prefs?.value === multipleDoctorGuid;
  if (doNotSave) return store.dispatch(setToastMessage({ type: 'info', message: t('Please_select_practitioner') }));

  const state = store.getState();
  setAuthHeader(state);
  const DCNGuid = state.clinic.details.DCNGuid;

  store.dispatch({ type: actions.SAVING_PREFERENCES, results: true });

  return axios
    .put(`${getApiBase()}/preferences/${DCNGuid}/${prefs?.value}`, prefs)
    .then((results) => {
      store.dispatch({ type: actions.SAVING_PREFERENCES, results: false });
      return results.data;
    })
    .catch((error) => {
      console.log('!!!savePrefs', error);
      store.dispatch({ type: actions.SAVING_PREFERENCES, results: false });
      store.dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
    });
};

export const setPrefs = (prefs) => {
  return store.dispatch({
    type: actions.GET_CURRENT_USER_PREFS_SUCCESS,
    results: prefs
  });
};

export const getPreferences = (dcnguid, userId, callback) => (dispatch, state) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return getPreferencesResponseActionForTests(userId, callback);
  }

  setAuthHeader(state);
  dispatch({ type: actions.IS_GETTING_PREFS, results: true });
  return axios
    .get(`${getApiBase()}/preferences/${dcnguid}/${userId}`)
    .then((results) => {
      dispatch({ type: actions.IS_GETTING_PREFS, results: false });
      return getPreferencesResponseAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_GETTING_PREFS, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const savePreferences =
  (practitionerGuid, prefs, callBack, setToRedux = true) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const currentState = state();
    const currentUserPrefs = currentState.preferences.currentUserPrefs;
    const DCNGuid = currentState.clinic.details.DCNGuid;

    dispatch({ type: actions.SAVING_PREFERENCES, results: true });

    axios
      .put(`${getApiBase()}/preferences/${DCNGuid}/${practitionerGuid}`, prefs)
      .then((results) => {
        dispatch({ type: actions.SAVING_PREFERENCES, results: false });
        const defaultPrefs = currentUserPrefs?.prefs?.find((i) => i.label === prefs.label);
        const updatedDefaultPrefs = { ...defaultPrefs, content: unionBy(results.data.content, defaultPrefs.content, 'label') };
        const updatedResults = {
          ...currentUserPrefs,
          prefs: currentUserPrefs?.prefs?.map((i) => (i.label === prefs.label ? updatedDefaultPrefs : i))
        };

        // dispatch(setToastMessage({ type: "success", message: t("Preferences_saved_successfully") }));
        callBack && callBack(prefs);
        setToRedux && dispatch(setCurrentUserPrefs(updatedResults));
      })
      .catch(() => {
        dispatch({ type: actions.SAVING_PREFERENCES, results: false });
        dispatch(setToastMessage({ type: 'error', lifeTime: 3000, message: t('Something_went_wrong') }));
      });
  };

export const setCurrentUserPrefs = (prefs) => (dispatch) => {
  dispatch({
    type: actions.GET_CURRENT_USER_PREFS_SUCCESS,
    results: prefs
  });
};

export const setCurrentPractitionerPrefs = (prefs) => (dispatch) => {
  dispatch({
    type: actions.SET_CURRENT_PRACTITIONER_PREFS,
    results: prefs
  });
};
