import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { getDefaultPrefs } from '../../../helpers/getDefaultPrefs';

export const updateUserDefaultPrefs = ({ defaultPrefs, currentUserDefaultPrefs, doctorGuid }) => {
  let newPrefs = { ...defaultPrefs, content: defaultPrefs.content };

  // [KS] CMO-1189 - Display the locked lock when the user switches between an unlocked value to the locked value
  // const practitionerPrefsLockValue = user?.details?.UserGuid === doc?.DoctorGuid ? "1" : "0";
  const practitionerPrefsLockValue =
    getDefaultPrefs(currentUserDefaultPrefs?.content, prefsCodes.defaultPractitioner) &&
    getDefaultPrefs(currentUserDefaultPrefs?.content, prefsCodes.defaultPractitioner) === doctorGuid
      ? '1'
      : '0';

  // [KS] CMO-1167 - Create/Edit claim - add "lock" button for inputs, which have preferences values
  const practitionerPrefs = [
    { value: practitionerPrefsLockValue, label: prefsCodes.presetPractitioner },
    { value: doctorGuid, label: prefsCodes.defaultPractitioner }
  ];

  const updatedPrefs = newPrefs.content?.map((i) => practitionerPrefs?.find((x) => x.label === i.label) || i);
  newPrefs = { ...defaultPrefs, content: updatedPrefs };

  return newPrefs;
};
