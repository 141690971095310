export const sortByAlphabetical = (arr, key) => {
  if (Array.isArray(arr)) {
    if (typeof arr[0] === 'string') {
      // Array of strings
      return arr.sort((a, b) => {
        const valueA = a.toUpperCase();
        const valueB = b.toUpperCase();
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    } else if (typeof arr[0] === 'object' && key in arr[0]) {
      // Array of objects
      return arr.sort((a, b) => {
        const valueA = a[key].toUpperCase();
        const valueB = b[key].toUpperCase();
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    }
  }

  // Invalid input, return the original array
  return arr;
};
