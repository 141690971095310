import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextAreaInput from './Common/TextAreaInput';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { commentInputRequirements } from '../../../helpers/validationSchema';

const Comment = () => {
  const { watch, localState, control, errors } = useFormContext();
  const saveAsDraft = watch(inputs.saveAsDraft.name);
  const submissionCode = watch(inputs.submission.name);
  const invoiceType = watch(inputs.payor.name);
  const serviceDate = watch(inputs.serviceDate.name);
  const feeCodes = watch(inputs.feeCodes.codeType);
  const startTime = watch(inputs.startTime.name);
  const endTime = watch(inputs.endTime.name);
  const units = watch(inputs.units.name);
  const speciality = watch(inputs.speciality.name);
  const required = commentInputRequirements({
    submissionCode,
    invoiceType,
    saveAsDraft,
    serviceDate,
    startTime,
    endTime,
    feeCodes,
    step: localState.step,
    units,
    speciality
  });

  return (
    <div className="mb-3">
      <Controller
        name={inputs.comment.name}
        control={control}
        render={({ field }) => (
          <InputWrapper
            name={inputs.comment.name}
            label={inputs.comment.label}
            required={required}
            errorMessage={errors[inputs.comment.name]?.message}
            style={{ height: 'auto' }}
          >
            <TextAreaInput field={field} />
          </InputWrapper>
        )}
      />
    </div>
  );
};

export default Comment;
