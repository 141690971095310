import moment from 'moment';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClaimNotes } from '../../../notes/actions/actions';
import {
  createClaimNote,
  deleteClaimNote,
  editClaimNote,
  getClaimNotes,
  setClaimInfo,
  updateClaimList,
  getAllClaimNotes
} from '../../actions/claims.action.creators';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../routes/routes';

export const initNotesQuery = {
  DoctorGuid: '',
  PatientGuid: '',
  ClaimNumber: '',
  NoteDate: moment(moment().subtract(12, 'months').toDate()).format('YYYY/MM/DD')
};

const initState = { page: 1, pageSize: 20, totalPages: 0, first: 0, totalRecords: 0, notesList: [], isLoadingNotes: false };

const useClaimNotes = (invoiceGuid) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const claimList = useSelector((state) => state.claims.claim_list);
  const claimNotes = useSelector((state) => state.notes.claimNotes);
  const claim_details = useSelector((state) => state.claims.claim_details);
  const [notes, setNotes] = useState(initState);
  const [loadingAllNotes, setLoadingAllNotes] = useState(false);

  // Get init notes
  useEffect(() => {
    if (invoiceGuid) fetchNotes();
    // fetchAllNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset notes on unmount
  useEffect(() => {
    return () => {
      resetNotes();
    };
  }, []);

  const fetchAllNotes = async ({ page, pageSize, query } = {}) => {
    setLoadingAllNotes(true);
    const result = await getAllClaimNotes({
      pageSize: pageSize || claimNotes.pageSize || 20,
      page: page || claimNotes.page || 1,
      query
    });
    setLoadingAllNotes(false);
    if (result) {
      dispatch(setClaimNotes({ ...result, first: (result.page - 1) * result.pageSize }));
    }
  };

  const searchForAllNotes = async ({ freetext } = {}) => {
    setLoadingAllNotes(true);
    const result = await getAllClaimNotes({
      pageSize: claimNotes.pageSize || 20,
      page: 1,
      freetext
    });
    setLoadingAllNotes(false);
    if (result) {
      dispatch(setClaimNotes({ ...result, first: (result.page - 1) * result.pageSize }));
    }
  };

  const fetchNotes = useCallback(
    async ({ page, pageSize, resetNotesList } = {}) => {
      const pageSizeValue = pageSize > 0 ? pageSize : notes.pageSize;
      setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
      const result = await getClaimNotes({
        pageSize: pageSizeValue,
        page: page || notes.page,
        invoiceGuid
      });
      setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
      if (result) {
        const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
        setNotes((prevState) => ({
          ...result,
          pageSize: notes.pageSize,
          notesList: resetNotesList ? newNotesList : [...prevState.notesList, ...newNotesList]
        }));
      }
    },
    [invoiceGuid, notes.pageSize, notes.page]
  );

  const searchForNotes = async ({ freetext } = {}) => {
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
    const result = await getClaimNotes({
      pageSize: notes.pageSize,
      page: 1,
      invoiceGuid,
      freetext
    });
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
    if (result) {
      const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
      setNotes({
        ...result,
        pageSize: notes.pageSize,
        notesList: newNotesList
      });
    }
  };

  const onCreateNote = async (note, options) => {
    const result = await createClaimNote(note);
    if (result) {
      updateClaimListForNote('add');
      dispatch(setClaimInfo({ ...claim_details, TotalNotes: claim_details.TotalNotes + 1 }));
      if (options) {
        const { searchQuery } = options;
        if (searchQuery) {
          fetchNotes({ resetNotesList: true });
        } else {
          setNotes((prevState) => ({
            ...prevState,
            notesList: [result, ...prevState.notesList]
          }));
        }
      }
    }
  };

  const onReplyNote = async (note) => {
    await createClaimNote(note);
    fetchAllNotes({ page: claimNotes.page, pageSize: claimNotes.pageSize });
    history.replace(routes.claimNotesInbox.path);
  };

  const onEditNote = async (note, options) => {
    const result = await editClaimNote(note);
    if (result) {
      const { searchQuery } = options;
      if (searchQuery) {
        fetchNotes({ resetNotesList: true });
      } else {
        setNotes((prevState) => {
          const updatedNotesList = prevState.notesList?.map((i) => {
            return i.NoteGuid === result.NoteGuid ? result : i;
          });

          return {
            ...prevState,
            notesList: updatedNotesList
          };
        });
      }
    }
  };

  const onDeleteNoteForMemos = async (noteGuid) => {
    await deleteClaimNote(noteGuid);
    fetchAllNotes({ page: claimNotes.page, pageSize: claimNotes.pageSize });
  };

  const onDeleteNote = async (noteGuid) => {
    const result = await deleteClaimNote(noteGuid);
    if (result) {
      updateClaimListForNote('delete');
      dispatch(setClaimInfo({ ...claim_details, TotalNotes: claim_details.TotalNotes - 1 }));
      setNotes((prevState) => {
        const notesList = prevState.notesList.filter((i) => i.NoteGuid !== noteGuid);
        return {
          ...prevState,
          notesList
        };
      });
    }
  };

  const updateClaimListForNote = (action) => {
    const updatedClaimList = claimList.map((claim) => {
      if (claim.InvoiceGuid === invoiceGuid) {
        if (action === 'add') {
          return { ...claim, TotalNotes: claim.TotalNotes + 1 };
        } else if (action === 'delete') {
          return { ...claim, TotalNotes: claim.TotalNotes - 1 };
        }
      }
      return claim;
    });

    dispatch(updateClaimList(updatedClaimList));
  };

  const onSearch = async (query) => {
    await searchForNotes({ freetext: query });
  };

  const onSearchAll = async (query) => {
    await searchForAllNotes({ freetext: query });
  };

  const onScroll = ({ page } = {}) => {
    fetchNotes({ page });
  };

  const resetNotes = () => {
    setNotes(initState);
  };

  return {
    notes,
    fetchAllNotes,
    loadingAllNotes,
    setNotes,
    fetchNotes,
    searchForNotes,
    onScroll,
    resetNotes,
    onCreateNote,
    onReplyNote,
    onEditNote,
    onDeleteNote,
    onSearch,
    onSearchAll,
    onDeleteNoteForMemos
  };
};

export default useClaimNotes;
