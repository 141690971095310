import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';

const ModulesTable = ({ modules, allowEdit = false, updateModulePermission, onModuleChange }) => {
  const handleCanSeeChange = (rowData) => {
    const newPermissions = {
      CanSee: !rowData.Permissions.CanSee,
      AccessDenied: rowData.Permissions.CanSee ? false : rowData.Permissions.AccessDenied
    };
    onModuleChange?.();

    updateModulePermission(rowData.Module, newPermissions);
  };

  return (
    <div className="card mt-3">
      <DataTable value={modules} tableStyle={{ minWidth: '50rem' }} scrollable scrollHeight="flex" responsiveLayout="scroll">
        <Column
          field="ModuleName"
          header="Module Name"
          style={{ width: '250px' }}
          body={(rowData) => {
            let moduleName = rowData.ModuleName;

            if (moduleName === 'EClaims') {
              moduleName = 'eClaims';
            } else if (moduleName === 'UserSettings') {
              moduleName = 'User Settings';
            } else if (moduleName === 'ClinicSettings') {
              moduleName = 'Clinic Settings';
            }

            return <span>{moduleName}</span>;
          }}
        />
        <Column
          header="Access Granted"
          body={(rowData) => (
            <InputSwitch disabled={!allowEdit} checked={rowData.Permissions.CanSee} onChange={allowEdit ? () => handleCanSeeChange(rowData) : null} />
          )}
        />
      </DataTable>
    </div>
  );
};

export default ModulesTable;
