import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const Percent = () => {
  const name = inputs.percent.name;
  const { control, watch } = useFormContext();

  const onBlur = (value, field) => {
    const updatedValue = Number(value?.replace('%', '')) || 100;
    const units = watch(inputs.units.name);
    const percent = units > 1 ? 100 : updatedValue;
    field.onChange(percent);
  };

  const percentInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.percent.label}>
        <InputNumber
          inputId={elementIDs.percentInput}
          inputStyle={{ width: 'inherit' }}
          style={{ width: 'inherit' }}
          type="text"
          value={field.value}
          suffix="%"
          onChange={(e) => field.onChange(e.value)}
          onBlur={(e) => onBlur(e.target.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => percentInput(field)} />;
};

export default Percent;
