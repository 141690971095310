import React, { useState } from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { AutoComplete } from 'primereact/autocomplete';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { ruralCodesDropdownOptions } from '../../../helpers/dropdownOptions';
import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { showRuralCodeInput } from '../../../helpers/inputsConditions';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const RuralCode = () => {
  const name = inputs.ruralCode.name;
  const { control, errors } = useFormContext();
  const [filteredCodes, setFilteredCodes] = useState(null);
  const { isLocked, onSavePrefs } = usePreferences();
  const ruralCode = useWatch({ control, name });
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const showRuralInput = showRuralCodeInput(speciality);

  if (!showRuralInput) return null;

  const defaultCode = prefsCodes.defaultRural;
  const presetCode = prefsCodes.presetRural;

  const onSavePreferences = () => {
    const preferences = [
      { value: '1', label: presetCode },
      { value: ruralCode?.value, label: defaultCode }
    ];

    onSavePrefs(preferences);
  };

  const searchRuralCode = (e) => {
    let _filteredCodes;
    if (!e.query.trim().length) {
      _filteredCodes = [...ruralCodesDropdownOptions];
    } else {
      _filteredCodes = ruralCodesDropdownOptions.filter((code) => {
        return code.label.toLowerCase().startsWith(e.query.toLowerCase());
      });
    }

    setFilteredCodes(_filteredCodes);
  };

  const onChange = (e, field) => {
    field.onChange(e.value);
  };

  const locationCodeInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.ruralCode.label}
        errorMessage={errors[name]?.message}
        isLocked={isLocked(ruralCode?.value, defaultCode)}
        onSavePrefs={onSavePreferences}
      >
        <AutoComplete
          id={elementIDs.ruralCodeAutocomplete}
          inputId={elementIDs.ruralCodeAutocompleteInput}
          inputRef={field.ref}
          type="text"
          className={cx({ 'p-invalid': errors[name]?.message })}
          value={field.value}
          dropdown
          autoHighlight
          forceSelection
          field="label"
          suggestions={filteredCodes}
          scrollHeight={dropdownScrollHeight()}
          placeholder={t('Search_for_codes')}
          completeMethod={searchRuralCode}
          onChange={(e) => onChange(e, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => locationCodeInput(field)} />;
};

export default RuralCode;
