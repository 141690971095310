import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { inputs } from '../helpers/inputs';

export const useEmployersList = () => {
  const claimsList = useSelector((state) => state.claims.claimsData.WCB);
  const [employers, setEmployers] = useState([]);

  // Set initial employers list
  useEffect(() => {
    if (claimsList?.length) {
      const employers = claimsList.map((i) => {
        if (i.Reports?.length) return i.Reports[0];
        return '';
      });

      // const uniqEmployerNames = employers.filter((i, index, self) => i && self.indexOf(i) === index);
      const uniqEmployers = [...new Map(employers.map((i) => [i[inputs.employer.name], i])).values()].filter((i) => i);
      setEmployers(uniqEmployers);
      return;
    }

    return setEmployers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsList]);

  return { employers };
};
