import actionTypes from '../actions/demos.action.types';
import { defaultPageSize, demosDefaultSortBy, demosDefaultFilters } from '../../../modules/config/defaultValuesConfig';

export const demosPageSizeKey = 'demos-page-size';

export const demosInitialState = {
  demosItemsList: [],
  demosCompleteList: [],
  demoItemDetails: {},
  demoItemIndex: 0,
  active_offset: 0,
  totalPages: 0,
  totalRecords: 0,
  totalRecordsAlter: 0,
  first: 0,
  page: 0,
  query: '',
  pageSize: JSON.parse(localStorage.getItem(demosPageSizeKey)) ? JSON.parse(localStorage.getItem(demosPageSizeKey)) : defaultPageSize,
  filters: demosDefaultFilters,
  sortby: demosDefaultSortBy.sortOrder,
  isFetchingDemos: false,
  filterByPatientForDemos: false
};

// DONT FORGET TO RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo", "Demo" or "DEMO" prefix

export default function demosReducer(state = demosInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case actionTypes.FETCH_DEMOS_DATA_SUCCESS:
      return {
        ...state,
        demosItemsList: results.isLoadData4Scroll ? [] : results.data.claimList,
        demosCompleteList: results.isLoadData4Scroll ? [...state.demosCompleteList, results.data.claimList] : [],
        page: results.data.page,
        first: (results.data.page - 1) * results.data.pageSize + 1,
        totalPages: results.data.totalPages,
        totalRecords: results.data.totalRecords,
        totalRecordsAlter: results.data.totalRecordsAlter,
        pageSize: results.data.pageSize,
        query: results.data.query,
        sortby: results.data.sortby,
        filters: results?.data?.query?.length ? JSON.parse(results.data.query) : {}
      };

    case actionTypes.IS_FETCHING_DEMOS:
      return {
        ...state,
        isFetchingDemos: results
      };

    case actionTypes.SET_DEMO_DETAILS:
      return {
        ...state,
        demoItemDetails: results
      };

    case actionTypes.SET_DEMO_ITEM_INDEX:
      return {
        ...state,
        demoItemIndex: results
      };

    case actionTypes.FILTER_BY_PATIENT_FOR_DEMOS:
      return {
        ...state,
        filterByPatientForDemos: results
      };

    default:
      return state;
  }
}
