import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const LessMSP = ({ index }) => {
  const name = `${inputs.services.name}.${index}.${inputs.lessMSP.name}`;
  const { control, clearErrors } = useFormContext();

  const onChange = (e, field) => {
    field.onChange(e);
    if (e.target.value) clearErrors(name);
  };

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <InputWrapper name={name} label={inputs.lessMSP.label} errorMessage={error?.message}>
          <InputNumber
            {...field}
            inputStyle={{ width: 'inherit' }}
            style={{ width: 'inherit' }}
            className={cx('flex', { 'p-invalid': error?.message })}
            type="text"
            mode="currency"
            currency="USD"
            locale="en-US"
            disabled
            onChange={(e) => onChange(e, field)}
          />
        </InputWrapper>
      )}
      name={name}
      control={control}
      // rules={{ validate: (value) => !!value || t('Mandatory_field.1') }} // No need validation for disabled input
    />
  );
};

export default LessMSP;
