import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { inputsForFirstVisit } from '../helpers/defaultValues';

export const useUpdateFirstVisitInputs = () => {
  const { watch, setValue, errors, clearErrors } = useFormContext();
  const dcnguid = watch(inputs.dcnguid.name);

  const updateFirstVisitInputs = (patient = null) => {
    if (patient) setValue(inputs.patient.name, [patient]);
    setValue(inputs.patientGuid.name, patient?.PatientGuid || '');
    setValue(inputs.dcnguid.name, patient?.DCNGuid || dcnguid);

    Object.entries(inputsForFirstVisit).forEach(([inputName, inputValue]) => {
      const value = patient ? patient[inputName] : inputValue;

      if (value && errors[inputName]) clearErrors(inputName); // Clear error message
      if (inputName === inputs.birthDay.name) {
        const birthDay = value ? new Date(value) : null;
        return setValue(inputName, birthDay);
      }

      return setValue(inputName, value); // Update input value
    });
  };

  return { updateFirstVisitInputs };
};
