/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const MenuItemRender = ({ item }) => {
  return (
    <div id={item.id} className="p-menuitem-content" onClick={item.command}>
      <a className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
      </a>
    </div>
  );
};

export default MenuItemRender;
