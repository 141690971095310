import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import SuccessPage from '../../../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const Success = () => {
  const { reset, getValues, isNew } = useFormContext();

  useEffect(() => {
    reset(getValues(), { keepValues: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patients = getValues(inputs.patient.name);
  const patient = patients[0];
  const patientName = `${patient[inputs.lastName.name]},  ${patient[inputs.firstName.name]}`;
  const successMessage = isNew
    ? `${t('You_recorded_new_insurance_policy_for')} ${patientName}`
    : `${t('You_recorded_new_insurance_policy_for')} ${patientName}`;

  return (
    <SuccessPage successMessage={successMessage}>
      <SuccessActionButtons patientsList={patients} />
    </SuccessPage>
  );
};

export default Success;
