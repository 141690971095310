import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { inputs } from '../../../../../helpers/inputs';
import cx from 'classnames';

const FeeCodeLayout = ({ rowOptions }) => {
  const feeCode = rowOptions.rowData[inputs.feeCodes.codeType][0];

  return (
    <>
      <Tooltip target={`.groupPickerFeeTooltip_${feeCode.value}`} position="top" />
      <span className={cx(`groupPickerFeeTooltip_${feeCode.value}`)} data-pr-tooltip={feeCode.label}>
        {feeCode.value}
      </span>
    </>
  );
};

export default FeeCodeLayout;
