import React from 'react';
import { useFormContext } from 'react-hook-form';

import CommonTimeInput from './Common/CommonTimeInput';
import RecommendedTime from '../../Layouts/RecommendedTime';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { isRecommendetTime } from '../../../helpers/isRecommendetTime';
import { startTimeInputRequirements } from '../../../helpers/validationSchema';
import { isTimeValid } from '../../../../../../utils/formatTime';
import { calculateUnits } from '../../../helpers/calculateUnits';
import { useValidation } from '../../../hooks/useValidation';
import { totalValue } from '../../../helpers/totalValue';
import { map } from 'lodash';

const StartTime = ({ className = '' }) => {
  const { watch, setValue, isGroup, isNew, errors } = useFormContext();
  const { clearCommentError } = useValidation();
  const emergency = watch(inputs.emergency.name);
  const feeCodes = watch(inputs.feeCodes.codeType);
  const feeAmount = watch(inputs.feeAmount.name);
  const saveAsDraft = watch(inputs.saveAsDraft.name);
  const speciality = watch(inputs.speciality.name);
  const invoiceType = watch(inputs.payor.name);
  const startTime = watch(inputs.startTime.name);
  const feeCodesOnly = map(feeCodes, (i) => i.value);
  const required = !isGroup && startTimeInputRequirements(emergency, feeCodesOnly, saveAsDraft, speciality, invoiceType);
  const isRecommendet = isRecommendetTime({ feeCodes }) && !isGroup && !errors[inputs.startTime.name]?.message;

  const onChange = (time, field) => {
    field.onChange(time);
    const percent = watch(inputs.percent.name);
    const endTime = watch(inputs.endTime.name);
    const isFeeCodesValid = Array.isArray(feeCodes) && feeCodes?.length;
    const feeCode = isFeeCodesValid ? feeCodes[0] : undefined;
    const isValidTime = isTimeValid(time) && isTimeValid(endTime);

    // Clear error message for commen input
    clearCommentError({ startTime: time });

    // Update Units on edit teleplan
    if (!isNew && feeCode && isValidTime) {
      const units = calculateUnits(feeCode, time, endTime);
      const feeTotal = totalValue(feeAmount, units, percent);
      setValue(inputs.units.name, units);
      setValue(inputs.feeTotal.name, feeTotal);
    }
  };

  const footerContent = !isTimeValid(startTime) && isRecommendet ? <RecommendedTime /> : null;

  return (
    <CommonTimeInput
      className={className}
      id={elementIDs.startTimeInput}
      input={inputs.startTime}
      footerContent={footerContent}
      required={required}
      onChange={onChange}
    />
  );
};

export default StartTime;
