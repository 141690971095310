/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { elementIDs } from '../../../../../../../config/elementIDsConfig';
import cx from 'classnames';

const TextAreaInput = ({ field }) => {
  const { errors } = useFormContext();
  const textareaRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // VER-683 - Edit teleplan -> long notes do not fit the field
  useEffect(() => {
    if (field.value !== undefined && !isLoaded) {
      resizeTextarea();
      setIsLoaded(true);
    }
  }, [field.value]);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  return (
    <InputTextarea
      ref={textareaRef}
      className={cx({ 'p-invalid': errors[field.name]?.message })}
      id={elementIDs.noteInput}
      type="text"
      value={field.value}
      rows={2}
      cols={30}
      autoResize
      onChange={(e) => field.onChange(e.target.value)}
    />
  );
};

export default TextAreaInput;
