import { payeeList } from '../../../helpers/mapper';

export const convertInvoiceType = (invoiceType, isShort) => {
  // If invoice type is short (i.e. M, W, I) then return label (i.e. MSP, WSBC, ICBC)
  if (isShort) {
    const label = payeeList.find((i) => i.short === invoiceType)?.label;
    return label;
  }

  // If invoice type is not short (i.e. MSP, WSBC, ICBC) then return short value (i.e. M, W, I)
  if (!isShort) {
    const short = payeeList.find((i) => i.label === invoiceType)?.short;
    return short;
  }

  return invoiceType;
};
