import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useStorage } from '../../../../../hooks/useStorage';
import { localStorageKeys } from '../../../../../config/localStorageKeysConfig';
import { daysToMilliseconds } from '../../../../../utils/daysToSeconds';
import { inputs } from '../../helpers/inputs';

const WeekendDialog = () => {
  const key = localStorageKeys.doNotShowEmergencyServiceDialog;
  const { watch, onSubmit, localState, setLocalState, isGroup } = useFormContext();
  const [checked, setChecked] = useState(false);
  const [localStorageValue, setValueInStorage] = useStorage(key);

  useEffect(() => {
    setChecked(localStorageValue);
  }, [localStorageValue]);

  const handleCheck = (checked) => {
    setChecked(checked);
    setValueInStorage(checked, daysToMilliseconds(1)); // Set expiration to 24 hours (1 day)
  };

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      weekendValidationDialog: false
    }));
  };

  const proseedSubmission = () => {
    onHide();
    onSubmit({ ...watch(), [inputs.ignoreValidation.name]: true });
  };

  const actionButtons = (
    <>
      <Button id={elementIDs.ignoreButton} label={t('Ignore')} onClick={proseedSubmission} />
      <Button id={elementIDs.goBackButton} className="p-button-outlined" label={t('Go_back')} onClick={onHide} />
    </>
  );

  const message = () => {
    if (isGroup) return `${t('One_of_the_selected_fee_codes_might_qualify_for_out_of_office_premiums')}.`;
    return `${t('You_may_qualify_for_the_out_of_office_premiums')}.`;
  };

  return (
    <Dialog
      id={elementIDs.weekendValidationDialog}
      header={t('Warning')}
      visible={localState.weekendValidationDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg">{message()}</div>

      <div className="p-field-checkbox pt-4">
        <Checkbox className="mr-3" type="checkbox" inputId="dontShowThisAgainToday" checked={checked} onChange={(e) => handleCheck(e.checked)} />
        <label htmlFor="dontShowThisAgainToday">{t('Dont_show_this_again_today')}</label>
      </div>
    </Dialog>
  );
};

export default WeekendDialog;
