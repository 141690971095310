import React, { useState } from 'react';
import { PatientBillingHistoryContext } from '../../../../modules/contexts/contexts';
import { defaultRequestParams } from '../helpers/defaultValues';
import { patientLastNameWithInitials } from '../../../../modules/utils/patientFullName';
import { initStateForPagination } from '../../../../modules/config/defaultValuesConfig';
import { t } from '../../../../service/localization/i18n';

const PatientBillingHistoryProvider = ({ initPatient, patientsList, ...props }) => {
  const [state, setState] = useState({
    ...initStateForPagination,
    ...defaultRequestParams,
    claim: props.claim,
    emptyMessage: props.emptyMessage || t('No_records_found'),
    selectedItem: null,
    loading: false,
    PatientGuid: null,
    totalBilled: 0,
    totalPaid: 0,
    totalDue: 0,
    totalRecordsAlter: 0,
    claimList: [],
    filters: {
      patient: initPatient
        ? { ...initPatient, label: patientLastNameWithInitials(initPatient, true) }
        : { ...patientsList?.[0], label: patientLastNameWithInitials(patientsList?.[0], true) }
    },
    patientsList: patientsList?.map((i) => ({ ...i, label: patientLastNameWithInitials(i, true) })) || []
  });

  return <PatientBillingHistoryContext.Provider value={{ state, setState }}>{props.children}</PatientBillingHistoryContext.Provider>;
};

export default PatientBillingHistoryProvider;
