import React from 'react';
import { useFormContext } from 'react-hook-form';

import { inputs } from '../../helpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const CardTitle = () => {
  const { watch, isEdit } = useFormContext();
  const insurerNameEN = watch(inputs.insurerNameEN.name);

  const createTitle = t('Create_Insurance_Policy');
  const editTitle = t('Edit_Insurance_Policy');
  // const editTitle = `${t('Edit')} ${insurerNameEN}`;

  return (
    <div id={elementIDs.teleplanInvoiceTitle} className="flex align-items-center text-2xl font-bold">
      {isEdit ? editTitle : createTitle}
    </div>
  );
};

export default CardTitle;
