import React, { useRef } from 'react';

import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';
import CodesDescriptionTemplate from '../Layouts/CodesDescriptionTemplate';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { removeAllSpecialCharacters } from '../../../../../regex/regex';
import cx from 'classnames';

const CustomCodesInfoOverlay = ({ record }) => {
  const ref = useRef(null);
  const itemId = record.id.replaceAll(removeAllSpecialCharacters, '');

  return (
    <>
      {/* <OverlayPanel ref={ref} id={elementIDs.previewCodesDetailsTooltip}>
        <CodesDescriptionTemplate record={record} />
      </OverlayPanel>

      <i
        id={`${elementIDs.previewCodesDetailsIcon}_${record.id.replaceAll(removeAllSpecialCharacters, '')}`}
        className={cx("pi pi-info-circle ml-1 pointer", `billingInfoTooltip_${record.id}`)}
        onClick={(e) => ref.current.toggle(e)}
      /> */}

      <Tooltip id={elementIDs.previewReferralTooltip} className="locationTooltip" target={`.billingInfoTooltip_${itemId}`} position="top">
        <CodesDescriptionTemplate record={record} />
      </Tooltip>

      <i
        id={`${elementIDs.previewCodesDetailsIcon}_${record.id.replaceAll(removeAllSpecialCharacters, '')}`}
        className={cx('pi pi-info-circle ml-1 pointer', `billingInfoTooltip_${itemId}`)}
      />
    </>
  );
};

export default CustomCodesInfoOverlay;
