export const initialState = {
  searchQuery: '',
  notesList: [],
  noteDialog: {
    showDialog: false,
    note: undefined
  },
  deleteNoteDialog: {
    showDialog: false,
    note: undefined
  }
};
