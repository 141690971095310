import React from 'react';
import { useFormContext } from 'react-hook-form';

import ToggleCatalogsButton from '../../../modules/ActionButtons/ToggleCatalogsButton';
import PractitionerSection from '../../../modules/TeleplanInvoiceForm/Layouts/PractitionerSection';
import Payor from '../../../modules/TeleplanInvoiceForm/Inputs/Payor';
import PayorOptionalSectionPOS from '../../TeleplanPOS/PayorOptionalSectionPOS';
import ReasonFor01080 from '../../../modules/TeleplanInvoiceForm/Inputs/ReasonFor01080';
import BMI from '../../../modules/TeleplanInvoiceForm/Inputs/BMI';
import SubmissionCode from '../../../modules/TeleplanInvoiceForm/Inputs/SubmissionCode';
import Comment from '../../../modules/TeleplanInvoiceForm/Inputs/Comment';
import SubmitWithGenericPHN from '../../../modules/TeleplanInvoiceForm/Inputs/SubmitWithGenericPHN';

const InputSectionForTeleplanGroup = () => {
  const { localState } = useFormContext();

  return (
    <>
      <ToggleCatalogsButton />

      <PractitionerSection />

      {!localState.isEmptyPractitioner && (
        <>
          <Payor />

          <PayorOptionalSectionPOS />

          <ReasonFor01080 />

          <BMI />

          <SubmissionCode />

          <Comment />

          <SubmitWithGenericPHN />
        </>
      )}
    </>
  );
};

export default InputSectionForTeleplanGroup;
