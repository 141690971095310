import React from 'react';

import InputWrapper from '../InputWrapper/InputWrapper';
import { InputText } from 'primereact/inputtext';

import { toTitleCase } from '../../../modules/utils/toTitleCase';
import cx from 'classnames';

// interface IGender {
//   input: {
//     name: string,
//     codeType: string,
//   },
//   value: string,
//   placeholder: string,
//   onChange: () => void,
//   onBlur?: () => void,
//   required?: boolean,
//   errorMessage?: string,
//   ref?: any
// };

const AddressLine = React.forwardRef((props, ref) => {
  const name = props.input.name;

  const handleChange = (value) => {
    props.onChange && props.onChange(toTitleCase(value));
  };

  const handleBlur = (value) => {
    props.onBlur && props.onBlur(toTitleCase(value));
  };

  return (
    <InputWrapper name={name} label={props.input.label} required={props.required} errorMessage={props.errorMessage}>
      <InputText
        ref={ref}
        className={cx({ 'p-invalid': props.errorMessage })}
        id={name}
        type="text"
        value={props.value}
        placeholder={props.placeholder || ''}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
      />
    </InputWrapper>
  );
});

export default AddressLine;
