import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const BMISurgicalAssistForGroupDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      bmiSurgicalAssistForGroupDialog: false
    }));
  };

  const emergencyDialogFooter = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHide} autoFocus />;
  };

  return (
    <Dialog
      id={elementIDs.bmiSurgicalAssistForGroupDialog}
      header={t('Call-Out_Charges')}
      visible={localState.bmiSurgicalAssistForGroupDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={emergencyDialogFooter()}
      onHide={() => onHide()}
    >
      {t('Please_use_a_different_Create_Teleplan_screen_to_bill_13003')}
    </Dialog>
  );
};

export default BMISurgicalAssistForGroupDialog;
