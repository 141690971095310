import React from 'react';
import { useSelector } from 'react-redux';

import AddressLayout from '../../../../../modules/common/components/AddressLayout';
import { inputs } from '../../../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/inputs';
import { formatPhoneNumber } from '../../../../../modules/utils/formatPhoneNumber';
import { isPhysician } from '../../../../../modules/config/specialitiesConfig';
import { t } from '../../../../../service/localization/i18n';

const PayeeSection = ({ data }) => {
  const members = useSelector((state) => state.clinic.members);
  const payTo = data[inputs().payTo.name] ? data[inputs().payTo.name] : {};
  const currentPractitioner = members.find((member) => member.DoctorGuid === data.DoctorGuid);
  const isPhysicianPractitiner = currentPractitioner ? isPhysician(currentPractitioner.Speciality) : false;

  return (
    <>
      <div className="company-name">{t('Payee').toUpperCase()}</div>

      <div className="flex col-12 p-0 mb-3 font-bold">
        {payTo?.LastName ? `${payTo?.LastName?.toUpperCase()}, ${payTo?.FirstName?.toUpperCase()}` : payTo?.ClinicName}
      </div>

      <div className="flex">
        <div className="addressWrap mb-0">
          <AddressLayout data={payTo} />

          {isPhysicianPractitiner && (payTo?.Phone || payTo?.Email) && (
            <div>
              {payTo?.Phone && <div className="mb-1">{formatPhoneNumber(payTo?.Phone)}</div>}
              <div className="mb-1">{payTo?.Email}</div>
            </div>
          )}
        </div>
      </div>

      {/* GST number */}
      {payTo.GSTNumber && <div>{`${t('GST')} # ${payTo.GSTNumber}`}</div>}
    </>
  );
};

export default PayeeSection;
