import ClaimNotesTable from '../ClaimNotesTable/ClaimNotesTable';
import MailsLayout from '../../../mails/components/MailsLayout';

const ClaimNotesInbox = () => {
  return (
    <MailsLayout>
      {window.innerWidth < 768 && <h3 style={{ textAlign: 'center' }}>Claim Notes</h3>}
      <ClaimNotesTable />
    </MailsLayout>
  );
};

export default ClaimNotesInbox;
