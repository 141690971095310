import React from 'react';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';

const FeeBodyTemplate = ({ rowData }) => {
  const { isRequiredInput } = useUpdateRecords();
  const isRequired = isRequiredInput(rowData.id, 'fee');

  if (isRequired) {
    return (
      <span id={`${elementIDs.invoiceRecordsTimeBodyTemplate}_${'fee'}`} className="p-error">
        {t('Required')}
      </span>
    );
  }

  return <span style={{ color: 'var(--primary-color)' }}>{`${currencyFormat(rowData.fee)}`}</span>;
};

export default FeeBodyTemplate;
