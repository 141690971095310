import actions from './upload.action.types';
import axios from 'axios';
import { store } from '../../../store';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase, setAuthHeader } from '../../../config';
import { calculateFirst } from '../../utils/calculate';
import { t } from '../../../service/localization/i18n';

export const getFiles = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize, pageNumber } = data || { pageNumber: 1, pageSize: 10 };

  return axios
    .get(`${getApiBase()}/upload/${DCNGuid}/${pageNumber ?? 1}/${pageSize ?? 10}`)
    .then((results) => {
      const page = results.data.page;
      const pageSize = results.data.pageSize;
      return {
        ...results.data,
        first: calculateFirst({ page, pageSize })
      };
    })
    .catch((error) => {
      console.log('!!!getFiles', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const updateStatus = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const { DCNGuid } = data || {};
  return axios
    .put(`${getApiBase()}/upload/${DCNGuid}`, data)
    .then((results) => results)
    .catch((error) => {
      console.log('!!!updateStatus', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
