import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AutoComplete } from 'primereact/autocomplete';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useInsuranceCompany } from '../../../hooks/useInsuranceCompany';
import { useValidation } from '../../../hooks/useValidations';
import { inputs } from '../../../helpers/inputs';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const InsurancePicker = React.forwardRef((props, ref) => {
  const [filtered, setFiltered] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const errorMessage = props.errorMessage || (noResultsFound ? t('No_results_found') : '');

  // Set init value
  useEffect(() => {
    updateLocalValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  // Set suggestion list
  useEffect(() => {
    if (props.suggestions) {
      setSuggestions(props.suggestions);
    }
  }, [props.suggestions]);

  const updateLocalValue = () => {
    if (typeof props.value === 'string') {
      return setSelectedValue({
        name: props.value || '',
        label: props.suggestions?.find((i) => i.name?.toLowerCase() === props.value?.toLowerCase())?.label || ''
      });
    }

    setSelectedValue(props.value);
  };

  const search = (event) => {
    let _filtered;

    if (!event.query.trim().length) {
      _filtered = [...suggestions];
    } else {
      _filtered = suggestions.filter((item) => {
        return item.label.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    _filtered?.length ? setNoResultsFound(false) : setNoResultsFound(true);
    setFiltered(_filtered);
  };

  const onSelect = (e) => {
    props.onSelect(e.value);
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      updateLocalValue();
      noResultsFound && setNoResultsFound(false);
    }
  };

  const onFocus = () => {
    setSelectedValue(null);
  };

  return (
    <InputWrapper name={props.name} label={inputs.insurerID.label} required errorMessage={errorMessage}>
      <AutoComplete
        className={cx({ 'p-invalid': props.isInvalid || noResultsFound }, 'flex')}
        inputClassName="w-full"
        field={props.field || 'label'}
        inputRef={ref}
        inputId={props.inputId}
        value={selectedValue}
        suggestions={filtered}
        forceSelection
        autoHighlight
        dropdown
        disabled={props.disabled}
        emptyMessage={t('N_A')}
        scrollHeight={dropdownScrollHeight()}
        completeMethod={search}
        onChange={(e) => setSelectedValue(e.value)}
        onSelect={onSelect}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </InputWrapper>
  );
});

const InsuranceCompany = () => {
  const { control, errors, isEdit, getValues } = useFormContext();
  const { insurerCompanyValidation } = useValidation();
  const { options, onSelect } = useInsuranceCompany();
  const insurerNameEN = getValues(inputs.insurerNameEN.name);

  const name = inputs.insurerID.name;
  const errorMessage = errors[name]?.message;

  if (isEdit) return <div className="text-3xl font-bold text-center pb-4">{insurerNameEN}</div>;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => insurerCompanyValidation(value) }}
      render={({ field }) => (
        <InsurancePicker
          {...field}
          inputId={field.name}
          isInvalid={!!errorMessage}
          errorMessage={errorMessage}
          suggestions={options}
          disabled={isEdit}
          onSelect={onSelect}
        />
      )}
    />
  );
};

export default InsuranceCompany;
