import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { patientPeriodDropdownOptions } from '../../../helpers/dropdownOptions';

const TermPrimary = () => {
  const name = inputs.termPrimary.name;
  const { control } = useFormContext();

  const patientPeriodInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.termPrimary.label}>
        <Dropdown id={field.name} options={patientPeriodDropdownOptions} value={field.value} onChange={(e) => field.onChange(e.value)} />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => patientPeriodInput(field)} />;
};

export default TermPrimary;
