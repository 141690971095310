import React from 'react';
import DateOfBirth from './DateOfBirth';
import InputWrapper from '../../InputWrapper/InputWrapper';

// interface IWrappedDateOfBirth {
//   name: string
//   label: string
//   value: string
//   onChange: (e) => void
//   onBlur?: (e) => void
//   required?: boolean
//   isInvalid?: boolean
//   errorMessage?: string
// }

const WrappedDateOfBirth = (props) => {
  return (
    <InputWrapper name={props.name} label={props.label} required={props.required} errorMessage={props.errorMessage}>
      <DateOfBirth {...props} isInvalid={props.errorMessage} />
    </InputWrapper>
  );
};

export default WrappedDateOfBirth;
