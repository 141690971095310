import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Card } from 'primereact/card';

import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Stepper from '../../../modules/Layouts/Stepper';
import FormTitle from '../../../modules/Layouts/FormTitle';
import SubmitProcessing from '../../../modules/Layouts/SubmitProcessing';
import Success from '../../../modules/Layouts/Success';
import StepsWrap from '../../../modules/Steps/StepsWrap';

import { steps } from '../../../config/steps';

const Steps = () => {
  const { handleSubmit, onSubmit, localState } = useFormContext();

  return (
    <StepsWrap>
      {localState.step < steps.submitPreview && (
        <Card className="w-full container">
          <div className="flex flex-column gap-3 eClaimForm">
            <Stepper />
            <FormTitle />
            <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
              {localState.step === steps.stepOne && <StepOne />}
              {localState.step === steps.stepTwo && <StepTwo />}
            </form>
          </div>
        </Card>
      )}

      {/* Submit processing view */}
      {localState.step === steps.submitProcessing && <SubmitProcessing />}
      {/* Results view*/}
      {localState.step === steps.submitPreview && <StepThree />}
      {/* Success view */}
      {localState.step === steps.success && <Success />}
    </StepsWrap>
  );
};

export default Steps;
