import React from 'react';
import cx from 'classnames';

const TimelineMarker = ({ item }) => {
  return (
    <>
      <span className={item.status}>
        <i className={cx(item.icon, 'text-sm')} />
      </span>
    </>
  );
};

export default TimelineMarker;
