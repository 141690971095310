import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import ReferralCatalogsTable from '../../../../../../../components/Widgets/ReferralCatalogsTable/ReferralCatalogsTable';

import { deleteReferral } from '../../../../../../practitioners/actions/practitioners.action.creators';
import { updateInvoicesQuickPickCodesList } from '../../../../../actions/claims.action.creators';
import { useGroupPickerInputs } from '../../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { useGroupCatalogs } from '../../../views/TeleplanGroup/hooks/useGroupCatalogs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { inputs } from '../../../helpers/inputs';

const ReferralCatalogs = ({ scrollHeight, catalogsState, isGroup }) => {
  const dispatch = useDispatch();
  const { getValues, localState, setLocalState } = useFormContext();
  const { onReferralChange } = useGroupPickerInputs();
  const { onCodeSelect } = useGroupCatalogs();
  const { onChange } = useCodePickerActions(inputs.referral);
  const catalogsCodesList = catalogsState?.tableData?.codeList;

  const onDelete = async (deletingData) => {
    setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: true }));
    const result = await deleteReferral(deletingData.value);
    if (result) {
      setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: false }));

      const mostRecentUpdatedList = catalogsCodesList?.filter((i) => i.value !== deletingData.value);

      if (isGroup) {
        // Remove code for each row that have this code
        localState.groupRecords?.forEach((record, index) => {
          const currentReferralCode = record?.[inputs.referral.codeType]?.[0];
          if (currentReferralCode && currentReferralCode?.value === deletingData.value) {
            onReferralChange([], { rowData: record, rowIndex: index });
          }
        });
      } else {
        const currentReferralNumber = getValues(inputs.referral.codeType);
        if (currentReferralNumber.some((i) => i.value === deletingData.value)) {
          onChange({ value: [] });
        }
      }

      // CMO-3047 - FE should refresh list of referrals after delete from catalogue
      dispatch(updateInvoicesQuickPickCodesList(mostRecentUpdatedList, inputs.referral.codeType));
    } else {
      setLocalState((prevState) => ({ ...prevState, isLoadingCatalogsData: false }));
    }
  };

  const onRowClick = (code) => {
    if (isGroup) {
      onCodeSelect(code, { catalogsState });
    } else {
      onChange({ value: [code] });
    }
  };

  return <ReferralCatalogsTable scrollHeight={scrollHeight} catalogsState={catalogsState} onRowClick={onRowClick} onDelete={onDelete} />;
};

export default ReferralCatalogs;
