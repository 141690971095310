import React, { useState } from 'react';
import { NotesContext } from './context';
import { initialState } from '../../helpers/initialState';

const NotesProvider = (props) => {
  const [notesState, setNotesState] = useState(initialState);

  return <NotesContext.Provider value={{ notesState, setNotesState, ...props }}>{props.children}</NotesContext.Provider>;
};

export default NotesProvider;
