import React, { useState } from 'react';
import { InvoiceTimelineContext } from '../../../../../modules/contexts/contexts';

const InvoiceTimelineProvider = (props) => {
  const [state, setState] = useState({ invoice: props.invoice });

  return <InvoiceTimelineContext.Provider value={{ state, setState }}>{props.children}</InvoiceTimelineContext.Provider>;
};

export default InvoiceTimelineProvider;
