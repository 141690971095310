import { getSpeciality } from '../../config/specialitiesConfig';
import { commonInputs } from '../../config/commonInputsConfig';

export const updateQuickPickCodesList = (catalogMostRecentCodes, speciality) => {
  let updatedCatalogList = catalogMostRecentCodes;

  // add ICD9 code
  if (parseInt(speciality, 10) === 80) {
    const icd9 = commonInputs.icd9.codeType;
    // eslint-disable-next-line eqeqeq
    const suspect = getSpeciality(speciality); // [KS] Don't make a strict comparison!
    updatedCatalogList = {
      ...updatedCatalogList,
      [icd9]: checkIfUniq(catalogMostRecentCodes, icd9, suspect.icd9)
    };
  }

  if (updatedCatalogList?.feecodes_pp?.length) {
    updatedCatalogList = {
      ...updatedCatalogList,
      feecodes_pp: updatedCatalogList?.feecodes_pp?.slice(0, 12) // show only 12 first services
    };
  }

  return updatedCatalogList;
};

const checkIfUniq = (catalogMostRecentCodes, category, newCodes) => {
  const mostRecentCodes = catalogMostRecentCodes[category];
  const isUniq = mostRecentCodes?.some((i) => newCodes?.some((x) => x.value === i.value)) ? mostRecentCodes : [...newCodes, ...mostRecentCodes];

  return isUniq;
};
