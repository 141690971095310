import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';
import { InputWrapper } from '../../../../components/Inputs';

const DateFrom = () => {
  const name = 'PeriodFrom';
  const { control } = useFormContext();

  const onChange = (date, field) => {
    field.onChange(date);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name="dateFrom" label="From" style={{ height: 'auto', width: '100%' }}>
          <Calendar
            value={field.value}
            dateFormat="mm/dd/yy"
            placeholder="MM/DD/YYYY"
            mask="99/99/9999"
            showIcon
            showButtonBar
            showOnFocus={false}
            onChange={(e) => onChange(e.value, field)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default DateFrom;
