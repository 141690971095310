import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { getClaimDetails } from '../../actions/claims.action.creators';
import { setClaimInfo } from '../../actions/claims.action.creators';

import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Skeleton } from 'primereact/skeleton';
import ViewPanel from '../../components/ViewPanel';
import CustomAvatar from '../../../common/components/CustomAvatar';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import ClaimMenu from './ClaimMenu';
import ClaimNotes from './ClaimNotes';

import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const ClaimDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const { claim_details, isFetchingClaims } = useSelector((state) => state.claims);
  const { isMobileOnly } = useSelector((state) => state.core.window);

  const fetchClaimDetails = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getClaimDetails(id);
      dispatch(setClaimInfo(result));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  }, [dispatch, id]);

  useEffect(() => {
    fetchClaimDetails();
  }, [fetchClaimDetails, claim_details?.InvoiceGuid]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeIndex]);

  const handleTabChange = (index) => {
    setActiveIndex(index);
  };

  const header = () => {
    return (
      <div className="flex col-12 p-2 mb-3">
        <div className="flex col-9 p-0 align-items-start">
          <div className="flex align-items-center mr-3">
            {isFetchingClaims ? (
              <Skeleton size="3rem" />
            ) : (
              <CustomAvatar id={claim_details.InvoiceGuid} label={claim_details.LastName?.trim().charAt(0).toUpperCase()} />
            )}
          </div>

          <div className="flex flex-column justify-content-center">
            <div
              className={cx('flex align-items-center mb-1 font-bold', isMobileOnly ? 'medium-size-text' : 'large-text')}
              style={{ lineHeight: '18px' }}
            >
              {isFetchingClaims ? (
                <Skeleton width="10em" />
              ) : (
                <div className="flex align-items-center gap-3">MSP Claim for {claim_details.FullName?.trim()}</div>
              )}
            </div>
            <div className="mb-1">
              {isFetchingClaims ? (
                <Skeleton className="mt-1" width="10em" />
              ) : (
                <div className="flex flex-column">
                  <span>Of. #{claim_details?.RecordNo}</span>
                  {claim_details?.SequenceNum && <span>Sq. #{claim_details.SequenceNum}</span>}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex align-items-start justify-content-end col-3 p-0">
          <>
            <ClaimMenu claimRecord={claim_details} closeMenuIcon={false} notesCounter={false} />
            <Button className="p-button-text p-button-rounded ml-2" icon="pi pi-times" onClick={() => history.goBack()} />
          </>
        </div>
      </div>
    );
  };

  const notesCounter = !!claim_details?.TotalNotes ? <Badge className="ml-2" value={claim_details.TotalNotes} severity="info" /> : null;

  return (
    <div className="flex col-12 p-0 justify-content-center">
      <div className="containerXL subMenuRoot mt-4 px-3 w-full">
        {header()}
        <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
          <TabPanel header={t('Profile')} headerClassName="extra-large-text">
            <div className="flex justify-content-start">
              {loading ? <CircularProgress /> : <ViewPanel showPagination={false} notesCounter={false} />}
            </div>
          </TabPanel>
          <TabPanel header={t('Notes')} headerClassName="extra-large-text" rightIcon={notesCounter}>
            <div className="pt-3">
              <ClaimNotes invoiceGuid={claim_details?.InvoiceGuid} />
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default ClaimDetails;
