import React from 'react';
import { useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import TimeSection from '../../TeleplanInvoiceForm/Layouts/TimeSection';
import OutOfOfficeHoursPremiums from '../../TeleplanInvoiceForm/Inputs/Common/OutOfOfficeHoursPremiums';

import { hideEmergency } from '../../../helpers/inputsConditions';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { inputs } from '../../../helpers/inputs';

const QuickPickEmergency = () => {
  const { control, isNew, isGroup } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const hideEmergencySection = hideEmergency({ isGroup, isNew, speciality, invoiceType });

  return (
    <div id={elementIDs.quickPickTimePickerBlock} className="w-full -mb-3">
      <div className="teleplan_catalogs_time_picker_section">
        {!hideEmergencySection && (
          <div id={elementIDs.quickPickEmergencyBlock} className="teleplan_catalogs_outOfOfficeHoursPremiums">
            <OutOfOfficeHoursPremiums />
          </div>
        )}

        <TimeSection inputsClassName="teleplan_catalogs_time_inputs" />
      </div>
    </div>
  );
};

export default QuickPickEmergency;

// ================= OLD LAYOUT ================= //

// import React from 'react';
// import { useWatch } from 'react-hook-form';

// import Emergency from '../../TeleplanInvoiceForm/Inputs/Emergency';
// import TimeSection from '../../TeleplanInvoiceForm/Inputs/Sections/TimeSection';
// import EmergencySection from '../../TeleplanInvoiceForm/Inputs/Sections/EmergencySection';

// import { elementIDs } from '../../../../../config/elementIDsConfig';
// import { inputs } from '../../helpers/inputs';

// const QuickPickEmergency = () => {
//   const { isNew, control, isGroup } = useFormContext();
//   const emergency = useWatch({ control, name: inputs.emergency.name });

//   const isTablet = window.innerWidth < 1270;
//   const emergencySwitchIsTablet = window.innerWidth < 1410;
//   const isEmergencyColumn = window.innerWidth < 1440;
//   const labelStyles = isEmergencyColumn ? { paddingRight: '0px', marginBottom: '6px' } : { paddingRight: '20px' };
//   const emergencyLabelStyles = emergencySwitchIsTablet ? { paddingRight: '0px', marginBottom: '6px' } : { paddingRight: '20px' };
//   const dir = isEmergencyColumn ? 'column' : 'row';

//   const emergencySection = () => {
//     if (isTablet) return <EmergencySection />;

//     return (
//       <EmergencySection
//         dir={dir}
//         labelStyle={labelStyles}
//         className='teleplan_catalogs_emergency_section'
//       />
//     )
//   };

//   const emergencySwitch = () => {
//     if (emergencySwitchIsTablet) return <EmergencySection />;

//     return (
//       <div id='catalog-emergency-switch'>
//         <Emergency labelStyle={emergencyLabelStyles} />
//       </div>
//     );
//   };

//   return (
//     <div>
//       {/* CMO-2244 - Add Emergency section to 1st visit and Create Teleplan screens in the Quick Pick tab */}
//       {emergency && isNew && !isGroup && (
//         <div id={elementIDs.quickPickEmergencyBlock}>
//           {emergencySection()}
//         </div>
//       )}

//       <div id={elementIDs.quickPickTimePickerBlock} className='teleplan_catalogs_time_picker_section -mb-3'>
//         {!emergency && isNew && !isGroup && (
//           <div className='pr-5'>
//             {emergencySwitch()}
//           </div>
//         )}

//         <div style={{ maxWidth: '400px' }}>
//           <TimeSection />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default QuickPickEmergency
