import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import classes from './InputWithClear.module.scss';
import cx from 'classnames';
import { debounce } from 'lodash';
import { t } from '../../../service/localization/i18n';

const InputWithClear = ({ id, initValue, placeholder, onChange, isMobile, isMobileOnly, isLandscape, showOnPatientDetailsScreen = false }) => {
  const [value, setValue] = useState('');
  const searchRef = useRef(null);
  const _classes = cx(
    isMobileOnly && !isLandscape && value.length === 0 ? 'pl-2 pr-1' : isMobileOnly && !isLandscape && value.length > 0 ? 'pl-2 pr-6' : 'pl-6 pr-6',
    classes.input
  );

  // [KS] CMO-832 - Top search filter looses the keyword after opening and closing a record, but the filter stays on
  useEffect(() => {
    setValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (e) => {
    setValue(e.target.value);
    debouncedRequest({ target: { id: id, value: e.target.value } });
  };

  const debouncedRequest = useCallback(
    debounce((searchParams) => {
      onChange(searchParams);
    }, 1000),
    []
  );

  const clearInput = () => {
    setValue('');
    onChange({ target: { id: id, value: '' } });
    searchRef?.current?.focus();
  };

  return (
    //Google-styled input
    // <div className={cx("flex align-items-center justify-content-center", classes.bar)}>
    //   <input className={classes.searchbar} type="text" title="Search" />
    // </div>

    <div className={classes.inputRoot}>
      {!showOnPatientDetailsScreen && (
        <>
          {(!isMobileOnly || (isMobileOnly && isLandscape)) && <i className={cx('pi pi-search', classes.searchIcon)} />}

          <InputText
            id={id}
            ref={searchRef}
            autoFocus={isMobile ? false : true}
            value={value}
            className={_classes}
            placeholder={placeholder}
            onChange={(e) => handleFieldChange(e)}
          />
        </>
      )}

      {value?.length > 0 && (
        <Button
          className={cx('p-button-text', isMobile ? classes.x_button_mobile : classes.x_button)}
          icon="pi pi-times"
          tooltip={t('Clear')}
          tooltipOptions={{ position: 'bottom' }}
          onClick={clearInput}
        />
      )}
    </div>
  );
};

export default InputWithClear;
