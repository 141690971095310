import { useDispatch } from 'react-redux';
import { dataForSubmit } from '../helpers/dataForSubmit';
import { createEClaim, editEClaim } from '../../../actions/claims.action.creators';
import { inputs } from '../helpers/inputs';
import { steps } from '../config/steps';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { t } from '../../../../../service/localization/i18n';

export const useSubmit = ({ reset, localState, setLocalState, isNew }) => {
  const dispatch = useDispatch();

  // const onSubmit = (eClaim) => {
  //   // Create new
  //   if (isNew) return onCreate(eClaim);

  //   // Edit invoice
  //   if (!isNew) return onEdit(eClaim);
  // };

  const onSubmit = async (eClaim) => {
    const sendNow = localState.sendNow;
    const formattedData = dataForSubmit({ ...eClaim, [inputs.sendNow.name]: sendNow });

    if (sendNow) {
      // On submit show submit processing step
      setLocalState((prevState) => ({ ...prevState, step: steps.submitProcessing }));
    } else {
      setLocalState((prevState) => ({ ...prevState, isCreating: !sendNow, isSubmitting: sendNow }));
    }

    let result;
    // Create new
    if (isNew) result = await createEClaim(formattedData);
    // Edit invoice
    if (!isNew) result = await editEClaim(formattedData);

    if (result) {
      const eClaimForReset = {
        ...eClaim,
        [inputs.claimGuid.name]: result?.ClaimGuid,
        [inputs.claimNumber.name]: result?.[inputs.claimNumber.name],
        [inputs.services.name]: formattedData?.[inputs.services.name]
      };

      setLocalState((prevState) => {
        const step = sendNow ? steps.submitProcessing : steps.success;
        return { ...prevState, step };
      });

      // reset the isDirty value and keep form state
      reset(eClaimForReset);
      !sendNow && setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false }));
    } else {
      // reset the isDirty value and keep form state
      reset({}, { keepValues: true });
      dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
      setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false }));
    }
  };

  const onCreate = async (eClaim) => {
    const sendNow = localState.sendNow;
    const formattedData = dataForSubmit({ ...eClaim, [inputs.sendNow.name]: sendNow });

    if (sendNow) {
      // On submit show submit processing step
      setLocalState((prevState) => ({ ...prevState, step: steps.submitProcessing }));
    } else {
      setLocalState((prevState) => ({ ...prevState, isCreating: !sendNow, isSubmitting: sendNow }));
    }

    const result = await createEClaim(formattedData);

    if (result) {
      const eClaimForReset = {
        ...eClaim,
        [inputs.claimGuid.name]: result?.ClaimGuid,
        [inputs.claimNumber.name]: result?.[inputs.claimNumber.name],
        [inputs.services.name]: formattedData?.[inputs.services.name]
      };

      setLocalState((prevState) => {
        const step = sendNow ? steps.submitProcessing : steps.success;
        return { ...prevState, step };
      });

      // reset the isDirty value and keep form state
      reset(eClaimForReset);
      !sendNow && setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false }));
    } else {
      // reset the isDirty value and keep form state
      reset({}, { keepValues: true });
      dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
      setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false }));
    }
  };

  const onEdit = async (eClaim) => {
    const sendNow = localState.sendNow;
    const formattedData = dataForSubmit({ ...eClaim, [inputs.sendNow.name]: sendNow });

    if (sendNow) {
      // On submit show submit processing step
      setLocalState((prevState) => ({ ...prevState, step: steps.submitProcessing }));
    } else {
      setLocalState((prevState) => ({ ...prevState, isCreating: !sendNow, isSubmitting: sendNow }));
    }

    const result = await editEClaim(formattedData);

    if (result) {
      const eClaimForReset = {
        ...eClaim,
        [inputs.claimGuid.name]: result?.ClaimGuid,
        [inputs.claimNumber.name]: result?.[inputs.claimNumber.name],
        [inputs.services.name]: formattedData?.[inputs.services.name]
      };

      // When submit processing is done then show submit preview step
      setLocalState((prevState) => {
        const step = sendNow ? steps.submitProcessing : steps.success;
        return { ...prevState, step };
      });

      // reset the isDirty value and keep form state
      reset(eClaimForReset);
      !sendNow && setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false }));
    } else {
      // reset the isDirty value and keep form state
      reset({}, { keepValues: true });
      const step = sendNow ? steps.submitPreview : steps.success;
      setLocalState((prevState) => ({ ...prevState, isCreating: false, isSubmitting: false, step }));
    }
  };

  return { onSubmit };
};
