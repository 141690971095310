import React from 'react';
import { useFormContext } from 'react-hook-form';

import FacilityInput from './Common/FacilityInput';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { defaulFacilitytCode } from '../../../config/defaultValues';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';

const Facility = () => {
  const { watch } = useFormContext();
  const { isLocked, onSavePrefs } = usePreferences();
  const name = inputs.facility.name;
  const label = inputs.facility.label;
  const currentFacility = watch(inputs.facility.name);
  const facility = !currentFacility ? defaulFacilitytCode : currentFacility;

  const onSavePreferences = () => {
    const preferences = [{ value: facility, label: prefsCodes.presetFacilityNum }];

    onSavePrefs(preferences);
  };

  return (
    <InputWrapper name={name} label={label} isLocked={isLocked(facility, prefsCodes.presetFacilityNum)} onSavePrefs={onSavePreferences}>
      <FacilityInput inputId={elementIDs.facilityInput} name={name} />
    </InputWrapper>
  );
};

export default Facility;
