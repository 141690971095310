import React from 'react';
import DeleteDialog from '../../../../../Dialogs/DeleteDialog/DeleteDialog';
import { initialState } from '../../helpers/initialState';
import { useNotesContext } from '../../hooks/useNotesContext';
import { decodeText } from '../../../../../../modules/utils/decodeText';

const DeleteNoteDialog = () => {
  const { notesState, setNotesState, onDeleteNote } = useNotesContext();
  const { showDialog, note } = notesState.deleteNoteDialog;
  const message = `Delete "${decodeText(note?.Title)}" comment?`;

  const onHide = () => {
    setNotesState((prevState) => ({ ...prevState, deleteNoteDialog: initialState.deleteNoteDialog }));
  };

  const accept = () => {
    onDeleteNote(note.NoteGuid);
  };

  return <DeleteDialog visible={showDialog} message={message} onHide={onHide} accept={accept} />;
};

export default DeleteNoteDialog;
