import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Card } from 'primereact/card';

import ContentWrap from '../Layouts/ContentWrap';
import CardTitle from '../Layouts/CardTitle';
import PreviewPanelActionButtons from '../ActionButtons/PreviewActionButtons';
import TeleplanInvoicePreview from '../TeleplanInvoicePreview/TeleplanInvoicePreview';

import { validateOffHours } from '../../helpers/validationSchema';
import { inputs } from '../../helpers/inputs';

const StepThree = () => {
  const { watch } = useFormContext();

  useEffect(() => {
    // Scroll page to the top
    window[`scrollTo`]({ top: 0 });

    // CheckOffHours warn message
    validateOffHours(watch(inputs.feeCodes.name), watch(inputs.startTime.name), watch(inputs.serviceDate.name));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentWrap>
      <Card className="teleplan_preview_card">
        <div className="flex justify-content-center pb-2">
          <CardTitle />
        </div>

        <TeleplanInvoicePreview />
        <PreviewPanelActionButtons />
      </Card>
    </ContentWrap>
  );
};

export default StepThree;
