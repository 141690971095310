import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import GroupPickerTableSettings from '../../views/TeleplanGroup/GroupPicker/GroupPickerTable/GroupPickerTableSettings/GroupPickerTableSettings';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const GroupTableSettingsDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      groupTabelSettingsDialog: false
    }));
  };

  const actionButtons = (
    <>
      <Button id={elementIDs.dialogCancel} label={t('Close')} onClick={onHide} />
    </>
  );

  return (
    <Dialog
      id={elementIDs.groupTabelSettingsDialog}
      header={t('Settings')}
      visible={localState.groupTabelSettingsDialog}
      breakpoints={{ '1366px': '40vw', '960px': '50vw', '768px': '95vw' }}
      style={{ width: '25vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="pt-3 text-lg">
        <GroupPickerTableSettings />
      </div>
    </Dialog>
  );
};

export default GroupTableSettingsDialog;
