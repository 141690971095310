import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GenericPHN } from '../../../../../../../components/Inputs';

const SubmitWithGenericPHN = () => {
  const { control } = useFormContext();

  return (
    <div className="pt-4">
      <GenericPHN control={control} />
    </div>
  );
};

export default SubmitWithGenericPHN;
