import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import InputErrorMessage from '../../../../../../../components/Messages/InputErrorMessage';

import { E_CLAIM_TERMS_AND_CONDITIONS } from '../../../../../../../config';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useValidation } from '../../../hooks/useValidation';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const TermsCheckbox = () => {
  const name = inputs.termsAndConditions.name;
  const { control, errors, setLocalState } = useFormContext();
  const { termsValidation } = useValidation();

  const onTermsClick = () => {
    setLocalState((prevState) => ({ ...prevState, showTermsDialog: true }));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => termsValidation(value) }}
      render={({ field, fieldState }) => (
        <>
          <div className="p-field-checkbox pt-2 pb-2">
            <Checkbox
              type="checkbox"
              className={cx('mr-3', { 'p-invalid': fieldState.error })}
              id={elementIDs.authorizationFormCheckbox}
              inputId={name}
              checked={field.value}
              onChange={(e) => field.onChange(e.checked)}
            />
            <label htmlFor={name}>
              {t('I_accept_the')}
              <a
                onClick={onTermsClick}
                // target='_blank'
                rel="noreferrer"
                // href={E_CLAIM_TERMS_AND_CONDITIONS}
                className="underline pointer pl-2"
              >
                {inputs.termsAndConditions.label}
              </a>
            </label>
          </div>
          <InputErrorMessage message={errors[name]?.message} />
        </>
      )}
    />
  );
};

export default TermsCheckbox;
