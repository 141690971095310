import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import MoreActionsButton from './MoreActionsButton';
import LegoDetailsViewSkeleton from './LegoDetailsViewSkeleton';
import CircularProgress from '../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { t } from '../../../service/localization/i18n';
import classes from './LegoDetailsView.module.scss';
import cx from 'classnames';
import _ from 'lodash';

const LegoDetailsView = ({
  data,
  page,
  title,
  sortby,
  filters,
  pageSize,
  itemsList,
  splitIcon,
  menuItems,
  setRecord,
  bodyLayout,
  isFetching,
  totalPages,
  itemIndex,
  getDataList,
  setItemInfo,
  contentFirst,
  totalRecords,
  onSplitClick,
  filterByPatient,
  setFilterByPatient
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, isMobileOnly, isTablet } = useSelector((state) => state.core.window);
  const { PatientGuid } = useSelector((state) => state.patients.patient_details);
  const currentRecord = contentFirst + 1;

  //component will unmount
  useEffect(() => {
    return () => {
      dispatch(setFilterByPatient(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOnePage = (dir) => {
    let newPage = '';
    const patientId = filterByPatient ? PatientGuid : 'undefined';
    if (dir === 'nextPage') newPage = page + 1;
    if (dir === 'prevPage') newPage = page - 1;
    if (dir === 'firstPage') newPage = 1;
    if (dir === 'lastPage') newPage = totalPages;

    const params = {
      PatientGuid: patientId,
      filters,
      sortby,
      page: newPage,
      rows: pageSize
    };

    dispatch(getDataList(params, true, dir));
  };

  const navigateOnPage = (dir) => {
    if (dir === 'nextPage') {
      if (itemsList?.length === itemIndex + 1) {
        getOnePage(dir);
      } else {
        const nextItem = itemsList?.find((i, index) => index === itemIndex + 1);
        setRecord(nextItem);
        //set item to highlight in in DataTable/DataScroller
        dispatch(setItemInfo(nextItem));
      }
    }

    if (dir === 'prevPage') {
      if (itemIndex === 0) {
        getOnePage(dir);
      } else {
        const prevItem = itemsList?.find((i, index) => index === itemIndex - 1);
        setRecord(prevItem);
        //set item to highlight in in DataTable/DataScroller
        dispatch(setItemInfo(prevItem));
      }
    }

    if (dir === 'firstPage') {
      if (page === 1) {
        setRecord(itemsList[0]);
        //set item to highlight in in DataTable/DataScroller
        dispatch(setItemInfo(itemsList[0]));
      } else {
        getOnePage(dir);
      }
    }

    if (dir === 'lastPage') {
      if (page === totalPages) {
        setRecord(itemsList[itemsList?.length - 1]);
        //set item to highlight in in DataTable/DataScroller
        dispatch(setItemInfo(itemsList[itemsList?.length - 1]));
      } else {
        getOnePage(dir);
      }
    }
  };

  return (
    <div className={isMobileOnly ? 'col-12 px-3' : 'flex justify-content-center'}>
      {isFetching && <CircularProgress />}

      <div className={!isMobileOnly ? classes.card : ''}>
        {_.isEmpty(data) && isFetching ? (
          <LegoDetailsViewSkeleton />
        ) : _.isEmpty(data) && !isFetching ? (
          <div className="flex flex-column align-items-center mt-5">
            <h5>{t('No_data_found')}</h5>
            <div>
              <Button className="p-button-text" label={t('Close')} icon="pi pi-times" onClick={() => history.goBack()} />
            </div>
          </div>
        ) : (
          <>
            <div className={isMobile ? classes.paginatorRoot : 'flex col-12 p-0'}>
              <div className={!isMobile ? 'col-10 p-0' : ''}>
                {/* <Paginator
                  first={contentFirst}
                  rows={1}
                  totalRecords={totalRecords}
                  onPageChange={onContentPageChange}
                  template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                /> */}

                <div className={cx('p-paginator p-component', isMobile ? '' : 'ml-6')}>
                  <Button
                    className={
                      contentFirst === 0 || isFetching
                        ? 'p-paginator-prev p-paginator-element p-link p-disabled'
                        : 'p-paginator-first p-paginator-element p-link'
                    }
                    icon="p-paginator-icon pi pi-angle-double-left"
                    onClick={() => navigateOnPage('firstPage')}
                  />
                  <Button
                    className={
                      contentFirst === 0 || isFetching
                        ? 'p-paginator-prev p-paginator-element p-link p-disabled'
                        : 'p-paginator-prev p-paginator-element p-link'
                    }
                    icon="p-paginator-icon pi pi-angle-left"
                    onClick={() => navigateOnPage('prevPage')}
                  />
                  <span className="p-paginator-current">{`${currentRecord} of ${totalRecords}`}</span>
                  <Button
                    className={
                      (page === totalPages && itemIndex === itemsList?.length - 1) || isFetching
                        ? 'p-paginator-next p-paginator-element p-link p-disabled'
                        : 'p-paginator-next p-paginator-element p-link'
                    }
                    icon="p-paginator-icon pi pi-angle-right"
                    onClick={() => navigateOnPage('nextPage')}
                  />
                  <Button
                    className={
                      (page === totalPages && itemIndex === itemsList?.length - 1) || isFetching
                        ? 'p-paginator-last p-paginator-element p-link p-disabled'
                        : 'p-paginator-last p-paginator-element p-link'
                    }
                    icon="p-paginator-icon pi pi-angle-double-right"
                    onClick={() => navigateOnPage('lastPage')}
                  />
                </div>
              </div>
              {/* Close button for desktop layout */}
              {!isMobile && (
                <div className="flex align-items-center col-2 p-0">
                  <Button
                    className={cx('p-button-text', classes.closeButton)}
                    label={t('Close')}
                    icon="pi pi-times"
                    onClick={() => history.goBack()}
                  />
                </div>
              )}
            </div>

            <div className="mb-2">
              {/* Close button for tablets layout */}
              {isTablet && (
                <div className="flex justify-content-end col-12 p-0 my-3">
                  <div className="flex">
                    <Button
                      className={cx('p-button-text', classes.closeButton)}
                      label={t('Close')}
                      icon="pi pi-times"
                      onClick={() => history.goBack()}
                    />
                  </div>
                </div>
              )}

              <div className={cx('mt-1', isMobileOnly ? 'contentMarginBottom' : 'shadow-1 p-3')}>
                {/* HEADER */}
                <div className={cx('flex justify-content-between mb-3 pb-3', classes.borderBottomLine)}>
                  <div className="flex flex-column align-items-start justify-content-center font-bold extra-large-text">{title}</div>

                  <div className="flex p-0 align-items-start mb-1">
                    <MoreActionsButton
                      data={data}
                      isMobileOnly={isMobileOnly}
                      splitIcon={splitIcon}
                      menuItems={menuItems}
                      onSplitClick={onSplitClick}
                    />

                    {/* Close (X) button for mobile layout */}
                    {isMobileOnly && (
                      <Button
                        className="p-button-rounded p-button-text mobileRoundedButtonSize"
                        icon="pi pi-times"
                        onClick={() => history.goBack()}
                      />
                    )}
                  </div>
                </div>

                {/* BODY */}
                <>{bodyLayout()}</>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LegoDetailsView;
