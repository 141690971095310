import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const requiredInputs = {
  [inputs.practitioner.name]: inputs.practitioner.name,
  [inputs.ruralCode.name]: inputs.ruralCode.name,
  [inputs.locationCode.name]: inputs.locationCode.name,
  [inputs.aoi.name]: inputs.aoi.name,
  [inputs.noi.name]: inputs.noi.name,
  [inputs.feeCodes.name]: inputs.feeCodes.name,
  [inputs.icd9.name]: inputs.icd9.name,
  [inputs.callTime.name]: inputs.callTime.name,
  [inputs.startTime.name]: inputs.startTime.name,
  [inputs.endTime.name]: inputs.endTime.name,
  [inputs.referral.name]: inputs.referral.name,
  [inputs.comment.name]: inputs.comment.name
};

const SaveAsDraft = () => {
  const name = inputs.saveAsDraft.name;
  const { control, setValue, errors, clearErrors } = useFormContext();

  const onChange = (checked, field) => {
    const status = checked ? 4 : 0;
    field.onChange(checked);
    setValue(inputs.status.name, status);

    // Clear error message for Comment input
    Object.values(requiredInputs).forEach((i) => {
      errors[i] && clearErrors(i);
    });
  };

  const saveAsDraftInput = (field) => {
    return (
      <div className="p-field-checkbox pt-3">
        <Checkbox
          className="mr-3"
          type="checkbox"
          id={elementIDs.saveAsDraft}
          inputId={name}
          checked={field.value}
          onChange={(e) => onChange(e.checked, field)}
        />
        <label htmlFor={name}>{inputs.saveAsDraft.label}</label>
      </div>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => saveAsDraftInput(field)} />;
};

export default SaveAsDraft;
