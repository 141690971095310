import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateNoteStatus } from '../../actions/claims.action.creators';
import { setClaimNoteDetails } from '../../../notes/actions/actions';

import { rowActionsMenuItems } from './helpers/rowActionsMenuItems';
import useClaimNotes from '../ClaimDetails/useClaimNotes';
import NoteTable from '../../../mails/components/NoteTable';
import { routes } from '../../../../routes/routes';

const ClaimNotesTable = () => {
  const { onDeleteNoteForMemos, fetchAllNotes, onSearchAll, loadingAllNotes } = useClaimNotes();
  const history = useHistory();
  const dispatch = useDispatch();
  const claimNotes = useSelector((state) => state.notes.claimNotes);

  useEffect(() => {
    // Fetch all notes
    fetchAllNotes({ page: claimNotes.page, pageSize: claimNotes.pageSize });
  }, []);

  const onRowSelect = (note) => {
    updateNoteStatus(note, 1);
    dispatch(setClaimNoteDetails(note));
    history.push(`${routes.claimNotesInbox.path}/view/${note.NoteGuid}`);
  };

  const handleDeleteNote = async (noteGuid) => {
    await onDeleteNoteForMemos(noteGuid);
  };

  return (
    <NoteTable
      notes={claimNotes}
      loading={loadingAllNotes}
      onSearchAll={onSearchAll}
      onSelectRowCallback={onRowSelect}
      fetchNotes={fetchAllNotes}
      onDeleteNote={handleDeleteNote}
      rowActionsMenuItems={rowActionsMenuItems}
      inboxType="claim"
    />
  );
};

export default ClaimNotesTable;
