export const loadData = (e, { sortOrder, sortField, fitRows, dataOnPageInplace, isPaginator, setPage }) => {
  const sortOrderValue = sortOrder === 1 ? 'asc' : 'desc';
  const params = {
    ...e,
    sortField,
    isPaginator,
    sortOrder: sortOrderValue
  };
  if (!params.page) params.page = 0;
  if (!params.rows) params.rows = fitRows;
  dataOnPageInplace(params);
};

export const loadData4Scroll = (e, dataTotalRows, dataSourceAppend, dataOnPageAppend, defaultPageSize) => {
  const params = { ...e };
  if (!params.page) params.page = 0;
  if (!params.rows) params.rows = defaultPageSize;
  if (params.first && params.first > 0) {
    params.page = params.first / params.rows;
  }

  let needRefresh = dataTotalRows === 0 || dataSourceAppend.length < dataTotalRows || e.first === 0;
  if (params.filters && params.params && params.filters !== params.params.filter) needRefresh = true;
  if (needRefresh) {
    dataOnPageAppend(params);
  }
};
