import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const ClearAllDxButton = () => {
  const { localState, setLocalState } = useFormContext();
  const { clearAllDxCodes } = useGroupPickerInputs();
  const disabled = !localState.groupRecords.some((i) => i[inputs.icd9.codeType]?.length);

  const onClick = () => {
    // Reset all dx codes
    clearAllDxCodes();
    // Set off for ApplyToAllDx
    setLocalState((prevState) => ({ ...prevState, latestDx: false, applyToAllDx: false }));
  };

  return (
    <Button
      className="p-button-text p-button-rounded"
      icon="pi pi-undo"
      tooltip={t('Clear_all_dx_codes')}
      tooltipOptions={{ position: 'top', showOnDisabled: true }}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default ClearAllDxButton;
