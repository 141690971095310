import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Context from '../../../../Context';
import PayTo from './PayTo';
import PrivateAddress from './PrivateAddress';
import { editClinic, editPractitioner } from '../../../clinic/actions/clinic.action.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { inputs } from './claimDetailsHelpers/inputs';
import { onPayToUpdate } from './claimDetailsHelpers/onClaimUpdate';
import { t } from '../../../../service/localization/i18n';
import { elementIDs } from '../../../config/elementIDsConfig';

const PayToAddress = ({ showAddressDialog, setShowAddressDialog }) => {
  const dispatch = useDispatch();
  const { privateRecord } = useContext(Context);

  const payToFullame = () => {
    const payToValue = privateRecord[inputs().payTo.name];
    if (!payToValue?.DoctorGuid) return payToValue?.ClinicName;

    if (payToValue?.DoctorGuid) {
      return payToValue?.FullName || `${payToValue?.LastName}, ${payToValue?.FirstName}`;
    }
  };

  const onPayToAddressSave = (data, closeDialog) => {
    if (data?.DoctorGuid) {
      // edit practitioner
      dispatch(
        editPractitioner(data, (newData) => {
          onPayToUpdate({ [inputs().payTo.name]: newData }, dispatch);
          dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
          closeDialog();
        })
      );
    } else {
      // edit clinic
      dispatch(
        editClinic(data, (newData) => {
          onPayToUpdate({ [inputs().payTo.name]: newData }, dispatch);
          dispatch(setToastMessage({ type: 'success', message: t('Your_changes_are_saved') }));
          closeDialog();
        })
      );
    }
  };

  return (
    <div id={elementIDs.payToAddressWrap}>
      <PrivateAddress
        value={privateRecord[inputs().payTo.name]}
        label={inputs().payToAddress.label}
        showInDialog={true}
        additionalInputs={<PayTo />}
        showAddressDialog={showAddressDialog}
        setShowAddressDialog={setShowAddressDialog}
        additionalAddressData={payToFullame()}
        onSave={onPayToAddressSave}
      />
    </div>
  );
};

export default PayToAddress;
