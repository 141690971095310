export const themes = [
  { label: 'Blue Grey - Green', value: 'bluegrey' },
  { label: 'Indigo - Pink', value: 'indigo' },
  { label: 'Pink - Amber', value: 'pink' },
  { label: 'Purple - Pink', value: 'purple' },
  { label: 'Deep Purple - Orange', value: 'deeppurple' },
  { label: 'Blue - Amber', value: 'blue' },
  { label: 'Light Blue - Blue Grey', value: 'lightblue' },
  { label: 'Cyan - Amber', value: 'cyan' },
  { label: 'Teal - Red', value: 'teal' },
  { label: 'Green - Brown', value: 'green' },
  { label: 'Light Green - Purple', value: 'lightgreen' },
  { label: 'Lime - Blue Grey', value: 'lime' },
  { label: 'Yellow - Teal', value: 'yellow' },
  { label: 'Amber - Pink', value: 'amber' },
  { label: 'Orange - Indigo', value: 'orange' },
  { label: 'Deep Orange - Cyan', value: 'deeporange' },
  { label: 'Brown - Cyan', value: 'brown' },
  { label: 'Grey - Indigo', value: 'grey' }
];
