import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const ClearAllCommentsButton = () => {
  const { setLocalState } = useFormContext();

  const onClick = () => {
    setLocalState((prevState) => {
      const resetedComments = prevState.groupRecords.map((i) => ({
        ...i,
        [inputs.comment.name]: ''
      }));

      return { ...prevState, groupRecords: resetedComments };
    });
  };

  return (
    <Button
      className="p-button-text p-button-rounded"
      icon="pi pi-undo"
      tooltip={t('Clear_all_comments')}
      tooltipOptions={{ showDelay: 500, position: 'top' }}
      onClick={onClick}
    />
  );
};

export default ClearAllCommentsButton;
