import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputTextarea } from 'primereact/inputtextarea';
import { InputWrapper } from '../../../../../../../../components/Inputs';

import { requiredInputs } from '../../../../helpers/inputs';
import cx from 'classnames';

// interface = {
//   input: {
//     name: string,
//     label: string
//     placeholder?: string
//   },
//   maxLength: number
// };

const TextAreaInput = ({ input, maxLength, rows }) => {
  const name = input.name;
  const { errors, control } = useFormContext();

  const lengthCounter = (field) => {
    return (
      <div className="flex justify-content-end w-full text-500">
        <div id={`${field.name}_symbolCounter`}>{`${field.value.length}/${maxLength}`}</div>
      </div>
    );
  };

  const textAreaInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={input.label}
        required={!!requiredInputs[name]}
        errorMessage={errors[name]?.message}
        style={{ height: 'auto' }}
        labelStyle={{ height: 'unset' }}
        footerContent={lengthCounter(field)}
      >
        <InputTextarea
          ref={field.ref}
          className={cx({ 'p-invalid': errors[name]?.message })}
          id={field.name}
          value={field.value}
          maxLength={maxLength}
          rows={rows || 3}
          cols={30}
          autoResize
          placeholder={input.placeholder || ''}
          onChange={(e) => field.onChange(e.target.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => textAreaInput(field)} />;
};

export default TextAreaInput;
