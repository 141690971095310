import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Context from '../../../../Context';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { inputs } from './claimDetailsHelpers/inputs';
import { multipleDoctorGuid, NA_DoctorGuid } from '../../../config/defaultValuesConfig';
import { getClinicServices, updatePrivateRecord } from '../../actions/claims.action.creators';
import { formattedService } from './claimDetailsHelpers/formatServices';
import { addNewServiceToQuickPickList } from './claimDetailsHelpers/addNewServiceToQuickPickList';
import { catalogsRolodex } from './Catalogs/helpers/catalogsRolodex';
import { currencyFormat } from '../../../utils/currencyFormat';
import { rowHeight } from './reviewPanelHelpers/columns';
import { isObject } from 'lodash';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import classes from './PrivateService.module.scss';
import cx from 'classnames';

const PrivateService = ({ rowData }) => {
  const dispatch = useDispatch();
  const { isMobile, isMobileOnly, isLandscape } = useSelector((state) => state.core.window);
  const { privateRecord, catalogMostRecentCodes, isNew, setCatalogIndex, setFocusFieldParams, setShowCatalogsSidebar } = useContext(Context);

  const [codes, setCodes] = useState([]);
  const [value, setValue] = useState(null);

  // Initial value
  useEffect(() => {
    const currentValue = privateRecord[inputs().privateService.descriptionName]?.find((i) => {
      return i.id === rowData?.id;
    });
    currentValue && setValue(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuggestions = (query) => {
    const practitionerGuid =
      privateRecord?.DoctorGuid === NA_DoctorGuid || privateRecord?.DoctorGuid === multipleDoctorGuid
        ? privateRecord?.DCNGuid
        : privateRecord?.DoctorGuid;
    getClinicServices({ practitionerGuid, query }).then((response) => setCodes(response));
  };

  const handleChange = (e) => {
    setValue(e.value);
  };

  const onSelect = (e) => {
    if ((!e.value || e.value?.id === '-1') && isObject(e.value)) return;

    // Check if user tries to add duplicate service
    if (privateRecord[inputs().privateService.descriptionName]?.some((i) => i.id === e.value?.value)) return;

    if (rowData?.id === '-1' && isObject(e.value)) {
      dispatch(
        updatePrivateRecord({
          [inputs().privateService.name]: [...privateRecord[inputs().privateService.name], e.value?.value],
          [inputs().privateService.descriptionName]: [
            ...privateRecord[inputs().privateService.descriptionName],
            formattedService(e.value, privateRecord)
          ]
        })
      );
    }

    if (rowData?.id !== '-1' && isObject(e.value)) {
      const serviceGuidList = privateRecord[inputs().privateService.descriptionName].map((i) => (i.id === rowData?.id ? e.value.value : i.id));
      const servicesList = privateRecord[inputs().privateService.descriptionName].map((i) =>
        i.id === rowData?.id ? formattedService(e.value, privateRecord) : i
      );

      dispatch(
        updatePrivateRecord({
          [inputs().privateService.name]: serviceGuidList,
          [inputs().privateService.descriptionName]: servicesList
        })
      );
    }

    //[KS] - add code to the Quick Pick if it is not in the list
    //[KS] CMO-1548 - Create private invoice - new service codes do not appear in catalogs
    isObject(e.value) && addNewServiceToQuickPickList([e.value], privateRecord, catalogMostRecentCodes, dispatch);
  };

  const formatCodeItem = (code) => {
    let item = code.label;
    if (code.amount && code.duration && code.unit) {
      item = `${code.label} - ${code.duration} ${code.unit}, ${currencyFormat(code.amount)}`;
    }

    if (code.amount && (!code.duration || !code.unit)) {
      item = `${code.label}, ${currencyFormat(code.amount)}`;
    }

    return <div>{item}</div>;
  };

  return (
    <>
      <div className={isMobile ? classes.root : ''}>
        {isMobile && (
          <Button
            className={cx('p-button-text', classes.catalogsButton)}
            icon="pi pi-book pointer"
            onClick={() => {
              const focusField = catalogsRolodex(privateRecord, isNew)?.find((i) => i.name === inputs().privateService.name);
              setCatalogIndex(1);
              setFocusFieldParams(focusField);
              setShowCatalogsSidebar(true);
            }}
          />
        )}

        <AutoComplete
          className={cx(isMobile ? classes.dropdownAutocomplete : '')}
          inputClassName={isMobile ? 'pr-6' : ''}
          id={rowData?.id}
          inputId={elementIDs.selectServiceAutocompleteInput}
          value={value}
          suggestions={codes}
          autoHighlight
          field="label"
          placeholder={t('Select_service')}
          scrollHeight={dropdownScrollHeight()}
          inputStyle={{ width: isMobileOnly && !isLandscape ? '190px' : '250px', height: rowHeight }}
          onFocus={(e) => e.target.select()}
          itemTemplate={formatCodeItem}
          onChange={handleChange}
          onSelect={onSelect}
          completeMethod={(e) => getSuggestions(e.query)}
        />
      </div>
    </>
  );
};

export default PrivateService;
