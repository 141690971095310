import React from 'react';
import BadgeWrap from '../../../Wraps/BadgesWraps/BadgeWrap';
import { statuses } from '../../../../modules/config/statuses';
import './Status.scss';

// interface IStatusBadge {
//   status: string | number
//   onClick?: () => void // on badge click
// }

const StatusBadge = ({ status, onClick }) => {
  return (
    <BadgeWrap className={`status-${Number(status)}`} onClick={onClick}>
      {statuses[status]}
    </BadgeWrap>
  );
};

export default StatusBadge;
