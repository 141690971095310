import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { usePatient } from './usePatient';

export const useInsuredType = () => {
  const { clearErrors, getValues, setValue } = useFormContext();
  const { updateMemberData } = usePatient();

  const onChange = (value, field) => {
    field.onChange(value);

    // If value is "Patient"
    if (value === 'SELF') {
      const currentPatient = getValues(inputs.patient.name)?.[0];
      resetRelativeInputs('SELF');
      // If Insured Member value is "Patient" then autofill First, Last and Mid name inputs by selected patient
      if (currentPatient) updateMemberData(currentPatient);
    }

    // If value is "Relative"
    if (value === 'FAMDEP') {
      resetRelativeInputs();
      // Reset member data
      updateMemberData();
    }

    // Clear error message for time inputs
    if (value === 'NA') {
      resetPatientInputs();
      resetRelativeInputs();
      clearErrors([inputs.memberFirstName.name, inputs.memberLastName.name, inputs.memberDOB.name, inputs.relationship.name]);
    }
  };

  const resetPatientInputs = () => {
    getValues(inputs.memberLastName.name) && setValue(inputs.memberLastName.name, '');
    getValues(inputs.memberFirstName.name) && setValue(inputs.memberFirstName.name, '');
    getValues(inputs.memberMidName.name) && setValue(inputs.memberMidName.name, '');
  };

  const resetRelativeInputs = (relationship = 'NA') => {
    getValues(inputs.memberDOB.name) && setValue(inputs.memberDOB.name, null);
    getValues(inputs.relationship.name) && setValue(inputs.relationship.name, relationship);
  };

  return { onChange };
};
