import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from './inputs';

export const requiredFields = () => {
  // required inputs for private invoice
  const serviceRequired = [inputs().privateService.name] || [];
  const payorRequired = [inputs().billTo.name] || [];
  const invoiceDate = [inputs().invoiceDate.name] || [];
  const dueDate = [inputs().dueDate.name] || [];

  return [
    commonInputs.patients.descriptionName,
    commonInputs.serviceDate.name,
    // ...serviceRequired,
    ...payorRequired,
    ...invoiceDate,
    ...dueDate
  ];
};
