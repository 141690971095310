import React from 'react';
import CatalogsSubTabs from './CatalogsSubTabs';
import CatalogTabs from './CatalogTabs';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';

const TabsView = () => {
  const { hideTabs } = useCatalogsContext();

  return <>{hideTabs ? <CatalogsSubTabs /> : <CatalogTabs />}</>;
};

export default TabsView;
