import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Menu } from 'primereact/menu';
import cx from 'classnames';

const MoreActionsButton = ({ data, isMobileOnly, onSplitClick, menuItems, splitIcon }) => {
  const menu = useRef(null);

  return (
    <>
      {isMobileOnly ? (
        <>
          <Button
            className={cx('p-button-text p-button-rounded mr-2', isMobileOnly ? 'mobileRoundedButtonSize' : '')}
            icon="pi pi-ellipsis-h"
            aria-controls="popup_menu"
            aria-haspopup
            tooltipOptions={{ position: 'top' }}
            onClick={(event) => menu.current.toggle(event)}
          />
          <Menu id="popup_menu" ref={menu} popup model={menuItems(data)} />
        </>
      ) : (
        <SplitButton icon={splitIcon} style={{ height: '30px' }} model={menuItems(data)} onClick={onSplitClick} />
      )}
    </>
  );
};

export default MoreActionsButton;
