import { t } from '../../../../../service/localization/i18n';
import { commonInputs } from '../../../../config/commonInputsConfig';

const ICD9Text = 'DiagnosText';

export const inputs = {
  claimGuid: {
    name: 'ClaimGuid'
  },

  invoiceGuid: {
    name: 'InvoiceGuid'
  },

  rowGuid: {
    name: 'RowGuid'
  },

  speciality: {
    name: 'Speciality'
  },

  invoiceStatus: {
    name: 'Status' // Status
  },

  status: {
    name: 'Status'
  },

  statusText: {
    name: 'StatusText'
  },

  saveAsDraft: {
    name: 'SaveAsDraft',
    label: t('Save_as_Draft')
  },

  firstReportYN: {
    name: 'FirstReportYN'
  },

  mspCodes: {
    name: 'MSPCodes'
  },

  // Claim Info //
  patient: {
    name: 'PatientDetails',
    label: t('Patient.1')
  },

  formType: {
    name: 'FormType',
    label: t('FormType')
  },

  claimNumber: {
    name: 'ClaimNumber',
    label: t('Claim_Number'),
    placeholder: '12345'
  },

  injuryDate: {
    name: 'InjuryDate',
    label: t('Injury_Date.1'),
    placeholder: t('MM_DD_YYYY')
  },

  employer: {
    name: 'EmployerName',
    label: t('Employers_Name')
  },

  phone: {
    name: 'EmployerPhone',
    label: t('Employers_Phone'),
    placeholder: '(999) 999-9999'
  },

  address: {
    name: 'LocationAddress',
    label: t('Operating_Location_Address')
  },

  city: {
    name: 'LocationCity',
    label: t('City'),
    placeholder: t('Vancouver')
  },

  // General //
  isPrimary: {
    name: 'IsPrimary',
    label: t('Are_you_the_workers_regular_primary_practitioner')
  },

  termPrimary: {
    name: 'TermPrimary',
    label: t('How_long_has_the_worker_been_your_patient')
  },

  firstTreatment: {
    name: 'TreatedBy',
    label: t('Who_rendered_the_first_treatment_for_this_injury')
  },

  // Prior/Others //
  priorProblems: {
    name: 'PriorProblems',
    label: t('Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability')
  },

  // Injury //
  icd9: {
    name: 'DiagnosticCode',
    label: t('Diagnostic_Code'),
    codeDescription: ICD9Text,
    codeType: commonInputs.icd9.codeType
  },

  icd9Text: {
    name: ICD9Text,
    label: t('Diagnosis')
  },

  noi: {
    name: 'NOICode',
    label: commonInputs.noi.label,
    codeDescription: 'NOIText',
    codeType: commonInputs.noi.codeType
  },

  aoi: {
    name: 'AOICode',
    label: t('Area_of_Injury.3'),
    codeDescription: 'AOIText',
    codeType: commonInputs.aoi.codeType
  },

  anatomicCode: {
    name: 'Anatomic',
    label: t('Anatomic_Position')
  },

  disabled: {
    name: 'DisabledYN',
    label: t('From_injury_or_since_last_report')
  },

  disabledDate: {
    name: 'DisabledDate',
    label: t('If_Yes_as_of_what_date_if_known'),
    placeholder: t('MM_DD_YYYY')
  },

  // Clinical //
  clinicalInformation: {
    name: 'ClinicalText',
    label: t('Clinical_Information_Describe')
  },

  // Return To Work //
  capable: {
    name: 'CapableYN',
    label: t('Is_the_worker_now_medically_capable_of_working_full_time')
  },

  restrictions: {
    name: 'Restrictions',
    label: t('What_are_the_current_restrictions')
  },

  estimatedReturn: {
    name: 'EstimatedReturn',
    label: t('Estimated_time_before_the_worker_will_be_able_to_return')
  },

  rehabilitation: {
    name: 'RehubReadyYN',
    label: t('Is_the_worker_now_ready_for_a_rehabilitation_program')
  },

  consult: {
    name: 'ConsultYN',
    label: t('Do_you_wish_to_consult_with.1')
  },

  recoveryDate: {
    name: 'RecoveryDate',
    label: t('Estimate_date_of_Maximal_Medical_Recovery'),
    placeholder: t('MM_DD_YYYY')
  },

  // Service Details //
  serviceDate: {
    name: 'ServiceDate',
    label: t('Service_date'),
    placeholder: t('MM_DD_YYYY'),
    codeType: 'serviceDate'
  },

  practitioner: {
    name: 'DoctorGuid',
    label: t('Practitioner')
  },

  feeCodes: {
    name: 'FeeItemCode',
    label: t('Fee_Items')
  },

  // no inputs values
  patientGuid: {
    name: 'PatientGuid',
    label: t('Patient.1')
  },

  patientClaims: {
    name: 'PatientClaims'
  },

  recordNumber: {
    name: 'RecordNo'
  },

  sequenceNum: {
    name: 'SeqNumber'
  },

  feeAmount: {
    name: 'FeeAmount'
  },

  feePaid: {
    name: 'FeePaid'
  },

  sendNow: {
    name: 'SendNow'
  }
};

export const requiredInputs = {
  [inputs.patient.name]: inputs.patient.name,
  [inputs.injuryDate.name]: inputs.injuryDate.name,
  [inputs.employer.name]: inputs.employer.name,
  [inputs.address.name]: inputs.address.name,
  [inputs.city.name]: inputs.city.name,
  [inputs.isPrimary.name]: inputs.isPrimary.name,
  [inputs.firstTreatment.name]: inputs.firstTreatment.name,
  [inputs.priorProblems.name]: inputs.priorProblems.name,
  [inputs.icd9.name]: inputs.icd9.name,
  [inputs.aoi.name]: inputs.aoi.name,
  [inputs.noi.name]: inputs.noi.name,
  [inputs.disabled.name]: inputs.disabled.name,
  [inputs.clinicalInformation.name]: inputs.clinicalInformation.name,
  [inputs.capable.name]: inputs.capable.name,
  [inputs.estimatedReturn.name]: inputs.estimatedReturn.name,
  [inputs.rehabilitation.name]: inputs.rehabilitation.name,
  [inputs.consult.name]: inputs.consult.name,
  [inputs.serviceDate.name]: inputs.serviceDate.name,
  [inputs.feeCodes.name]: inputs.feeCodes.name
};
