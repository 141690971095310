import ReviewPractitioner from '../ReviewPractitioner';
import { rowHeight } from './columns';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';

export const practitionerEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start mt-3' : 'align-items-center ')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Practitioner')}</span>
          <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ height: rowHeight }}>
            {t('N_A')}
          </div>
        </div>
      )}

      {/* If row is empty */}
      {records[params.rowIndex]?.id !== '-1' && (
        <>
          <span className="p-column-title">{t('Practitioner')}</span>
          <ReviewPractitioner params={params} records={records} setRecords={setRecords} setIsReviewDirty={setIsReviewDirty} />
        </>
      )}
    </div>
  );
};
