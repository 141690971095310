import React from 'react';
import { useHistory } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';

const RotateYoutDeviseMessage = () => {
  const history = useHistory();

  return (
    <div className="flex justify-content-start align-items-center flex-column p-3">
      <Message
        severity="info"
        text="The Teleplan Picker and Teleplan Batch screens are only available on screens that are at least 1023 pixels wide. If you are viewing this on a smaller screen, please rotate your device to landscape mode to see the full form."
      />

      <Button className="mt-4" label="Go back" onClick={() => history.goBack()} />
    </div>
  );
};

export default RotateYoutDeviseMessage;
