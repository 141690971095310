import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataScroller } from 'primereact/datascroller';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { SlideMenu } from '../../../../../components/Menus/SlideMenuACI/SlideMenuACI';
import Context from '../../../../../Context';
import CustomScrollTop from '../../../../common/components/CustomScrollTop';
import { getCodesForCatalog } from '../../../../../service/Lookup';
import CommonChip from '../../../../common/components/CommonChip';
import { onCodeSelect, onCodeDelete } from './helpers/codeAction';
import { mainHeaderHeight } from '../../../../config/stylesConfig';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { invoicesCatalogsDefaultPageSize } from '../../../../config/defaultValuesConfig';
import { catalogsRolodex, mostRecent } from './helpers/catalogsRolodex';
import { setActiveTabIndexForInvoicesCatalog } from '../../../actions/claims.action.creators';
import { t } from '../../../../../service/localization/i18n';
import classes from './CodeCatalogs.module.scss';
import cx from 'classnames';
import QuickPickItem from '../../QuickPickItem';
import CircularProgress from '../../../../../components/Misc/Loader/CircularProgress/CircularProgress';

const CodeCatalogs = ({ setTrail, trail, sideBarCurrentHeight = 560 }) => {
  const dispatch = useDispatch();
  const catalogCategories = useSelector((state) => state.claims.catalogCategories.privateServices);
  const { isMobile, isMobileOnly, clientWidth, clientHeight, isLandscape, isTablet } = useSelector((state) => state.core.window);
  const {
    user,
    isLoadingTableData,
    searchValue,
    mobileContent,
    setMobileContent,
    setIsLoadingTableData,
    categoryValue,
    setCategoryValue,
    catalogsTableData,
    setCatalogsTableData,
    privateRecord,
    itemsNumber,
    focusFieldParams,
    catalogMostRecentCodes,
    setIsMaxCodeEntries,
    menuModel,
    setMenuModel
  } = useContext(Context);

  const [menuHeight, setMenuHeight] = useState(100);
  const [drillDown, setDrillDown] = useState([]);
  const [isLoadingDataScrollerTable, setisLoadingDataScrollerTable] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(t('No_records_found'));
  const [menuItemClicked, setMenuItemClicked] = useState({});
  const [scrollableTableHeightMobile, setScrollableTableHeightMobile] = useState(450);
  const [mobileSidebarMenuWidth, setMobileSidebarMenuWidth] = useState(349);
  const [currentPage, setCurrentPage] = useState(0);
  const [invoicesCatalogsBreadcrumbHeight, setinvoicesCatalogsBreadcrumbHeight] = useState(35);
  const [invoicesCatalogsChips, setInvoicesCatalogsChips] = useState(46);

  let path = [];
  const categoryType = focusFieldParams.codeType;
  const slideMenuRef = useRef(null);
  const breadcrumbRef = useRef(null);
  const dataScrollerRef = useRef(null);
  const chipsWrapperRef = useRef(null);
  const sidebarHeader = 47;
  const desktopSidebarMenuWidth = 242;
  const invoicesCatalogsHeader = 52;
  const invoicesCatalogsTabs = 43;
  // const invoicesCatalogsChips = 46;
  const mobileFooter = 40;
  const scrollableTableHeight =
    clientHeight -
    (isMobile ? 0 : mainHeaderHeight) -
    invoicesCatalogsHeader -
    invoicesCatalogsTabs -
    invoicesCatalogsChips -
    invoicesCatalogsBreadcrumbHeight -
    16;

  // set chips container height
  useEffect(() => {
    //The height of the container can be changed if the breadcrumbs are too long and begin to wrap.
    chipsWrapperRef?.current?.clientHeight && setInvoicesCatalogsChips(chipsWrapperRef?.current?.clientHeight);
  }, [chipsWrapperRef?.current?.clientHeight]);

  // set breadcrumb container height
  useEffect(() => {
    //The height of the container can be changed if the breadcrumbs are too long and begin to wrap.
    breadcrumbRef?.current?.clientHeight && setinvoicesCatalogsBreadcrumbHeight(breadcrumbRef?.current?.clientHeight);
  }, [breadcrumbRef?.current?.clientHeight]);

  //set data table and slide menu height on mobile view when adding/removing chips
  useEffect(() => {
    setScrollableTableHeightMobile(mobileCatalogsContentHeight(true, isTablet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord[focusFieldParams.name], isLandscape, sideBarCurrentHeight]);

  //set slide menu height/width
  useEffect(() => {
    //set slide menu height
    setMenuHeight(isMobile ? mobileCatalogsContentHeight(false, isTablet) : scrollableTableHeight);

    //set slide menu width
    isMobileOnly && setMobileSidebarMenuWidth(slideMenuRef?.current?.slideMenuContent?.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scrollableTableHeight,
    catalogCategories?.activeTabIndex,
    isLandscape,
    clientWidth,
    sideBarCurrentHeight, // [KS] recalculate slide menu height if height of sidebar is chanded on mobiles and tablets
    mobileContent, // [KS] recalculate slide menu height when user click on back button at the bottom of DataScroller on mobiles only
    invoicesCatalogsBreadcrumbHeight,
    slideMenuRef?.current?.slideMenuContent?.clientWidth,
    invoicesCatalogsChips
  ]);

  //set menu model
  useEffect(() => {
    const categories = catalogCategories?.tabs[catalogCategories?.activeTabIndex]?.categories || [];
    const msg = categories[0] && categories[0]?.end ? t('No_records_found') : t('Please_select_a_category');
    recursion(categories);
    setMenuModel(categories || []);
    setEmptyMessage(msg);

    !isMobileOnly && categories[0] && categories[0]?.end && categories[0]?.command(); //set initial request for data table when tab is changed

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogCategories?.activeTabIndex, categoryType]);

  //set active class for menu item
  useEffect(() => {
    if (menuModel?.length && menuModel[0]?.end) {
      slideMenuRef?.current?.slideMenuContent?.children[0]?.children[0]?.classList.add('p-menuitem-highlight');
      onNavigate([menuModel[0]]);
    }

    //set category value to "..._all" for using global search
    if (!catalogCategories?.tabs[catalogCategories?.activeTabIndex]?.categories[0]?.end) {
      setCategoryValue(focusFieldParams?.searchAllType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuModel, catalogCategories?.activeTabIndex, categoryType]);

  const recursion = (arr) => {
    arr.forEach((i) => {
      const isRecent = i.value.indexOf('_recent') > -1 ? true : false;
      if (i.end) {
        i.command = () => handleMenuSelect(i, isRecent);
      } else {
        i.command = () => handleMenuClick(i, isRecent);
        i.items = i.categories;
        recursion(i.categories);
      }
    });
  };

  const mobileCatalogsContentHeight = (showFooterActionButtons = false, showOnTablet) => {
    const paginatorHeight = showFooterActionButtons && !showOnTablet ? mobileFooter : 0;
    const heightWithChips =
      sideBarCurrentHeight - sidebarHeader - invoicesCatalogsTabs - invoicesCatalogsChips - invoicesCatalogsBreadcrumbHeight - paginatorHeight;
    const noChipsHeight = sideBarCurrentHeight - sidebarHeader - invoicesCatalogsTabs - invoicesCatalogsBreadcrumbHeight - paginatorHeight;
    const contentHeight = privateRecord[focusFieldParams.name]?.length || showOnTablet ? heightWithChips : noChipsHeight;

    return contentHeight;
  };

  const onNavigate = (data) => {
    const arr = data.map((x) => ({ label: x.label }));
    setTrail(arr);
  };

  const handleMenuClick = (menuItem, isRecent) => {
    removeFirstHiglightedElementInSlideMenu();
    isMobile && categoryValue !== menuItem.value && setCategoryValue(menuItem.value);
    setDrillDown([...drillDown, ...[menuItem]]); //memorize items when user drills down throught the slide menu
    setEmptyTableMessage();
    scrollInView(slideMenuRef);
  };

  const scrollInView = (topMenuRef) => {
    try {
      topMenuRef?.current?.menuRef?.current?.children[0].children[0].scrollTo(0, 0);
    } finally {
    }
  };

  const handleMenuSelect = (menuItem, isRecent) => {
    removeFirstHiglightedElementInSlideMenu();
    isMobileOnly && setMobileContent('table');
    categoryValue !== menuItem.value && setCategoryValue(menuItem.value); //set menuItem.value for using global search
    setIsLoadingTableData(true);
    setCatalogsTableData(itemsNumber); //reset data for dataTable
    getCodesForCatalog({ catalog: menuItem.value, query: searchValue, dcnguid: user?.details?.DCNGuid }).then((response) => {
      if (response?.status === 200) {
        setIsLoadingTableData(false);
        setMenuItemClicked({ catalogType: focusFieldParams.codeType, codeType: menuItem.value });
        setCatalogsTableData(response?.data);
        setEmptyMessage(t('No_records_found'));
      } else {
        setIsLoadingTableData(false);
        dispatch(setToastMessage({ type: 'error', message: response?.message }));
      }
    });
  };

  const removeFirstHiglightedElementInSlideMenu = () => {
    slideMenuRef?.current?.slideMenuContent?.children[0]?.children[0]?.classList?.remove('p-menuitem-highlight'); //delete active class for menu item
  };

  const navigateBack = () => {
    setEmptyTableMessage();
    const sliced = drillDown.slice(0, -1);
    sliced?.length ? setCategoryValue(drillDown[drillDown?.length - 1]?.value) : setCategoryValue(focusFieldParams?.searchAllType);
    setDrillDown(sliced); //remove last item when user drills up throught the slide menu
  };

  const setEmptyTableMessage = () => {
    setCatalogsTableData({ serviceList: [] });
    setEmptyMessage(t('Please_select_a_category'));
  };

  const onChipDelete = (data) => {
    onCodeDelete(data, focusFieldParams, privateRecord, dispatch);
  };

  const onTabChange = (index) => {
    if (index !== catalogCategories?.activeTabIndex) {
      trail?.length && setTrail([]);
      setCatalogsTableData(itemsNumber);
      isMobile && setCategoryValue(focusFieldParams?.searchAllType); //set category value to "..._all" for using global search
      dispatch(setActiveTabIndexForInvoicesCatalog(index, categoryType));
      if (mobileContent === 'table') setCatalogsTableData(itemsNumber); //reset data for dataTable
      if (isMobileOnly && mobileContent === 'table') {
        setMobileContent('menu');
        setCurrentPage(0);
      }
    }
  };

  const dataScrollerLazyLoad = (e, page = 0, totalPages) => {
    setCurrentPage(page);
    if (page < totalPages && page !== currentPage && menuItemClicked.codeType) {
      const prevList = catalogsTableData?.serviceList;
      prevList[0] === 'item' ? setIsLoadingTableData(true) : setisLoadingDataScrollerTable(true);
      getCodesForCatalog({
        catalog: menuItemClicked.codeType,
        page: page + 1,
        pageSize: catalogsTableData?.pageSize,
        query: searchValue,
        dcnguid: user?.details?.DCNGuid
      }).then((response) => {
        if (response?.status === 200) {
          setIsLoadingTableData(false);
          setisLoadingDataScrollerTable(false);
          setCatalogsTableData({
            ...response?.data,
            serviceList: [...prevList, ...response?.data?.serviceList]
          });
        } else {
          setIsLoadingTableData(false);
          setisLoadingDataScrollerTable(false);
          dispatch(setToastMessage({ type: 'error', message: response?.message }));
        }
      });
    }
  };

  const handleCodeSelect = (data) => {
    const params = {
      data,
      focusField: focusFieldParams,
      record: privateRecord,
      setIsMaxCodeEntries,
      dispatch,
      mostRecentCodes: catalogMostRecentCodes
    };
    onCodeSelect(params);
  };

  const dataScrollerItemTemplate = (service) => {
    const serviceName = service?.unit && !!Number(service?.duration) ? `${service.label}, ${service.duration} ${service.unit}` : service.label;

    return (
      <div className={cx('flex col-12 p-0')}>
        <div
          id={`amount_${service.value.replaceAll('-', '')}`}
          className={cx('flex col-12 align-items-center justify-content-end px-0 ml-2 py-2', classes.dataScrollerCodeColumn)}
        >
          {currencyFormat(service?.amount)}
        </div>

        <div
          id={`service_${service.value.replaceAll('-', '')}`}
          className={cx('flex align-items-center p-jc-left px-2 py-2 ml-5', classes.dataScrollerLableColumn)}
          onClick={() => handleCodeSelect(service)}
        >
          <span className={cx(service.value ? 'text-underline' : '')}>{serviceName}</span>
        </div>
      </div>
    );
  };

  const dataScrollerFooterBackButton = () => {
    setMobileContent('menu');
    setCategoryValue(focusFieldParams?.searchAllType); //set category value to "..._all" for using global search
    setTrail([]);
    setCatalogsTableData([]); //reset data for dataScroller
  };

  const onScrollTopDataTable = () => {
    dataScrollerRef?.current?.contentElement?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const slideMenu = (
    <SlideMenu
      ref={slideMenuRef}
      viewportHeight={menuHeight}
      menuWidth={isMobileOnly ? mobileSidebarMenuWidth : desktopSidebarMenuWidth}
      model={menuModel}
      //breadcrumb={path}
      onBack={() => navigateBack()}
      onNavigate={(data) => onNavigate(data)}
    />
  );

  const dataScroller = (
    <DataScroller
      lazy
      inline
      ref={dataScrollerRef}
      emptyMessage={emptyMessage}
      value={catalogsTableData?.serviceList}
      rows={catalogsTableData?.pageSize}
      scrollHeight={`${isMobile ? scrollableTableHeightMobile : scrollableTableHeight}px`}
      itemTemplate={dataScrollerItemTemplate}
      onLazyLoad={(e) => dataScrollerLazyLoad(e, catalogsTableData?.page, catalogsTableData?.totalPages)}
    />
  );

  const dataScrolleSkeleton = (
    <div style={{ height: `${isMobile ? scrollableTableHeightMobile : scrollableTableHeight}px`, width: '100%' }}>
      <ul className="p-0 m-0">
        {itemsNumber?.serviceList?.map((i, index) => {
          return (
            <li key={`${i}_${index}`}>
              <div className="flex align-items-center col-12 p-0">
                <div className={cx('flex col-12 align-items-center px-2 py-2', classes.dataScrollerCodeColumn)}>
                  <Skeleton height="17px" />
                </div>
                <div className={cx('flex align-items-center p-jc-left px-2 py-2', classes.dataScrollerLableColumn)}>
                  <Skeleton height="17px" />
                </div>
              </div>
              <Divider className="my-0" />
            </li>
          );
        })}
      </ul>
    </div>
  );

  const mobileCatalogsContent = (
    <div className={cx('flex', classes.mobileCatalogsContentRoot)}>
      <div className={cx(mobileContent === 'menu' ? classes.showMobileSlideMenu : classes.hideMobileSlidemenu)}>{slideMenu}</div>

      <div className={cx(mobileContent === 'table' ? classes.showMobileDataScroller : classes.hideMobileDataScroller)}>
        {isLoadingTableData ? (
          dataScrolleSkeleton
        ) : (
          <div className={classes.dataScrollerWrap}>
            {/* <CustomScrollTop onClick={onScrollTopDataTable} /> */}
            {dataScroller}
          </div>
        )}
        <div
          className={cx(
            'flex col-12 p-0 align-items-center pointer',
            mobileContent === 'table' ? classes.showDataScrollertableFooter : classes.hideDataScrollertableFooter
          )}
          style={{ height: mobileFooter }}
          onClick={dataScrollerFooterBackButton}
        >
          <span className="flex align-items-center ml-5">
            <i className={cx('pi pi-angle-left mr-1', classes.dataScrollerFooterBackIcon)} />
            <span>{t('Back')}</span>
          </span>
        </div>
      </div>
    </div>
  );

  const desktopCatalogsContent = (
    <>
      <div className={cx(classes.desktopCatalogSlideMenuWrap, isMobile ? '' : 'catalogsSlideMenuDesktop')}>{slideMenu}</div>
      {isLoadingTableData ? (
        dataScrolleSkeleton
      ) : (
        <div className={classes.dataScrollerWrap}>
          {/* <CustomScrollTop onClick={onScrollTopDataTable} /> */}
          {dataScroller}
        </div>
      )}
    </>
  );

  return (
    <>
      {!catalogCategories && <h5 className="mt-6 ml-3">{t('Please_select_practitioner_on_the_left_hand_panel_to_see_the_services')}</h5>}

      {catalogCategories && (
        <TabView activeIndex={catalogCategories?.activeTabIndex} onTabChange={(e) => onTabChange(e.index)}>
          {catalogCategories?.tabs?.map((i, index) => {
            return (
              <TabPanel header={i.label} key={index}>
                {isLoadingDataScrollerTable && <CircularProgress />}

                {((isMobileOnly && privateRecord[focusFieldParams.descriptionName]?.length) || !isMobileOnly) && (
                  <div className="flex justify-content-between align-items-center" style={{ minHeight: invoicesCatalogsChips }}>
                    <div className={cx(isMobile ? cx('mt-2', classes.chipsMobile) : 'col-12 p-0')}>
                      {isMobileOnly ? (
                        <>
                          {/* Chips */}
                          {privateRecord[focusFieldParams.descriptionName] &&
                            privateRecord[focusFieldParams.descriptionName]?.map((i) => {
                              return <CommonChip data={i} onDelete={onChipDelete} displayFieldValue="label" />;
                            })}
                        </>
                      ) : (
                        <>
                          {/* Quick Pick */}
                          {mostRecent()?.map((i) => {
                            if (focusFieldParams?.name === i.name) {
                              return (
                                <QuickPickItem
                                  item={i}
                                  useLabel={true}
                                  showTitile={false}
                                  showInCatalogs={true}
                                  displayQuickPickInColumn={true}
                                  showDivider={i.showDivider}
                                  ref={chipsWrapperRef}
                                  record={privateRecord}
                                  catalogsRolodex={catalogsRolodex}
                                  setIsMaxCodeEntries={setIsMaxCodeEntries}
                                  onCodeSelect={onCodeSelect}
                                  onCodeDelete={onCodeDelete}
                                />
                              );
                            }
                          })}
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className={isMobile ? 'breadcrumbMobile' : ''} ref={breadcrumbRef}>
                  <BreadCrumb model={trail} home={{ icon: 'pi pi-home' }} />
                </div>

                <div
                  className={cx(
                    'flex',
                    !catalogsTableData?.serviceList ||
                      catalogsTableData?.serviceList?.length === 0 ||
                      (catalogsTableData?.serviceList?.length && catalogsTableData?.serviceList[0] === 'item')
                      ? 'catalogsSidebarNoRecordsFoundMessage'
                      : ''
                  )}
                >
                  {isMobileOnly ? mobileCatalogsContent : desktopCatalogsContent}
                </div>
              </TabPanel>
            );
          })}
        </TabView>
      )}
    </>
  );
};

export default CodeCatalogs;
