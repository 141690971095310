import React from 'react';
import CommonCard from '../../../common/components/CommonCard';
import { t } from '../../../../service/localization/i18n';
import Patients from '../../../patients/components/Patients/Patients';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { routes } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setDefaultPatientsTableValues } from '../../../patients/actions/patients.action.creators';

const RecentPatients = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFetchingPatients } = useSelector((state) => state.patients);

  const patientsViewMore = () => {
    history.push(`${routes.patients.path}`);
    dispatch(setDefaultPatientsTableValues());
  };

  const patientssHeaderActionButton = (
    <Button
      className="p-button-outlined p-button-rounded"
      icon="pi pi-plus"
      tooltip={t('Add_new_patient')}
      tooltipOptions={{ position: 'top' }}
      onClick={() => history.push(`${routes.createPatient.path}/new`)}
    />
  );

  return (
    <CommonCard
      title={t('Recent_Patients')}
      showFooter={true}
      isFetching={isFetchingPatients}
      footerButtonLable={t('View_More')}
      footerButtonOnClick={patientsViewMore}
      headerActionButton={patientssHeaderActionButton}
    >
      <Patients showInCard={true} customPageSize={3} />
    </CommonCard>
  );
};

export default RecentPatients;
