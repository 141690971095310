import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useDeleteRecord } from '../../../hooks/useDeleteRecord';
import { icons } from '../../../../../../../../config/stylesConfig';
import { dataTableActionTooltip } from '../../../../../helpers/tooltips';
import { t } from '../../../../../../../../../service/localization/i18n';

const DeleteRecord = ({ rowOptions }) => {
  const { localState } = useFormContext();
  const { onDeleteClick } = useDeleteRecord();
  const tooltip = dataTableActionTooltip(t('Delete_group_record'), rowOptions.rowData);

  return (
    <div className="flex justify-content-center w-full">
      <Button
        className="p-button-text p-button-rounded"
        icon={icons.delete}
        tabIndex={-1}
        tooltip={tooltip}
        tooltipOptions={{ position: 'top' }}
        disabled={localState.gettingRecentCodes}
        onClick={() => onDeleteClick(rowOptions)}
      />
    </div>
  );
};

export default DeleteRecord;
