import React from 'react';
import EClaimPolicyForm from './EClaimPolicyForm/EClaimPolicyForm';
import Dialogs from './Dialogs/Dialogs';
import PoliciesList from './PoliciesList/PoliciesList';
import Header from './Header/Header';

const PatientPoliciesLayout = () => {
  return (
    <>
      <div className="flex flex-column gap-4">
        <Header />
        <PoliciesList />
      </div>
      <Dialogs />
      {/* <EClaimPolicyForm /> */}
    </>
  );
};

export default PatientPoliciesLayout;
