import React from 'react';
import UserVerify from './UserVerify';
import { useSelector } from 'react-redux';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

const obfuscateEmail = (email) => {
  if (email && email.indexOf('@') > -1) {
    const parts = email.split('@');
    const l1 = parts[0].length;
    return parts[0].substr(0, 1) + 'x'.repeat(l1 - 2) + parts[0].substr(l1 - 1, 1) + '@' + parts[1];
  }
  return 'x'.repeat(10);
};

const Verification = () => {
  const { details } = useSelector((state) => state.user);
  const mfaEmail = obfuscateEmail(details?.Email);

  return (
    <AuthFormWrap label="User verification">
      <div className="flex justify-content-end pb-4">
        We sent the code to {mfaEmail}. Please enter it here. If you haven't received the code, click "Cancel" and try again.
      </div>
      <UserVerify />
    </AuthFormWrap>
  );
};

export default Verification;
