import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { t } from '../../../../../../../../service/localization/i18n';

const RefToByDropdown = ({ value, onChange, disabled }) => {
  const options = [
    { name: t('None'), code: 'N' },
    { name: t('By'), code: 'B' },
    { name: t('To'), code: 'T' }
  ];

  const getValue = () => {
    return options.find((i) => i.code === value);
  };

  return (
    <Dropdown
      className="w-full"
      value={getValue()}
      optionLabel="name"
      options={options}
      disabled={disabled}
      onChange={(e) => onChange(e.value.code)}
    />
  );
};

export default RefToByDropdown;
