// eslint-disable-next-line import/no-anonymous-default-export
export default {
  FETCH_CLINIC_REQUEST: 'FETCH_CLINIC_REQUEST',
  FETCH_CLINIC_SUCCESS: 'FETCH_CLINIC_SUCCESS',

  FETCH_MEMBERS_REQUEST: 'FETCH_MEMBERS_REQUEST',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',

  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_ROOMS_SUCCESS: 'FETCH_ROOMS_SUCCESS',

  CREATE_CLINIC_REQUEST: 'CREATE_CLINIC_REQUEST',
  CREATE_CLINIC_FAILURE: 'CREATE_CLINIC_FAILURE',

  UPDATE_CLINIC_REQUEST: 'UPDATE_CLINIC_REQUEST',
  UPDATE_CLINIC_SUCCESS: 'UPDATE_CLINIC_SUCCESS',
  UPDATE_CLINIC_FAILURE: 'UPDATE_CLINIC_FAILURE',

  FETCH_CLINIC_FILTERS: 'FETCH_CLINIC_FILTERS',
  FETCH_CLINIC_FILTERS_SUCCESS: 'FETCH_CLINIC_FILTERS_SUCCESS',

  CLINIC_EDITING: 'CLINIC_EDITING',
  PRACTITIONER_EDITING: 'PRACTITIONER_EDITING'
};
