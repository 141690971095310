import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DemoDetailsBodyLayout from './DemoDetailsBodyLayout';
import DeleteConfirmation from '../../../../modules/common/components/DeleteConfirmation';
import LegoDetailsView from '../../../../modules/_lego/LegoDetailsView/LegoDetailsView';
import { menuItems } from './helpers/menuItems';
import { t } from '../../../../service/localization/i18n';
import { getDemosData, deleteDemo, setDemoDetails, setDemoItemIndex, setFilterByPatientForDemos } from '../../actions/demos.action.creators';

// DONT FORGET RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo" OR "Demo" prefix

const DemoDetailsView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    page,
    sortby,
    filters,
    pageSize,
    totalPages,
    totalRecords,
    active_offset,
    demoItemIndex,
    isFetchingDemos,
    demoItemDetails,
    demosItemsList,
    demosCompleteList,
    filterByPatientForDemos
  } = useSelector((state) => state.demos);
  const { isMobileOnly } = useSelector((state) => state.core.window);
  const { showDataScroller } = useSelector((state) => state.core);
  const { PatientGuid } = useSelector((state) => state.patients.patient_details);

  const [contentFirst, setContentFirst] = useState(0);
  const [demoRecord, setDemoRecord] = useState({});
  const [deleteDemoConfirm, onDeleteDemoConfirm] = useState(false);
  const [demoItemForDeletion, setDemoDataForDeletion] = useState({});

  const demosList = isMobileOnly && showDataScroller ? demosCompleteList : demosItemsList;

  // set initial demo details
  useEffect(() => {
    setDemoRecord(demoItemDetails);
  }, [demoItemDetails]);

  // set demo index
  useEffect(() => {
    // DONT FORGET TO CHANGE "InvoiceGuid" AT demoRecord?.InvoiceGuid TO VALID DATA ID
    const index = demosList.findIndex((i) => i.InvoiceGuid === demoItemDetails?.InvoiceGuid);
    if (index === -1 && demosList?.length > 0) {
      //debugger; // should never happen!
    } else {
      if (index !== -1) dispatch(setDemoItemIndex(index));
    }
  }, [demoRecord, demosList, dispatch]);

  // set contentFirst
  useEffect(() => {
    let offset = 0;
    // DONT FORGET TO CHANGE "InvoiceGuid" AT demoRecord?.InvoiceGuid TO VALID DATA ID
    const demoItem = demosList.find((i) => i.InvoiceGuid === demoRecord?.InvoiceGuid);
    if (page > 1) {
      offset = (page - 1) * pageSize;
    }

    const mobileContentFirst = page > 2 ? pageSize + demosList.indexOf(demoItem) : offset + demosList.indexOf(demoItem);
    const _contentFirst = isMobileOnly && showDataScroller ? mobileContentFirst : offset + demosList.indexOf(demoItem);

    setContentFirst(_contentFirst);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // DONT FORGET TO CHANGE "InvoiceGuid" AT demoRecord?.InvoiceGuid TO VALID DATA ID
  }, [demoRecord?.InvoiceGuid, demoItemIndex, demosList, page, pageSize, isMobileOnly]);

  const onDeleteDemo = (data) => {
    setDemoDataForDeletion(data);
    onDeleteDemoConfirm(true);
  };

  const onHideDeleteConfirm = () => {
    setDemoDataForDeletion({});
    onDeleteDemoConfirm(false);
  };

  const onAcceptDeleteItem = () => {
    // DONT FORGET TO CHANGE "InvoiceGuid" AT demoItemForDeletion.InvoiceGuid TO VALID DATA ID
    dispatch(deleteDemo(demoItemForDeletion.DCNGuid, demoItemForDeletion.InvoiceGuid, PatientGuid, true));
  };

  const onSplitClick = () => {
    history.push(`/`); // ADD PATHNAME
  };

  const bodyLayout = () => {
    return <DemoDetailsBodyLayout data={demoRecord} />;
  };

  const menu = (data) => menuItems(data, onDeleteDemo);

  return (
    <>
      <LegoDetailsView
        title={t('Demo title')}
        splitIcon="pi pi-pencil"
        data={demoItemDetails}
        active_offset={active_offset}
        menuItems={menu}
        isFetching={isFetchingDemos}
        filters={filters}
        sortby={sortby}
        page={page}
        pageSize={pageSize}
        itemsList={demosList}
        itemIndex={demoItemIndex}
        totalPages={totalPages}
        totalRecords={totalRecords}
        contentFirst={contentFirst}
        filterByPatient={filterByPatientForDemos}
        bodyLayout={bodyLayout}
        onSplitClick={onSplitClick}
        getDataList={getDemosData}
        setItemInfo={setDemoDetails}
        setRecord={setDemoRecord}
        setFilterByPatient={setFilterByPatientForDemos}
      />

      {/* SHOW CONFIRMATION IF DELETE ITEM */}
      <DeleteConfirmation
        visible={deleteDemoConfirm}
        header={t('Delete Item')}
        message={t('Are you sure you want to delete item?')}
        accept={onAcceptDeleteItem}
        reject={onHideDeleteConfirm}
        onHide={onHideDeleteConfirm}
      />
    </>
  );
};

export default DemoDetailsView;
