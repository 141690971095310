import { isTimeValid, timeDiff } from '../../../../../../utils/formatTime';

const SpanLayout = ({ rowData }) => {
  const startTime = rowData?.StartTime;
  const endTime = rowData?.FinishTime;
  const isStartValid = isTimeValid(startTime);
  const isEndValid = isTimeValid(endTime);

  if (isStartValid && isEndValid) {
    const durationInMinutes = timeDiff(startTime, endTime);
    return <div className="w-max">{`${durationInMinutes} min`}</div>;
  }

  return '—';
};

export default SpanLayout;
