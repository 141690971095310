import { useEffect, useState } from 'react';

const useEntities = (initialEntities) => {
  const [entities, setEntities] = useState(initialEntities);

  useEffect(() => {
    if (initialEntities) {
      setEntities(initialEntities);
    }
  }, [initialEntities]);

  const updateEntityPermission = (moduleId, newPermissions) => {
    setEntities((prevEntities) => prevEntities.map((entity) => (entity.Entity === moduleId ? { ...entity, Permissions: newPermissions } : entity)));
  };

  return { entities, updateEntityPermission };
};

export default useEntities;
