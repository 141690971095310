import React from 'react';
import LockPrefsButton from '../../Buttons/LockButton/LockPrefsButton';
import { isFunction } from 'lodash';

const DefaultLabelLayout = (props) => {
  const labelOptionalContentLayout = isFunction(props.labelOptionalContent) ? props.labelOptionalContent() : props.labelOptionalContent;

  return (
    <div>
      <span id={`${props.name}_label`}>{!props.hideLabel && props.label}</span>

      {props.required && !props.hideLabel && (
        <span id={`${props.name}_mandatoryFieldIcon`} className="ml-1 p-error">
          *
        </span>
      )}

      {props.labelOptionalContent && !props.hideLabel && <span className="mx-3">{labelOptionalContentLayout}</span>}
    </div>
  );
};

const LabelWithPrefsLayout = (props) => {
  return (
    <div className="flex">
      <div className={props.labelOptionalContent ? 'mr-1' : 'mr-3'}>
        <DefaultLabelLayout {...props} />
      </div>

      <LockPrefsButton name={props.name} isLocked={props.isLocked} onSavePrefs={props.onSavePrefs} />
    </div>
  );
};

const LabelContent = (props) => {
  const lableContentLayout = isFunction(props.lableContent) ? props.lableContent() : props.lableContent;

  if (props.lableContent) return lableContentLayout;
  if (props.onSavePrefs) return <LabelWithPrefsLayout {...props} />;
  return <DefaultLabelLayout {...props} />;
};

const LabelLayout = ({ labelStyle = {}, ...props }) => {
  if (props.hideLabel) return null;

  const marginBottom = props.dir === 'row' ? '0px' : '4px';
  return (
    <label
      htmlFor={props.name}
      style={{ paddingRight: props.dir === 'row' ? '20px' : '0px', marginBottom, height: '19px', ...labelStyle }}
      className="text-500 text-sm"
    >
      <LabelContent {...props} />
    </label>
  );
};

export default LabelLayout;
