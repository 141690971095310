export function trimLeftZeros(num) {
  const trimLetter =
    num && typeof num === 'string' ? num.replace('P', '').replace('M', '').replace('I', '').replace('W', '').replace('E', '').replace('R', '') : '';

  if (!trimLetter) return trimLetter;

  let reply = trimLetter.replace(/^[0-9]/g, '');
  let keep_doing = true;
  [...trimLetter].forEach((c, index) => {
    if (c !== '0' && keep_doing) {
      keep_doing = false;
      reply = trimLetter.substring(index);
    }
  });
  // if (reply.length>3) return reply.substring(0, reply.length - 3) + '-' + reply.substring(reply.length-3, reply.length);
  return reply;
}
