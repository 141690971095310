import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const ChartCardTitle = ({ title, subtitle }) => {
  const data = useSelector((state) => state.cashFlow.data);
  const query = useSelector((state) => state.cashFlow.requestParams.query);
  const formatDate = (date) => moment(date).format('MMM Do, YYYY');

  return (
    <div className="flex flex-column gap-1">
      <div className="text-lg semi-bold">{title}</div>
      <div className="text-sm text-gray-500">
        {subtitle
          ? subtitle
          : `Displaying ${data?.TotalRecords?.toLocaleString() || 0} claim(s) from ${formatDate(query.PeriodFrom)}, to ${formatDate(query.PeriodTo)}`}
      </div>
    </div>
  );
};

export default ChartCardTitle;
