import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { useStorage } from '../../../../../hooks/useStorage';
import { stringFormat } from '../../../../../utils/stringFormat';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { localStorageKeys } from '../../../../../config/localStorageKeysConfig';
import { getPractitionerNameWithSpec } from '../../../../../utils/getPractitioner';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const CompleteSetupDialog = () => {
  const key = localStorageKeys.doNotShowEClaimCompleteSetupDialog;
  const { localState, setLocalState, getValues } = useFormContext();
  const [checked, setChecked] = useState(false);
  const [localStorageValue, setValueInStorage] = useStorage(key);
  const practitionerGuid = getValues(inputs.practitioner.name);
  const practitionerName = getPractitionerNameWithSpec(practitionerGuid);

  useEffect(() => {
    setChecked(localStorageValue);
  }, [localStorageValue]);

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, completeSetupDialog: false }));
  };

  const handleCheck = (checked) => {
    setChecked(checked);
    setValueInStorage(checked);
  };

  const actionButtons = <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHideDialog} />;

  return (
    <Dialog
      id={elementIDs.completeSetupDialog}
      header={t('Complete_setup')}
      visible={localState.completeSetupDialog}
      breakpoints={{ '1366px': '45vw', '960px': '55vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      footer={actionButtons}
      onHide={onHideDialog}
    >
      <span className="text-lg">{stringFormat(t('Please_complete_setup_or_contact_us'), practitionerName)}</span>

      <div className="p-field-checkbox pt-4">
        <Checkbox className="mr-3" type="checkbox" inputId="dontShowThisAgainToday" checked={checked} onChange={(e) => handleCheck(e.checked)} />
        <label htmlFor="dontShowThisAgainToday">{t('Dont_show_this_again_today')}</label>
      </div>
    </Dialog>
  );
};

export default CompleteSetupDialog;
