import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { inputs as eClaimPolicyInputs } from '../../EClaimPolicy/helpers/inputs';
import { isEmpty } from 'lodash';
import { useLocation } from './useLocation';
import { deleteInsurancePolicy } from '../../../actions/claims.action.creators';
import { localStateInitValues } from '../helpers/initialState';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { t } from '../../../../../service/localization/i18n';

export const useInsurancePolicy = (input) => {
  const dispatch = useDispatch();
  const { setValue, getValues, errors, clearErrors, setLocalState, localState } = useFormContext();
  const { getLocation } = useLocation();
  const patients = getValues(inputs.patient.name);
  const primaryPolicyKey = inputs.primaryPolicy.name;
  const secondaryPolicyKey = inputs.secondaryPolicy.name;

  const isSelected = (item) => {
    const policy = getValues(input.name);
    if (isEmpty(policy)) return false;
    return item.RowGuid === policy?.RowGuid;
  };

  const onSelect = (item) => {
    const value = isSelected(item) ? {} : item;
    errors[input.name] && clearErrors(input.name);
    const insurerID = Number(value?.InsurerID);
    const isPbc = insurerID === -1;
    const isTec = insurerID !== -1;

    const itemInsurerId = item.InsurerGuid?.toLowerCase();
    const itemPolicyNum = item.PolicyNum?.toLowerCase();
    const itemMemberID = item.MemberID?.toLowerCase();

    const primaryPolicyInsurerId = getValues(primaryPolicyKey)?.InsurerGuid?.toLowerCase();
    const primaryPolicyNum = getValues(primaryPolicyKey)?.PolicyNum?.toLowerCase();
    const primaryMemberID = getValues(primaryPolicyKey)?.MemberID?.toLowerCase();

    const secondaryPolicyInsurerId = getValues(secondaryPolicyKey)?.InsurerGuid?.toLowerCase();
    const secondaryPolicyNum = getValues(secondaryPolicyKey)?.PolicyNum?.toLowerCase();
    const secondaryMemberID = getValues(secondaryPolicyKey)?.MemberID?.toLowerCase();

    const resetPrimaryPolicy =
      input.name === secondaryPolicyKey &&
      itemInsurerId === primaryPolicyInsurerId &&
      itemPolicyNum === primaryPolicyNum &&
      itemMemberID === primaryMemberID;
    const resetSecondaryPolicy =
      input.name === primaryPolicyKey &&
      itemInsurerId === secondaryPolicyInsurerId &&
      itemPolicyNum === secondaryPolicyNum &&
      itemMemberID === secondaryMemberID;

    setValue(input.name, value, { shouldDirty: true });

    // Update location
    const location = getLocation({ isPbc, isTec });
    setValue(inputs.location.name, location);

    if (resetPrimaryPolicy) return setValue(primaryPolicyKey, {});
    if (resetSecondaryPolicy) return setValue(secondaryPolicyKey, {});
  };

  const onEdit = (item) => {
    setLocalState((prevState) => ({
      ...prevState,
      eClaimPolicy: {
        policyInfo: {
          ...item,
          [eClaimPolicyInputs.patient.name]: Array.isArray(patients) ? patients : [patients]
        },
        showEClaimPolicyForm: true,
        isEdit: true
      }
    }));
  };

  const onDelete = (item) => {
    setLocalState((prevState) => ({
      ...prevState,
      deleteEClaimPolicy: {
        ...prevState.deleteEClaimPolicy,
        showDialog: true,
        policy: item
      }
    }));
  };

  const deleteInsurancePolicies = async () => {
    const policiesForDeletion = localState.deleteEClaimPolicy.policies;
    const requests = policiesForDeletion.map((i) => deleteInsurancePolicy(i));
    try {
      await Promise.all(requests);
      setLocalState((prevState) => ({
        ...prevState,
        deleteEClaimPolicy: localStateInitValues.deleteEClaimPolicy
      }));
    } catch (error) {
      dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
    }
  };

  return {
    onEdit,
    onSelect,
    onDelete,
    isSelected,
    deleteInsurancePolicies
  };
};
