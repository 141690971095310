import React from 'react';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../../helpers/inputs';
import moment from 'moment';

const ServiceDatesList = () => {
  const { watch } = useFormContext();
  const serviceDate = watch(inputs.serviceDate.name);
  const isArray = Array.isArray(serviceDate);

  if (!isArray || (isArray && serviceDate.length < 3)) return null;

  const formattedDates = serviceDate.map((i) => moment(i).format('MM/DD/YYYY'));

  return (
    <div class="surface-overlay">
      <p>{formattedDates.join().replaceAll(',', ', ')}</p>
    </div>
  );
};

export default ServiceDatesList;
