import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { stringSeparators } from '../../regex/regex';
import { setSelectedCodes } from '../../claims/actions/claims.action.creators';
import Context from '../../../Context';

import { t } from '../../../service/localization/i18n';
import _ from 'lodash';

const CodeInput = ({ classes, initialValue, setFieldFilter, field, fieldType, filters, keyfilter }) => {
  const dispatch = useDispatch();
  const { sortby, selectedCodes } = useContext(Context);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSetFieldFilter = (e) => {
    setValue(e.target.value);
    debouncedRequest(e, field, fieldType, filters, sortby, selectedCodes);
  };

  const debouncedRequest = useCallback(
    _.debounce((e, field, fieldType, filters, sortby, selectedCodes) => {
      //[KS] CMO-975 - Invoices table - Fee/Dx codes are not displaying in the filters sidebar when they are selected in the table filter
      const inputName = field === 'ICD9Code' ? 'ICD9Code' : field === 'FeeCode' ? 'FeeCode' : field;
      switch (field) {
        case inputName:
          const suspected = selectedCodes[field] ? selectedCodes[field] : [];
          const newValue = e.target.value?.length
            ? e.target.value?.split(stringSeparators)?.map((i) => {
                return suspected?.find((x) => x.value === i) || { value: i, label: '', text: '' };
              })
            : [];
          dispatch(setSelectedCodes(newValue, field));
          break;

        default:
          break;
      }
      //===========//

      setFieldFilter(e, field, fieldType, filters, sortby);
    }, 1500),
    []
  );

  return (
    <InputText
      className={classes}
      id={'flt_' + field}
      value={value}
      keyfilter={keyfilter}
      placeholder={t('Search')}
      onChange={(e) => onSetFieldFilter(e, field, fieldType)}
    />
  );
};

export default CodeInput;
