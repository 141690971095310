import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { routes } from '../../../../../../routes/routes';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const FormActionButtons = () => {
  const location = useLocation();
  const history = useHistory();
  const { isDirty, onCancel, reset, setLocalState, localState } = useFormContext();
  const showSaveAndClaim = !location.pathname?.includes(routes.eClaimNew.path);

  const handleCancel = () => {
    if (onCancel) {
      reset();
      onCancel();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {!isDirty && <Button id={elementIDs.close} className="w-full" type="button" label={t('Close')} onClick={handleCancel} />}

      {isDirty && (
        <>
          <Button
            id={elementIDs.submit}
            className="w-full"
            type="submit"
            form="eClaimPolicyForm"
            loading={localState.saving.save}
            disabled={localState.saving.saveAndClaim}
            label={t('Save')}
          />

          {showSaveAndClaim && (
            <Button
              id={elementIDs.submitAndClaim}
              className="p-button-outlined w-full"
              type="submit"
              form="eClaimPolicyForm"
              loading={localState.saving.saveAndClaim}
              disabled={localState.saving.save}
              label={t('Save_and_Claim')}
              onClick={() => setLocalState((prevState) => ({ ...prevState, saveAndClaim: true }))}
            />
          )}

          <Button
            id={elementIDs.cancel}
            className="p-button-outlined  w-full"
            type="button"
            label={t('Cancel')}
            disabled={localState.saving.save || localState.saving.saveAndClaim}
            onClick={handleCancel}
          />
        </>
      )}
    </>
  );
};

export default FormActionButtons;
