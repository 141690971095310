import { t } from '../../../../../service/localization/i18n';

export const salesTaxList = [
  {
    name: 'exempt',
    label: `${t('Exempt')} (0%)`,
    text: t('Exempt'),
    abbreviation: '',
    gst_percent: 0,
    pst_percent: 0
  },
  {
    name: 'zero_rated',
    label: `${t('Zero_rated')} (0%)`,
    text: t('Zero_rated'),
    abbreviation: '',
    gst_percent: 0,
    pst_percent: 0
  },
  {
    name: 'out_of_scope',
    label: `${t('Out_of_Scope')} (0%)`,
    text: t('Out_of_Scope'),
    abbreviation: '',
    gst_percent: 0,
    pst_percent: 0
  },
  {
    name: 'gst',
    label: `${t('GST')} (5%)`,
    text: t('GST'),
    abbreviation: 'G',
    gst_percent: 0.05,
    pst_percent: 0
  },
  {
    name: 'pst',
    label: `${t('PST_BC')} (7%)`,
    text: t('PST_BC'),
    abbreviation: 'P',
    gst_percent: 0,
    pst_percent: 0.07
  },
  {
    name: 'gst_pst',
    label: `${t('GST_PST_BC')} (12%)`,
    text: t('GST_PST_BC'),
    abbreviation: 'GP',
    gst_percent: 0.05,
    pst_percent: 0.07
  }
];
