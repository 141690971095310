import { useFormContext } from 'react-hook-form';

export const useChipRemove = () => {
  const { localState, setValue } = useFormContext();

  const onChipRemove = () => {
    setValue(localState?.focusFieldParams.name, '');
    setValue(localState?.focusFieldParams.codeDescription, '');
  };

  return { onChipRemove };
};
