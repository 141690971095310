import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../../../routes/routes';

const PromptDialog = () => {
  const { formState } = useFormContext();

  const promptMessage = (location, isDirty) => {
    //  return JSON.stringify({ isDirty: formState.isDirty });

    //Will be called with the next location and action the user is attempting to navigate to. Return a string to show a prompt to the user or true to allow the transition.
    return location.pathname.startsWith(routes.eClaimPolicyNew.path)
      ? true
      : JSON.stringify({
          isDirty,
          name: 'EClaim' //add 'name' to check which form should be nulled (see customPrompt for nulling)
        });
  };

  return <Prompt when={formState.isDirty} message={(location) => promptMessage(location, formState.isDirty)} />;
};

export default PromptDialog;
