import React, { useEffect, useRef } from 'react';
import { Editor as QuillEditor } from 'primereact/editor';

const Editor = ({ autoFocus, ...props }) => {
  const quillRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      const interval = setInterval(() => {
        if (quillRef.current && quillRef.current.getQuill) {
          clearInterval(interval);
          const length = props.value.length;
          const element = quillRef.current.getQuill();
          element?.focus();
          element.setSelection(length, length); // Set cursor to the end of the text
        }
      }, 100);

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  const header = (
    <div className="ql-formats flex gap-3">
      <div className="flex">
        <select className="ql-font">
          <option value="serif" />
          <option value="sans-serif" />
          <option value="monospace" />
        </select>
        <select className="ql-size">
          <option value="small" />
          <option selected />
          <option value="large" />
          <option value="huge" />
        </select>
      </div>
      <div className="flex">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <button className="ql-blockquote" />
      </div>
      <div className="flex">
        <select className="ql-color">
          <option value="rgb(0,0,0)" />
          <option value="rgb(255,0,0)" />
          <option value="rgb(255,153,0)" />
          <option value="rgb(255,255,0)" />
          <option value="rgb(0,255,0)" />
          <option value="rgb(0,255,255)" />
          <option value="rgb(0,0,255)" />
          <option value="rgb(255,0,255)" />
          <option value="rgb(255,255,255)" />
          <option value="rgb(128,0,0)" />
          <option value="rgb(128,128,0)" />
          <option value="rgb(0,128,0)" />
          <option value="rgb(0,128,128)" />
          <option value="rgb(0,0,128)" />
          <option value="rgb(128,0,128)" />
          <option value="rgb(192,192,192)" />
          <option value="rgb(128,128,128)" />
          <option value="rgb(0,0,0)" />
        </select>
        <select className="ql-background">
          <option value="rgb(255,0,0)" />
          <option value="rgb(255,153,0)" />
          <option value="rgb(255,255,0)" />
          <option value="rgb(0,255,0)" />
          <option value="rgb(0,255,255)" />
          <option value="rgb(0,0,255)" />
          <option value="rgb(255,0,255)" />
          <option value="rgb(255,255,255)" />
          <option value="rgb(128,0,0)" />
          <option value="rgb(128,128,0)" />
          <option value="rgb(0,128,0)" />
          <option value="rgb(0,128,128)" />
          <option value="rgb(0,0,128)" />
          <option value="rgb(128,0,128)" />
          <option value="rgb(192,192,192)" />
          <option value="rgb(128,128,128)" />
          <option value="rgb(0,0,0)" />
        </select>
      </div>
      <div className="flex">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-align" />
      </div>
      <div className="flex">
        <button className="ql-link" />
        <button className="ql-code-block" />
      </div>
    </div>
  );

  return <QuillEditor {...props} headerTemplate={header} ref={quillRef} />;
};

export default Editor;
