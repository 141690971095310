import React from 'react';
import { useFormContext } from 'react-hook-form';

import ClaimNumber from '../Inputs/ClaimNumber';
import InjuryDate from '../Inputs/InjuryDate';
import AOICode from '../Inputs/AOICode';
import NOICode from '../Inputs/NOICode';
import AnatomicCode from '../Inputs/AnatomicCode';

import { inputs } from '../../../helpers/inputs';
import { invoiceTypes } from '../../../helpers/defaultValues';

const PayorOptionalSection = () => {
  const { watch } = useFormContext();

  const wsbcLayout = (
    <>
      <div className="two_inputs_in_a_row">
        <ClaimNumber />
        <InjuryDate />
      </div>

      <AOICode />
      <NOICode />
      <AnatomicCode />
    </>
  );

  const layout = () => {
    const invoiceType = watch(inputs.payor.name);
    if (invoiceType === invoiceTypes.wsbc) return wsbcLayout;
    if (invoiceType === invoiceTypes.icbc) return <ClaimNumber />;
    return null;
  };

  return <>{layout()}</>;
};

export default PayorOptionalSection;
