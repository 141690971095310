import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import EClaimsTermsAndConditions from '../../../../../../components/Layouts/TermsAndConditions/EClaimsTermsAndConditions';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const TermsDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, showTermsDialog: false }));
  };

  const footer = <Button label={t('Close')} onClick={onHideDialog} />;

  return (
    <Dialog
      id={elementIDs.catalogsDialog}
      visible={localState.showTermsDialog}
      breakpoints={{ '1024px': '95vw' }}
      style={{ width: '80vw' }}
      onHide={onHideDialog}
      footer={footer}
    >
      <EClaimsTermsAndConditions />
    </Dialog>
  );
};

export default TermsDialog;
