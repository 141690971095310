import React from 'react';
import CustomAvatar from '../../../common/components/CustomAvatar';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { mobileRowToggle } from '../../../utils/mobileRowToggle';

const MobileRowTemplate = (props) => {
  let triggerTime;
  const avatarLetter = `${props.rowData.LastName.charAt(0).toUpperCase()}${props.rowData.FirstName.charAt(0).toUpperCase()}`;
  const _isExpanded = props.expandedRows ? Object.keys(props.expandedRows).some((i) => i === props.rowData[props.dataKey]) : {};

  return (
    <>
      {/* HEADER */}
      <div className="flex align-items-start justify-content-between w-full" style={{ minHeight: '70px' }}>
        <div>
          <CustomAvatar id={props.rowData.DoctorGuid} label={avatarLetter} />
        </div>

        <div
          className="flex flex-column justify-content-start align-items-start w-full px-3"
          onClick={() => props.longPressEvent(triggerTime, props.rowData)}
          onTouchStart={() => (triggerTime = new Date().getTime())}
          onTouchEnd={() => {
            let thisMoment = new Date().getTime();
            triggerTime = thisMoment - triggerTime;
          }}
        >
          {/* Practitioner name */}
          <div className="font-bold">{`${props.rowData.LastName}, ${props.rowData.FirstName}`}</div>
          {/* Practitioner number */}
          {props.rowData.PractitionerNumber && <div>{`#${props.rowData.PractitionerNumber}`}</div>}
          {/* Phone */}
          {props.rowData.Phone && props.rowData.Phone !== 'N/A' && <div>{props.rowData.Phone}</div>}
          {/* Email */}
          {props.rowData.Email && props.rowData.Email !== 'N/A' && <div>{props.rowData.Email}</div>}
        </div>

        <div className="flex flex-column justify-content-between align-items-center">
          <div>{props.rowActionButtons(props.rowData)}</div>

          {/* Expand row */}
          {props.showExpand && (
            <Button
              className="p-button-text p-button-rounded mt-2"
              icon={_isExpanded ? 'pi pi-angle-down' : 'pi pi-angle-right'}
              onClick={() => {
                mobileRowToggle({
                  rowData: props.rowData,
                  expandedRows: props.expandedRows,
                  dataKey: props.dataKey,
                  setExpandedRows: props.setExpandedRows
                });
              }}
            />
          )}
        </div>
      </div>

      {/* EXPAND CONTENT */}
      <div className={_isExpanded ? 'rowExpandedMobile' : 'rowCollapsedMobile'}>
        <div className="pt-1">
          <Divider />
          Test
        </div>
      </div>
    </>
  );
};

export default MobileRowTemplate;
