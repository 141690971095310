import React from 'react';
import { Button } from 'primereact/button';
import { icons } from '../../../../../../modules/config/stylesConfig';
import { useTable } from '../../../hooks/useTable';
import { t } from '../../../../../../service/localization/i18n';

const ArchiveButton = ({ rowData }) => {
  const { onArchive, showArchiveButton } = useTable();
  const status = Number(rowData.Status);

  if (!showArchiveButton(status)) return null;

  return <Button icon={icons.archived} label={t('Archive')} text onClick={() => onArchive(rowData)} />;
};

export default ArchiveButton;
