import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ScrollTop } from '../../Buttons';
import { debounce } from 'lodash';

// interface {
//   page: number, // Need for correct API requests for infinit scroll
//   totalPages: number, // Need for correct API requests for infinit scroll
//   onScroll: () => void;

//   requestParams?: any // aditional request params
//   className?: string;
//   style?: CSS Styles;
//   showScrollTop?: boolean;
//   ref?: ref // Pass "ref" if need to control scrolling up outside the InfinitScrollPanel component;
// }

const InfinitScrollPanel = React.forwardRef(
  ({ requestParams, style = {}, className = '', onScroll, page, totalPages, children, showScrollTop }, ref) => {
    const scrollContainerRef = useRef(null);
    const paginatorRef = useRef();
    const [showScrollUp, setShowScrollUp] = useState(false);
    const currentRef = ref || scrollContainerRef;
    paginatorRef.current = { currentPage: page, currentTotalPages: totalPages, currentRequestParams: requestParams };

    useEffect(() => {
      const scrollContainer = currentRef?.current;
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollContainer) {
          scrollContainer.removeEventListener('scroll', handleScroll);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const debouncedLoadMore = debounce((e) => onScroll(e), 250);

    const handleScroll = useCallback(() => {
      const scrollContainer = currentRef?.current;

      if (!scrollContainer) return;

      const scrollPosition = scrollContainer.scrollTop;
      const totalHeight = scrollContainer.scrollHeight;
      const containerHeight = scrollContainer.clientHeight;

      // Adjust the threshold as needed
      const threshold = 50;

      const isScrollingDown = scrollPosition > (scrollContainer._lastScrollTop || 0);
      scrollContainer._lastScrollTop = scrollPosition;

      setShowScrollUp(scrollPosition > containerHeight);

      if (isScrollingDown && totalHeight - (scrollPosition + containerHeight) < threshold) {
        const { currentPage, currentTotalPages, currentRequestParams } = paginatorRef.current;
        if (currentPage < currentTotalPages) {
          debouncedLoadMore({ page: currentPage + 1, totalPages: currentTotalPages, requestParams: currentRequestParams });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div ref={currentRef} className={className} style={{ overflowY: 'auto', height: '400px', position: 'relative', ...style }}>
        {children}
        {showScrollTop && showScrollUp && <ScrollTop ref={currentRef} />}
      </div>
    );
  }
);

export default InfinitScrollPanel;
