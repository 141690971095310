import React from 'react';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { address_types, provinces_list } from '../../contants';
import { toTitleCase } from '../../../utils/toTitleCase';
import { formatZipCode } from '../../../utils/formatZipCode';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { t } from '../../../../service/localization/i18n';

const Address = ({ formik, getFormErrorMessage, rootClass = '', showAddressType = false }) => {
  const { isMobile } = useSelector((state) => state.core.window);

  return (
    <>
      <div className={rootClass}>
        <div className="field">
          <label className="text-disabled-color">{t('Street Address')}</label>
          <InputText
            id="AddressLine"
            name="AddressLine"
            autoFocus={!isMobile}
            value={formik.values.AddressLine}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('AddressLine', toTitleCase(e.target.value));
            }}
          />
        </div>

        <div className="field">
          <label className="text-disabled-color">{t('City')}</label>
          <InputText
            id="City"
            name="City"
            value={formik.values.City}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('City', toTitleCase(e.target.value));
            }}
          />
        </div>

        <div className="field">
          <label className="text-disabled-color">{`${t('Province')}`}</label>
          <Dropdown
            id="Province"
            name="Province"
            scrollHeight={dropdownScrollHeight()}
            value={formik.values.Province}
            options={provinces_list}
            onChange={formik.handleChange}
          />
        </div>

        <div className="field">
          <label className="text-disabled-color">{t('Zip')}</label>
          <InputText
            id="Zip"
            name="Zip"
            value={formik.values.Zip}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('Zip', formatZipCode(e.target.value));
            }}
          />
          {getFormErrorMessage('Zip')}
        </div>

        {formik.values.AddressLine?.length > 0 && showAddressType && (
          <div className="field">
            <label className="text-disabled-color">{t('Address_Type')}</label>
            <Dropdown id="AddressType" name="AddressType" options={address_types} value={formik.values.AddressType} onChange={formik.handleChange} />
          </div>
        )}
      </div>
    </>
  );
};

export default Address;
