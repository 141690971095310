import React from 'react';
import { useInvoiceTimelineContext } from '../hooks/useInvoiceTimelineContext';
import { getRemittanceLink } from '../../../../../modules/utils/getLink';
import { t } from '../../../../../service/localization/i18n';

const TimelineContent = ({ item }) => {
  const { state } = useInvoiceTimelineContext();
  const data = state.invoice;
  console.log('data', data);
  return (
    <div className="mb-3 white-space-normal">
      <div className="font-bold">{item.statusText}</div>

      <div className="mt-1">
        {item.dateLink ? (
          data?.ClaimType === 'E' ? (
            <div className="mt-1">
              <a href={getRemittanceLink(data)} target="_blank" rel="noreferrer">
                {item.number}
              </a>
            </div>
          ) : (
            <div className="mt-1">
              <a href={getRemittanceLink(data)} target="_blank" rel="noreferrer">
                {data.Received}
              </a>
            </div>
          )
        ) : (
          <div className="mt-1">{item.number}</div>
        )}
      </div>

      {data.InvoiceType !== 'P' && item?.description?.length > 0 && (
        <div>
          {item.description.map((x, index) => {
            const key = `msc_${data.InvoiceGuid}${index}`;
            return (
              <div className="mt-1" key={key}>
                <span className="p-error">{x}</span>
              </div>
            );
          })}
        </div>
      )}

      <div className="mt-1">{item.date}</div>

      {item.resubmitBefore && (Number(data?.Status) === 3 || Number(data?.Status) === -3) && (
        <div className="mt-1">{`${t('Resubmit_before')}: ${item.resubmitBefore}`}</div>
      )}
    </div>
  );
};

export default TimelineContent;
