import { t } from '../../../../../service/localization/i18n';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const inputs = {
  dcnguid: {
    name: 'DCNGuid'
  },

  patientGuid: {
    name: 'PatientGuid'
  },

  memberGuid: {
    name: 'MemberGuid'
  },

  rowGuid: commonInputs.rowGuid,

  insurerGuid: commonInputs.insurerGuid,

  insurerID: {
    name: 'InsurerID',
    label: t('Insurance_Company')
  },

  insurerNameEN: commonInputs.insurerNameEN,

  patient: commonInputs.patient,

  firstName: {
    name: commonInputs.firstName.name,
    label: commonInputs.firstName.label
  },

  lastName: {
    name: commonInputs.lastName.name,
    label: commonInputs.lastName.label
  },

  midName: {
    name: commonInputs.midName.name,
    label: commonInputs.midName.label
  },

  gender: {
    name: commonInputs.gender.name,
    label: commonInputs.gender.label
  },

  birthDay: {
    name: commonInputs.birthDay.name,
    label: commonInputs.birthDay.label,
    placeholder: commonInputs.birthDay.placeholder
  },

  memberID: commonInputs.memberID,

  policyNumber: commonInputs.policyNumber,

  benefitType: {
    name: 'BenefitType',
    label: t('Benefit_Type')
  },

  relationship: {
    name: 'Relationship',
    label: t('Patient_is_his_her')
  },

  insuredType: {
    name: 'InsuredType',
    label: t('Insured_Member')
  },

  birthOrder: {
    name: 'BirthOrder',
    label: t('Birth_Order')
  },

  memberFirstName: {
    name: 'MemberFirstName',
    label: commonInputs.firstName.label
  },

  memberLastName: {
    name: 'MemberLastName',
    label: commonInputs.lastName.label
  },

  memberMidName: {
    name: 'MemberMidName',
    label: commonInputs.midName.label
  },

  memberGender: {
    name: 'MemberSex',
    label: commonInputs.gender.label
  },

  memberDOB: {
    name: 'MemberDOB',
    label: commonInputs.birthDay.label,
    placeholder: commonInputs.birthDay.placeholder
  },

  status: {
    name: 'Status'
  },

  isPrimary: commonInputs.isPrimary,

  validFrom: {
    name: 'ValidFrom'
  },

  validTo: {
    name: 'ValidTo'
  }
};
