import { useFormContext } from 'react-hook-form';
import { t } from '../../../../../service/localization/i18n';

export const useValidate = () => {
  const { trigger, signInState, onSubmit, getValues } = useFormContext();

  const onSignIn = async () => {
    let isValid = false;

    switch (signInState.step) {
      case 1: {
        isValid = await trigger('username');
        break;
      }

      case 2: {
        isValid = await trigger('password');
        break;
      }

      default:
        break;
    }

    if (isValid) onSubmit(getValues());
  };

  const usernameValidation = (value) => {
    return !!value || t('Mandatory_field.1');
  };

  const passwordValidation = (value) => {
    return !!value || t('Mandatory_field.1');
  };

  return { onSignIn, usernameValidation, passwordValidation };
};
