import { updatePrivateRecord } from '../../../actions/claims.action.creators';

export const updateLockValue = (lockValue, prefs, dispatch) => {
  const value = Array.isArray(lockValue) ? lockValue : [lockValue];
  const updatedPrefs = {
    ...prefs,
    content: prefs?.content?.map((i) => value.find((x) => x.label === i.label) || i)
  };
  dispatch(updatePrivateRecord({ CurrentUserPrefs: updatedPrefs }));
};
