import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import CustomAvatar from '../../../common/components/CustomAvatar';
import { elementIDs } from '../../../config/elementIDsConfig';
import cx from 'classnames';
import { t } from '../../../../service/localization/i18n';
import LFPBadge from '../../../../components/Misc/Badges/LFP/LFPBadge';

import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import { isEmpty } from 'lodash';
import StaffCard from './StaffCard';
import { getSpeciality } from '../../../config/specialitiesConfig'; 

//import { Menu } from 'primereact/menu'; not active for now
//import React, { useRef } from 'react'; 




const StaffDetails = () => {
    const { id } = useParams();
    
    const history = useHistory();
    const { members } = useSelector((state) => state.clinic);
    const index = members.find(x => x.DoctorGuid === id);
    const firstName = index?.FirstName;
    const lastName = index?.LastName;
    const fullName = index?.FullName;
    const fullAddress = index?.FullAddress;
    const role = index?.Role;
    const msp = index?.PractitionerNumber;
    const speciality = index?.Speciality;


    const { isMobile, isMobileOnly } = useSelector((state) => state.core.window);
    //const menuRef = useRef(null); related to pop up menue for edit (not active for now)


    const showSkeleton = isEmpty(index); 

    const avatarLetter = `${lastName?.charAt(0).toUpperCase()}${firstName?.charAt(0).toUpperCase()}`;


    const header = () => {
        return (
          <div className="flex col-12 p-2 mb-3">
            <div className="flex col-9 p-0 align-items-start">
              <div className="flex align-items-center mr-3">
                {showSkeleton ? (
                  <Skeleton size="3rem" />
                ) : (
                  <CustomAvatar id={id} label={avatarLetter} />
                )}
              </div>
    
               <div className="flex flex-column justify-content-center">
                <div
                  id={firstName}
                  className={cx('flex align-items-center mb-1 font-bold', isMobileOnly ? 'medium-size-text' : 'large-text')}
                  style={{ lineHeight: '18px' }}
                >
                  {showSkeleton ? (
                    <Skeleton width="10em" />
                  ) : (
                    <div className="flex align-items-center gap-3">
                      {fullName?.trim()}
                      {id?.LfpPanel && <LFPBadge />}
                    </div>
                  )}
                </div>
                <div id={elementIDs.patientDOB} className="mb-1">
                  {showSkeleton ? <Skeleton className="mt-1" width="10em" /> : extraDetails()}
                </div>
              </div>
            </div> 
    
            <div className="flex align-items-start justify-content-end col-3 p-0">
              <>
                <Button
                  id={elementIDs.threeDotsButton}
                  className="p-button-text p-button-rounded hidden" //hidden for now 
                  icon="pi pi-ellipsis-v"
                  aria-controls="popup_menu"
                />
                {/* <Menu
                  model={threeDotsMenu} //Not implemented for now
                  popup
                  ref={menuRef}
                  id="popup_menu"
                  viewportHeight={190}
                  menuWidth={175}
                  appendTo={document.body}
                  baseZIndex={1000000}
                /> */}
    
                <Button
                  id={elementIDs.threeDotsButton}
                  className="p-button-text p-button-rounded ml-2"
                  icon="pi pi-times"
                  onClick={() => history.goBack()}
                  tooltip='Close' tooltipOptions={{ position: 'top' }}
                /> 
            </>
            </div>
          </div>
        );
      };

      const fixAbrr = (role) => {
        if (role === "doc"){
            return "Doctor";
        }
        else{
            return role;
        }
      }

      const extraDetails = () => {
        if ((msp && msp.length > 0) && (fullAddress && fullAddress.length > 0 )&& (role && role.length > 0)) {
          return (
            <div className="flex flex-column">
              <span id={elementIDs.patientPHN}>MSP# {msp}</span>
              <span>{getSpeciality(speciality).label}</span>
              {/* <span>{fixAbrr(role)}</span> */}
            </div>
          );
        }
        return (
          <div className="flex flex-column">
            <span id={elementIDs.patientPHN}>MSP# {msp}</span>
          </div>
        );
      };

    
  
    return (
    <div className="flex col-12 p-0 justify-content-center">
        <div id={elementIDs.patientProfile} className="containerXL subMenuRoot mt-4 px-3 w-full">
            
            {header()}
            <TabView>
                <TabPanel header={t('Profile')} headerClassName="extra-large-text">
                  <div id="patient-profile-contact-card">

                    <StaffCard
                      index={index}
                      isMobileOnly={isMobileOnly}
                      showSkeleton={showSkeleton}
                    />
                  </div>
                </TabPanel>
                <TabPanel className='hidden' header={t('Money')} headerClassName="extra-large-text">
                 <div className="mt-3">
                    {/* <h1 style={{ textAlign: 'center' }}>Hello</h1>  place holder*/}
                  </div>
                 </TabPanel>
            </TabView>
        </div>
      </div>
    );
  
};

export default StaffDetails;
