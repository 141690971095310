import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Chip } from 'primereact/chip';
import { InputWrapper } from '../../../../Inputs';
import { inputs } from '../helpers/inputs';

const Author = () => {
  const name = inputs.author.name;
  const label = inputs.author.label;
  const { getValues } = useFormContext();

  return (
    <InputWrapper name={name} label={label}>
      <Chip className="w-max mt-1" label={getValues(name)} icon="pi pi-user" />
    </InputWrapper>
  );
};

export default Author;
