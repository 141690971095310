import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { editPatientProfile } from '../../../patients/components/Patients/helpers/rowActionsMenuItems';
import { getPatientsWithMissingData } from '../../../patients/actions/patients.action.creators';
import { getClaimList, submitAllClaims } from '../../../claims/actions/claims.action.creators';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { icons } from '../../../config/stylesConfig';
import { isArray } from 'lodash';
import { stringFormat } from '../../../utils/stringFormat';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const BatchRecordsWarnDialogForMultiplePatients = ({ data, visible, onHide, updateClaimsList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSubmittingAllClaims, filters, pageSize, sortby } = useSelector((state) => state.claims);
  const { isFetchingPatientsWithMissingData } = useSelector((state) => state.patients);
  const [patientsList, setPatientsList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const warnMessage = stringFormat(t('Missing_or_invalid_data_in_x_profiles'), data?.TotalInValid);

  useEffect(() => {
    if (visible) {
      dispatch(
        getPatientsWithMissingData((responseData) => {
          setPatientsList(responseData);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const footer = () => {
    return (
      <div className="flex flex-column sm:flex-row justify-content-end buttonsGap">
        <Button
          label={t('Move_to_draft_and_submit_the_rest')}
          loading={isSubmittingAllClaims}
          onClick={() => {
            dispatch(
              submitAllClaims('smartsubmit', () => {
                updateClaimsList &&
                  dispatch(
                    getClaimList({
                      page: 1,
                      pageSize: pageSize,
                      filter: JSON.stringify(filters),
                      query: `pid=undefined&sortby=${sortby}&q=${JSON.stringify(filters)}`
                    })
                  );
                onHide();
              })
            );
          }}
        />
        <Button className="p-button-outlined" label={t('Close')} onClick={onHide} />
      </div>
    );
  };

  const warnMessageContent = (
    <div className="flex justify-content-start align-items-center w-full">
      <i className={cx('mr-3', icons.warn)} />
      {warnMessage}
    </div>
  );

  const nameBody = (rowData) => {
    const errorMessage = isArray(data?.Issues) ? data?.Issues.find((i) => i.PatientGuid === rowData.PatientGuid)?.Message : '';

    return (
      <div className="flex" style={{ columnGap: '10px' }}>
        <div className="font-bold">{patientFullNameWithAge(rowData)}</div>

        <div>&ndash;</div>

        <div>{errorMessage}</div>
      </div>
    );
  };

  const actionsBodyTemplate = (rowData) => {
    return (
      <Button
        className="p-button-text p-button-rounded"
        icon={icons.edit}
        tooltip={t('Edit_profile')}
        onClick={() => {
          onHide();
          editPatientProfile(rowData, history);
        }}
      />
    );
  };

  return (
    <Dialog
      header={t('Warning')}
      visible={visible}
      breakpoints={{ '1366px': '60vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '40vw' }}
      modal
      // blockScroll
      footer={footer()}
      baseZIndex={100000}
      onHide={onHide}
    >
      <Message className="w-full" severity="warn" content={warnMessageContent} />

      <DataTable
        className="pt-4"
        value={patientsList}
        responsiveLayout="scroll"
        sortField="LastName"
        sortOrder={1}
        selectionMode="single"
        selection={selectedPatient}
        onSelectionChange={(e) => setSelectedPatient(e.value)}
        emptyMessage={isFetchingPatientsWithMissingData ? `${t('Loading')}...` : t('No_records_found')}
      >
        <Column field="LastName" header="Name" body={nameBody} sortable />
        <Column body={actionsBodyTemplate} style={{ width: '2.5em' }} />
      </DataTable>
    </Dialog>
  );
};

export default BatchRecordsWarnDialogForMultiplePatients;
