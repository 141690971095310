import SectionTitle from './SectionTitle';
import PriorProblems from '../Inputs/PriorProblems';
import { t } from '../../../../../../../service/localization/i18n';

const PriorSection = () => {
  return (
    <>
      <SectionTitle title={t('Prior')} className="mb-3" />

      <PriorProblems />
    </>
  );
};

export default PriorSection;
