import { updateInvoicesQuickPickCodesList } from '../../../actions/claims.action.creators';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { inputs } from './inputs';

export const addNewServiceToQuickPickList = (value, privateRecord, catalogMostRecentCodes, dispatch) => {
  //[KS] CMO-1548 - Create private invoice - new service codes do not appear in catalogs
  const category = getCategoryType(inputs().privateService.codeType);
  const mostRecentCodes = catalogMostRecentCodes[category] || [];
  const servicesId = privateRecord[inputs().privateService.descriptionName]?.map((i) => i?.value);
  const newCode = value?.find((i) => !servicesId?.includes(i?.value));
  const checkIfUniq = mostRecentCodes?.some((i) => i?.value === newCode?.value) ? mostRecentCodes : [newCode, ...mostRecentCodes];
  if (!newCode) return;
  dispatch(updateInvoicesQuickPickCodesList(checkIfUniq, category));
};
