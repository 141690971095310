import React from 'react';
import CashFlow from '../../cashFlow/components';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';

const ChartsView = () => {
  return (
    <PageWrap>
      <CashFlow />
    </PageWrap>
  );
};

export default ChartsView;
