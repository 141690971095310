import React from 'react';
import { useFormContext } from 'react-hook-form';

import FeeAmount from '../Inputs/FeeAmount';
import FeeTotal from '../Inputs/FeeTotal';
import Percent from '../Inputs/Percent';
import Units from '../Inputs/Units';

const UnitsSection = () => {
  const { isNew } = useFormContext();

  if (isNew) return null;

  return (
    <>
      <div className="teleplan_two_inputs_in_a_row">
        <Units />
        <Percent />
      </div>

      <div className="teleplan_two_inputs_in_a_row">
        <FeeAmount />
        <FeeTotal />
      </div>
    </>
  );
};

export default UnitsSection;
