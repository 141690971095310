import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { discountOptions } from './discountOptions';
import { integersOnly } from '../../../../regex/regex';
import { onEditorValueChange } from './onEditorValueChange';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { rowHeight } from './columns';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';

export const discountEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  const value = discountOptions.find((i) => i.name === params?.rowData?.discountUnits);

  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Discount.1')}</span>
          <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ height: rowHeight }}>
            ―
          </div>
        </div>
      )}

      {records[params.rowIndex]?.id !== '-1' && (
        <div className="p-inputgroup">
          <span className="p-column-title">{t('Discount.1')}</span>
          <InputText
            id={elementIDs.discountInput}
            keyfilter={integersOnly}
            autoFocus
            value={records[params.rowIndex]?.discount}
            style={{ height: rowHeight, width: '5em' }}
            onChange={(e) => onEditorValueChange(params, e.target.value, 'discount', records, setRecords, setIsReviewDirty)}
          />
          <Dropdown
            className="salesTaxEditor"
            id={elementIDs.discountUnitsInput}
            value={value}
            options={discountOptions}
            optionLabel="label"
            style={{ height: rowHeight, width: '4em', borderLeft: '1px solid lightgray' }}
            onChange={(e) => onEditorValueChange(params, e.value?.name, 'discountUnits', records, setRecords, setIsReviewDirty)}
          />
        </div>
      )}
    </div>
  );
};
