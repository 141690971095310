import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWatch, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { isRebillStatus } from '../../../../../utils/getStatus';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';

const GenerateInvoiceButton = () => {
  const { isSavingClaims, isFetchingGeneratedInvoices } = useSelector((state) => state.claims);
  const { isFetchingPHNValidation } = useSelector((state) => state.patients);
  const { isGettingPrefs } = useSelector((state) => state.preferences);
  const { isGroup, watch } = useFormContext();

  const saveAsDraft = watch(inputs.saveAsDraft.name);
  const label = saveAsDraft ? t('Save') : t('Generate_Invoices');

  return (
    <Button
      id={saveAsDraft ? elementIDs.save : elementIDs.generateInvoices}
      className="w-full"
      type="submit"
      form="teleplanForm"
      label={label}
      tooltip={!isGroup && t('Preview_the_claims_before_submitting_them')}
      tooltipOptions={{ position: 'top' }}
      loading={isFetchingGeneratedInvoices || isSavingClaims}
      disabled={isFetchingPHNValidation || isGettingPrefs}
    />
  );
};

const NextButton = () => {
  const { isGettingPrefs } = useSelector((state) => state.preferences);

  return <Button id={elementIDs.next} className="w-full" type="submit" form="teleplanForm" label={t('Next')} disabled={isGettingPrefs} />;
};

const ReviewButton = () => {
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { setLocalState } = useFormContext();

  return (
    <Button
      id={elementIDs.reviewAndSubmitButton}
      className="w-full"
      type="submit"
      form="teleplanForm"
      label={t('Review')}
      loading={isSavingClaims}
      onClick={() => setLocalState((prevState) => ({ ...prevState, saveNow: false }))}
    />
  );
};

const SaveButton = () => {
  const { isSavingClaims } = useSelector((state) => state.claims);
  const { control, setLocalState } = useFormContext();
  const status = useWatch({ control, name: inputs.status.name });
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const isMobile = window.innerWidth < 400;

  return (
    <Button
      id={elementIDs.save}
      className={cx('w-full', saveAsDraft || status === 4 ? '' : 'p-button-outlined')}
      type="submit"
      form="teleplanForm"
      loading={isSavingClaims}
      label={isMobile ? t('Save') : t('Save_and_Close')}
      onClick={() => setLocalState((prevState) => ({ ...prevState, saveAndClose: true }))}
    />
  );
};

const CloseButton = ({ className = '' }) => {
  const { onClose } = useFormContext();

  return <Button id={elementIDs.close} className={cx('w-full', className)} type="button" label={t('Close')} onClick={onClose} />;
};

const CancelButton = () => {
  const history = useHistory();

  return <Button id={elementIDs.cancel} className="p-button-outlined  w-full" type="button" label={t('Cancel')} onClick={() => history.goBack()} />;
};

const NewClaimLayout = () => {
  const { isDirty, isGroup } = useFormContext();

  if (!isDirty) return <CloseButton />;

  if (isGroup) {
    return (
      <>
        <NextButton />
        <CancelButton />
      </>
    );
  }

  return (
    <>
      <GenerateInvoiceButton />
      <CancelButton />
    </>
  );
};

const EditClaimLayout = () => {
  const { isDirty, control, watch } = useFormContext();
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const status = Number(watch(inputs.status.name));
  const rebillStatus = isRebillStatus(status);
  const autoEdit = isDirty || rebillStatus || status === 1;

  // If NOT Declined status
  if (!autoEdit) {
    return (
      <>
        {status !== 4 && <ReviewButton />}
        <CloseButton className={status === 4 ? '' : 'p-button-outlined'} />
      </>
    );
  }

  // If saveAsDraft or Archived status
  if (saveAsDraft || status === 4) {
    return (
      <>
        <SaveButton />
        <CancelButton />
      </>
    );
  }

  return (
    <>
      <ReviewButton />
      <SaveButton />
      <CancelButton />
    </>
  );
};

const FormActionButtons = () => {
  const { isNew } = useFormContext();

  return (
    <>
      {/* Create new claim */}
      {isNew && <NewClaimLayout />}

      {/* Edit claim */}
      {!isNew && <EditClaimLayout />}
    </>
  );
};

export default FormActionButtons;
