import { store } from '../../../../../store';
import { getPatientInfo, setPatientIdForHighlightInTable, setPatientInfo } from '../../../actions/patients.action.creators';
import { icons } from '../../../../config/stylesConfig';
import { routes } from '../../../../../routes/routes';
import { addEClaim, addNewEClaimPolicy, addNewPrivate, addNewTeleplan, addNewWSBCR } from '../../../../claims/helpers/addNewInvoice';
import { addNewItemToArray } from '../../../../utils/addNewItemToArray';
import { showPolicy } from '../../../../config/specialitiesConfig';
import { t } from '../../../../../service/localization/i18n';

export const rowActionsMenuItems = (props) => {
  const dispatch = store.dispatch;
  const user = store.getState().user.details;
  const showNewTeleplanButtons = store.getState().core.window.showNewTeleplanButtons;
  const billId = 'bill';

  let commonItems = [
    {
      id: 'view',
      icon: icons.info,
      label: t('View'),
      command: () => viewPatientProfile(props.rowData, props.history)
    },
    {
      id: 'edit',
      icon: icons.edit,
      label: t('Edit'),
      command: () => editPatientProfile(props.rowData, props.history)
    },

    { separator: true },

    {
      id: billId,
      icon: icons.dollar,
      label: t('Bill'),
      items: [
        {
          id: 'invoice',
          // icon: icons.invoice,
          label: t('Invoices.create'),
          command: () => {
            addNewPrivate({ history: props.history, dispatch, patient_details: props.rowData });
            dispatch(setPatientIdForHighlightInTable(props.rowData));
          }
        }
      ]
    },

    { separator: true },

    {
      id: 'delete',
      label: t('Delete'),
      icon: icons.delete,
      command: () => {
        props.setSelectedRows(props.rowData);
        props.setDeletePatientConfirm(true);
      }
    }
  ];

  const claimButton = {
    id: 'teleplan',
    // icon: icons.dollar,
    label: t('Create_Teleplan'),
    command: () => {
      addNewTeleplan({ history: props.history, patient_details: props.rowData });
      dispatch(setPatientIdForHighlightInTable(props.rowData));
    }
  };

  const telteplanPOS = {
    id: 'telteplanPOS',
    // icon: icons.dollar,
    label: t('Create_Teleplan_POS'),
    command: () => {
      addNewTeleplan({ history: props.history, patient_details: props.rowData, pos: true });
      dispatch(setPatientIdForHighlightInTable(props.rowData));
    }
  };

  const telteplanGroup = {
    id: 'telteplanGroup',
    // icon: icons.dollar,
    label: t('Create_Teleplan_Batch'),
    command: () => {
      addNewTeleplan({ history: props.history, patient_details: props.rowData, group: true });
      dispatch(setPatientIdForHighlightInTable(props.rowData));
    }
  };

  const wsbcrButton = {
    id: 'wsbcr',
    // icon: icons.invoice,
    label: t('WSBC_Report'),
    command: () => addNewWSBCR({ history: props.history, patient_details: props.rowData })
  };

  const eClaimButton = {
    id: 'eClaim',
    label: t('Create_eClaim'),
    command: () => addEClaim({ history: props.history, patientGuid: props.rowData?.PatientGuid })
  };

  const eClaimPolicyButton = {
    id: 'eClaimPolicy',
    label: t('Create_eClaim_Policy'),
    command: () => addNewEClaimPolicy({ history: props.history, patientGuid: props.rowData?.PatientGuid })
  };

  if (user.canBillMSP) {
    const billItems = commonItems.find((i) => i.id === billId);
    const adaptiveBillItems = showNewTeleplanButtons ? [claimButton] : [claimButton, telteplanPOS, telteplanGroup];
    const updatedBillItems = addNewItemToArray(billItems.items, 0, adaptiveBillItems);

    commonItems = commonItems.map((i) => {
      if (i.id === billId) return { ...billItems, items: updatedBillItems };
      return i;
    });
  }

  if (user.canBillWCB) {
    const billItems = commonItems.find((i) => i.id === billId);
    const updatedBillItems = addNewItemToArray(billItems.items, billItems.items.length, wsbcrButton);

    commonItems = commonItems.map((i) => {
      if (i.id === billId) return { ...billItems, items: updatedBillItems };
      return i;
    });
  }

  if (showPolicy()) {
    const billItems = commonItems.find((i) => i.id === billId);
    const updatedBillItems = addNewItemToArray(billItems.items, billItems.items.length, [eClaimButton, eClaimPolicyButton]);

    commonItems = commonItems.map((i) => {
      if (i.id === billId) return { ...billItems, items: updatedBillItems };
      return i;
    });
  }

  return commonItems;
};

export const editPatientProfile = (patientDetails, history, replace = false) => {
  const dispatch = store.dispatch;
  dispatch(
    getPatientInfo({
      id: patientDetails.PatientGuid,
      callback: (patient) => {
        dispatch(setPatientInfo(patient));
        dispatch(setPatientIdForHighlightInTable(patientDetails));
        const baseURL = `${routes.editProfile.path}/${patientDetails.PatientGuid}`;
        replace ? history.replace(baseURL) : history.push(baseURL);
      }
    })
  );
};

export const viewPatientProfile = (patientDetails, history) => {
  const dispatch = store.dispatch;
  dispatch(setPatientIdForHighlightInTable(patientDetails));
  history.push(`${routes.patientDetails.path}/${patientDetails.PatientGuid}`);
};
