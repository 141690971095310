import { getAllPatientNotes, getPatientNotes, createPatientNote, editPatientNote, deletePatientNote } from '../actions/patients.action.creators';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientNotes } from '../../notes/actions/actions';
import { routes } from '../../../routes/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const initState = { page: 1, pageSize: 20, totalPages: 0, first: 0, totalRecords: 0, notesList: [], isLoadingNotes: false };

const usePatientNotes = (patientGuid) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(initState);
  const patientNotes = useSelector((state) => state.notes.patientNotes);
  const [loadingAllNotes, setLoadingAllNotes] = useState(false);

  // Get init notes
  useEffect(() => {
    if (patientGuid) fetchNotes();
    //fetchAllNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset notes on unmount
  useEffect(() => {
    return () => {
      resetNotes();
    };
  }, []);

  const fetchAllNotes = async ({ page, pageSize, query } = {}) => {
    setLoadingAllNotes(true);
    const result = await getAllPatientNotes({
      pageSize: pageSize || patientNotes.pageSize || 20,
      page: page || patientNotes.page || 1,
      query
    });
    setLoadingAllNotes(false);
    if (result) {
      dispatch(setPatientNotes({ ...result, first: (result.page - 1) * patientNotes.pageSize }));
    }
  };

  const searchForAllNotes = async ({ freetext } = {}) => {
    setLoadingAllNotes(true);
    const result = await getAllPatientNotes({
      pageSize: patientNotes.pageSize || 20,
      page: 1,
      freetext
    });
    setLoadingAllNotes(false);
    if (result) {
      dispatch(setPatientNotes({ ...result, first: (result.page - 1) * patientNotes.pageSize }));
    }
  };

  const fetchNotes = useCallback(
    async ({ page, pageSize, resetNotesList } = {}) => {
      const pageSizeValue = pageSize > 0 ? pageSize : notes.pageSize;
      setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
      const result = await getPatientNotes({
        pageSize: pageSizeValue,
        page: page || notes.page,
        patientGuid
      });
      setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
      if (result) {
        const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
        setNotes((prevState) => ({
          ...result,
          pageSize: notes.pageSize,
          notesList: resetNotesList ? newNotesList : [...prevState.notesList, ...newNotesList]
        }));
      }
    },
    [patientGuid, notes.pageSize, notes.page]
  );

  const searchForNotes = async ({ freetext } = {}) => {
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: true }));
    const result = await getPatientNotes({
      pageSize: notes.pageSize,
      page: 1,
      patientGuid,
      freetext
    });
    setNotes((prevState) => ({ ...prevState, isLoadingNotes: false }));
    if (result) {
      const newNotesList = result.notesList && Array.isArray(result.notesList) ? result.notesList : [];
      setNotes({
        ...result,
        pageSize: notes.pageSize,
        notesList: newNotesList
      });
    }
  };

  const onCreateNote = async (note, options) => {
    const result = await createPatientNote(note);
    if (result) {
      if (options) {
        const { searchQuery } = options;
        if (searchQuery) {
          fetchNotes({ resetNotesList: true });
        } else {
          setNotes((prevState) => ({
            ...prevState,
            notesList: [result, ...prevState.notesList]
          }));
        }
      }
    }
  };

  const onReplyNote = async (note) => {
    await createPatientNote(note);
    fetchAllNotes({ page: patientNotes.page, pageSize: patientNotes.pageSize });
    history.replace(routes.patientNotesInbox.path);
  };

  const onEditNote = async (note, options) => {
    const result = await editPatientNote(note);
    if (result) {
      const { searchQuery } = options;
      if (searchQuery) {
        fetchNotes({ resetNotesList: true });
      } else {
        setNotes((prevState) => {
          const updatedNotesList = prevState.notesList?.map((i) => {
            return i.NoteGuid === result.NoteGuid ? result : i;
          });

          return {
            ...prevState,
            notesList: updatedNotesList
          };
        });
      }
    }
  };

  const onDeleteNote = async (noteGuid) => {
    await deletePatientNote(noteGuid);
    fetchAllNotes({ page: patientNotes.page, pageSize: patientNotes.pageSize });
  };

  const onSearch = async (query) => {
    await searchForNotes({ freetext: query });
  };

  const onSearchAll = async (query) => {
    await searchForAllNotes({ freetext: query });
  };

  const onScroll = ({ page } = {}) => {
    fetchNotes({ page });
  };

  const resetNotes = () => {
    setNotes(initState);
  };

  return {
    notes,
    fetchAllNotes,
    loadingAllNotes,
    setNotes,
    fetchNotes,
    searchForNotes,
    onScroll,
    resetNotes,
    onCreateNote,
    onReplyNote,
    onEditNote,
    onDeleteNote,
    onSearch,
    onSearchAll
  };
};

export default usePatientNotes;
