import React from 'react';
import { Button } from 'primereact/button';
import { commonInputs } from '../../../../../modules/config/commonInputsConfig';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { t } from '../../../../../service/localization/i18n';

const AddNewPolicy = () => {
  const { setState } = usePatientPoliciesContext();

  const onAddClick = () => {
    setState((prevState) => ({
      ...prevState,
      eClaimPolicy: {
        showEClaimPolicyForm: true,
        policyInfo: {
          [commonInputs.patient.name]: [prevState.patient],
          [commonInputs.isPrimary.name]: false
        }
      }
    }));
  };

  return <Button className="w-max" type="button" icon="pi pi-plus" label={t('Add_New_Policy')} style={{ height: '40px' }} onClick={onAddClick} />;
};

export default AddNewPolicy;
