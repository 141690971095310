import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Context from '../../../../Context';
import PrivateInvoicePreview from './PrivateInvoicePreview/PrivateInvoicePreview';

import { routes } from '../../../../routes/routes';
import { deleteClaim } from '../../actions/claims.action.creators';
import { onEditCommonActions, onReceivePayment } from '../../helpers/rowActions';
import { resetPrivateForm } from './claimDetailsHelpers/resetPrivateForm';

const PreviewPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { privateRecord, setStep, deleteInvoiceCallback } = useContext(Context);

  // scroll page to the top
  useEffect(() => {
    window[`scrollTo`]({ top: 0 }); // scroll page to the top
  }, []);

  const onClose = () => setStep(3);

  const receivePayment = () => {
    onReceivePayment({
      history,
      replaceHistory: true,
      data: {
        ...privateRecord,
        BillTo: Array.isArray(privateRecord.BillTo) ? privateRecord.BillTo[0] : privateRecord.BillTo
      }
    });
    resetPrivateForm();
  };

  const onEdit = () => {
    const patientGuid = Array.isArray(privateRecord?.Patients) ? privateRecord?.Patients[0].PatientGuid : privateRecord?.Patients.PatientGuid;
    const data = { ...privateRecord, PatientGuid: patientGuid };
    onEditCommonActions(data, routes.privateRecord.path, history, true);
    setStep(0);
  };

  const onDelete = () => {
    dispatch(deleteClaim({ invoiceGuid: privateRecord?.InvoiceGuid, callback: deleteInvoiceCallback }));
  };

  return (
    <PrivateInvoicePreview
      showEditButton
      data={privateRecord}
      onRecordPayment={receivePayment}
      onEditInvoice={onEdit}
      onDeleteInvoice={onDelete}
      onClose={onClose}
    />
  );
};

export default PreviewPanel;
