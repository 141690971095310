import React from 'react';
import { useFormContext } from 'react-hook-form';

import PatientPicker from '../EClaimPolicyForm/Inputs/PatientPicker';
import MemberID from '../EClaimPolicyForm/Inputs/MemberID';
import InsuredType from '../EClaimPolicyForm/Inputs/InsuredType';
import PolicyNumber from '../EClaimPolicyForm/Inputs/PolicyNumber';
import InsuredMember from '../EClaimPolicyForm/Inputs/InsuredMember';
import IsPrimary from '../EClaimPolicyForm/Inputs/IsPrimary';
import InsuranceCompany from '../EClaimPolicyForm/Inputs/InsuranceCompany';
import ToggleCatalogsButton from '../ActionButtons/ToggleCatalogsButton';

const InputsLayout = () => {
  const { isEdit } = useFormContext();

  return (
    <>
      {!isEdit && <ToggleCatalogsButton />}

      <div className="flex flex-column gap-4">
        <div className={isEdit ? 'flex justify-content-center' : ''}>
          <PatientPicker />
        </div>

        <div className={isEdit ? 'flex justify-content-center' : ''}>
          <IsPrimary />
        </div>

        <InsuranceCompany />
      </div>

      <div className="two_inputs_in_a_row">
        <PolicyNumber />
        <MemberID />
      </div>

      <InsuredType />
      <InsuredMember />
    </>
  );
};

export default InputsLayout;
