import moment from 'moment';

export const toMilitaryTime = (time) => {
  let militaryTime = time.replace(':', '')?.replaceAll('_', '');
  if (militaryTime.substring(0, 1) === '0') {
    militaryTime = militaryTime.substring(1, 4);
  }

  return militaryTime;
};

export const parseMilitaryTime = (time) => {
  // Check if the time includes a colon (:) or not
  const hasColon = time.includes(':');

  // Define the appropriate format for parsing based on the presence of colon
  const format = hasColon ? 'HH:mm' : 'Hmm';

  // Parse the time using Moment.js
  const parsedTime = moment(time, format);

  // Format the parsed time in the desired format
  const formattedTime = parsedTime.format('HH:mm');

  return formattedTime;
};

export const isTimeValid = (time) => {
  if (!time) return false;

  if (time === '24:00') {
    return true; // Treat '24:00' as a valid time (midnight of the following day)
  }

  return moment(time, 'HH:mm', true).isValid();
};

export const convertToMilitaryTimeFormat = (time) => {
  if (!time) return time;
  if (time.length > 0) {
    return moment(time, ['h:mm A']).format('HH:mm');
  } else return time;
};

export const isNightTime = (time, startTime, endTime) => {
  if (!time) return false;

  const currentTime = moment(time, 'HH:mm', true);
  const start = moment(startTime || '18:00', 'HH:mm');
  const end = moment(endTime || '08:00', 'HH:mm');

  if (!currentTime.isValid()) return false; // Invalid time format

  if (end.isBefore(start)) {
    return currentTime.isBefore(end) || currentTime.isSameOrAfter(start);
  }

  return currentTime.isBetween(start, end, '[]');
};

export const timeDiff = (startTime, endTime, measurement = 'minutes') => {
  // Time format should be 'HH:mm' (i.e. '10:00')
  // The supported measurements are years, months, weeks, days, hours, minutes, and seconds

  // Format the time inputs as HH:mm
  const format = 'HH:mm';
  const formattedStartTime = moment(startTime, format);
  const formattedEndTime = moment(endTime, format);

  // Check if the end time is before the start time
  if (formattedEndTime.isBefore(formattedStartTime)) {
    // Add 24 hours to the end time to account for crossing midnight
    formattedEndTime.add(1, 'day');
  }

  // Calculate the difference in minutes
  const diffMinutes = formattedEndTime.diff(formattedStartTime, measurement);

  return diffMinutes;
};
