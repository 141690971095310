import { store } from '../../../store';
import { icons } from '../../config/stylesConfig';
import { t } from '../../../service/localization/i18n';
import { routes } from '../../../routes/routes';
import { deleteRoleById, getRoles, updateRoleStatus } from '../actions/roles.action.creators';
import { setRoles } from '../actions/actions';

const dispatch = store.dispatch;

export const rowActionsMenuItems = (props) => {
  let commonItems = [
    {
      id: 'view',
      icon: icons.info,
      label: t('View'),
      command: () => viewRole(props.rowData, props.history)
    },
    {
      id: 'edit',
      icon: icons.edit,
      label: t('Edit'),
      command: () => editRole(props.rowData, props.history)
    },
    // {
    //   id: 'viewUsers',
    //   icon: 'pi pi-users',
    //   label: 'View users'
    //   //command:
    // },
    // {
    //   id: 'history',
    //   icon: 'pi pi-history',
    //   label: 'History'
    //   //command:
    // },
    // !props.isDefault && { separator: true },
    // {
    //   id: 'delete',
    //   icon: icons.delete,
    //   label: t('Delete'),
    //   command: props.onDelete,
    // }    
    {
      id: 'inactivate',
      icon: icons.delete,
      label: t('Disable'),
      command: () => updateRole(props.rowData, 0)
    },    
    {
      id: 'activate',
      icon: icons.delete,
      label: t('Enable'),
      command: () => updateRole(props.rowData, 1)
    }
  ];

  if (props.isDefault) {
    return commonItems.filter(item => item.id !== 'inactivate' && item.id !== 'activate' && item.id !== 'edit')
  }
  if (props.rowData.Status == 0) {
    return commonItems.filter(item => item.id !== 'inactivate')
  }
  if (props.rowData.Status == 1) {
    return commonItems.filter(item => item.id !== 'activate')
  }

  return commonItems;
};

export const viewRole = (roleDetails, history) => {
  history.push(`${routes.roleDetails.path}/${roleDetails.RoleId}`);
};

export const editRole = (roleDetails, history) => {
  history.push(`${routes.editRole.path}/${roleDetails.RoleId}`);
};

export const deleteRole = async (roleDetails) => {
  await deleteRoleById(roleDetails.RoleId);
  const result = await getRoles();
  if (result) {
    dispatch(setRoles(result));
  }
};
export const updateRole = async (roleDetails, status) => {
  await updateRoleStatus(roleDetails.RoleId, roleDetails.RoleId, status);
  const result = await getRoles();
  if (result) {
    dispatch(setRoles(result));
  }
};
