export const integersOnly = /^[0-9]/;
export const integersOnlyCheckForString = /^[0-9]+$/;
export const lettersOnly = /[a-z]/gi;
export const integersAndLettersOnly = /^[a-zA-Z0-9\s]+$/;
export const everyFirstLetterOfString = /\b(\w)/g;
export const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const deleteRoundBracketsWithData = /\s*\(.*?\)$/gm;
export const validateCanadianZip = /^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i;
export const stringSeparators = /[\s,|/-]+/; // str.split for , | / - space
export const stripHTMLTags = /<[^>]*>?/gm;
export const removeAllSpecialCharacters = /[^a-zA-Z0-9 ]/g;
export const asciiRegex = /[^\x00-\x7F]/g;
export const practitionerNumberValidation = /^[a-zA-Z0-9][0-9]{4}$/;
export const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^*_()])[A-Za-z\d!@#$%^*_()]{8,50}$/;
