import { store } from '../../../store';
import { decodeText } from '../../utils/decodeText';
import { getFormTypeValue } from '../components/WSBCReport/helpers/formTypeActions';
import { inputs } from '../components/WSBCReport/helpers/inputs';

export const formatWSBCClaimReport = (report) => {
  const user = store.getState().user.details;
  const speciality = user.Speciality || 0;

  return {
    ...report,
    [inputs.icd9Text.name]: decodeText(report[inputs.icd9Text.name]),
    [inputs.employer.name]: decodeText(report[inputs.employer.name]),
    [inputs.address.name]: decodeText(report[inputs.address.name]),
    [inputs.city.name]: decodeText(report[inputs.city.name]),
    [inputs.priorProblems.name]: decodeText(report[inputs.priorProblems.name]),
    [inputs.firstTreatment.name]: decodeText(report[inputs.firstTreatment.name]),
    [inputs.formType.name]: getFormTypeValue(report[inputs.formType.name], speciality)
  };
};

export const formatWCBClaims = (responseData) => {
  const formatWCBClaims = responseData?.WCB?.map((i) => {
    if (i.Reports?.length) {
      return {
        ...i,
        Reports: i.Reports.map((report) => formatWSBCClaimReport(report))
      };
    }

    return i;
  });

  const formattedResponse = { ...responseData, WCB: formatWCBClaims };

  return formattedResponse;
};
