import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { CLAIM_MANAGER_CONTACT_EMAIL, CONTACT_PHONE_7 } from '../../../../../../config';
import { setActiveTab } from '../../../../../patients/actions/patients.action.creators';
import { getEClaimProgress } from '../../../../actions/claims.action.creators';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { onViewEClaim, openPatientProfile } from '../../../../helpers/rowActions';
import { steps } from '../../config/steps';
import { inputs } from '../../helpers/inputs';
import { sumBy } from 'lodash';
import { t } from '../../../../../../service/localization/i18n';

const SubmitProcessing = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { watch, setLocalState } = useFormContext();
  const patients = watch(inputs.patient.name);
  const patientGuid = patients?.[0]?.PatientGuid;
  const services = watch(inputs.services.name);
  const claimGuid = watch(inputs.claimGuid.name);
  const claimNumber = watch(inputs.claimNumber.name);
  const primaryPolicy = watch(inputs.primaryPolicy.name);
  const totalAmount = currencyFormat(sumBy(services, 'amount'));
  const patientName = patientFullNameWithAge(patients?.[0]);
  const claimNumberText = claimNumber || '';

  const [progress, setProgress] = useState({ value: 0, label: 'Waiting in queue' });

  useEffect(() => {
    if (claimGuid) {
      const fetchData = async () => {
        try {
          const response = await getEClaimProgress(claimGuid);

          // Update the progress state
          setProgress(response);

          // If value is 100, clear the interval to stop further requests
          if (Number(response.value) === 100) {
            // Show submit preview step
            clearInterval(interval);
            setTimeout(() => onNext(), 1500);
          }
        } catch (error) {
          console.error('Error fetching progress:', error);
        }
      };

      // Make an initial call and set up the interval
      fetchData();
      const interval = setInterval(fetchData, 1000);

      // Cleanup on component unmount
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimGuid]);

  const onClose = () => {
    // Open patient profile in the "Money" tab
    dispatch(setActiveTab(1));
    openPatientProfile(patientGuid, history, true);
  };

  const onNext = () => {
    // Show submit preview step
    setLocalState((prevState) => ({ ...prevState, step: steps.submitPreview }));
  };

  const onView = () => {
    onViewEClaim({ invoiceGuid: claimGuid, history, replace: true });
  };

  return (
    <Card className="w-full container">
      <div className="flex flex-column gap-7">
        <div className="flex justify-content-center w-full">
          <div className="w-full max-w-30rem">
            <h5 className="text-center">{progress.label}</h5>
            <ProgressBar value={progress.value} style={{ height: '15px' }} />
          </div>
        </div>

        <span className="text-lg line-height-3">
          {`Your claim(s) ${claimNumberText} to ${primaryPolicy?.InsurerNameEn} for ${patientName} for the amount of ${totalAmount} is being processed. Please wait. If it takes too long, you can close this screen and check the claim's status at a later time by visiting the patient's profile. For support, call `}
          <a href={`tel:${CONTACT_PHONE_7}`}>{CONTACT_PHONE_7}</a>
          {` or email `}
          <a href={`mailto:${CLAIM_MANAGER_CONTACT_EMAIL}`}>{CLAIM_MANAGER_CONTACT_EMAIL}</a>
        </span>

        <div className="flex justify-content-center gap-3">
          <Button className="w-full max-w-7rem" label={progress === 100 ? t('Next') : t('Close')} onClick={progress === 100 ? onNext : onClose} />

          <Button className="p-button-outlined w-full max-w-7rem" label={t('View_claim')} disabled={!claimGuid} onClick={onView} />
        </div>
      </div>
    </Card>
  );
};

export default SubmitProcessing;
