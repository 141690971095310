import { t } from '../../../../../service/localization/i18n';
import { aoiCodeMaxEntry, feecodesMaxEntry, icd9CodeMaxEntry, noiCodeMaxEntry } from '../../../../config/defaultValuesConfig';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const inputs = {
  dcnguid: {
    name: 'DCNGuid'
  },

  claimGuid: {
    name: 'ClaimGuid'
  },

  invoiceGuid: {
    name: 'InvoiceGuid'
  },

  speciality: commonInputs.speciality,

  invoiceStatus: {
    name: 'Status' // Status
  },

  status: {
    name: 'Status'
  },

  statusText: {
    name: 'StatusText'
  },

  mspCodes: {
    name: 'MSPCodes'
  },

  dateCreated: {
    name: 'DateCreated'
  },

  dateUpdated: {
    name: 'DateUpdated'
  },

  sentDate: {
    name: 'SDate'
  },

  patientGuid: {
    name: 'PatientGuid',
    label: t('Patient.1')
  },

  patientList: {
    name: 'PatientList'
  },

  patientClaims: {
    name: 'PatientClaims'
  },

  recordNumber: {
    name: 'RecordNo'
  },

  sequenceNumber: {
    name: 'SequenceNum'
  },

  sequenceNum: {
    name: 'SequenceNum'
  },

  feePaid: {
    name: 'FeePaid'
  },

  practitionerNumber: {
    name: 'PractitionerNumber'
  },

  sendNow: {
    name: 'sendNow'
  },

  ignoreValidation: {
    name: 'ignoreValidation'
  },

  recentCodes: {
    name: 'RecentCodes'
  },

  received: {
    name: 'Received'
  },

  // First Visit inputs //
  phnProvince: {
    name: commonInputs.phnProvince.name
  },

  phn: {
    name: commonInputs.phn.name,
    label: commonInputs.phn.label
  },

  birthDay: {
    name: commonInputs.birthDay.name,
    label: commonInputs.birthDay.label,
    placeholder: commonInputs.birthDay.placeholder
  },

  lastName: {
    name: commonInputs.lastName.name,
    label: commonInputs.lastName.label
  },

  firstName: {
    name: commonInputs.firstName.name,
    label: commonInputs.firstName.label
  },

  midName: {
    name: commonInputs.midName.name,
    label: commonInputs.midName.label
  },

  nickname: {
    name: commonInputs.nickname.name,
    label: commonInputs.nickname.label
  },

  accessWord: {
    name: commonInputs.accessWord.name,
    label: commonInputs.accessWord.label
  },

  gender: {
    name: commonInputs.gender.name,
    label: commonInputs.gender.label
  },

  addressLine: {
    name: commonInputs.addressLine.name,
    label: commonInputs.addressLine.label
  },

  city: {
    name: commonInputs.city.name,
    label: commonInputs.city.label
  },

  province: {
    name: commonInputs.province.name,
    label: commonInputs.province.label
  },

  zip: {
    name: commonInputs.zip.name,
    label: commonInputs.zip.label
  },

  // Claim Info //
  practitioner: commonInputs.practitionerGuid,

  facility: {
    name: 'FacilityNum',
    label: t('Facility')
  },

  subFacility: {
    name: 'SubFacility',
    label: t('Sub_Facility')
  },

  payeeNumber: {
    name: 'PayeeNumber',
    label: t('Payee_number.2')
  },

  ruralCode: {
    name: 'RuralCode',
    label: t('Rural_code'),
    codeDescription: 'RuralName'
  },

  locationCode: {
    name: 'LocationCode',
    label: t('Location_code'),
    codeDescription: 'LocationName',
    codeType: 'locationCode'
  },

  patient: {
    name: 'PatientDetails',
    label: t('Patient.1')
  },

  payor: {
    name: 'InvoiceType',
    label: t('Payor')
  },

  serviceDate: {
    name: 'ServiceDate',
    label: t('Service_date'),
    placeholder: t('MM_DD_YYYY'),
    codeType: 'serviceDate'
  },

  bmi: {
    name: 'BMI',
    label: t('BMI'),
    codeType: 'bmi'
  },

  claimNumber: {
    name: 'ClaimNumber',
    label: t('Claim_Number'),
    placeholder: '12345'
  },

  injuryDate: {
    name: 'InjuryDay',
    label: t('Injury_Date.1'),
    placeholder: t('MM_DD_YYYY')
  },

  aoi: {
    name: commonInputs.aoi.name,
    label: commonInputs.aoi.label,
    tabLabel: commonInputs.aoi.tabLabel,
    codeDescription: commonInputs.aoi.descriptionName,
    codeType: commonInputs.aoi.codeType,
    maxEntries: aoiCodeMaxEntry
  },

  noi: {
    name: commonInputs.noi.name,
    label: commonInputs.noi.label,
    tabLabel: commonInputs.noi.tabLabel,
    codeDescription: commonInputs.noi.descriptionName,
    codeType: commonInputs.noi.codeType,
    maxEntries: noiCodeMaxEntry
  },

  anatomicCode: {
    name: 'AnatomicCode',
    label: t('Anatomic_Code.1')
  },

  feeCodes: {
    name: commonInputs.feeCodes.name,
    label: commonInputs.feeCodes.label,
    tabLabel: commonInputs.feeCodes.tabLabel,
    codeDescription: commonInputs.feeCodes.descriptionName,
    codeType: commonInputs.feeCodes.codeType,
    maxEntries: feecodesMaxEntry
  },

  reasonFor: {
    name: 'ReasonFor01080',
    label: t('Reason_for_01080')
  },

  icd9: {
    name: commonInputs.icd9.name,
    label: commonInputs.icd9.label,
    tabLabel: commonInputs.icd9.tabLabel,
    codeDescription: commonInputs.icd9.descriptionName,
    codeType: commonInputs.icd9.codeType,
    maxEntries: icd9CodeMaxEntry
  },

  startTime: {
    name: 'StartTime',
    label: t('Start_time'),
    secondLabel: t('Group_Start_Time')
  },

  endTime: {
    name: 'FinishTime',
    label: t('End_time'),
    secondLabel: t('Group_End_Time')
  },

  callTime: {
    name: 'CallTime',
    label: t('Call_time')
  },

  emergency: {
    name: 'Emergency'
  },

  travel: {
    name: 'Travel',
    label: t('Immediate travel')
  },

  ccfpp: {
    name: 'CCFPP',
    label: t('CCFPP')
  },

  refToBy: {
    name: 'RefToBy',
    label: t('Referral')
  },

  referral: {
    name: commonInputs.referral.name,
    label: commonInputs.referral.label,
    tabLabel: commonInputs.referral.tabLabel,
    codeDescription: commonInputs.referral.descriptionName,
    codeType: commonInputs.referral.codeType,
    maxEntries: commonInputs.referral.maxEntries
  },

  submission: {
    name: 'SubmissionCode',
    label: t('Submission_Code')
  },

  comment: {
    name: 'Comment',
    label: t('Comment')
  },

  saveAsDraft: {
    name: 'SaveAsDraft',
    label: t('Save_as_Draft')
  },

  // Inputs for edit invoice
  units: {
    name: 'Units',
    label: t('Units')
  },

  duration: {
    name: 'Duration'
  },

  percent: {
    name: 'Percent',
    label: t('Percent')
  },

  feeAmount: {
    name: 'FeeAmount',
    label: t('Fee_amount')
  },

  feeTotal: {
    name: 'FeeTotal',
    label: t('Fee_total')
  },

  groupRowId: {
    name: 'GroupRowId',
    label: 'RowId'
  },

  isDuplicated: {
    name: 'IsDuplicated'
  }
};
