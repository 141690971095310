import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import Referral from '../Inputs/Referral';
import RefToBy from '../Inputs/RefToBy';

import { inputs } from '../../../helpers/inputs';
import { showReferralInput } from '../../../helpers/inputsConditions';
import { isSupplementary } from '../../../../../../config/specialitiesConfig';

const ReferralSection = () => {
  const { control } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const refToBy = useWatch({ control, name: inputs.refToBy.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const hideReferral = isSupplementary(speciality, invoiceType);

  if (hideReferral) return null;

  return (
    <>
      <RefToBy />
      {showReferralInput(refToBy) && <Referral />}
    </>
  );
};

export default ReferralSection;
