import React from 'react';
import { useSelector } from 'react-redux';

const LockPrefsButton = ({ name, isLocked, onSavePrefs }) => {
  const { savingPreferences } = useSelector((state) => state.preferences);
  const icon = savingPreferences ? 'pi pi-spin pi-spinner' : isLocked ? 'pi pi-lock' : 'pi pi-lock-open';

  return (
    <i
      id={`${name}_lockIcon`}
      className={`pointer ${icon}`}
      onClick={!isLocked ? onSavePrefs : undefined}
      style={{ height: '14px', color: 'black' }}
    />
  );
};

export default LockPrefsButton;
