import { t } from '../../../service/localization/i18n';

export const legoFilters = {
  invoices: {
    ServiceDate: { filter: 'ServiceDate', label: t('Service_date') },
    SubmissionCode: { filter: 'SubmissionCode', label: t('Submission_Code') },
    FullName: { filter: 'FullName', label: t('Patient.1') },
    InvoiceType: { filter: 'InvoiceType', label: t('Payer') },
    ICD9Code: { filter: 'ICD9Code', label: t('Dx') },
    FeeCode: { filter: 'FeeCode', label: t('Service') },
    FeeAmount: { filter: 'FeeAmount', label: t('Billed') },
    FeePaid: { filter: 'FeePaid', label: t('Paid') },
    FeeDue: { filter: 'FeeDue', label: t('Due') },
    StatusText: { filter: 'StatusText', label: t('Status') },
    RecordNo: { filter: 'RecordNo', label: t('Record Id') },
    SCCode: { filter: 'SCCode', label: t('Rural') },
    SubmissionDate: { filter: 'SubmissionDate', label: t('Sent') },
    RemittanceDate: { filter: 'RemittanceDate', label: t('Remittance') },
    RecordType: { filter: 'RecordType', label: '' },
    PatientGuid: { filter: 'PatientGuid', label: '' },
    DoctorGuid: { filter: 'DoctorGuid', label: '' },
    Comment: { filter: 'Comment', label: t('Comment') }
  },

  patients: {
    Name: { filter: 'Name', field: 'LastName', label: t('Name') },
    PHN: { filter: 'PHN', field: 'PHN', label: t('PHN') },
    DOBText: { filter: 'DOBText', field: 'BirthDay', label: t('Day_of_birthday.1') }
  }
};

export const chipLables = Object.values(legoFilters)
  .map((i) => Object.values(i))
  .flat(1);
