import React from 'react';
import { useFormContext } from 'react-hook-form';
import EClaimPolicyForm from '../../modules/EClaimPolicyForm/EClaimPolicyForm';
import Steps from './Steps/Steps';

const CreateEClaimView = () => {
  const { localState } = useFormContext();
  const showEClaimPolicyForm = localState.eClaimPolicy.showEClaimPolicyForm;

  return <>{showEClaimPolicyForm ? <EClaimPolicyForm /> : <Steps />}</>;
};

export default CreateEClaimView;
