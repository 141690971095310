import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Zip as ZipCode } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const Zip = () => {
  const name = inputs.zip.name;
  const { errors, control } = useFormContext();

  const zipCodeInput = (field) => {
    return (
      <ZipCode
        ref={field.ref}
        input={inputs.zip}
        value={field.value}
        errorMessage={errors[name]?.message}
        onChange={(value) => field.onChange(value)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => zipCodeInput(field)} />;
};

export default Zip;
