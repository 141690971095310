import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { t } from '../../../../service/localization/i18n';
import { getAge } from '../../../utils/getAge';
import classes from './DuplicatePatientsDialog.module.scss';
import cx from 'classnames';
import { formatPHN } from '../../../utils/formatPHN';
import { SplitButton } from 'primereact/splitbutton';
import { addNewOverlayItemsList } from '../../../claims/helpers/addNewOverlayItemsList';
import { addNewTeleplan } from '../../../claims/helpers/addNewInvoice';

const DuplicatePatientsDialog = ({
  visible,
  duplicatedPatientsList,
  onHide,
  onSubmit,
  handleExistingProfile,
  addNewPatientToNewClaim = false,
  useDefaultStatusText = false
}) => {
  const history = useHistory();
  const { isCreatingPatient, isSavingPatientDetails } = useSelector((state) => state.patients);
  const showSplitButton = !addNewPatientToNewClaim && duplicatedPatientsList?.length === 1;

  return (
    <Dialog
      header={t('Duplicate_records_alert')}
      visible={visible}
      breakpoints={{ '1366px': '70vw', '960px': '80vw', '768px': '90vw' }}
      style={{ width: '40vw' }}
      onHide={onHide}
    >
      <div className="flex flex-column">
        <div className="flex flex-column">{t('You_have_similar_records_in_the_system')}</div>

        {duplicatedPatientsList?.map((i, index) => {
          const address = i.AddressLine && i.City ? `, ${i.AddressLine} ${i.City} ${i.Province} ${i.Zip}` : '';
          const phn = i.PHN && i.PHN !== 'N/A' ? `, ${t('PHN')}: ${formatPHN(i.PHN, i.Province)}` : '';
          const statusText = () => {
            if (addNewPatientToNewClaim || useDefaultStatusText) return i.Status === -1 ? t('Restore_and_use') : t('Use');
            if (!addNewPatientToNewClaim) return i.Status === -1 ? t('Restore') : t('Open');
          };
          return (
            <div key={i.PatientGuid} className={cx('flex align-items-center', classes.duplicatePatientsDialogActionButtons)}>
              <div className={classes.statusTextWrap}>{`${index + 1}. ${statusText()}`}</div>
              <Button
                className="p-button-link"
                label={`${i.LastName}, ${i.FirstName} (${getAge(i.BirthDay)}${i.Sex})${phn} ${address}`}
                onClick={() => (!isSavingPatientDetails ? handleExistingProfile(i) : undefined)}
              />
              {i.Status === -1 && `(${t('Archived')})`}
            </div>
          );
        })}

        <div className={cx('flex flex-column md:flex-row w-full mt-4')} style={{ gap: '10px' }}>
          {showSplitButton && (
            <SplitButton
              className="p-button-rounded p-button-text mb-2 w-full"
              label={t('New_claim')}
              autoFocus
              model={addNewOverlayItemsList({ history, patient_details: duplicatedPatientsList[0], replaceRoute: true })}
              onClick={() => addNewTeleplan({ history, patient_details: duplicatedPatientsList[0], replaceRoute: true })}
            />
          )}

          <Button
            className={cx('p-button-outlined mb-2', classes.duplicatePatientsDialogActionButtons)}
            label={t('Ignore_similar_records')}
            loading={isCreatingPatient || isSavingPatientDetails}
            onClick={onSubmit}
          />

          <Button className={cx('p-button-outlined mb-2', classes.duplicatePatientsDialogActionButtons)} label={t('Close')} onClick={onHide} />
        </div>
      </div>
    </Dialog>
  );
};

export default DuplicatePatientsDialog;
