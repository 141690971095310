import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';

export const usePatient = () => {
  const { setValue, clearErrors, getValues, isDirty } = useFormContext();
  const setDirty = !isDirty ? { shouldDirty: true } : {};

  const onPatientChange = (patients = []) => {
    const currentPatient = patients?.[0];
    const insuredType = getValues(inputs.insuredType.name);

    setValue(inputs.patient.name, patients, setDirty);
    clearErrors(inputs.patient.name); // Clear error message

    // If Insured Member value is "Patient" then autofill First, Last and Mid name inputs by selected patient
    if (insuredType === 'SELF' && currentPatient) updateMemberData(currentPatient);
    // Reset member data if patient is not selected
    if (!currentPatient) updateMemberData();
  };

  const updateMemberData = (patient) => {
    setValue(inputs.memberFirstName.name, patient?.FirstName || '');
    setValue(inputs.memberLastName.name, patient?.LastName || '');
    setValue(inputs.memberMidName.name, patient?.MidName || '');
    clearErrors([inputs.firstName.name, inputs.lastName.name]);
  };

  return { onPatientChange, updateMemberData };
};
