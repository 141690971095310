import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { inputs } from '../helpers/inputs';
import { duplicatePatientCheck } from '../../../../patients/actions/patients.action.creators';
import moment from 'moment';

export const useLastName = () => {
  const dispatch = useDispatch();
  const { checkingPatientDuplicates } = useSelector((state) => state.patients);
  const { watch, setLocalState } = useFormContext();
  const dcnguid = watch(inputs.dcnguid.name);
  const birthDay = watch(inputs.birthDay.name);
  const lastName = watch(inputs.lastName.name);
  const patientGuid = watch(inputs.patientGuid.name);

  const formatDate = (date) => moment(date).format('MM/DD/YYYY');

  const getDOB = (dob, field) => {
    const isBirthDayField = field.name === inputs.birthDay.name;
    const date = isBirthDayField ? dob : birthDay;
    return date ? formatDate(date) : null;
  };

  const lastNameOnBlur = (value, field) => {
    if (!checkingPatientDuplicates) {
      const patientDetails = {
        DCNGuid: dcnguid,
        DOB: getDOB(value, field),
        LastName: field.name === inputs.lastName.name ? value : lastName
      };

      if (patientDetails.DOB && patientDetails.LastName && (patientGuid === dcnguid || patientGuid === '')) {
        dispatch(
          duplicatePatientCheck(patientDetails, (responseData) => {
            if (responseData?.length) duplicatePatientCheckCallback(responseData);
          })
        );
      }
    }
  };

  const duplicatePatientCheckCallback = (responseData) => {
    const formattedResponse = responseData?.map((i) => ({ ...i, PHN: i.PHN === 'N/A' ? '' : i.PHN }));
    setLocalState((prevState) => ({
      ...prevState,
      duplicatedPatients: formattedResponse,
      duplicatePatientDialog: true
    }));
  };

  return { lastNameOnBlur, duplicatePatientCheckCallback };
};
