import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import BillingHistoryButton from '../../../../../modules/ActionButtons/BillingHistoryButton';
import { patientFullNameWithAge, patientLastNameWithInitials } from '../../../../../../../../utils/patientFullName';
import { inputs } from '../../../../../helpers/inputs';
import { formatPHN } from '../../../../../../../../utils/formatPHN';
import { t } from '../../../../../../../../../service/localization/i18n';

const PatientLayout = ({ rowOptions }) => {
  const patient = rowOptions.rowData[inputs.patient.name][0];
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const phn = patient?.PHN ? formatPHN(patient?.PHN, patient?.PHNProvince) : t('No_PHN');
  const patientName = `${patientFullNameWithAge(patient)} ${phn}`;

  return (
    <div className="flex justify-content-between align-items-center w-full">
      <Tooltip target={`.groupPickerPatientTooltip_${rowId}`} position="top" />
      <div
        className={`flex align-items-center font-normal groupPickerPatientTooltip_${rowId}`}
        style={{ minHeight: '60px' }}
        data-pr-tooltip={patientName}
      >
        {patientLastNameWithInitials(patient, true)}
      </div>

      <BillingHistoryButton initPatient={patient} />
    </div>
  );
};

export default PatientLayout;
