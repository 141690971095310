import React from 'react';
import { useFormContext } from 'react-hook-form';

import { DatePickerButton } from '../../../../../../../components/Inputs';

import { useServiceDate } from '../../../hooks/useServiceDate';
import { inputs } from '../../../helpers/inputs';
import { some } from 'lodash';
import moment from 'moment';

const ServiceDateButton = () => {
  const { localState, watch } = useFormContext();
  const { onChangeServiceDate, onChangeMultipleServiceDate } = useServiceDate();
  const serviceDate = watch(inputs.serviceDate.name);
  const status = watch(inputs.status.name);
  const showMultipleDates = localState.isMultipleDates;

  const handleChange = (value) => {
    if (showMultipleDates) {
      // Check if new date is an array of dates
      const isArray = Array.isArray(value);

      // Clear service date if no value
      if ((isArray && !value.length) || !value) return onChangeMultipleServiceDate([]);

      // Function to check if a single date is in the array
      const isDateInArray = (date) => !some(serviceDate, (i) => moment(i).isSame(moment(date), 'day'));

      // Function to check if any date in an array is in the array of dates
      const areDatesInArray = (dates) => some(dates, (date) => isDateInArray(date));

      const isNewDate = isArray ? areDatesInArray(value) : isDateInArray(value);

      if (isNewDate) {
        if (isArray) return onChangeMultipleServiceDate(value);
        return onChangeMultipleServiceDate([...serviceDate, value]);
      } else {
        return onChangeMultipleServiceDate(value);
      }
    } else {
      onChangeServiceDate(value);
    }
  };

  return <DatePickerButton value={serviceDate} multipleDates={showMultipleDates} status={status} onChange={handleChange} />;
};

export default ServiceDateButton;
