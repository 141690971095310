import { t } from '../../../service/localization/i18n';

export const recursion = (params) => {
  const { arr } = params;
  if (arr && Array.isArray(arr)) {
    arr.forEach((i) => {
      if (i.end) {
        if (i.callback) {
          i.command = () => i.callback();
          return;
        }
        i.command = () => handleMenuSelect(i, params);
      } else {
        i.command = () => handleMenuClick(i, params);
        i.items = i.categories;
        recursion({ ...params, arr: i.categories });
      }
    });
  }
};

const handleMenuClick = (menuItem, params) => {
  const { setCatalogsState, slideMenuRef } = params;
  removeFirstHiglightedElementInSlideMenu(slideMenuRef);
  setCatalogsState((prevState) => ({
    ...prevState,
    tableData: { codeList: [] },
    emptyMessage: t('Please_select_a_category_on_the_left'),
    drillDown: [...prevState.drillDown, ...[menuItem]] //memorize items when user drills down throught the slide menu
  }));

  scrollInView(slideMenuRef);
};

const handleMenuSelect = async (menuItem, params) => {
  const { getCatalogCodesList, catalogsState, setCatalogsState, slideMenuRef, query, page } = params;

  setCatalogsState((prevState) => ({ ...prevState, mobileContent: 'table' }));
  removeFirstHiglightedElementInSlideMenu(slideMenuRef);

  //set menuItem.value for using global search
  setCatalogsState((prevState) => ({
    ...prevState,
    tableData: { codeList: [] },
    categoryValue: menuItem.value,
    isLoadingTableData: true,
    emptyMessage: `${t('Loading')}...`
  }));

  const response = await getCatalogCodesList({ catalogsState, menuItem, query, page });

  if (response) {
    setCatalogsState((prevState) => ({
      ...prevState,
      tableData: response,
      isLoadingTableData: false,
      emptyMessage: t('No_records_found')
    }));
  } else {
    setCatalogsState((prevState) => ({
      ...prevState,
      isLoadingTableData: false,
      emptyMessage: t('No_records_found')
    }));
  }
};

const removeFirstHiglightedElementInSlideMenu = (slideMenuRef) => {
  slideMenuRef?.current?.slideMenuContent?.children[0]?.children[0]?.classList?.remove('p-menuitem-highlight'); //delete active class for menu item
};

const scrollInView = (slideMenuRef) => {
  slideMenuRef?.current?.menuRef?.current?.children[0].children[0].scrollTo(0, 0);
};
