import { useFormContext } from 'react-hook-form';
import { useConditions } from './useConditions';
import { emptyLocation } from '../helpers/defaultValues';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { getPresetPrefs } from '../../../helpers/getDefaultPrefs';

export const useLocation = () => {
  const { localState } = useFormContext();
  const { isPBC, isTEC } = useConditions();

  const getLocationDropdownOptions = ({ pbcOptions, tecOptions, isPbc, isTec } = {}) => {
    const pbc = pbcOptions || localState.locationsList.pbc;
    const tec = tecOptions || localState.locationsList.tec;
    if (isPbc || isPBC) return [emptyLocation, ...pbc];
    if (isTec || isTEC) return [emptyLocation, ...tec];
    return [emptyLocation];
  };

  const onLocationChange = (e, field) => {
    field.onChange(e.value);
  };

  const getLocation = ({ pbcOptions, tecOptions, isPbc, isTec, practitionerPrefs } = {}) => {
    // Get locations list
    const locationOptions = getLocationDropdownOptions({ pbcOptions, tecOptions, isPbc, isTec });
    const policyLocation = locationOptions.find((i) => i.RowGuid) || emptyLocation;

    const prefs = practitionerPrefs ? practitionerPrefs.content : localState?.practitionerPrefs?.content;
    // Get location from prefs
    const prefsLocation = getPresetPrefs(prefs, prefsCodes.eClaimLocation);
    // Define current location
    const currentLocation = prefsLocation ? locationOptions.find((i) => i.RowGuid?.toLowerCase() === prefsLocation.toLowerCase()) : policyLocation;
    return currentLocation;
  };

  return { onLocationChange, getLocationDropdownOptions, getLocation };
};
