import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreateNote from '../../../CreateNote/CreateNote';
import { useNotesContext } from '../../hooks/useNotesContext';
import { initialState } from '../../helpers/initialState';
import { t } from '../../../../../../service/localization/i18n';

const AddNoteDialog = () => {
  const { notesState, setNotesState, onCreateNote, onEditNote, entityGuid } = useNotesContext();
  const { showDialog, note } = notesState.noteDialog;
  const isNew = !note;
  const header = isNew ? t('Add_New_Note') : t('Edit_Note');

  const clearSearchValue = () => {
    setNotesState((prevState) => ({ ...prevState, searchQuery: '' }));
  };

  const onSubmit = async (note) => {
    if (isNew) {
      await onCreateNote(note, { searchQuery: notesState.searchQuery });
      notesState?.searchQuery && clearSearchValue();
    }

    if (!isNew) {
      await onEditNote(note, { searchQuery: notesState.searchQuery });
      notesState?.searchQuery && clearSearchValue();
    }
  };

  const onHide = () => {
    setNotesState((prevState) => ({ ...prevState, noteDialog: initialState.noteDialog }));
  };

  return (
    <Dialog
      header={header}
      visible={showDialog}
      blockScroll
      breakpoints={{ '1600px': '60vw', '1366px': '70vw', '960px': '95vw', '768px': '95vw' }}
      style={{ width: '50vw' }}
      onHide={onHide}
    >
      <CreateNote note={note} entityGuid={entityGuid} onSubmit={onSubmit} onCancel={onHide} />
    </Dialog>
  );
};

export default AddNoteDialog;
