import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import Context from '../../../../../Context';
import CodeCatalogs from './CodeCatalogs';
import MostRecent from './MostRecent';

const Catalogs = () => {
  const { isMobile } = useSelector((state) => state.core.window);
  const { privateRecord, setCatalogsTableData, focusFieldParams, setFocusFieldParams, catalogIndex, setCatalogIndex, setCategoryValue } =
    useContext(Context);

  const [trail, setTrail] = useState([]);
  const [sideBarCurrentHeight, setSideBarCurrentHeight] = useState(false);
  const sideBarRef = useRef(null);

  // set sidebar current height. Needed to calculate the height of the dataScrolled  for mobile view
  useEffect(() => {
    setSideBarCurrentHeight(sideBarRef?.current?.sidebarRef?.current?.clientHeight);
  }, [sideBarRef?.current?.sidebarRef?.current?.clientHeight]);

  // reset catalogs state when user change catalog
  useEffect(() => {
    resetCatalogsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusFieldParams?.codeType]);

  const resetCatalogsState = () => {
    isMobile && setCategoryValue(focusFieldParams.searchAllType); // reset categoryValue (this value using for search input request)
    setCatalogsTableData({ serviceList: [] }); // reset data table
    trail?.length && setTrail([]); // reset breadcrumb
  };

  return (
    <>
      {catalogIndex === 0 && (
        <MostRecent
          privateRecord={privateRecord}
          catalogIndex={catalogIndex}
          setCatalogIndex={setCatalogIndex}
          setFocusFieldParams={setFocusFieldParams}
        />
      )}

      {catalogIndex !== 0 && <CodeCatalogs trail={trail} setTrail={setTrail} sideBarCurrentHeight={sideBarCurrentHeight} />}
    </>
  );
};

export default Catalogs;
