import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { useStorage } from '../../../../../../../../hooks/useStorage';
import { localStorageKeys } from '../../../../../../../../config/localStorageKeysConfig';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const ApplyToAllReferral = () => {
  const key = localStorageKeys.doNotShowApplyToAllReferralDialog;
  const { localState, setLocalState } = useFormContext();
  const { setLatestReferral, setSameReferral } = useGroupPickerInputs();
  const [doNotShowpplyAllReferralDialog] = useStorage(key);
  const showWarn = !doNotShowpplyAllReferralDialog && localState.groupRecords.some((i) => i[inputs.referral.codeType]?.length);

  const handleOff = () => {
    setLocalState((prevState) => ({ ...prevState, latestReferral: false, applyToAllReferral: false }));
  };

  const handleLatestReferral = () => {
    if (showWarn) {
      return setLocalState((prevState) => ({
        ...prevState,
        applyToAllReferralDialog: { showDialog: true, option: 'latestReferral' }
      }));
    }

    setLatestReferral();
  };

  const handleSameReferral = () => {
    if (showWarn) {
      return setLocalState((prevState) => ({
        ...prevState,
        applyToAllReferralDialog: { showDialog: true, option: 'sameReferral' }
      }));
    }

    setSameReferral();
  };

  return (
    <div className="p-buttonset flex w-full">
      <Button
        className={cx('applyToAllButton', !localState.latestReferral && !localState.applyToAllReferral ? '' : 'p-button-outlined')}
        type="button"
        label={t('Off')}
        onClick={handleOff}
      />

      <Tooltip target={`.latestReferral_tooltip`} showDelay={500}>
        <div style={{ maxWidth: '250px' }}>{t('Latest_referral_tooltip')}</div>
      </Tooltip>
      <Button
        className={cx('latestReferral_tooltip applyToAllButton', localState.latestReferral ? '' : 'p-button-outlined')}
        data-pr-position="top"
        type="button"
        label={t('Latest_Referral')}
        onClick={handleLatestReferral}
      />

      <Tooltip target={`.sameReferral_tooltip`} showDelay={500}>
        <div style={{ maxWidth: '250px' }}>{t('Apply_to_all_referral_tooltip')}</div>
      </Tooltip>
      <Button
        className={cx('sameReferral_tooltip applyToAllButton', localState.applyToAllReferral ? '' : 'p-button-outlined')}
        data-pr-position="top"
        type="button"
        label={t('Same_Referral')}
        onClick={handleSameReferral}
      />
    </div>
  );
};

export default ApplyToAllReferral;
