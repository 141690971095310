export const dataTableFieldFilters = (docList, statuses) => {
  return [
    { id: 'ServiceDate', field: 'ServiceDate', value: '', fieldType: 'date' },
    { id: 'RecordNo', field: 'RecordNo', value: '' },
    { id: 'TreatedBy', field: 'TreatedBy', value: '', filterOptions: docList, fieldType: 'dropdown' },
    { id: 'FullName', field: 'FullName', value: '', fieldType: 'string' },
    { id: 'InvoiceType', field: 'InvoiceType', value: '-2', filterOptions: statuses.invoiceTypes, fieldType: 'dropdown' },
    { id: 'ICD9Code', field: 'ICD9Code', value: '', fieldType: 'code' },
    { id: 'FeeCode', field: 'FeeCode', value: '', fieldType: 'code' },
    { id: 'FeeAmount', field: 'FeeAmount', value: '', fieldType: 'money' },
    { id: 'FeePaid', field: 'FeePaid', value: '', fieldType: 'money' },
    { id: 'FeeDue', field: 'FeeDue', value: '', fieldType: 'money' },
    { id: 'StatusText', field: 'StatusText', value: '-2', filterOptions: statuses.shortStatuses, fieldType: 'dropdown' }
  ];
};
