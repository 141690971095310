import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

const Insurers = () => {
  const history = useHistory();

  return (
    <div className="flex justify-content-start align-items-center flex-column pt-6" style={{ width: '100%', height: '90vh' }}>
      <h1>Insurers</h1>
      <h2>Coming soon...</h2>
      <Button className="mt-4" label="Go back" onClick={() => history.goBack()} />
    </div>
  );
};

export default Insurers;
