import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedDatePicker } from '../../../../../../../../components/Inputs';

// interface = {
//   input: {
//     name: string,
//     label: string
//     placeholder?: string
//   },
//  required?: boolean,
//  status?: number | string,
//  onChange?: (value: Date) => void,
// };

const DatePicker = ({ input, required = false, optionalRequired = false, status, onChange }) => {
  const name = input.name;
  const { errors, control } = useFormContext();
  const errorMessage = errors[name]?.message;

  const handleChange = (value, field) => {
    field.onChange(value);
    if (onChange) onChange(value);
  };

  const datePickerInput = (field) => {
    return (
      <WrappedDatePicker
        input={input}
        ref={field.ref}
        value={field.value}
        required={required}
        status={status}
        errorMessage={errorMessage}
        isInvalid={errorMessage && (required || optionalRequired)}
        onChange={(value) => handleChange(value, field)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => datePickerInput(field)} />;
};

export default DatePicker;
