import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import Facility from '../Inputs/Facility';
import SubFacility from '../Inputs/SubFacility';

import { showFacility, showSubFacility } from '../../../helpers/inputsConditions';
import { inputs } from '../../../helpers/inputs';

const FacilitySection = () => {
  const { control, localState } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });

  const preferences = localState.practitionerPrefs?.content;
  const showFacilityInput = showFacility(speciality, preferences);
  const showSubFacilityInput = showSubFacility(speciality);

  return (
    <>
      {showFacilityInput && <Facility />}
      {showSubFacilityInput && <SubFacility />}
    </>
  );
};

export default FacilitySection;
