import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { findPayorCode, payorDropdownOptions } from '../helpers/dropdownOptions';
import { inputs } from '../helpers/inputs';
import { useCatalogTabs } from './useCatalogTabs';
import { usePatient } from './usePatient';
import { defaultAnatomicCodeForWSBC, invoiceTypes } from '../helpers/defaultValues';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { usePreferences } from './usePreferences';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { convertInvoiceType } from '../helpers/convertInvoiceType';
import { useSubmission } from './useSubmission';
import { useValidation } from './useValidation';

export const usePayor = () => {
  const members = useSelector((state) => state.clinic.members);
  const { setValue, watch, isGroup } = useFormContext();
  const { onTabChange } = useCatalogTabs();
  const { updateSubmission } = useSubmission();
  const { clearCommentError } = useValidation();
  const { isLocked, onSavePrefs } = usePreferences();
  const { getPatientClaimsData, claimForReset } = usePatient();
  const invoiceType = watch(inputs.payor.name);
  const practitionerGuid = watch(inputs.practitioner.name);
  const defaultCode = prefsCodes.invoiceType;

  const options = () => {
    let items = payorDropdownOptions;
    const currentPractitioner = members?.find((i) => i.DoctorGuid?.toUpperCase() === practitionerGuid?.toUpperCase());
    const canBillWCB = currentPractitioner?.canBillWCB;
    const canBillICBC = currentPractitioner?.canBillICBC;

    const hidePayorCode = (code) => {
      items = items.filter((i) => i.value !== findPayorCode(code).label);
    };

    if (!canBillWCB && !canBillICBC) {
      hidePayorCode('W');
      hidePayorCode('I');
    } else if (isGroup) {
      hidePayorCode('W');
      hidePayorCode('I');
    } else {
      if (!canBillWCB) {
        hidePayorCode('W');
      }
      if (!canBillICBC) {
        hidePayorCode('I');
      }
    }

    return items;
  };

  const onChange = (e, field) => {
    const value = e.value;
    const patients = watch(inputs.patient.name);

    field.onChange(e.value);

    // Set catalogs tab to Quick Pick
    onTabChange({ index: 0 });

    if (value === invoiceTypes.wsbc) {
      // Update Anatomic Code
      setValue(inputs.anatomicCode.name, defaultAnatomicCodeForWSBC);
    }

    // VER-250 - rebill - change in date defaults submission code
    updateSubmission({ invoiceType: value });

    // Reset claim data
    if (value === invoiceTypes.msp || value === invoiceTypes.icbc) {
      setValue(inputs.patientClaims.name, {});
      watch(commonInputs.submitWithGenericPHN.name) === '1' && setValue(commonInputs.submitWithGenericPHN.name, '0');
      Object.entries(claimForReset).forEach(([inputName, inputValue]) => setValue(inputName, inputValue));
    }

    // Get WSBC/ICBC claims per patient
    if ((value === invoiceTypes.wsbc || value === invoiceTypes.icbc) && patients[0]?.PatientGuid) {
      getPatientClaimsData(patients[0]?.PatientGuid, value);
    }

    // Clear error message for commen input
    clearCommentError({ invoiceType: e.value });
  };

  const onSavePreferences = () => {
    const preferences = [{ value: convertInvoiceType(invoiceType), label: defaultCode }];

    onSavePrefs(preferences);
  };

  const isLockedPrefs = isLocked(convertInvoiceType(invoiceType), defaultCode);

  return {
    onChange,
    onSavePreferences,
    options: options(),
    isLocked: isLockedPrefs
  };
};
