import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { useStorage } from '../../../../../../../../hooks/useStorage';
import { localStorageKeys } from '../../../../../../../../config/localStorageKeysConfig';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const ApplyToAllDx = () => {
  const key = localStorageKeys.doNotShowApplyToAllDxDialog;
  const { localState, setLocalState } = useFormContext();
  const { setLatestDx, setSameDx } = useGroupPickerInputs();
  const [doNotShowpplyAllDxDialog] = useStorage(key);
  const showWarn = !doNotShowpplyAllDxDialog && localState.groupRecords.some((i) => i[inputs.icd9.codeType]?.length);

  const handleOff = () => {
    setLocalState((prevState) => ({ ...prevState, latestDx: false, applyToAllDx: false }));
  };

  const handleLatestDx = () => {
    if (showWarn) {
      return setLocalState((prevState) => ({ ...prevState, applyToAllDxDialog: { showDialog: true, option: 'latestDx' } }));
    }

    setLatestDx();
  };

  const handleSametDx = () => {
    if (showWarn) {
      return setLocalState((prevState) => ({ ...prevState, applyToAllDxDialog: { showDialog: true, option: 'sameDx' } }));
    }

    setSameDx();
  };

  return (
    <div className="p-buttonset flex w-full">
      <Button
        className={cx('applyToAllButton', !localState.latestDx && !localState.applyToAllDx ? '' : 'p-button-outlined')}
        type="button"
        label={t('Off')}
        onClick={handleOff}
      />

      <Tooltip target={`.latestDx_tooltip`} showDelay={500}>
        <div style={{ maxWidth: '250px' }}>{t('Latest_dx_tooltip')}</div>
      </Tooltip>
      <Button
        className={cx('latestDx_tooltip applyToAllButton', localState.latestDx ? '' : 'p-button-outlined')}
        data-pr-position="top"
        type="button"
        label={t('Latest_Dx')}
        onClick={handleLatestDx}
      />

      <Tooltip target={`.sameDx_tooltip`} showDelay={500}>
        <div style={{ maxWidth: '250px' }}>{t('Apply_to_all_dx_tooltip')}</div>
      </Tooltip>
      <Button
        className={cx('sameDx_tooltip applyToAllButton', localState.applyToAllDx ? '' : 'p-button-outlined')}
        data-pr-position="top"
        type="button"
        label={t('Same_Dx')}
        onClick={handleSametDx}
      />
    </div>
  );
};

export default ApplyToAllDx;
