import React from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import InsuranceQuickPick from '../InsuranceQuickPick';

const InsuranceCompanyTabLayout = () => {
  return (
    <ScrollPanel className="h-full">
      <InsuranceQuickPick />
    </ScrollPanel>
  );
};

export default InsuranceCompanyTabLayout;
