import React, { useState, useEffect, useCallback } from 'react';
import PatientService from '../../../../service/PatientService';
import { AutoComplete } from 'primereact/autocomplete';
import { debounce } from 'lodash';
import { dropdownScrollHeight } from '../../../config/stylesConfig';

// const formatValue = (value) => {
//   let list = [];
//   if (value && value !== '-1') {
//     if (Array.isArray(value) === false) {
//       list.push(value);
//     }
//     else {
//       list = value;
//     }
//   }
//   return list;
// };

const PatientAutoComplete = ({ onChange, value, placeholder }) => {
  //COMMENTED CODE CAN BE USED IN FUTURE RELEASES

  const controlId = 'PatientFilter';
  // const [ref, setRef] = useState();
  // const [partial, setPartial] = useState('');
  // const [selected, setSelected] = useState(formatValue(value));
  const [selected, setSelected] = useState();
  const [patientList, setPatientList] = useState([]);

  // useEffect(() => {
  //   const preset = formatValue(value);
  //   setSelected(preset);
  // }, [value]);

  useEffect(() => {
    if (value) setSelected(value.value);
    if (value === null) setSelected();
  }, [value]);

  const suggestFetchedData = (event) => {
    if (event.query) {
      const req = {
        filter: event.query,
        pagesize: 20,
        page: 1
      };
      PatientService.fetchPatients(req).then((response) => setPatientList(response.patientList));
    }
  };

  const selectedTemplateCode = (item) => {
    if (item.label) {
      if (item.disabled) {
        return (
          <div style={{ color: '#ff0000' }}>
            <div>{item.label}</div>
          </div>
        );
      }
      return (
        <div className="p-clearfix">
          <div>{item.label}</div>
        </div>
      );
    } else {
      return (
        <div className="p-clearfix">
          <div>
            {item.LastName} {item.FirstName} ({item.DOB}) PHN: {item.PHN}
          </div>
        </div>
      );
    }
  };

  const smartOnChange = (e) => {
    setSelected(e.value);
    typeof e.value === 'string' && debouncedRequest(e);
  };

  const debouncedRequest = useCallback(
    debounce((e) => {
      if (onChange) {
        const event = {
          target: {
            id: controlId,
            PatientGuid: null,
            value: e.value
          }
        };
        onChange(event);
      }
    }, 750),
    []
  );

  const onSelect = (e) => {
    if (onChange) {
      const event = {
        target: {
          id: controlId,
          PatientGuid: e.value.PatientGuid,
          value: `${e.value.FirstName} ${e.value.LastName} ${e.value.DOB}`
        }
      };
      onChange(event);
    }
  };

  // const smartOnChange = (e) => {
  //   const items = e.value;
  //   let updated = Object.assign([], items);
  //   const lastIndex = items.length - 1;
  //   if (lastIndex > -1 && items[lastIndex].FirstName) {
  //     items[lastIndex].value = `${items[lastIndex].LastName} ${items[lastIndex].FirstName.substring(0, 1)}. ${items[lastIndex].DOB}`;
  //     // remove partial text added by Blur
  //     if (partial !== '' && items[lastIndex].LastName.indexOf(partial) === 0) {
  //       updated = items.filter((x) => x.value !== partial);
  //     }
  //   }
  //   setPartial('');
  //   setSelected(updated);
  //   if (onChange) {
  //     const event = { target: { id: controlId, value: updated } };
  //     onChange(event);
  //   }
  // };

  // const smartOnBlur = (e) => {
  //   const id = e.target.id !== '' ? e.target.id : null;
  //   if (ref) {
  //     const value = ref.inputEl && ref.inputEl.value ? ref.inputEl.value : e._value;
  //     if (value && value !== '') {
  //       const event = { target: { id: controlId, PatientGuid: id, value: value } };
  //       ref.inputEl.value = '';
  //       setPartial(value.toUpperCase());
  //       setSelected(event);
  //       if (onChange) {
  //         onChange(event);
  //       }
  //     }
  //     else {
  //       setPartial('');
  //     }
  //   }
  // };

  // const debounceOnBlur = _.debounce((e) => {
  //   smartOnBlur(e);
  // }, 500);

  return (
    <AutoComplete
      // ref={(ref) => setRef(ref)}
      className="block"
      id={controlId}
      value={selected}
      suggestions={patientList}
      scrollHeight={dropdownScrollHeight()}
      minLength={1}
      field="value"
      // multiple
      displayField="value"
      placeholder={placeholder}
      style={{ width: '100%' }}
      inputStyle={{ width: '100%' }}
      panelStyle={{ maxWidth: '200px' }}
      onChange={(e) => smartOnChange(e)}
      onSelect={(e) => onSelect(e)}
      // onBlur={(e) => debounceOnBlur(e)}
      itemTemplate={selectedTemplateCode}
      completeMethod={(e) => suggestFetchedData(e)}
    />
  );
};

export { PatientAutoComplete };
