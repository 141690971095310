import moment from 'moment';
import cashFlowActions from '../actions/cashFlow.action.types';

// const claimsFilters = {
//   FullName: '',
//   InvoiceType: '',
//   ICD9Code: '',
//   FeeAmount: null,
//   FeeCode: '',
//   FeeDue: null,
//   FeePaid: null,
//   ServiceDate: '',
//   StatusText: '',
//   Period: '',
//   PeriodFrom: '',
//   PeriodTo: '',
//   PeriodText: '',
//   PayeeNumber: '',
//   SubmissionPeriodText: '',
//   SubmissionPeriod: '',
//   SubmissionPeriodFrom: '',
//   SubmissionPeriodTo: '',
//   RemittancePeriodText: '',
//   RemittancePeriod: '',
//   RemittancePeriodFrom: '',
//   RemittancePeriodTo: '',
//   showArchived: '', // "True" - show archiver, "" - do not show archived
//   freetext: '',
//   RecordNo: '',
//   RecordType: '',
//   DoctorGuid: '',
//   SCCode: '',
//   SubmissionCode: '',
//   Comment: ''
// };

const defaultQuery = {
  PeriodFrom: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'), // Show data from the last 30 days
  PeriodTo: moment().endOf('day').format('YYYY-MM-DD'),
  Period: '7',
  PeriodText: 'All',
  PayeeNumber: 'All',
  SubmissionPeriodText: 'All',
  RemittancePeriodText: 'All',
  InvoiceType: 'All'
};

export const cashFlowRequestParams = {
  patientGuid: undefined,
  type: 0, // 0 - custom filters, 1 - day , 2 - week, 3 - month, 4 - year
  page: 1,
  pageSize: 20,
  sortby: 'LastName_asc',
  query: defaultQuery
};

export const initialState = {
  requestParams: cashFlowRequestParams,
  data: null,
  cashFlowLoading: false,
  dialogs: {
    claimsDialog: { visible: false, requestParams: cashFlowRequestParams, billedCount: 0, periodFrom: '' }
  }
};

export default function cashFlowReducer(state = initialState, action) {
  const { results } = action;

  switch (action.type) {
    case cashFlowActions.SET_CASH_FLOW_DATA:
      return {
        ...state,
        data: results
      };

    case cashFlowActions.SET_QUERY:
      return {
        ...state,
        requestParams: { ...state.requestParams, query: results }
      };

    case cashFlowActions.RESET_CASH_FLOW_STATE:
      return initialState;

    case cashFlowActions.SET_CASH_FLOW_LOADING:
      return {
        ...state,
        cashFlowLoading: results
      };

    case cashFlowActions.SET_CLAIMS_DIALOG_VISIBILITY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          claimsDialog: results
        }
      };

    default:
      return state;
  }
}
