import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const UnitsEditor = ({ options }) => {
  const { onUnitsEditorChange, onUnitsEditorBlur, onUnitsEditorFocus } = useUpdateRecords();

  return (
    <div className="relative">
      <InputText
        id={elementIDs.unitsEditor}
        className="w-5rem"
        keyfilter="num"
        value={options.value}
        onChange={(e) => onUnitsEditorChange(e.target.value, options)}
        onBlur={(e) => onUnitsEditorBlur(e.target.value, options)}
        onFocus={(e) => onUnitsEditorFocus(e.target.value, options)}
      />
    </div>
  );
};

export default UnitsEditor;
