import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AddressLine } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const StreetAdress = () => {
  const name = inputs.addressLine.name;
  const { control } = useFormContext();

  const streetAddressInput = (field) => {
    return <AddressLine ref={field.ref} input={inputs.addressLine} value={field.value} onChange={(value) => field.onChange(value)} />;
  };

  return <Controller name={name} control={control} render={({ field }) => streetAddressInput(field)} />;
};

export default StreetAdress;
