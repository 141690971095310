import React from 'react';

import { Skeleton } from 'primereact/skeleton';
import AddressLayout from '../../../../../common/components/AddressLayout';

import { formatPhoneNumber } from '../../../../../utils/formatPhoneNumber';
import { patientFullName } from '../../../../../utils/patientFullName';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../claimDetailsHelpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const BillToSection = ({ data, isFetching }) => {
  const billTo = data[inputs().billTo.name] && Array.isArray(data[inputs().billTo.name]) ? data[inputs().billTo.name][0] : data[inputs().billTo.name];

  return (
    <div className="pb-2">
      <div className="bill-to">{t('Bill_To').toUpperCase()}</div>
      {isFetching ? (
        <>
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </>
      ) : (
        <div id={elementIDs.billToSection}>
          <div className="flex col-12 p-0 mb-3 font-bold">
            {patientFullName(billTo)}
            {/* {`${billTo?.LastName}, ${billTo?.FirstName}`} */}
          </div>

          <div className="addressLayoutWrap">
            <div className="addressWrap">
              <AddressLayout data={billTo} />

              {(billTo?.Phone || billTo?.Email) && (
                <div className="mt-3">
                  {billTo?.Phone && <div className="mb-1">{formatPhoneNumber(billTo?.Phone)}</div>}
                  <div className="mb-1">{billTo?.Email}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillToSection;
