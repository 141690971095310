import constants from './constants';

export const rolesInitialState = {
  roleDetails: {},
  roles: []
};

export default function roleReducer(state = rolesInitialState, action) {
  switch (action.type) {
    case constants.SET_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case constants.CLEAR_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case constants.SET_ROLE_DETAILS:
      return {
        ...state,
        roleDetails: action.payload
      };
    case constants.CLEAR_ROLE_DETAILS:
      return {
        ...state,
        roleDetails: action.payload
      };

    default:
      return state;
  }
}
