import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { formatPHN } from '../../../../../utils/formatPHN';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';
import { useUpdateFirstVisitInputs } from '../../hooks/useUpdateFirstVisitInputs';
import { editPatient } from '../../../../../patients/actions/patients.action.creators';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';

const DuplicatePatientDialog = () => {
  const dispatch = useDispatch();
  const { updateFirstVisitInputs } = useUpdateFirstVisitInputs();
  const { isSavingPatientDetails } = useSelector((state) => state.patients);
  const { watch, localState, setLocalState, setValue } = useFormContext();
  const phn = watch(inputs.phn.name);
  const duplicateList = localState.duplicatedPatients;

  const onHideDuplicatePatientsDialog = () => {
    setLocalState((prevState) => ({
      ...prevState,
      duplicatePatientDialog: false,
      duplicatedPatients: []
    }));
  };

  const onIgnoreSimilarRecords = () => {
    onHideDuplicatePatientsDialog();
  };

  const handleExistingProfile = (patient) => {
    // if patient is archived
    if (patient.Status === -1) {
      dispatch(editPatient({ ...patient, Status: 0 }, (responseData) => onPatientSelect(responseData)));
    } else {
      // if patient is NOT archived
      onPatientSelect(patient);
    }
  };

  const onPatientSelect = (patient) => {
    // CMO-1706 - use case 2, 5 and 6
    if (
      (!phn && patient.PHN) ||
      (phn && patient.PHN) ||
      // use case 6
      (duplicateList?.length > 1 && duplicateList?.some((i) => i.PHN) && duplicateList?.some((i) => !i.PHN))
    ) {
      onHideDuplicatePatientsDialog();
      updateFirstVisitInputs(patient);
    }

    // CMO-1706 - use case 3 and 4
    if ((phn && !patient.PHN) || (!phn && !patient.PHN)) {
      onHideDuplicatePatientsDialog();
      setValue(inputs.patientGuid.name, patient?.PatientGuid || '');
      setValue(inputs.dcnguid.name, patient?.DCNGuid || '');
    }
  };

  return (
    <Dialog
      id={elementIDs.duplicateRecordsAlert}
      header={t('Duplicate_records_alert')}
      visible={localState.duplicatePatientDialog}
      breakpoints={{ '1366px': '60vw', '960px': '80vw', '768px': '90vw' }}
      style={{ width: '40vw' }}
      onHide={onHideDuplicatePatientsDialog}
    >
      <div className="flex flex-column">
        <div className="mb-4 font-bold">{t('You_have_similar_records_in_the_system')}</div>

        {duplicateList?.map((i, index) => {
          const phnText = i.PHN ? `, ${t('PHN')}: ${formatPHN(i.PHN, i.Province)}` : '';
          const statusText = () => {
            if ((!phn && i.PHN) || (phn && i.PHN)) {
              // CMO-1706 - use case 2
              return i.Status === -1 ? t('Restore_and_use') : t('Use');
            }
            if ((phn && !i.PHN) || (!phn && !i.PHN)) {
              // CMO-1706 - use case 3
              return i.Status === -1 ? t('Restore_and_merge') : t('Merge_records');
            }
          };

          return (
            <div id={i.PatientGuid?.replaceAll('-', '')?.toUpperCase()} key={i.PatientGuid} className="flex align-items-center w-full mb-2">
              <div style={{ width: '145px' }}>{`${index + 1}. ${statusText()}`}</div>
              <Button
                id={`${i.PatientGuid?.replaceAll('-', '')?.toUpperCase()}_button`}
                className="p-button-link py-0"
                label={`${patientFullNameWithAge(i)}${phnText}`}
                disabled={isSavingPatientDetails}
                onClick={() => handleExistingProfile(i)}
              />
              {i.Status === -1 && `(${t('Archived')})`}
            </div>
          );
        })}

        {phn && duplicateList?.length === 1 && !duplicateList[0]?.PHN && (
          <div className="mt-3">{t('The_demographics_in_previous_record_will_be_replaced_with_the_current_values')}</div>
        )}

        {phn && duplicateList?.length === 1 && duplicateList[0]?.PHN && (
          <div className="mt-3">{t('Note_that_the_existing_record_has_a_different_PHN')}</div>
        )}

        <div className={cx('grid col-12 p-0 p-align-center mt-4')}>
          <Button id={elementIDs.ignoreSimilarRecords} className="mb-2 w-full" label={t('Ignore_similar_records')} onClick={onIgnoreSimilarRecords} />
        </div>
      </div>
    </Dialog>
  );
};

export default DuplicatePatientDialog;
