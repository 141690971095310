import React from 'react';
import { Chip } from 'primereact/chip';
import { usePatientBillingHistoryContext } from '../../../hooks/usePatientBillingHistoryContext';
import { useTable } from '../../../hooks/useTable';
import moment from 'moment';

const PeriodChip = () => {
  const { state } = usePatientBillingHistoryContext();
  const { fetchData, currentRequestParams } = useTable();
  const from = state.query.PeriodFrom;
  const to = from ? moment(state.query.PeriodTo || moment().toDate()).format('YYYY/MM/DD') : '';
  const label = `Period from ${from} to ${to}`;

  const onRemove = () => {
    fetchData({ ...currentRequestParams, query: { ...state.query, Period: '', PeriodFrom: '', PeriodTo: '' } });
  };

  if (!from && !to) return null;

  return <Chip label={label} removable onRemove={onRemove} />;
};

export default PeriodChip;
