import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Province as ProvincePicker } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';
import { provincesListWithEmptyOption } from '../../../../../../config/dropdownListsConfig';

const Province = () => {
  const name = inputs.province.name;
  const { errors, control } = useFormContext();

  const streetAddressInput = (field) => {
    return (
      <ProvincePicker
        ref={field.ref}
        input={inputs.province}
        value={field.value}
        options={provincesListWithEmptyOption}
        errorMessage={errors[name]?.message}
        onChange={(value) => field.onChange(value)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => streetAddressInput(field)} />;
};

export default Province;
