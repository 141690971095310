import React from 'react';
import ReactDOM from 'react-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { Button } from 'primereact/button';

import { isEmpty } from 'lodash';
import { store } from '../../../store';
import { resetPrivateForm } from '../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/resetPrivateForm';
import { updateTeleplanInvoice, updateWSBCRecord } from '../../../modules/claims/actions/claims.action.creators';
import { closeToastMessage, setTheme, setToastPosition } from '../../../modules/core/actions/core.action.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';

export const customPrompt = (message, callback, dispatch) => {
  const claims = store.getState().claims;
  const { name, data, warnMessage, isDirty } = JSON.parse(message);
  const node = document.createElement('div');
  node.setAttribute('custom-prompt', '');

  document.body.appendChild(node);

  const accept = (answer) => {
    ReactDOM.unmountComponentAtNode(node);
    callback(answer);
    // check if form should be nulled
    switch (name) {
      case 'privateClaim':
        resetPrivateForm();
        dispatch(closeToastMessage(true));
        break;

      case 'EClaim':
        break;

      case 'EClaimPolicy':
        break;

      case 'WSBCReport':
        // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
        !isEmpty(claims.wsbcRecord) && !claims.wsbcRecord.formState?.resetWSBCR && dispatch(updateWSBCRecord({}));
        dispatch(closeToastMessage(true));
        break;

      case 'TeleplanInvoice':
        // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
        !isEmpty(claims.teleplanInvoice) && !claims.teleplanInvoice.formState?.resetTeleplanInvoice && dispatch(updateTeleplanInvoice({}));
        // Reset toast messages postion
        dispatch(setToastPosition(''));
        dispatch(closeToastMessage(true));
        break;

      case 'userPreferences':
        dispatch(setTheme(data));
        break;

      case 'schedulerSavedEvent':
        dispatch(closeToastMessage(true));
        break;

      default:
        break;
    }
  };

  const reject = () => {
    ReactDOM.unmountComponentAtNode(node);
    callback();
  };

  const footer = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={() => accept(callback)} />
        <Button id={elementIDs.dialogCancel} className="p-button-outlined" label={t('No')} onClick={() => reject(callback)} />
      </>
    );
  };

  ReactDOM.render(
    <ConfirmDialog
      id={elementIDs.customPrompt}
      visible={isDirty}
      message={warnMessage ? warnMessage : t('You_have_unsaved_changes')}
      header={t('Confirmation')}
      icon="pi pi-exclamation-triangle"
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      onHide={() => reject(callback)}
      footer={footer}
    />,
    node
  );
};
