import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider } from 'primereact/divider';
import Service from '../EClaimForm/Inputs/Service';
import Duration from '../EClaimForm/Inputs/Duration';
import Fee from '../EClaimForm/Inputs/Fee';
import LessMSP from '../EClaimForm/Inputs/LessMSP';
import Delete from './ActionButtons/Delete';
import AddService from './ActionButtons/AddService';

import { serviceInitValues } from '../../helpers/defaultValues';

const ServicePicker = ({ fieldArrayMethods }) => {
  const { isView } = useFormContext();
  const { fields, remove, insert } = fieldArrayMethods;
  const serviceInit = serviceInitValues();

  return (
    <>
      {fields?.map((item, index) => {
        return (
          <div className="w-full" key={item.id}>
            <div className="flex flex-column lg:flex-row align-items-center gap-0 lg:gap-3 w-full">
              {/* Show number of the row on large screens (> 1024px) */}
              <div className="hidden lg:block eclaim_step_two_left_gap">{`${index + 1}.`}</div>

              {/* Show divider only on small screens (< 1024px) */}
              {index > 0 && (
                <div className="block lg:hidden w-full">
                  <Divider />
                </div>
              )}

              <div className="flex flex-column sm:flex-row gap-3 w-full">
                <div className="eclaim_step_two_input_wrap">
                  <Service index={index} />
                </div>
                <div className="eclaim_step_two_input_wrap">
                  <Duration index={index} />
                </div>
              </div>

              <div className="flex flex-column sm:flex-row gap-3 w-full">
                <div className="eclaim_step_two_input_wrap">
                  <Fee index={index} />
                </div>
                <div className="eclaim_step_two_input_wrap">
                  <LessMSP index={index} />
                </div>
              </div>

              {isView ? (
                <div className="eclaim_step_two_right_gap"></div>
              ) : (
                <div className="flex gap-3 w-auto sm:w-full lg:w-auto">
                  <AddService onClick={() => insert(index + 1, serviceInit)} />
                  <Delete fields={fields} onClick={() => remove(index)} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ServicePicker;
