import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ConfirmDialog } from 'primereact/confirmdialog';

import { inputs } from '../../helpers/inputs';
import { localStateInitValues } from '../../helpers/initialState';
import { t } from '../../../../../../service/localization/i18n';

const DeleteEClaimPolicyConfirm = () => {
  const { localState, setLocalState, setValue, getValues } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      deleteEClaimPolicy: {
        ...localStateInitValues.deleteEClaimPolicy,
        policies: prevState.deleteEClaimPolicy.policies
      }
    }));
  };

  const onAccept = () => {
    const rowGuidKey = inputs.rowGuid.name;
    const primaryPolicyKey = inputs.primaryPolicy.name;
    const secondaryPolicyKey = inputs.secondaryPolicy.name;
    const primaryPolicy = getValues(primaryPolicyKey);
    const secondaryPolicy = getValues(secondaryPolicyKey);
    const policyForDeletion = localState.deleteEClaimPolicy.policy;

    if (primaryPolicy?.[rowGuidKey] === policyForDeletion?.[rowGuidKey]) setValue(primaryPolicyKey, {});
    if (secondaryPolicy?.[rowGuidKey] === policyForDeletion?.[rowGuidKey]) setValue(secondaryPolicyKey, {});

    setLocalState((prevState) => {
      const filteredPoliciesList = prevState.policiesList?.filter((i) => {
        return i?.[rowGuidKey] !== policyForDeletion?.[rowGuidKey];
      });

      return {
        ...prevState,
        policiesList: filteredPoliciesList,
        deleteEClaimPolicy: {
          ...prevState.deleteEClaimPolicy,
          policies: [...prevState.deleteEClaimPolicy.policies, policyForDeletion]
        }
      };
    });

    // Set dirty screen to trigger revalidation
    setValue(inputs.fantom.name, `${Math.random()}`, { shouldDirty: true });
  };

  return (
    <ConfirmDialog
      visible={localState.deleteEClaimPolicy.showDialog}
      message="Are you sure you want to proceed?"
      header={t('Confirmation')}
      icon="pi pi-exclamation-triangle"
      acceptClassName="p-button-danger"
      rejectClassName="p-button-outlined"
      onHide={onHide}
      accept={onAccept}
      reject={onHide}
    />
  );
};

export default DeleteEClaimPolicyConfirm;
