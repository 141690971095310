import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'jspdf-autotable';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { isEmpty } from 'lodash';
import { prefsCodes } from '../../modules/config/prefsCodesConfig';
import { updatePreference } from '../../modules/preferences/helpers/updatePreference';
import { savePreferences, setCurrentUserPrefs } from '../../modules/preferences/actions/preferences.action.creators';
import { t } from '../../service/localization/i18n';

const TableSettings = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { currentUserPrefs, savingPreferences } = useSelector((state) => state.preferences);

  const displayPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'display');
  const dataTablePrefs = () => {
    const currentTablePrefs = displayPrefs?.content?.find((i) => i.label === prefsCodes.dataTable);

    // If new account and there are no any prefs for data table yet
    if (!currentTablePrefs) {
      return {
        ...displayPrefs,
        content: [
          ...displayPrefs?.content,
          {
            label: prefsCodes.dataTable,
            value: {
              [props.dataTableName]: {
                freezeColumns: '',
                columns: props.columns?.map((i) => i.field)
              }
            }
          }
        ]
      };
    }

    return currentTablePrefs;
  };

  const onColumnToggle = (e) => {
    let _selectedColumns;
    const isFrozen = props.selectedColumns?.find((i) => i.field === e.target.name)?.frozen;

    if (props.selectedColumns.length === 1 && isFrozen) return;

    if (e.checked) {
      _selectedColumns = [...props.selectedColumns, props.columns?.find((i) => i.field === e.target.name)];
    }
    if (!e.checked) {
      _selectedColumns = props.selectedColumns?.filter((i) => i.field !== e.target.name);
    }

    const orderedSelectedColumns = props.columns?.filter((i) => _selectedColumns.some((x) => x.field === i.field));

    props.setSelectedColumns(orderedSelectedColumns);
  };

  const toggleColumnBody = (rowData) => {
    return (
      <div className="ml-6">
        <Checkbox
          inputId={rowData.field}
          name={rowData.field}
          value={rowData.header}
          checked={props.selectedColumns.some((x) => x.field === rowData.field)}
          onChange={onColumnToggle}
        />
      </div>
    );
  };

  const freezeColumnBody = (rowData) => {
    return (
      <div className="ml-6">
        <Checkbox
          inputId={rowData.field}
          name={rowData.field}
          value={rowData.header}
          disabled={!props.selectedColumns.some((x) => x.field === rowData.field)}
          checked={props.selectedColumns.find((x) => x.field === rowData.field)?.frozen}
          onChange={onFreezeColumn}
        />
      </div>
    );
  };

  const onFreezeColumn = (e) => {
    if (e.checked) {
      const freezeColumn = props.selectedColumns?.map((i) => {
        if (i.frozen) return { ...i, frozen: false };
        if (i.field === e.target.name) return { ...i, frozen: e.checked };
        return i;
      });
      props.showExpand && props.setShowExpandColumn(false);
      return props.setSelectedColumns(freezeColumn);
    }

    if (!e.checked) {
      const unfreezeColumn = props.selectedColumns?.map((i) => {
        if (i.frozen) return { ...i, frozen: false };
        return i;
      });
      props.showExpand && props.setShowExpandColumn(true);
      return props.setSelectedColumns(unfreezeColumn);
    }
  };

  const onSaveColumnPreferences = () => {
    let currentDataTablePrefs;
    const columnsList = props.selectedColumns?.map((i) => i.field);
    const frozenColumn = props.selectedColumns?.find((i) => i.frozen)?.field || '';

    if (dataTablePrefs() && !isEmpty(dataTablePrefs())) {
      currentDataTablePrefs = {
        ...dataTablePrefs().value,
        [props.dataTableName]: {
          ...dataTablePrefs()[props.dataTableName],
          columns: columnsList,
          freezeColumns: frozenColumn
        }
      };
    } else {
      currentDataTablePrefs = {
        [props.dataTableName]: {
          columns: columnsList,
          freezeColumns: frozenColumn
        }
      };
    }

    const newPrefs = updatePreference(displayPrefs, currentDataTablePrefs, prefsCodes.dataTable);
    dispatch(savePreferences(user.UserGuid, newPrefs, savePrefsResponseCallback));
  };

  const savePrefsResponseCallback = (prefs) => {
    const successResponse = () => {
      dispatch(setCurrentUserPrefs(prefs));
      props.setShowSettingsDialog(false);
    };
    return successResponse();
  };

  return (
    <Dialog
      header={t('Table_settings')}
      contentClassName="pb-0"
      visible={props.showSettingsDialog}
      style={{ width: '25vw' }}
      breakpoints={{ '1600px': '35vw', '1366px': '45vw', '960px': '55vw', '768px': '75vw', '550px': '95vw' }}
      baseZIndex={1000000}
      onHide={props.setShowSettingsDialog}
    >
      <div className="pb-4 pt-2">
        <DataTable value={props.columnsForToggle} className="p-datatable-sm">
          <Column field="header" header={t('Name')} />
          <Column
            field="code"
            header={t('Toggle_columns')}
            body={toggleColumnBody}
            // style={{ width: '120px' }}
          />

          {/* <Column
            field="name"
            header={t("Freeze_column")}
            body={freezeColumnBody}
            style={{ width: '120px' }}
          /> */}
        </DataTable>

        <Button className="w-full mt-5" label={t('Save')} loading={savingPreferences} onClick={onSaveColumnPreferences} />
      </div>
    </Dialog>
  );
};

export default TableSettings;
