import { t } from '../../../../../service/localization/i18n';

export const paymentMethodOptions = [
  { name: 'na', value: '', label: t('N_A') },
  { name: 'cash', value: 'cash', label: t('Cash') },
  { name: 'visaCredit', value: 'visaCredit', label: t('Visa_Credit') },
  { name: 'masterCardCredit', value: 'masterCardCredit', label: t('MasterCard_Credit') },
  { name: 'amExCredit', value: 'amExCredit', label: t('AmEx_Credit') },
  { name: 'debitCard', value: 'debitCard', label: t('Debit_Card_Interac') },
  { name: 'visaDebit', value: 'visaDebit', label: t('Visa_Debit') },
  { name: 'masterCardDebit', value: 'masterCardDebit', label: t('MasterCard_Debit') },
  { name: 'amExDebit', value: 'amExDebit', label: t('AmEx_Debit') },
  { name: 'check', value: 'check', label: t('Check') },
  { name: 'other', value: 'other', label: t('Other') }
];
