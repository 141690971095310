import React from 'react';

import SubmitWithGenericPHN from '../Inputs/SubmitWithGenericPHN';
import ToggleCatalogs from '../../ActionButtons/ToggleCatalogs';
import SectionTitle from './SectionTitle';
import PatientPicker from '../Inputs/PatientPicker';
import FormType from '../Inputs/FormType';
import ClaimNumber from '../Inputs/ClaimNumber';
import InjuryDate from '../Inputs/InjuryDate';
import Employer from '../Inputs/Employer';
import Phone from '../Inputs/Phone';
import Address from '../Inputs/Address';
import City from '../Inputs/City';

const ClaimInfoSection = () => {
  return (
    <>
      <SectionTitle>
        <ToggleCatalogs />
      </SectionTitle>

      <PatientPicker />

      <FormType />

      <ClaimNumber />

      <InjuryDate />

      <Employer />

      <Phone />

      <Address />

      <City />

      <SubmitWithGenericPHN />
    </>
  );
};

export default ClaimInfoSection;
