import React from 'react';
import CatalogsProvider from './CatalogsProvider/CatalogsProvider';
import CatalogsView from './Views/CatalogsView';

import './Catalogs.scss';

// interface IPatient {
//   PatientGuid: string,
//   FirstName: string,
//   LastName: string,
//   BirthDay: string | Date,
//   Sex: string,
//   MidName?: string,
//   AlterName?: string,
// }

// interface ICatalogTab {
//   name: string,
//   label: string,
//   codeType: string,
//   categoryType: "feecodes_dobc" | "feecodes_icbc" | "feecodes_msp" | "feecodes_pbc" | "feecodes_tec" | "feecodes_wsbc" | "icd9" | "insurers_all" | "insurers_primary" | "aoi" | "noi" | "referral" | undefined, // Provide `categoryType` to get sub-tabs from "catalogCategories" from redux
//   requestParams: {
//      getCodesFor: "teleplan" | "private" | "eclaim" | "appt" // required param to define which request should be done
//      practitionerGuid?: string // Need for services "getClinicServices"
//      catalogType?: "tec" | "pbc" // Need for eClaims "getEClaimsServices"
//      codeType?: "feecodes" | "icd9", | "aoi" | "noi" | "referral" | "services" | "facility", // Need for teleplan codes "getCodesByType"
//      speciality?: string
//  }, // additional params for getting codes request for catalogs

//   codeDescription?: string,
//   maxEntries?: number,
//   openCatalogButton?: boolean,
//   searchAllType?: string,
//   getCatalogCodes?: ({catalogsState, menuItem}) => ({codeList: []ICode}), // The `getCatalogCodes` prop is a optional async callback function that make request for items list for data scroller and returns response data object that should have codeList proerty.
//   showAlphabet?: boolean, // Define to show alphabet search
//   showSearchPanelForCustomTabLayout?: boolean, // Define to show search panel for custom tab layout
//   hideSearchForMobileView?: boolean, // Define to show search button for mobile catalogs view
//   tabLayout?: ({ scrollHeight: number }) => JSX.Element, // custom tab layout
//   defaultTableData?: any // Data for tab custom layout. Required when using the "tabLayout" property. "defaultTableData" is used for searching codes. When the search value is reset, it should revert to "defaultTableData",
//   searchActionButton?: {
//      tooltip: string,
//      onClick: () => void,
//   }
// }

// interface ITabs {
//   activeTabIndex: number, // Index for active catalog tab
//   catalogTabs: ICatalogTab[] // Tabs list
//   onTabChange?: (e) => void, // On catalog tab change
// }

// interface ITabsOptional {
//   hideTabs: true, // Default value "false". If need to hide tabs, set hideTabs to "true" and pass "activeTab" property. Do not pass "activeTabIndex", "onTabChange" and "catalogTabs" props
//   activeTab: ICatalogTab
// }

// interface ICode {
//   value: string,
//   label: string,
//   order?: number,
//   isWeekend?: boolean
// }

// interface ICatalogs {
// ====Catalogs header=== //
//   id?: string, // Catalogs id
//   patients?: IPatient[], // List of patients
//   onPatientChipRemove?: (patient: IPatient) => void,
//   removablePatientChip?: boolean // Whether to display a remove icon
//   hideCatalogsHeader?: boolean,
//   showCloseButton?: boolean, // Show close button for closing form. By default is false.
//   onClose?: () => void, // Close form function. By default is history.goBack()
//   showCatalogs?: { desktop: boolean, mobile: boolean }, // Provide state for toggle catalogs button
//   toggleCatalogs?: (view: "desktop" | "mobile") => void, // Provide function for updating "showCatalogs" state
//   hideToggleCatalogsButton?: boolean,
//   patientChipsLayout?: () => JSX.Element | JSX.Element, // Custom patient chips layout
//   dataScrollerItemTemplate?: (data: ICode, catalogsState) => JSX.Element, // Custom layout for data scroller
//   codeChipLabel?: (code: ICode) => string, // Custom label for code chip

// ====Catalogs tabs=== //
// ITabs | ITabsOptional // If need to hide catalog tabs then use "ITabsOptional" interface

// ====Catalogs search panel=== //
/**
 * The `getSelectedCodes` prop is a required callback function that can be provided to retrieve the selected codes for the current catalog tab. When a catalog tab is active, this function is invoked with the active tab as an argument, allowing you to obtain and handle the selected codes associated with that specific tab.
 *
 * @param {ICatalogTab} activeTab - The currently active catalog tab for which selected codes are to be retrieved.
 *
 * @returns {ICode[]} An array of selected codes for the current catalog tab.
 *
 * @example
 * // Provide the `getSelectedCodes` callback to handle selected codes for the active tab.
 * <YourComponent getSelectedCodes={(activeTab) => handleSelectedCodes(activeTab)} />
 */
// getSelectedCodes: (activeTab: ICatalogTab) => ICode[];

/**
 * The `onCodeChipClick` prop is a required callback function that handles the click event on a code chip within the catalog tab. When provided, this callback allows you to respond to user interactions, such as selecting or interacting with a specific code chip.
 * 
 * <YourComponent onCodeChipClick={handleCodeChipClick} getSelectedCodes={getSelectedCodes} />
 *
 * @param {ICode} code - The code associated with the clicked code chip.
// onCodeChipClick: (code: ICode) => void
 */

/**
 * The `onLetterClick` prop is a optional callback function that is invoked when a user click on alphabet letter on the search panel. It is responsible for making a search request and updating the catalogs state via the "setCatalogsState" function.
 *
 * @param {{ letter: string, setCatalogsState: () => void }} event - The search event triggering the callback. This event object contains information about the clicked letter and state update callback function.
 *
 * @example
 * // Example usage of `onLetterClick` prop:
 * const handleLetterClick = (event) => {
 *   // Implement search logic using the information from the event
 *   // Make a search request and update catalogs state
 *   setCatalogsState((prevState) => ({
 *     ...prevState,
 *     // Update relevant properties in the catalogs state
 *   }));
 * };
 *
 * <YourComponent onLetterClick={handleLetterClick} />
 */

/**
 * The `onSelectCodeFromCatalogsTable` prop is a required callback function that handles the click event on a code within the catalogs data table row.
 * 
 * <YourComponent onSelectCodeFromCatalogsTable={handleSelectCodeFromCatalogsTable} />
 *
 * @param {ICode} code - The code associated with the clicked code chip.
// onSelectCodeFromCatalogsTable: (code: ICode, params: {[key: string]: any}) => void
 */

/**
 * The `catalogsTablValues` prop is a optional array of codes or callback function that return array of codes items.
 *
// catalogsTableValues?: ICode[] | (catalogsState) => ICode[]
 */
// }

const Catalogs = (props) => {
  return (
    <CatalogsProvider {...props}>
      <CatalogsView />
    </CatalogsProvider>
  );
};

export default Catalogs;
