import React from 'react';

const AuthFormWrap = ({ children, label }) => {
  return (
    <div className="login-body">
      <div className="login-panel grid h-auto">
        <div className="login-panel-header login-panel-header-text">{label}</div>
        <div className="login-panel-content col-12 lg:col-12 pt-4">
          <div className="w-full text-center pb-4">
            <img src="/assets/layout/images/logo-cm.png" alt="logo" />
          </div>
          {children}
        </div>
        <div style={{ fontSize: 'small', padding: '1em' }}>
          This system is restricted to authorized users. If you're unauthorized, terminate access now.
        </div>
      </div>
    </div>
  );
};

export default AuthFormWrap;
