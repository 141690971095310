import React from 'react';
import SimilarClaimsTable from './modules/table/SimilarClaimsTable';
import SimilarClaimsListProvider from './SimilarClaimsListProvider/SimilarClaimsListProvider';

// interface ISimilarClaimsList {
//   patientsList: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }[]
//   initPatient?: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }
// }

const SimilarClaimsList = (props) => {
  return (
    <SimilarClaimsListProvider {...props}>
      <SimilarClaimsTable />
    </SimilarClaimsListProvider>
  );
};

export default SimilarClaimsList;
