import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Steps } from 'primereact/steps';
import { steps } from '../../config/steps';
import { t } from '../../../../../../service/localization/i18n';

const Stepper = () => {
  const { localState, isView } = useFormContext();
  const showStepLabels = window.innerWidth >= 640;

  const commonItems = [{ label: showStepLabels ? t('Insurance') : '' }, { label: showStepLabels ? t('Service') : '' }];

  const previewItems = commonItems;

  const items = [...commonItems, { label: showStepLabels ? t('Response') : '' }];

  const getActiveIndex = () => {
    if (localState.step === steps.submitPreview) return 2;
    return localState.step - 1;
  };

  const activeIndex = getActiveIndex();

  return (
    <div className="flex justify-content-center">
      <div className="w-full" style={{ maxWidth: '508px' }}>
        <Steps className="p-0" model={isView ? previewItems : items} activeIndex={activeIndex} />
      </div>
    </div>
  );
};

export default Stepper;
