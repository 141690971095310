import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { removeAllSpecialCharacters } from '../../../../../../regex/regex';

const DxCodeLayout = ({ rowData }) => {
  if (rowData?.InvoiceType === 'Private') {
    return <div></div>;
  }

  return (
    <>
      <Tooltip
        className="max-w-20rem"
        target={`.dxTooltip_${rowData.InvoiceGuid?.replaceAll(removeAllSpecialCharacters, '')?.replaceAll(' ', '')}`}
        position="top"
        showDelay={1000}
      >
        <div className="flex flex-column gap-2">
          {rowData.ICD9Text?.map((i, index) => {
            const codesList = rowData.ICD9Code?.split(',');

            return (
              <div key={i}>
                <div>{`${codesList[index]} - ${i}`}</div>
              </div>
            );
          })}
        </div>
      </Tooltip>
      <span className={`dxTooltip_${rowData.InvoiceGuid?.replaceAll(removeAllSpecialCharacters, '')?.replaceAll(' ', '')}`}>{rowData.ICD9Code}</span>
    </>
  );
};

export default DxCodeLayout;
