import React from 'react';
import ClaimsTableDialog from './ClaimsTableDialog';

const Dialogs = () => {
  return (
    <>
      <ClaimsTableDialog />
    </>
  );
};

export default Dialogs;
