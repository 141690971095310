import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { InputWrapper, NoteEditorWithFormControl } from '../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { decodeText, encodeText } from '../../utils/decodeText';
import { t } from '../../../service/localization/i18n';

const Edit = ({ note, onEditNote }) => {
  const history = useHistory();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting }
  } = useForm({
    defaultValues: {
      Title: decodeText(note?.Title),
      Comment: decodeText(note?.Comments)
    }
  });

  const onSubmit = async (data) => {
    const newNote = {
      ...note,
      Title: encodeText(data.Title),
      Comments: encodeText(data.Comment)
    };

    await onEditNote(newNote);

    reset(data, { keepValues: true });
    history.goBack();
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty });
  };

  return (
    <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <Prompt when={isDirty} message={promptMessage} />

      <Controller
        name="Title"
        control={control}
        rules={{ required: t('Mandatory_field.1') }}
        render={({ field }) => (
          <InputWrapper label="Title" required errorMessage={errors?.Title?.message}>
            <InputText {...field} id="replyTitle" className={errors?.Title ? 'p-invalid' : ''} />
          </InputWrapper>
        )}
      />

      <NoteEditorWithFormControl id="newComment" control={control} name="Comment" autoFocus />

      <div className="flex column-gap-3 justify-content-end mt-auto">
        {isDirty ? (
          <>
            <Button type="button" outlined label="Cancel" onClick={() => history.goBack()} />
            <Button loading={isSubmitting} type="submit" label="Save" />
          </>
        ) : (
          <Button type="button" outlined label="Close" onClick={() => history.goBack()} />
        )}
      </div>
    </form>
  );
};

export default Edit;
