import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';

const EndTimeEditor = ({ options }) => {
  const { onEndTimeEditorChange, handleTimeBlur } = useUpdateRecords();

  return (
    <div className="relative">
      <InputMask
        className="w-5rem"
        mask="99:99"
        placeholder="HH:MM"
        value={options.value}
        onChange={(e) => onEndTimeEditorChange(e.target.value, options)}
        onBlur={(e) => handleTimeBlur(e.target.value, options)}
      />
    </div>
  );
};

export default EndTimeEditor;
