import React from 'react';
import Notes from '../../../../../components/Widgets/Notes/NotesView/Notes';
import { createPatientNote, deletePatientNote, editPatientNote } from '../../../actions/patients.action.creators';

const PatientNotes = ({ notes, entityGuid, setNotes, onScroll, searchForNotes, fetchNotes }) => {
  const onCreateNote = async (note, options) => {
    const result = await createPatientNote(note);
    if (result) {
      const { searchQuery } = options;
      if (searchQuery) {
        fetchNotes({ resetNotesList: true });
      } else {
        setNotes((prevState) => ({
          ...prevState,
          totalRecords: prevState.totalRecords + 1,
          notesList: [result, ...prevState.notesList]
        }));
      }
    }
  };

  const onEditNote = async (note, options) => {
    const result = await editPatientNote(note);
    if (result) {
      const { searchQuery } = options;
      if (searchQuery) {
        fetchNotes({ resetNotesList: true });
      } else {
        setNotes((prevState) => {
          const updatedNotesList = prevState.notesList?.map((i) => {
            return i.NoteGuid === result.NoteGuid ? result : i;
          });

          return {
            ...prevState,
            notesList: updatedNotesList
          };
        });
      }
    }
  };

  const onDeleteNote = async (noteGuid) => {
    const result = await deletePatientNote(noteGuid);
    if (result) {
      setNotes((prevState) => {
        const notesList = prevState.notesList.filter((i) => i.NoteGuid !== noteGuid);
        return {
          ...prevState,
          totalRecords: prevState.totalRecords - 1,
          notesList
        };
      });
    }
  };

  const onSearch = async (query) => {
    await searchForNotes({ freetext: query });
  };

  return (
    <Notes
      entityGuid={entityGuid}
      notesList={notes.notesList}
      page={notes.page}
      totalPages={notes.totalPages}
      onScroll={onScroll}
      onSearch={onSearch}
      onEditNote={onEditNote}
      onCreateNote={onCreateNote}
      onDeleteNote={onDeleteNote}
    />
  );
};

export default PatientNotes;
