import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';
import { steps } from '../../helpers/steps';

const ChangeOrderDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideOrderDialog = () => {
    setLocalState((prevState) => ({ ...prevState, changeOrderDialog: false }));
  };

  const onNext = () => {
    onHideOrderDialog();
    // Go to Dx step
    setLocalState((prevState) => ({ ...prevState, step: steps.groupDxPicker }));
  };

  const orderDialogFooter = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onHideOrderDialog} autoFocus />

        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onNext} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.changeOrderDialog}
      header={t('Warning')}
      visible={localState.changeOrderDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={orderDialogFooter()}
      onHide={onHideOrderDialog}
    >
      {t('You_changed_the_order_of_the_patients')}
    </Dialog>
  );
};

export default ChangeOrderDialog;
