// interface {
//   feeCodes: {
//     base_codes: string,
//     rate: number,
//     min_per_unit: number,
//     max_unit: number,
//     portion: number
//   }[],
//   records: {
//     base_codes: string,
//     rate: number,
//     min_per_unit: number,
//     max_unit: number,
//     portion: number
// }[]
// }

export const calculateSurgicalAssist = ({ feeCodes, records }) => {
  const code13003 = feeCodes?.find((i) => i.value === '13003');
  const baseCodesOnly = code13003?.base_codes?.split(',');
  const baseCodes = records?.filter((i) => baseCodesOnly.some((x) => x === i.fee_code));
  const sumOfFees = baseCodes.reduce((acc, current) => acc + current.billed, 0);
  const amountFor13003 = sumOfFees * (code13003.rate / 100);
  // Round to two decimal places
  const roundedAmountFor13003 = Math.round(amountFor13003 * 100) / 100;

  if (roundedAmountFor13003 > 500) return 500;
  return roundedAmountFor13003;
};
