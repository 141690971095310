/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PayeeSection from '../../Print/PrivateInvoice/Sections/PayeeSection';
import InvoiceDetailsSection from '../../Print/PrivateInvoice/Sections/InvoiceDetailsSection';
import BillToSection from '../../Print/PrivateInvoice/Sections/BillToSection';
import ServiceDateSection from '../../Print/PrivateInvoice/Sections/ServiceDateSection';
import DataTableSection from '../../Print/PrivateInvoice/Sections/DataTableSection';
import NotesSection from '../../Print/PrivateInvoice/Sections/NotesSection';
import TotalSection from '../../Print/PrivateInvoice/Sections/TotalSection';

import { t } from '../../../../service/localization/i18n';

const PrivateInvoiceSend = ({ data }) => {
  return (
    <div className="flex justify-content-center col-12 p-0 privateInvoicePrint">
      <div className="invoiceCardRoot">
        <div className="grid">
          <div className="col-12">
            <div className="card p-0" id="invoice-content">
              <div className="invoice">
                {/* Header */}
                <div className="invoice-header">
                  <div className="invoice-company">
                    <PayeeSection data={data} />
                  </div>

                  <div>
                    <div className="invoice-title">{t('Invoices.3').toUpperCase()}</div>
                    <InvoiceDetailsSection data={data} />
                  </div>
                </div>

                {/* Invoice to */}
                {/* {invoiceToSection} */}
                <div className="invoice-to-email">
                  <div className="flex justify-content-between">
                    <BillToSection data={data} />
                    <ServiceDateSection data={data} />
                  </div>
                </div>

                {/* Data table */}
                <div className="invoice-items-email mt-0">
                  <DataTableSection data={data} />
                </div>

                {/* Total / Notes */}
                <div className="invoice-summary">
                  <div>
                    <NotesSection data={data} />
                  </div>
                  <TotalSection data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateInvoiceSend;
