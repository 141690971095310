import React from 'react';

import FacilityInput from './Common/FacilityInput';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const SubFacility = () => {
  const name = inputs.subFacility.name;
  const label = inputs.subFacility.label;

  return (
    <InputWrapper name={name} label={label}>
      <FacilityInput inputId={elementIDs.subFacilityInput} name={name} />
    </InputWrapper>
  );
};

export default SubFacility;
