import React from 'react';
import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';

const SignUp = () => {
  return (
    <AuthFormWrap label="Welcome to Claim Manager">
      <form name="SignUp">
        <div className="flex flex-column gap-3">SignUp</div>
      </form>
    </AuthFormWrap>
  );
};

export default SignUp;
