import SectionTitle from './SectionTitle';
import IsPrimary from '../Inputs/IsPrimary';
import TermPrimary from '../Inputs/TermPrimary';
import FirstTreatment from '../Inputs/FirstTreatment';
import { t } from '../../../../../../../service/localization/i18n';

const GeneralSection = () => {
  return (
    <>
      <SectionTitle title={t('General')} className="mb-3" />

      <IsPrimary />

      <TermPrimary />

      <FirstTreatment />
    </>
  );
};

export default GeneralSection;
