import React from 'react';
import { useController } from 'react-hook-form';
import WrappedDateOfBirth from './WrappedDateOfBirth';
import { t } from '../../../../service/localization/i18n';
import moment from 'moment';

// interface IDateOfBirthWithFormControl {
//   name: string
//   label: string
//   control: UseControllerProps<FormValues>, // react-hook-form control
//   required?: boolean
// }

const DateOfBirthWithFormControl = (props) => {
  const {
    field,
    formState: { errors }
  } = useController({
    name: props.name,
    control: props.control,
    rules: {
      validate: (value) => validation(value)
    }
  });

  const validation = (value) => {
    if (props.required && !value) {
      return t('Mandatory_field.1');
    }

    const momentValue = moment(value, 'MM/DD/YYYY', true);

    if (!momentValue.isValid()) {
      return t('Invalid_date_format');
    }

    const minYear = 1900;
    const maxYear = moment().year();

    const year = momentValue.year();

    if (year < minYear || year > maxYear) {
      return `Year must be between ${minYear} and ${maxYear}`;
    }

    return true;
  };

  const errorMessage = errors[props.name]?.message;
  return (
    <WrappedDateOfBirth
      {...field}
      name={props.name}
      label={props.label}
      required={props.required}
      isInvalid={errorMessage}
      errorMessage={errorMessage}
    />
  );
};

export default DateOfBirthWithFormControl;
