import { allStatuses } from '../claims/helpers/mapper';

export const getStatusCode = (statusText, statusList) => {
  const list = statusList ? statusList : allStatuses;

  const item = list.find((i) => {
    const normalized = i.label.replace(' ', '').toUpperCase();
    const reply = normalized === statusText.toUpperCase();
    return reply;
  });
  return item?.value;
};

export const getStatusLable = (statusCode) => {
  return allStatuses.find((i) => i.value === statusCode)?.label;
};
