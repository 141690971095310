import React from 'react';
import { useSelector } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';
import classes from './LegoSubMenu.module.scss';
import cx from 'classnames';

const LegoSubMenu = ({
  children,
  subMenuItems,
  activeIndex,
  setActiveIndex,
  rightContent,
  showRightContent = false,
  showOnPatientDetailsScreen = false
}) => {
  const { layoutMode } = useSelector((state) => state.core);
  const { isMobile, iPadProInLandscape } = useSelector((state) => state.core.window);

  return (
    <div
      className={cx(
        'subMenuRoot',
        classes.root,
        showOnPatientDetailsScreen
          ? ''
          : iPadProInLandscape && layoutMode === 'overlay'
            ? 'tabMenuFixedTopiPadProOverlay'
            : iPadProInLandscape && layoutMode === 'static'
              ? 'tabMenuFixedTopiPadProStatic'
              : isMobile
                ? 'tabMenuFixedTopMobile'
                : 'tabMenuFixedTopDesktop'
      )}
    >
      {children ? (
        children
      ) : (
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          {subMenuItems?.map((i) => {
            return (
              <TabPanel headerTemplate={i.headerTemplate} key={i.header} disabled={i.disabled} header={i.header} headerClassName="extra-large-text">
                {i.body()}
              </TabPanel>
            );
          })}
        </TabView>
      )}

      {showRightContent && <div className={classes.rightContent}>{rightContent()}</div>}
    </div>
  );
};

export default LegoSubMenu;
