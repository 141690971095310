import { steps } from '../config/steps';

// Local state to control the form, catalogs, steps, dialogs, etc. No need to use redux.
export const localStateInitValues = {
  sendNow: false,
  isLoading: false,
  isCreating: false,
  isSubmitting: false,
  showTermsDialog: false,
  creatingPrivateInvoice: false,
  policiesList: [],
  locationsList: { pbc: [], tec: [] },
  servicesList: [],
  practitionerPrefs: {},
  eClaimResult: {},
  completeSetupDialog: false,
  addNewPractitionerDialog: false,
  searchValue: '',
  catalogIndex: 0,
  step: steps.stepOne,
  focusFieldParams: {},
  showCatalogs: {
    desktop: true,
    mobile: false
  },
  eClaimPolicy: {
    showEClaimPolicyForm: false,
    policyInfo: {}
  },
  deleteEClaimPolicy: {
    showDialog: false,
    policy: {},
    policies: []
  },
  revokeDialog: {
    showDialog: false,
    data: {}
  },
  catalogsDialog: {
    showDialog: false
  },
  archiveEClaimDialog: {
    showDialog: false,
    eClaim: {}
  }
};
