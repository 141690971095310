import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';

import { currencyFormat } from '../../../utils/currencyFormat';
import { submitAllClaimsSuccessMessage } from '../../../claims/helpers/toastMessages';
import { getClaimList, submitAllClaims } from '../../../claims/actions/claims.action.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { t } from '../../../../service/localization/i18n';

const BatchRecordsSuccessDialogForMultiplePatients = ({ visible, data, onHide, onReviewClick, isFetching, updateClaimsList }) => {
  const dispatch = useDispatch();
  const { filters, pageSize, sortby } = useSelector((state) => state.claims);
  const noTotalCount = data?.TotalCount === 0;

  const footer = () => {
    return (
      <>
        {noTotalCount ? (
          <Button label={t('Close')} onClick={onHide} />
        ) : (
          <Button
            label={t('Send')}
            loading={isFetching}
            tooltip={t('Submit_your_claim_to_insurer')}
            tooltipOptions={{ position: 'top' }}
            onClick={() =>
              dispatch(
                submitAllClaims('submit', (responseData) => {
                  updateClaimsList &&
                    dispatch(
                      getClaimList({
                        page: 1,
                        pageSize: pageSize,
                        filter: JSON.stringify(filters),
                        query: `pid=undefined&sortby=${sortby}&q=${JSON.stringify(filters)}`
                      })
                    );
                  dispatch(setToastMessage({ type: 'success', message: submitAllClaimsSuccessMessage(responseData) }));
                  onHide();
                })
              )
            }
          />
        )}

        <Button
          className="p-button-outlined"
          label={t('Review')}
          tooltip={t('View_all_ready_to_submit_claims')}
          tooltipOptions={{ position: 'top' }}
          onClick={() => onReviewClick({ StatusText: 'Outstanding', InvoiceType: 'Teleplan' })}
        />
      </>
    );
  };

  return (
    <Dialog
      header={t('Please_review_your_submition')}
      visible={visible}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '30vw' }}
      modal
      // blockScroll
      footer={footer()}
      baseZIndex={100000}
      onHide={onHide}
    >
      {noTotalCount ? (
        <>{t('Your_outstanding_claims_have_not_passed_our_validation')}</>
      ) : (
        <>
          <div className="mb-3 font-bold">{t('You_are_about_to_submit_your_invoices_to_Teleplan')}</div>

          <div className="mb-3">
            <div>
              {`${t('Total_invoices')}: `}
              <span className="font-bold">{data?.TotalCount}</span>
            </div>
            <div>
              {`${t('Total_amount')}: `}
              <span className="font-bold">{currencyFormat(data?.TotalAmount)}</span>
            </div>
          </div>

          {/* Total */}
          {data?.ByPayor?.length && (
            <Fieldset className="mb-4" legend={`${t('By_payor')}:`}>
              {data?.ByPayor?.map((i, index) => {
                const typeText = i.InvoiceType === 'M' ? t('Claim').toLowerCase() : t('Invoices.3').toLowerCase();
                return (
                  <div key={`${i.Caption}_${index}`}>
                    <div>
                      {`${t('Type')}: `}
                      <Button
                        className="p-button-link p-0"
                        label={`${i.InvoiceTypeLabel} ${typeText}`}
                        onClick={() => onReviewClick({ StatusText: 'Outstanding', InvoiceType: i.InvoiceTypeLabel })}
                      />
                    </div>
                    <div>
                      {`${t('Total_invoices')}: `}
                      <span className="font-bold">{i.Total}</span>
                    </div>
                    <div>
                      {`${t('Total_amount')}: `}
                      <span className="font-bold">{currencyFormat(i.Amount)}</span>
                    </div>

                    {data?.ByPayor?.length - 1 > index && <Divider />}
                  </div>
                );
              })}
            </Fieldset>
          )}

          {/* Practitioners */}
          {data?.ByPractitioner?.length && (
            <Fieldset legend={`${t('By_practitioner')}:`}>
              <div>
                {data?.ByPractitioner?.map((i, index) => {
                  return (
                    <div key={`${i.Caption}_${index}`}>
                      <div>
                        {`${t('Practitioner')}: `}
                        <Button
                          className="p-button-link p-0"
                          label={`${i.Caption}`}
                          onClick={() => onReviewClick({ StatusText: 'Outstanding', DoctorGuid: i.InvoiceTypeFilter })}
                        />
                      </div>
                      <div>
                        {`${t('Total_invoices')}: `}
                        <span className="font-bold">{i.Total}</span>
                      </div>
                      <div>
                        {`${t('Total_amount')}: `}
                        <span className="font-bold">{currencyFormat(i.Amount)}</span>
                      </div>

                      {data?.ByPractitioner?.length - 1 > index && <Divider />}
                    </div>
                  );
                })}
              </div>
            </Fieldset>
          )}

          {data?.ByPayeeNumber?.length && (
            <Fieldset className="mb-4" legend={`${t('By_payee')}:`}>
              {data?.ByPayeeNumber?.map((i, index) => {
                return (
                  <div key={`${i.Caption}_${index}`}>
                    <div className="flex" style={{ columnGap: '10px' }}>
                      {`${i.Caption}: `}
                      <div className="font-bold text-right" style={{ width: '75px' }}>
                        {currencyFormat(i.Amount)}
                      </div>
                    </div>

                    {data?.ByPayeeNumber?.length - 1 > index && <Divider />}
                  </div>
                );
              })}
            </Fieldset>
          )}
        </>
      )}
    </Dialog>
  );
};

export default BatchRecordsSuccessDialogForMultiplePatients;
