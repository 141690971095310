import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePatientNotes from '../../../hooks/usePatientNotes';
import NoteDetails from '../../../../mails/components/NoteDetails';
import { rowActionsMenuItems, viewProfile } from '../helpers/rowActionsMenuItems';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { editPatientNote, getPatientNoteDetails } from '../../../actions/patients.action.creators';
import { routes } from '../../../../../routes/routes';
import { setPatientNoteDetails } from '../../../../notes/actions/actions';

const PatientNoteDetails = ({ isReply = false, isEdit = false }) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { onReplyNote, fetchAllNotes, onDeleteNote } = usePatientNotes();
  const patientNotes = useSelector((state) => state.notes.patientNotes);
  const note = useSelector((state) => state.notes.patientNoteDetails);
  const [loading, setLoading] = useState(false);

  const getNoteDetails = async () => {
    setLoading(true);
    const result = await getPatientNoteDetails(id);
    setLoading(false);
    if (result) dispatch(setPatientNoteDetails(result));
  };

  const fetchNotes = async (fetchProps) => {
    setLoading(true);
    await fetchAllNotes(fetchProps);
    setLoading(false);
  };

  const handleDeleteNote = async (noteGuid) => {
    setLoading(true);
    await onDeleteNote(noteGuid);
    setLoading(false);
    history.replace(routes.patientNotesInbox.path);
  };

  const onEditNote = async (note) => {
    await editPatientNote(note);
    await getNoteDetails();
  };

  const onReplyClick = () => history.push(`${routes.patientNotesInbox.path}/reply`);

  return (
    <NoteDetails
      inboxType="patient"
      note={note}
      notes={patientNotes}
      loading={loading}
      isReply={isReply}
      isEdit={isEdit}
      onViewVlaimProfile={viewProfile}
      onEditNote={onEditNote}
      fetchNotes={fetchNotes}
      onSaveReply={onReplyNote}
      onReplyClick={onReplyClick}
      onDeleteNote={handleDeleteNote}
      setNoteDetails={setPatientNoteDetails}
      rowActionsMenuItems={rowActionsMenuItems}
    />
  );
};

export default PatientNoteDetails;
