import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  checkFor03333Code,
  checkIfCodeValidForLocation,
  checkIfFeeCodeNeedsEmergency,
  checkIfRefNumberIsRequeredOnAddingCode,
  endTimeInputRequirements,
  startTimeInputRequirements,
  validateOffHours
} from '../helpers/validationSchema';
import { useCatalogTabs } from './useCatalogTabs';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { isOverMaxEntries } from '../helpers/isOverMaxEntries';
import { inputs } from '../helpers/inputs';
import { feeCodeConflicts } from '../helpers/claimFormValidation';
import { bonusFeeCodes, feeCodeMaxEntry } from '../../../../config/defaultValuesConfig';
import { defaultBMI } from '../../../../config/defaultValuesConfig';
import { calculateUnits } from '../helpers/calculateUnits';
import { isTimeValid } from '../../../../utils/formatTime';
import { totalValue } from '../helpers/totalValue';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { useCreateNewRecord } from '../views/TeleplanGroup/hooks/useCreateNewRecord';
import { addMissingCodesToRecentList } from '../helpers/updateRecentCodesList';
import { codesThatRequiresBMI } from '../helpers/codesCheckList';
import { weeksForMW } from '../config/defaultValues';
import { useServiceDate } from './useServiceDate';
import { useValidation } from './useValidation';
import { differenceBy, map } from 'lodash';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

export const useCodePickerActions = (input) => {
  const dispatch = useDispatch();
  const { catalogTabs } = useCatalogTabs();
  const { setValue, setLocalState, localState, errors, clearErrors, watch, isNew, isGroup, isUnlimitedEntriesForFee, isDirty, getValues } =
    useFormContext();
  const { createNewRecord } = useCreateNewRecord();
  const { onChangeServiceDate } = useServiceDate();
  const { clearCommentError } = useValidation();
  const setDirty = !isDirty ? { shouldDirty: true } : {};
  const emergency = watch(inputs.emergency.name);
  const speciality = watch(inputs.speciality.name);
  const invoiceType = watch(inputs.payor.name);
  const saveAsDraft = watch(inputs.saveAsDraft.name);
  const bmi = watch(inputs.bmi.name);

  const onChange = (e, shoudUpdateQuickPickList = true) => {
    // Empty value
    if (e.value && !e.value?.length) {
      // CMO-2416 - After user deselects referring practitioner number, switch By or To to N/A
      if (input.name === inputs.referral.name) {
        setValue(inputs.refToBy.name, 'N');
      }

      // On select Fee Code
      onFeeCodeSelect(e);

      // Clear error message for time inputs
      errors[inputs.startTime.name] && clearErrors(inputs.startTime.name);
      errors[inputs.endTime.name] && clearErrors(inputs.endTime.name);
      resetCode(input);
      return;
    }

    // On select from dropdown list
    if (e.value?.length) {
      // Show a warning message if the user tries to add more codes than the maximum number of entries
      if (isOverMaxEntries(e.value, input.name, isUnlimitedEntriesForFee)) {
        const maxEntries = input.name === inputs.feeCodes.name ? feeCodeMaxEntry(isUnlimitedEntriesForFee) : input.maxEntries;
        return dispatch(setToastMessage({ type: 'warn', message: `${t('Maximum_number_of_entries_is_X')} ${maxEntries}` }));
      }

      // Add code to quick pick list if it's missing
      if (shoudUpdateQuickPickList) {
        const recentCodesType = getCategoryType(input.codeType, invoiceType);
        const selectedCodes = { [recentCodesType]: e.value };
        addMissingCodesToRecentList({ selectedCodes });
      }

      // Clear error message
      if (e.value[0].value && errors[input.name]) clearErrors(input.name);

      // Check that duplicate codes are selected
      const codesOnly = e.value.map((i) => i.value);
      const set = new Set(codesOnly);
      // eslint-disable-next-line array-callback-return
      const duplicateCodes = codesOnly.filter((i) => {
        if (set.has(i)) {
          set.delete(i);
        } else {
          return i;
        }
      });

      if (duplicateCodes.length) {
        dispatch(setToastMessage({ type: 'info', message: String.format(t('already_added'), duplicateCodes[0]) }));
        return;
      }

      // On select Fee Code
      onFeeCodeSelect(e);

      // On select other codes
      if (input.name !== inputs.feeCodes.name) {
        const refToBy = watch(inputs.refToBy.name);

        // Update RefToBy if selected code is referral
        if (input.name === inputs.referral.name && refToBy === 'N') {
          setValue(inputs.refToBy.name, 'B');
        }

        return onUpdateState(e.value);
      }

      return;
    }
  };

  const onFeeCodeSelect = (e) => {
    if (input.name === inputs.feeCodes.name) {
      const currentCodes = getValues(input.name)?.length ? getValues(input.codeType) : [];
      const onAddCode = e.value.length > currentCodes?.length;
      const feeCodesOnly = map(e.value, (i) => i.value);
      const refToBy = getValues(inputs.refToBy.name);
      const startTime = getValues(inputs.startTime.name);
      const endTime = getValues(inputs.endTime.name);
      const emergency = getValues(inputs.emergency.name);
      const speciality = getValues(inputs.speciality.name);
      const locationCode = getValues(inputs.locationCode.name);
      const serviceDate = getValues(inputs.serviceDate.name);
      const preferences = localState.practitionerPrefs;
      const params = { feeCodes: e.value, refToBy, preferences, setValue, speciality, isGroup, invoiceType };

      if (e.value.length > 1) {
        feeCodeConflicts(feeCodesOnly) && dispatch(setToastMessage({ type: 'warn', message: t('Selected_cannot_be_billed') }));
      }

      // Show warn message if FeeCodes conflicts with LocationCode
      checkIfCodeValidForLocation(feeCodesOnly, locationCode, speciality);

      // CheckOffHours warn message
      validateOffHours(feeCodesOnly, startTime, serviceDate);

      if (!isGroup) {
        // CMO-2746 - Out-of-office premiums selected by user -> turn Emergency on when user adds such a code
        const isEmergency = checkIfFeeCodeNeedsEmergency(feeCodesOnly);
        if (isEmergency && !emergency) setValue(inputs.emergency.name, true);
      }

      if (isGroup) {
        const updatedRecords = [];
        const patients = watch(inputs.patient.name);

        // On add code
        if (onAddCode) {
          patients.forEach((patient) => {
            e.value?.forEach((feeCode) => {
              const record = getValues();
              const createNewRow = (patient) => createNewRecord({ record, patient, feeCode });
              const newRow = createNewRow(patient);
              // Check if the new code is in the bonus fee codes
              if (bonusFeeCodes.includes(feeCode)) {
                // If it's in the bonus fee codes, add the new row at the beginning of the records array
                updatedRecords.unshift(newRow);
              } else {
                // If it's not in the bonus fee codes, add the new row at the end of the records array
                updatedRecords.push(newRow);
              }
            });
          });
        } else {
          // On delete code
          if (localState.groupRecords?.length) {
            for (const record of localState.groupRecords) {
              if (record[inputs.feeCodes.name] && record[inputs.feeCodes.name].length) {
                const existingCode = record[inputs.feeCodes.name][0];
                const isRemovedCode = e.value.some((code) => code.value === existingCode);
                if (isRemovedCode) {
                  updatedRecords.push(record);
                }
              }
            }
          }
        }

        // Update the localState by replacing the groupRecords with the updatedRecords
        setLocalState((prevState) => ({ ...prevState, groupRecords: updatedRecords }));
      }

      // Update BMI
      if (isNew) onBMIUpdate(feeCodesOnly);

      // Update Units on edit teleplan
      if (e.value?.length && !isNew && isTimeValid(startTime) && isTimeValid(endTime)) {
        const feeCode = e.value[0];
        const percent = watch(inputs.percent.name);
        const units = calculateUnits(feeCode, startTime, endTime);
        const feeTotal = totalValue(feeCode.amount, units, percent);
        setValue(inputs.units.name, units);
        setValue(inputs.feeTotal.name, feeTotal);
      }

      // Clear error message for time inputs
      clearTimeInputsErrors(feeCodesOnly);

      // On add code
      if (onAddCode) {
        const updatedCodeList = checkFor03333Code(params);
        const addedCode = differenceBy(e.value, currentCodes, 'value');

        // VER-389 - Teleplan->first screen->Add suggestion for the service date for 36020
        const serviceDateFor36010 = localState.aditionalInfoForPatient.serviceDate;
        if (serviceDateFor36010 && addedCode?.length && addedCode[0]?.value === '36020') {
          // Add 14 weeks to service date for 36010
          const updatedServiceDateForMW = moment(serviceDateFor36010).add(weeksForMW, 'weeks');
          onChangeServiceDate(updatedServiceDateForMW);
          // Set a timeout to display an info toast message after a delay,
          // triggered by the closing of another toast message in the `validateOffHours` function above.
          setTimeout(() => {
            dispatch(setToastMessage({ type: 'info', message: t('The_date_updated_based_on_36010') }));
          }, 200);
        }

        return onUpdateState(updatedCodeList);
      }

      // On delete code
      if (!onAddCode) {
        // Check if "Referring Practitioner" field is required when adding a value to the Fees Item(s) field
        checkIfRefNumberIsRequeredOnAddingCode(params);

        // Reset values for ReasonFor01080 field if 01080 code was removes
        const removedCode = differenceBy(currentCodes, e.value, 'value');
        if (removedCode?.length && removedCode[0]?.value === '01080') setValue(inputs.reasonFor.name, []);

        // Clear error message for commen input
        clearCommentError({ feeCodes: e.value });

        return onUpdateState(e.value);
      }

      return;
    }
  };

  const clearTimeInputsErrors = (feeCodes) => {
    const isStartTimeRequired = startTimeInputRequirements(emergency, feeCodes, saveAsDraft, speciality, invoiceType);
    const isEndTimeRequired = endTimeInputRequirements(emergency, feeCodes, saveAsDraft, speciality, invoiceType);

    if (!isStartTimeRequired && errors[inputs.startTime.name]) {
      clearErrors(inputs.startTime.name);
    }

    if (!isEndTimeRequired && errors[inputs.endTime.name]) {
      clearErrors(inputs.endTime.name);
    }
  };

  const onUpdateState = (codesList = []) => {
    const codes = codesList.map((i) => i.value);
    const codesDescription = codesList.map((i) => i.label);
    setValue(input.name, codes || [], setDirty);
    setValue(input.codeDescription, codesDescription || []);

    setValue(input.codeType, codesList); // Set full code data to state

    if (input.name === inputs.feeCodes.name && !isNew) {
      const amount = codesList?.length ? codesList[0].amount : 0;
      setValue(inputs.feeAmount.name, amount);
    }
  };

  const resetCode = (input) => {
    setValue(input.name, [], setDirty);
    setValue(input.codeDescription, []);
    setValue(input.codeType, []);
  };

  const onFocus = (name) => {
    if (localState.showCatalogs.desktop && localState.focusFieldParams.name !== name) {
      const index = catalogTabs.findIndex((i) => i.name === name);
      setLocalState((prevState) => ({
        ...prevState,
        catalogIndex: index,
        focusFieldParams: catalogTabs[index]
      }));
    }
  };

  const onBMIUpdate = (feeCodesOnly) => {
    const isBMIcode = codesThatRequiresBMI.some((i) => feeCodesOnly?.includes(i));
    if ((!isBMIcode && bmi) || (isBMIcode && !bmi)) return setValue(inputs.bmi.name, defaultBMI);
  };

  return { onChange, onFocus };
};
