import { useFormContext } from 'react-hook-form';
import { t } from '../../../../../service/localization/i18n';
import { inputs } from '../helpers/inputs';

export const useValidation = () => {
  const { getValues } = useFormContext();

  const patientValidation = (value) => {
    const maxPatients = 1;

    if (!value || !value.length) {
      return t('Mandatory_field.1');
    }

    if (value.length > maxPatients) {
      return t('Limit_one_patient');
    }

    return true;
  };

  const insurerCompanyValidation = (value) => {
    return (value !== 'N/A' && value !== 'NA') || t('Mandatory_field.1');
  };

  const insurerTypeValidation = (value) => {
    return (value !== 'N/A' && value !== 'NA') || t('Mandatory_field.1');
  };

  const policyNumberValidation = (value) => {
    return !!value || t('Mandatory_field.1');
  };

  const memberIDValidation = (value) => {
    return !!value || t('Mandatory_field.1');
  };

  const memberLastNameValidation = (value) => {
    const insuredType = getValues(inputs.insuredType.name);
    if (insuredType !== 'NA' && insuredType !== 'N/A') {
      return !!value || t('Mandatory_field.1');
    }
    return true;
  };

  const memberFirstNameValidation = (value) => {
    const insuredType = getValues(inputs.insuredType.name);
    if (insuredType !== 'NA' && insuredType !== 'N/A') {
      return !!value || t('Mandatory_field.1');
    }
    return true;
  };

  const relationshipValidation = (value) => {
    const insuredType = getValues(inputs.insuredType.name);
    if (insuredType === 'FAMDEP') {
      return (value !== 'N/A' && value !== 'NA') || t('Mandatory_field.1');
    }
    return true;
  };

  return {
    memberFirstNameValidation,
    memberLastNameValidation,
    insurerCompanyValidation,
    policyNumberValidation,
    relationshipValidation,
    insurerTypeValidation,
    memberIDValidation,
    patientValidation
  };
};
