export default {
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  GET_AFFILIATES: 'GET_AFFILIATES',
  GETTING_AFFILIATES: 'GETTING_AFFILIATES',

  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',

  UPDATE_USER_FIELD: 'UPDATE_USER_FIELD',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_VIEW: 'LOGIN_VIEW',
  REDIRECT_REQUEST: 'REDIRECT_REQUEST',
  LOGIN_AS_AFFILIATE_REQUEST: 'LOGIN_AS_AFFILIATE_REQUEST',
  USER_VERIFY: 'USER_VERIFY'
};
