import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { totalValue } from '../../../helpers/totalValue';

const FeeTotal = () => {
  const name = inputs.feeTotal.name;
  const { control, watch, setValue } = useFormContext();
  const feeAmount = watch(inputs.feeAmount.name);
  const units = watch(inputs.units.name);
  const percent = units > 1 ? 100 : watch(inputs.percent.name);

  useEffect(() => {
    const feeTotal = totalValue(feeAmount, units, percent);
    setValue(inputs.feeTotal.name, feeTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeAmount, units, percent]);

  const feeTotalInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.feeTotal.label}>
        <InputNumber
          inputId={elementIDs.feeTotalInput}
          inputStyle={{ width: 'inherit' }}
          style={{ width: 'inherit' }}
          className="flex"
          type="text"
          value={field.value}
          disabled
          mode="currency"
          currency="USD"
          locale="en-US"
          onValueChange={(e) => field.onChange(e.target.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => feeTotalInput(field)} />;
};

export default FeeTotal;
