import actionTypes from './dashboard.actio.types';

export const setDashboardActiveTabIndex = (index) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_DASHBOARD_ACTIVE_TAB_INDEX,
    results: index
  });
};

export const setSearchBy = (index) => (dispatch) => {
  return dispatch({
    type: actionTypes.SET_SEARCH_BY,
    results: index
  });
};
