import { t } from '../../../service/localization/i18n';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const today = new Date();
const thisYear = today.getFullYear();
const thisMonth = months[today.getMonth()] + ' ' + today.getFullYear();

const previousYear = thisYear - 1;

export const invoicesTypesLabels = {
  all: t('All'),
  teleplan: t('Teleplan'),
  msp: t('MSP'),
  wsbc: t('WSBC'),
  wsbcr: t('WSBC_Report'),
  icbc: t('ICBC'),
  patientPay: t('Patient.1'),
  eClaims: t('eClaims')
};

export const teleplanStatusesLables = {
  all: t('All Statuses'),
  outstanding: t('Outstanding'),
  inProcess: t('In Process'),
  onHold: t('On_Hold'),
  inProcessDebit: t('In_Process_Debit'),
  accepted: t('Accepted'),
  rebilled: t('Rebilled'),
  declined: t('Declined'),
  adjusted: t('Adjusted'),
  reduced: t('Reduced'),
  draft: t('Draft'),
  archived: t('Archived'), // statuses: 9, -3
  error: t('Error'),
  paid: t('Paid'),
  processed: t('Processed'),
  drUser: t('DR User'),
  drTeleplan: t('DR Teleplan')
};

const invoiceTypes = [
  { value: '-2', label: invoicesTypesLabels.all },
  { value: 'T', label: invoicesTypesLabels.teleplan, type: 'teleplan' },
  { value: 'M', label: invoicesTypesLabels.msp },
  { value: 'W', label: invoicesTypesLabels.wsbc },
  { value: 'R', label: invoicesTypesLabels.wsbcr },
  { value: 'I', label: invoicesTypesLabels.icbc },
  { value: 'P', label: invoicesTypesLabels.patientPay, type: 'pp' },
  { value: 'E', label: invoicesTypesLabels.eClaims }
];

const reportPeriods = [
  { value: '-2', label: t('All time'), cloudTag: t('All time') },
  { value: '1', label: thisYear, cloudTag: thisYear },
  { value: '2', label: previousYear, cloudTag: previousYear },
  { value: '4', label: t('Last 3 months') },
  { value: '5', label: t('Last 6 months') },
  { value: '6', label: t('Future') },
  { value: '7', label: t('Custom period') } // not for MVP
  // { value: '8', label: t("Single_date") }
];

const remPeriods = [
  { value: '-2', label: t('All time'), cloudTag: t('All time') },
  { value: '1', label: thisYear.toString(), cloudTag: thisYear.toString() },
  { value: '2', label: previousYear.toString(), cloudTag: previousYear.toString() },
  { value: '3', label: thisMonth.toString(), cloudTag: thisMonth.toString() },
  { value: '4', label: t('Last 3 months') },
  { value: '5', label: t('Last 6 months') },
  { value: '6', label: t('Future') },
  { value: '7', label: t('Custom period') }
  // { value: '8', label: t("Single_date") }
];

/*All_Invoices_and_Claims
All Claims
All Invoices
Teleplan claims
eClaims & PBC */
const reportTypes0 = [
  { value: '-2', label: t('All_Invoices_and_Claims'), cloudTag: t('All_Invoices_and_Claims') },
  { value: '7', label: t('All Claims'), cloudTag: t('All Claims') },
  { value: '8', label: t('All Invoices'), cloudTag: t('All Invoices') },
  { value: '10', label: t('Teleplan_claims'), cloudTag: t('Teleplan_claims') },
  { value: '9', label: t('eClaims_and_PBC'), cloudTag: t('eClaims_and_PBC') }
  // { value: '1', label: t("MSP"), cloudTag: t("MSP") },
  // { value: '2', label: t("ICBC"), cloudTag: t("ICBC") },
  // { value: '3', label: t("WorkSafeBC"), cloudTag: t("WorkSafeBC") },
  // { value: '4', label: t("Private_Uninsured_Patient_Pay"), cloudTag: t("Private_Uninsured_Patient_Pay") },
  // { value: '5', label: t("Private_Uninsured_third_party_pay)"), cloudTag: t("Private_Uninsured_third_party_pay)") }
];

const reportTypes44 = [
  // { value: '-2', label: t("All_Invoices_and_Claims"), cloudTag: t("All_Invoices_and_Claims") },
  // { value: '1', label: t("MSP"), cloudTag: t("MSP") },
  // { value: '8', label: t("All Private"), cloudTag: t("All Private") },
  // { value: '9', label: t("All Extended"), cloudTag: t("All Extended") },
  // { value: '4', label: t("Private_Uninsured_Patient_Pay"), cloudTag: t("Private_Uninsured_Patient_Pay") },
  // { value: '5', label: t("Private_Uninsured_third_party_pay)"), cloudTag: t("Private_Uninsured_third_party_pay)") },
  // { value: '6', label: t("TELUS Health"), cloudTag: t("TELUS Health") },
  // { value: '7', label: t("Other Extended"), cloudTag: t("Other Extended") }
];

const reportTypes5 = [
  // { value: '-2', label: t("All_Invoices_and_Claims"), cloudTag: t("All_Invoices_and_Claims") },
  // { value: '10', label: t("All Teleplan"), cloudTag: t("All Teleplan") },
  // { value: '8', label: t("All Private"), cloudTag: t("All Private") },
  // { value: '9', label: t("All Extended"), cloudTag: t("All Extended") },
  // { value: '1', label: t("MSP"), cloudTag: t("MSP") },
  // { value: '2', label: t("ICBC"), cloudTag: t("ICBC") },
  // { value: '3', label: t("WorkSafeBC"), cloudTag: t("WorkSafeBC") },
  // { value: '4', label: t("Private_Uninsured_Patient_Pay"), cloudTag: t("Private_Uninsured_Patient_Pay") },
  // { value: '5', label: t("Private_Uninsured_third_party_pay)"), cloudTag: t("Private_Uninsured_third_party_pay)") },
  // { value: '6', label: t("TELUS Health"), cloudTag: t("TELUS Health") },
  // { value: '7', label: t("Other Extended"), cloudTag: t("Other Extended") }
];

const reportTypesOOP = [
  // { value: '-2', label: t("All_Invoices_and_Claims"), cloudTag: t("All_Invoices_and_Claims") },
  // { value: '8', label: t("All Private"), cloudTag: t("All Private") },
  // { value: '9', label: t("All Extended"), cloudTag: t("All Extended") },
  // { value: '4', label: t("Private_Uninsured_Patient_Pay"), cloudTag: t("Private_Uninsured_Patient_Pay") },
  // { value: '5', label: t("Private_Uninsured_third_party_pay)"), cloudTag: t("Private_Uninsured_third_party_pay)") },
  // { value: '6', label: t("TELUS Health"), cloudTag: t("TELUS Health") },
  // { value: '7', label: t("Other Extended"), cloudTag: t("Other Extended") }
];

const teleplanStatuses = [
  { value: '-2', label: teleplanStatusesLables.all, cloudTag: teleplanStatusesLables.all },
  { value: '0', label: teleplanStatusesLables.outstanding, cloudTag: teleplanStatusesLables.outstanding },
  { value: '1', label: teleplanStatusesLables.inProcess, cloudTag: teleplanStatusesLables.inProcess },
  { value: '2', label: teleplanStatusesLables.paid, cloudTag: teleplanStatusesLables.paid },
  { value: '3', label: teleplanStatusesLables.declined, cloudTag: teleplanStatusesLables.declined },
  { value: '200', label: teleplanStatusesLables.adjusted, cloudTag: teleplanStatusesLables.adjusted },
  { value: '4', label: teleplanStatusesLables.draft, cloudTag: teleplanStatusesLables.draft },
  { value: '9', label: teleplanStatusesLables.archived, cloudTag: teleplanStatusesLables.archived }
];

const privateStatuses = [
  { value: '-2', label: teleplanStatusesLables.all, cloudTag: teleplanStatusesLables.all },
  { value: '4', label: teleplanStatusesLables.draft, cloudTag: teleplanStatusesLables.draft },
  { value: '0', label: teleplanStatusesLables.outstanding, cloudTag: teleplanStatusesLables.outstanding },
  { value: '2', label: teleplanStatusesLables.paid, cloudTag: teleplanStatusesLables.paid }
];

const eclaimStatuses = [
  { value: '-2', label: teleplanStatusesLables.all, cloudTag: teleplanStatusesLables.all },
  { value: '0', label: teleplanStatusesLables.draft, cloudTag: teleplanStatusesLables.draft },
  { value: '1', label: teleplanStatusesLables.inProcess, cloudTag: teleplanStatusesLables.inProcess },
  { value: '2', label: teleplanStatusesLables.processed, cloudTag: teleplanStatusesLables.processed },
  { value: '4', label: teleplanStatusesLables.error, cloudTag: teleplanStatusesLables.error }
];

const allStatuses = [
  { value: '-2', label: teleplanStatusesLables.all, cloudTag: teleplanStatusesLables.all },
  { value: '0', label: teleplanStatusesLables.outstanding, cloudTag: teleplanStatusesLables.outstanding },
  { value: '1', label: teleplanStatusesLables.inProcess, cloudTag: teleplanStatusesLables.inProcess },
  { value: '104', label: teleplanStatusesLables.onHold, cloudTag: teleplanStatusesLables.onHold },
  { value: '203', label: teleplanStatusesLables.inProcessDebit, cloudTag: teleplanStatusesLables.inProcessDebit },
  { value: '2', label: teleplanStatusesLables.paid, cloudTag: teleplanStatusesLables.paid },
  { value: '27', label: teleplanStatusesLables.accepted, cloudTag: teleplanStatusesLables.accepted },
  { value: '21', label: teleplanStatusesLables.rebilled, cloudTag: teleplanStatusesLables.rebilled },
  { value: '3', label: teleplanStatusesLables.declined, cloudTag: teleplanStatusesLables.declined },
  { value: '4', label: teleplanStatusesLables.draft, cloudTag: teleplanStatusesLables.draft },
  { value: '200', label: teleplanStatusesLables.adjusted, cloudTag: teleplanStatusesLables.adjusted },
  { value: '1234', label: teleplanStatusesLables.reduced, cloudTag: teleplanStatusesLables.reduced }, // VER-192 - Money table->status filter->Add new drop down item - 'Reduced'
  { value: '201', label: teleplanStatusesLables.drUser, cloudTag: teleplanStatusesLables.drUser },
  { value: '202', label: teleplanStatusesLables.drTeleplan, cloudTag: teleplanStatusesLables.drTeleplan },
  //{ value: '6', label: t("Not Paid"), cloudTag: t("Not Paid") },
  { value: '9', label: teleplanStatusesLables.archived, cloudTag: teleplanStatusesLables.archived }, // status numbers that mean archived 9, -1, -3
  { value: '10', label: teleplanStatusesLables.error, cloudTag: teleplanStatusesLables.error }
];

const anatomicList = [
  { name: t('N_Not_Applicable'), label: 'N', text: t('N_A') },
  { name: t('L_Left'), label: 'L', text: t('Left') },
  { name: t('R_Right'), label: 'R', text: t('Right') },
  { name: t('B_Both'), label: 'B', text: t('Both') }
];

const payeeList = [
  { name: t('MSP'), label: 'MSP', short: 'M', text: t('MSP') },
  { name: t('WorkSafeBC'), label: 'WSBC', short: 'W', text: t('WSBC') },
  { name: t('ICBC'), label: 'ICBC', short: 'I', text: t('ICBC') }

  //Future relase
  // { name: t("Patient Pay"), label: "PP" }
];

const submissionCodes = [
  { value: '0', label: t('Normal_Submission.1'), text: t('Normal_Submission.2') },
  { value: 'I', label: t('ICBC_Claim.1'), text: t('ICBC_Claim.2') },
  { value: 'W', label: t('Declined_WSBC_Claim.1'), text: t('Declined_WSBC_Claim.2') },
  { value: 'A', label: t('Over-age_Claim.1'), text: t('Over-age_Claim.2') },
  { value: 'X', label: t('Declined_Claim.1'), text: t('Declined_Claim.2') },
  { value: 'C', label: t('Coverage_Problem.1'), text: t('Coverage_Problem.2') },
  { value: 'D', label: t('Duplicate_Claim.1'), text: t('Duplicate_Claim.2') },
  { value: 'R', label: t('Re-Submitted_Claim.1'), text: t('Re-Submitted_Claim.2') }
];

const TeleplanNoExtended = [0, 1, 2, 3, 8, 16, 18, 19, 39, 47];
const TeleplanWithExtended = [-1, 4, 5, 14];
const NoWCBWithExtended = [39, 44, 80, 87];

export {
  invoiceTypes,
  remPeriods,
  reportPeriods,
  reportTypesOOP,
  reportTypes0,
  reportTypes5,
  reportTypes44,
  teleplanStatuses,
  privateStatuses,
  eclaimStatuses,
  allStatuses,
  TeleplanNoExtended,
  TeleplanWithExtended,
  NoWCBWithExtended,
  anatomicList,
  payeeList,
  submissionCodes
};
