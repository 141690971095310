import React from 'react';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const PatientsCout = () => {
  const { watch } = useFormContext();
  const patientsCount = watch(inputs.patient.name)?.length;

  if (!patientsCount) return null;

  return (
    <div className="w-max" style={{ minWidth: '90px' }}>
      {`${patientsCount} ${t('Patient.2').toLowerCase()}`}
    </div>
  );
};

export default PatientsCout;
