import axios from 'axios';
import actions from '../actions/practitioners.action.types';
import { getApiBase, setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { t } from '../../../service/localization/i18n';
import { createNewReferralResponseAction } from './responceAction';
import newPractitionerResponce from '../../../mocks/db/practitioners/newPractitioner/newPractitionerResponce.json';
import { store } from '../../../store';

export const setPractitioners = (practitioners) => (dispatch) => {
  return dispatch({
    type: actions.SET_PRACTITIONERS,
    results: practitioners
  });
};

export const createNewPractitioner = (data, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: true });

  axios
    .post(`${getApiBase()}/users/${DCNGuid}`, data)
    .then((results) => {
      dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: false });
      callback && callback(results.data);
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const deletePractitioner = (practitionerId, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: true });

  axios
    .delete(`${getApiBase()}/users/${DCNGuid}/${practitionerId}`)
    .then(() => {
      callback && callback();
      dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: false });
      dispatch(setToastMessage({ type: 'success', message: t('Practitioner_is_deleted') }));
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_PRACTITIONER, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const createNewReferral = (data, callback) => (dispatch, state) => {
  if (process.env.REACT_APP_CYPRESS) return createNewReferralResponseAction(newPractitionerResponce, callback);

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  dispatch({ type: actions.IS_FETCHING_REFERRAL, results: true });

  axios
    .post(`${getApiBase()}/lookup/referral/${DCNGuid}`, data)
    .then((results) => {
      dispatch({ type: actions.IS_FETCHING_REFERRAL, results: false });
      createNewReferralResponseAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_REFERRAL, results: false });
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const deleteReferral = (number) => {
  if (process.env.REACT_APP_CYPRESS) return true;

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .delete(`${getApiBase()}/lookup/referral/${DCNGuid}/${number}`)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log('!!!getReferralCodesByLetter', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
