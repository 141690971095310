import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import GroupStepper from '../../Layouts/GroupStepper';

import { getPractitionerNameWithSpec } from '../../../../../../../utils/getPractitioner';
import { elementIDs } from '../../../../../../../config/elementIDsConfig';
import { inputs } from '../../../../helpers/inputs';
import { usePractitioner } from '../../../../hooks/usePractitioner';
import { formatDate } from '../../../../../../../utils/formatDate';
import { t } from '../../../../../../../../service/localization/i18n';

const FeeCodesLayout = () => {
  const { watch } = useFormContext();
  const feeCodesList = watch(inputs.feeCodes.codeType);
  const feeCodes = watch(inputs.feeCodes.codeType)?.map((i) => i.value);

  return (
    <>
      <Tooltip target=".groupPickerFeeTooltip" position="top">
        {feeCodesList?.map((i, index) => (
          <div key={`${i.value}_${index}`} className="pb-2">
            {`${i.label}`}
          </div>
        ))}
      </Tooltip>

      <span className="groupPickerFeeTooltip font-bold">{feeCodes?.join()?.replaceAll(',', ', ')}</span>
    </>
  );
};

const GroupPickerHeader = () => {
  const practitioners = useSelector((state) => state.clinic.members);
  const { watch } = useFormContext();
  const { practitionerOptions } = usePractitioner();
  const practitioenr = watch(inputs.practitioner.name);
  const gapStyles = { rowGap: '1.5rem', columnGap: '1.5rem' };

  const serviseDateChipTemplate = <>{formatDate(watch(inputs.serviceDate.name))}</>;

  const treatedBy = () => {
    const currentPreactitioner = practitioners.find((i) => i.DoctorGuid?.toUpperCase() === practitioenr?.toUpperCase());
    return getPractitionerNameWithSpec(currentPreactitioner, practitionerOptions);
  };

  return (
    <div className="flex flex-column lg:flex-row w-full justify-content-between" style={gapStyles}>
      <GroupStepper />

      <div className="flex flex-column md:flex-row align-items-center" style={gapStyles}>
        <div className="flex align-items-center">
          <span id={elementIDs.teleplanRecordsTablePractitionerName} className="font-bold">
            {treatedBy()}
          </span>
        </div>

        <div className="flex align-items-center">
          <span id={elementIDs.teleplanRecordsTableDOSLabel} className="mr-2 font-normal">
            {`${t('DOS')}: `}
          </span>
          <Message id={elementIDs.teleplanRecordsTableDOS} severity="info" content={serviseDateChipTemplate} />
        </div>

        {/* <div className='flex align-items-center'>
          <span className='mr-1 font-normal'>
            {`${t('Fee Code')}: `}
          </span>
          <FeeCodesLayout />
        </div> */}
      </div>
    </div>
  );
};

export default GroupPickerHeader;
