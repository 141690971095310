import React from 'react';
import { useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../../../../components/Inputs';
import CheckEligibilityOnly from '../../../../../../components/Buttons/CheckEligibility/CheckEligibilityOnly';
import CheckNameAndEligibility from '../../../../../../components/Buttons/CheckEligibility/CheckNameAndEligibility';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';
import { isEmpty } from 'lodash';
import { useLastName } from '../../hooks/useLastName';
import { colors } from '../../../../../config/stylesConfig';
import { formatDate } from '../../../../../utils/formatDate';
import { t } from '../../../../../../service/localization/i18n';

const CheckEligibilityButtons = () => {
  const { duplicatePatientCheckCallback } = useLastName();
  const { watch, localState, setLocalState, isNew, setValue } = useFormContext();
  const buttonWrapStyles = { width: '100%', paddingBottom: '25px' };

  const patientDataForEligibilityOnly = {
    PHN: watch(inputs.phn.name),
    PHNProvince: watch(inputs.phnProvince.name),
    BirthDay: watch(inputs.birthDay.name)
  };

  const patientDataForNameAndEligibility = {
    PHN: watch(inputs.phn.name),
    PHNProvince: watch(inputs.phnProvince.name),
    BirthDay: watch(inputs.birthDay.name),
    FirstName: watch(inputs.firstName.name),
    LastName: watch(inputs.lastName.name),
    MidName: watch(inputs.midName.name),
    Sex: watch(inputs.gender.name),
    DCNGuid: watch(inputs.dcnguid.name)
  };

  const handleCheckEligibilityOnly = (eligibility) => {
    setLocalState((prevValue) => ({ ...prevValue, eligibility }));

    if (eligibility?.Parsed && !isEmpty(eligibility.Parsed)) {
      setLocalState((prevState) => ({
        ...prevState,
        eligibility: eligibility.Parsed
      }));
    } else {
      setLocalState((prevState) => ({
        ...prevState,
        eligibility: { noEligibilityReport: true }
      }));
    }
  };

  const getEligibilityMessage = () => {
    const eligibility = localState?.eligibility;
    const checkOnDate = formatDate(eligibility?.CHECK_ON_DATE);

    if (eligibility?.ELIG_ON_DOS?.toUpperCase() === 'YES') {
      return { color: colors.success, message: `${t('Active_on')} ${checkOnDate}` };
    }

    if (eligibility?.ELIG_ON_DOS?.toUpperCase() === 'NO') {
      return { color: colors.danger, message: `${t('Inactive_on')} ${checkOnDate}` };
    }

    if (eligibility?.ELIG_ON_DOS === '') {
      return { color: colors.warning, message: `${t('Undetermined_on')} ${checkOnDate}` };
    }
  };

  const lableContent = (
    <div className="flex justify-content-between w-full">
      <span>{t('Check_MSP')}</span>
      <span id={elementIDs.eligibilityMessage} style={{ color: getEligibilityMessage()?.color }}>
        {getEligibilityMessage()?.message}
      </span>
    </div>
  );

  return (
    <InputWrapper style={buttonWrapStyles} lableContent={lableContent} labelStyle={{ width: '100%' }}>
      <div className="two_inputs_in_a_row">
        <CheckNameAndEligibility
          className="w-full p-button-outlined"
          isNew={isNew}
          isPHNValid={localState.isPHNValid}
          patientData={patientDataForNameAndEligibility}
          setValue={setValue}
          onClick={handleCheckEligibilityOnly}
          duplicatePatientCheckCallback={duplicatePatientCheckCallback}
        />

        <CheckEligibilityOnly
          className="w-full p-button-outlined"
          isPHNValid={localState.isPHNValid}
          patientData={patientDataForEligibilityOnly}
          onClick={handleCheckEligibilityOnly}
        />
      </div>
    </InputWrapper>
  );
};

export default CheckEligibilityButtons;
