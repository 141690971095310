import React from 'react';
import Asterisk from '../../../../../../../../../components/Misc/Asterisk/Asterisk';
import { inputs } from '../../../../../helpers/inputs';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';

const AsterisksForTimeInput = ({ rowOptions, inputName }) => {
  const { showAsterisksForTimeInput } = useValidateGoupPicker();
  const feeCodes = rowOptions.rowData[inputs.feeCodes.codeType];
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const showAsterisks = showAsterisksForTimeInput(inputName, feeCodes, rowId);

  if (!showAsterisks) return <div style={{ width: '6px' }}></div>;

  return <Asterisk tooltipId={`${inputName}_${rowId}`} />;
};

export default AsterisksForTimeInput;
