import { store } from '../../../../../store';
import { getInvoicesCatalogMostRecentCodes } from '../../../actions/claims.action.creators';
import { addMissingCodesToRecentList } from './updateRecentCodesList';

export const getMostRecentCodes = (formState, { patientGuid, setLocalState } = {}) => {
  // Get most recent catalog codes
  setLocalState && setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: true }));

  store.dispatch(
    getInvoicesCatalogMostRecentCodes({
      patientGuid: patientGuid || '',
      updateRedux: false,
      callback: (recentCodesList) => {
        addMissingCodesToRecentList({ formState, recentCodesList });
        setLocalState && setLocalState((prevState) => ({ ...prevState, gettingRecentCodes: false }));
      }
    })
  );
};
