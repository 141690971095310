import React from 'react';
import { Button } from 'primereact/button';
import { inputs } from '../../claimDetailsHelpers/inputs';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';

const NotesSection = ({ data, setShowPaymentsDialog }) => {
  const paymentLatestDate = () => new Date(Math.max(...data?.Payments?.map((i) => new Date(i.PaymentDate))));

  return (
    <>
      <div className="invoice-notes h-full">
        <div className="flex flex-column justify-content-between h-full">
          <div>{data[inputs().messageOnInvoice.name] && <div className="pr-6">{data[inputs().messageOnInvoice.name]}</div>}</div>

          {data?.Payments?.length > 0 && (
            <div className="flex align-items-center">
              <Button
                className="p-button-link p-0 mr-1"
                label={`${data?.Payments?.length} ${data?.Payments?.length > 1 ? t('Payments.1').toLowerCase() : t('Payments.2').toLowerCase()}`}
                aria-haspopup
                aria-controls="overlay_panel"
                onClick={() => setShowPaymentsDialog(true)}
              />
              <div className="flex align-items-center ml-1" style={{ marginBottom: '2px', fontSize: '1.1rem' }}>
                {`${t('made_on')} ${moment(paymentLatestDate()).format('MM/DD/YYYY')}`}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotesSection;
