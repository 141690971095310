import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RadioButton } from 'primereact/radiobutton';
import { InputWrapper } from '../../../../../../../../components/Inputs';

import { requiredInputs } from '../../../../helpers/inputs';
import { t } from '../../../../../../../../service/localization/i18n';

// interface = {
//   input: {
//     name: string,
//     label: string
//   },
//   inputWrapperStyle?: any
// };

const YesNoRadio = ({ input, inputWrapperStyle = { height: '110px' } }) => {
  const name = input.name;
  const { errors, control } = useFormContext();

  const radioButtons = (field) => {
    return (
      <InputWrapper name={name} label={input.label} required={!!requiredInputs[name]} errorMessage={errors[name]?.message} style={inputWrapperStyle}>
        <div className="flex radio_buttons_wrap">
          <div className="field-radiobutton mb-1 pr-5">
            <RadioButton
              inputRef={field.ref}
              id={`${field.name}_yes`}
              name={field.name}
              inputId="Yes"
              value="1"
              checked={field.value === '1'}
              onChange={field.onChange}
            />
            <label htmlFor={field.name}>{t('Yes')}</label>
          </div>

          <div className="field-radiobutton mb-1">
            <RadioButton id={`${field.name}_no`} name={field.name} inputId="No" value="0" checked={field.value === '0'} onChange={field.onChange} />
            <label htmlFor={field.name}>{t('No')}</label>
          </div>
        </div>
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => radioButtons(field)} />;
};

export default YesNoRadio;
