import { t } from '../../service/localization/i18n';

export const address_types = [
  { label: t('Mailing'), value: 'M' },
  { label: t('Residence'), value: 'R' },
  { label: t('Facility'), value: 'F' }
];
export const email_types = [
  { label: t('Personal'), value: 'P' },
  { label: t('Business'), value: 'B' },
  { label: t('Other'), value: 'O' }
];
export const phone_types = [
  { label: t('Cell'), value: 'C' },
  { label: t('Home'), value: 'H' },
  { label: t('Business'), value: 'B' },
  { label: t('Other'), value: 'O' }
];
// MSP accepts "AB", "BC", "SK", "MB", "ON", "NB", "NS", "PE", "NF", "NT", "YT", "NU"
export const provinces_list = [
  { label: t('Alberta'), value: 'AB' },
  { label: t('British_Columbia'), value: 'BC' },
  { label: t('Manitoba'), value: 'MB' },
  { label: t('New_Brunswick'), value: 'NB' },
  { label: t('Newfoundland_and_Labrador'), value: 'NF' },
  { label: t('Northwest_Territories'), value: 'NT' },
  { label: t('Nova_Scotia'), value: 'NS' },
  { label: t('Nunavut'), value: 'NU' },
  { label: t('Ontario'), value: 'ON' },
  { label: t('Prince_Edward_Island'), value: 'PE' },
  { label: t('Quebec'), value: 'QC' },
  { label: t('Saskatchewan'), value: 'SK' },
  { label: t('Yukon'), value: 'YT' },
  { label: t('Institutional'), value: 'Institutional' }
];

export const location_codes = [
  { value: 'C', label: t('Residential_Care') },
  { value: 'D', label: t('Diagnostic_Facility') },
  { value: 'E', label: t('Hospital_Emergency_Room') },
  { value: 'I', label: t('Hospital_Inpatient') },
  { value: 'P', label: t('Hospital_Outpatient') },
  { value: 'R', label: t('Patient_Private_Home') },
  { value: 'Z', label: t('Other_Z') },
  { value: 'M', label: t('Mental_Health_Center') },
  { value: 'T', label: t('Practitioner_Office_Publicly_Administered') },
  { value: 'G', label: t('Hospital_Day_Care') },
  { value: 'B', label: t('Community_Health_Center') },
  { value: 'J', label: t('FN_Primary_Clinic') },
  { value: 'K', label: t('Hybrid_Primary_Care') },
  { value: 'L', label: t('Longitudinal_Primary_Care') },
  { value: 'N', label: t('Practitioner_Office_NonPhysician') },
  { value: 'Q', label: t('Specialist_Physician_Office') },
  { value: 'U', label: t('Urgent_and_Primary_Care _Centre') },
  { value: 'V', label: t('Virtual_Care_Clinic') },
  { value: 'W', label: t('Walk_In_Clinic') },
  { value: 'F', label: t('Private_Medical_Surgical_Facility') }
];

export const rural_codes_only = [
  { value: 'A6', label: 'AGASSIZ/HARRISON' },
  { value: 'A4', label: 'AHOUSAT' },
  { value: 'A1', label: 'ALERT BAY' },
  { value: 'AC', label: 'ALEXIS CREEK' },
  { value: 'A5', label: 'ANAHIM LAKE' },
  { value: 'A7', label: 'ARMSTRONG/SPALLUMCHEEN' },
  { value: 'A3', label: 'ASHCROFT' },
  { value: 'A2', label: 'ATLIN' },
  { value: 'BA', label: 'BALFOUR' },
  { value: 'B8', label: 'BAMFIELD' },
  { value: 'B4', label: 'BARRIERE' },
  { value: 'B3', label: 'BELLA COOLA' },
  { value: 'B7', label: 'BIG WHITE' },
  { value: 'BB', label: 'BLIND BAY' },
  { value: 'B5', label: 'BLUE RIVER' },
  { value: 'B9', label: 'BLUEBERRY RIVER' },
  { value: 'B6', label: 'BOWEN ISLAND' },
  { value: 'B1', label: 'BRIDGE LAKE' },
  { value: 'B2', label: 'BURNS LAKE/FRANCOIS LAKE' },
  { value: 'CR', label: 'CAMPBELL RIVER' },
  { value: 'C5', label: 'CANAL FLATS' },
  { value: 'CN', label: 'CANOE CREEK' },
  { value: 'CA', label: 'CASTLEGAR' },
  { value: 'CH', label: 'CHASE/SCOTCH CREEK' },
  { value: 'CM', label: 'CHEMAINUS' },
  { value: 'CS', label: 'CHESLATTA' },
  { value: 'C2', label: 'CHETWYND/SAULTEAU' },
  { value: 'C7', label: 'CHRISTINA LAKE/GRAND FORKS' },
  { value: 'C8', label: 'CLEARWATER' },
  { value: 'C3', label: 'CLINTON' },
  { value: 'CE', label: 'COBBLE HILL' },
  { value: 'C4', label: 'CORTES ISLAND' },
  { value: 'CC', label: 'COURTENAY/COMOX/CUMBERLAN' },
  { value: 'CB', label: 'CRANBROOK' },
  { value: 'CV', label: 'CRESCENT VALLEY' },
  { value: 'C6', label: 'CRESTON' },
  { value: 'D1', label: 'DAWSON CREEK' },
  { value: 'D3', label: 'DEASE LAKE' },
  { value: 'D2', label: 'DENMAN ISLAND' },
  { value: 'D5', label: 'DOIG RIVER' },
  { value: 'D4', label: 'DUNCAN/NORTH COWICHAN' },
  { value: 'E2', label: 'EDGEWOOD' },
  { value: 'E1', label: 'ELKFORD' },
  { value: 'E3', label: 'ENDERBY' },
  { value: 'F1', label: 'FERNIE' },
  { value: 'F7', label: 'FORT BABINE' },
  { value: 'F2', label: 'FORT NELSON' },
  { value: 'F3', label: 'FORT ST JAMES' },
  { value: 'F4', label: 'FORT ST JOHN' },
  { value: 'F6', label: 'FORT WARE' },
  { value: 'F5', label: 'FRASER LAKE' },
  { value: 'G7', label: 'GABRIOLA ISLAND' },
  { value: 'G5', label: 'GALIANO ISLAND' },
  { value: 'G6', label: 'GOLD BRIDGE/BRALORNE' },
  { value: 'G2', label: 'GOLD RIVER' },
  { value: 'G1', label: 'GOLDEN' },
  { value: 'G4', label: 'GRANISLE' },
  { value: 'G8', label: 'GRASSY PLAINS' },
  { value: 'G3', label: 'GREENWOOD/MIDWAY/ROCK CRE' },
  { value: 'H9', label: 'HALFWAY RIVER' },
  { value: 'H6', label: 'HARTLEY BAY' },
  { value: 'H1', label: 'HAZELTON' },
  { value: 'H2', label: 'HOLBERG' },
  { value: 'H8', label: 'HOPE' },
  { value: 'H5', label: 'HORNBY ISLAND' },
  { value: 'H7', label: 'HOT SPRINGS COVE' },
  { value: 'H4', label: 'HOUSTON' },
  { value: 'H3', label: "HUDSON'S HOPE" },
  { value: 'VM', label: 'INVERMERE' },
  { value: 'K1', label: 'KASLO' },
  { value: 'K8', label: 'KEREMEOS' },
  { value: 'KM', label: 'KIMBERLEY' },
  { value: 'KK', label: 'KINCOLITH' },
  { value: 'K6', label: 'KINGCOME' },
  { value: 'K2', label: 'KITIMAT' },
  { value: 'K9', label: 'KITKATLA' },
  { value: 'K3', label: 'KITSAULT' },
  { value: 'K4', label: 'KITWANGA' },
  { value: 'KL', label: 'KLEMTU' },
  { value: 'K5', label: 'KOOTENAY BAY/RIONDEL' },
  { value: 'K7', label: 'KYUQUOT' },
  { value: 'L4', label: 'LADYSMITH' },
  { value: 'L5', label: 'LAKE COWICHAN' },
  { value: 'L1', label: 'LILLOOET' },
  { value: 'L3', label: 'LOGAN LAKE' },
  { value: 'L7', label: 'LOWER POST' },
  { value: 'L6', label: 'LUMBY' },
  { value: 'L2', label: 'LYTTON' },
  { value: 'M1', label: 'MACKENZIE' },
  { value: 'M5', label: 'MADEIRA PARK' },
  { value: 'M3', label: 'MASSET' },
  { value: 'M7', label: 'MAYNE ISLAND' },
  { value: 'M2', label: 'MCBRIDE' },
  { value: 'M4', label: 'MERRITT' },
  { value: 'M8', label: 'METLAKATLA' },
  { value: 'MB', label: 'MILL BAY' },
  { value: 'M6', label: 'MIOCENE' },
  { value: 'MT', label: 'MORICETOWN' },
  { value: 'MC', label: 'MOUNT CURRIE' },
  { value: 'NA', label: 'NADLEH' },
  { value: 'N1', label: 'NAKUSP' },
  { value: 'NT', label: 'NEE TAHI BUHN' },
  { value: 'N5', label: 'NELSON' },
  { value: 'NV', label: 'NEMAIAH VALLEY' },
  { value: 'N2', label: 'NEW AIYANSH' },
  { value: 'N3', label: 'NEW DENVER' },
  { value: 'N4', label: 'NITINAT' },
  { value: 'CF', label: 'OCEAN FALLS' },
  { value: 'OL', label: 'OLIVER' },
  { value: 'LS', label: 'OLIVER/OSOYOOS' },
  { value: 'OS', label: 'OSOYOOS' },
  { value: 'PQ', label: 'PARKSVILLE/QUALICUM' },
  { value: 'P1', label: 'PEMBERTON' },
  { value: 'P8', label: 'PENDER ISLAND' },
  { value: 'PI', label: 'PENELAKUT ISLAND' },
  { value: 'PA', label: 'PORT ALBERNI' },
  { value: 'P2', label: 'PORT ALICE' },
  { value: 'P6', label: 'PORT CLEMENTS' },
  { value: 'P3', label: 'PORT HARDY' },
  { value: 'P4', label: 'PORT MCNEILL' },
  { value: 'PN', label: 'PORT RENFREW' },
  { value: 'P9', label: 'PORT SIMPSON' },
  { value: 'PR', label: 'POWELL RIVER' },
  { value: 'PG', label: 'PRINCE GEORGE' },
  { value: 'P5', label: 'PRINCE RUPERT' },
  { value: 'P7', label: 'PRINCETON' },
  { value: 'Q1', label: 'QUADRA ISLAND' },
  { value: 'Q4', label: 'QUATSINO' },
  { value: 'Q2', label: 'QUEEN CHARLOTTE' },
  { value: 'Q3', label: 'QUESNEL' },
  { value: 'R2', label: 'REDSTONE RESERVE' },
  { value: 'R1', label: 'REVELSTOKE' },
  { value: 'R3', label: 'RIVERS INLET' },
  { value: 'SZ', label: "SAIK'UZ" },
  { value: 'S5', label: 'SALMO' },
  { value: 'SA', label: 'SALMON ARM' },
  { value: 'SS', label: 'SALTSPRING ISLAND' },
  { value: 'SC', label: 'SAMAHQUAM' },
  { value: 'S7', label: 'SATURNA ISLAND' },
  { value: 'SV', label: 'SAVORY ISLAND' },
  { value: 'S1', label: 'SAYWARD' },
  { value: 'SG', label: 'SECHELT/GIBSONS' },
  { value: 'SP', label: 'SETON PORTAGE' },
  { value: 'SL', label: 'SHAWNIGAN LAKE' },
  { value: 'SI', label: 'SIDAR' },
  { value: 'SO', label: 'SKATIN' },
  { value: 'SN', label: 'SKIN TYEE' },
  { value: 'S8', label: 'SLOCAN PARK' },
  { value: 'S2', label: 'SMITHERS' },
  { value: 'S6', label: 'SOINTULA' },
  { value: 'SK', label: 'SOOKE' },
  { value: 'S9', label: 'SORRENTO' },
  { value: 'S3', label: 'SPARWOOD' },
  { value: 'SB', label: 'SPENCES BRIDE' },
  { value: 'SQ', label: 'SQUAMISH' },
  { value: 'ST', label: "STELLAT'EN" },
  { value: 'S4', label: 'STEWART' },
  { value: 'SU', label: 'SUMMERLAND' },
  { value: 'SE', label: 'SUN PEAKS' },
  { value: 'T9', label: 'TACHET' },
  { value: 'T2', label: 'TAHSIS' },
  { value: 'T6', label: 'TAKLA LANDING' },
  { value: 'TL', label: 'TATLA LAKE' },
  { value: 'T8', label: 'TATLAYOKO LAKE' },
  { value: 'TC', label: 'TELEGRAPH CREEK' },
  { value: 'TP', label: 'TEPPELLA' },
  { value: 'T3', label: 'TERRACE' },
  { value: 'T1', label: 'TEXADA ISLAND' },
  { value: 'T4', label: 'TOFINO' },
  { value: 'TR', label: 'TRAIL' },
  { value: 'TK', label: "TS'IL KAZ KOH (BURNS LAKE BAND)" },
  { value: 'T7', label: 'TSAY KEH' },
  { value: 'T5', label: 'TUMBLER RIDGE' },
  { value: 'U1', label: 'UCLUELET' },
  { value: 'V2', label: 'VALEMOUNT' },
  { value: 'V1', label: 'VANDERHOOF' },
  { value: 'W4', label: 'WAGLISLA' },
  { value: 'W5', label: 'WARDNER' },
  { value: 'W8', label: 'WASA' },
  { value: 'WE', label: "WET'SUWET'EN (BROMAN LAKE)" },
  { value: 'W6', label: 'WHISTLER' },
  { value: 'W7', label: 'WILLIAMS LAKE' },
  { value: 'W3', label: 'WINLAW' },
  { value: 'W1', label: 'WOSS' },
  { value: 'WY', label: 'WOYENNE (LAKE BABINE)' },
  { value: 'Y1', label: 'YEKOOCHE' },
  { value: 'Z1', label: 'ZEBALLOS' },
  { value: 'MH', label: '100 MILE HOUSE' }
];

export const rural_codes = [{ value: 'N/A', label: 'N/A' }, ...rural_codes_only];
