import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import QuickPickItem from './QuickPickItem';

// interface = {
//   item: {
//     name: string,
//     label: string,
//     codeDescription: string,
//     codeType: string,
//   }
//  showDivider?: boolean
// }

const QuickPickItemsBlock = (props) => {
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const mostRecentCodes = catalogMostRecentCodes[props.item.codeType];

  return (
    <>
      <div className="quickPickBlockWrap">
        {/* Label */}
        <div className="pb-2">
          <div className="large-text" style={{ width: '155px' }}>
            {`${props.item.label}:`}
            <span className="ml-1 p-error">*</span>
          </div>
        </div>

        <div id={`${props.item.name}_items`}>
          <QuickPickItem item={props.item} mostRecentCodes={mostRecentCodes} showActionButtons />
        </div>
      </div>

      {props.showDivider && <Divider className="my-3" />}
    </>
  );
};

export default QuickPickItemsBlock;
