import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedNameInput } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const FirstName = () => {
  const name = inputs.firstName.name;
  const { errors, control } = useFormContext();

  const nameInput = (field) => {
    return (
      <WrappedNameInput
        {...field}
        input={inputs.firstName}
        errorMessage={errors[name]?.message}
        required
        onChange={(value) => field.onChange(value)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => nameInput(field)} />;
};

export default FirstName;
