import { useFormContext } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { sortByAlphabetical } from '../../../../utils/sortBy';
import { defaultGuid } from '../../../../config/defaultValuesConfig';
import { localStorageKeys } from '../../../../config/localStorageKeysConfig';
import { t } from '../../../../../service/localization/i18n';

export const useInsuranceCompany = () => {
  const { control, setValue, clearErrors } = useFormContext();
  const isPrimary = useWatch({ control, name: inputs.isPrimary.name });
  const key = localStorageKeys.invoicesCatalogCategories;
  const catalogCategories = JSON.parse(localStorage.getItem(key));

  const options = () => {
    const naOption = { name: 'NA', label: t('N_A'), insurerGuid: defaultGuid };

    if (!catalogCategories?.insurers_primary || !catalogCategories?.insurers_all) {
      return [naOption];
    }

    if (isPrimary) {
      const formattedPrimary = formatInsurers(catalogCategories?.insurers_primary);
      return [naOption, ...formattedPrimary];
    }

    if (!isPrimary) {
      const formattedAll = formatInsurers(catalogCategories?.insurers_all);
      return [naOption, ...formattedAll];
    }
  };

  const formatInsurers = (insurersList) => {
    const formattedList = insurersList.map((i) => ({ name: i.id, label: i.label, insurerGuid: i.value }));
    return sortByAlphabetical(formattedList, 'label');
  };

  const onSelect = (value) => {
    setValue(inputs.insurerID.name, value.name, { shouldDirty: true });
    setValue(inputs.insurerNameEN.name, value.label);
    setValue(inputs.insurerGuid.name, value.insurerGuid);
    clearErrors(inputs.insurerID.name); // Clear error message
  };

  return { options: options(), onSelect };
};
