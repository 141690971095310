import React from 'react';
import { t } from '../../../service/localization/i18n';

const FacilityLayout = ({ data }) => {
  const { facility, subFacility } = data;
  let facilityText = '';

  if (!facility && !subFacility) return null;

  if (facility && subFacility) {
    facilityText = `${t('Facility')}/${t('Sub_Facility')}: ${facility}/${subFacility}`;
  }

  if (facility && !subFacility) {
    facilityText = `${t('Facility')}: ${facility}`;
  }

  return <div className="white-space-normal">{facilityText}</div>;
};

export default FacilityLayout;
