import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import { InputWrapper } from '../../../../../../../components/Inputs';
import { ClaimNumber as ClaimNumberInput } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { invoiceTypes } from '../../../helpers/defaultValues';
import { usePatient } from '../../../hooks/usePatient';
import { icons } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const ClaimNumber = () => {
  const name = inputs.claimNumber.name;
  const { control, setValue, errors, isNew, localState } = useFormContext();
  const patientClaims = useWatch({ control, name: inputs.patientClaims.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const { updateStateForWSBC, updateStateForICBC, resetInputValues } = usePatient();
  const isICBC = invoiceType === invoiceTypes.icbc;
  const errorMessage = errors[name]?.message;
  const maxLength = isICBC ? 8 : 69;
  const delay = isICBC ? 700 : 0;

  const onChange = (e) => {
    const _claimNumber = e.value?.value || e.value;
    const shouldDirty = e.onCreateNewClaim || !patientClaims?.WCB?.length ? { shouldDirty: true } : {};
    setValue(e.field, _claimNumber, shouldDirty);
  };

  const onUpdateInitialState = (claim) => {
    if (invoiceType === invoiceTypes.wsbc) return updateStateForWSBC(claim);
    if (invoiceType === invoiceTypes.icbc) return updateStateForICBC(claim);
  };

  const onClear = () => {
    resetInputValues();
    setValue(name, ''); // reset claim nubmer
    if (!isNew) setValue(inputs.claimGuid.name, localState.initialDataForEdit?.ClaimGuid); // reset claim guid in edit mode
  };

  const infoIcon = () => {
    if (isICBC)
      return (
        <>
          <Tooltip target=".icbc_input_label_tooltip" position="top" style={{ maxWidth: '250px' }}>
            {t('ICBC_valid_format_tooltip')}
          </Tooltip>
          <i className={cx('icbc_input_label_tooltip', icons.info)} />
        </>
      );

    return null;
  };

  const claimNumberInput = (field) => {
    return (
      <InputWrapper
        name={field.name}
        label={t('Claim_Number')}
        footerContent={clearButton}
        labelOptionalContent={infoIcon}
        errorMessage={errorMessage}
      >
        <ClaimNumberInput
          className={errorMessage ? 'p-invalid' : ''}
          id={field.name}
          type="text"
          name={field.name}
          value={field.value}
          delay={delay}
          maxLength={maxLength}
          invoiceType={invoiceType}
          calimNumbersList={patientClaims || {}}
          updateInitialState={(claim) => onUpdateInitialState(claim)}
          onChange={(e) => onChange(e)}
        />
      </InputWrapper>
    );
  };

  const clearButton = () => {
    return (
      <div className="flex justify-content-end w-full">
        <Button
          id={elementIDs.clearClaimDataButton}
          className="p-button-text py-0 px-1 small-text w-auto"
          label={t('Clear_claim_data')}
          type="button"
          tabIndex={-1}
          onClick={onClear}
        />
      </div>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => claimNumberInput(field)} />;
};

export default ClaimNumber;
