import React, { useState } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';

import HighlightSearchQuery from '../../Misc/HighlightSearchQuery/HighlightSearchQuery';
import DeleteConfirmation from '../../../modules/common/components/DeleteConfirmation';

import { setFocusToElement } from '../../../modules/utils/setFocusToElement';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { t } from '../../../service/localization/i18n';

const ReferralCatalogsTable = ({ scrollHeight, catalogsState, onRowClick, onDelete }) => {
  const catalogsCodesList = catalogsState?.tableData?.codeList;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deletingData, setDeletingData] = useState(null);

  const numberBodyTemplate = (rowData) => {
    return <HighlightSearchQuery text={rowData.value} query={catalogsState.searchValue} />;
  };

  const nameBodyTemplate = (rowData) => {
    return <HighlightSearchQuery text={rowData.name} query={catalogsState.searchValue} />;
  };

  const cityBodyTemplate = (rowData) => {
    return <HighlightSearchQuery text={rowData.city} query={catalogsState.searchValue} />;
  };

  // const phoneBodyTemplate = (rowData) => {
  //   if (!rowData.phone) return null;
  //   return <span>{formatPhoneNumber(rowData.phone)}</span>;
  // };

  const actionsBodyTemplate = (rowData) => {
    return (
      <div id={`delete_${rowData.value}`} className="w-full flex justify-content-center">
        <Button className="p-button-rounded p-button-text" icon="pi pi-trash" onClick={() => handleDelete(rowData)} />
      </div>
    );
  };

  const handleDelete = (rowData) => {
    setDeletingData(rowData);
    setShowDeleteDialog(true);
  };

  const onHideDeleteConfirm = () => {
    setDeletingData(null);
    setShowDeleteDialog(false);
  };

  const onDeleteReferral = () => {
    onHideDeleteConfirm();
    onDelete(deletingData);
  };

  const handleRowClick = (code) => {
    setFocusToElement(elementIDs.catalogsSearch); // CMO-2904 - Return the cursor to search field on Fee, Dx, and Referral and other wsbc catalog
    onRowClick(code);
  };

  const columnStyle = (minWidth) => ({ flexGrow: 1, flexBasis: minWidth });

  return (
    <>
      <DataTable
        value={catalogsCodesList}
        scrollable
        scrollHeight={scrollHeight}
        scrollDirection="both"
        columnResizeMode="fit"
        selectionMode="single"
        emptyMessage={catalogsState.emptyMessage}
        onSelectionChange={(e) => handleRowClick(e.value)}
      >
        <Column header={t('Number')} style={columnStyle('120px')} body={numberBodyTemplate} />
        <Column header={t('Name')} body={nameBodyTemplate} style={columnStyle('250px')} />
        <Column header={t('Field')} field="specialization" style={columnStyle('240px')} />
        <Column header={t('City')} body={cityBodyTemplate} style={columnStyle('120px')} />
        {/* <Column header={t("Phone")} field='phone' style={columnStyle('120px')} body={phoneBodyTemplate} /> */}
        <Column style={columnStyle('80px')} body={actionsBodyTemplate} frozen alignFrozen="right" />
      </DataTable>

      <DeleteConfirmation
        visible={showDeleteDialog}
        header={t('Delete_Referral')}
        message={String.format(t('Are_you_sure_you_want_to_delete_referral'), deletingData?.label?.toUpperCase())}
        accept={onDeleteReferral}
        reject={onHideDeleteConfirm}
        onHide={onHideDeleteConfirm}
      />
    </>
  );
};

export default ReferralCatalogsTable;
