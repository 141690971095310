import React, { useRef } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import PHNWithProvince from '../../../../../../../components/Inputs/PHN/PHNWithProvince';

import { inputs } from '../../../helpers/inputs';
import { usePHN } from '../../../hooks/usePHN';
import { t } from '../../../../../../../service/localization/i18n';

const PHN = () => {
  const inputRef = useRef(null);
  const { control, errors, localState } = useFormContext();
  const { provinceValidationCallback, onProvinceChange, onPHNChange, onPHNBlur } = usePHN();
  const phn = useWatch({ control, name: inputs.phn.name });
  const province = useWatch({ control, name: inputs.phnProvince.name });
  const errorMessage = !localState.isPHNValid ? t('Invalid_PHN') : errors[inputs.phn.name]?.message;

  return (
    <PHNWithProvince
      required
      autoFocus
      ref={inputRef}
      errorMessage={errorMessage}
      values={{ phn, province }}
      provinceValidationCallback={provinceValidationCallback}
      onProvinceChange={onProvinceChange}
      onPHNChange={onPHNChange}
      onPHNBlur={onPHNBlur}
    />
  );
};

export default PHN;
