import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { parseMilitaryTime } from '../../../modules/utils/formatTime';
import cx from 'classnames';
import './TimeInput.scss';

// interface ITimeInput {
//   name: string,
//   value: string // time format should be "HH:mm"
//   onChange: (time) => void,

//   ref?: ref
//   id?: string,
//   className?: string,
//   disabled?: boolean,
//   isInvalid?: boolean,
//   onBlur?: (time) => void
// }

const TimeInput = React.forwardRef(({ name, value, isInvalid, id, disabled, onChange, onBlur, className = '' }, ref) => {
  const handleBlur = (e) => {
    const time = parseMilitaryTime(e.target.value);
    if (time === e.target.value || e.target.value === '24:00') {
      onBlur && onBlur(time);
      onChange(time);
    } else {
      onBlur && onBlur('');
      onChange('');
    }
  };

  return (
    <span className="p-input-icon-right w-full">
      <InputMask
        id={id || name}
        ref={ref}
        className={cx(className, { 'p-invalid': isInvalid, disabled_time_input: disabled })}
        style={{ width: 'inherit' }}
        type="text"
        value={value}
        mask="99:99"
        placeholder="HH:MM"
        disabled={disabled}
        onChange={(e) => onChange(e.value)}
        onBlur={handleBlur}
      />
      {value && !disabled && <i className="pi pi-times pointer" onClick={() => onChange('')} />}
    </span>
  );
});

export default TimeInput;
