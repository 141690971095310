import { useFormContext } from 'react-hook-form';
import CreateEClaimView from './createEClaim/CreateEClaimView';
import PreviewEClaim from './viewEClaim/PreviewEClaim';

const EClaimsView = () => {
  const { isNew, isEdit, isView } = useFormContext();

  if (isNew || isEdit) return <CreateEClaimView />;
  if (isView) return <PreviewEClaim />;
};

export default EClaimsView;
