import { compact } from 'lodash';

export const formatDataForChips = (filters, columnValues) => {
  const currentFilters = { ...filters };

  Object.keys(currentFilters).forEach((i) => {
    if (currentFilters[i] === '' || i === 'freetext') {
      delete currentFilters[i];
    }
  });

  const chipsData = Object.keys(currentFilters)?.map((i) => {
    if (filters[i]) {
      const currentColumnKey = Object.keys(columnValues).find((x) => i === columnValues[x].filter);
      const currentColumn = columnValues[currentColumnKey];

      return {
        field: currentColumn?.filter,
        label: currentColumn?.header,
        value: filters[i]
      };
    }
  });

  return compact(chipsData);
};
