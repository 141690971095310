import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { t } from '../../../../../../service/localization/i18n';
import { stringFormat } from '../../../../../utils/stringFormat';
import { inputs } from '../../helpers/inputs';

const FeeCodeForWSBCDialog = () => {
  const { localState, setLocalState, getValues } = useFormContext();
  const feeCodes = getValues(inputs.feeCodes.codeType);
  const feeCodesOnly = feeCodes?.map((i) => i.value);
  const feeCodesForWSBC = feeCodesOnly?.filter((i) => i.startsWith('19'))?.join(', ');

  const onHideDialog = () => {
    setLocalState((prevState) => ({
      ...prevState,
      feeCodeForWSBCDialog: false
    }));
  };

  const footer = () => {
    return <Button label={t('Ok')} onClick={onHideDialog} autoFocus />;
  };

  return (
    <Dialog
      header={t('Warning')}
      visible={localState.feeCodeForWSBCDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={footer()}
      onHide={() => onHideDialog()}
    >
      {stringFormat(t('feeCodesForWSBCMessage'), feeCodesForWSBC)}
    </Dialog>
  );
};

export default FeeCodeForWSBCDialog;
