import React from 'react';
import { Card } from 'primereact/card';
import GroupPickerHeader from '../../views/TeleplanGroup/GroupPicker/Layouts/GroupPickerHeader';
import ContentWrap from './ContentWrap';

const GroupPickerContentWrap = ({ children }) => {
  return (
    <ContentWrap>
      <Card className="teleplan_group_picker_card">
        <div className="flex md:justify-content-start justify-content-center">
          <GroupPickerHeader />
        </div>

        <div className="group_picker_data_table_wrap">{children}</div>
      </Card>
    </ContentWrap>
  );
};

export default GroupPickerContentWrap;
