import React from 'react';
import AuthorizationFormCheckbox from '../Inputs/AuthorizationFormCheckbox';
import TermsCheckbox from '../Inputs/TermsCheckbox';

const Terms = () => {
  return (
    <div className="flex flex-column lg:flex-row align-items-center lg:gap-3 w-full">
      <div className="eclaim_step_two_left_gap"></div>
      <div className="flex flex-column sm:flex-row gap-3 w-full">
        <div className="eclaim_step_two_input_wrap">
          <AuthorizationFormCheckbox />
          <TermsCheckbox />
        </div>
      </div>
      <div className="eclaim_step_two_right_gap"></div>
    </div>
  );
};

export default Terms;
