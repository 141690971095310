import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { useConditions } from './useConditions';
import { t } from '../../../../../service/localization/i18n';

const commonOptions = [
  { value: 'N/A', label: t('N_A') },
  { value: 'POLHOLD', label: t('Insured_Member') },
  { value: 'PROV', label: t('Practitioner') },
  { value: 'PROVORG', label: t('Clinic') }
];

export const usePayee = () => {
  const { watch, setValue, isNew } = useFormContext();
  const insurerID = watch(inputs.primaryPolicy.name).InsurerID;
  const payee = watch(inputs.payee.name);
  const { isPBC } = useConditions();

  // Update init value for payee (pay to) when change policy
  useEffect(() => {
    if (isNew) {
      if (Number(insurerID) === 10051) {
        return setValue(inputs.payee.name, 'POLHOLD');
      }

      if (isPBC) {
        return setValue(inputs.payee.name, 'PROVORG');
      }

      if (!payee) setValue(inputs.payee.name, payee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurerID]);

  const options = () => {
    if (Number(insurerID) === 10051) {
      return commonOptions.filter((i) => i.value === 'POLHOLD');
    }

    if (isPBC) {
      return commonOptions.filter((i) => i.value === 'PROVORG');
    }

    return commonOptions;
  };

  const onPayeeChange = (e, field) => {
    field.onChange(e.value);
  };

  return { onPayeeChange, options: options() };
};
