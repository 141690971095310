import { icons } from '../../../../../modules/config/stylesConfig';
import { t } from '../../../../../service/localization/i18n';

// DONT FORGET RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo" OR "Demo" prefix

export const menuItems = (data, onDeleteDemo) => {
  return [
    {
      label: t('Delete'),
      icon: icons.delete,
      command: () => onDeleteDemo(data)
    }
  ];
};
