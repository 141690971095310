import React from 'react';
import PractitionerReports from './PractitionerReports/PractitionerReports';
import PageWrap from '../../../components/Wraps/PageWrap/PageWrap';
import PayeeReports from './PayeeReports/PayeeReports';

const Reports = () => {
  return (
    <PageWrap>
      <div className="flex flex-column w-full gap-3 containerXL">
        <PayeeReports />
        {/* <PractitionerReports /> */}
      </div>
    </PageWrap>
  );
};

export default Reports;
