import React from 'react';

const ErrorLayout = ({ name, errorMessage }) => {
  if (!errorMessage) return null;

  return (
    <div id={`${name}-error-message`} className="p-error text-sm max-w-max">
      {errorMessage}
    </div>
  );
};

export default ErrorLayout;
