import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { NA_DoctorGuid, NA_Speciality } from '../../../../config/defaultValuesConfig';
import { inputs } from '../helpers/inputs';
import { prefsCodes } from '../../../../config/prefsCodesConfig';
import { getCatalogCategories, setCatalogCategories, setClaimsData } from '../../../actions/claims.action.creators';
import { getPreferences } from '../../../../preferences/actions/preferences.action.creators';
import { facilityInit, initInvoiceType, payeeNumberInit, subFacilityInit } from '../helpers/defaultValues';
import { isEmpty } from 'lodash';
import { getSpeciality, isSupplementary } from '../../../../config/specialitiesConfig';
import { hideEmergency, hideTimeInputs, showMultipleServiceDate, showRuralCodeInput } from '../helpers/inputsConditions';
import { ruralCodesDropdownOptions } from '../helpers/dropdownOptions';
import { useCreateNewRecord } from '../views/TeleplanGroup/hooks/useCreateNewRecord';
import { checkIfCodeValidForLocation } from '../helpers/validationSchema';
import { getPractitionerByGuid, getPractitionerGuid, getPractitionersList } from '../../../../utils/getPractitioner';
import { getDefaultPrefs } from '../../../helpers/getDefaultPrefs';
import { getServiceDate } from '../../../../utils/getDate';
import { useCatalogTabs } from './useCatalogTabs';
import { validate } from 'uuid';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { addMissingCodesToRecentList } from '../helpers/updateRecentCodesList';
import { usePatient } from './usePatient';
import { useValidation } from './useValidation';

export const usePractitioner = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);
  const { claimsData } = useSelector((state) => state.claims);

  const { watch, reset, isNew, isGroup, setLocalState, setValue, localState } = useFormContext();
  const { get36010Code } = usePatient();
  const { onTabChange } = useCatalogTabs();
  const { clearCommentError } = useValidation();
  const { createNewRecord } = useCreateNewRecord();
  const patients = watch(inputs.patient.name);
  const currentFormState = watch();
  const practitionerOptions = { naOption: isNew };
  const practitionersList = getPractitionersList(practitionerOptions);

  const onBlur = (practitionerGuid) => {
    isEmptyPractitioner(practitionerGuid);
  };

  const onSelect = (practitionerGuid) => {
    isEmptyPractitioner(practitionerGuid);
  };

  const isEmptyPractitioner = (practitionerGuid) => {
    const isValidPractitioner = validate(practitionerGuid);

    // Hide inputs if selected practitioner is N/A
    if (localState.isEmptyPractitioner === isValidPractitioner) {
      setLocalState((prevState) => {
        return {
          ...prevState,
          isEmptyPractitioner: !isValidPractitioner
        };
      });
    }
  };

  const onChangePractitioner = async (doctorGuid, field) => {
    const practitionerGuid = getPractitionerGuid(doctorGuid);
    const selectedPractitioner = getPractitionerByGuid(doctorGuid, practitionerOptions);
    const speciality = Number(selectedPractitioner.Speciality);
    const currentSpeciality = Number(currentFormState[inputs.speciality.name]);
    const shouldGetCategories = currentSpeciality !== speciality && speciality !== NA_Speciality;

    // CMO-2980 - Change fee catalogs when the user changes the profile in Teleplan form
    if (shouldGetCategories) {
      const categoriesResult = await getCatalogCategories(speciality);
      if (categoriesResult) setCatalogCategories(categoriesResult);
    }

    field.onChange(doctorGuid); // Update practitioner
    setValue(inputs.speciality.name, speciality);

    // Clear error message for commen input
    clearCommentError({ speciality });

    // VER-389 - Teleplan->first screen->Add suggestion for the service date for 36020
    if (patients?.length) {
      const patientGuid = patients?.[0]?.PatientGuid;
      get36010Code({ patientGuid, speciality });
    }

    // Set catalogs tab to Quick Pick if selected N/A
    doctorGuid === NA_DoctorGuid && onTabChange({ index: 0 });

    !isEmpty(claimsData) && dispatch(setClaimsData()); // [KS] - reset WSBC and ICBC claim data
    dispatch(getPreferences(user.DCNGuid, practitionerGuid, (response) => getPrefsCallback(response, doctorGuid)));
  };

  const getPrefsCallback = (response, practitionerGuid) => {
    const defaultPractitionerPrefs = response?.find((i) => i.label === 'default');
    const practitionerPrefsContent = defaultPractitionerPrefs?.content;
    const currentServiceDate = currentFormState?.[inputs.serviceDate.name];
    const currentPractitioner = getPractitionerByGuid(practitionerGuid, practitionerOptions);
    const invoiceType = initInvoiceType({ preferences: practitionerPrefsContent, isGroup, practitionerGuid });
    const speciality = Number(currentPractitioner.Speciality);
    const showMultipleDates = showMultipleServiceDate(speciality, invoiceType) || localState.isMultipleDates; // Keep the switch turned on (multiple dates) by default for healthcare practitioners

    const getUpdatedFormState = (formState) => {
      const facility = facilityInit(practitionerPrefsContent, speciality);
      const subFacility = subFacilityInit(practitionerPrefsContent, speciality);
      const payeeNumber = payeeNumberInit(practitionerPrefsContent, practitionerGuid);
      const ruralCode = getRuralCode(speciality, formState, practitionerPrefsContent);
      const locationCode = getLocationCode(formState, practitionerPrefsContent);
      const serviceDate = isNew ? getServiceDates(showMultipleDates) : currentServiceDate;
      const feeCodes = getFeeCodes(formState, speciality, invoiceType);
      const dxCodes = getDxCodes(formState, speciality, invoiceType);
      const emergency = getEmergency(formState, speciality, invoiceType);
      const travel = getTravel(formState, speciality, invoiceType);
      const ccfpp = getCCFPP(formState, speciality, invoiceType);
      const callTime = getTime(formState, speciality, invoiceType, inputs.callTime.name);
      const startTime = getTime(formState, speciality, invoiceType, inputs.startTime.name);
      const endTime = getTime(formState, speciality, invoiceType, inputs.endTime.name);
      const refToBy = getRefToBy(formState, speciality, invoiceType);
      const referralCodes = getReferralCodes(formState, speciality, invoiceType);

      return {
        ...formState,
        ...feeCodes,
        ...dxCodes,
        ...referralCodes,
        [inputs.payor.name]: invoiceType,
        [inputs.serviceDate.name]: serviceDate,
        [inputs.emergency.name]: emergency,
        [inputs.travel.name]: travel,
        [inputs.ccfpp.name]: ccfpp,
        [inputs.callTime.name]: callTime,
        [inputs.startTime.name]: startTime,
        [inputs.endTime.name]: endTime,
        [inputs.refToBy.name]: refToBy,

        // Practitioner section
        [inputs.practitioner.name]: practitionerGuid,
        [inputs.practitionerNumber.name]: currentPractitioner?.PractitionerNumber || '',
        [inputs.facility.name]: facility,
        [inputs.subFacility.name]: subFacility,
        [inputs.payeeNumber.name]: payeeNumber,
        [inputs.ruralCode.name]: ruralCode,
        [inputs.locationCode.name]: locationCode,
        [inputs.speciality.name]: speciality
      };
    };

    const updatedFormState = getUpdatedFormState(currentFormState);
    const updatedLocationCode = updatedFormState[inputs.locationCode.name];
    const updatedSpeciality = updatedFormState[inputs.speciality.name];
    const updatedFeeCodes = updatedFormState[inputs.feeCodes.codeType];
    const feeCodesOnly = updatedFeeCodes?.map((i) => i.value) || [];

    // Show warn message if FeeCodes conflicts with LocationCode
    checkIfCodeValidForLocation(feeCodesOnly, updatedLocationCode, updatedSpeciality);

    setLocalState((prevState) => {
      let groupRecords = prevState.groupRecords;
      if (isGroup) {
        if (groupRecords?.length) {
          groupRecords = groupRecords?.map((i) => ({ ...getUpdatedFormState(i) }));
        } else {
          const newGroupRecords = patients?.map((patient) => {
            const feeCode = updatedFormState[inputs.feeCodes.codeType];
            const newRecord = createNewRecord({ record: updatedFormState, patient, feeCode });
            return newRecord;
          });
          groupRecords = newGroupRecords;
        }
      }

      return {
        ...prevState,
        practitionerPrefs: defaultPractitionerPrefs,
        templatesList: [],
        groupRecords,
        isMultipleDates: showMultipleDates
      };
    });

    reset(updatedFormState, { keepDirty: true });
  };

  const getRuralCode = (speciality, formState, preferences) => {
    const defaultCode = 'N/A';
    const showRural = showRuralCodeInput(speciality);

    if (showRural) {
      const ruralPrefs = getDefaultPrefs(preferences, prefsCodes.defaultRural);
      if (ruralPrefs) {
        return ruralCodesDropdownOptions.find((i) => i.value === ruralPrefs);
      } else {
        return formState[inputs.ruralCode.name];
      }
    } else {
      return ruralCodesDropdownOptions.find((i) => i.value === defaultCode);
    }
  };

  const getLocationCode = (formState, preferences) => {
    const locationPrefs = getDefaultPrefs(preferences, prefsCodes.defaultLocation);

    if (locationPrefs && locationPrefs !== 'A') {
      return locationPrefs;
    } else {
      return formState[inputs.locationCode.name];
    }
  };

  const getServiceDates = (showMultipleDates) => {
    const date = getServiceDate();
    return showMultipleDates ? [date] : date;
  };

  const getFeeCodes = (formState, speciality, invoiceType) => {
    const feeCodeType = inputs.feeCodes.codeType;
    const suspect = getSpeciality(speciality);
    const feeCodes = suspect[feeCodeType];

    if (feeCodes?.length) {
      const feeType = getCategoryType(feeCodeType, invoiceType);
      const selectedCodes = { [feeType]: feeCodes };
      addMissingCodesToRecentList({ selectedCodes });

      return {
        [inputs.feeCodes.name]: feeCodes?.map((i) => i.value),
        [inputs.feeCodes.codeDescription]: feeCodes?.map((i) => i.label),
        [feeCodeType]: feeCodes
      };
    }

    const resetCode = shouldResetCode(speciality, feeCodeType);

    return {
      [inputs.feeCodes.name]: resetCode ? [] : formState[inputs.feeCodes.name],
      [inputs.feeCodes.codeDescription]: resetCode ? [] : formState[inputs.feeCodes.codeDescription],
      [feeCodeType]: resetCode ? [] : formState[feeCodeType]
    };
  };

  const getDxCodes = (formState, speciality, invoiceType) => {
    const icd9CodeType = inputs.icd9.codeType;
    const suspect = getSpeciality(speciality);
    const dxCodes = suspect[icd9CodeType];

    if (dxCodes?.length) {
      const dxType = getCategoryType(icd9CodeType, invoiceType);
      const selectedCodes = { [dxType]: dxCodes };
      addMissingCodesToRecentList({ selectedCodes });

      return {
        [inputs.icd9.name]: dxCodes?.map((i) => i.value),
        [inputs.icd9.codeDescription]: dxCodes?.map((i) => i.label),
        [icd9CodeType]: dxCodes
      };
    }

    const resetCode = shouldResetCode(speciality, icd9CodeType);

    return {
      [inputs.icd9.name]: resetCode ? [] : formState[inputs.icd9.name],
      [inputs.icd9.codeDescription]: resetCode ? [] : formState[inputs.icd9.codeDescription],
      [icd9CodeType]: resetCode ? [] : formState[icd9CodeType]
    };
  };

  const getEmergency = (formState, speciality, invoiceType) => {
    const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
    if (ishideEmergency) return false;
    return formState[inputs.emergency.name];
  };

  const getTravel = (formState, speciality, invoiceType) => {
    const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
    if (ishideEmergency) return false;
    return formState[inputs.travel.name];
  };

  const getCCFPP = (formState, speciality, invoiceType) => {
    const ishideEmergency = hideEmergency({ isGroup, isNew, speciality, invoiceType });
    if (ishideEmergency) return false;
    return formState[inputs.ccfpp.name];
  };

  const getTime = (formState, speciality, invoiceType, field) => {
    const hideInputs = hideTimeInputs(speciality, invoiceType);

    if (hideInputs) return '';
    return formState[field];
  };

  const getRefToBy = (formState, speciality, invoiceType) => {
    const _isSupplementary = isSupplementary(speciality, invoiceType);

    if (isGroup || _isSupplementary) return 'N';
    return formState[inputs.refToBy.name];
  };

  const getReferralCodes = (formState, speciality, invoiceType) => {
    const _isSupplementary = isSupplementary(speciality, invoiceType);
    return {
      [inputs.referral.name]: _isSupplementary ? [] : formState[inputs.referral.name],
      [inputs.referral.codeDescription]: _isSupplementary ? [] : formState[inputs.referral.codeDescription],
      [inputs.referral.codeType]: _isSupplementary ? [] : formState[inputs.referral.codeType]
    };
  };

  const shouldResetCode = (speciality, codeType) => {
    const currentSpeciality = getSpeciality(speciality);
    const prevPractitionerSpec = watch(inputs.speciality.name);
    const prevSpeciality = getSpeciality(prevPractitionerSpec);
    const resetCode = prevSpeciality[codeType]?.length && !currentSpeciality[codeType]?.length;
    return resetCode;
  };

  return {
    practitionersList,
    practitionerOptions,
    onChangePractitioner,
    onBlur,
    onSelect
  };
};
