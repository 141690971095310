import React, { useRef } from 'react';

import { CodePicker, InputWrapper } from '../../../../../../../../../components/Inputs';

import { icd9CodeMaxEntry } from '../../../../../../../../config/defaultValuesConfig';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { dxCodeEditorWidth, rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { t } from '../../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const DxCodeEditor = ({ rowOptions }) => {
  const { onDxChange, disabledDxInput, toggleCatalogsForGroup } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const ref = useRef(null);
  const name = inputs.icd9.name;
  const dxCodes = rowOptions.rowData[inputs.icd9.codeType];
  const speciality = rowOptions.rowData[inputs.speciality.name];
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const disabled = disabledDxInput(rowOptions);
  const description = dxCodes?.length ? dxCodes[0]?.label : null;
  const errorMessage = getErrorMessage(rowId, name);

  const descriptionLayout = (
    <div
      className="white-space-nowrap overflow-hidden text-overflow-ellipsis input_description_styles"
      style={{ width: `calc(${dxCodeEditorWidth} - 20px)` }}
    >
      {description}
    </div>
  );

  return (
    <InputWrapper
      hideLabel
      name={name}
      label={inputs.referral.label}
      errorMessage={errorMessage}
      description={descriptionLayout}
      style={{ height: rowInputHeight, width: '100%' }}
    >
      <div className="flex w-full" style={{ columnGap: '5px' }}>
        <div className="flex flex-column w-full">
          <CodePicker
            name={name}
            ref={ref}
            className={cx('w-full', { disabled_autocomplete: disabled })}
            value={dxCodes}
            isInvalid={!!errorMessage && !dxCodes.length}
            disabled={disabled}
            placeholder={disabled ? ' ' : t('Search_for_codes')}
            maxEntry={icd9CodeMaxEntry}
            showCatalogsButton
            catalogsButtonTooltip={t('Find_dx_in_the_catalog')}
            searchParams={{ speciality, codeType: inputs.icd9.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={() => toggleCatalogsForGroup(inputs.icd9, rowOptions)}
            onChange={(e) => onDxChange(e.value, rowOptions)}
          />
        </div>
      </div>
    </InputWrapper>
  );
};

export default DxCodeEditor;
