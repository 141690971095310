import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { inputs } from '../../../../../helpers/inputs';
import { defaultUnits } from '../../../../../config/defaultValues';
import { t } from '../../../../../../../../../service/localization/i18n';

const ClearAllTimeButton = () => {
  const { watch, setLocalState, setValue } = useFormContext();

  const onClick = () => {
    const endKey = inputs.endTime.name;
    const starKey = inputs.startTime.name;

    // Reset start and fimish time on first step if any time is changed in the group time picker
    watch(starKey) && setValue(starKey, '');
    watch(endKey) && setValue(endKey, '');

    setLocalState((prevState) => {
      const resetedTime = prevState.groupRecords.map((i) => ({
        ...i,
        [inputs.startTime.name]: '',
        [inputs.endTime.name]: '',
        [inputs.duration.name]: 0,
        [inputs.units.name]: defaultUnits
      }));

      return { ...prevState, groupRecords: resetedTime };
    });
  };

  return (
    <Button
      className="p-button-text p-button-rounded"
      icon="pi pi-history"
      tooltip={t('Clear_all_time')}
      tooltipOptions={{ position: 'top' }}
      onClick={onClick}
    />
  );
};

export default ClearAllTimeButton;
