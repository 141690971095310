import SafeHtmlContent from '../../../components/Misc/SafeHTMLContent/SafeHTMLContent';
import { t } from '../../../service/localization/i18n';

export const warnToastBasicLifeTime = 25000;

export const commonToast = (ref, message) => {
  let basicTitile;
  let basicLifeTime;
  switch (message?.type) {
    case 'success':
      basicTitile = t('Success');
      basicLifeTime = 10000;
      break;

    case 'warn':
      basicTitile = t('Warning');
      basicLifeTime = warnToastBasicLifeTime;
      break;

    case 'info':
      basicTitile = t('Info');
      basicLifeTime = 20000;
      break;

    case 'error':
      basicTitile = t('Error');
      basicLifeTime = 60000;
      break;

    default:
      break;
  }

  ref.current.show({
    sticky: message?.lifeTime === 'sticky',
    life: message?.lifeTime ? message?.lifeTime : basicLifeTime,
    severity: message?.type,
    summary: message?.title ? message?.title : basicTitile,

    detail: typeof message?.message === 'string' ? <SafeHtmlContent htmlContent={message?.message} /> : message?.message
  });
};
