import React from 'react';
import {
  BS_TOLL_FREE,
  CLAIM_MANAGER_CONTACT_EMAIL,
  CONTACT_EMAIL_2,
  CONTACT_PHONE_1,
  CONTACT_PHONE_2,
  CONTACT_PHONE_3,
  CONTACT_PHONE_4,
  CONTACT_PHONE_5,
  CONTACT_PHONE_7,
  FAX_NUMBER_1,
  FAX_NUMBER_2,
  VANCOUVER_PHONE,
  VICTORIA_2
} from '../../config';
import { t } from '../../service/localization/i18n';

const Support = () => {
  return (
    <div className="flex justify-content-center align-items-center flex-column py-4 text-center" style={{ rowGap: '30px' }}>
      <div className="flex justify-content-center align-items-center flex-column pb-6">
        <h1 className="text-center">{t('Claim_manager_support_and_sales')}</h1>

        <div className="flex flex-column align-items-center pt-2 gap-3">
          <h4 className="m-0 font-normal text-center">{`${t('Contact_claim_manager_for_all_your_medical_billing_needs')}`}</h4>

          <div className="flex flex-column gap-3 max-w-15rem align-items-start">
            <div className="text-2xl">
              <span className="pr-2">(p)</span>
              <a href={`tel:${CONTACT_PHONE_7}`}>{CONTACT_PHONE_7}</a>
            </div>
            <div className="text-2xl">
              <span className="pr-2">(f)</span>
              <a href={`tel:${FAX_NUMBER_1}`}>{FAX_NUMBER_1}</a>
            </div>
          </div>
          <div className="text-2xl">
            <a href={`mailto:${CLAIM_MANAGER_CONTACT_EMAIL}`}>{CLAIM_MANAGER_CONTACT_EMAIL}</a>
          </div>
        </div>

        <div className="flex flex-column pt-4 gap-3">
          <h2 className="m-0 pt-3 text-center">{t('Working_hours')}</h2>
          <h4 className="m-0 font-normal text-center">{t('Claim_manager_online_24_7')}</h4>
          <h4 className="m-0 font-normal text-center">{t('Support_and_inquiries')}</h4>
        </div>

        <h2 className="text-center">{`${t('We_take_calls_on_evenings_and_weekends')}`}</h2>
      </div>

      <div>
        <h1 className="pb-3">{t('Teleplan_Support')}</h1>

        <h4 className="m-0 font-normal">{`${t('Contact_claim_manager_for_all_your_medical_billing_needs_and_teleplan_enrollment')}.`}</h4>

        <ul className="mt-0" style={{ listStyleType: 'disc' }}>
          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(p)</span>
            <a href={`tel:${CONTACT_PHONE_1}`}>{CONTACT_PHONE_1}</a>
            <span>{`, ${t('Options').toLowerCase()} 3, 2 (${t('Teleplan_enrollment_and_cancelling_in_process_claims')})`}</span>
          </li>

          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(f)</span>
            <a href={`tel:${CONTACT_PHONE_2}`}>{CONTACT_PHONE_2}</a>
            <span>{`, ${t('Options').toLowerCase()} 1, 3 (${t('Billing_support_and_troubleshooting_of_declined_claims')})`}</span>
          </li>

          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(f)</span>
            <a href={`tel:${CONTACT_PHONE_3}`}>{CONTACT_PHONE_3}</a>
            <span>{`, ${t('Options').toLowerCase()} 3, 2 (${t('toll_free')})`}</span>
          </li>
        </ul>
      </div>

      <div className="text-2xl">
        <h2>{t('Working_hours')}</h2>

        <ul className="m-0" style={{ listStyleType: 'disc' }}>
          <li className="text-left">(hrs) M-Fr, 8:00 am to 4:30 pm</li>
        </ul>
      </div>

      <div className="text-2xl">
        <h2>{t('Submit_Teleplan_related_forms')}</h2>

        <ul className="m-0" style={{ listStyleType: 'disc' }}>
          <li className="text-2xl text-left">
            <a href={`mailto:${CONTACT_EMAIL_2}`}>{CONTACT_EMAIL_2}</a>
          </li>
          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(f)</span>
            <a href={`tel:${CONTACT_PHONE_4}`}>{CONTACT_PHONE_4}</a>
          </li>
        </ul>
      </div>

      <div className="text-2xl">
        <h2>{t('Submit_Request_for_Approval_of_Over_Age_claims')}</h2>

        <ul className="m-0" style={{ listStyleType: 'disc' }}>
          <li className="text-2xl text-left">
            <span className="pr-2">(f)</span>
            <a href={`tel:${CONTACT_PHONE_5}`}>{CONTACT_PHONE_5}</a>
          </li>
          <li className="text-2xl text-left">
            <span className="pr-2">(f)</span>
            <a href={`tel:${FAX_NUMBER_2}`}>{FAX_NUMBER_2}</a>
          </li>
        </ul>
      </div>

      <div className="text-2xl">
        <h2>{t('Check_Patients_coverage')}</h2>

        <ul className="m-0" style={{ listStyleType: 'disc' }}>
          <li className="text-2xl text-left">
            <span className="pr-2">(p)</span>
            <a href={`tel:${VICTORIA_2}`}>{VICTORIA_2}</a>
            <span>{` (${t('in_Victoria')})`}</span>
          </li>

          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(p)</span>
            <a href={`tel:${VANCOUVER_PHONE}`}>{VANCOUVER_PHONE}</a>
            <span>{` (${t('in_Vancouver')})`}</span>
          </li>

          <li className="pt-3 text-2xl text-left">
            <span className="pr-2">(p)</span>
            <a href={`tel:${BS_TOLL_FREE}`}>{BS_TOLL_FREE}</a>
            <span>{` (${t('all_other_areas_of_BC')})`}</span>
          </li>

          <li className="pt-3 text-2xl text-left">
            <span>{t('24_hours_automated_line')}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Support;
