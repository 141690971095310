import React from 'react';
import { useSelector } from 'react-redux';

const CalendarDateTemplete = ({ data }) => {
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const hightlighteLatest = catalogMostRecentCodes?.visits?.some((i) => i.Day === data.day && i.Month === data.month && i.Year === data.year);

  if (hightlighteLatest) {
    return <div className="flex justify-content-center align-items-center lastVisitsDateTempate">{data.day}</div>;
  }

  return data.day;
};

export default CalendarDateTemplete;
