import React, { useContext } from 'react';

import Context from '../../../../../../../Context';
import { inputs } from '../../../helpers/inputs';
import { getRadioButtonValue } from '../../../helpers/getRadioButtonValue';
import { estimatedTimeDropdownOptions } from '../../../helpers/dropdownOptions';
import { getRehabilitation } from '../../../helpers/formTypeActions';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';

const ReturnToWork = () => {
  const { data } = useContext(Context);
  const estimatedReturn = estimatedTimeDropdownOptions.find((i) => i.value === data[inputs.estimatedReturn.name])?.label;

  return (
    <div className="pt-3 text-sm">
      <div className="font-semibold demographicsInfoTableHeader">{t('Return_To_Work')}</div>
      <table className="demographicsInfoTable">
        <tbody>
          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {t('Is_the_worker_now_medically_capable_of_working_full_time')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRadioButtonValue(data[inputs.capable.name])}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {t('If_no_what_are_the_current_physical_and_or_psychological_restrictions')}
            </td>
            <td>
              <div className="flex justify-content-end wordBreakText text-justify coloredText">{data[inputs.restrictions.name]}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {t('Estimated_time_before_the_worker_will_be_able_to_return')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{estimatedReturn}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {t('Is_the_worker_now_ready_for_a_rehabilitation_program')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRehabilitation(data[inputs.rehabilitation.name])?.label}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {t('Do_you_wish_to_consult_with.2')}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">{getRadioButtonValue(data[inputs.consult.name])}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold caption" style={{ width: '60%' }}>
              {`${t('Estimate_date_of_Maximal_Medical_Recovery')} (yyyy/mm/dd)`}
            </td>
            <td>
              <div className="flex justify-content-end coloredText">
                {data[inputs.recoveryDate.name] ? moment(data[inputs.recoveryDate.name]).format('YYYY/MM/DD') : ''}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReturnToWork;
