import { showInCardPageSize, claimsFiltersWithPeriod, claimsDefaultSortBy } from '../../config/defaultValuesConfig';

export const cardInitialState = {
  page: 0,
  rows: showInCardPageSize,
  totalRecords: 0,
  totalRecordsAlter: 0,
  totalBilled: 0,
  totalPaid: 0,
  totalDue: 0,
  first: 0,
  filters: claimsFiltersWithPeriod,
  claim_list: [],
  claim_details: {},
  complete_list: [],
  isFetching: false,
  isFetchingClaimHistory: false,
  selectedCodes: {},
  sortby: claimsDefaultSortBy.sortOrder
};
