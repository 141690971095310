import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedNameInput } from '../../../../../../../components/Inputs';
import { useValidation } from '../../../hooks/useValidations';
import { inputs } from '../../../helpers/inputs';

const LastName = () => {
  const name = inputs.memberLastName.name;
  const { memberLastNameValidation } = useValidation();
  const { errors, control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => memberLastNameValidation(value) }}
      render={({ field }) => (
        <WrappedNameInput
          ref={field.ref}
          input={inputs.memberLastName}
          value={field.value}
          errorMessage={errors[name]?.message}
          required
          onChange={(value) => field.onChange(value)}
        />
      )}
    />
  );
};

export default LastName;
