/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import cx from 'classnames';

import classes from './Table.module.scss';

const TableHeaderMobile = (props) => {
  const searchRef = useRef(null);
  const optionsMenuRef = useRef(null);

  const onGlobalSearch = (e) => {
    props.globalSearch.onChange(e);
  };

  const selectModeTemplate = (
    <div className="flex justify-content-between w-full">
      <div className="flex align-items-center">
        <Button
          className="p-button-rounded p-button-text mr-2"
          icon="pi pi-times"
          onClick={() => {
            props.setIsMultipleSelect(false);
            props.setSelectedRow([]);
          }}
        />
        {props.selectedRow?.length}
      </div>

      <div>
        {/* Select all button */}
        <Button className="p-button-rounded p-button-text" icon="pi pi-list" onClick={() => props.setSelectedRow(props.dataTableValue)} />

        <Menu id="options_popup_menu" ref={optionsMenuRef} popup model={props.selectedOptionsMenuMode()} />
        <Button
          className="ml-2 p-button-outlined p-button-rounded"
          type="button"
          icon="pi pi-ellipsis-v"
          aria-controls="options_popup_menu"
          aria-haspopup
          onClick={(e) => optionsMenuRef.current.toggle(e)}
        />
      </div>
    </div>
  );

  const defaultModeContent = (
    <>
      <span className={cx('p-input-icon-left', props?.globalFilter ? 'p-input-icon-right' : '')}>
        <i className="pi pi-search" />
        <InputText
          ref={searchRef}
          // type="search" // not support in Firefox
          value={props.globalFilter}
          className="w-full"
          placeholder={props.globalSearch.placeholder}
          onChange={onGlobalSearch}
        />
        {props?.globalFilter && <i className="pi pi-times pointer" onClick={() => onGlobalSearch({ target: { value: '' } })} />}
      </span>

      <div className="flex">
        {props.addNewButton.display && (
          <Button className="ml-3 p-button-rounded" type="button" icon="pi pi-plus" onClick={props.addNewButton.onClick} />
        )}

        {/* More options button */}
        {props.moreOptionsButton}
      </div>
    </>
  );

  return <div className={classes.tableHeaderMobile}>{props.isMultipleSelect ? selectModeTemplate : defaultModeContent}</div>;
};

export default TableHeaderMobile;
