import { v4 as uuidv4 } from 'uuid';
import { inputs } from '../../../helpers/inputs';

export const useCreateNewRecord = () => {
  const createNewRecord = ({ record, patient, feeCode }) => {
    const currentFeeCode = feeCode ? (Array.isArray(feeCode) ? feeCode : [feeCode]) : [];

    return {
      ...record,
      [inputs.patient.name]: [patient],
      [inputs.groupRowId.name]: uuidv4(),
      [inputs.feeCodes.name]: currentFeeCode?.map((i) => i.value),
      [inputs.feeCodes.codeDescription]: currentFeeCode?.map((i) => i.label),
      [inputs.feeCodes.codeType]: currentFeeCode
    };
  };

  return { createNewRecord };
};
