import React from 'react';
import SelectionCard from '../SelectionCard/SelectionCard';
import { commonInputs } from '../../../modules/config/commonInputsConfig';
import { icons } from '../../../modules/config/stylesConfig';
import { t } from '../../../service/localization/i18n';

// interface IPolicy {
//   InsurerNameEn: string
//   PolicyNum: string
//   MemberID: string
//   MemberFirstName: string
//   MemberLastName: string
// }

// interface IPolicyCard {
//   policy: IPolicy
//   isSelected?: boolean
//   hideActions?: boolean // hide 3 dot menu button
//   menuModel?: {
//     label: string
//     icon: string
//     command: (policy: IPolicy) => void
//   }[]
//   onSelect?: (policy: IPolicy) => void // on policy card click
//   onEdit?: (policy: IPolicy) => void // on edit policy click
//   onDelete?: (policy: IPolicy) => void // on delete policy click
// }

const PolicyCard = ({ policy, isSelected, onSelect, onEdit, onDelete, menuModel, hideActions }) => {
  const splitButtonItems = (item) => {
    return [
      {
        label: t('Edit'),
        icon: icons.edit,
        command: () => onEdit(item)
      },
      {
        label: t('Delete'),
        icon: icons.delete,
        command: () => onDelete(item)
      }
    ];
  };

  const insurerNameENKey = commonInputs.insurerNameEN.name;
  const policyNumKey = commonInputs.policyNumber.name;
  const memberIDKey = commonInputs.memberID.name;
  const menu = hideActions ? undefined : menuModel || splitButtonItems(policy);

  const onPolicyClick = (policyItem) => {
    onSelect && onSelect(policyItem);
  };

  return (
    <SelectionCard isSelected={isSelected} menuModel={menu}>
      <div className="flex flex-column align-items-start gap-3 w-21rem" onClick={() => onPolicyClick(policy)}>
        <div className="flex align-items-center justify-content-between w-full">
          <h5 className="m-0 text-left">{policy?.[insurerNameENKey]}</h5>
        </div>

        <div className="flex flex-column align-items-start gap-1">
          <div>{`${t('Policy')}: ${policy?.[policyNumKey]}`}</div>
          <div>{`${t('Member_ID')}: ${policy?.[memberIDKey]}`}</div>
        </div>

        <div>{`${t('Insured_Member')}: ${policy.MemberLastName}, ${policy.MemberFirstName}`}</div>
      </div>
    </SelectionCard>
  );
};

export default PolicyCard;
