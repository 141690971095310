import moment from 'moment';
import { t } from '../../../../service/localization/i18n';

export const formatData = (data) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const labels = data?.map((i) => moment(i.Tick).format('MMM D, YYYY'));
  const paidData = data?.map((i) => i.Paid);
  const billedData = data?.map((i) => i.Billed);

  return {
    labels,
    datasets: [
      {
        label: t('Paid'),
        data: paidData,
        fill: false,
        borderColor: documentStyle.getPropertyValue('--green-500'),
        tension: 0.4,
        pointRadius: 8, // Change the size of the bullet points
        pointHoverRadius: 10,
        pointHoverBorderWidth: 6,
        pointBorderWidth: 2,
        pointBackgroundColor: '#fff'
      }
      // {
      //   label: t('Billed'),
      //   data: billedData,
      //   fill: false,
      //   borderColor: '#FFA726',
      //   tension: .4,
      //   pointRadius: 8, // Change the size of the bullet points
      //   pointHoverRadius: 10,
      //   pointHoverBorderWidth: 6,
      //   pointBorderWidth: 2,
      //   pointBackgroundColor: '#fff',
      // }
    ]
  };
};
