import React from 'react';

import SuccessPage from '../../../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { formatFormTypeValue } from '../../helpers/formTypeActions';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const Success = ({ report }) => {
  const type = formatFormTypeValue(report[inputs.formType.name], report[inputs.speciality.name]);
  const fullName = `${report[inputs.patient.name]?.LastName}, ${report[inputs.patient.name]?.FirstName}`;

  const successMessage = () => {
    return (
      <>
        {`${String.format(
          t('You_saved_WSBC_report_for_'),
          t('Submited').toLowerCase(),
          `F${type}`,
          fullName,
          currencyFormat(report[inputs.feeAmount.name])
        )}`}
      </>
    );
  };

  return (
    <SuccessPage successMessage={successMessage()}>
      <SuccessActionButtons patientsList={[report[inputs.patient.name]]} />
    </SuccessPage>
  );
};

export default Success;
