import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

const ClaimsViewPaginatorLeft = ({ totalPaid, totalDue, totalBilled, isMobile, isTablet }) => {
  const op = useRef(null);

  const desktopView = (
    <>
      {totalPaid ? (
        <>
          <span className={cx('mr-4 medium-size-text', isTablet ? 'mb-1' : '')}>
            {t('Billed')}: {totalBilled}
          </span>
          <span className={cx('mr-4 medium-size-text', isTablet ? 'mb-1' : '')}>
            {t('Paid')}: {totalPaid}
          </span>
          <span className="medium-size-text">
            {' '}
            {t('A_R')}: {totalDue}
          </span>
        </>
      ) : (
        <span></span>
      )}
    </>
  );

  return (
    <>
      {isTablet && (
        <>
          <Button
            className="p-button-text p-button-rounded"
            icon="pi pi-dollar"
            aria-haspopup
            aria-controls="overlay_panel"
            onClick={(e) => op.current.toggle(e)}
          />
        </>
      )}

      {!isMobile && desktopView}

      <OverlayPanel ref={op} id="overlay_panel" dismissable appendTo={document.body}>
        <div className="flex flex-column">{desktopView}</div>
      </OverlayPanel>
    </>
  );
};

export default ClaimsViewPaginatorLeft;
