import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '../../../../../../routes/routes';

const PromptDialog = () => {
  const { isDirty } = useFormContext();

  const promptMessage = (location) => {
    //  return JSON.stringify({ isDirty });

    //Will be called with the next location and action the user is attempting to navigate to. Return a string to show a prompt to the user or true to allow the transition.
    return location.pathname.startsWith(`${routes.createPatient.path}/new`)
      ? true
      : JSON.stringify({
          isDirty,
          name: 'WSBCReport' //add 'name' to check which form should be nulled (see customPrompt for nulling)
        });
  };

  return <Prompt when={isDirty} message={(location) => promptMessage(location)} />;
};

export default PromptDialog;
