import React from 'react';
import MissingPatientData from '../MissingPatientData/MissingPatientData';
import TotalOutstandingDialog from '../MissingPatientData/TotalOutstandingDialog';

const CommonDialogs = () => {
  return (
    <>
      <MissingPatientData />
      <TotalOutstandingDialog />
    </>
  );
};

export default CommonDialogs;
