import React from 'react';
import { useHistory } from 'react-router-dom';
import CustomAvatar from '../../../../common/components/CustomAvatar';


const DoctorNameTemplate = ({ rowData }) => {
  const history = useHistory();

  const avatarLetter = `${rowData.LastName?.charAt(0).toUpperCase()}${rowData.FirstName?.charAt(0).toUpperCase()}`;

 const handleAvatarClick = (rowData) =>{
    history.push(`/staff-details/${rowData.DoctorGuid}`);
 }

  return (
    <div className="fullname flex align-items-center" data-pr-tooltip="" style={{ cursor: 'pointer' }}>
      <div onClick={() => handleAvatarClick(rowData)}>
        <CustomAvatar id={rowData.DoctorGuid} className="mr-4" label={avatarLetter} />
      </div>
      {`${rowData?.FullName}`}
    </div>
  );
};

export default DoctorNameTemplate;
