export const initialModules = [
  {
    Module: 1,
    ModuleName: 'Patients',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 2,
    ModuleName: 'Appointments',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 3,
    ModuleName: 'Claims',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 4,
    ModuleName: 'Reports',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 5,
    ModuleName: 'Clinic',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 6,
    ModuleName: 'Staff',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 7,
    ModuleName: 'ClinicSettings',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 8,
    ModuleName: 'UserSettings',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  },
  {
    Module: 9,
    ModuleName: 'Roles',
    Permissions: {
      CanSee: false,
      AccessDenied: true
    }
  }
];

export const initialEntities = [
  {
    Entity: 1,
    EntityName: 'Patients',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 2,
    EntityName: 'Appointments',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 3,
    EntityName: 'Claims',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 4,
    EntityName: 'EClaims',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 5,
    EntityName: 'Clinics',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 6,
    EntityName: 'Users',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 7,
    EntityName: 'ClinicSettings',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 8,
    EntityName: 'UserSettings',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  },
  {
    Entity: 9,
    EntityName: 'Roles',
    Permissions: {
      CanRead: false,
      CanReadPI: false,
      CanCreate: false,
      CanEdit: false,
      CanDelete: false,
      CanUse: false,
      AccessDenied: true
    }
  }
];
