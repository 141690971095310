import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import AsterisksForReferralCodeInput from './AsterisksForReferralCodeInput';
import AddPractitionerButton from '../../../../../modules/ActionButtons/AddPractitionerButton';
import { CodePicker, InputWrapper } from '../../../../../../../../../components/Inputs';

import { referralCodeMaxEntry } from '../../../../../../../../config/defaultValuesConfig';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { referralCodeEditorWidth, rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const ReferralCodeEditor = ({ rowOptions }) => {
  const { setLocalState } = useFormContext();
  const { onReferralChange, disabledReferralInput, toggleCatalogsForGroup } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const ref = useRef(null);
  const name = inputs.referral.name;
  const referralCodes = rowOptions.rowData[inputs.referral.codeType];
  const speciality = rowOptions.rowData[inputs.speciality.name];
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const disabled = disabledReferralInput(rowOptions);
  const errorMessage = getErrorMessage(rowId, name);
  const description = referralCodes?.length ? referralCodes[0]?.label : null;

  const descriptionLayout = (
    <div
      className="white-space-nowrap overflow-hidden text-overflow-ellipsis input_description_styles"
      style={{ width: `calc(${referralCodeEditorWidth} - 30px)` }}
    >
      {description}
    </div>
  );

  const onAddPractitioner = () => {
    setLocalState((prevState) => ({ ...prevState, addNewPractitionerForGroupDialog: { showDialog: true, rowOptions } }));
  };

  return (
    <InputWrapper
      hideLabel
      name={name}
      label={inputs.referral.label}
      errorMessage={errorMessage}
      description={descriptionLayout}
      style={{ height: rowInputHeight, width: '100%' }}
    >
      <div className="flex w-full" style={{ columnGap: '5px' }}>
        <div className="flex flex-column w-full">
          {/* VER-64 - [batch] Referral step - remove Add New button from Refer field */}
          {/* <div className='codePickerWrap_referral_group'> */}
          {/* <AddPractitionerButton mini onAdd={onAddPractitioner} /> */}
          <CodePicker
            name={name}
            ref={ref}
            className={cx('w-full', 'teleplan_group_code_picker', { disabled_autocomplete: disabled })}
            value={referralCodes}
            isInvalid={!!errorMessage && !referralCodes.length}
            placeholder={disabled ? ' ' : t('Search_for_codes')}
            maxEntry={referralCodeMaxEntry}
            groupLabel={false}
            disabled={disabled}
            showCatalogsButton
            catalogsButtonTooltip={t('Find_practitioner_in_the_catalog')}
            searchParams={{ speciality, codeType: inputs.referral.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={() => toggleCatalogsForGroup(inputs.referral, rowOptions)}
            onChange={(e) => onReferralChange(e.value, rowOptions)}
          />
          {/* </div> */}
        </div>

        <AsterisksForReferralCodeInput rowOptions={rowOptions} />
      </div>
    </InputWrapper>
  );
};

export default ReferralCodeEditor;
