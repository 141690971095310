import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { Message } from 'primereact/message';
import { InputWrapper } from '../../Inputs';
import PHNInput from '../../../modules/patients/components/commonElements/PHNInput';
import EligibilityMessage from '../../../modules/patients/components/commonElements/EligibilityMessage';

import { getEligibility } from '../../../modules/patients/actions/patients.action.creators';
import { icons } from '../../../modules/config/stylesConfig';
import { t } from '../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';
import * as yup from 'yup';

const MSPEligibility = ({ patientDetails, onSubmitCallback, onHide }) => {
  const dispatch = useDispatch();
  const { isFetchingPatientEligibility, isFetchingPHNValidation } = useSelector((state) => state.patients);
  const [eligibility, setEligibility] = useState({});
  const [isPHNValid, setIsPHNValid] = useState(true);
  const [isDOBDateValid, setIsDOBDateValid] = useState(true);
  const [isDOSDateValid, setIsDOSDateValid] = useState(true);
  const [showEligibilityDialog, setShowEligibilityDialog] = useState(false);
  const defaultPHNProvince = 'BC';

  const onSubmit = (data) => {
    const formattedData = { ...data, DOS: moment(data.DOS).format('MM/DD/YYYY') };

    if ((!isPHNValid && formattedData?.PHN?.length) || !isDOBDateValid || !isDOSDateValid) {
      return;
    } else {
      dispatch(
        getEligibility(formattedData, (results) => {
          if (onSubmitCallback) {
            onSubmitCallback(formattedData, results);
          } else {
            setEligibility({
              ...results.Parsed,
              CHECK_ON_DATE_TEXT: moment(new Date(results.Parsed.CHECK_ON_DATE)).format('MM/DD/YYYY')
            });
            setShowEligibilityDialog(true);
          }
        })
      );
    }
  };

  const validationSchema = yup.object().shape({
    PHN: yup.string().required(t('Mandatory_field.1')),
    DOB: yup.date().required(t('Mandatory_field.1')).nullable(),
    DOS: yup.date().required(t('Mandatory_field.1')).nullable()
  });

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      PHNProvince: patientDetails?.PHNProvince || defaultPHNProvince,
      PHN: patientDetails?.PHN && patientDetails?.PHN !== 'N/A' ? patientDetails?.PHN : '',
      DOB: patientDetails?.BirthDay ? moment(patientDetails?.BirthDay).format('MM/DD/YYYY') : null,
      DOS: new Date()
    },
    validationSchema,
    onSubmit
  });

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const dateOnChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'DOB') {
      value && setIsDOBDateValid(true);
    }

    if (name === 'DOS') {
      value && setIsDOSDateValid(true);
      formik.setFieldValue('DOS', moment(value).format('DD/MM/YYYY'));
    }
    formik.handleChange(e);
  };

  const dateOnBlur = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const isValid = moment(value, 'MM/DD/YYYY', true).isValid();

    if (name === 'DOB') {
      value?.length > 0 && !isValid ? setIsDOBDateValid(false) : setIsDOBDateValid(true);
    }

    if (name === 'DOS') {
      value?.length > 0 && !isValid ? setIsDOSDateValid(false) : setIsDOSDateValid(true);
    }
  };

  const onHideEligibilityDialog = () => {
    setShowEligibilityDialog(false);
    setEligibility({});
    formik.resetForm();
  };

  const warnMessageContent = () => {
    return (
      <div className="flex justify-content-start align-items-center w-full">
        <i className={cx('mr-2', icons.warn)} />
        <span className="ml-1">{t('MSP_eligibility_is_limited_to_BC_patients_only')}</span>
      </div>
    );
  };

  const eligibilityDialogFooter = (
    <div>
      <Button label={t('Close')} autoFocus onClick={onHideEligibilityDialog} />
    </div>
  );

  const disableCheckMSP =
    !formik.values.PHN?.replaceAll(' ', '').replaceAll('_', '')?.length ||
    isFetchingPHNValidation ||
    !isPHNValid ||
    !isDOBDateValid ||
    !isDOSDateValid ||
    formik.values.PHNProvince !== defaultPHNProvince;

  return (
    <>
      {formik.values.PHNProvince !== defaultPHNProvince && <Message className="mb-3 w-full" severity="warn" content={warnMessageContent} />}

      <form onSubmit={formik.handleSubmit} className="p-fluid">
        <PHNInput
          formik={formik}
          required={true}
          isPHNValid={isPHNValid}
          setIsPHNValid={setIsPHNValid}
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}
        />

        <div className="flex gap-3">
          <InputWrapper
            required
            label={t('Day_of_birthday.2')}
            errorMessage={getFormErrorMessage('DOB') || (!isDOBDateValid && t('Invalid_date_format'))}
          >
            <InputMask
              className={cx({
                'p-invalid': isFormFieldValid('DOB') || !isDOBDateValid
              })}
              id="DOB"
              name="DOB"
              placeholder={t('MM_DD_YYYY')}
              mask="99/99/9999"
              value={formik.values.DOB}
              slotChar={t('MM_DD_YYYY')}
              onChange={dateOnChange}
              onBlur={dateOnBlur}
            />
          </InputWrapper>

          <InputWrapper required label={t('Service_date')} errorMessage={getFormErrorMessage('DOS') || (!isDOSDateValid && t('Invalid_date_format'))}>
            <Calendar
              className={cx({
                'p-invalid': isFormFieldValid('DOS') || !isDOSDateValid
              })}
              id="DOS"
              name="DOS"
              placeholder={t('MM_DD_YYYY')}
              dateFormat="mm/dd/yy"
              mask="99/99/9999"
              showIcon={true}
              showButtonBar
              inputMode="text"
              selectOtherMonths
              monthNavigator
              yearNavigator
              baseZIndex={1000000}
              yearRange={`1900:${moment().year()}`}
              value={formik.values.DOS}
              onChange={dateOnChange}
              onBlur={dateOnBlur}
            />
          </InputWrapper>
        </div>

        <div className="flex justify-content-end col-12 p-0">
          <Button
            className="p-button-content mt-2 mr-2"
            type="button"
            disabled={disableCheckMSP}
            loading={isFetchingPatientEligibility}
            label={t('Check_MSP')}
            onClick={formik.handleSubmit}
          />
          {onHide && <Button className="p-button-outlined p-button-content mt-2 ml-2" type="button" label={t('Close')} onClick={onHide} />}
        </div>
      </form>

      <Dialog
        visible={showEligibilityDialog}
        header={t('MSP_Eligibility')}
        breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
        style={{ width: '30vw' }}
        footer={eligibilityDialogFooter}
        onHide={onHideEligibilityDialog}
      >
        <EligibilityMessage
          eligibility={eligibility}
          iconSize="large"
          showIcon={true}
          showMessageColor={false}
          showInRowExpantion={true}
          showFullCheckOnDate={false}
        />
      </Dialog>
    </>
  );
};

export default MSPEligibility;
