import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';

import AsterisksForTimeInput from './AsterisksForTimeInput';
import RecommendedTime from '../../../../../modules/Layouts/RecommendedTime';
import { WrappedTimeInput } from '../../../../../../../../../components/Inputs';
import EndTimeInputDescription from '../../../../../modules/Layouts/EndTimeInputDescription';

import { colors } from '../../../../../../../../config/stylesConfig';
import { isTimeValid } from '../../../../../../../../utils/formatTime';
import { rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { inputs } from '../../../../../helpers/inputs';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { isRecommendetTime } from '../../../../../helpers/isRecommendetTime';
import { t } from '../../../../../../../../../service/localization/i18n';
import moment from 'moment';

const WarnIcont = ({ rowData }) => {
  const id = rowData[inputs.patient.name][0]?.PatientGuid;
  const startTime = rowData[inputs.startTime.name];
  const endTime = rowData[inputs.endTime.name];
  const isValidTime = isTimeValid(startTime) && isTimeValid(endTime);
  const duration = isValidTime ? moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minutes') : 0; // Do not use timeDiff function to calculate duration!

  if (duration < 0) {
    return (
      <>
        <Tooltip target={`.record_warn_tooltip_${id}`} position="top" />
        <div className={`record_warn_tooltip_${id}`} data-pr-tooltip={t('End_time_is_less_than_start_time')}>
          <i className="pi pi-exclamation-triangle text-lg" style={{ paddingTop: '3px', color: colors.warning }} />
        </div>
      </>
    );
  }

  return null;
};

const FinishTimeEditor = ({ rowOptions }) => {
  const ref = useRef(null);
  const { localState } = useFormContext();
  const { onTimeChange } = useGroupPickerInputs();
  const { getErrorMessage } = useValidateGoupPicker();
  const rowIndex = rowOptions.rowIndex;
  const name = inputs.endTime.name;
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const value = rowOptions.rowData[name];
  const feeCodes = rowOptions.rowData[inputs.feeCodes.codeType];
  const startTime = rowOptions.rowData[inputs.startTime.name];
  const endTime = rowOptions.rowData[inputs.endTime.name];
  const isValidTime = isTimeValid(value);
  const errorMessage = getErrorMessage(rowId, name);
  const disabled = rowIndex > 0 && localState.applyToAllTime;
  const isRecommendet = isRecommendetTime({ feeCodes });

  const onChange = (time) => {
    if (time === value) return;
    onTimeChange(time, name, rowOptions);
  };

  const footerContent = (
    <div className="flex gap-3 justify-content-between w-full">
      {!isTimeValid(endTime) && isRecommendet && <RecommendedTime feeCode={feeCodes?.[0]?.value} />}
      <EndTimeInputDescription startTime={startTime} endTime={value} />
    </div>
  );

  return (
    <div className="flex align-items-start" style={{ rowGap: '0.1rem', columnGap: '0.1rem' }}>
      <WrappedTimeInput
        ref={ref}
        name={name}
        value={value}
        hideLabel
        disabled={disabled}
        footerContent={footerContent}
        errorMessage={!isValidTime && errorMessage}
        style={{ height: rowInputHeight, width: '92px' }}
        id={`${name}_${rowOptions.rowIndex}`}
        isInvalid={!isValidTime && !!errorMessage}
        onChange={(time) => onChange(time)}
      />

      <AsterisksForTimeInput rowOptions={rowOptions} inputName={name} />
      <WarnIcont rowData={rowOptions.rowData} />
    </div>
  );
};

export default FinishTimeEditor;
