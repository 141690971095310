import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import {
  findClosestMatchingPsychiatryCode,
  getInvalidDurationMessageForPsychiatryCodes,
  getPsychiatryCodesWithWrongDuration
} from '../../helpers/claimFormValidation';
import { getCodesByType } from '../../../../actions/claims.action.creators';
import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';
import { useGroupPickerInputs } from '../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { patientFullName } from '../../../../../utils/patientFullName';
import { compact, findIndex } from 'lodash';
import { t } from '../../../../../../service/localization/i18n';
import { useGroupActionButtons } from '../../views/TeleplanGroup/hooks/useGroupActionButtons';
import { ignoreDurationForPsychiatryCodesList } from '../../helpers/codesCheckList';

const BatchDurationForPsychiatryCodesDialog = () => {
  const { localState, setLocalState, getValues } = useFormContext();
  const { showDialog, options } = localState.batchMatchDurationForFeeCodeDialog;
  const [fetchingCode, setFetchingCode] = useState(false);
  const { onFeeChange } = useGroupPickerInputs();
  const { handleNextForServicesPicker } = useGroupActionButtons();
  const notMatchDurationRows = options.notMatchDurationForPsychiatryCodes;
  const showIgnoreButton = notMatchDurationRows?.some((i) => {
    const feeCode = i[inputs.feeCodes.codeType]?.[0];
    return ignoreDurationForPsychiatryCodesList.some((x) => x === feeCode?.value);
  });

  const showOkButton = () => {
    const codesForReplace = notMatchDurationRows?.some((i) => {
      const feeCodes = i[inputs.feeCodes.codeType];
      const startTime = i[inputs.startTime.name];
      const endTime = i[inputs.endTime.name];
      const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });
      const matchingCode = findClosestMatchingPsychiatryCode({ startTime, endTime, psychiatryCodesList })?.value;
      return matchingCode;
    });

    return !codesForReplace;
  };

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      batchMatchDurationForFeeCodeDialog: localStateInitValues.batchMatchDurationForFeeCodeDialog
    }));
  };

  const onReplace = async (rowData) => {
    const feeCodes = rowData[inputs.feeCodes.codeType];
    const startTime = rowData[inputs.startTime.name];
    const endTime = rowData[inputs.endTime.name];

    // Get matching duration code
    const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });
    const matchingCode = findClosestMatchingPsychiatryCode({ startTime, endTime, psychiatryCodesList })?.value;

    // If no matching code, return early
    if (!matchingCode) return;

    const speciality = getValues(inputs.speciality.name);
    const codeType = inputs.feeCodes.codeType;

    // Set fetching state to true during the asynchronous operation
    setFetchingCode(true);

    // Get codes by type and matching code
    const results = await getCodesByType({ codeType, query: matchingCode, speciality });

    // Set fetching state back to false after the operation
    setFetchingCode(false);

    // Find the code in the results that matches the duration code
    const codeForReplacing = results.find((i) => i.value === matchingCode);

    return codeForReplacing;
  };

  const replaceCodes = async () => {
    // Asynchronously execute onReplace for each item in notMatchDurationForPsychiatryCodes
    const replacements = await Promise.all(
      notMatchDurationRows.map(async (rowData) => {
        return await onReplace(rowData);
      })
    );
    // Use lodash's compact function to remove undefined items
    const validReplacements = compact(replacements);

    // Iterate through each item in notMatchDurationForPsychiatryCodes
    notMatchDurationRows.forEach((i) => {
      const groupRowId = i[inputs.groupRowId.name];
      const feeCodes = i[inputs.feeCodes.codeType];
      const startTime = i[inputs.startTime.name];
      const endTime = i[inputs.endTime.name];

      // Get matching duration code
      const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });
      const matchingCode = findClosestMatchingPsychiatryCode({ startTime, endTime, psychiatryCodesList })?.value;

      if (!matchingCode) return;

      // Find the index of the group record based on groupRowId
      const rowIndex = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: groupRowId });

      // Find the replacement code based on the matching code
      const codeForReplace = validReplacements?.find((i) => i.value === matchingCode);

      // Trigger onFeeChange to update the fee code
      onFeeChange([codeForReplace], { rowData: i, rowIndex });
    });

    // Hide the dialog after code replacement
    onHide();
  };

  // VER-677
  const onIgnore = () => {
    onHide();
    handleNextForServicesPicker({ ignoreDurationValidatonForPsychiatry: true });
  };

  const ignoreButton = showIgnoreButton ? <Button type="button" className="p-button-outlined" label={t('Ignore')} onClick={onIgnore} /> : null;

  const actionButtons = showOkButton() ? (
    <>
      {ignoreButton}
      <Button type="button" label={t('Ok')} onClick={onHide} />
    </>
  ) : (
    <>
      <Button type="button" label={t('Replace_all_codes')} loading={fetchingCode} onClick={replaceCodes} />
      {ignoreButton}
      <Button type="button" className="p-button-outlined" label={t('Close')} onClick={onHide} />
    </>
  );

  return (
    <Dialog
      id={elementIDs.batchMatchDurationForFeeCodeDialog}
      header={t('Warning')}
      visible={showDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg flex flex-column gap-4">
        {notMatchDurationRows?.map((i) => {
          const groupRowId = i[inputs.groupRowId.name];
          const feeCodes = i[inputs.feeCodes.codeType];
          const startTime = i[inputs.startTime.name];
          const endTime = i[inputs.endTime.name];
          const patient = i[inputs.patient.name]?.[0];
          const message = getInvalidDurationMessageForPsychiatryCodes({ feeCodes, startTime, endTime });
          const rowIndex = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: groupRowId });

          return (
            <div className="flex flex-column gap-2" key={i[inputs.groupRowId.name]}>
              <h5 className="m-0">{`Line ${rowIndex + 1} - ${patientFullName(patient)}`}</h5>
              {`${message}`}
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default BatchDurationForPsychiatryCodesDialog;
