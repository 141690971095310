import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { stringFormat } from '../../../../../utils/stringFormat';
import { isTimeValid } from '../../../../../utils/formatTime';
import { calculateUnits } from '../../helpers/calculateUnits';
import { t } from '../../../../../../service/localization/i18n';

const FeeCodeDurationDialog = () => {
  const { localState, setLocalState, getValues } = useFormContext();

  if (!localState.feeCodeDurationDialog) return null;

  const getFeeCodesOnly = () => {
    const startTime = getValues(inputs.startTime.name);
    const endTime = getValues(inputs.endTime.name);
    const isStartTimeValid = isTimeValid(startTime);
    const isEndTimeValid = isTimeValid(endTime);
    if (isStartTimeValid && isEndTimeValid) {
      const feeCodes = getValues(inputs.feeCodes.codeType);
      const shortDurationCodes = feeCodes?.filter((i) => {
        const units = calculateUnits(i, startTime, endTime);
        return units === 0;
      });
      const feeCodesOnly = shortDurationCodes?.map((i) => i.value);
      return feeCodesOnly;
    }

    return [];
  };

  const onHide = () => {
    setLocalState((prevState) => ({ ...prevState, feeCodeDurationDialog: false }));
  };

  const actionButtons = <Button id={elementIDs.dialogClose} label={t('Ok')} onClick={onHide} />;

  return (
    <Dialog
      header={t('Warning')}
      visible={localState.feeCodeDurationDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      onHide={onHide}
      footer={actionButtons}
    >
      {stringFormat(t('The_fee_code_xxx_requires_longer_duration'), getFeeCodesOnly()?.join()?.replaceAll(',', ', '))}
    </Dialog>
  );
};

export default FeeCodeDurationDialog;
