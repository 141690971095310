import moment from 'moment';
import { store } from '../../../store';
import { setClaimsDialogVisibility } from '../actions/cashFlow.axtion.creators';

export const onBarClick = (event, activeElements) => {
  const dateFormat = 'MMM Do, YYYY';
  const requestParams = store.getState().cashFlow.requestParams;

  if (activeElements.length > 0) {
    const chart = activeElements[0].element.$context.chart;
    const dataIndex = activeElements[0].index; // Get index of the bar
    const clickedDate = chart.data.labels[dataIndex]; // Date label for the clicked bar

    store.dispatch(
      setClaimsDialogVisibility({
        visible: true,
        periodFrom: moment(clickedDate, dateFormat),
        requestParams: {
          ...requestParams,
          query: {
            ...requestParams.query,
            PeriodFrom: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
            PeriodTo: moment(clickedDate, dateFormat).format('YYYY-MM-DD'),
            Period: '7',
            SubmissionPeriodFrom: '2000-01-01', // Hack
            SubmissionPeriodTo: moment().format('YYYY-MM-DD'),
            SubmissionPeriod: '7',
            InvoiceType: 'Teleplan'
          }
        }
      })
    );
  }
};
