import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import Alphabet from '../../../../../../../components/Misc/Alphabet/Alphabet';

import { getReferralCodesByLetter, updateInvoicesQuickPickCodesList } from '../../../../../actions/claims.action.creators';
import { inputs } from '../../../helpers/inputs';
import { getCategoryType } from '../../../../../helpers/getCategoryType';
import { getRecentCodes } from '../../../../../../../service/Lookup';
import { unionBy } from 'lodash';

const ReferralQuickPickAlphabetFilter = () => {
  const dispatch = useDispatch();
  const { setLocalState, getValues } = useFormContext();

  const onLetterClick = async (letter) => {
    setLocalState((prevState) => ({ ...prevState, getingReferralCodesByLetter: true }));

    if (letter) {
      const results = await getReferralCodesByLetter({
        letter,
        pageNumber: 1,
        pageSize: 25
      });
      if (results) {
        setLocalState((prevState) => ({ ...prevState, getingReferralCodesByLetter: false }));
        dispatch(updateInvoicesQuickPickCodesList(results, inputs.referral.codeType));
      }
    } else {
      const patients = getValues(inputs.patient.name);
      const patientGuid = patients[0]?.PatientGuid;
      const recontCodesResponse = await getRecentCodes(patientGuid);

      if (recontCodesResponse) {
        setLocalState((prevState) => ({ ...prevState, getingReferralCodesByLetter: false }));
        const invoiceType = getValues(inputs.payor.name);
        const referralCodes = getValues(inputs.referral.codeType);
        const referralType = getCategoryType(inputs.referral.codeType, invoiceType);
        // Merge the current referral codes with the updated referral codes from the response,
        // ensuring uniqueness based on the "value" property of the objects
        const updatedRecentList = unionBy(referralCodes, recontCodesResponse[referralType], 'value');
        dispatch(updateInvoicesQuickPickCodesList(updatedRecentList, inputs.referral.codeType));
      }
    }
  };

  return (
    <div className="pb-3">
      <Alphabet onClick={onLetterClick} />
    </div>
  );
};

export default ReferralQuickPickAlphabetFilter;
