import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { InputWrapper, NoteEditorWithFormControl } from '../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { decodeText, encodeText } from '../../utils/decodeText';
import { v4 as uuidv4 } from 'uuid';
import { t } from '../../../service/localization/i18n';

const Reply = ({ note, onReply }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const DCNGuid = user?.DCNGuid || '';
  const DoctorGuid = user?.UserGuid || '';

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting }
  } = useForm({
    defaultValues: {
      Title: note?.Title && decodeText(note.Title).startsWith('RE:') ? decodeText(note.Title) : `RE: ${decodeText(note?.Title)}`,
      Comment: ''
    }
  });

  const onSubmit = async (data) => {
    const newNote = {
      ...note,
      DCNGuid,
      DoctorGuid,
      Title: encodeText(data.Title),
      Comments: encodeText(data.Comment),
      NoteGuid: uuidv4(),
      NoteDate: new Date()
    };

    reset(data, { keepValues: true });

    await onReply(newNote);
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty });
  };

  return (
    <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <Prompt when={isDirty} message={promptMessage} />

      <Controller
        name="Title"
        control={control}
        rules={{ required: t('Mandatory_field.1') }}
        render={({ field }) => (
          <InputWrapper label="Title" required errorMessage={errors?.Title?.message}>
            <InputText {...field} id="newTitle" className={errors?.Title ? 'p-invalid' : ''} />
          </InputWrapper>
        )}
      />

      <NoteEditorWithFormControl id="newComment" control={control} name="Comment" autoFocus />

      <div className="flex column-gap-3 justify-content-end mt-auto">
        {isDirty ? (
          <>
            <Button type="button" outlined label="Cancel" onClick={() => history.goBack()} />
            <Button loading={isSubmitting} type="submit" label="Save" />
          </>
        ) : (
          <Button type="button" outlined label="Close" onClick={() => history.goBack()} />
        )}
      </div>
    </form>
  );
};

export default Reply;
