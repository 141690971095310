import { store } from '../../../../../store';
import { setClean } from '../../../../core/actions/core.action.creators';
import { setPatientPickerQueryValue, setPrivateRecord, shouldResetForm } from '../../../actions/claims.action.creators';

export const resetPrivateForm = (resetForm = true) => {
  store.dispatch(setClean());
  store.dispatch(setPrivateRecord({}));
  resetForm && store.dispatch(shouldResetForm(true));
  store.dispatch(setPatientPickerQueryValue('')); // CMO-1365 - New Teleplan claim->Patient field->type in PHN->No matches->Carry PHN from Patient field into PHN field on the Add patient screen
};
