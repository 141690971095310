import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import CreateGroupTemplate from '../../views/TeleplanGroup/Layouts/CreateGroupTemplate';

import { patientLastNameWithInitials } from '../../../../../utils/patientFullName';
import { defaultGuid } from '../../../../../config/defaultValuesConfig';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';
import { uniqBy } from 'lodash';
import { t } from '../../../../../../service/localization/i18n';

const CreateGroupTemplateDialog = () => {
  const { watch, localState, setLocalState } = useFormContext();

  const getInitialDescription = () => {
    const groupRecords = localState.groupRecords;
    // Get all unique codes
    const uniqueCodes = uniqBy(groupRecords, (record) => record[inputs.feeCodes.codeType][0]?.value).map(
      (i) => i[inputs.feeCodes.codeType][0]?.value
    );
    const patientsName = watch(inputs.patient.name)
      ?.map((i) => patientLastNameWithInitials(i))
      ?.sort();

    return `${patientsName?.join()?.replaceAll(',', ', ')}, ${uniqueCodes?.join()?.replaceAll(',', ', ')}`;
  };

  const defaultValues = {
    DCNGuid: '',
    DoctorGuid: defaultGuid,
    TemplateGuid: defaultGuid,
    Name: '',
    Description: getInitialDescription(),
    DateCreated: '',
    Status: 1,
    Records: [],
    FirstStep: {}
  };

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, createGroupTemplateDialog: false }));
  };

  return (
    <Dialog
      className="createGroupTemplateDialog"
      id={elementIDs.createGroupTemplateDialog}
      header={t('Create_Template')}
      visible={localState.createGroupTemplateDialog}
      breakpoints={{ '1366px': '60vw', '960px': '70vw', '768px': '95vw' }}
      style={{ width: '45vw' }}
      onHide={onHideDialog}
    >
      <CreateGroupTemplate defaultValues={defaultValues} />
    </Dialog>
  );
};

export default CreateGroupTemplateDialog;
