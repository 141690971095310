import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { checkIfCodeValidForLocation, locationCodeInputRequirements } from '../../../helpers/validationSchema';
import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { locationCodesDropdownOptions } from '../../../helpers/dropdownOptions';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const LocationCode = () => {
  const name = inputs.locationCode.name;
  const { control, errors, watch } = useFormContext();
  const { isLocked, onSavePrefs } = usePreferences();
  const feeCodes = watch(inputs.feeCodes.codeType);
  const locationCode = useWatch({ control, name });
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const required = locationCodeInputRequirements(saveAsDraft);

  const defaultCode = prefsCodes.defaultLocation;
  const presetCode = prefsCodes.presetLocation;

  const onSavePreferences = () => {
    const preferences = [
      { value: '1', label: presetCode },
      { value: locationCode, label: defaultCode }
    ];

    onSavePrefs(preferences);
  };

  const onChange = (locationCode, field) => {
    field.onChange(locationCode);
    const feeCodesOnly = feeCodes?.map((i) => i.value);
    // Show warn message if FeeCodes conflicts with LocationCode
    checkIfCodeValidForLocation(feeCodesOnly, locationCode, speciality);
  };

  const locationCodeInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.locationCode.label}
        required={required}
        errorMessage={errors[name]?.message}
        isLocked={isLocked(locationCode, defaultCode)}
        onSavePrefs={onSavePreferences}
      >
        <Dropdown
          id={elementIDs.locationCodeDropdown}
          className={cx({ 'p-invalid': errors[name]?.message })}
          inputRef={field.ref}
          value={field.value}
          type="text"
          placeholder={t('Select_code')}
          scrollHeight={dropdownScrollHeight()}
          options={locationCodesDropdownOptions}
          onChange={(e) => onChange(e.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => locationCodeInput(field)} />;
};

export default LocationCode;
