import React from 'react';
import { t } from '../../../service/localization/i18n';
import { formatZipCode } from '../../utils/formatZipCode';

const AddressLayout = ({ data, additionalAddressData, className, direction = 'column' }) => {
  // direction - "column" or "row"

  const city =
    data?.City?.trim() && data?.Province?.trim() ? `${data?.City}, ${data?.Province && data?.Province}` : `${data?.Province && data?.Province}`;
  const fullAddress = data?.Zip ? `${data?.AddressLine} ${city}, ${formatZipCode(data?.Zip)}` : `${data?.AddressLine} ${city}`;
  const addressLine = data?.AddressLine?.trim();

  return (
    <>
      {(!data || (!addressLine && !data?.City && !additionalAddressData)) && <>{t('No_address')}</>}

      {data && !addressLine && !data?.City && <>{additionalAddressData}</>}

      {data && addressLine && data?.City && (
        <>
          {direction === 'column' && (
            <div className={className ? className : 'flex flex-column'}>
              <div className={className ? 'mr-1' : 'mb-1'}>{addressLine}</div>

              {(data?.City || data?.Province) && (
                <div className={className ? 'mr-1' : 'mb-1'}>
                  <span>
                    {city}
                    {data?.Zip && `, ${formatZipCode(data?.Zip)}`}
                  </span>
                </div>
              )}
            </div>
          )}

          {direction === 'row' && (
            <>
              {additionalAddressData ? (
                <div className="flex flex-column">
                  <div>{additionalAddressData}</div>
                  <div>{fullAddress}</div>
                </div>
              ) : (
                // `${additionalAddressData}, ${fullAddress}`
                fullAddress
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AddressLayout;
