import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { patientFullName, patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';

const ServiceDateSection = ({ data, isFetching }) => {
  const fullName = () => {
    return data[commonInputs.patients.descriptionName] ? patientFullNameWithAge(data[commonInputs.patients.descriptionName]?.[0]) : t('N_A');
  };

  return (
    <div className="invoiceSectionWrap">
      <div className="flex align-items-center bill-to mb-2">
        <div className="serviceDate">{`${t('Service_date')}:`}</div>
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="w-full text-right">{moment(data?.ServiceDate).format('MM/DD/YYYY')}</div>
        )}
      </div>

      <div className="flex align-items-start mb-2">
        <div className="font-semibold serviceDate">{`${t('Patient.1')}:`}</div>
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="w-full text-right font-bold">
            {data[commonInputs.patients.descriptionName]?.length && fullName()}
            {!data[commonInputs.patients.descriptionName]?.length && patientFullName(data)}
            {/* {!data[commonInputs.patients.descriptionName]?.length && `${data?.LastName}, ${data?.FirstName}`} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceDateSection;
