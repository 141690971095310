import actionTypes from '../actions/clinic.action.types';

export const clinicInitialState = {
  details: {},
  members: [],
  filters: [],
  rooms: [],
  locations: [],
  clinicEditing: false,
  practitionerEditing: false
};

const clinicReducer = (state = clinicInitialState, action) => {
  const { results } = action;

  switch (action.type) {
    case actionTypes.FETCH_CLINIC_SUCCESS:
      return {
        ...state,
        details: results
      };

    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: results
      };

    case actionTypes.FETCH_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: results
      };

    case actionTypes.UPDATE_CLINIC_FAILURE:
      return state;

    case actionTypes.FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        members: results
      };

    case actionTypes.FETCH_CLINIC_FILTERS_SUCCESS:
      return {
        ...state,
        filters: results.data.List
      };

    case actionTypes.CLINIC_EDITING:
      return {
        ...state,
        clinicEditing: results
      };

    case actionTypes.PRACTITIONER_EDITING:
      return {
        ...state,
        practitionerEditing: results
      };

    default:
      return state;
  }
};

export default clinicReducer;
