import { useFormContext } from 'react-hook-form';

export const useToggleCatalogs = () => {
  const { setLocalState } = useFormContext();

  const toggleCatalogs = (view) => {
    setLocalState((prevState) => ({
      ...prevState,
      showCatalogs: {
        ...prevState.showCatalogs,
        [view]: !prevState.showCatalogs[view]
      }
    }));
  };

  return { toggleCatalogs };
};
