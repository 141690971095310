

export function formatPID(num) {

    if (num == null) {
        return num;
    }

    num = String(num);

    if (num.length < 7) {
        return num;
    }

    return num.substring(0, 3) + '-' + num.substring(3);

}