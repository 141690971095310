import React from 'react';

const CommonInputField = ({
  label,
  labelId = '',
  children,
  errorMessage,
  required = false,
  className = '',
  style = false,
  direction = 'column',
  description = ''
}) => {
  const error = () => {
    if (errorMessage) {
      return <div className="p-error">{errorMessage}</div>;
    }

    return null;
  };

  return (
    <div className={className ? className : 'col-12 p-0 mb-1'} style={style ? style : { height: '90px' }}>
      {direction === 'column' && (
        <div className="field">
          {/* Label */}
          <label id={labelId} className="text-disabled-color mb-0">
            {label}
            {required && <span className="ml-1 p-error">*</span>}
          </label>

          {/* Body */}
          {children}

          {/* Error message */}
          {error()}

          {description && <span className="input_description_styles">{description}</span>}
        </div>
      )}

      {direction === 'row' && (
        <>
          <div className="flex align-items-center">
            {/* Label */}
            <label className="text-disabled-color mr-4 mb-0">
              {label}
              {required && <span className="ml-1 p-error">*</span>}
            </label>

            {/* Body */}
            {children}
          </div>

          {/* Error message */}
          {error()}
        </>
      )}
    </div>
  );
};

export default CommonInputField;
