import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { t } from '../../../../../../service/localization/i18n';

const AddNewPolicy = ({ isPrimary = false }) => {
  const { watch, setLocalState } = useFormContext();
  const patients = watch(inputs.patient.name);

  const onAddClick = () => {
    setLocalState((prevState) => ({
      ...prevState,
      eClaimPolicy: {
        showEClaimPolicyForm: true,
        policyInfo: {
          [commonInputs.patient.name]: Array.isArray(patients) ? patients : [patients],
          [commonInputs.isPrimary.name]: isPrimary
        }
      }
    }));
  };

  return <Button className="p-button-text" type="button" icon="pi pi-plus" label={t('Add_New_Policy')} onClick={onAddClick} />;
};

export default AddNewPolicy;
