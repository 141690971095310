import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { anatomicCodesDropdownOptions } from '../../../helpers/dropdownOptions';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const AnatomicCode = ({ hideLabel = false }) => {
  const name = inputs.anatomicCode.name;
  const { control } = useFormContext();

  const patientPeriodInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.anatomicCode.label} hideLabel={hideLabel}>
        <Dropdown
          id={elementIDs.anatomicCode}
          type="text"
          value={field.value}
          options={anatomicCodesDropdownOptions}
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => patientPeriodInput(field)} />;
};

export default AnatomicCode;
