import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import NotesCounter from '../../../ClaimDetails/NotesCounter';

import { CONTACT_PHONE_8 } from '../../../../../../config';
import { inputs } from '../../helpers/inputs';
import { invoiceTypes } from '../../helpers/defaultValues';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';

const HeaderInfo = () => {
  const { gettingWSBCReport } = useSelector((state) => state.claims);
  const { isNew, watch, isMobile, localState, declinedStatus } = useFormContext();
  const invoiceType = watch(inputs.payor.name);
  const status = Number(watch(inputs.status.name));
  const statusText = watch(inputs.statusText.name);
  const recordNumber = watch(inputs.recordNumber.name);
  const invoiceGuid = watch(inputs.invoiceGuid.name);
  const totalNotes = watch('TotalNotes');
  const sequenceNum = watch(inputs.sequenceNum.name) || t('N_A').toLowerCase();
  const isLoading = gettingWSBCReport || localState.isLoading;
  const isPaid = Number(status) === 2;
  const isInProcess = Number(status) === 1;
  const isDeclined = Number(status) === 3 || Number(status) === -3 || Number(status) === 6 || Number(status) === 9;
  const isWSBC = invoiceType === invoiceTypes.wsbc;
  const showMessage = (declinedStatus || isInProcess || isPaid) && !isNew;

  const statusLayout = () => {
    if (isLoading) return <Skeleton width="110px" height="17px" />;

    return (
      <div style={{ minWidth: '110px' }}>
        <span id={elementIDs.statusChip} className={cx('customer-badge-mobile py-1', `cm_status_${status}`)}>
          {isNew ? t('New') : statusText}
        </span>
      </div>
    );
  };

  const numbersLayout = (
    <div className="flex justify-content-between align-items-center w-full">
      <div className="flex">
        <div className="flex mr-3">
          <span className="mr-2">{t('Office.1')}:</span>
          {isLoading ? (
            <Skeleton width="45px" height="17px" />
          ) : (
            <b id={elementIDs.recordNumber}>{isNew ? t('N_A').toLowerCase() : trimLeftZeros(recordNumber)}</b>
          )}
        </div>

        <div className="flex mr-3">
          <span className="mr-2">{t('Sequence.1')}:</span>
          {isLoading ? <Skeleton width="45px" height="17px" /> : <b id={elementIDs.sequenceNumber}>{isNew ? t('N_A').toLowerCase() : sequenceNum}</b>}
        </div>
      </div>

      {!isNew && (
        <div className="flex justify-content-end align-items-center col-1 p-0">
          <div className="flex align-items-center justify-content-center gap-2">
            <NotesCounter totalNotes={totalNotes} invoiceGuid={invoiceGuid} />
            <div>
              <Tooltip target=".target-icon" position={isMobile ? 'left' : 'top'} style={{ minWidth: '195px' }}>
                <div>{`${t('Date_created')}: ${watch(inputs.dateCreated.name)}`}</div>
                {watch(inputs.sentDate.name) && status !== 0 && `${t('Sent')}: ${watch(inputs.sentDate.name)}`}
                <div>{`${t('Date_updated')}: ${watch(inputs.dateUpdated.name)}`}</div>
              </Tooltip>
              <i id={elementIDs.createdUpdatedInfoButton} className="pi pi-info-circle target-icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const messageLayout = (
    <div className="flex flex-column gap-2">
      {/* Declined */}
      {declinedStatus && <div className="text-lg w-full">{t('Based_on_Declined_Claim')}</div>}

      {/* In process */}
      {isInProcess && <div className="text-lg w-full">{t('Based_on_In_Process_Claim')}</div>}

      {/* Paid */}
      {isPaid && <div className="text-lg w-full">{t('Based_on_Accepted_Claim')}</div>}

      <div className="flex">{numbersLayout}</div>

      {watch(inputs.mspCodes.name)?.map((i, index) => {
        return (
          <div id={`${elementIDs.mspCodeError}_${index}`} className="my-3 p-error" key={index}>
            {i}
          </div>
        );
      })}

      {(isPaid || isInProcess || isDeclined) && isWSBC && (
        <div>
          {`Please call WSBC support line at `}
          <a href={`tel:${CONTACT_PHONE_8}`}>{CONTACT_PHONE_8}</a>
          {` to ensure that this claim can be re-billed`}
        </div>
      )}
    </div>
  );

  return (
    <>
      {showMessage && (
        <Message id={elementIDs.teleplanMessageForDeclinedInvoice} className="w-full justify-content-start" severity="warn" content={messageLayout} />
      )}

      {!showMessage && (
        <div className="flex" style={{ minHeight: '20px', columnGap: '15px' }}>
          {statusLayout()}
          {numbersLayout}
        </div>
      )}
    </>
  );
};

export default HeaderInfo;
