import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import HighlightSearchQuery from '../../../../../../../components/Misc/HighlightSearchQuery/HighlightSearchQuery';
import { InputWrapper } from '../../../../../../../components/Inputs';
import { AutoComplete } from 'primereact/autocomplete';
import { Tooltip } from 'primereact/tooltip';

import { useService } from '../../../hooks/useService';
import { useValidation } from '../../../hooks/useValidation';
import { useConditions } from '../../../hooks/useConditions';
import { inputs } from '../../../helpers/inputs';
import { validate } from 'uuid';
import { removeAllSpecialCharacters } from '../../../../../../regex/regex';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const ServiceAutocomplete = React.forwardRef((props, ref) => {
  const { getTooltipForService } = useService();
  const [query, setQuery] = useState('');
  const [filtered, setFiltered] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  const placeholder = props.maxEntry === props.value?.length || props.disabled ? ' ' : props.placeholder || t('Search_for_codes');
  const classNameForTests = cx('block', props.isInvalid ? 'p-invalid' : '', props.className ? props.className : '');
  const classNameForLive = cx('block', props.isInvalid ? 'p-invalid' : '', props.className ? props.className : '', {
    code_picker_autocomplete_single_code: props.maxEntry === 1 && props.value?.length === 1
  });
  const className = process.env.REACT_APP_CYPRESS ? classNameForTests : classNameForLive;
  const inputId = props.inputId || `${props.name}_input`;

  // Set suggestion list
  useEffect(() => {
    if (props.suggestions) {
      setSuggestions(props.suggestions);
    }
  }, [props.suggestions]);

  const search = (event) => {
    let _filtered;
    setQuery(event.query);

    if (!event.query.trim().length) {
      _filtered = [...suggestions];
    } else {
      _filtered = suggestions.filter((item) => {
        // Change the condition to check if the label includes the search query
        return item.label.toLowerCase().includes(event.query.toLowerCase());
      });
    }

    setFiltered(_filtered);
  };

  const onChange = (e) => {
    props.onChange(e, props.input);
  };

  const itemTemplate = (item) => {
    return <HighlightSearchQuery query={query} text={`${item?.text}: ${item?.label}`} />;
  };

  const selectedItemTemplate = (code) => {
    const formattedCode = code.value.replaceAll(removeAllSpecialCharacters, '');
    const id = `${inputId}_${code.value?.replaceAll('.', '')}`;
    const className = cx(
      props.selectedItemTemplateClass ? props.selectedItemTemplateClass(code.value?.trim()) : '',
      `codeAutocompleteTooltip_${formattedCode}`
    );

    return (
      <>
        <Tooltip target={`.codeAutocompleteTooltip_${formattedCode}`} position="top">
          {getTooltipForService(code)}
        </Tooltip>
        <div id={id} className={className} style={{ color: 'black' }}>
          {code?.label}
        </div>
      </>
    );
  };

  return (
    <AutoComplete
      className={className}
      inputClassName="w-full"
      field="text"
      inputRef={ref}
      inputId={props.inputId || props.name}
      value={props.value}
      suggestions={filtered}
      forceSelection
      autoHighlight
      dropdown
      multiple
      placeholder={placeholder}
      disabled={props.disabled}
      itemTemplate={itemTemplate}
      selectedItemTemplate={selectedItemTemplate}
      scrollHeight={dropdownScrollHeight()}
      completeMethod={search}
      onChange={onChange}
      onFocus={() => ref?.current?.select()}
    />
  );
});

const Service = ({ index }) => {
  const name = `${inputs.services.name}.${index}`;
  const { control, localState, isView } = useFormContext();
  const { onServiceChange } = useService();
  const { serviceValidation } = useValidation();
  const { isPractitionerSelected } = useConditions();
  const servicesList = Array.isArray(localState.servicesList) ? localState.servicesList : [];
  const disabled = isView || !isPractitionerSelected;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => serviceValidation(value) }}
      render={({ field, fieldState: { error } }) => {
        const value = field.value?.value ? [field.value] : [];
        const fieldValue = validate(field.value?.value) ? 'label' : 'value';

        return (
          <InputWrapper required={!isView} name={inputs.service.name} label={inputs.service.label} errorMessage={error?.message}>
            <ServiceAutocomplete
              {...field}
              value={value}
              maxEntry={1}
              field={fieldValue}
              isInvalid={!!error?.message}
              suggestions={servicesList}
              disabled={disabled}
              onChange={(e) => onServiceChange(e, index)}
            />
          </InputWrapper>
        );
      }}
    />
  );
};

export default Service;
