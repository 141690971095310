import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { columnValues } from '../helpers/columnValues';
import { getPatients } from '../../../actions/patients.action.creators';
import { debounce } from 'lodash';

const TextInput = ({ columnKey }) => {
  const currentColumn = columnValues[columnKey];
  const currentField = currentColumn.filter;

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.patients);
  const [value, setValue] = useState(filters[currentField] || '');

  // Update local state when chip is deleted
  useEffect(() => {
    setValue(filters[currentField] || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[currentField]]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRequest = useCallback(
    debounce((params) => dispatch(getPatients(params)), 1000),
    []
  );

  const onChange = (e) => {
    const _value = e.target.value;
    const updatedFilters = { ...filters, [currentField]: _value };
    setValue(_value);
    debouncedRequest({ filters: updatedFilters, page: 0 });
  };

  return <InputText type="search" value={value} placeholder={currentColumn.placeholder} onChange={onChange} />;
};

export default TextInput;
