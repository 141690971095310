import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const SaveAsDraft = () => {
  const name = inputs.saveAsDraft.name;
  const { control, setValue } = useFormContext();

  const onChange = (checked, field) => {
    const status = checked ? 4 : 0;
    const statusText = checked ? 'Draft' : 'Outstanding';
    field.onChange(checked);
    setValue(inputs.status.name, status);
    setValue(inputs.statusText.name, statusText);
  };

  const saveAsDraftInput = (field) => {
    return (
      <div className="p-field-checkbox pt-3">
        <Checkbox
          className="mr-3"
          type="checkbox"
          id={elementIDs.saveAsDraft}
          inputId={name}
          checked={field.value}
          onChange={(e) => onChange(e.checked, field)}
        />
        <label htmlFor={name}>{inputs.saveAsDraft.label}</label>
      </div>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => saveAsDraftInput(field)} />;
};

export default SaveAsDraft;
