import React from 'react';
import PageWrap from './PageWrap';

const PageWithCard = ({ children }) => {
  return (
    <PageWrap>
      <div className="card w-full m-0">{children}</div>
    </PageWrap>
  );
};

export default PageWithCard;
