import React, { useEffect } from 'react';
import PatientForm from '../PatientForm/PatientForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getPatientInfo } from '../../actions/patients.action.creators';
import { useDispatch } from 'react-redux';

const EditProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    dispatch(getPatientInfo({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PatientForm />
    </>
  );
};

export default EditProfile;
