import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import FormTitle from '../../modules/Layouts/FormTitle';
import ViewClaimOptionButton from './ViewClaimOptionButton';
import StatusBadge from '../../../../../../components/Misc/Badges/Status/StatusBadge';

import { inputs } from '../../helpers/inputs';
import { onEOB } from '../../../../helpers/rowActions';
import { Button } from 'primereact/button';

const TitleLayout = () => {
  const history = useHistory();
  const { getValues } = useFormContext();
  const eClaim = getValues();
  const status = getValues(inputs.status.name);
  const isOutstanding = status === 0;

  return (
    <div className="flex justify-content-between align-items-center gap-3">
      <div></div>
      <FormTitle />
      <div className="flex align-items-center gap-4">
        {!isOutstanding && <StatusBadge status={status} onClick={() => onEOB({ eClaim })} />}
        <ViewClaimOptionButton />
        <Button
          icon="pi pi-times"
          className="p-button-text p-button-rounded"
          tooltip="Close"
          tooltipOptions={{ position: 'top' }}
          onClick={() => history.goBack()}
        />
      </div>
    </div>
  );
};

export default TitleLayout;
