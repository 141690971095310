import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createInsurancePolicy, editInsurancePolicy } from '../../../actions/claims.action.creators';
import { dataForSubmit } from '../helpers/dataForSubmit';
import { addEClaim } from '../../../helpers/addNewInvoice';
import { inputs } from '../helpers/inputs';

export const useSubmit = ({ reset, localState, setLocalState, isNew, isEdit, onCreate, onEdit, isNewForPatient }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    // Create new
    if (isNew || (isNewForPatient && !isEdit)) return handleCreate(data);

    // Edit invoice
    if (isEdit) return handleEdit(data);
  };

  const handleCreate = (data) => {
    // reset the isDirty value and keep form state
    const formatedData = dataForSubmit(data);

    // Show loading
    setLocalState((prevState) => ({
      ...prevState,
      saving: {
        save: !localState.saveAndClaim,
        saveAndClaim: localState.saveAndClaim
      }
    }));

    dispatch(
      createInsurancePolicy(formatedData, (responseData) => {
        reset({}, { keepValues: true });
        setLocalState((prevState) => ({ ...prevState, saving: { save: false, saveAndClaim: false } }));

        // Click on "Save and Claim"
        if (localState.saveAndClaim) {
          setLocalState((prevState) => ({ ...prevState, saveAndClaim: false }));
          const patientGuid = data?.[inputs.patient.name][0]?.PatientGuid;
          // Redirect to new claim form
          addEClaim({ history, patientGuid, replaceRoute: true });
        }

        // Click on "Save"
        if (!localState.saveAndClaim) {
          if (onCreate) {
            onCreate(responseData);
          } else {
            setLocalState((prevState) => ({ ...prevState, step: 2 }));
          }
        }
      })
    );
  };

  const handleEdit = (data) => {
    // reset the isDirty value and keep form state
    const formatedData = dataForSubmit(data);
    // Show loading
    setLocalState((prevState) => ({ ...prevState, saving: { save: true } }));

    dispatch(
      editInsurancePolicy(formatedData, () => {
        reset({}, { keepValues: true });
        setLocalState((prevState) => ({ ...prevState, saving: { save: false } }));

        if (onEdit) {
          onEdit(formatedData);
        } else {
          setLocalState((prevState) => ({ ...prevState, step: 2 }));
        }
      })
    );
  };

  return { onSubmit };
};
