import React from 'react';

import { Button } from 'primereact/button';

import { quickPickCodeChipCommonClass } from '../../../../../../../../config/stylesConfig';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const commonNotesList = [
  {
    value: 'CCFPP',
    label: t('CCFPP')
  }
];

const CommonNotes = ({ rowOptions }) => {
  const { onCommentChange } = useGroupPickerInputs();

  const onNoteClick = (note, isSelected) => {
    const comment = isSelected ? '' : note;
    onCommentChange(comment, rowOptions);
  };

  return (
    <div className="quick_pick_items_list">
      {commonNotesList?.map((i) => {
        const isSelected = i.value === rowOptions.rowData[inputs.comment.name];
        return (
          <Button
            key={i.value}
            tabIndex={-1}
            className={quickPickCodeChipCommonClass(isSelected)}
            label={i.label}
            onClick={() => onNoteClick(i.value, isSelected)}
          />
        );
      })}
    </div>
  );
};

export default CommonNotes;
