import React from 'react';
import CodeChip from './CodeChip';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { removeAllSpecialCharacters } from '../../../../modules/regex/regex';

const Chips = () => {
  const { catalogsState, getSelectedCodes, onCodeChipClick, codeChipLabel } = useCatalogsContext();
  const codes = getSelectedCodes(catalogsState?.activeTab);

  return (
    <>
      {codes?.map((i, index) => {
        const label = codeChipLabel ? codeChipLabel(i) : null;
        const tooltip = i.text || i.label;
        const id = `catalogsCodeChip_${i.value.replaceAll(removeAllSpecialCharacters, '')}`;
        return (
          <span key={`${i?.value}_${index}`} className="pr-2">
            <CodeChip id={id} item={i} tooltip={tooltip} isSelected={true} label={label} onClick={() => onCodeChipClick(i)} />
          </span>
        );
      })}
    </>
  );
};

export default Chips;
