const constants = {
  SET_CLAIM_NOTES: 'SET_CLAIM_NOTES',
  CLEAR_CLAIM_NOTES: 'CLEAR_CLAIM_NOTES',
  SET_PATIENT_NOTES: 'SET_PATIENT_NOTES',
  CLEAR_PATIENT_NOTES: 'CLEAR_PATIENT_NOTES',
  SET_CLAIM_NOTE_DETAILS: 'SET_CLAIM_NOTE_DETAILS',
  CLEAR_CLAIM_NOTE_DETAILS: 'CLEAR_CLAIM_NOTE_DETAILS',
  SET_PATIENT_NOTE_DETAILS: 'SET_PATIENT_NOTE_DETAILS',
  CLEAR_PATIENT_NOTE_DETAILS: 'CLEAR_PATIENT_NOTE_DETAILS',
  GET_TOTAL_UNREAD_NOTES: 'GET_TOTAL_UNREAD_NOTES'
};

export default constants;
