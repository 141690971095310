import { useDispatch, useSelector } from 'react-redux';
import { archiveClaimRecord, getClaimList, getListOfClaims } from '../../../../modules/claims/actions/claims.action.creators';
import { claimsDefaultSortBy } from '../../../../modules/config/defaultValuesConfig';
import { useSimilarClaimsListContext } from './useSimilarClaimsListContext';
import { defaultRequestParams } from '../helpers/defaultValues';
import moment from 'moment';
import { updatedClaimListParams } from '../../../../modules/claims/helpers/rowActions';

export const useTable = () => {
  const dispatch = useDispatch();
  const moneyTablePatientGuid = useSelector((state) => state.claims.PatientGuid);
  const { state, setState } = useSimilarClaimsListContext();
  const currentRequestParams = {
    patientGuid: state.PatientGuid,
    page: state.page,
    pageSize: state.pageSize,
    query: state.query,
    sortby: state.sortby
  };

  const tableStyle = {
    minWidth: '30rem'
  };

  // Check for screen width and update style accordingly
  if (window.innerWidth >= 968) {
    tableStyle.minWidth = '60rem';
  }

  const fetchData = async (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const requestParams = {
      ...defaultRequestParams,
      ...params
    };
    const results = await getListOfClaims(requestParams);
    if (results) {
      setState((prevState) => ({
        ...prevState,
        ...results,
        loading: false,
        selectedItem: state.claim
      }));
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const onSelectionChange = (e) => {
    setState((prevState) => ({ ...prevState, selectedItem: e.value }));
  };

  const onSort = (e) => {
    const sortOrders = { 1: 'asc', '-1': 'desc' };
    const sortby = e.sortOrder === 0 ? claimsDefaultSortBy.sortOrder : `${e.sortField}_${sortOrders[e.sortOrder]}`;
    fetchData({ ...currentRequestParams, sortby });
  };

  const onPage = (e) => {
    fetchData({ ...currentRequestParams, page: e.page + 1 });
  };

  const onArchive = (claim) => {
    dispatch(
      archiveClaimRecord(claim, () => {
        const claimListParams = updatedClaimListParams(moneyTablePatientGuid);
        const serviceDate = moment(state.claim.ServiceDate).format('YYYY/MM/DD');
        dispatch(getClaimList(claimListParams));

        const similarClaimsPatientGuid = state.filters?.patient?.PatientGuid;
        similarClaimsPatientGuid &&
          fetchData({
            patientGuid: similarClaimsPatientGuid,
            query: {
              FeeCode: state.claim.FeeCode,
              Period: '7',
              showArchived: '',
              PeriodFrom: serviceDate,
              PeriodTo: serviceDate,
              ServiceDate: serviceDate
            }
          });
      })
    );
  };

  const isDeclinedOrNotPaid = (status) => {
    return Number(status) === 3 || Number(status) === 6;
  };

  const showArchiveColumn = state.claimList?.some((i) => {
    const status = Number(i.Status);
    return isDeclinedOrNotPaid(status);
  });

  const showArchiveButton = (status) => isDeclinedOrNotPaid(status);

  return {
    tableStyle,
    currentRequestParams,
    showArchiveColumn,
    showArchiveButton,
    onSort,
    onPage,
    fetchData,
    onSelectionChange,
    onArchive
  };
};
