import moment from 'moment';
import { compact, unionBy } from 'lodash';
import { localStorageKeys } from '../../config/localStorageKeysConfig';
import { serviceDateFormat, sortDatesForQuickPick } from '../../utils/serviceDateFormat';

export const recentServiceDates = (visits = []) => {
  // Retrieve the localStorageDateValue from local storage and format it as "YYYY-MM-DD"
  const localStorageDateValue = JSON.parse(localStorage.getItem(localStorageKeys.serviseDate));
  const localStorageDate = localStorageDateValue ? moment(localStorageDateValue).format('YYYY-MM-DD') : false;

  // Number of dates displayed in the quick pick catalog
  const NUM_OF_DAYS = 8;
  let dates = [];

  // Generate an array of dates for the last NUM_OF_DAYS days
  for (let i = 0; i < NUM_OF_DAYS; i++) {
    dates.push(moment().subtract(i, 'day').format('YYYY-MM-DD'));
  }

  // Generate service dates using the serviceDateFormat function and remove any falsy values
  const serviceDates = compact(dates.map((i) => serviceDateFormat(i)));

  // Generate formattedVisits using the serviceDateFormat function with an order of 1 and remove any falsy values
  const formattedVisits = compact(visits.map((i) => serviceDateFormat(i, 1)));

  // Sort and select the last 3 visits from the formattedVisits array
  const sortedVisits = sortDatesForQuickPick(formattedVisits).slice(0, 3);

  // Add the localStorageDateValue to serviceDates if it exists and is not already in the array
  const addLocalStorageValueToServiceDates =
    localStorageDate && !serviceDates.some((i) => i.value === localStorageDate)
      ? [...serviceDates, serviceDateFormat(localStorageDate)]
      : serviceDates;

  // Combine and deduplicate sortedVisits and addLocalStorageValueToServiceDates based on the "value" property
  const results = unionBy(sortedVisits, addLocalStorageValueToServiceDates, 'value');

  // Return the sorted results for the quick pick catalog
  return sortDatesForQuickPick(results);
};
