import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const BillingHistoryButton = ({ initPatient }) => {
  const { watch, localState, setLocalState, isGroup } = useFormContext();
  const patients = watch(inputs.patient.name);
  const disableBillingHistoryButton = !patients?.length;

  const onClick = () => {
    setLocalState((prevState) => ({ ...prevState, billingHistoryDialog: { showDialog: true, options: { initPatient } } }));
  };

  if (isGroup && localState.step > 1) {
    return (
      <Button
        id="billingHistoryButton"
        style={{ width: '24px', height: '24px' }}
        className="p-button-outlined"
        icon="pi pi-calendar-plus"
        tooltipOptions={{ position: 'top', showDelay: 1000 }}
        tooltip={disableBillingHistoryButton ? t('Select_patient') : t('See_patients_billing_history')}
        disabled={disableBillingHistoryButton}
        onClick={onClick}
      />
    );
  }

  return (
    <Button
      id="billingHistoryButton"
      className="p-button-rounded py-1 px-2 p-button-text p-button-info"
      label={t('Past_claims')}
      iconPos="right"
      tooltipOptions={{ position: 'top', showDelay: 1000 }}
      tooltip={disableBillingHistoryButton ? t('Select_patient') : t('See_patients_billing_history')}
      disabled={disableBillingHistoryButton}
      onClick={onClick}
    />
  );
};

export default BillingHistoryButton;
