import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from 'primereact/card';

import GroupPreviewActionButtons from '../ActionButtons/GroupPreviewActionButtons';
import PreviewBodyLayout from './PreviewBodyLayout';
import PreviewTitle from './PreviewTitle';

import { closeToastMessage } from '../../../../../../core/actions/core.action.creators';

const GroupPreview = () => {
  const dispatch = useDispatch();
  const { toastMessage } = useSelector((state) => state.core);

  // Close toast messages
  useEffect(() => {
    toastMessage.message && dispatch(closeToastMessage(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-3">
      <div className="flex flex-column justify-content-center">
        <div className="flex justify-content-center w-full">
          <Card className="teleplan_preview_card teleplan_group_preview_card">
            <PreviewTitle />
            <PreviewBodyLayout />
            <GroupPreviewActionButtons />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GroupPreview;
