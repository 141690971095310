export const onEditorInit = (e, editingCellRows, setEditingCellRows, records) => {
  const { rowIndex: index, field, rowData } = e.columnProps;
  let _editingCellRows = [...editingCellRows];
  if (!editingCellRows[index]) {
    _editingCellRows[index] = { ...rowData };
    setEditingCellRows(_editingCellRows);
  }
  _editingCellRows[index][field] = records[index][field];
  setEditingCellRows(_editingCellRows);
};

export const onEditorCancel = (e, editingCellRows, setEditingCellRows, records, setRecords) => {
  const { rowIndex: index, field } = e.columnProps;
  let products = [...records];
  let _editingCellRows = [...editingCellRows];
  products[index][field] = _editingCellRows[index][field];
  delete _editingCellRows[index][field];

  setEditingCellRows(_editingCellRows);
  setRecords(products);
};

export const onEditorSubmit = (e, editingCellRows, setEditingCellRows) => {
  const { rowIndex: index, field } = e.columnProps;
  let _editingCellRows = [...editingCellRows];
  delete _editingCellRows[index][field];
  setEditingCellRows(_editingCellRows);
};

export const emptyValueValidator = (e) => {
  const { rowData, field } = e.columnProps;
  return rowData[field].trim().length > 0;
};
