import { validate, v4 as uuidv4 } from 'uuid';
import { inputs } from './inputs';
import moment from 'moment';

// Format data for save/submit API request

export const dataForSubmit = (data) => {
  const patient = data[inputs.patient.name][0];
  const serviceDate = data?.[inputs.serviceDate.name];
  const formattedServiceDate = Array.isArray(serviceDate)
    ? serviceDate.map((i) => moment(i).format('YYYY-MM-DD'))
    : moment(serviceDate).format('YYYY-MM-DD');
  const services = data?.[inputs.services.name]?.map((i) => {
    if (validate(i.value)) return i;
    return { ...i, value: uuidv4() };
  });

  const dataForRequest = {
    ...data,
    [inputs.patientGuid.name]: patient?.PatientGuid,
    [inputs.primaryPolicy.name]: data?.[inputs.primaryPolicy.name]?.RowGuid,
    [inputs.secondaryPolicy.name]: data?.[inputs.secondaryPolicy.name]?.RowGuid || '',
    [inputs.serviceDate.name]: formattedServiceDate,
    [inputs.services.name]: services,
    [inputs.location.name]: data?.[inputs.location.name]?.RowGuid
  };

  delete dataForRequest[inputs.patient.name];
  delete dataForRequest[inputs.authorizationForm.name];
  delete dataForRequest[inputs.termsAndConditions.name];
  delete dataForRequest[inputs.fantom.name];

  return dataForRequest;
};
