import React from 'react';
import Highlighter from 'react-highlight-words';
import './HighlightSearchQuery.scss';

// const HighlightSearchQuery = ({ text, query }) => {
//   if (!query) return text;

//   const textWords = text?.split(' ');
//   const queryWords = query?.split(' ');

//   const highlightedText = textWords.map((word, i) => {
//     const matchedWord = queryWords.find((queryWord) =>
//       word.toLowerCase().includes(queryWord.toLowerCase())
//     );

//     if (matchedWord) {
//       return (
//         <span key={i} style={{ backgroundColor: "#ffff00" }}>
//           {word}
//         </span>
//       );
//     } else {
//       return <span key={i}>{word}</span>;
//     }
//   });

//   return (
//     <div>
//       {highlightedText.reduce((prev, curr) => [prev, ' ', curr])}
//     </div>
//   );
// };

// export default HighlightSearchQuery;

const HighlightSearchQuery = ({ text, query }) => {
  return (
    <div className="highlighter">
      <Highlighter autoEscape={true} textToHighlight={text} searchWords={query?.split(/[ ,.]+/)} />
    </div>
  );
};

export default HighlightSearchQuery;
