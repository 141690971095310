import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

import { addNewItemToArray } from '../../../../../utils/addNewItemToArray';
import { inputs } from '../../helpers/inputs';
import { weeksForMW } from '../../config/defaultValues';
import { useServiceDate } from '../../hooks/useServiceDate';
import { useCodePickerActions } from '../../hooks/useCodePickerActions';
import { getCodesByType } from '../../../../actions/claims.action.creators';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';

const DateAssistButton = () => {
  const menu = useRef(null);
  const { onChangeServiceDate } = useServiceDate();
  const { onChange } = useCodePickerActions(inputs.feeCodes);
  const { localState, getValues } = useFormContext();

  const getMenuItems = () => {
    let items = [];
    const serviceDateFor36010 = localState.aditionalInfoForPatient.serviceDate;
    const isValidServiceDateFor36010 = moment(serviceDateFor36010).isValid();
    const feeCode36010 = localState.aditionalInfoForPatient.feeCode === '36010';

    if (feeCode36010 && isValidServiceDateFor36010) {
      const suggestDateFor36020 = {
        label: t('Suggest_date_for_36020'),
        command: async () => {
          const speciality = Number(getValues(inputs.speciality.name));
          const feeCode36020Info = await getCodesByType({ codeType: inputs.feeCodes.codeType, query: '36020', speciality });

          // Add 14 weeks to service date for 36010
          const updatedServiceDateForMW = moment(serviceDateFor36010).add(weeksForMW, 'weeks');
          onChangeServiceDate(updatedServiceDateForMW);

          if (feeCode36020Info) {
            const currentFeeCodes = getValues(inputs.feeCodes.codeType);
            onChange({ value: [...currentFeeCodes, ...feeCode36020Info] });
          }
        }
      };

      items = addNewItemToArray(items, 1, suggestDateFor36020);
    }

    return items;
  };

  const menuItems = getMenuItems();

  if (!menuItems.length) return null;

  return (
    <>
      <Menu model={menuItems} popup ref={menu} id="popup_menu" />
      <Button
        className="p-button-text p-button-info"
        label={t('Date_assist')}
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </>
  );
};

export default DateAssistButton;
