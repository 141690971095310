import React from 'react';
import InvoiceTimelineProvider from './InvoiceTimelineProvider/InvoiceTimelineProvider';
import TimeLine from './modules/TimeLine';
import './InvoiceTimeline.scss';

// interface IInvoiceTimeline {
//   invoice: {} // Invoice info
// }

const InvoiceTimeline = (props) => {
  return (
    <InvoiceTimelineProvider {...props}>
      <TimeLine />
    </InvoiceTimelineProvider>
  );
};

export default InvoiceTimeline;
