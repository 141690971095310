import moment from 'moment';
import { claimsDefaultFilters, claimsDefaultSortBy, initStateForPagination } from '../../../../modules/config/defaultValuesConfig';

export const defaultRequestParams = {
  page: initStateForPagination.page,
  pageSize: initStateForPagination.pageSize,
  sortby: claimsDefaultSortBy.sortOrder,
  query: {
    ...claimsDefaultFilters,
    Period: '7', // Use "7" for VER-386 - Past claims -> show future claims
    PeriodFrom: moment(moment().subtract(12, 'months').toDate()).format('YYYY/MM/DD'),
    PeriodTo: '' // VER-386 - Past claims -> show future claims
  }
};
