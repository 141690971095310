import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { steps } from '../../../../../helpers/steps';
import { useDuplicateCodes } from '../../../hooks/useDuplicateCodes';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const DuplicateReferralButton = () => {
  const { localState } = useFormContext();
  const { onDuplicateCode, isDisabledDuplicateCodesButton } = useDuplicateCodes();
  const disabled = isDisabledDuplicateCodesButton(inputs.referral);

  const onClick = () => {
    onDuplicateCode(inputs.referral);
  };

  if (localState.step === steps.groupReferralPicker) {
    return (
      <Button
        className="p-button-text w-full"
        label={t('Duplicate_Referral')}
        tooltip={t('Duplicate_referral_for_same_patient')}
        tooltipOptions={{ showDelay: 500, position: 'top' }}
        disabled={disabled}
        onClick={onClick}
      />
    );
  }

  return null;
};

export default DuplicateReferralButton;
