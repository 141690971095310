import { deleteInsurancePolicy } from '../../../../modules/claims/actions/claims.action.creators';
import { commonInputs } from '../../../../modules/config/commonInputsConfig';
import { defaultValues } from '../config/defaultValues';
import { usePatientPoliciesContext } from './usePatientPoliciesContext';

export const useEClaimPolicy = () => {
  const { setState } = usePatientPoliciesContext();

  const onCreateEClaimPolicy = (data) => {
    setState((prevState) => ({
      ...prevState,
      policiesList: [data, ...prevState.policiesList],
      eClaimPolicy: defaultValues.eClaimPolicy
    }));
  };

  const onEditEClaimPolicy = (data) => {
    setState((prevState) => {
      const policiesList = prevState.policiesList?.map((i) => {
        const rowGuidKey = commonInputs.rowGuid.name;
        const insurerGuid = commonInputs.insurerGuid.name;
        const replacePolicy = i?.[rowGuidKey] === data?.[rowGuidKey] && i?.[insurerGuid] === data?.[insurerGuid];
        if (replacePolicy) return data;
        return i;
      });

      return {
        ...prevState,
        policiesList,
        eClaimPolicy: defaultValues.eClaimPolicy
      };
    });
  };

  const onDeleteEClaimPolicy = async (policyForDeletion) => {
    const result = await deleteInsurancePolicy(policyForDeletion);

    if (result) {
      const rowGuidKey = commonInputs.rowGuid.name;
      setState((prevState) => {
        const filteredPoliciesList = prevState.policiesList?.filter((i) => {
          return i?.[rowGuidKey] !== policyForDeletion?.[rowGuidKey];
        });

        return { ...prevState, policiesList: filteredPoliciesList };
      });
    }
  };

  const onCloseEClaimPolicy = () => {
    setState((prevState) => ({ ...prevState, eClaimPolicy: defaultValues.eClaimPolicy }));
  };

  return { onCloseEClaimPolicy, onCreateEClaimPolicy, onEditEClaimPolicy, onDeleteEClaimPolicy };
};
