import React from 'react';
import CodeChip from '../Chips/CodeChip';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { removeAllSpecialCharacters } from '../../../../modules/regex/regex';

// interface IItem {
//   value: string,
//   label: string,
//   order?: number,
//   isWeekend?: boolean
// }

// interface IProps {
//   name: string, // Name of the code input that chip relates to
//   itemsList: IItem[],
//   onClick: (item: IItem) => void

//   isSelected?: () => void // Callback to check if item is selected
//   labelKey?: string // Key for label value
//   tooltipKey?: string // Key for tooltip value
// }

const ChipsList = (props) => {
  return (
    <>
      {props.itemsList?.map((i, index) => {
        const isSelected = props.isSelected ? props.isSelected(i) : false;
        const label = props.labelKey ? i[props.labelKey] : null;
        const tooltip = props.tooltipKey ? i[props.tooltipKey] : null;
        const id = `${props.name}_${elementIDs.quickPickCodeItem}_${label?.replaceAll(removeAllSpecialCharacters, '')}`;

        return <CodeChip {...props} id={id} item={i} label={label} tooltip={tooltip} key={`${i?.value}_${index}`} isSelected={isSelected} />;
      })}
    </>
  );
};

export default ChipsList;
