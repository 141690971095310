import { t } from '../../../../../service/localization/i18n';

export const benefitDropdownOptions = [{ label: t('Extended_Health'), value: 'EHCPOL' }];

const self = { label: t('Patient.1'), value: 'SELF' };

export const insuredTypeOptions = [{ label: t('N_A'), value: 'NA' }, self, { label: t('Relative'), value: 'FAMDEP' }];

export const relationshipOptions = [
  { label: t('N_A'), value: 'NA' },
  { label: t('Spouse'), value: 'SPS' },
  { label: t('Child'), value: 'CHILD' },
  { label: t('Full_time_student'), value: 'FSTUD' },
  { label: t('Part_time_student'), value: 'PSTUD' },
  { label: t('Family_dependent'), value: 'FAMDEP' },
  { label: t('Handicapped_dependent'), value: 'HANDIC' },
  { label: t('Domestic_partner'), value: 'DOMPART' }
];

export const allRelationshipOptions = [
  self,
  { label: t('Spouse'), value: 'SPS' },
  { label: t('Child'), value: 'CHILD' },
  { label: t('Full_time_student'), value: 'FSTUD' },
  { label: t('Part_time_student'), value: 'PSTUD' },
  { label: t('Family_dependent'), value: 'FAMDEP' },
  { label: t('Handicapped_dependent'), value: 'HANDIC' },
  { label: t('Domestic_partner'), value: 'DOMPART' }
];
