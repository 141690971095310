import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

import EligibilityMessage from './NewEligibilityMessage';
import SuccessPage from '../../../../components/Layouts/SuccessPage/SuccessPage';
import SuccessActionButtons from '../../../../components/Layouts/SuccessPage/SuccessActionButtons';

import { setEligibility } from '../../actions/patients.action.creators';
import { isEmpty } from 'lodash';
import { t } from '../../../../service/localization/i18n';

const Success = ({ patientDetails, setStep, eligibility, showNewPatientSuccessPage, addNewPatientForNewClaim }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const newPatient = () => {
    dispatch(setEligibility({}));
    setStep(0);
  };

  const onAddNewPatientToNewClaim = () => {
    addNewPatientForNewClaim(patientDetails);
    history.goBack();
  };

  const successMessage = () => {
    return <>{`${t('You_created_profile_for')} ${patientDetails?.Summary}`}</>;
  };

  const alternativeActionButtons = showNewPatientSuccessPage.from === 'teleplanClaim';

  const eligibilityLayout = () => {
    if (!isEmpty(eligibility) || alternativeActionButtons) {
      return (
        <div className="flex flex-column align-items-center w-full mb-5" style={{ maxWidth: '950px' }}>
          {!isEmpty(eligibility) && (
            <div className="mt-5">
              <EligibilityMessage eligibility={eligibility} />
            </div>
          )}

          {/* Action buttons if user create new patient from new teleplan form */}
          {alternativeActionButtons && (
            <div>
              <div className="flex justify-content-center my-6">{t('Do_you_want_to_add_the_patient_to_your_teleplan_claim')}</div>

              <div className="flex justify-content-center align-items-center">
                <Button className="mb-3 mr-2 w-full" label={t('Yes')} autoFocus onClick={onAddNewPatientToNewClaim} />
                <Button className="p-button-outlined mb-3 ml-2 w-full" label={t('No')} onClick={() => history.goBack()} />
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="px-3">
      <SuccessPage successMessage={successMessage()}>
        {eligibilityLayout()}

        {/* Default buttons */}
        {!showNewPatientSuccessPage.from && <SuccessActionButtons patientsList={[patientDetails]} newPatient={newPatient} />}
      </SuccessPage>
    </div>
  );
};

export default Success;
