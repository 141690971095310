import React, { useState } from 'react';
import QuickPickItem from '../../QuickPickItem';
import { TabView, TabPanel } from 'primereact/tabview';
import { catalogsRolodex, mostRecent } from './helpers/catalogsRolodex';
import { onCodeSelect, onCodeDelete } from './helpers/codeAction';
import { t } from '../../../../../service/localization/i18n';

const MostRecent = ({ privateRecord, setIsMaxCodeEntries, catalogIndex, setCatalogIndex, setFocusFieldParams }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
      <TabPanel header={t('Codes')}>
        {mostRecent()?.map((i, index) => {
          return (
            <QuickPickItem
              key={`mrqp${index}`}
              item={i}
              useLabel={true}
              record={privateRecord}
              catalogIndex={catalogIndex}
              setCatalogIndex={setCatalogIndex}
              catalogsRolodex={catalogsRolodex}
              setFocusFieldParams={setFocusFieldParams}
              setIsMaxCodeEntries={setIsMaxCodeEntries}
              onCodeSelect={onCodeSelect}
              onCodeDelete={onCodeDelete}
            />
          );
        })}
      </TabPanel>
    </TabView>
  );
};

export default MostRecent;
