import React from 'react';
import { useFormContext } from 'react-hook-form';

import InputsSection from './Layouts/InputsSection';
import HeaderInfoSection from '../Layouts/HeaderInfoSection';
import InputsSectionPOS from '../../views/TeleplanPOS/InputsSectionPOS';
import InputSectionForTeleplanGroup from '../../views/TeleplanGroup/Inputs/InputSectionForTeleplanGroup';

import { elementIDs } from '../../../../../config/elementIDsConfig';

const TeleplanInvoiceForm = () => {
  const { handleSubmit, onSubmit, isMobile, pos, isGroup } = useFormContext();

  const formLayout = () => {
    if (pos && !isMobile) return <InputsSectionPOS />;
    if (isGroup) return <InputSectionForTeleplanGroup />;
    return <InputsSection />;
  };

  return (
    <div id={elementIDs.desktopFormCard}>
      <form id="teleplanForm" className="h-full" onSubmit={handleSubmit(onSubmit)}>
        <HeaderInfoSection />
        {formLayout()}
      </form>
    </div>
  );
};

export default TeleplanInvoiceForm;
