import { store } from '../../../../../store';
import { serviceDateFormat } from '../../../../utils/serviceDateFormat';
import { setMissingCodesToRecentList } from '../../../actions/claims.action.creators';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { inputs } from './inputs';

export const addMissingCodesToRecentList = ({ selectedCodes, formState, recentCodesList = null } = {}) => {
  // "codes" example structure:
  // {
  //   "icd9": [
  //     {
  //       "value": "01Z",
  //       "label": "01Z: Lenka",
  //       "text": "Lenka",
  //       "amount": null,
  //       "order": 0,
  //       "min_per_unit": null,
  //       "portion": null,
  //       "dateAdded": "0001-01-01T00:00:00"
  //     }
  //   ]
  // }

  let codes = {};

  if (selectedCodes) codes = selectedCodes;

  if (formState) {
    const invoiceType = formState?.[inputs.payor.name];
    const serviceDate = formState?.[inputs.serviceDate.name];
    const formattedServiceDate = Array.isArray(serviceDate) ? serviceDate.map((i) => serviceDateFormat(i)) : [serviceDateFormat(serviceDate)];
    const feeType = getCategoryType(inputs.feeCodes.codeType, invoiceType);
    const dxType = getCategoryType(inputs.icd9.codeType, invoiceType);
    const aoiType = getCategoryType(inputs.aoi.codeType, invoiceType);
    const noiType = getCategoryType(inputs.noi.codeType, invoiceType);
    const referralType = getCategoryType(inputs.referral.codeType, invoiceType);
    codes = {
      [inputs.serviceDate.codeType]: formattedServiceDate,
      [feeType]: formState?.[inputs.feeCodes.codeType],
      [dxType]: formState?.[inputs.icd9.codeType],
      [aoiType]: formState?.[inputs.aoi.codeType],
      [noiType]: formState?.[inputs.noi.codeType],
      [referralType]: formState?.[inputs.referral.codeType]
    };
  }

  store.dispatch(setMissingCodesToRecentList({ codes, recentCodesList }));
};
