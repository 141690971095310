import React, { useState, useEffect, useCallback, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import Context from '../../../Context';

import cx from 'classnames';
import _ from 'lodash';

const TextInput = ({ classes, initialValue, setFieldFilter, invalidFields, field, fieldType, setFieldClean, filters, keyfilter }) => {
  const { sortby } = useContext(Context);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onSetFieldFilter = (e) => {
    setValue(e.target.value);
    debouncedRequest(e, field, fieldType, filters, sortby);
  };

  const debouncedRequest = useCallback(
    _.debounce((e, field, fieldType, filters, sortby) => {
      setFieldFilter(e, field, fieldType, filters, sortby);
    }, 1500),
    []
  );

  return (
    <InputText
      className={cx(classes, invalidFields[field])}
      id={'flt_' + field}
      placeholder="Search"
      value={value}
      keyfilter={keyfilter ? keyfilter : ''}
      onKeyPress={(e) => setFieldClean(e, fieldType)}
      onChange={(e) => onSetFieldFilter(e, field, fieldType)}
    />
  );
};

export default TextInput;
