import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { filterBy } from './filterBy';
import classes from '../LegoView.module.scss';
import cx from 'classnames';

export const getColumns = ({ showExtras, columns, actions, dataFieldFilters, filters, invalidFields, onSort, setFieldFilter, isFetching }) => {
  const reply = [];

  const extrasBody = () => {
    return <Skeleton width="70%" className="ml-2" />;
  };

  if (showExtras) {
    reply.push(
      <Column key={`lvc_expander`} className={classes.rowToggleButton} expander={showExtras} showFilterMenu={false} body={isFetching && extrasBody} />
    );
  }

  columns.forEach((x) => {
    const filterByParams = {
      field: x.id,
      columns,
      dataFieldFilters,
      filters,
      setFieldFilter,
      invalidFields,
      inputStyle: x.inputStyle
    };

    if (x.body) {
      reply.push(
        <Column
          className={x.className}
          filterHeaderClassName={x.filterHeaderClassName ? x.filterHeaderClassName : ''}
          field={x.field}
          header={x.header}
          sortable={x.sortable}
          key={`lvc_${x.field}`}
          body={x.body}
          title={x.title}
          filter={x.filter || false}
          showFilterMenu={false}
          filterElement={filterBy(filterByParams)}
          style={{ minWidth: '100px' }}
        />
      );
    } else {
      if (x.className !== 'hidden') {
        reply.push(
          <Column
            className={x.className}
            field={x.field}
            header={x.header}
            sortable={x.sortable}
            key={`lvc_${x.field}`}
            filter={x.filter || false}
            showFilterMenu={false}
            filterElement={filterBy(filterByParams)}
            style={{ minWidth: '100px' }}
          />
        );
      }
    }
  });

  actions.forEach((x, index) => {
    if (x.body) {
      reply.push(
        <Column
          className={cx(x.className ? x.className : '', classes.actionColumn)}
          filterHeaderClassName={cx('pb-0', x.filterHeaderClassNameResetSort ? x.filterHeaderClassNameResetSort : 'text-center')}
          filter
          key={`lva_${index}`}
          filterElement={x.resetSort(onSort)}
          header=" "
          showFilterMenu={false}
          body={(e) => x.body(e)}
          style={{ minWidth: '100px' }}
        />
      );
    }
  });

  return reply;
};
