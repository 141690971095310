import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import StepOneWrap from '../../../modules/Steps/StepOneWrap';
import InsurancePolicySelector from '../../../modules/EClaimForm/Inputs/InsurancePolicySelector';
import StepOneActionButtons from '../../../modules/ActionButtons/StepOneActionButtons';
import AddNewPolicy from '../../../modules/ActionButtons/AddNewPolicy';

import { SUBMITTING_TO_A_SECONDARY_INSURER } from '../../../../../../../config';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const StepOne = () => {
  const { errors } = useFormContext();

  return (
    <div>
      <StepOneWrap>
        <div className="flex flex-column gap-4">
          <h6 className={cx('text-center text-lg m-0', { 'p-error': errors[inputs.primaryPolicy.name] })}>
            {t('Select_the_primary_insurance_policy')}
          </h6>

          <div className="flex flex-column justify-content-center align-items-center gap-4">
            <InsurancePolicySelector input={inputs.primaryPolicy} />
            <div>
              <AddNewPolicy isPrimary />
            </div>
          </div>
        </div>

        <div className="flex flex-column gap-4">
          <div className="flex justify-content-center align-items-center gap-3">
            <Tooltip target=".secondaruInsurancePolicyTooltip" position="top" autoHide={false}>
              <div className="text-center">
                {`${t('Note')}: ${t('Please_refer_to_the_Telus')}`}
                <a target="_blank" rel="noreferrer" href={SUBMITTING_TO_A_SECONDARY_INSURER} className="underline pl-2 text-white">
                  {t('coordination_of_benefits')}
                </a>
              </div>
            </Tooltip>

            <h6 className="m-0 text-lg">{t('Select_the_secondary_insurance_policy')}</h6>
            <i className="pi pi-question-circle pointer secondaruInsurancePolicyTooltip" />
          </div>

          <div className="flex flex-column justify-content-center align-items-center gap-4">
            <InsurancePolicySelector input={inputs.secondaryPolicy} />
            <div>
              <AddNewPolicy />
            </div>
          </div>
        </div>
      </StepOneWrap>

      <StepOneActionButtons />
    </div>
  );
};

export default StepOne;
