import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { inputs } from '../helpers/inputs';
import { useUpdateFirstVisitInputs } from './useUpdateFirstVisitInputs';
import { duplicatePatientCheck } from '../../../../patients/actions/patients.action.creators';
import { inputsForFirstVisit } from '../helpers/defaultValues';
import { setToastMessage } from '../../../../core/actions/core.action.creators';
import { getMostRecentCodes } from '../helpers/getMostRecentCodes';
import { t } from '../../../../../service/localization/i18n';
import { isEmpty } from 'lodash';

export const usePHN = () => {
  const dispatch = useDispatch();
  const { updateFirstVisitInputs } = useUpdateFirstVisitInputs();
  const { watch, getValues, setValue, localState, setLocalState } = useFormContext();

  const phn = watch(inputs.phn.name);
  const province = watch(inputs.phnProvince.name);

  const onProvinceChange = (province) => {
    setValue(inputs.phnProvince.name, province);
  };

  const provinceValidationCallback = ({ phnProvince, isValid }) => {
    setLocalState((prevState) => ({ ...prevState, isPHNValid: isValid }));

    if (isValid) {
      const dataForCheckDuplicate = { [inputs.phn.name]: phn, [inputs.phnProvince.name]: phnProvince };
      checkPatientDuplicate({ patientData: dataForCheckDuplicate });
    }

    if (!isValid && phn) {
      resetDemographics();
      onProvinceChange(phnProvince);
    }
  };

  const onPHNChange = (phn) => {
    setValue(inputs.phn.name, phn);
    !localState.isPHNValid && setLocalState((prevState) => ({ ...prevState, isPHNValid: true }));
  };

  const onPHNBlur = ({ phn, isValid }) => {
    setLocalState((prevState) => ({ ...prevState, isPHNValid: isValid }));

    if (isValid) {
      const dataForCheckDuplicate = { [inputs.phn.name]: phn, [inputs.phnProvince.name]: province };
      checkPatientDuplicate({ patientData: dataForCheckDuplicate });
    }

    if (!isValid && phn) resetDemographics();
  };

  const checkPatientDuplicate = ({ patientData }) => {
    const patientDetails = {
      DCNGuid: getValues(inputs.dcnguid.name),
      PHN: patientData[inputs.phn.name] || phn,
      PHNProvince: patientData[inputs.phnProvince.name] || province,
      FirstName: getValues(inputs.firstName.name),
      MidName: getValues(inputs.midName.name),
      LastName: getValues(inputs.lastName.name),
      Sex: getValues(inputs.gender.name),
      DOB: getValues(inputs.birthDay.name)
    };

    dispatch(
      duplicatePatientCheck(patientDetails, (responseData) => {
        if (responseData?.length) {
          const patient = responseData[0];
          // CMO-2085 - Teleplan 1st visit does not show billing history for existing patients

          // Get most recent catalog codes
          getMostRecentCodes(getValues(), { patientGuid: patient?.PatientGuid, setLocalState });

          updateFirstVisitInputs(patient); //Only one PHN can exist in the system, so no duplicate record message is needed. BE will always return one match.

          // CMO-1733 - see comments
          if (patient.Status === -1) {
            dispatch(setToastMessage({ type: 'info', message: t('This_record_was_restored_from_archived') }));
          }

          // Show eligibility message
          if (patient?.Eligibility?.Parsed && !isEmpty(patient?.Eligibility?.Parsed)) {
            setLocalState((prevState) => ({
              ...prevState,
              eligibility: patient?.Eligibility?.Parsed
            }));
          } else {
            // CMO-1721 - First visit ->Missing eligibility report for existing PHN
            setLocalState((prevState) => ({
              ...prevState,
              eligibility: { noEligibilityReport: true }
            }));
          }
        }
      })
    );
  };

  const resetDemographics = () => {
    updateFirstVisitInputs({
      ...inputsForFirstVisit,
      [inputs.phn.name]: phn,
      [inputs.phnProvince.name]: province
    });
  };

  return {
    provinceValidationCallback,
    onProvinceChange,
    onPHNChange,
    onPHNBlur
  };
};
