import React from 'react';
import { useSelector } from 'react-redux';

import PayeeSection from './Sections/PayeeSection';
import InvoiceDetailsSection from './Sections/InvoiceDetailsSection';
import BillToSection from './Sections/BillToSection';
import ServiceDateSection from './Sections/ServiceDateSection';
import DataTableSection from './Sections/DataTableSection';
import NotesSection from './Sections/NotesSection';
import TotalSection from './Sections/TotalSection';

import { prefsCodes } from '../../../../modules/config/prefsCodesConfig';
import { t } from '../../../../service/localization/i18n';

import './PrivateInvoicePrint.scss';

const PrivateInvoicePrint = ({ data }) => {
  const { currentUserPrefs } = useSelector((state) => state.preferences);
  const { clinic } = useSelector((state) => state.clinic.details);

  const invoiceLogo = currentUserPrefs?.prefs?.find((i) => i.label === 'system')?.content?.find((i) => i.label === prefsCodes.clinicLogo)?.value;

  const invoiceLogoSection = (
    <img
      id="invoice-logo"
      className="logo-image"
      // src="assets/layout/images/logo-colored.png"
      src={invoiceLogo || ''}
      alt={clinic?.ClinicName}
    />
  );

  return (
    <div id="invoice-content" className="printContent w-full p-0 privateInvoicePrint">
      <div className="flex justify-content-center col-12 p-0">
        <div className="contentRootWrap p-0">
          <div className="invoice p-0">
            {/* Header */}
            <div className="invoice-header">
              <div className="invoice-company">
                {invoiceLogoSection}
                <PayeeSection data={data} />
              </div>

              <div>
                <div className="invoice-title">{t('Invoices.3').toUpperCase()}</div>
                <InvoiceDetailsSection data={data} />
              </div>
            </div>

            {/* Invoice to */}
            {/* {invoiceToSection} */}
            <div className="invoice-to">
              <div className="flex justify-content-between">
                <BillToSection data={data} />
                <ServiceDateSection data={data} />
              </div>
            </div>

            {/* Data table */}
            <div className="invoice-items mt-0">
              <DataTableSection data={data} />
            </div>

            {/* Total / Notes */}
            <div className="invoice-summary">
              <div>
                <NotesSection data={data} />
              </div>
              <TotalSection data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateInvoicePrint;
