import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { getSubmissionCode } from '../helpers/getSubmissionCode';
import { closeToastMessage, setToastMessage } from '../../../../core/actions/core.action.creators';
import { useDispatch } from 'react-redux';
import { useValidation } from './useValidation';

export const useSubmission = () => {
  const name = inputs.submission.name;
  const dispatch = useDispatch();
  const { watch, getValues, setValue, isNew } = useFormContext();
  const { clearCommentError } = useValidation();
  const currentInvoiceType = watch(inputs.payor.name);
  const currentServiceDate = watch(inputs.serviceDate.name);
  const status = Number(watch(inputs.status.name));

  const onChangeSubmission = (value, field) => {
    field.onChange(value);

    // Clear error message for commen input
    clearCommentError({ submissionCode: value });
  };

  const updateSubmission = ({ invoiceType, serviceDate }) => {
    const type = invoiceType || currentInvoiceType;
    const date = serviceDate || currentServiceDate;
    const currentSubmission = getValues(inputs.submission.name);
    const submission = getSubmissionCode({ invoiceType: type, status, serviceDate: date, isNew });

    // VER-250 - rebill - change in date defaults submission code
    if (currentSubmission !== submission) {
      dispatch(closeToastMessage(true));
      setTimeout(() => {
        const message = `The recommended submission code is ${submission}. It was applied to your claim.`;
        // Update submission code
        setValue(name, submission);
        dispatch(setToastMessage({ message }));
      }, 250);
    }
  };

  return { onChangeSubmission, updateSubmission };
};
