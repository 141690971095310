import { t } from '../../../../../../service/localization/i18n';
import { localStorageKeys } from '../../../../../config/localStorageKeysConfig';
import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { updatePrivateRecord } from '../../../../actions/claims.action.creators';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { addNewServiceToQuickPickList } from '../../claimDetailsHelpers/addNewServiceToQuickPickList';
import { formattedService } from '../../claimDetailsHelpers/formatServices';

export const onCodeSelect = ({ data, focusField, record, dispatch, setIsMaxCodeEntries, mostRecentCodes }) => {
  const formattedData = formattedService(data, record);

  if (focusField.codeType === commonInputs.serviceDate.codeType) {
    // add Service Date
    dispatch(updatePrivateRecord({ [focusField.name]: formattedData.value }));
    localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(formattedData.value || null)); // [KS] CMO-1423 - Persist service date during session
  } else {
    const isUniqCode = record[focusField.name]?.some((i) => i === formattedData.value)
      ? record[focusField.name]
      : [...record[focusField.name], formattedData.value];

    const isUniqData = record[focusField.descriptionName]?.some((i) => i.value === formattedData.value)
      ? record[focusField.descriptionName]
      : [...record[focusField.descriptionName], formattedData];

    const newCodes = focusField.maxEntries === 1 ? [formattedData.value] : isUniqCode;
    const newCodeData = focusField.maxEntries === 1 ? [formattedData] : isUniqData;

    // Show toast message if service already added
    if (record?.ServiceGuid?.some((i) => i === data.value)) {
      dispatch(setToastMessage({ type: 'info', message: String.format(t('already_added'), data.label) }));
    }

    if (newCodes.length > focusField.maxEntries) {
      setIsMaxCodeEntries({ isMaxEntries: true, maxEntries: focusField.maxEntries });
    } else {
      dispatch(
        updatePrivateRecord({
          [focusField.name]: newCodes,
          [focusField.descriptionName]: newCodeData
        })
      );

      if (mostRecentCodes) {
        //[KS] - add code to the Quick Pick if it is not in the list
        //[KS] CMO-1548 - Create private invoice - new service codes do not appear in catalogs
        addNewServiceToQuickPickList([formattedData], record, mostRecentCodes, dispatch);
      }
    }
  }
};

export const onCodeDelete = (data, focusField, record, dispatch) => {
  if (focusField.codeType === commonInputs.serviceDate.codeType) {
    dispatch(updatePrivateRecord({ [focusField.name]: data.value }));
  } else {
    const filteredCodes = record[focusField.name]?.filter((i) => i?.toUpperCase() !== data.value?.toUpperCase());
    const filteredDescriptions = record[focusField.descriptionName]?.filter((i) => i.value?.toUpperCase() !== data.value?.toUpperCase());
    dispatch(
      updatePrivateRecord({
        [focusField.name]: filteredCodes,
        [focusField.descriptionName]: filteredDescriptions
      })
    );
  }
};
