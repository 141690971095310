import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs/index';

import { inputs } from '../../../helpers/inputs';
import { usePayee } from '../../../hooks/usePayee';
import { useValidation } from '../../../hooks/useValidation';
import { usePreferences } from '../../../hooks/usePreferences';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import cx from 'classnames';

const Payee = () => {
  const name = inputs.payee.name;
  const { control, isView } = useFormContext();
  const { payeeValidation } = useValidation();
  const { options, onPayeeChange } = usePayee();
  const { isLocked, onSavePreferences } = usePreferences();
  const disabled = isView;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => payeeValidation(value) }}
      render={({ field, fieldState: { error } }) => (
        <InputWrapper
          required={!disabled}
          name={name}
          label={inputs.payee.label}
          errorMessage={error?.message}
          isLocked={isLocked(field.value, prefsCodes.eClaimPayTo)}
          onSavePrefs={disabled ? undefined : () => onSavePreferences(field.value, prefsCodes.eClaimPayTo)}
        >
          <Dropdown
            style={{ width: 'inherit' }}
            id={elementIDs.payeePickerDropdown}
            className={cx({ 'p-invalid': error?.message })}
            inputRef={field.ref}
            type="text"
            value={field.value}
            field="label"
            options={options}
            disabled={disabled}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => onPayeeChange(e, field)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default Payee;
