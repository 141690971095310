import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dataForSubmit } from '../helpers/dataForSubmit';
import { inputs } from '../helpers/inputs';
import { openWSBCRPreview } from '../../../helpers/rowActions';
import { createWSBCReport, saveWSBCReport } from '../../../actions/claims.action.creators';

export const useSubmit = ({ reset, setLocalState, isNew }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formatData = dataForSubmit(data);
    const sendNow = formatData[inputs.sendNow.name]; // on create - false, on submit - true

    setLocalState((prev) => ({ ...prev, sendNow: data[inputs.sendNow.name] }));

    // Create (Review) report
    if (isNew && !sendNow) {
      return dispatch(createWSBCReport(formatData, (response) => submitResponseHandler(response, sendNow)));
    }

    // Edit or Submit report
    if (sendNow || !isNew) return dispatch(saveWSBCReport(formatData, (response) => submitResponseHandler(response, sendNow)));
  };

  const submitResponseHandler = (response) => {
    if (response.error) {
      setLocalState((prevState) => ({
        ...prevState,
        showBatchRecordsWarn: true,
        claimData: response
      }));
    } else {
      // Reset form state
      reset();

      // Show "Preview" page
      openWSBCRPreview(response[inputs.invoiceGuid.name], history, true);
    }
  };

  return { onSubmit };
};
