import React from 'react';
import PromptDialog from './PromptDialog';
import RevokeDialog from './RevokeDialog';
import CatalogsDialog from './CatalogsDialog';
import CompleteSetupDialog from './CompleteSetupDialog';
import DeleteEClaimPolicyConfirm from './DeleteEClaimPolicyConfirm';
import TermsDialog from './TermsDialog';
import ArchiveConfirmationDialog from './ArchiveConfirmationDialog';

const Dialogs = () => {
  return (
    <>
      {/* HERE GOES ALL DIALOG WINDOWS */}
      <TermsDialog />
      <PromptDialog />
      <RevokeDialog />
      <CatalogsDialog />
      <CompleteSetupDialog />
      <ArchiveConfirmationDialog />
      <DeleteEClaimPolicyConfirm />
    </>
  );
};

export default Dialogs;
