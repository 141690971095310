import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { dataTableActionTooltip } from '../../../../../helpers/tooltips';
import { t } from '../../../../../../../../../service/localization/i18n';

const ClearDxForRow = ({ rowOptions }) => {
  const { setLocalState } = useFormContext();
  const { disabledDxInput } = useGroupPickerInputs();
  const dxCodes = disabledDxInput(rowOptions) || rowOptions.rowData[inputs.icd9.name];
  const tooltip = dataTableActionTooltip(t('Clear_dx_codes_for'), rowOptions.rowData);

  const onClick = () => {
    setLocalState((prevState) => {
      const updatedRecords = prevState.groupRecords.map((record, index) => {
        if (index === rowOptions.rowIndex) {
          return {
            ...rowOptions.rowData,
            [inputs.icd9.name]: [],
            [inputs.icd9.codeDescription]: [],
            [inputs.icd9.codeType]: []
          };
        }

        return record;
      });

      return {
        ...prevState,
        groupRecords: updatedRecords
      };
    });
  };

  return (
    <div className="flex justify-content-end w-full">
      <Button
        className="p-button-text p-button-rounded"
        icon="pi pi-undo"
        tooltip={tooltip}
        tooltipOptions={{ position: 'top', showOnDisabled: true }}
        tabIndex={-1}
        disabled={!dxCodes?.length}
        onClick={onClick}
      />
    </div>
  );
};

export default ClearDxForRow;
