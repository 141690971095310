import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedDatePicker } from '../../../../../../../components/Inputs';
import { useValidation } from '../../../hooks/useValidation';
import { useConditions } from '../../../hooks/useConditions';
import { inputs } from '../../../helpers/inputs';

const AccidentDate = () => {
  const name = inputs.accidentDate.name;
  const { errors, control, isView, watch } = useFormContext();
  const { accidentDateValidation } = useValidation();
  const { accidentDateRequired } = useConditions();
  const errorMessage = errors[name]?.message;
  const accidentType = watch(inputs.accidentType.name);
  const disabled = isView || accidentType === 'N/A';

  if (!accidentDateRequired) return null;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => accidentDateValidation(value) }}
      render={({ field }) => (
        <WrappedDatePicker
          input={inputs.accidentDate}
          ref={field.ref}
          value={field.value}
          required={!isView && accidentDateRequired}
          disabled={disabled}
          errorMessage={errorMessage}
          onChange={(value) => field.onChange(value)}
        />
      )}
    />
  );
};

export default AccidentDate;
