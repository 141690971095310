import React, { useRef } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';

const ActionButtons = ({ rowData, setSelectedRows, rowActionButtonsMenuModel }) => {
  const rowMenuRef = useRef(null);
  const { isMobile } = useSelector((state) => state.core.window);

  return (
    <div className="flex justify-content-end">
      {isMobile && <Menu id={`popup_menu_${rowData?.PatientGuid}`} ref={rowMenuRef} popup model={rowActionButtonsMenuModel(rowData)} />}

      {!isMobile && <TieredMenu id={`popup_menu_${rowData?.PatientGuid}`} ref={rowMenuRef} popup model={rowActionButtonsMenuModel(rowData)} />}

      <Button
        id={rowData.id?.replaceAll('-', '')}
        className="p-button-text p-button-rounded mobileRoundedButtonSize"
        icon="pi pi-ellipsis-v"
        aria-controls={`popup_menu_${rowData?.PatientGuid}`}
        aria-haspopup
        onClick={(e) => {
          rowMenuRef.current.toggle(e);
          setSelectedRows && setSelectedRows([rowData]);
        }}
      />
    </div>
  );
};

export default ActionButtons;
