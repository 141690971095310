import React from 'react';
import { Button } from 'primereact/button';

// interface {
//   ref: ref // Ref of the scrollable element
// }

const ScrollTop = React.forwardRef((_, ref) => {
  const scrollToTop = () => {
    const scrollContainer = ref?.current;
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Button
      className="p-button-rounded"
      icon="pi pi-chevron-up"
      tooltip="Scroll up"
      tooltipOptions={{ position: 'top', showDelay: 1000 }}
      onClick={scrollToTop}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '30px',
        padding: '10px',
        cursor: 'pointer'
      }}
    />
  );
});

export default ScrollTop;
