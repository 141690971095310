import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

const Asterisk = ({ iconId, tooltipId }) => {
  return (
    <>
      <Tooltip target={`.asteriskTooltip_${tooltipId}`} position="top" />
      <div id={iconId || 'mandatoryFieldIcon'} className={cx('p-error', `asteriskTooltip_${tooltipId}`)} data-pr-tooltip={t('Required')}>
        *
      </div>
    </>
  );
};

export default Asterisk;
