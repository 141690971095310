import React from 'react';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const PercentTemplate = ({ rowData }) => {
  const style = !rowData.units || rowData.units > 1 ? {} : { color: 'var(--primary-color)' };
  return (
    <span id={`${elementIDs.invoiceRecordsPercentTemplate}`} style={style}>
      {rowData.percent}
    </span>
  );
};

export default PercentTemplate;
