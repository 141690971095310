import { find } from 'lodash';
import { prefsCodes } from '../../config/prefsCodesConfig';

export const getDefaultPrefs = (prefs, label) => {
  const suspect = find(prefs, { label });
  const defaultPrefsCodes = [prefsCodes.defaultEmergency, prefsCodes.defaultTravel, prefsCodes.defaultCCFPP];

  if (!suspect) {
    return false;
  }

  if (suspect.value === '1' && defaultPrefsCodes.includes(suspect.label)) {
    return true;
  }

  if (suspect.value === '0' && defaultPrefsCodes.includes(suspect.label)) {
    return false;
  }

  return suspect.value;
};

export const getPresetPrefs = (prefs, label) => {
  const suspect = find(prefs, { label });
  return suspect?.value;
};
