import InsuranceCompanyTabLayout from '../modules/EClaimPolicyCatalogs/TabLayouts/InsuranceCompanyTabLayout';
import { t } from '../../../../../service/localization/i18n';

export const useCatalogTabs = () => {
  let commonTabs = [
    {
      name: 'InsuranceCompany',
      label: t('Insurance_Company'),
      codeType: 'insuranceCompany',
      hideSearchForMobileView: true,
      tabLayout: () => <InsuranceCompanyTabLayout />
    }
  ];

  return { catalogTabs: commonTabs };
};
