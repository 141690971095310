import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toast } from 'primereact/toast';

import { closeToastMessage, setToastMessage } from '../../../modules/core/actions/core.action.creators';
import { commonToast } from '../../../modules/common/components/toastMessages';
import { elementIDs } from '../../../modules/config/elementIDsConfig';

const Toasts = () => {
  const dispatch = useDispatch();
  const { toastMessage, shouldCloseToastMessage } = useSelector((state) => state.core);

  const baseZIndex = 1000000;
  const toastBL = useRef(null);
  const toastBR = useRef(null);
  const toastTL = useRef(null);
  const toastTR = useRef(null);

  useEffect(() => {
    toastMessage?.message &&
      toastMessage?.message?.length !== 0 &&
      commonToast(
        toastMessage.position === 'bottom-left'
          ? toastBL
          : toastMessage.position === 'bottom-right'
            ? toastBR
            : toastMessage.position === 'top-left'
              ? toastTL
              : toastTR,
        toastMessage
      );
  }, [toastMessage]);

  // Close toast message
  useEffect(() => {
    if (shouldCloseToastMessage) closeToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldCloseToastMessage]);

  const closeToast = () => {
    toastMessage.position === 'bottom-left'
      ? toastBL.current.clear()
      : toastMessage.position === 'bottom-right'
        ? toastBR.current.clear()
        : toastMessage.position === 'top-left'
          ? toastTL.current.clear()
          : toastTR.current.clear();
    onRemoveToast();
    dispatch(closeToastMessage(false));
  };

  const onRemoveToast = () => {
    dispatch(setToastMessage({ type: '' }));
  };

  return (
    <>
      <Toast
        id={elementIDs.toastMessage}
        ref={toastBR}
        baseZIndex={baseZIndex}
        position="bottom-right"
        onRemove={onRemoveToast}
        onClick={closeToast}
      />

      <Toast
        id={elementIDs.toastMessage}
        ref={toastBL}
        baseZIndex={baseZIndex}
        position="bottom-left"
        onRemove={onRemoveToast}
        onClick={closeToast}
      />

      <Toast id={elementIDs.toastMessage} ref={toastTL} baseZIndex={baseZIndex} position="top-left" onRemove={onRemoveToast} onClick={closeToast} />

      <Toast id={elementIDs.toastMessage} ref={toastTR} baseZIndex={baseZIndex} position="top-right" onRemove={onRemoveToast} onClick={closeToast} />
    </>
  );
};

export default Toasts;
