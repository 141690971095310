import React, { useContext } from 'react';
import Context from '../../../../../../../Context';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const ClinicalInformation = () => {
  const { data } = useContext(Context);

  return (
    <div className="pt-3 text-sm">
      <div className="font-semibold demographicsInfoTableHeader">{t('Clinical_information')}</div>
      <table className="demographicsInfoTable">
        <tbody>
          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold">{t('What_happened_subject_sx_examination')}</span>
                <span className="wordBreakText coloredText">{data[inputs.clinicalInformation.name]}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClinicalInformation;
