import React from 'react';
import QuickPickItemsBlock from './QuickPickItemsBlock';
import { ScrollPanel } from 'primereact/scrollpanel';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useQuickPickItems } from '../../../hooks/useQuickPickItems';

const QuickPick = () => {
  const { quickPickItems } = useQuickPickItems();

  return (
    <ScrollPanel className="h-full">
      <div id={elementIDs.quickPickPanel} className="mt-3 pr-1">
        {quickPickItems.map((i) => {
          return <QuickPickItemsBlock key={`${i.name}`} item={i} />;
        })}
      </div>
    </ScrollPanel>
  );
};

export default QuickPick;
