import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { submissionCodesDropdownOptions } from '../../../helpers/dropdownOptions';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useSubmission } from '../../../hooks/useSubmission';

const SubmissionCode = () => {
  const name = inputs.submission.name;
  const { control } = useFormContext();
  const { onChangeSubmission } = useSubmission();

  const submissionCodeInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.submission.label}>
        <Dropdown
          id={elementIDs.submissionCodes}
          type="text"
          inputRef={field.ref}
          value={field.value}
          scrollHeight={dropdownScrollHeight()}
          options={submissionCodesDropdownOptions}
          onChange={(e) => onChangeSubmission(e.value, field)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => submissionCodeInput(field)} />;
};

export default SubmissionCode;
