import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useValidation } from '../../../hooks/useValidation';
import { useConditions } from '../../../hooks/useConditions';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const Fee = ({ index }) => {
  const name = `${inputs.services.name}.${index}.${inputs.amount.name}`;
  const lessMSPNameame = `${inputs.services.name}.${index}.${inputs.lessMSP.name}`;
  const { control, isView, setValue, clearErrors } = useFormContext();
  const { isPractitionerSelected } = useConditions();
  const { feeValidation } = useValidation();
  const disabled = isView || !isPractitionerSelected;

  const onChange = (e, field) => {
    field.onChange(e.value);
    setValue(lessMSPNameame, e.value);
    if (e.value) clearErrors(name);
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      setValue(name, 0);
      setValue(lessMSPNameame, 0);
    }
  };

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <InputWrapper require={!isView} name={name} label={inputs.amount.label} errorMessage={error?.message}>
          <InputNumber
            {...field}
            disabled={disabled}
            inputStyle={{ width: 'inherit' }}
            style={{ width: 'inherit' }}
            className={cx('flex', { 'p-invalid': error?.message })}
            type="text"
            mode="currency"
            currency="USD"
            locale="en-US"
            onChange={(e) => onChange(e, field)}
            onBlur={(e) => onBlur(e, field)}
          />
        </InputWrapper>
      )}
      name={name}
      control={control}
      rules={{ validate: (value) => feeValidation(value) }}
    />
  );
};

export default Fee;
