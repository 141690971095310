import { useFormContext } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import QuickPick from '../modules/TeleplanCatalogs/QuickPick/QuickPick';
import ReferralCatalogs from '../modules/TeleplanCatalogs/Layouts/ReferralCatalogs';

import { inputs } from '../helpers/inputs';
import { isSupplementary } from '../../../../config/specialitiesConfig';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { invoiceTypes } from '../helpers/defaultValues';
import { getCategoryType } from '../../../helpers/getCategoryType';
import { t } from '../../../../../service/localization/i18n';

export const useCatalogTabs = () => {
  const { control, setLocalState, isGroup, localState } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const rowOptions = localState?.batchCatalogsDialog?.rowOptions;

  const mostRecent = {
    ...commonInputs.mostRecent,
    hideSearchForMobileView: true,
    tabLayout: () => <QuickPick />
  };

  const aoi = {
    name: inputs.aoi.name,
    label: inputs.aoi.tabLabel,
    codeType: inputs.aoi.codeType,
    categoryType: getCategoryType(inputs.aoi.codeType, invoiceType),
    codeDescription: inputs.aoi.codeDescription,
    maxEntries: inputs.aoi.maxEntries,
    openCatalogButton: true,
    searchAllType: 'aoi_all',
    requestParams: { codeType: commonInputs.aoi.codeType, speciality, getCodesFor: 'teleplan' }
  };

  const noi = {
    name: inputs.noi.name,
    label: inputs.noi.tabLabel,
    codeType: inputs.noi.codeType,
    categoryType: getCategoryType(inputs.noi.codeType, invoiceType),
    codeDescription: inputs.noi.codeDescription,
    maxEntries: inputs.noi.maxEntries,
    openCatalogButton: true,
    searchAllType: 'noi_all',
    requestParams: { codeType: commonInputs.noi.codeType, speciality, getCodesFor: 'teleplan' }
  };

  const fee = {
    name: inputs.feeCodes.name,
    label: inputs.feeCodes.tabLabel,
    codeType: inputs.feeCodes.codeType,
    categoryType: getCategoryType(inputs.feeCodes.codeType, invoiceType),
    codeDescription: inputs.feeCodes.codeDescription,
    maxEntries: inputs.feeCodes.maxEntries,
    searchAllType: 'fee_all',
    requestParams: { codeType: commonInputs.feeCodes.codeType, speciality, getCodesFor: 'teleplan' }
  };

  const dx = {
    name: inputs.icd9.name,
    label: inputs.icd9.tabLabel,
    codeType: inputs.icd9.codeType,
    categoryType: getCategoryType(inputs.icd9.codeType, invoiceType),
    codeDescription: inputs.icd9.codeDescription,
    maxEntries: inputs.icd9.maxEntries,
    searchAllType: 'icd9_all',
    requestParams: { codeType: commonInputs.icd9.codeType, speciality, getCodesFor: 'teleplan' }
  };

  const referral = {
    name: inputs.referral.name,
    label: inputs.referral.tabLabel,
    codeType: inputs.referral.codeType,
    categoryType: getCategoryType(inputs.referral.codeType, invoiceType),
    codeDescription: inputs.referral.codeDescription,
    maxEntries: inputs.referral.maxEntries,
    searchAllType: 'referral_all',
    requestParams: { codeType: commonInputs.referral.codeType, speciality, getCodesFor: 'teleplan' },
    defaultTableData: catalogMostRecentCodes[commonInputs.referral.codeType],
    showAlphabet: true,
    showSearchPanelForCustomTabLayout: true,
    tabLayout: (e) => <ReferralCatalogs {...e} />,
    searchActionButton: {
      tooltip: t('Add_new_practitioner'),
      onClick: () => {
        if (isGroup) {
          setLocalState((prevState) => ({ ...prevState, addNewPractitionerForGroupDialog: { showDialog: true, rowOptions } }));
        } else {
          setLocalState((prevState) => ({ ...prevState, addNewPractitionerDialog: true }));
        }
      }
    }
  };

  const tabsListForGroup = [mostRecent, aoi, noi, dx, fee, { ...referral, tabLayout: (e) => <ReferralCatalogs {...e} isGroup /> }];

  const getTabs = () => {
    let commonTabs = [mostRecent];

    if (localState.isEmptyPractitioner) return commonTabs;

    const showFeeTab = !isSupplementary(speciality, invoiceType);
    const showDxTab = !localState.isEmptyPractitioner;
    const showReferralTab = !isSupplementary(speciality, invoiceType);

    if (showDxTab) commonTabs.splice(1, 0, dx);
    if (showFeeTab) commonTabs.splice(1, 0, fee);
    if (invoiceType === invoiceTypes.wsbc) commonTabs.splice(1, 0, ...[aoi, noi]);
    if (showReferralTab) commonTabs = [...commonTabs, referral];

    // Do not show Dx tab for Teleplan Group form
    if (isGroup) commonTabs = commonTabs.filter((i) => i.name !== inputs.icd9.name && i.name !== inputs.referral.name);

    return commonTabs;
  };

  const onTabChange = (e) => {
    const tabs = getTabs();

    setLocalState((prevState) => ({
      ...prevState,
      catalogIndex: e.index,
      focusFieldParams: tabs[e.index]
    }));
  };

  return { catalogTabs: getTabs(), tabsListForGroup, onTabChange };
};
