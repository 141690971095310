import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { t } from '../../../../service/localization/i18n';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';
import CommonCard from '../../../common/components/CommonCard';
import { elementIDs } from '../../../config/elementIDsConfig';
import { Divider } from 'primereact/divider';
import { getSpeciality } from '../../../config/specialitiesConfig';





const StaffCard = ({ index, isMobileOnly, showSkeleton }) => {

  const { isMobile, clientWidth } = useSelector((state) => state.core.window);


  const contactsLayout = (
    <>
      {!index.Email && !index.Phone ? (
        <div className="flex align-items-center col-11 p-0">{t('No_email_or_phone_number')}</div>
      ) : (
        <div className="col-12 p-0">
          {index.Email && (
            <div className="flex col-12 p-0 medium-size-text mb-1">
              <div className={cx('col-1 p-0')}>
                <i className="pi pi-envelope mr-4" />
              </div>
              <div className="flex flex-column col-11 p-0">
                {index.EmailType ? (
                  <div className="flex">
                    <Tooltip target=".emailType" />
                    <span id={elementIDs.patientEmail} className="mr-2">
                      {index.Email}
                    </span>
                  </div>
                ) : (
                  <span id={elementIDs.patientEmail}>{index.Email}</span>
                )}
              </div>
            </div>
          )}

          {index.Phone && (
            <div className="flex col-12 p-0 medium-size-text">
              <div className={cx('col-1 p-0')}>
                <i className="pi pi-phone mr-4" />
              </div>
              <div className="flex flex-column col-11 p-0">
                {index.Phone ? (
                  <div className="flex">
                    <span id={elementIDs.patientPhone} className="mr-2">
                      {index.Phone}
                    </span>
                  </div>
                ) : (
                  <span id={elementIDs.patientPhone}>{index.Phone}</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );

  const addressLayout = (
    <>
      {!index.AddressLine && !index.City ? (
        <div className="flex align-items-center col-11 p-0">{t('No_address')}</div>
      ) : (
        <div className="col-12 p-0">
          {(index.AddressLine || index.City) && (
            <div className="flex col-12 p-0 medium-size-text">
              <div className={cx('col-1 p-0')}>
                <i className={cx('pi pi-home mr-4 addressType')} />
              </div>

              <div className="flex flex-column col-11 p-0">
                <span id={elementIDs.patientAddressLine}>{index.AddressLine}</span>

                {(index.City || index.Province) && (
                  <div className="mb-1">
                    {index.City && index.Province ? (
                      <span id={elementIDs.patientCity}>{`${index.City}, ${index.Province && index.Province}`}</span>
                    ) : (
                      <>
                        <i className="pi pi-home mr-4" />
                        <span id={elementIDs.patientCity}>{index.Province && index.Province}</span>
                      </>
                    )}
                  </div>
                )}

                {index.Zip && (
                  <div id={elementIDs.patientZip} className="mb-1">
                    {index.Zip}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );

  const specialistsLayout = (
    <>
      {!index.Specialties ? (
        <div className="flex align-items-center col-11 p-0">{t('No_specialties')}</div>
      ) : (
        <div className="col-12 p-0">
          <div className="flex col-12 p-0 medium-size-text">
            <div className={cx('col-1 p-0')}>
              <i className={cx('pi pi-briefcase mr-4')} />
            </div>
            <div className="flex flex-column col-11 p-0">
              {index.Specialties.split('|')
                .filter(specialty => specialty) // Filter out empty strings
                .map((specialty, idx) => (
                  <div key={idx} className="flex col-12 p-0 mb-1">
                    <span>{getSpeciality(specialty).label}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );

const payeeNumbersLayout = (
  <>
    {!index.PayeeNumbers || index.PayeeNumbers.length === 0 ? (
      <div className="flex align-items-center col-11 p-0">{t('No_payee_numbers')}</div>
    ) : (
      <div className="col-12 p-0">
        <div className="flex col-12 p-0 medium-size-text">
          <div className={cx('col-1 p-0')}>
            <i className={cx('pi pi-credit-card mr-4')} /> 
          </div>
          <div className="flex flex-column col-11 p-0">
            {index.PayeeNumbers.map((payeeNumber, idx) => (
              <div key={idx} className="flex col-12 p-0 mb-1">
                <span>
                  {idx === 0 ? 'MSP# ' : 'MSP Payee# '}
                  {payeeNumber}
                  </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  </>
);



  return (
    <>
      <div className="flex justify-content-center">
        <div className="grid col-12 mt-4 mx-0 p-0">
          <div className={cx('col-12 lg:col-6', isMobileOnly ? 'p-0' : 'py-0')}>
            {/* CONTACT INFO */}
            <div id="patient-profile-contact-card">

              <CommonCard title={t('Contact_Info')}>
                <div className="flex flex-column sm:flex-row mt-3">
                  <div className="col-12 sm:col-6 p-0">
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="15rem" />
                          <Skeleton className="mt-2" width="15rem" />
                        </div>
                      ) : (
                        contactsLayout
                      )}
                    </div>
                  </div>


                  <Divider className='' layout={clientWidth > 556 ? 'vertical' : 'horizontal'} />
                  {/* ADDRESS */}
                  <div className="col-12 sm:col-6 p-0">
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="15rem" />
                          <Skeleton className="mt-2" width="15rem" />
                        </div>
                      ) : (
                        addressLayout
                      )}
                    </div>
                  </div>
                </div>

              </CommonCard>
              
              <div>
                <CommonCard title={t('Specialties_And_Billing')}>
                  {/* Specialties */}
                <div className="flex flex-column sm:flex-row mt-3">

                  <div className="col-12 sm:col-6 p-0">
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="15rem" />
                          <Skeleton className="mt-2" width="15rem" />
                        </div>
                      ) : (
                        specialistsLayout
                      )}
                    </div>
                  </div>
                  <Divider className='' layout={clientWidth > 556 ? 'vertical' : 'horizontal'} />
                  {/* Billing */}
                  <div className="col-12 sm:col-6 p-0">
                    <div className="flex col-12 p-0">
                      {showSkeleton ? (
                        <div className="flex flex-column">
                          <Skeleton width="15rem" />
                          <Skeleton className="mt-2" width="15rem" />
                        </div>
                      ) : (
                        payeeNumbersLayout
                      )}
                    </div>
                  </div>
                </div>
            </CommonCard>
          </div>

        </div>
      </div>
    </div >
      </div >
    </>
  );

};

export default StaffCard;
