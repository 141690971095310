import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useClaimsListContext } from '../../../hooks/useClaimsListContext';
import { currencyFormat } from '../../../../../../utils/currencyFormat';
import { t } from '../../../../../../../service/localization/i18n';

const CurrencyLayout = () => {
  const { state } = useClaimsListContext();

  return (
    <div className="flex gap-3">
      <div>{`${t('Billed')}: ${currencyFormat(state.totalBilled)}`}</div>
      <div>{`${t('Paid')}: ${currencyFormat(state.totalPaid)}`}</div>
    </div>
  );
};

const PaginatorLeft = () => {
  const { state } = useClaimsListContext();
  const op = useRef(null);

  return (
    <>
      <div className="hidden md:flex">
        <CurrencyLayout />
      </div>

      <OverlayPanel ref={op} showCloseIcon id="overlay_panel">
        <div className="flex flex-column gap-1">
          <div className="flex justify-content-between gap-1 w-9rem">
            <span>{`${t('Billed')}: `}</span>
            <span>{`${currencyFormat(state.totalBilled)}`}</span>
          </div>

          <div className="flex justify-content-between gap-1 w-9rem">
            <span>{`${t('Paid')}: `}</span>
            <span>{`${currencyFormat(state.totalPaid)}`}</span>
          </div>

          <div className="flex justify-content-between gap-1 w-9rem">
            <span>{`${t('A_R')}: `}</span>
            <span>{`${currencyFormat(state.totalDue)}`}</span>
          </div>
        </div>
      </OverlayPanel>

      <div className="flex md:hidden">
        <i
          className="pi pi-info-circle pointer paginatorLeftTooltip"
          onClick={(e) => op.current.toggle(e)}
          aria-haspopup
          aria-controls="overlay_panel"
        />
      </div>
    </>
  );
};

export default PaginatorLeft;
