import React from 'react';
import { Timeline } from 'primereact/timeline';
import { useInvoiceTimeline } from '../hooks/useInvoiceTimeline';

const TimeLine = () => {
  const { value, marker, content } = useInvoiceTimeline();

  return <Timeline className="invoice-timeline" value={value} marker={marker} content={content} />;
};

export default TimeLine;
