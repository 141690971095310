import React from 'react';

const EClaimsTermsAndConditions = () => {
  return (
    <>
      <h3 className="underline text-center">eClaims - Terms &amp; Conditions</h3>
      <p>
        <b>English version:</b>
      </p>
      <p>I certify that the information being submitted is true and complete.</p>
      <p>I certify that the total claim amount charged does not exceed the amount charged to a cash paying customer or any other payer.</p>
      <p>
        I certify that all goods and services being claimed herein have been received by the Plan Member and/or his/her spouse and/or dependents, and
        have not been previously paid for by this or any other plan.
      </p>
      <p>
        I certify that the Plan Member and/or his/her spouse and/or dependents has agreed to the collection, use and disclosure of personal
        information for the purposes described in the Authorization and Consent form, and that I am retaining a copy of the signed Authorization and
        Consent form signed by the Plan Member.
      </p>
      <p>
        I understand that the Payer makes the final decision on the eligibility of all Claims and that submitting false, inaccurate or medically
        unnecessary claims will result in the immediate suspension and/or permanent termination of the ability to submit claims electronically, and
        may also lead to the requirement to repay the claim and/or submission of complaints to law enforcement, the appropriate regulatory college or
        association, and/or civil action as appropriate.
      </p>
      <p>
        I understand that an audit related to this submission may be conducted and the auditor is to be supplied with unrestricted access to such
        things including, but not limited to clinical, billing, patient and business records, as they relate to the submission of this claim. If I am
        asked to participate in a fraud, know someone that I believe is participating in a fraud or believe someone is using my identity without my
        permission, I will contact the police, my regulatory body and/or my professional association.
      </p>
      <p>
        <hr />
      </p>
      <p>
        <b>French version:</b>
      </p>
      <p>J’atteste que les renseignements soumis sont complets et exacts.</p>
      <p>J’atteste que le montant total réclamé n’excède pas le montant facturé à un client qui a payé au comptant ou à tout autre type de payeur.</p>
      <p>
        J’atteste que tous les biens et services réclamés ont effectivement été fournis au bénéficiaire du régime/à son conjoint ou à sa conjointe
        et/ou à ses personnes à charge et n’ont pas déjà été remboursés en vertu de ce régime ni de tout autre régime.
      </p>
      <p>
        J’atteste que le bénéficiaire du régime, son conjoint ou sa conjointe ainsi que ses personnes à charge ont autorisé la collecte, l’utilisation
        et la divulgation de leurs données personnelles aux fins décrites dans un formulaire d’autorisation et de consentement dont je possède une
        copie signée par le bénéficiaire du régime.
      </p>
      <p>
        Je comprends que le payeur décide ultimement de l’admissibilité de toutes les réclamations et que si je soumets des réclamations inexactes,
        fausses ou non nécessaires sur le plan médical, je me verrai immédiatement interdit d’accès au programme de réclamations électroniques, de
        façon temporaire ou permanente, et peut également conduire à l’obligation de rembourser la réclamation ainsi qu’à une plainte qui pourrait
        être déposée à mon encontre, soit à la police, à l’autorité réglementaire ou à l’association appropriée ou encore dans le cadre d’un recours
        en justice civile.
      </p>
      <p>
        Je comprends que je pourrai faire l’objet d’une vérification relativement aux présentes et que je devrai alors fournir au vérificateur libre
        accès à tout le matériel lié aux réclamations, soit, sans toutefois s’y limiter, les dossiers cliniques, les registres de facturation, les
        dossiers des patients et les documents professionnels.
      </p>
      <p>
        Si on me demande de participer à une fraude, que je connaissais quelqu'un qui, selon moi, participe à une fraude ou croit que quelqu'un
        utilise mon identité sans ma permission, je communiquerai avec la police, mon organisme de réglementation et/ou mon association
        professionnelle.
      </p>
      <p>
        <br />
      </p>
    </>
  );
};

export default EClaimsTermsAndConditions;
