import { getPatient } from '../../../../modules/patients/actions/patients.action.creators';
import { getPatientInsurancePolicies } from '../../../../service/Lookup';

export const getPatientData = async (patientGuid, setState) => {
  const result = await getPatient(patientGuid);
  if (result) setState((prevState) => ({ ...prevState, patient: result }));
};

export const getPoliciesList = async (patientGuid, setState) => {
  setState((prevState) => ({ ...prevState, loadingPoliciesList: true }));
  const result = await getPatientInsurancePolicies(patientGuid);
  setState((prevState) => ({ ...prevState, loadingPoliciesList: false }));
  if (result) setState((prevState) => ({ ...prevState, policiesList: result }));
};
