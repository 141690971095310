import React, { useContext } from 'react';
import Context from '../../../../../Context';
import { inputs } from '../../../../../modules/claims/components/WSBCReport/helpers/inputs';
import { t } from '../../../../../service/localization/i18n';

const ClinicalInformation = () => {
  const { data } = useContext(Context);

  return (
    <div className="pt-3 text-sm">
      <div className="font-semibold demographicsInfoTableHeaderPrint">{t('Clinical_information')}</div>
      <table className="demographicsInfoTablePrint">
        <tbody>
          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold">{t('What_happened_subject_sx_examination')}</span>
                <span className="wordBreakTextPrint coloredText">{data[inputs.clinicalInformation.name]}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClinicalInformation;
