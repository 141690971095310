import React from 'react';
import { inputs } from '../../../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/inputs';
import { commonInputs } from '../../../../../modules/config/commonInputsConfig';
import { trimLeftZeros } from '../../../../../modules/utils/trimLeftZeros';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const InvoiceDetailsSection = ({ data }) => {
  return (
    <div className="invoice-details invoiceSectionWrap">
      <div className="invoice-label mb-0 mt-1">{t('Status').toUpperCase()}</div>
      <div className="invoice-value flex justify-content-end">
        <span>{data?.StatusText}</span>
      </div>

      <div className="invoice-label">{`${t('Invoices.3').toUpperCase()} #`}</div>
      <div className="invoice-value flex justify-content-end">{trimLeftZeros(data[inputs().invoiceNumber.name])}</div>

      <div className="invoice-label">{t('Date').toUpperCase()}</div>
      <div className="invoice-value flex justify-content-end">
        {data[commonInputs.serviceDate.name] ? moment(data[commonInputs.serviceDate.name]).format('MM/DD/YYYY') : ''}
      </div>

      <div className="invoice-label">{t('Due_Date').toUpperCase()}</div>
      <div className="invoice-value flex justify-content-end">{moment(data[inputs().dueDate.name]).format('MM/DD/YYYY')}</div>
    </div>
  );
};

export default InvoiceDetailsSection;
