import actionTypes from '../actions/preferences.action.types';

export const prefsInitialState = {
  currentUserPrefs: {},
  currentPractitionerPrefs: {},
  savingPreferences: false,
  isGettingPrefs: false
};

export default function preferencesReducer(state = prefsInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case actionTypes.GET_CURRENT_USER_PREFS_SUCCESS:
      return {
        ...state,
        currentUserPrefs: results
      };

    case actionTypes.SAVING_PREFERENCES:
      return {
        ...state,
        savingPreferences: results
      };

    case actionTypes.IS_GETTING_PREFS:
      return {
        ...state,
        isGettingPrefs: results
      };

    case actionTypes.SET_CURRENT_PRACTITIONER_PREFS:
      return {
        ...state,
        currentPractitionerPrefs: results
      };

    default:
      return state;
  }
}
