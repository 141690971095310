import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import DatePicker from './Common/DatePicker';

import { inputs } from '../../../helpers/inputs';
import { injuryDateInputRequirements } from '../../../helpers/validationSchema';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const InjuryDate = () => {
  const { control } = useFormContext();
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const required = injuryDateInputRequirements(invoiceType);

  return <DatePicker id={elementIDs.injuryDay} input={inputs.injuryDate} required={required} />;
};

export default InjuryDate;
