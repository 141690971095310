import React from 'react';
import EClaimPolicy from '../../../../../modules/claims/components/EClaimPolicy/EClaimPolicy';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { useEClaimPolicy } from '../../hooks/useEClaimPolicy';

const EClaimPolicyForm = () => {
  const { state } = usePatientPoliciesContext();
  const { onCloseEClaimPolicy, onCreateEClaimPolicy, onEditEClaimPolicy } = useEClaimPolicy();
  const showEClaimPolicyForm = state.eClaimPolicy?.showEClaimPolicyForm;
  const eClaimPolicy = state.eClaimPolicy;

  // if (!showEClaimPolicyForm) return null;

  return (
    <EClaimPolicy
      {...eClaimPolicy}
      showInDialog
      onCancel={onCloseEClaimPolicy}
      onCreate={(data) => onCreateEClaimPolicy(data)}
      onEdit={(data) => onEditEClaimPolicy(data)}
    />
  );
};

export default EClaimPolicyForm;
