import React from 'react';
import { Button } from 'primereact/button';

const CheckMSPButton = ({ id, label, disabled, loading, tooltip, onClick, tabIndex }) => {
  return (
    <Button
      id={id}
      className="p-button-text"
      label={label}
      type="button"
      tooltip={tooltip}
      tabIndex={tabIndex}
      tooltipOptions={{ position: 'top' }}
      disabled={disabled}
      loading={loading}
      autoFocus={false}
      onClick={onClick}
    />
  );
};

export default CheckMSPButton;
