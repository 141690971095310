import { store } from '../../store';
import { invoiceTypes } from '../claims/components/TeleplanInvoice/helpers/defaultValues';
import { commonInputs } from './commonInputsConfig';

export const specialities = [
  { value: '0', label: 'GP', tooltip: 'GENERAL PRACTICE', [commonInputs.icd9.codeType]: [] },
  { value: '1', label: 'Derm.', tooltip: 'DERMATOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '2', label: 'Neur.', tooltip: 'NEUROLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '3', label: 'PSYCHIATRY', tooltip: 'PSYCHIATRY', [commonInputs.icd9.codeType]: [] },
  { value: '-5', label: 'O&G', tooltip: 'OBSTETRICS & GYNAECOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '6', label: 'Ophthalmol.', tooltip: 'OPHTHALMOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '7', label: 'ORL', tooltip: 'OTOLARYNGOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '8', label: 'Gen. Surg.', tooltip: 'GENERAL SURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '9', label: 'NeuroSurg.', tooltip: 'NEUROSURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '10', label: 'Ortho', tooltip: 'ORTHOPAEDICS', [commonInputs.icd9.codeType]: [] },
  { value: '11', label: 'Plast. Surg.', tooltip: 'PLASTIC SURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '12', label: 'Cardiac Surg.', tooltip: 'CARDIAC SURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '13', label: 'Urology', tooltip: 'UROLOGY', [commonInputs.icd9.codeType]: [] },
  {
    value: '19',
    label: 'Pediatrics',
    tooltip: 'PAEDIATRICS',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '00189: PODIATRY VISIT',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'PODIATRY VISIT',
        value: '00189'
      }
    ]
  },
  { value: '15', label: 'Internal Med.', tooltip: 'INTERNAL MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '16', label: 'Radiology', tooltip: 'RADIOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '17', label: 'Lab Med.', tooltip: 'LABORATORY MEDICINE', [commonInputs.icd9.codeType]: [] },
  {
    value: '18',
    label: 'Anesth.',
    tooltip: 'ANAESTHESIA',
    [commonInputs.icd9.codeType]: [
      {
        amount: 0,
        order: 0,
        category: 'icd9_OTHER',
        category_label: 'ADDITIONAL DIAGNOSTIC CODES',
        label: '01Z: *ANAESTHETIC',
        level: '1',
        min_per_unit: null,
        portion: null,
        text: '*ANAESTHETIC',
        value: '01Z'
      }
    ]
  },
  { value: '-19', label: 'Pediatric cardio.', tooltip: 'PAEDIATRIC CARDIOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '20', label: 'PM&R', tooltip: 'PHYSICAL MEDICINE AND REHABILITATION', [commonInputs.icd9.codeType]: [] },
  { value: '21', label: 'Public Health', tooltip: 'PUBLIC HEALTH (COMMUNITY MEDICINE)', [commonInputs.icd9.codeType]: [] },
  { value: '23', label: 'Occup. Med.', tooltip: 'OCCUPATIONAL MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '-24', label: 'Geriatr. Med.', tooltip: 'GERIATRIC MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '26', label: 'Cardiology', tooltip: 'CARDIOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '28', label: 'Emerg. Med.', tooltip: 'EMERGENCY MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '29', label: 'Med. Microbiolg.', tooltip: 'MEDICAL MICROBIOLOGY', [commonInputs.icd9.codeType]: [] },
  {
    value: '14',
    label: 'Chiropr.',
    tooltip: 'CHIROPRACTORS',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '00138: CHIROPRACTIC SERVICE',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'CHIROPRACTIC SERVICE',
        value: '00138'
      }
    ]
  },
  {
    value: '24',
    label: 'ND',
    tooltip: 'NATUROPATHS',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '00145: NATUROPATHY SERVICE',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'NATUROPATHY SERVICE',
        value: '00145'
      }
    ]
  },
  {
    value: '5',
    label: 'PH',
    tooltip: 'PHYSICAL THERAPY',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '09938: PHYSIOTHERAPY SERVICE',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'PHYSIOTHERAPY SERVICE',
        value: '09938'
      }
    ]
  },
  { value: '33', label: 'Nuclear Med.', tooltip: 'NUCLEAR MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '34', label: 'Osteopathy', tooltip: 'OSTEOPATHY', [commonInputs.icd9.codeType]: [] },
  { value: '37', label: 'Oral Surg.', tooltip: 'ORAL SURGEONS', [commonInputs.icd9.codeType]: [] },
  {
    value: '38',
    label: 'DPM',
    tooltip: 'PODIATRISTS',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '00189: PODIATRY VISIT',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'PODIATRY VISIT',
        value: '00189'
      }
    ]
  },
  { value: '39', label: 'OD', tooltip: 'OPTOMETRIST', [commonInputs.icd9.codeType]: [] },
  { value: '40', label: 'Dental Surg.', tooltip: 'DENTAL SURGEONS', [commonInputs.icd9.codeType]: [] },
  { value: '41', label: 'Oral Med.', tooltip: 'ORAL MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '42', label: 'Orthod.', tooltip: 'ORTHODONTISTS', [commonInputs.icd9.codeType]: [] },
  {
    value: '4',
    label: 'RMT',
    tooltip: 'MASSAGE PRACTITIONER',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '09948: MASSAGE THERAPY SERVICE',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'MASSAGE THERAPY SERVICE',
        value: '09948'
      }
    ]
  },
  { value: '-44', label: 'Rheumatol.', tooltip: 'RHEUMATOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '45', label: 'CI&A', tooltip: 'CLINICAL IMMUNIZATION AND ALLERGY', [commonInputs.icd9.codeType]: [] },
  { value: '46', label: 'Med. Genetics', tooltip: 'MEDICAL GENETICS', [commonInputs.icd9.codeType]: [] },
  { value: '47', label: 'Vasc. Surg.', tooltip: 'VASCULAR SURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '48', label: 'Thorac. Surg.', tooltip: 'THORACIC SURGERY', [commonInputs.icd9.codeType]: [] },
  { value: '49', label: 'Respirol.', tooltip: 'RESPIROLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '51', label: 'Endocr.', tooltip: 'ENDOCRINOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '53', label: 'Crit. Care Med.', tooltip: 'CRITICAL CARE MEDICINE', [commonInputs.icd9.codeType]: [] },
  { value: '56', label: 'GI', tooltip: 'GASTROENTEROLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '59', label: 'Nephrol.', tooltip: 'NEPHROLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '67', label: 'Infect. Dis.', tooltip: 'INFECTIOUS DISEASE', [commonInputs.icd9.codeType]: [] },
  {
    value: '44',
    label: 'Acupunct.',
    tooltip: 'ACUPUNCTURE',
    [commonInputs.icd9.codeType]: [],
    [commonInputs.feeCodes.codeType]: [
      {
        amount: 23,
        category: 'fee80all',
        category_label: 'All codes',
        label: '00142: ACUPUNCTURE SERVICE',
        level: '',
        min_per_unit: null,
        portion: null,
        text: 'ACUPUNCTURE SERVICE',
        value: '00142'
      }
    ]
  },
  { value: '74', label: 'Hem. Onc.', tooltip: 'HEMATOLOGY ONCOLOGY', [commonInputs.icd9.codeType]: [] },
  { value: '78', label: 'RN in Cert. Pract.', tooltip: 'RN - IN CERTIFIED PRACTICE', [commonInputs.icd9.codeType]: [] },
  {
    value: '80',
    label: 'Midwife',
    tooltip: 'MIDWIVES OF BC',
    [commonInputs.icd9.codeType]: [
      {
        amount: 0,
        order: 0,
        category: 'icd9_OTHER',
        category_label: 'ADDITIONAL DIAGNOSTIC CODES',
        label: '30B: *PRENATAL CARE',
        level: '1',
        min_per_unit: null,
        portion: null,
        text: '*PRENATAL CARE',
        value: '30B'
      }
    ]
  },
  { value: '81', label: 'Reg. Nurse', tooltip: 'REGISTERED NURSE - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '82', label: 'Nutr./Diet.', tooltip: 'NUTRITIONIST/DIETICIAN - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '83', label: 'Couns./physch.', tooltip: 'COUNSELLOR/PSYCHOLOGIST - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '84', label: 'Educator', tooltip: 'EDUCATOR - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '85', label: 'Lic. Pract. Nurse', tooltip: 'LICENSED PRACTICAL NURSE - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '86', label: 'MOA', tooltip: 'MEDICAL OFFICE ASSISTANCE - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '87', label: 'NP', tooltip: 'NURSE PRACTITIONER', [commonInputs.icd9.codeType]: [] },
  { value: '88', label: 'Resp. Ther.', tooltip: 'RESPIRATORY THERAPIST - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '89', label: 'Home Supp.', tooltip: 'HOME SUPPORT - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '91', label: 'Pharmacist', tooltip: 'PHARMACIST - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '92', label: 'Occup. Therap.', tooltip: 'OCCUPATIONAL THERAPIST - PHCO ONLY', [commonInputs.icd9.codeType]: [] },
  { value: '93', label: 'Allied Health. Profess.', tooltip: 'OTHER ALLIED HEALTH PROFESSIONAL - PHCO ONLY', [commonInputs.icd9.codeType]: [] }
];

export const getSpeciality = (spec) => {
  return specialities.find((i) => Number(i.value) === Number(spec)) || { value: spec, label: '', fee: [], [commonInputs.icd9.codeType]: [] };
};

// CMO-2741 - Teleplan screen behavior changes for supplemental benefit practitioners
export const isSupplementary = (speciality, invoiceType) => {
  if (Number(speciality) === 14 && invoiceType === invoiceTypes.wsbc) return false; // for Chiro, do not change anything for WorksafeBC invoice (leave fee codes and referrals as is)

  const validSpecializations = [4, 5, 14, 24, 38, 44];
  return validSpecializations.includes(Number(speciality));
};

export const isPhysician = (speciality) => {
  return !isSupplementary(speciality) && Number(speciality) !== 80;
};

export const isChiropractor = (speciality) => {
  return Number(speciality) === 14;
};

export const isDxPrefilled = (speciality) => {
  const validSpecializations = [18, 80];
  return validSpecializations.includes(Number(speciality));
};

export const showPolicy = () => {
  const validSpecializations = [4, 44, 5, 14, 24, 39];
  const practitioners = store.getState().clinic.members;
  return practitioners?.some((i) => validSpecializations.includes(Number(i.Speciality)));
};

export const isReceptionist = (practitionerNum) => {
  return practitionerNum?.length === 1;
};

export const isRadiology = (speciality) => {
  return speciality === 16 || speciality === 17 || speciality === 29;
};
