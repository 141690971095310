import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const TotalSection = ({ data, isFetching }) => {
  const pstAmount = data ? data.PstTotal : 0;
  const gstAmount = data ? data.GstTotal : 0;
  const grandTotal = data ? data.FeeTotal : 0;
  const balanceDue = data ? data.FeeTotal - data.FeePaid : 0;
  const subTotal = data ? data.FeeAmount - data.DiscountTotal : 0;

  return (
    <>
      <div className="invoice-details" style={{ width: '18rem' }}>
        <div className="invoice-label font-normal">{t('Subtotal').toUpperCase()}</div>
        <div className="invoice-value flex justify-content-end font-normal">
          {isFetching ? <Skeleton width="4rem" className="mt-1" /> : currencyFormat(subTotal)}
        </div>

        <div className="invoice-label font-normal">{`${t('PST')} (BC) @ 7%`}</div>
        <div className="invoice-value flex justify-content-end font-normal">
          {isFetching ? <Skeleton width="4rem" className="mt-1" /> : currencyFormat(pstAmount)}
        </div>

        <div className="invoice-label font-normal">{`${t('GST')} (BC) @ 5%`}</div>
        <div className="invoice-value flex justify-content-end font-normal">
          {isFetching ? <Skeleton width="4rem" className="mt-1" /> : currencyFormat(gstAmount)}
        </div>

        <div className="invoice-label">{t('Total').toUpperCase()}</div>
        <div className="invoice-value flex justify-content-end">
          {isFetching ? <Skeleton width="4rem" className="mt-1" /> : currencyFormat(grandTotal)}
        </div>

        {data?.Payments?.length > 0 && (
          <>
            <div className="invoice-label font-normal">{t('Amount_received')}</div>
            <div className="invoice-value flex justify-content-end font-normal">
              {isFetching ? <Skeleton width="4rem" className="mt-1" /> : currencyFormat(data?.FeePaid)}
            </div>
          </>
        )}

        <div className="invoice-label">{t('Balance_due')}</div>
        <div id={elementIDs.balanceDue} className="invoice-value flex justify-content-end">
          {isFetching ? (
            <Skeleton width="4rem" className="mt-1" />
          ) : (
            <span className={Number(balanceDue) > 0 ? 'p-error' : ''}>{currencyFormat(balanceDue)}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default TotalSection;
