import { useFormContext } from 'react-hook-form';
import { useCatalogTabs } from './useCatalogTabs';
import { useQuickPickItems } from './useQuickPickItems';

export const useToggleCatalogs = () => {
  const { setLocalState } = useFormContext();
  const { catalogTabs } = useCatalogTabs();
  const { quickPickItems } = useQuickPickItems();

  const toggleCatalogs = (view, inputName) => {
    // view: "desktop" | "mobile"
    const catalogIndex = catalogTabs?.findIndex((i) => i.name === inputName);
    const focusFieldParams = quickPickItems?.find((i) => i.name === inputName);

    setLocalState((prevState) => ({
      ...prevState,
      catalogIndex: inputName ? catalogIndex : 0,
      focusFieldParams: inputName ? focusFieldParams : catalogTabs[0],
      showCatalogs: {
        ...prevState.showCatalogs,
        [view]: !prevState.showCatalogs[view]
      }
    }));
  };

  return { toggleCatalogs };
};
