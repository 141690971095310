import React from 'react';
import LoginAsView from './LoginAsView';

const Bureau = () => {
  return (
    <div className="login-body">
      <div className="flex justify-content-center align-items-center p-3 h-screen">
        <div className="login-panel m-0 h-auto w-full" style={{ maxWidth: '800px', position: 'unset' }}>
          <div className="login-panel-header login-panel-header-text">Please select Data Center and user</div>
          <div className="px-3 pb-3" style={{ maxHeight: '90vh' }}>
            <LoginAsView />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bureau;
