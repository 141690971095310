import { Dropdown } from 'primereact/dropdown';

import CodeInput from '../TableFilterElements/CodeInput';
import DateInput from '../TableFilterElements/DateInput';
import TextInput from '../TableFilterElements/TextInput';
import MoneyInput from '../TableFilterElements/MoneyInput';
import { dropdownScrollHeight } from '../../config/stylesConfig';
import { t } from '../../../service/localization/i18n';

export const filterBy = ({ field, columns, dataFieldFilters, filters, setFieldFilter, invalidFields, inputStyle }) => {
  const column = columns?.find((x) => x.id === field);
  const fieldFilter = dataFieldFilters?.find((x) => x.id === field);

  if (column && fieldFilter) {
    let value = filters[field] ? filters[field] : fieldFilter.value;

    switch (fieldFilter.fieldType) {
      case 'dropdown':
        if (fieldFilter.filterOptions) {
          const suspect = fieldFilter.filterOptions.find((x) => x.label === value);
          if (suspect) value = suspect.value;
        }

        if (field === 'StatusText' && value === 'All Statuses') value = '-2';

        return (
          <Dropdown
            id={'flt_' + field}
            className={inputStyle}
            options={fieldFilter.filterOptions}
            value={value}
            placeholder={t('Search')}
            appendTo={document.body}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => setFieldFilter(e, field, fieldFilter.fieldType)}
          />
        );

      case 'code':
        return (
          <CodeInput
            classes={inputStyle}
            field={field}
            filters={filters}
            initialValue={value}
            keyfilter={column?.keyfilter ? column?.keyfilter : ''}
            setFieldFilter={setFieldFilter}
            fieldType={fieldFilter.fieldType}
          />
        );

      case 'money':
        return (
          <MoneyInput
            classes={inputStyle}
            field={field}
            filters={filters}
            initialValue={value}
            invalidFields={invalidFields}
            setFieldFilter={setFieldFilter}
            fieldType={fieldFilter.fieldType}
            ensureNumeric={ensureNumeric}
          />
        );

      case 'date':
        return (
          <DateInput
            field={field}
            filters={filters}
            initialValue={value}
            invalidFields={invalidFields}
            fieldType={fieldFilter.fieldType}
            setFieldFilter={setFieldFilter}
          />
        );

      default:
        return (
          <TextInput
            classes={inputStyle}
            field={field}
            filters={filters}
            initialValue={value}
            invalidFields={invalidFields}
            fieldType={fieldFilter.fieldType}
            setFieldFilter={setFieldFilter}
            keyfilter={column?.keyfilter ? column?.keyfilter : ''}
            setFieldClean={setFieldClean}
          />
        );
    }
  }

  return <span></span>;
};

const ensureNumeric = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode === 189 || charCode === 190) return true;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

const setFieldClean = (e, field_type) => {
  let value = e.target && e.target.value ? e.target.value : e.value || '';
  if (field_type === 'string' && value !== '' && value.charAt(value.length - 1) === '*') {
    e.target.value = value.slice(0, -1);
  }
};
