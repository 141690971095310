import React from 'react';
import { useFormContext } from 'react-hook-form';
import StepOne from './StepOne';
import Success from '../Layouts/Success';

const Steps = () => {
  const { localState } = useFormContext();

  return (
    <>
      {/* Show Teleplan Invoice form */}
      {localState.step === 1 && <StepOne />}

      {/* Show Success page */}
      {localState.step === 2 && <Success />}
    </>
  );
};

export default Steps;
