import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { quickPickCodeChipCommonClass } from '../../../../../../../../config/stylesConfig';
import { inputs } from '../../../../../helpers/inputs';
import { differenceWith } from 'lodash';
import { t } from '../../../../../../../../../service/localization/i18n';

const RecentDxCodes = ({ rowOptions }) => {
  const { localState } = useFormContext();
  const { onDxChange, disabledDxInput } = useGroupPickerInputs();
  const rowData = rowOptions.rowData;
  const patientGuid = rowData[inputs.patient.name][0].PatientGuid;
  const mostRecentCodes = localState.listOfPatients.find((i) => i.PatientGuid === patientGuid)?.RecentCodes?.icd9;
  const numbersOfCodes = 8;
  const disabled = disabledDxInput(rowOptions);

  const onCodeClick = (code) => {
    const dxCodes = rowData[inputs.icd9.codeType] || [];
    onDxChange([...dxCodes, code], rowOptions);
  };

  if (mostRecentCodes?.length) {
    const codesForDisplay = differenceWith(mostRecentCodes, rowData[inputs.icd9.name], (obj1, obj2) => obj1.value === obj2).slice(0, numbersOfCodes);

    return (
      <div className="quick_pick_items_list">
        {codesForDisplay?.map((i, index) => {
          return (
            <Button
              key={`${i.value}_${index}`}
              tabIndex={-1}
              className={quickPickCodeChipCommonClass(false, { order: i.order })}
              label={i.value}
              disabled={disabled}
              tooltip={i.label}
              tooltipOptions={{ position: 'top', showDelay: 1000 }}
              onClick={() => onCodeClick(i)}
            />
          );
        })}
      </div>
    );
  }

  return <span>{t('No_recent_codes')}</span>;
};

export default RecentDxCodes;
