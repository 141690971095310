import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useInsuranceCompany } from '../../hooks/useInsuranceCompany';
import { inputs } from '../../helpers/inputs';
import cx from 'classnames';

const InsuranceQuickPick = () => {
  const { watch } = useFormContext();
  const { options, onSelect } = useInsuranceCompany();

  const isSelected = (item) => {
    return item.name === watch(inputs.insurerID.name);
  };

  return (
    <div className="flex flex-wrap justify-content-center gap-3 py-4">
      {options?.map((i, index) => {
        return (
          <Button
            className={cx(isSelected(i) ? '' : 'p-button-outlined', 'w-16rem h-4rem')}
            key={`${i.name}_${index}`}
            label={i.label}
            onClick={() => onSelect(i)}
          />
        );
      })}
    </div>
  );
};

export default InsuranceQuickPick;
