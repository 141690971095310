import React from 'react';

import { Tooltip } from 'primereact/tooltip';

import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { t } from '../../../../../../../service/localization/i18n';

const DxBodyTemplate = ({ rowData }) => {
  const field = 'icd9';
  const { isRequiredInput } = useUpdateRecords();
  const isRequired = isRequiredInput(rowData.id, field);
  const dxCodesList = rowData[field]?.split(',');
  const dxCodesDescriptionList = rowData.icd9_text?.split('|');

  if (isRequired) {
    return (
      <span id={`${elementIDs.invoiceRecordsDxTemplate}`} className="p-error">
        {t('Required')}
      </span>
    );
  }

  return (
    <>
      <Tooltip target={`.invoiceRecordsDxTooltip_${rowData.fee_code}`} position="top">
        <div>
          {dxCodesList?.map((i, index) => (
            <div key={`${i}_${index}`} className="pb-1">
              {`${i}: ${dxCodesDescriptionList[index]}`}
            </div>
          ))}
        </div>
      </Tooltip>

      <span
        id={`${elementIDs.invoiceRecordsDxTemplate}`}
        className={`invoiceRecordsDxTooltip_${rowData.fee_code}`}
        style={{ color: 'var(--primary-color)' }}
      >
        {rowData.icd9.replaceAll(',', ', ')}
      </span>
    </>
  );
};

export default DxBodyTemplate;
