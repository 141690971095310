import { useEffect } from 'react';

function useSpacebarHandler() {
  useEffect(() => {
    const handleClick = (event) => {
      const editor = document.querySelector('.ql-editor');
      if (editor) {
        editor.addEventListener('keydown', function (event) {
          // Allow spacebar key press inside the editor
          if (event.key === ' ') {
            event.stopPropagation();
          }
        });
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
}

export default useSpacebarHandler;
