import moment from 'moment';
import { getFromLocalStorage } from './getFromLocalStorage';
import { localStorageKeys } from '../config/localStorageKeysConfig';

const isWorkday = (date) => {
  // Check if the day is not a weekend (Saturday or Sunday)
  return date.isoWeekday() <= 5;
};

const isWeekendDay = (date) => {
  // Check if the day is a weekend (Saturday or Sunday)
  return date.isoWeekday() >= 6;
};

export const getServiceDate = () => {
  const date = getFromLocalStorage(localStorageKeys.serviseDate)
    ? moment(getFromLocalStorage(localStorageKeys.serviseDate)?.value).toDate()
    : moment().toDate();

  return date;
};

export const getClosestWorkdayInPast = () => {
  let currentDate = moment();
  while (!isWorkday(currentDate)) {
    currentDate = currentDate.subtract(1, 'day');
  }
  return currentDate.format('MM/DD/YYYY');
};

export const getClosestWeekendDayInPast = () => {
  let currentDate = moment();
  while (!isWeekendDay(currentDate)) {
    currentDate = currentDate.subtract(1, 'day');
  }
  return currentDate.format('MM/DD/YYYY');
};
