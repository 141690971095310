import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Steps } from 'primereact/steps';

import { isSupplementary } from '../../../../../../config/specialitiesConfig';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';
import { steps } from '../../../helpers/steps';

const itemsWithLabel = [
  {
    id: 'services',
    label: t('Services')
  },
  {
    id: 'dx',
    label: t('Dx')
  },
  {
    id: 'referral',
    label: t('Referral')
  },
  {
    id: 'comment',
    label: t('Comment')
  },
  {
    id: 'review',
    label: t('Review')
  }
];

const itemsWithoutLabel = [
  {
    label: ''
  },
  {
    label: ''
  },
  {
    label: ''
  },
  {
    label: ''
  },
  {
    label: ''
  },
  {
    label: ''
  }
];

const GroupStepper = () => {
  const { clientWidth } = useSelector((state) => state.core.window);
  const [activeIndex, setActiveIndex] = useState(0);
  const { watch, localState } = useFormContext();
  const step = localState.step;
  const speciality = watch(inputs.speciality.name);
  const invoiceType = watch(inputs.payor.name);
  const _isSupplementary = isSupplementary(speciality, invoiceType);

  const model = () => {
    if (clientWidth < 950 && step === steps.groupPreview) {
      return itemsWithoutLabel;
    }

    if (clientWidth > 1500 || step > 1) {
      if (_isSupplementary) {
        return itemsWithLabel.filter((item) => ['dx', 'comment', 'review'].includes(item.id));
      }
      return itemsWithLabel;
    }

    return itemsWithoutLabel;
  };

  useEffect(() => {
    if (step > 1) {
      if (_isSupplementary) {
        const indexes = { 6: 0, 8: 1, 9: 2 };
        return setActiveIndex(indexes[step]);
      }

      setActiveIndex(step - 5);
    } else {
      setActiveIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div style={{ minWidth: '600px', maxWidth: '700px' }}>
      <Steps className="p-0 group_stepper" model={model()} activeIndex={activeIndex} />
    </div>
  );
};

export default GroupStepper;
