import { t } from '../../../../../service/localization/i18n';
import { inputs } from './inputs';

export const quickPickItems = [
  {
    name: inputs.serviceDate.name,
    label: inputs.serviceDate.label,
    codeType: inputs.serviceDate.codeType,
    openCatalogButton: false
  },

  {
    name: inputs.icd9.name,
    label: inputs.icd9.label,
    codeType: inputs.icd9.codeType,
    codeDescription: inputs.icd9.codeDescription,
    openCatalogButton: true
  },

  {
    name: inputs.aoi.name,
    label: t('Area_of_Injury.1'),
    codeType: inputs.aoi.codeType,
    codeDescription: inputs.aoi.codeDescription,
    openCatalogButton: true
  },

  {
    name: inputs.noi.name,
    label: inputs.noi.label,
    codeType: inputs.noi.codeType,
    codeDescription: inputs.noi.codeDescription,
    openCatalogButton: true
  }
];
