import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputCharacterCounter, InputWrapper } from '../../../../../../../components/Inputs';

import { createGroupInvoicesTemplate, editGroupInvoicesTemplate, getGroupInvoicesTemplates } from '../../../../../actions/claims.action.creators';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { inputs } from '../../../helpers/inputs';
import { setFocusToElement } from '../../../../../../utils/setFocusToElement';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { commonInputs } from '../../../../../../config/commonInputsConfig';
import { stringFormat } from '../../../../../../utils/stringFormat';
import { useGroupTemplate } from '../hooks/useGroupTemplate';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';
import moment from 'moment';

// interface {
//   option: 'create' | 'edit'
//   defaultValues: {
//     DCNGuid: string,
//     DoctorGuid: string,
//     TemplateGuid: string,
//     Name: string',
//     Description: string,
//     DateCreated: string,
//     Status: number,
//     Records: [],
//     FirstStep: {}
//   }
// }

const CreateGroupTemplate = ({ defaultValues, option = 'create' }) => {
  const dispatch = useDispatch();
  const { isDuplicateTemplate } = useGroupTemplate();
  const { watch, localState, setLocalState } = useFormContext();
  const [duplicateTemplateDialog, setDuplicateTemplateDialog] = useState({ showDialog: false, data: {} });
  const nameCharactersLimit = 50;
  const descriptionCharactersLimit = 1000;

  const { control, formState, handleSubmit, register, reset, setValue } = useForm({ defaultValues });

  const name = useWatch({ control, name: 'Name' });
  const description = useWatch({ control, name: 'Description' });

  // Reset form on unmount
  useEffect(() => {
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CMO-2712 - Create Templates for Batch/Group Teleplan
  const onSubmit = async (data) => {
    if (option === 'create') {
      // Show dialog that template is duplicated befor submitting
      if (isDuplicateTemplate && !duplicateTemplateDialog.showDialog) {
        return setDuplicateTemplateDialog({ showDialog: true, data });
      }

      const initValues = (record) => {
        delete record[commonInputs.fantom.name];
        return {
          ...record,
          [inputs.serviceDate.name]: null,
          [inputs.icd9.name]: [],
          [inputs.icd9.codeDescription]: [],
          [inputs.icd9.codeType]: [],
          [inputs.referral.name]: [],
          [inputs.referral.codeDescription]: [],
          [inputs.referral.codeType]: [],
          [inputs.comment.name]: '',

          // CMO-2737 - Batch screen using template -> do not persist/backfill times and durations
          [inputs.startTime.name]: '',
          [inputs.endTime.name]: '',
          [inputs.duration.name]: 0
        };
      };

      const records = localState.groupRecords.map((i) => initValues(i));

      const dataForRequest = {
        ...data,
        DCNGuid: watch(inputs.dcnguid.name),
        DoctorGuid: watch(inputs.practitioner.name),
        DateCreated: moment(moment().toDate()).format('YYYY/MM/DD'),
        Records: records,
        FirstStep: initValues(watch())
      };

      delete dataForRequest[commonInputs.fantom.name];

      setLocalState((prevState) => ({ ...prevState, creatingGroupInvoicesTemplate: true }));
      const result = await createGroupInvoicesTemplate(dataForRequest);
      if (result) createTemplateCallback(result.Name);
    }

    if (option === 'edit') {
      setLocalState((prevState) => ({ ...prevState, creatingGroupInvoicesTemplate: true }));
      const result = await editGroupInvoicesTemplate(data);
      if (result) {
        createTemplateCallback(data.Name);
        // Update templates list
        setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: true }));
        const groupTemplate = await getGroupInvoicesTemplates(watch(inputs.practitioner.name));
        setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: false }));
        if (groupTemplate) setLocalState((prevState) => ({ ...prevState, templatesList: groupTemplate }));
      }
    }
  };

  const createTemplateCallback = (name) => {
    setLocalState((prevState) => ({ ...prevState, creatingGroupInvoicesTemplate: false }));
    onHideDialog();
    dispatch(setToastMessage({ type: 'success', message: String.format(t('Template_saved_successfully'), `"${name}"`) }));
  };

  const onHideDialog = () => {
    reset();
    if (option === 'create') return setLocalState((prevState) => ({ ...prevState, createGroupTemplateDialog: false }));
    if (option === 'edit') return setLocalState((prevState) => ({ ...prevState, editGroupTemplateDialog: false }));
  };

  const onHideDuplicateTemplateDialog = () => {
    setDuplicateTemplateDialog(false);
    onHideDialog();
  };

  const validateName = (inputValue) => {
    const isNameAlreadyExist = option === 'create' && localState.templatesList?.some((i) => i.Name === inputValue);
    if (isNameAlreadyExist) {
      return t('This_name_already_exists');
    }
    return true;
  };

  const nameFooterContent = () => <InputCharacterCounter text={name} countLimit={nameCharactersLimit} />;
  const descriptionFooterContent = () => {
    return (
      <div className="flex justify-content-between align-items-center w-full">
        <Button
          type="button"
          className="p-button-text p-0"
          label={t('Clear_all')}
          onClick={() => {
            setValue('Description', '');
            setFocusToElement('description');
          }}
        />
        <InputCharacterCounter className="w-max" text={description} countLimit={descriptionCharactersLimit} />
      </div>
    );
  };

  const duplicateTemplateDialogFooter = (
    <>
      <Button className="" label={t('Yes')} onClick={() => onSubmit(duplicateTemplateDialog.data)} />
      <Button className="p-button-outlined" label={t('No')} onClick={onHideDuplicateTemplateDialog} />
    </>
  );

  const nameError = formState.errors?.Name?.message;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id="createGroupTemplateForm">
        <div className="pb-4">
          <InputWrapper name="name" label={t('Name')} required errorMessage={nameError} footerContent={nameFooterContent}>
            <InputText
              className={cx({ 'p-invalid': nameError })}
              type="text"
              autoFocus
              maxLength={nameCharactersLimit}
              {...register('Name', {
                required: t('Mandatory_field.1'),
                validate: (value) => validateName(value)
              })}
            />
          </InputWrapper>

          <InputWrapper name="description" label={t('Description')} style={{ height: 'inherit' }} footerContent={descriptionFooterContent}>
            <InputTextarea
              id="description"
              type="text"
              rows={10}
              autoResize
              maxLength={descriptionCharactersLimit}
              {...register('Description')}
              onFocus={(e) => e.target.select()}
            />
          </InputWrapper>
        </div>

        <div className="flex justify-content-end py-3 gap-3 stickyActionButtons">
          <Button
            type="submit"
            form="createGroupTemplateForm"
            id={elementIDs.dialogAccept}
            label={option === 'create' ? t('Create') : t('Save')}
            loading={localState.creatingGroupInvoicesTemplate}
          />

          <Button type="button" className="p-button-outlined" id={elementIDs.dialogClose} label={t('Cancel')} onClick={onHideDialog} />
        </div>
      </form>

      <Dialog
        id={elementIDs.duplicateRecordsAlert}
        header={t('Duplicate_records_alert')}
        visible={duplicateTemplateDialog.showDialog}
        breakpoints={{ '1366px': '60vw', '960px': '80vw', '768px': '90vw' }}
        style={{ width: '40vw' }}
        onHide={onHideDuplicateTemplateDialog}
        footer={duplicateTemplateDialogFooter}
      >
        {stringFormat(t('Such_a_group_already_exists'), localState.selectedTemplate.Name)}
      </Dialog>
    </>
  );
};

export default CreateGroupTemplate;
