/**
 * This reducer provides formatted data..
 * PatientList supports two modes: appening page of results (for autisuggestion) or overwriting (for grid)
 */

import actionTypes from '../actions/practitioners.action.types';

export const practitionerInitialState = {
  practitioners: [],
  isFetchingReferral: false,
  isFetchingPractitioner: false
};

export default function practitionersReducer(state = practitionerInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case actionTypes.SET_PRACTITIONERS:
      return {
        ...state,
        practitioners: results
      };

    case actionTypes.IS_FETCHING_PRACTITIONER:
      return {
        ...state,
        isFetchingPractitioner: results
      };

    case actionTypes.IS_FETCHING_REFERRAL:
      return {
        ...state,
        isFetchingReferral: results
      };

    default:
      return state;
  }
}
