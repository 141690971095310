import React, { useEffect } from 'react';

import { Chart } from 'primereact/chart';
import PayeeNumbersFilter from './PayeeNumbersFilter';
import CircularProgress from '../../../../../components/Misc/Loader/CircularProgress/CircularProgress';

import { usePayeeReportsContext } from '../hooks/usePayeeReportsContext';
import { usePaidReport } from '../hooks/usePaidReport';

const basicOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.6,
  plugins: {
    legend: {
      labels: {
        color: '#495057'
      }
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.dataset?.label}: $${tooltipItem.formattedValue}`; // Format tooltip content as currency. Do not use "currencyFormat" function!
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    },
    y: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    }
  }
};

const PaidReport = () => {
  const { reportsState } = usePayeeReportsContext();
  const { getChartData, loading } = usePaidReport();
  const chartData = reportsState?.paidReports?.paidReportsChartData;

  // Get reports
  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <CircularProgress wrapClassName="circular_progress_wrap_absolute" />}
      <PayeeNumbersFilter />
      <Chart type="line" data={chartData} options={basicOptions} />
    </>
  );
};

export default PaidReport;
