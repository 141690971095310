import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Skeleton } from 'primereact/skeleton';
import PolicyCard from '../../../../../../components/Misc/PolicyCard/PolicyCard';

import { isEmpty } from 'lodash';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const PrimaryPolicy = () => {
  const { localState, watch } = useFormContext();
  const primaryPolicy = watch(inputs.primaryPolicy.name);

  // Skeleton
  if (localState.isLoading) {
    return <Skeleton className="policy_skeleton" />;
  }

  return <PolicyCard policy={primaryPolicy} hideActions />;
};

const SecondaryPolicy = () => {
  const { watch } = useFormContext();
  const secondaryPolicy = watch(inputs.secondaryPolicy.name);

  if (!secondaryPolicy) return null;

  return <PolicyCard policy={secondaryPolicy} hideActions />;
};

const Policies = () => {
  const { watch } = useFormContext();
  const secondaryPolicy = watch(inputs.secondaryPolicy.name);

  return (
    <div className="flex flex-wrap justify-content-start gap-3 py-3">
      <div className="eclaim_step_two_left_gap"></div>

      <div className="flex flex-column gap-4">
        <span className="text-start text-500 m-0">{t('Primary_insurance_policy')}</span>

        <div className="flex flex-column justify-content-center align-items-center gap-4">
          <PrimaryPolicy />
        </div>
      </div>

      {!isEmpty(secondaryPolicy) && (
        <div className="flex flex-column gap-4">
          <span className="text-start text-500 m-0">{t('Secondary_insurance_policy')}</span>

          <div className="flex flex-column justify-content-center align-items-center gap-4">
            <SecondaryPolicy />
          </div>
        </div>
      )}
    </div>
  );
};

export default Policies;
