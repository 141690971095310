import React from 'react';
import CommonCard from '../../../common/components/CommonCard';
import CreateTeleplan from './CreateTeleplan';
import CreatePrivateInvoice from './CreatePrivateInvoice';
import CreateWSBCReport from './CreateWSBCReport';
import CreatePatient from './CreatePatient';
import CheckMSPCoverage from './CheckMSPCoverage';

const BillingCard = () => {
  return (
    <CommonCard title="Do some billing">
      <div className="grid">
        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <CreateTeleplan />
            <CreatePrivateInvoice />
          </div>
        </div>

        <div className="col-12 sm:col-6">
          <div className="flex flex-column w-full gap-3">
            <CreatePatient />
            <CreateWSBCReport />
            <CheckMSPCoverage />
          </div>
        </div>
      </div>
    </CommonCard>
  );
};

export default BillingCard;
