import { InputTextarea } from 'primereact/inputtextarea';
import { t } from '../../../../../service/localization/i18n';
import { setNoteValue } from './rowExpansionTemplate';
import { rowHeight } from './columns';
import cx from 'classnames';
import { elementIDs } from '../../../../config/elementIDsConfig';

export const noteEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  return (
    <div
      className={cx('flex mb-3', mobileView ? 'align-items-start' : 'align-items-center')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Note')}</span>
          <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ height: rowHeight }}>
            ―
          </div>
        </div>
      )}

      {records[params.rowIndex]?.id !== '-1' && (
        <>
          <span className="p-column-title">{t('Note')}</span>
          <InputTextarea
            id={elementIDs.noteInput}
            autoFocus
            rows={1}
            cols={30}
            autoResize
            value={records[params.rowIndex]?.note}
            placeholder={t('Add_note')}
            style={{ height: rowHeight, width: '100%' }}
            onChange={(e) => setNoteValue(e, records[params.rowIndex]?.id, records, setRecords, setIsReviewDirty)}
          />
        </>
      )}
    </div>
  );
};
