import { isWeekendOrHoliday } from './formatDate';
import moment from 'moment';

export const serviceDateFormat = (date, order) => {
  const isDateValid = moment(date).isValid();

  if (!isDateValid) return;

  return {
    amount: null,
    label: moment(date).format('MM/DD/YYYY'),
    order: order ? order : 0,
    value: moment(date).format('YYYY-MM-DD'),
    weekday: moment(date).format('dddd'),
    isWeekend: isWeekendOrHoliday(date)
  };
};

export const sortDatesForQuickPick = (dates) => {
  const sorted = dates.sort((a, b) => new Date(b.value) - new Date(a.value)); // Sort dates in descending order
  return sorted;
};
