import { useFormContext } from 'react-hook-form';
import { usePatient } from '../../../hooks/usePatient';
import { useGroupPickerInputs } from './useGroupPickerInputs';
import { inputs } from '../../../helpers/inputs';
import { compact, filter, get, includes, map, some, uniqBy } from 'lodash';
import { localStorageKeys } from '../../../../../../config/localStorageKeysConfig';
import { useStorage } from '../../../../../../hooks/useStorage';

export const useDeleteRecord = () => {
  const key = localStorageKeys.doNotShowDeleteGroupRecordConfirm;
  const [doNotShowToday] = useStorage(key);
  const { setValue, watch, localState, setLocalState } = useFormContext();
  const { onPatientChange } = usePatient();
  const { onTimeChange, removeRowRequirementsForDuplicatedRecords, resetValuesForDupicatedRecord } = useGroupPickerInputs();

  const onDeleteClick = (rowOptions) => {
    if (doNotShowToday) return onDeleteRow(rowOptions);
    setLocalState((prevState) => ({
      ...prevState,
      deleteGroupRecordConfirm: { showDialog: true, rowOptions }
    }));
  };

  const onDeleteRow = (rowOptions) => {
    const records = localState.groupRecords;
    const rowIndex = rowOptions.rowIndex;
    const record = rowOptions.rowData;
    const feeCode = record[inputs.feeCodes.codeType][0]?.value;
    const recordRowId = record[inputs.groupRowId.name];
    const patients = watch(inputs.patient.name);
    const patientGuid = record[inputs.patient.name][0]?.PatientGuid;
    const filteredGroupRecords = filter(records, (i) => i[inputs.groupRowId.name] !== recordRowId);

    const updatedGroupRecords = resetValuesForDupicatedRecord(patientGuid, feeCode, filteredGroupRecords);

    // Check if it's the last row for the patient
    const lastRowForPatient = !some(updatedGroupRecords, (i) => {
      const patient = get(i, [inputs.patient.name, 0, 'PatientGuid']);
      return patient === patientGuid;
    });

    // Get the time and handle patient change
    const time = records?.length > 2 && rowIndex > 0 ? records[rowIndex - 1][inputs.endTime.name] : '';
    const groupRecordsRequiredInputs = removeRowRequirementsForDuplicatedRecords(record);

    if (lastRowForPatient) {
      // Remove patient from the list on first step when it's the last row
      const updatedPatientList = filter(patients, (i) => i.PatientGuid !== patientGuid);
      onPatientChange(updatedPatientList);

      const updatedList = localState.listOfPatients.filter((i) => i.PatientGuid !== patientGuid);
      setLocalState((prevState) => ({ ...prevState, listOfPatients: updatedList, groupRecordsRequiredInputs }));
    }

    if (time) {
      // Handle time change and set state
      onTimeChange(time, inputs.startTime.name, rowOptions, updatedGroupRecords, groupRecordsRequiredInputs);
    } else {
      // Set state without time change
      setLocalState((prevState) => ({ ...prevState, groupRecords: updatedGroupRecords, groupRecordsRequiredInputs }));
    }

    // CMO-2538 - Teleplan group - remove all rows with the same fee code on step 2
    const updatedGroupRecordsFeeCodes = compact(map(updatedGroupRecords, (item) => get(item, [inputs.feeCodes.codeType, 0])));
    const feeCodesOnly = map(updatedGroupRecordsFeeCodes, (i) => i.value);

    if (!includes(feeCodesOnly, feeCode)) {
      // Update fee codes when necessary
      const uniqueFeeCodes = uniqBy(updatedGroupRecordsFeeCodes, 'value');
      const codes = map(uniqueFeeCodes, (i) => i.value);
      const codesDescription = map(uniqueFeeCodes, (i) => i.label);
      setValue(inputs.feeCodes.name, codes || []);
      setValue(inputs.feeCodes.codeDescription, codesDescription || []);
      setValue(inputs.feeCodes.codeType, uniqueFeeCodes);
    }
  };

  return { onDeleteRow, onDeleteClick };
};
