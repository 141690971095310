import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { checkAppliedFilters } from '../utils/checkAppliedFilters';
import { isEmpty } from 'lodash';
import { t } from '../../service/localization/i18n';
import classes from './LegoFilters.module.scss';
import cx from 'classnames';

const ClaimFilters = ({
  children,
  showSidebarFilters,
  setShowSidebarFilters,
  totalRecords,
  filters,
  isMobile,
  isFetching,
  filtersTitle,
  clearFilters
}) => {
  const header = () => <h5 className={cx('mb-0')}>{filtersTitle}</h5>;

  const isFiltersDefault = checkAppliedFilters(filters).length || isEmpty(filters) === 0 ? false : true;

  return (
    <Sidebar
      className={cx('sidebarFilters', isMobile ? classes.mobileSidebar : classes.desktopSidebar)}
      visible={showSidebarFilters}
      position="right"
      blockScroll
      baseZIndex={9999999999}
      icons={header()}
      onHide={() => setShowSidebarFilters(false)}
    >
      {children}
      <div className={isMobile ? classes.footerRootMobile : classes.footerRoot}>
        {isFiltersDefault ? (
          <Button className={cx('col-12 p-0 p-button-text', classes.footerButton)} label={t('Close')} onClick={() => setShowSidebarFilters(false)} />
        ) : (
          <>
            <Button
              className={cx('col-6', classes.footerButton)}
              icon={isFetching ? 'p-autocomplete-loader pi pi-spinner pi-spin' : null}
              iconPos={!isMobile ? 'right' : null}
              label={`Show ${totalRecords} records`}
              onClick={() => setShowSidebarFilters(false)}
            />
            <Button className={cx('col-6 p-button-text', classes.footerButton)} label={t('Reset')} onClick={clearFilters} />
          </>
        )}
      </div>
    </Sidebar>
  );
};

export default ClaimFilters;
