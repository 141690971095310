// Supports foreign languages, like Cyrillic letters
export const decodeText = (txt = '') => {
  if (txt) {
    if (txt.includes(':~~:') || txt.includes(':||:') || txt.includes(':|:')) {
      txt = txt.replace(':||:', '==').replace(':|:', '=').replace(':~~:', '');
      txt = atob(txt); // Using atob() to decode base64
    }
  }
  else { return ''; }
  return txt;
};

// Supports foreign languages, like Cyrillic letters
export const encodeText = (txt) => {
  const encoded = btoa(txt || '');

  if (encoded.includes('=')) {
    return encoded.replace(/==/g, ':||:').replace(/=/g, ':|:');
  } else {
    return `${encoded}:~~:`;
  }
};

export const decodeTextContent = (txt = '') => {
  if (txt) {
    let decodedText = decodeText(txt);

    // Decode HTML entities
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = decodedText;
    decodedText = tempDiv.innerText || tempDiv.textContent;

    return decodedText;
  }
  return txt;
};

export const decodeTextForLayout = (txt = '') => {
  if (txt) {
    if (txt.includes(':~~:') || txt.includes(':||:') || txt.includes(':|:')) {
      txt = txt.replace(':||:', '==').replace(':|:', '=').replace(':~~:', '');
      const decodedText = decodeURIComponent(escape(atob(txt)));
      return <span dangerouslySetInnerHTML={{ __html: decodedText }} />;
    }
  }
  return txt;
};
