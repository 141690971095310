import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { range } from 'lodash';
import cx from 'classnames';

const Alphabet = ({ onClick, style }) => {
  const [selectedLetter, setSelectedLetter] = useState('');

  const alphabetArray = range('A'.charCodeAt(0), 'Z'.charCodeAt(0) + 1).map((charCode) => ({
    value: String.fromCharCode(charCode),
    label: String.fromCharCode(charCode)
  }));

  const handleClick = (letter) => {
    const value = selectedLetter === letter ? '' : letter;
    setSelectedLetter(value);
    onClick && onClick(value);
  };

  return (
    <div>
      {alphabetArray.map((i) => {
        return (
          <Button
            key={i.value}
            className={cx(selectedLetter === i.value ? '' : 'p-button-text', 'p-0')}
            label={i.label}
            style={style || { minWidth: '21px', height: '21px' }}
            onClick={() => handleClick(i.value)}
          />
        );
      })}
    </div>
  );
};

export default Alphabet;
