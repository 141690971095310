import React from 'react';
import { useFormContext } from 'react-hook-form';
import PatientPicker from '../../../../../modules/TeleplanInvoiceForm/Inputs/PatientPicker';
import PatientsCout from '../../../../../modules/Layouts/PatientsCout';
import { steps } from '../../../../../helpers/steps';

const PatientsPicker = () => {
  const { localState } = useFormContext();

  if (localState.step === steps.groupServicesPicker) {
    return (
      <div className="flex align-items-center font-normal" style={{ rowGap: '1rem', columnGap: '1rem' }}>
        <PatientPicker hideLabel hideSelectedValues inputWrapStyles={{ height: '45px', minWidth: '310px', width: '100%' }} />

        <PatientsCout />
      </div>
    );
  }

  return <PatientsCout />;
};

export default PatientsPicker;
