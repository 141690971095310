import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CodePicker } from '../../../../../../../components/Inputs';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs, requiredInputs } from '../../../helpers/inputs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useToggleCatalogs } from '../../../hooks/useToggleCatalogs';

const ICD9Code = () => {
  const name = inputs.icd9.name;
  const { errors, control, isMobile, watch } = useFormContext();
  const { code, onChange, onFocus } = useCodePickerActions(inputs.icd9);
  const { toggleCatalogs } = useToggleCatalogs();
  const speciality = watch(inputs.speciality.name);

  const icd9Input = (field) => {
    return (
      <InputWrapper name={name} label={inputs.icd9.label} required={!!requiredInputs[name]} errorMessage={errors[name]?.message}>
        <CodePicker
          name={name}
          ref={field.ref}
          id={elementIDs.icd9Autocomplete}
          inputId={elementIDs.icd9AutocompleteInput}
          isInvalid={!!errors[name]?.message}
          value={code}
          maxEntry={1}
          showCatalogsButton={isMobile}
          searchParams={{ speciality, codeType: inputs.icd9.codeType, getCodesFor: 'teleplan' }}
          toggleCatalogs={() => toggleCatalogs('mobile', name)}
          onFocus={onFocus}
          onChange={onChange}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => icd9Input(field)} />;
};

export default ICD9Code;
