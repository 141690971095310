import { useCallback, useEffect, useState } from 'react';
import { getAllLogs } from '../../actions/logs.action.creators';
import LogsTable from '../LogsTable/LogsTable';
import { Skeleton } from 'primereact/skeleton';
import { logsDefaultFilters, logsDefaultSortBy } from '../../helpers/defaultValues';

const Logs = () => {
  const [logs, setLogs] = useState();
  const [loading, setLoading] = useState(false);

  const fetchLogs = useCallback(async ({ page, pageSize, query, sortby }) => {
    setLoading(true);
    const result = await getAllLogs({ page, pageSize, query, sortby });
    setLoading(false);
    if (result) setLogs(result);
  }, []);

  useEffect(() => {
    fetchLogs({
      page: 1,
      pageSize: 20,
      query: logsDefaultFilters,
      sortby: `${logsDefaultSortBy.sortField}_${logsDefaultSortBy.sortOrder === 1 ? 'asc' : 'desc'}`
    });
  }, [fetchLogs]);

  return (
    <div className="mt-3">{logs ? <LogsTable logs={logs} loading={loading} fetchData={fetchLogs} /> : <Skeleton width="100%" height="150px" />}</div>
  );
};

export default Logs;
