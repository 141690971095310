import React from 'react';
import { useFormContext } from 'react-hook-form';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { inputs } from '../../helpers/inputs';
import { isEClaimStatusError } from '../../helpers/getEClaimStatus';
import { t } from '../../../../../../service/localization/i18n';

const ResultsMoney = ({ eClaimResult }) => {
  const { getValues } = useFormContext();
  const payee = getValues(inputs.payee.name);
  const replyType = eClaimResult.ReplyType;
  const owes = eClaimResult.FeeBilled - eClaimResult.FeeAccepted;
  const isDifferenPayee = eClaimResult[inputs.payee.name]?.toLowerCase() !== payee?.toLowerCase();
  const isAck = replyType === 'ACK';
  const paidLabel = isAck ? 'N/A' : 'Paid';
  const isError = isEClaimStatusError(replyType);
  const feeAccepted = eClaimResult.FeeAccepted ? currencyFormat(eClaimResult.FeeAccepted) : t('N_A');
  const owesText = eClaimResult.FeeAccepted ? currencyFormat(owes) : t('N_A');

  return (
    <div className="flex justify-content-center w-full">
      <div className="flex flex-column gap-3 w-full max-w-20rem">
        {!isError && (
          <div className="flex justify-content-between">
            <span>{`Pay To: `}</span>
            <span className={isDifferenPayee ? 'p-error' : ''}>{eClaimResult.PayeeName || t('N_A')}</span>
          </div>
        )}

        <div className="flex justify-content-between">
          <span>{`Billed: `}</span>
          <span>{`${currencyFormat(eClaimResult.FeeBilled)}`}</span>
        </div>

        <div className="flex justify-content-between">
          <span>{`${paidLabel}: `}</span>
          <span>{`${feeAccepted}`}</span>
        </div>

        <div className="flex justify-content-between font-bold">
          <span>{`Patient owes: `}</span>
          <span>{`${owesText}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ResultsMoney;
