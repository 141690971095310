import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import classes from './MobileRowExpansion.module.scss';
import cx from 'classnames';

const MobileRowExpansionSkeleton = () => {
  return (
    <div className={classes.mobileRowExpantionRoot}>
      <div className={cx('flex col-12 p-0', classes.mobileRowExpansionSkeletonRoot)}>
        <div className="flex col-10 p-0">
          <div className={cx('flex align-items-center px-0', classes.leftItemBox)}>
            <Skeleton shape="circle" size="3rem" className="mr-2" />
          </div>

          <div className={classes.headerDetailsContainer}>
            <div className="flex justify-content-center flex-column pr-0">
              <Skeleton width="70%" className="mb-2" />
              <Skeleton width="60%" className="mb-2" />
              <Skeleton width="60%" className="mb-2" />
              <Skeleton width="50%" className="mb-2" />
            </div>
          </div>
        </div>

        <div className="flex justify-content-end align-items-center col-2 p-0">
          <div className={classes.mobileRowExpansionSkeletonDotsButtons}>
            <Skeleton shape="circle" size="0.3rem" className="mb-1" />
            <Skeleton shape="circle" size="0.3rem" className="mb-1" />
            <Skeleton shape="circle" size="0.3rem" className="mb-1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileRowExpansionSkeleton;
