export const convertReferralValue = (value, reverse) => {
  if (value?.length === 1) {
    if (value === 'N') return 'N/A';
    if (value === 'B') return 'By';
    if (value === 'T') return 'To';
  }

  if (reverse) {
    if (value === 'N/A') return 'N';
    if (value === 'By') return 'B';
    if (value === 'To') return 'T';
  }

  return value;
};
