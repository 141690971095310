import React from 'react';

import ResultsMoney from './ResultsMoney';
import ResultsHeader from './ResultsHeader';
import ResultsPreviewActionButtons from '../ActionButtons/ResultsPreviewActionButtons';
import Link from '../../../../../../components/Buttons/Link/Link';

import { getEOBLink } from '../../../../../utils/getLink';

const ErrorResult = ({ eClaimResult }) => {
  const url = getEOBLink(eClaimResult);

  return (
    <div className="flex flex-column gap-7">
      {/* ResultsHeader */}
      <ResultsHeader eClaimResult={eClaimResult} />

      {/* Body */}
      <div className="flex flex-column gap-4">
        <div>
          <span>{`You received `}</span>
          <span className="font-bold">{`Error`}</span>
        </div>

        {eClaimResult.ReasonText && <div className="p-error pb-3">{`${eClaimResult.ReasonText}`}</div>}

        <ResultsMoney eClaimResult={eClaimResult} />
      </div>

      {/* Buttons */}
      <ResultsPreviewActionButtons eClaimResult={eClaimResult} />

      {/* Link */}
      <div>
        <span>{`Please click `}</span>
        <Link href={url}>{`here`}</Link>
        <span>{` to see the error details.`}</span>
      </div>
    </div>
  );
};

export default ErrorResult;
