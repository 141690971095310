import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Steps from './modules/Steps/Steps';
import Dialogs from './modules/Dialogs/Dialogs';
import TeleplanLoader from './modules/Layouts/TeleplanLoader';

import { useDefaultValues } from './hooks/useDefaultValues';
import { inputs } from './helpers/inputs';
import { useFormErrorAutoscroll } from '../../../hooks/useFormErrorAutoscroll';
import { setToastPosition } from '../../../core/actions/core.action.creators';
import { updateTeleplanInvoice } from '../../actions/claims.action.creators';

import './TeleplanInvoice.scss';

const TeleplanInvoice = () => {
  const dispatch = useDispatch();
  const initValues = useDefaultValues();
  const { teleplanInvoice } = useSelector((state) => state.claims);

  // Autoscroll and set focus to invalid input
  useFormErrorAutoscroll({ errors: initValues.formState.errors, inputs, setFocus: initValues.setFocus });

  // Unmount component
  useEffect(() => {
    return () => {
      // CMO-2317 - Cach is not cleaned on new Teleplan when the new patient is created from that screen
      if (teleplanInvoice?.formState?.resetTeleplanInvoice) {
        dispatch(updateTeleplanInvoice({}));
        // Reset toast messages position
        (initValues.pos || initValues.isGroup) && dispatch(setToastPosition(''));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...initValues}>
      <TeleplanLoader />
      <Steps />
      <Dialogs />
    </FormProvider>
  );
};

export default TeleplanInvoice;
