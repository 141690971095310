import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { t } from '../../../service/localization/i18n';
import { elementIDs } from '../../config/elementIDsConfig';

const DeleteConfirmation = ({
  id = elementIDs.deleteConfirmation,
  style,
  onHide,
  header,
  accept,
  reject,
  visible,
  message,
  baseZIndex,
  rejectLabel,
  acceptLabel
}) => {
  return (
    <ConfirmDialog
      id={id}
      visible={visible}
      onHide={onHide}
      message={message}
      header={header}
      icon="pi pi-exclamation-triangle"
      acceptClassName="p-button-danger"
      acceptLabel={acceptLabel ? acceptLabel : t('Yes')}
      rejectLabel={rejectLabel ? rejectLabel : t('No')}
      breakpoints={{ '1366px': '45vw', '960px': '70vw', '768px': '90vw' }}
      style={style ? style : { width: '25vw' }}
      accept={accept}
      reject={reject}
      baseZIndex={baseZIndex}
    />
  );
};

export default DeleteConfirmation;
