import actionTypes from '../actions/dashboard.actio.types';

export const dashboardInitialState = {
  dashboardActiveTabIndex: 0,
  searchBy: 'patient'
};

export default function dashboardReducer(state = dashboardInitialState, action) {
  const { results } = action;

  switch (action.type) {
    case actionTypes.SET_DASHBOARD_ACTIVE_TAB_INDEX:
      return {
        ...state,
        dashboardActiveTabIndex: results
      };

    case actionTypes.SET_SEARCH_BY:
      return {
        ...state,
        searchBy: results
      };

    default:
      return state;
  }
}
