import React from 'react';
import Chips from './Chips';
import { catalogSelectedCodesChipsWrap } from '../../config/styles';

const ChipsWithHorizontalScroll = () => {
  return (
    <div className="flex surface-overlay w-full overflow-auto" style={{ height: `${catalogSelectedCodesChipsWrap}px` }}>
      <div className="white-space-nowrap pt-1">
        <span style={{ minWidth: '265px' }}>
          <Chips />
        </span>
      </div>
    </div>
  );
};

export default ChipsWithHorizontalScroll;
