export const onThemeChange = (theme, colorMode) => {
  changeTheme(theme, colorMode);
  changeLayout(theme);
};

const changeTheme = (theme, colorMode) => {
  const themeLink = document.getElementById('theme-css');
  const themeHref = 'assets/theme/' + theme + '/theme-' + colorMode + '.css';
  replaceLink(themeLink, themeHref);
};

const changeLayout = (event) => {
  const layoutLink = document.getElementById('layout-css');
  const layoutHref = 'assets/layout/css/layout-' + event + '.css';
  replaceLink(layoutLink, layoutHref);
};

const isIE = () => {
  return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
};

const replaceLink = (linkElement, href) => {
  if (isIE()) {
    linkElement.setAttribute('href', href);
  } else {
    const id = linkElement.getAttribute('id');
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }
};
