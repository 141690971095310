import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider } from 'primereact/divider';
import CardTitle from '../Layouts/CardTitle';
import HeaderInfoSection from './Sections/HeaderInfoSection';
import ClaimInfoSection from './Sections/ClaimInfoSection';
import GeneralSection from './Sections/GeneralSection';
import PriorSection from './Sections/PriorSection';
import InjurySection from './Sections/InjurySection';
import ClinicalSection from './Sections/ClinicalSection';
import ReturnToWorkSection from './Sections/ReturnToWorkSection';
import ServiceDetailsSection from './Sections/ServiceDetailsSection';
import SaveAsDraft from './Inputs/SaveAsDraft';

const WSBCReportForm = () => {
  const { handleSubmit, onSubmit } = useFormContext();

  return (
    <form id="wsbcReportForm" className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <CardTitle />

        <div className="text-base pt-4">
          <HeaderInfoSection />
          <Divider />
        </div>
      </div>

      <ClaimInfoSection />
      <Divider />

      <GeneralSection />
      <Divider />

      <PriorSection />
      <Divider />

      <InjurySection />
      <Divider />

      <ClinicalSection />
      <Divider />

      <ReturnToWorkSection />
      <Divider />

      <ServiceDetailsSection />

      <SaveAsDraft />
    </form>
  );
};

export default WSBCReportForm;
