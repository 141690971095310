import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import AddNewPayorForm from '../../../common/components/AddNewPayorForm/AddNewPayorForm';
import { inputs } from './claimDetailsHelpers/inputs';
import { t } from '../../../../service/localization/i18n';

const BillTo = ({ value }) => {
  const [showAddNewPayorDizlog, setShowAddNewPayorDizlog] = useState(false);

  const onSaveNewPayor = (data) => {
    console.log('!!!!data', data);
  };

  const formatSelectedCodeItem = (code) => `${code?.LastName}, ${code?.FirstName}`;

  return (
    <>
      <InputText
        className="w-full"
        name={inputs().billTo.name}
        disabled
        value={
          Array.isArray(value) && value?.length
            ? formatSelectedCodeItem(value[0])
            : Array.isArray(value) && !value?.length
              ? ''
              : formatSelectedCodeItem(value)
        }
      />

      <AddNewPayorForm
        header={t('Add_new_payor')}
        visible={showAddNewPayorDizlog}
        showInDialog={true}
        formikInitValues={{}}
        onSave={onSaveNewPayor}
        onClose={() => setShowAddNewPayorDizlog(false)}
      />
    </>
  );
};

export default BillTo;
