// import React from 'react';
// import { Controller, useFormContext } from 'react-hook-form';
// import { WrappedDatePicker } from '../../../../../../../components/Inputs';
// import { elementIDs } from '../../../../../../config/elementIDsConfig';
// import { inputs } from '../../../helpers/inputs';

// const BirthDate = () => {
//   const name = inputs.memberDOB.name;
//   const { errors, control } = useFormContext();
//   const errorMessage = errors[name]?.message;

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field }) => (
//         <WrappedDatePicker
//           {...field}
//           required
//           id={elementIDs.birthDate}
//           input={inputs.memberDOB}
//           errorMessage={errorMessage}
//         />
//       )}
//     />
//   )
// }

// export default BirthDate

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DateOfBirthWithFormControl } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const BirthDate = () => {
  const name = inputs.memberDOB.name;
  const label = inputs.memberDOB.label;
  const { control } = useFormContext();

  return <DateOfBirthWithFormControl name={name} label={label} required control={control} />;
};

export default BirthDate;

// import React from 'react';
// import { Controller, useFormContext } from 'react-hook-form';
// import { WrappedDateOfBirth } from '../../../../../../../components/Inputs';
// import { inputs } from '../../../helpers/inputs';

// const BirthDate = () => {
//   const name = inputs.memberDOB.name;
//   const label = inputs.memberDOB.label;
//   const { errors, control } = useFormContext();
//   const errorMessage = errors[name]?.message;

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field }) => (
//         <WrappedDateOfBirth
//           {...field}
//           label={label}
//           required
//           errorMessage={errorMessage}
//         />
//       )}
//     />
//   )
// }

// export default BirthDate
