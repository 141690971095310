import { anatomicList, payeeList, submissionCodes } from '../../../helpers/mapper';
import { t } from '../../../../../service/localization/i18n';
import { rural_codes_only } from '../../../../common/contants';

export const findAnatomicCode = (code) => anatomicList.find((i) => i.label === code);
export const findPayorCode = (code) => payeeList.find((i) => i.short === code);

export const submissionCodesDropdownOptions = submissionCodes;

export const anatomicCodesDropdownOptions = [
  { label: findAnatomicCode('N').name, value: findAnatomicCode('N').label },
  { label: findAnatomicCode('L').name, value: findAnatomicCode('L').label },
  { label: findAnatomicCode('R').name, value: findAnatomicCode('R').label },
  { label: findAnatomicCode('B').name, value: findAnatomicCode('B').label }
];

export const payorDropdownOptions = [
  { label: findPayorCode('M').name, value: findPayorCode('M').label },
  { label: findPayorCode('W').name, value: findPayorCode('W').label },
  { label: findPayorCode('I').name, value: findPayorCode('I').label }
];

export const locationCodesDropdownOptions = [
  { value: 'C', label: t('Residential_Care') },
  { value: 'D', label: t('Diagnostic_Facility') },
  { value: 'E', label: t('Hospital_Emergency_Room') },
  { value: 'I', label: t('Hospital_Inpatient') },
  { value: 'P', label: t('Hospital_Outpatient') },
  { value: 'R', label: t('Patient_Private_Home') },
  { value: 'Z', label: t('Other_Z') },
  { value: 'M', label: t('Mental_Health_Center') },
  { value: 'T', label: t('Practitioner_Office_Publicly_Administered') },
  { value: 'G', label: t('Hospital_Day_Care') },
  { value: 'B', label: t('Community_Health_Center') },
  { value: 'J', label: t('FN_Primary_Clinic') },
  { value: 'K', label: t('Hybrid_Primary_Care') },
  { value: 'L', label: t('Longitudinal_Primary_Care') },
  { value: 'N', label: t('Practitioner_Office_NonPhysician') },
  { value: 'Q', label: t('Specialist_Physician_Office') },
  { value: 'U', label: t('Urgent_and_Primary_Care _Centre') },
  { value: 'V', label: t('Virtual_Care_Clinic') },
  { value: 'W', label: t('Walk_In_Clinic') },
  { value: 'F', label: t('Private_Medical_Surgical_Facility') }
].sort((a, b) => a.label.localeCompare(b.label));

export const ruralCodesDropdownOptions = [{ value: 'N/A', label: 'N/A' }, ...rural_codes_only];
