import TextAreaInput from './Common/TextAreaInput';
import { inputs } from '../../../helpers/inputs';

export const clinicalInformationMaxLength = 800;

const ClinicalInformation = () => {
  return <TextAreaInput input={inputs.clinicalInformation} maxLength={clinicalInformationMaxLength} />;
};

export default ClinicalInformation;
