import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormHeaderLayout from '../Layouts/FormHeaderLayout';
import InputsLayout from '../Layouts/InputsLayout';

const EClaimPolicyForm = () => {
  const { handleSubmit, onSubmit } = useFormContext();

  return (
    <form id="eClaimPolicyForm" className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <FormHeaderLayout />
      <InputsLayout />
    </form>
  );
};

export default EClaimPolicyForm;
