import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import Context from '../../../../Context';
import ClaimInputField from './ClaimInputField';
import { NA_DoctorGuid, multipleDoctorGuid } from '../../../config/defaultValuesConfig';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { addNewItemToArray } from '../../../utils/addNewItemToArray';
import { initDoc } from './claimDetailsHelpers/initDoc';
import { onPayToUpdate } from './claimDetailsHelpers/onClaimUpdate';
import { clinicType, practitionerType } from './claimDetailsHelpers/payorTypes';
import { inputs } from './claimDetailsHelpers/inputs';
import { getDefaultPrefs, getPresetPrefs } from '../../helpers/getDefaultPrefs';
import { prefsCodes } from '../../../config/prefsCodesConfig';
import { unionBy } from 'lodash';
import { elementIDs } from '../../../config/elementIDsConfig';

const PayTo = () => {
  const dispatch = useDispatch();
  const { currentPractitionerPrefs } = useSelector((state) => state.preferences);
  const { users, clinic, privateRecord, payToList } = useContext(Context);
  const [payToValue, setPayToValue] = useState();
  const [payToOptionsList, setPayToOptionsList] = useState();

  // set initial PayTo value
  useEffect(() => {
    if (privateRecord?.PayTo?.DoctorGuid) {
      setPayToValue({
        ...initDoc(privateRecord?.PayTo),
        DoctorGuid: privateRecord?.PayTo?.DoctorGuid,
        FullName: privateRecord?.PayTo?.FullName || `${privateRecord?.PayTo?.LastName}, ${privateRecord?.PayTo?.FirstName}`,
        Type: practitionerType
      });
    } else {
      setPayToValue({
        ...initDoc(privateRecord?.PayTo),
        DoctorGuid: clinic?.DCNGuid,
        FullName: clinic?.ClinicName,
        Type: clinicType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.PayTo?.DoctorGuid]);

  // set initial suggestions list
  useEffect(() => {
    let defaultItems = [{ ...initDoc(clinic), DoctorGuid: clinic?.DCNGuid, FullName: clinic?.ClinicName, Type: 'clinic' }];
    const findCurrentDoc = users?.find((i) => i.DoctorGuid === privateRecord.DoctorGuid);
    const currentPractitoner = {
      ...initDoc(findCurrentDoc),
      DoctorGuid: findCurrentDoc?.DoctorGuid,
      FullName: findCurrentDoc?.FullName,
      Type: practitionerType
    };

    if (privateRecord?.DoctorGuid === NA_DoctorGuid || privateRecord?.DoctorGuid === multipleDoctorGuid) {
      defaultItems = defaultItems?.filter((i) => i.DoctorGuid !== currentPractitoner?.DoctorGuid);
    } else {
      defaultItems = addNewItemToArray(defaultItems, 0, currentPractitoner);
    }

    setPayToOptionsList(defaultItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.DoctorGuid]);

  const onChange = (e) => {
    const currentPractitoner = users?.find((i) => i.DoctorGuid === e.value?.DoctorGuid);
    const _value = e.value?.Type === clinicType ? clinic : currentPractitoner;

    const practitionerPrefsLockValue =
      getDefaultPrefs(currentPractitionerPrefs?.content, prefsCodes.defaultPayTo) &&
      getDefaultPrefs(currentPractitionerPrefs?.content, prefsCodes.defaultPayTo)?.toUpperCase() === e.value?.DoctorGuid?.toUpperCase()
        ? '1'
        : '0';

    const practitionerPrefs = [
      { value: practitionerPrefsLockValue, label: prefsCodes.presetPayTo },
      { value: e.value?.DoctorGuid, label: prefsCodes.defaultPayTo }
    ];
    const updatedPrefs = unionBy(practitionerPrefs, privateRecord?.CurrentUserPrefs?.content, 'label');

    const updatedData = {
      [inputs().payTo.name]: _value,
      CurrentUserPrefs: { ...privateRecord?.CurrentUserPrefs, content: updatedPrefs }
    };

    onPayToUpdate(updatedData, dispatch);
  };

  return (
    <ClaimInputField
      // [KS] CMO-1167 - Create/Edit claim - add "lock" button for inputs, which have preferences values
      presetCode={prefsCodes.presetPayTo}
      inputValue={privateRecord?.DoctorGuid}
      updatedPresetPrefs={[
        { value: '1', label: prefsCodes.presetPayTo },
        { value: payToValue?.DoctorGuid, label: prefsCodes.defaultPayTo }
      ]}
      isLocked={getPresetPrefs(privateRecord?.CurrentUserPrefs?.content, prefsCodes.presetPayTo)}
      showPreferencesButton={true}
      //=======================//
      label={inputs().payTo.label}
      required={false}
    >
      <Dropdown
        id={elementIDs.payToDropdown}
        className="flex"
        optionLabel="FullName"
        value={payToValue}
        options={payToList?.length ? payToList : payToOptionsList}
        scrollHeight={dropdownScrollHeight()}
        onChange={(e) => onChange(e)}
      />
    </ClaimInputField>
  );
};

export default PayTo;
