import { useCatalogsContext } from './useCatalogsContext';

export const useActions = () => {
  const { catalogsState, setCatalogsState, toggleCatalogs } = useCatalogsContext();

  const onDataScrollerBackButtonClick = () => {
    setCatalogsState((prevState) => ({
      ...prevState,
      trail: [],
      mobileContent: 'menu',
      categoryValue: catalogsState.activeTab?.searchAllType, //set category value to "..._all" for using global search
      tableData: { codeList: [] } //reset data for dataScroller
    }));
  };

  const onCloseMobileCatalogs = () => {
    toggleCatalogs('mobile');
    onDataScrollerBackButtonClick();
    catalogsState?.showSearch && setCatalogsState((prevState) => ({ ...prevState, showSearch: false }));
  };

  return { onDataScrollerBackButtonClick, onCloseMobileCatalogs };
};
