import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import Catalogs from '../../../../../../components/Catalogs/Catalogs';

import { inputs } from '../../helpers/inputs';
import { useCatalogs } from '../../hooks/useCatalogs';
import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';
import { useGroupCatalogs } from '../../views/TeleplanGroup/hooks/useGroupCatalogs';
import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { t } from '../../../../../../service/localization/i18n';

const BatchCatalogsDialog = () => {
  const { localState, setLocalState } = useFormContext();
  const { catalogsTab, onCodeSelect, onChipRemove, getSelectedCodes } = useGroupCatalogs();
  const { catalogsTableValues, onLetterClick } = useCatalogs();
  const batchCatalogs = localState.batchCatalogsDialog;
  const rowOptions = batchCatalogs.rowOptions;
  const rowData = rowOptions.rowData;
  const name = rowOptions.input?.name;
  const feeCode = rowData?.[inputs.feeCodes.codeType]?.[0];
  const patient = rowData?.[inputs.patient.name]?.[0];
  const patientName = patientFullNameWithAge(patient);
  const isFeeCatalogs = catalogsTab?.name === inputs.feeCodes.name;

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      batchCatalogsDialog: localStateInitValues.batchCatalogsDialog
    }));
  };

  if (!batchCatalogs.showDialog) return null;

  const chipsLayout = (
    <>
      <Chip className="mr-2 w-max" label={patientName} removable={false} icon="pi pi-user" />
      {!isFeeCatalogs && <Chip className="mr-2 w-max" label={`Fee item: ${feeCode?.value}`} removable={false} />}

      {isFeeCatalogs && (
        <>
          <Tooltip target={`.fee_codes_column_tooltip`} position="top">
            <ul className="flex flex-column gap-3 max-w-20rem list-disc">
              <li>{t('Select_all_the_codes_tha_you_want_to_submit_for_this_patient')}</li>
              <li>{t('Choose_the_codes_in_the_order_the_services_were_provided')}</li>
            </ul>
          </Tooltip>
          <i className="pi pi-question-circle pointer fee_codes_column_tooltip" />
        </>
      )}
    </>
  );

  return (
    <Dialog
      className="batch_catalogs_dialog"
      showHeader={false}
      visible={batchCatalogs.showDialog}
      style={{ width: '50vw', height: '100%' }}
      breakpoints={{ '1600px': '60vw', '1366px': '70vw', '1024px': '95vw' }}
      baseZIndex={1000000}
      dismissableMask
      onHide={onHide}
    >
      <Catalogs
        id={`${name}_batchCatalogs`}
        activeTabIndex={0}
        catalogTabs={[catalogsTab]}
        showCatalogs={localState.showCatalogs}
        // hideTabs={name !== inputs.referral.name}
        activeTab={name !== inputs.referral.name ? catalogsTab : undefined}
        patientChipsLayout={chipsLayout}
        catalogsTableValues={catalogsTableValues}
        // Handlers
        toggleCatalogs={onHide}
        getSelectedCodes={getSelectedCodes}
        onLetterClick={onLetterClick}
        onCodeChipClick={onChipRemove}
        onSelectCodeFromCatalogsTable={onCodeSelect}
      />
    </Dialog>
  );
};

export default BatchCatalogsDialog;
