export const reformatMembers = (list, spec) => {
  return list.map((x) => {
    const PayeeNumbers = Array.isArray(x.PayeeNumbers) ? x.PayeeNumbers : x.PayeeNumbers.split(',');
    return {
      ...x,
      PayeeNumbers,
      FullName: `${x.LastName}, ${x.FirstName}`,
      Role: x.Role || x.PractitionerNumber === '0' ? 'rcp' : 'doc',
      FullAddress: `${x.AddressLine} ${x.City} ${x.Province} ${x.Zip}`,
      Speciality: spec ? parseInt(spec, 10) : x.Speciality,
      Phone: x.Phone || 'N/A',
      Email: x.Email || 'N/A'
    };
  });
};

export const addFullAddress = (data) => {
  return {
    ...data,
    FullAddress: `${data?.AddressLine} ${data?.City} ${data?.Province} ${data?.Zip}`
  };
};
