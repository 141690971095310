import React, { useState } from 'react';
import { initialState } from '../config/initialState';
import { PayeeReportsContext } from '../../../../contexts/contexts';

const ReportsProvider = (props) => {
  const [reportsState, setReportsState] = useState(initialState);

  return <PayeeReportsContext.Provider value={{ reportsState, setReportsState }}>{props.children}</PayeeReportsContext.Provider>;
};

export default ReportsProvider;
