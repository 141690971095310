import React from 'react';
import CardTitle from './CardTitle';
import { Divider } from 'primereact/divider';

const FormHeaderLayout = () => {
  return (
    <div className="teleplan_form_header_info_block">
      <div className="flex align-items-center justify-content-center w-full">
        <CardTitle />
      </div>

      <Divider />
    </div>
  );
};

export default FormHeaderLayout;
