import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { editPatientProfile } from '../../../patients/components/Patients/helpers/rowActionsMenuItems';
import { getPatientInfo } from '../../../patients/actions/patients.action.creators';
import { icons } from '../../../config/stylesConfig';
import { t } from '../../../../service/localization/i18n';
import { elementIDs } from '../../../config/elementIDsConfig';
import cx from 'classnames';

const BatchRecordsWarnDialogForOnePatient = ({
  data,
  visible,
  onHide,
  showOnPatientDetailsScreen = false,
  showOnCreateNewClaimScreen = false,
  showOnInvoicesPage = false,
  replaceHistoryOnEditPatient = false
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const errorMessage = data?.Issues?.length
    ? data?.Issues.map((i) => i.Message)
        .join()
        .replaceAll(',', ', ')
    : '';
  const fullName = data?.Issues?.length ? data?.Issues[0]?.FullName : '';
  const patientGuid = data?.Issues?.length ? data?.Issues[0]?.PatientGuid : '';

  const [patient, setPatient] = useState({});

  useEffect(() => {
    patientGuid &&
      dispatch(
        getPatientInfo({
          id: patientGuid,
          callback: (response) => setPatient(response)
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientGuid]);

  const footer = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} tooltipOptions={{ position: 'top' }} onClick={onHide} />;
  };

  const commonWarnMessageContent = (warnMessage, warnTitle) => {
    const title = warnTitle ? warnTitle : t('Missing_or_invalid_data');
    return (
      <div className="flex justify-content-start align-items-center w-full">
        <i className={cx('mr-2', icons.warn)} />
        <span id="batchRecordsWarnDialogForOnePatient_warnMessage">
          {`${title}:`}
          <span className="font-bold ml-1">{warnMessage}</span>
        </span>
      </div>
    );
  };

  const handleEditPatient = () => {
    editPatientProfile(patient, history, replaceHistoryOnEditPatient);
  };

  const commonWarnMessage = <Message className="mb-3 w-full" severity="warn" content={() => commonWarnMessageContent(errorMessage)} />;

  return (
    <Dialog
      id={elementIDs.BatchRecordsWarnDialogForOnePatient}
      header={t('Warning')}
      visible={visible}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '30vw' }}
      modal
      // blockScroll
      footer={footer()}
      baseZIndex={100000}
      onHide={onHide}
    >
      <>
        {/* Message when showing dialog on the current patient profile page */}
        {showOnPatientDetailsScreen && (
          <>
            {commonWarnMessage}
            {t('Please_correct_the_missing_data_and_try_to_submit_the_claim_again')}
          </>
        )}

        {/* Message when showing dialog on the invoices and view pages */}
        {showOnInvoicesPage && !showOnPatientDetailsScreen && (
          <>
            {commonWarnMessage}
            <div id="batchRecordsWarnDialogForOnePatient_bodyText">
              <span className="mr-2">{`Open profile of`}</span>
              <Button id={elementIDs.nameButton} className="p-button-link p-0" label={fullName} onClick={() => handleEditPatient()} />
              <span className="ml-2">{`and correct the data before submitting the claim(s).`}</span>
            </div>
          </>
        )}

        {/* Message for create new claim -> step 3 */}
        {showOnCreateNewClaimScreen && (
          <>
            <Message
              className="mb-3 w-full"
              severity="warn"
              content={() => commonWarnMessageContent(`${errorMessage} in ${fullName}`, t('Invalid'))}
            />
            <div id="batchRecordsWarnDialogForOnePatient_bodyText">{t('Your_claims_are_not_ready_for_submission_yet')}</div>
          </>
        )}
      </>
    </Dialog>
  );
};

export default BatchRecordsWarnDialogForOnePatient;
