import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { CodePicker } from '../../../../components/Inputs';
import Catalogs from '../../../../components/Catalogs/Catalogs';
import CommonInputField from '../../../common/components/CommonInputField';
import CircularProgress from '../../../../components/Misc/Loader/CircularProgress/CircularProgress';
import ReferralCatalogsTable from '../../../../components/Widgets/ReferralCatalogsTable/ReferralCatalogsTable';

import { commonInputs } from '../../../config/commonInputsConfig';
import { patientFullNameWithAge } from '../../../utils/patientFullName';
import { deleteReferral } from '../../../practitioners/actions/practitioners.action.creators';
import { getRecentCodesList, getReferralCodesByLetter } from '../../../claims/actions/claims.action.creators';
import { t } from '../../../../service/localization/i18n';
import AddReferral from '../../../practitioners/components/AddReferral/AddReferral';

const ReferralInput = ({ formik, inputFieldClassName }) => {
  const user = useSelector((state) => state.user.details);
  const [showRferralCatalogsDialog, setrShowRferralCatalogsDialog] = useState(false);
  const [showNewPractitionerDialog, setShowNewPractitionerDialog] = useState(false);
  const [defaultReferralList, setDefaultReferralList] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = window.innerWidth < 992;

  const fetchReferralsList = async () => {
    const results = await getRecentCodesList();
    if (results?.referral?.length) {
      setDefaultReferralList(results.referral);
    }
  };

  useEffect(() => {
    fetchReferralsList();
  }, []);

  const toggleCatalogs = () => setrShowRferralCatalogsDialog((prevState) => !prevState);

  const onLetterClick = async ({ letter, setCatalogsState }) => {
    if (letter) {
      setLoading(true);
      const results = await getReferralCodesByLetter({ letter });
      setLoading(false);
      if (results) {
        setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList: results },
          emptyMessage: `${t('No_records_found')}`
        }));
      }
    } else {
      setCatalogsState((prevState) => ({
        ...prevState,
        tableData: { codeList: defaultReferralList },
        emptyMessage: `${t('No_records_found')}`
      }));
    }
  };

  const catalogsTableValues = (catalogsState) => {
    return catalogsState.tableData?.codeList;
  };

  const onDeleteReferral = async (deletingData) => {
    const result = await deleteReferral(deletingData.value);
    if (result) {
      const currentReferralNumber = formik.values.MRP;
      if (currentReferralNumber.some((i) => i.value === deletingData.value)) {
        onChange([]);
      }
    }
  };

  const getSelectedCodes = () => {
    return formik.values.MRP;
  };

  const catalogTabs = [
    {
      name: commonInputs.referral.name,
      label: commonInputs.referral.tabLabel,
      codeType: commonInputs.referral.codeType,
      categoryType: commonInputs.referral.codeType,
      codeDescription: commonInputs.referral.codeDescription,
      maxEntries: commonInputs.referral.maxEntries,
      searchAllType: 'referral_all',
      requestParams: { codeType: commonInputs.referral.codeType, speciality: user.Speciality, getCodesFor: 'teleplan' },
      defaultTableData: defaultReferralList,
      showAlphabet: true,
      showSearchPanelForCustomTabLayout: true,
      tabLayout: (e) => <ReferralCatalogsTable {...e} onRowClick={(code) => onChange([code])} onDelete={onDeleteReferral} />,
      searchActionButton: {
        tooltip: t('Add_new_practitioner'),
        onClick: () => setShowNewPractitionerDialog(true)
      }
    }
  ];

  const patientChipsLayout =
    formik.values.FirstName && formik.values.LastName ? (
      <Chip className="w-max" label={patientFullNameWithAge(formik.values)} icon="pi pi-user" />
    ) : (
      <></>
    );

  const onChange = (code) => {
    formik.setFieldValue('MRP', code);
  };

  return (
    <>
      {loading && <CircularProgress />}
      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label="MRP"
        description={formik.values.MRP?.[0]?.name}
      >
        <CodePicker
          name="MRP"
          value={formik.values.MRP}
          isInvalid={!!formik.errors.MRP}
          placeholder={t('Search_for_codes')}
          maxEntry={1}
          showCatalogsButton
          groupLabel={false}
          catalogsButtonTooltip={t('Find_practitioner_in_the_catalog')}
          searchParams={{ speciality: user.Speciality, codeType: commonInputs.referral.codeType, getCodesFor: 'teleplan' }}
          toggleCatalogs={toggleCatalogs}
          onChange={(e) => onChange(e.target.value)}
        />
      </CommonInputField>

      <Dialog
        className="batch_catalogs_dialog"
        showHeader={false}
        visible={showRferralCatalogsDialog}
        style={{ width: '50vw', height: '100%' }}
        breakpoints={{ '1600px': '60vw', '1366px': '70vw', '1024px': '95vw' }}
        baseZIndex={1000000}
        dismissableMask
        onHide={toggleCatalogs}
      >
        <Catalogs
          id={`new_patient_referral_catalogs`}
          activeTabIndex={0}
          catalogTabs={catalogTabs}
          showCatalogs={{ desktop: showRferralCatalogsDialog && !isMobile, mobile: showRferralCatalogsDialog && isMobile }}
          patientChipsLayout={patientChipsLayout}
          catalogsTableValues={catalogsTableValues}
          // Handlers
          toggleCatalogs={toggleCatalogs}
          getSelectedCodes={getSelectedCodes}
          onLetterClick={onLetterClick}
          onCodeChipClick={() => onChange([])}
        />
      </Dialog>

      <Dialog
        header={t('Add_new_referral_practitioner')}
        contentClassName="pb-0"
        visible={showNewPractitionerDialog}
        style={{ width: '25vw' }}
        breakpoints={{ '1366px': '35vw', '960px': '55vw', '768px': '95vw' }}
        baseZIndex={1000000}
        onHide={() => setShowNewPractitionerDialog(false)}
      >
        <AddReferral
          onCancel={() => setShowNewPractitionerDialog(false)}
          onCreateResponseAction={(code) => {
            onChange([code]);
            setDefaultReferralList((prevState) => [code, ...prevState]);
            setShowNewPractitionerDialog(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default ReferralInput;
