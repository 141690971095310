import { store } from '../../../../store';
import { getApiBase } from '../../../../config';

export const printTypes = (recordId) => {
  const DCNGuid = store.getState().user.details.DCNGuid;

  return {
    privateInvoice: {
      type: 'privateInvoice',
      url: `${getApiBase()}/claims/${DCNGuid}/${recordId}`
    },

    wsbcReport: {
      type: 'wsbcReport',
      url: `${getApiBase()}/wcbreports/${DCNGuid}/${recordId}`
    }
  };
};
