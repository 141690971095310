import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { inputs } from '../../helpers/inputs';

const AnesProceduralDialog = () => {
  const { watch, onSubmit, localState, setLocalState } = useFormContext();

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      anesProceduralDialog: false
    }));
  };

  const proseedSubmission = () => {
    onHide();
    onSubmit({ ...watch(), [inputs.ignoreValidation.name]: true });
  };

  const actionButtons = (
    <>
      <Button id={elementIDs.ignoreButton} label={t('Ignore')} onClick={proseedSubmission} />
      <Button id={elementIDs.goBackButton} className="p-button-outlined" label={t('Go_back')} onClick={onHide} />
    </>
  );

  return (
    <Dialog
      id={elementIDs.anesProceduralDialog}
      header={t('Warning')}
      visible={localState.anesProceduralDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg">{t('Applies_only_to_the_anesthetic_procedural_fee')}</div>
    </Dialog>
  );
};

export default AnesProceduralDialog;
