import {
  getInvoicesCatalogMostRecentCodes,
  getPatientClaimData,
  getWSBCReport,
  setMissingCodesToRecentList
} from '../../../actions/claims.action.creators';
import { serviceDateFormat } from '../../../../utils/serviceDateFormat';
import { store } from '../../../../../store';
import { inputs } from './inputs';
import { formatInitialValues } from './formatInitialValues';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const getInitialValues = ({ reportId, setLocalState, reset }) => {
  store.dispatch(
    getWSBCReport(reportId, (wsbcReport) => {
      wsbcReport.SaveAsDraft = wsbcReport.Status === 4;
      const patientGuid = wsbcReport[inputs.patient.name]?.PatientGuid;
      wsbcReport[inputs.patient.name] = [wsbcReport[inputs.patient.name]];

      store.dispatch(getPatientClaimData(patientGuid));
      store.dispatch(
        getInvoicesCatalogMostRecentCodes({
          patientGuid,
          callback: (response) => {
            // Add date to the quick pick catalogs if it's not in the list
            const date = serviceDateFormat(new Date(wsbcReport[inputs.serviceDate.name]));
            store.dispatch(setMissingCodesToRecentList({ codes: { [commonInputs.serviceDate.codeType]: [date] } }));
          }
        })
      );

      const formattedInitValues = formatInitialValues(wsbcReport);

      setLocalState((prevState) => ({ ...prevState, initialDataForEdit: formattedInitValues }));
      // Update initial values

      reset(formattedInitValues);
    })
  );
};
