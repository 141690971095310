import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import Context from '../../../../../Context';
import CommonConfirmDialog from '../../../../common/components/CommonConfirmDialog';
import DeleteConfirmation from '../../../../common/components/DeleteConfirmation';
import { formattedService } from '../claimDetailsHelpers/formatServices';
import { toTitleCase } from '../../../../utils/toTitleCase';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from '../claimDetailsHelpers/inputs';
import { createPrivateRecord, deleteClaim, setClaimInfo, updatePrivateRecord } from '../../../actions/claims.action.creators';
import { privateBlankRecord } from '../claimDetailsHelpers/newClaimBlankRecord';
import { resetPrivateForm } from '../claimDetailsHelpers/resetPrivateForm';
import { multipleDoctorGuid } from '../../../../config/defaultValuesConfig';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { t } from '../../../../../service/localization/i18n';
import classes from './EditPanelActionButtons.module.scss';
import cx from 'classnames';

const ReviewPanelActionButtons = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { privateRecord, records, setStep, isNew, isDirty, validateForm, setInitialRecords, setIsPractitionerIsEmpty, deleteInvoiceCallback } =
    useContext(Context);
  const { creatingPrivateRecord, isFetchingClaims } = useSelector((state) => state.claims);
  const { isMobile, isMobileOnly } = useSelector((state) => state.core.window);

  const [visible, setVisible] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onCreate = () => {
    const requestType = isNew ? 'create' : 'save';
    const updatedClaimRecord = {
      ...privateRecord,
      DoctorGuid: privateRecord?.DoctorGuid === multipleDoctorGuid ? privateRecord?.DCNGuid : privateRecord?.DoctorGuid,
      PatientList: Array.isArray(privateRecord[commonInputs.patients.descriptionName])
        ? privateRecord[commonInputs.patients.descriptionName]?.map((i) => i?.PatientGuid)
        : [privateRecord[commonInputs.patients.descriptionName]?.PatientGuid],
      PayToGuid: privateRecord[inputs().payTo.name]?.DoctorGuid
        ? privateRecord[inputs().payTo.name]?.DoctorGuid
        : privateRecord[inputs().payTo.name]?.DCNGuid,
      BillToGuid: privateRecord[inputs().billTo.name][0]?.PatientGuid
    };

    delete updatedClaimRecord[inputs().payTo.name];
    delete updatedClaimRecord[inputs().billTo.name];
    delete updatedClaimRecord[commonInputs.patients.descriptionName];
    delete updatedClaimRecord[inputs().privateService.name];
    delete updatedClaimRecord.PatientDetails;
    delete updatedClaimRecord.CurrentUserPrefs;
    delete updatedClaimRecord.DateCreated;
    delete updatedClaimRecord.DateUpdated;
    delete updatedClaimRecord.SDate;
    delete updatedClaimRecord.FeePaid;
    delete updatedClaimRecord.FeeAmount;
    delete updatedClaimRecord.NoOfServiceUnits;

    setIsPractitionerIsEmpty(false);
    dispatch(
      createPrivateRecord(updatedClaimRecord, requestType, (responceData) => {
        const formattedRecord = privateBlankRecord({
          privateRecord: {
            ...responceData,
            ServicesList: updatedClaimRecord.ServicesList // CMO-2424 - Private invoice->Service/product change order on the invoice
          },
          responceUpdate: true
        });
        const patientDetails = {
          ...formattedRecord?.Patients[0],
          LastPrivateGuid: formattedRecord?.InvoiceGuid
        };

        dispatch(
          updatePrivateRecord(
            {
              ...formattedRecord,
              Payments: responceData.Payments,
              PatientDetails: patientDetails
            },
            true
          )
        );
        setStep(2);
        dispatch(setClaimInfo(formattedRecord)); // highlight line in the data table
      })
    );
  };

  const onCancel = () => {
    if (isDirty) history.goBack();
    if (!isDirty) {
      resetPrivateForm();
      history.goBack();
    }
  };

  const onDelete = () => {
    dispatch(deleteClaim({ invoiceGuid: privateRecord?.InvoiceGuid, callback: deleteInvoiceCallback }));
  };

  const cancelButton = (
    <Button
      id={isDirty ? elementIDs.cancel : elementIDs.close}
      className={cx(isMobile ? 'w-full' : 'mr-2', isDirty ? 'p-button-outlined' : '')}
      label={isDirty ? t('Cancel') : t('Close')}
      onClick={onCancel}
    />
  );

  const deleteButton = (
    <Button
      id={elementIDs.delete}
      className={cx('p-button-outlined', isMobileOnly ? 'w-full' : 'mr-3')}
      label={t('Delete')}
      loading={isFetchingClaims}
      onClick={() => setShowDeleteConfirmation(true)}
    />
  );

  return (
    <>
      <div className={cx('flex align-items-center stickyActionButtons', isMobileOnly ? 'w-full' : '')}>
        {!isDirty && (
          <div>
            {!isNew && privateRecord?.Status !== 2 && deleteButton}
            {cancelButton}
          </div>
        )}

        {isDirty && (
          <div className={cx('flex align-items-center', isMobile ? 'justify-content-between w-full' : 'justify-content-around')}>
            <Button
              id={isNew ? elementIDs.createPrivateInvoiceButton : elementIDs.savePrivateInvoiceButton}
              className={cx('p-button-secondar', isMobileOnly ? 'w-full' : cx('mr-3', classes.nextButtonDesctop))}
              label={isNew ? t('Create') : t('Save')}
              loading={creatingPrivateRecord}
              disabled={isFetchingClaims}
              onClick={() => validateForm(onCreate)}
            />

            {records?.some((i) => i.id === '-1') ? null : (
              <Button
                id={elementIDs.addService}
                className={cx('p-button-outlined', isMobileOnly ? 'w-full' : 'mr-3')}
                label={toTitleCase(t('Add_service'))}
                onClick={() => setInitialRecords([...records, formattedService()])}
              />
            )}

            {!isNew && privateRecord?.Status !== 2 && deleteButton}

            {cancelButton}
          </div>
        )}
      </div>

      <CommonConfirmDialog
        visible={visible}
        body={t('You_made_some_changes_to_this_screen')}
        accept={() => setStep(0)}
        reject={() => setVisible(false)}
      />

      <DeleteConfirmation
        visible={showDeleteConfirmation}
        message={t('Are_you_sure_delete_this_invoice')}
        header={t('Delete_Claim')}
        icon="pi pi-exclamation-triangle"
        acceptLabel={t('Yes')}
        rejectLabel={t('No')}
        accept={onDelete}
        reject={() => setShowDeleteConfirmation(false)}
        onHide={() => setShowDeleteConfirmation(false)}
        baseZIndex={1000}
      />
    </>
  );
};

export default ReviewPanelActionButtons;
