import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { relationshipOptions } from '../../../helpers/dropdownOptions';
import { useValidation } from '../../../hooks/useValidations';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const Relationship = () => {
  const name = inputs.relationship.name;
  const { relationshipValidation } = useValidation();
  const { control, errors } = useFormContext();
  const errorMessage = errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => relationshipValidation(value) }}
      render={({ field }) => (
        <InputWrapper name={name} label={inputs.relationship.label} required errorMessage={errorMessage}>
          <Dropdown
            inputRef={field.ref}
            inputId={field.name}
            className={cx({ 'p-invalid': errorMessage }, 'flex')}
            type="text"
            value={field.value}
            scrollHeight={dropdownScrollHeight()}
            options={relationshipOptions}
            onChange={(e) => field.onChange(e.target.value)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default Relationship;
