import React from 'react';
import NoteCard from '../../../NoteCard/NoteCard';
import InfinitScrollPanel from '../../../../../Misc/ScrollPanel/InfinitScrollPanel';
import { useNotesContext } from '../../hooks/useNotesContext';
import { t } from '../../../../../../service/localization/i18n';

const NotesList = React.forwardRef((_, ref) => {
  const {
    notesList,
    setNotesState,
    onScroll,
    page,
    totalPages,
    renderMenu = true,
    notesListRootStyles,
    notesState: { searchQuery }
  } = useNotesContext();

  if (!notesList?.length) {
    return <div className="text-center px-0 md:px-5 text-xl">{t('No_notes_found')}</div>;
  }

  const getMenuModel = (note) => {
    return [
      {
        label: t('Edit'),
        icon: 'pi pi-pencil',
        command: () =>
          setNotesState((prevState) => ({
            ...prevState,
            noteDialog: { showDialog: true, note }
          }))
      },
      {
        label: t('Delete'),
        icon: 'pi pi-trash',
        command: () =>
          setNotesState((prevState) => ({
            ...prevState,
            deleteNoteDialog: { showDialog: true, note }
          }))
      }
    ];
  };

  return (
    <div style={notesListRootStyles || { height: 'calc(100vh - 290px)' }}>
      <InfinitScrollPanel ref={ref} className="h-full" showScrollTop onScroll={onScroll} page={page} totalPages={totalPages}>
        <div className="flex flex-column gap-2 pt-3 px-0 md:px-5">
          {notesList?.map((note, idx) => {
            const menuModel = getMenuModel(note);
            return <NoteCard key={`${note.NoteGuid}_${idx}`} note={note} menuModel={menuModel} renderMenu={renderMenu} searchQuery={searchQuery} />;
          })}
        </div>
      </InfinitScrollPanel>
    </div>
  );
});

export default NotesList;
