import axios from 'axios';
import { getApiBase, setAuthHeader } from '../../../config';
import { store } from '../../../store';
import constants from '../reducers/constants';

export const setClaimNotes = (payload) => ({
  type: constants.SET_CLAIM_NOTES,
  payload
});

export const clearClaimNotes = () => ({
  type: constants.CLEAR_CLAIM_NOTES,
  payload: {}
});

export const setPatientNotes = (payload) => ({
  type: constants.SET_PATIENT_NOTES,
  payload
});

export const clearPatientNotes = () => ({
  type: constants.CLEAR_PATIENT_NOTES,
  payload: {}
});

export const setClaimNoteDetails = (payload) => ({
  type: constants.SET_CLAIM_NOTE_DETAILS,
  payload
});

export const clearClaimNoteDetails = () => ({
  type: constants.CLEAR_CLAIM_NOTE_DETAILS,
  payload: {}
});

export const setPatientNoteDetails = (payload) => ({
  type: constants.SET_PATIENT_NOTE_DETAILS,
  payload
});

export const clearPatientNoteDetails = () => ({
  type: constants.CLEAR_PATIENT_NOTE_DETAILS,
  payload: {}
});

// VER-635
export const getTotalUnreadNotes = () => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  const baseUrl = `${getApiBase()}/notes/${DCNGuid}/count`;
  const url = new URL(baseUrl);

  return axios
    .get(url)
    .then((results) => {
      store.dispatch({
        type: constants.GET_TOTAL_UNREAD_NOTES,
        payload: results.data
      });
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getTotalUnreadNotes', error);
    });
};
