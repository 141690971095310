import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import { dropdownScrollHeight } from '../../../../../../../config/stylesConfig';
import { getDefaultPrefs } from '../../../../../../helpers/getDefaultPrefs';
import { prefsCodes } from '../../../../../../../config/prefsCodesConfig';
import { defaulFacilitytCode } from '../../../../config/defaultValues';
import { t } from '../../../../../../../../service/localization/i18n';

// interface IPatients {
//   name: string,
//   inputId: string,
// };

const FacilityInput = ({ name, inputId }) => {
  const defaultValue = { code: defaulFacilitytCode };

  const { control, watch, setValue, localState } = useFormContext();
  const [code, setCode] = useState(null);

  useEffect(() => {
    if (!watch(name)) return setCode(defaultValue);
    return setCode({ code: watch(name) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch(name)]);

  const options = () => {
    const facilityPrefs = getDefaultPrefs(localState.practitionerPrefs.content, prefsCodes.presetFacilityList);

    if (facilityPrefs) {
      const prefsCodesList = facilityPrefs.split(',').map((i) => ({ code: i }));
      return [defaultValue, ...prefsCodesList];
    }

    return [defaultValue];
  };

  const onChange = (e) => {
    const value = e.value.code === defaulFacilitytCode ? '' : e.value.code;
    setValue(name, value, { shouldDirty: true });
  };

  const facilityInput = () => {
    return (
      <Dropdown
        inputId={inputId}
        autoHighlight
        type="text"
        value={code}
        options={options()}
        optionLabel="code"
        scrollHeight={dropdownScrollHeight()}
        placeholder={t('Select_code')}
        onChange={onChange}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => facilityInput(field)} />;
};

export default FacilityInput;
