import SectionTitle from './SectionTitle';
import Capable from '../Inputs/Capable';
import Restrictions from '../Inputs/Restrictions';
import EstimatedReturn from '../Inputs/EstimatedReturn';
import Rehabilitation from '../Inputs/Rehabilitation';
import Consult from '../Inputs/Consult';
import RecoveryDate from '../Inputs/RecoveryDate';
import { t } from '../../../../../../../service/localization/i18n';

const ReturnToWorkSection = () => {
  return (
    <>
      <SectionTitle title={t('Return_To_Work')} className="mb-3" />

      <Capable />

      <Restrictions />

      <EstimatedReturn />

      <Rehabilitation />

      <Consult />

      <RecoveryDate />
    </>
  );
};

export default ReturnToWorkSection;
