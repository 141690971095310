import { routes } from '../../../routes/routes';
import { invoicesTypes } from './addNewOverlayItemsList';
import {
  getInvoicesCatalogMostRecentCodes,
  setShowMissingPatientDataDialog,
  shouldResetForm,
  updatePrivateRecord,
  updateWSBCRecord
} from '../actions/claims.action.creators';
import { commonInputs } from '../../config/commonInputsConfig';
import { formatPatientInfoForClaims } from '../../patients/helpers/formatPatientInfoForClaims';
import { resetPrivateForm } from '../components/PrivateDetails/claimDetailsHelpers/resetPrivateForm';
import { inputs as wsbcrInputs } from '../components/WSBCReport/helpers/inputs';
import { getDefaultValues as wsbcrDefaultValues } from '../components/WSBCReport/helpers/defaultValues';
import { localStateInitValues as wsbcrLocalStateInitValues } from '../components/WSBCReport/helpers/wsbcReportInitialState';
import { store } from '../../../store';
import { validatePatientProfile } from '../components/WSBCReport/helpers/validatePatientProfile';
import { setPatientInfo } from '../../patients/actions/patients.action.creators';

export const addNewInvoice = (type, history, patient_details = null, replaceRoute = false) => {
  switch (type) {
    case invoicesTypes.teleplan:
      addNewTeleplan({ history, patient_details, replaceRoute });
      break;

    case invoicesTypes.private:
      addNewPrivate({ history, patient_details, replaceRoute });
      break;

    default:
      break;
  }
};

export const addNewTeleplan = ({ history, patient_details = null, replaceRoute = false, firstVisit = false, pos = false, group = false }) => {
  const patientGuid = patient_details?.PatientGuid;
  let path = routes.newClaimRecord.path;

  if (patientGuid) {
    path += `/${patientGuid}`;
  }

  if (pos) {
    path = routes.teleplanPOS.path + (patientGuid ? `/${patientGuid}` : '');
  }

  if (group) {
    path = routes.teleplanGroup.path + (patientGuid ? `/${patientGuid}` : '');
  }

  if (firstVisit) {
    path = routes.claimRecordFirstVisit.path;
  }

  // [KS] CMO-1363 - Fee Item and Dx get carried over from unsaved claim for previous patient (check comments)
  replaceRoute ? history.replace(path) : history.push(path);
};

export const addNewPrivate = ({ history, patient_details = null, replaceRoute = false }) => {
  resetPrivateForm();
  store.dispatch(getInvoicesCatalogMostRecentCodes({ patientGuid: patient_details?.PatientGuid })); //[KS] CMO-1085 - Forgot to call getrecetn api when creating new invoice (with and without patient's id)

  if (patient_details?.PatientGuid) {
    //don't forget DO NOT reset form to initialize patients data (use shouldResetForm(false))
    store.dispatch(shouldResetForm(false));
    store.dispatch(
      updatePrivateRecord({
        [commonInputs.patients.descriptionName]: formatPatientInfoForClaims(patient_details)
      })
    );
  }

  const path = `${routes.newPrivateRecord.path}`;
  replaceRoute ? history.replace(path) : history.push(path); // [KS] CMO-1363 - Fee Item and Dx get carried over from unsaved claim for previous patient (check comments)
};

export const addNewWSBCR = ({ history, patient_details = null, replaceRoute = false }) => {
  const _navigate = () => {
    const path = `${routes.newWSBCReport.path}`;
    replaceRoute ? history.replace(path) : history.push(path);
  };

  const _callback = (patientInfo) => {
    store.dispatch(
      updateWSBCRecord({
        formState: { ...wsbcrDefaultValues(), [wsbcrInputs.patient.name]: [patientInfo] },
        localState: wsbcrLocalStateInitValues
      })
    );

    store.dispatch(setShowMissingPatientDataDialog(false));
    _navigate();
  };

  if (patient_details) {
    const formattedPatientInfo = formatPatientInfoForClaims(patient_details);
    const showMissingPatientDataDialog = store.getState().claims.showMissingPatientDataDialog;

    store.dispatch(setPatientInfo(formattedPatientInfo));
    if (!showMissingPatientDataDialog) return validatePatientProfile(formattedPatientInfo, () => _callback(formattedPatientInfo));
    return _callback(formattedPatientInfo);
  }

  return _navigate();
};

export const addEClaim = ({ history, patientGuid = null, replaceRoute = false }) => {
  const path = patientGuid ? `${routes.eClaimNew.path}/${patientGuid}` : `${routes.eClaimNew.path}`;
  replaceRoute ? history.replace(path) : history.push(path);
};

export const addNewEClaimPolicy = (props) => {
  const { history, patientGuid = null, replaceRoute = false } = props;
  let path = `${routes.eClaimPolicyNew.path}`;

  if (patientGuid) path = `${routes.eClaimPolicyNew.path}/${patientGuid}`;
  replaceRoute ? history.replace(path) : history.push(path);
};
