import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { logout } from '../../../modules/auth/actions/auth.actions.creators';
import { elementIDs } from '../../../modules/config/elementIDsConfig';
import { stringFormat } from '../../../modules/utils/stringFormat';
import { t } from '../../../service/localization/i18n';

// For tests
// const timeout = 1 * 60 * 1000; // one min
// const promptBeforeIdle = 5000; // 5 sec

const timeout = 15 * 60 * 1000; // 15 minutes in milliseconds
const promptBeforeIdle = 60 * 1000; // 60 seconds in milliseconds

const SessionIdle = () => {
  const dispatch = useDispatch();
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const onIdle = () => {
    setOpen(false);
    dispatch(logout());
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <Dialog
        id={elementIDs.sessionIdleDialog}
        header={t('Inactive_session')}
        visible={open}
        breakpoints={{ '768px': '95vw' }}
        style={{ width: '25vw' }}
        modal
        baseZIndex={1000000}
        onHide={handleStillHere}
      >
        <h6>{stringFormat(t('Logging_out_in_x_seconds'), remaining)}</h6>
        <p>{t('Inactive_session_text')}</p>
        <div className="flex justify-content-center gap-2">
          <Button className="w-full" label={t('Stay_connected')} onClick={handleStillHere} />
          <Button className="w-full" label={t('Logout_now')} onClick={() => dispatch(logout())} outlined />
        </div>
      </Dialog>
    </>
  );
};

export default SessionIdle;
