import { useDispatch, useSelector } from 'react-redux';
import { archiveClaimRecord, getListOfClaims } from '../../../../claims/actions/claims.action.creators';
import { claimsDefaultSortBy } from '../../../../config/defaultValuesConfig';
import { useClaimsListContext } from './useClaimsListContext';
import { setClaimsDialogVisibility } from '../../../actions/cashFlow.axtion.creators';

export const useTable = () => {
  const dispatch = useDispatch();
  const claimsDialog = useSelector((state) => state.cashFlow.dialogs.claimsDialog);
  const { state, setState } = useClaimsListContext();
  const currentRequestParams = {
    patientGuid: state.PatientGuid,
    page: state.page,
    pageSize: state.pageSize,
    query: state.query,
    sortby: state.sortby
  };

  const tableStyle = {
    minWidth: '30rem'
  };

  // Check for screen width and update style accordingly
  if (window.innerWidth >= 968) {
    tableStyle.minWidth = '60rem';
  }

  const fetchData = async (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const results = await getListOfClaims(params);
    if (results) {
      setState((prevState) => ({
        ...prevState,
        ...results,
        loading: false
      }));

      dispatch(
        setClaimsDialogVisibility({
          ...claimsDialog,
          billedCount: results.totalRecords // Set billedCount for displaying this value in the claims dialog
        })
      );
    } else {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const onSelectionChange = (e) => {
    setState((prevState) => ({ ...prevState, selectedItem: e.value }));
  };

  const onSort = (e) => {
    const sortOrders = { 1: 'asc', '-1': 'desc' };
    const sortby = e.sortOrder === 0 ? claimsDefaultSortBy.sortOrder : `${e.sortField}_${sortOrders[e.sortOrder]}`;
    fetchData({ ...currentRequestParams, sortby });
  };

  const onPage = (e) => {
    fetchData({ ...currentRequestParams, page: e.page + 1 });
  };

  const onArchive = (claim) => {
    dispatch(
      archiveClaimRecord(claim, () => {
        console.log('!!!archiveClaimRecord');
      })
    );
  };

  const isDeclinedOrNotPaid = (status) => {
    return Number(status) === 3 || Number(status) === 6;
  };

  const showArchiveColumn = state.claimList?.some((i) => {
    const status = Number(i.Status);
    return isDeclinedOrNotPaid(status);
  });

  const showRejectionColumn = state.claimList?.some((i) => {
    const status = Number(i.Status);
    return isDeclinedOrNotPaid(status);
  });

  const showArchiveButton = (status) => isDeclinedOrNotPaid(status);

  return {
    tableStyle,
    currentRequestParams,
    showArchiveColumn,
    showRejectionColumn,
    showArchiveButton,
    onSort,
    onPage,
    fetchData,
    onSelectionChange,
    onArchive
  };
};
