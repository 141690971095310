import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Context from '../../../../Context';
import { updateLockValue } from './claimDetailsHelpers/updatePreferences';
import { shouldResetForm } from '../../actions/claims.action.creators';
import { savePreferences, setCurrentPractitionerPrefs } from '../../../preferences/actions/preferences.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';

const LockValue = ({
  iconId,
  isLocked,
  presetCode,
  inputValue,
  updatedPresetPrefs, // array of updated preferences
  updateDefaultPractitioner = false
}) => {
  const dispatch = useDispatch();
  const { privateRecord, isNew, shouldResetNewInvoiceForm, savingPreferences, user } = useContext(Context);

  const savePrefs = () => {
    if (!isNew) return;
    shouldResetNewInvoiceForm && dispatch(shouldResetForm(false));
    const docId = updateDefaultPractitioner ? user.details.UserGuid : privateRecord.DoctorGuid;
    const formatUpdatedPrefs = {
      content: updatedPresetPrefs,
      label: 'default',
      value: privateRecord.DoctorGuid
    };

    dispatch(savePreferences(docId, formatUpdatedPrefs, (prefs) => savePreferencesCallback(prefs), updateDefaultPractitioner));
  };

  const savePreferencesCallback = (prefs) => {
    if (!updateDefaultPractitioner) {
      const updatedPrefs = {
        ...privateRecord?.CurrentUserPrefs,
        content: privateRecord?.CurrentUserPrefs?.content?.map((i) => prefs?.content?.find((x) => x.label === i.label) || i)
      };
      updateLockValue(prefs?.content, privateRecord?.CurrentUserPrefs, dispatch); //[KS] update local state with prefs
      dispatch(setCurrentPractitionerPrefs(updatedPrefs)); //[KS] update redux state with prefs for practitioner
    }
  };

  const onLockClick = (value) => {
    if (value === '0' || inputValue === '' || inputValue === undefined || inputValue === null || inputValue === {} || inputValue === []) return;
    if (!savingPreferences) {
      const _lockValue = { value, label: presetCode };
      updateLockValue(_lockValue, privateRecord?.CurrentUserPrefs, dispatch);
      if (value === '1') savePrefs();
    }
  };

  return (
    <div id={`quickSettingsRoot_${presetCode}`} className="ml-4 mb-2 pointer" onClick={() => onLockClick(isLocked === '1' ? '0' : '1')}>
      {isLocked === '1' || isLocked === true ? (
        <i id={`${iconId}_${elementIDs.lockedIcon}`} className="pi pi-unlock" />
      ) : (
        <i id={`${iconId}_${elementIDs.unlockedIcon}`} className="pi pi-lock-open" />
      )}
    </div>
  );
};

export default LockValue;
