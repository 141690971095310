import React from 'react';
import PatientForm from '../PatientForm/PatientForm';

const CreatePatient = () => {
  return (
    <>
      <PatientForm />
    </>
  );
};

export default CreatePatient;
