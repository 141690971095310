import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputNumber } from 'primereact/inputnumber';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';

const FeeAmount = () => {
  const name = inputs.feeAmount.name;
  const { control } = useFormContext();

  const feeAmountInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.feeAmount.label}>
        <InputNumber
          inputId={elementIDs.feeAmountInput}
          inputStyle={{ width: 'inherit' }}
          style={{ width: 'inherit' }}
          className="flex"
          type="text"
          value={field.value}
          mode="currency"
          currency="USD"
          locale="en-US"
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => feeAmountInput(field)} />;
};

export default FeeAmount;
