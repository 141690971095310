import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Skeleton } from 'primereact/skeleton';
import RatioPercent from './RatioPercent';

import { routes } from '../../../../routes/routes';
import { currencyFormat } from '../../../utils/currencyFormat';

const CashFlowCard = ({ data, loading, title, subtitle, amount, onClick, children }) => {
  // `type` options: 1 - day , 2 - week, 3 - month, 4 - year
  const history = useHistory();
  const ratioPercent = Number(data?.RatioBilled) || 0;

  const handleClick = () => {
    onClick();
    history.push(routes.charts.path);
  };

  const amountLayout = () => {
    if (loading) return <Skeleton width="105px" height="33px" />;

    if (amount) return currencyFormat(amount);

    // Explicitly check for undefined or null
    if (data?.TotalBilled === undefined || data?.TotalBilled === null) return currencyFormat(0);
    return currencyFormat(data.TotalBilled);
  };

  const percentRatioLayout = () => {
    if (loading) return <Skeleton className="mt-2" width="50px" height="17px" />;
    if (!ratioPercent) return null;
    if (ratioPercent === -100) return null;
    if (!data?.TotalBilled) return null;
    return <RatioPercent ratioPercent={ratioPercent} />;
  };

  const chartLayout = () => {
    if (loading) return <Skeleton className="w-full h-full" />;
    return children;
  };

  return (
    <div className="col-12 md:col-6 xl:col-3 pointer h-11rem max-h-11rem" onClick={handleClick}>
      <div className="card m-0 h-full">
        <div className="flex justify-content-between align-items-start h-full gap-3">
          <div className="w-6">
            <div className="flex flex-column pb-2">
              <span className="font-semibold text-lg">{title}</span>
              {/* <span className="text-sm text-gray-500">{subtitle}</span> */}
            </div>
            <span className="text-3xl xl:text-2xl font-bold text-900">
              {/* Amount */}
              {amountLayout()}
            </span>
            {/* Percent ratio */}
            {percentRatioLayout()}
          </div>

          {/* Chart */}
          <div className="w-6 h-full text-center">{chartLayout()}</div>
        </div>
      </div>
    </div>
  );
};

export default CashFlowCard;
