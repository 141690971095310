import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import Address from '../AddressForm/AddressFormLayout';
import { autocompleteSearchProvince } from '../../../utils/autocompleteSearchProvince';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const NewPayorFormLayout = ({ formik, isFormFieldValid, getFormErrorMessage, selectedProvince, setSelectedProvince }) => {
  const [filteredProvinces, setfilteredProvinces] = useState(null);

  const onChangeInputText = (e, fieldName) => {
    formik.handleChange(e);
    formik.setFieldValue(fieldName, e.target.value.toUpperCase());
  };

  const searchProvince = (e) => {
    autocompleteSearchProvince(e, setfilteredProvinces);
  };

  const onChangeProvince = (e) => {
    setSelectedProvince(e.value);
    formik.handleChange(e);
  };

  return (
    <>
      <div className="field">
        <label className="text-disabled-color">{t('Company_Name')}</label>
        <InputText id="CompanyName" name="CompanyName" value={formik.values.CompanyName} onChange={(e) => onChangeInputText(e, 'CompanyName')} />
        {getFormErrorMessage('CompanyName')}
      </div>

      <div className="field">
        <label className="text-disabled-color">{`${t('Last_Name')}`}</label>
        <InputText
          className={cx({
            'p-invalid': isFormFieldValid('LastName')
          })}
          id="LastName"
          name="LastName"
          value={formik.values.LastName}
          onChange={(e) => onChangeInputText(e, 'LastName')}
        />
        {getFormErrorMessage('LastName')}
      </div>

      <div className="field">
        <label className="text-disabled-color">{`${t('First_Name')}`}</label>
        <InputText
          className={cx({
            'p-invalid': isFormFieldValid('FirstName')
          })}
          id="FirstName"
          name="FirstName"
          value={formik.values.FirstName}
          onChange={(e) => onChangeInputText(e, 'FirstName')}
        />
        {getFormErrorMessage('FirstName')}
      </div>

      <Address
        formik={formik}
        selectedProvince={selectedProvince}
        filteredProvinces={filteredProvinces}
        searchProvince={searchProvince}
        onChangeProvince={onChangeProvince}
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        setSelectedProvince={setSelectedProvince}
      />

      <div className="field">
        <label className="text-disabled-color">{t('Email')}</label>
        <InputText
          className={cx({
            'p-invalid': isFormFieldValid('Email')
          })}
          id="Email"
          name="Email"
          value={formik.values.Email}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage('Email')}
      </div>

      <div className="field">
        <label className="text-disabled-color">{t('Phone')}</label>
        <InputMask
          id="Phone"
          name="Phone"
          mask="(999) 999-9999"
          placeholder="(999) 999-9999"
          value={formik.values.Phone}
          onChange={formik.handleChange}
        />
      </div>
    </>
  );
};

export default NewPayorFormLayout;
