import { inputs } from './inputs';
import { defaultGuid } from '../../../../config/defaultValuesConfig';
import { formatFormTypeValue } from './formTypeActions';
import { store } from '../../../../../store';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { isChiropractor, isPhysician } from '../../../../config/specialitiesConfig';
import { getInitPractitionerGuid } from '../../../../utils/getPractitioner';
import * as dropdownOptions from './dropdownOptions';

export const getDefaultValues = () => {
  const user = store.getState().user.details;
  const clinic = store.getState().clinic.details;
  const speciality = user.Speciality || 0;
  const initFormType = formTypeInit(speciality);

  return {
    DCNGuid: clinic.DCNGuid,
    [inputs.claimGuid.name]: '',
    [inputs.invoiceGuid.name]: '',
    [inputs.rowGuid.name]: defaultGuid,
    [inputs.speciality.name]: speciality,
    [inputs.invoiceStatus.name]: '0',
    [inputs.statusText.name]: 'Outstanding',

    [inputs.patient.name]: [],
    [inputs.formType.name]: initFormType,
    [inputs.firstReportYN.name]: '',
    [inputs.claimNumber.name]: '',
    [inputs.injuryDate.name]: null,
    [inputs.employer.name]: '',
    [inputs.phone.name]: '',
    [inputs.address.name]: '',
    [inputs.city.name]: '',
    [commonInputs.submitWithGenericPHN.name]: '0',

    [inputs.isPrimary.name]: '',
    [inputs.termPrimary.name]: dropdownOptions.patientPeriodDropdownOptions[0].value,
    [inputs.firstTreatment.name]: '',

    [inputs.priorProblems.name]: '',

    [inputs.noi.name]: '',
    [inputs.noi.codeDescription]: '',
    [inputs.aoi.name]: '',
    [inputs.aoi.codeDescription]: '',
    [inputs.icd9.name]: '',
    [inputs.icd9.codeDescription]: '',
    [inputs.anatomicCode.name]: dropdownOptions.anatomicCodesDropdownOptions[0].value,
    [inputs.disabled.name]: '',
    [inputs.disabledDate.name]: null,

    [inputs.clinicalInformation.name]: '',

    [inputs.capable.name]: '',
    [inputs.restrictions.name]: '',
    [inputs.estimatedReturn.name]: dropdownOptions.estimatedTimeDropdownOptions[0].value,
    [inputs.rehabilitation.name]: '',
    [inputs.consult.name]: '',
    [inputs.recoveryDate.name]: null,

    [inputs.serviceDate.name]: null,
    [inputs.practitioner.name]: getInitPractitionerGuid(),
    [inputs.feeCodes.name]: feeCodeInit(speciality, formatFormTypeValue(initFormType, speciality)),

    // no inputs values
    [inputs.patientClaims.name]: {},
    [inputs.sendNow.name]: false, // on create - false, on submit - true
    [inputs.saveAsDraft.name]: false
    // [inputs.recordNumber.name]: '',
    // [inputs.sequenceNum.name]: '',
    // [inputs.feeAmount.name]: '',
    // [inputs.feePaid.name]: '',
  };
};

export const formTypeInit = (speciality) => {
  // Phisitian
  if (isPhysician(speciality)) return '11';
  // Chiropractic
  if (isChiropractor(speciality)) return '1';
};

export const feeCodeInit = (speciality, formType = '') => {
  // Phisitian
  if (isPhysician(speciality)) {
    if (formType === dropdownOptions.formTypeValues.firstReport) {
      return dropdownOptions.feeCodesDropdownOptions.physician.firstReport.find((i) => i.value === '19937').value;
    }

    if (formType === dropdownOptions.formTypeValues.nextReport) {
      return dropdownOptions.feeCodesDropdownOptions.physician.nextReport.find((i) => i.value === '19940').value;
    }
  }

  // Chiropractic
  if (isChiropractor(speciality)) {
    if (formType === dropdownOptions.formTypeValues.firstReport) {
      return dropdownOptions.feeCodesDropdownOptions.chiropractor.firstReport.find((i) => i.value === '19134').value;
    }

    if (formType === dropdownOptions.formTypeValues.nextReport) {
      return dropdownOptions.feeCodesDropdownOptions.chiropractor.nextReport.find((i) => i.value === '19135').value;
    }
  }
  // if (isChiropractor(speciality)) {
  //   if (formType === dropdownOptions.formTypeValues.firstReport) {
  //     return dropdownOptions.feeCodesDropdownOptions.chiropractor.find(i => i.value === '19134').value
  //   }

  //   if (formType === dropdownOptions.formTypeValues.nextReport) {
  //     return dropdownOptions.feeCodesDropdownOptions.chiropractor.find(i => i.value === '19135').value
  //   }
  // };
};
