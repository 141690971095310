import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { usePreferences } from '../../../hooks/usePreferences';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';
import { find } from 'lodash';
import cx from 'classnames';

const PayeeNumber = () => {
  const name = inputs.payeeNumber.name;
  const practitioners = useSelector((state) => state.clinic.members);
  const { control, errors } = useFormContext();
  const { isLocked, onSavePrefs } = usePreferences();
  const practitionerGuid = useWatch({ control, name: inputs.practitioner.name });
  const payeeNumber = useWatch({ control, name });
  const errorMessage = errors[name]?.message;
  const defaultCode = prefsCodes.defaultPayee;
  const presetCode = prefsCodes.presetPayee;

  const getPayeeNumbersList = () => {
    const doc = find(practitioners, { DoctorGuid: practitionerGuid });
    const payeeNumbers = doc ? doc?.PayeeNumbers?.map((i) => ({ value: i, label: i })) : [];
    return payeeNumbers;
  };

  const payeeNumbersList = getPayeeNumbersList();

  const onSavePreferences = () => {
    const preferences = [
      { value: '1', label: presetCode },
      { value: payeeNumber, label: defaultCode }
    ];

    onSavePrefs(preferences);
  };

  const payeeNumberInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.payeeNumber.label}
        isLocked={isLocked(payeeNumber, defaultCode)}
        onSavePrefs={onSavePreferences}
        required
        errorMessage={errorMessage}
      >
        <Dropdown
          id={elementIDs.payeeNumberInput}
          inputRef={field.ref}
          className={cx({ 'p-invalid': errorMessage })}
          value={field.value}
          type="text"
          scrollHeight={dropdownScrollHeight()}
          options={payeeNumbersList}
          placeholder={t('Select_code')}
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => payeeNumberInput(field)} />;
};

export default PayeeNumber;
