import React from 'react';
import Asterisk from '../../../../../../../../../components/Misc/Asterisk/Asterisk';
import { inputs } from '../../../../../helpers/inputs';
import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';

const AsterisksForCommentInput = ({ rowOptions }) => {
  const inputName = inputs.comment.name;
  const { showAsterisk } = useValidateGoupPicker();
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const showAsterisks = showAsterisk(rowId, inputName);

  if (!showAsterisks) return <div style={{ width: '6px' }}></div>;

  return <Asterisk tooltipId={`${inputName}_${rowId}`} />;
};

export default AsterisksForCommentInput;
