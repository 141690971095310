import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import Context from '../../../../Context';
import LockValue from './LockValue';
import { setToastMessage } from '../../../core/actions/core.action.creators';

const ClaimInputField = ({
  presetCode,
  isLocked,
  inputValue,
  updatedPresetPrefs,
  updateDefaultPractitioner,

  label,
  required,
  helpText,
  children,
  labelIcon,
  style,
  className,
  showPreferencesButton
}) => {
  const dispatch = useDispatch();
  const { isNew } = useContext(Context);
  const id = label.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '');

  return (
    <div className={className ? className : ''} style={style ? style : { minHeight: '100px' }} id={`privateInputField_${id}`}>
      <div className="flex align-items-center text-disabled-color" style={{ height: '28px' }}>
        <label>
          {label}
          {required && <span className="ml-1 p-error">*</span>}
        </label>

        {labelIcon && <span className="mb-1">{labelIcon}</span>}

        {helpText && (
          <Button
            tabIndex={-1}
            icon="pi pi-question-circle"
            className="p-button-rounded p-button-text p-0 ml-3 mb-2 iconBtn text-disabled-color"
            style={{ width: '1em', height: '1em' }}
            onClick={() =>
              dispatch(
                setToastMessage({
                  type: 'info',
                  message: helpText.detail,
                  lifeTime: helpText.lifeTime ? helpText.lifeTime : 10000,
                  title: helpText.title
                })
              )
            }
          />
        )}

        {/* [KS] CMO-1167 - Create/Edit claim - add "lock" button for inputs, which have preferences values */}
        {showPreferencesButton && isNew && (
          <LockValue
            iconId={id}
            isLocked={isLocked}
            inputValue={inputValue}
            presetCode={presetCode}
            updatedPresetPrefs={updatedPresetPrefs}
            updateDefaultPractitioner={updateDefaultPractitioner}
          />
        )}
      </div>

      {children}
    </div>
  );
};

export default ClaimInputField;
