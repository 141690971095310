import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import CCFPPTooltipLayout from '../Layouts/CCFPPTooltipLayout';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const CCFPPInfoDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideCCFPPDialog = () => {
    setLocalState((prevState) => ({
      ...prevState,
      ccfppInfoDialog: false
    }));
  };

  const emergencyDialogFooter = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHideCCFPPDialog} autoFocus />;
  };

  return (
    <Dialog
      id={elementIDs.ccfppInfoDialog}
      header={t('Continuing_Care_Surcharges')}
      visible={localState.ccfppInfoDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={emergencyDialogFooter()}
      onHide={() => onHideCCFPPDialog()}
    >
      <CCFPPTooltipLayout />
    </Dialog>
  );
};

export default CCFPPInfoDialog;
