import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { CodePicker } from '../../../../../../../components/Inputs';
import { InputWrapper } from '../../../../../../../components/Inputs';
import AddPractitionerButton from '../../ActionButtons/AddPractitionerButton';

import { inputs } from '../../../helpers/inputs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { referralInputRequirements } from '../../../helpers/validationSchema';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useToggleCatalogs } from '../../../hooks/useToggleCatalogs';
import { referralCodeMaxEntry } from '../../../../../../config/defaultValuesConfig';

const Referral = () => {
  const name = inputs.referral.name;
  const description = inputs.referral.codeDescription;

  const { errors, control, watch, isMobile } = useFormContext();
  const { onFocus, onChange } = useCodePickerActions(inputs.referral);
  const { toggleCatalogs } = useToggleCatalogs();
  const referralCodes = watch(inputs.referral.codeType);
  const speciality = watch(inputs.speciality.name);
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const refToBy = useWatch({ control, name: inputs.refToBy.name });
  const required = referralInputRequirements(refToBy, saveAsDraft);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper
          name={name}
          label={inputs.referral.label}
          required={required}
          errorMessage={errors[name]?.message}
          description={watch(description)}
          style={{ height: 'auto', paddingBottom: '10px' }}
        >
          <div className="codePickerWrap_referral">
            <AddPractitionerButton mini />
            <CodePicker
              name={name}
              ref={field.ref}
              id={elementIDs.referralAutocomplete}
              inputId={elementIDs.referralAutocompleteInput}
              isInvalid={!!errors[name]?.message}
              value={referralCodes}
              groupLabel={false}
              maxEntry={referralCodeMaxEntry}
              showCatalogsButton={isMobile}
              searchParams={{ speciality, codeType: inputs.referral.codeType, getCodesFor: 'teleplan' }}
              toggleCatalogs={() => toggleCatalogs('mobile', name)}
              onFocus={onFocus}
              onChange={onChange}
            />
          </div>
        </InputWrapper>
      )}
    />
  );
};

export default Referral;
