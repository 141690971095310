import React from 'react';
import { useFormContext } from 'react-hook-form';

import BMI from '../../modules/TeleplanInvoiceForm/Inputs/BMI';
import Payor from '../../modules/TeleplanInvoiceForm/Inputs/Payor';
import Comment from '../../modules/TeleplanInvoiceForm/Inputs/Comment';
import ToggleCatalogsButton from '../../modules/ActionButtons/ToggleCatalogsButton';
import PayorOptionalSectionPOS from './PayorOptionalSectionPOS';
import SaveAsDraft from '../../modules/TeleplanInvoiceForm/Inputs/SaveAsDraft';
import SubmissionCode from '../../modules/TeleplanInvoiceForm/Inputs/SubmissionCode';
import ReasonFor01080 from '../../modules/TeleplanInvoiceForm/Inputs/ReasonFor01080';
import SubmitWithGenericPHN from '../../modules/TeleplanInvoiceForm/Inputs/SubmitWithGenericPHN';
import PractitionerSection from '../../modules/TeleplanInvoiceForm/Layouts/PractitionerSection';

const InputsSectionPOS = () => {
  const { localState } = useFormContext();

  return (
    <>
      <ToggleCatalogsButton />

      <PractitionerSection />

      {!localState.isEmptyPractitioner && (
        <>
          <Payor />

          <PayorOptionalSectionPOS />

          <ReasonFor01080 />

          <BMI />

          <SubmissionCode />

          <Comment />

          <SubmitWithGenericPHN />

          <SaveAsDraft />
        </>
      )}
    </>
  );
};

export default InputsSectionPOS;
