import React from 'react';

const ContentWrap = ({ children }) => {
  return (
    <div className="flex justify-content-center">
      <div className="flex justify-content-center w-full p-3">{children}</div>
    </div>
  );
};

export default ContentWrap;
