import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { WrappedNameInput } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';
import { useLastName } from '../../../hooks/useLastName';

const LastName = () => {
  const name = inputs.lastName.name;
  const { errors, control } = useFormContext();
  const { lastNameOnBlur } = useLastName();

  const nameInput = (field) => {
    return (
      <WrappedNameInput
        {...field}
        input={inputs.lastName}
        errorMessage={errors[name]?.message}
        required
        onChange={(value) => field.onChange(value)}
        onBlur={(value) => lastNameOnBlur(value, field)}
      />
    );
  };

  return <Controller name={name} control={control} render={({ field }) => nameInput(field)} />;
};

export default LastName;
