import { useCatalogsContext } from './useCatalogsContext';
import { recursion } from '../helpers/recursion';
import { useRequests } from './useRequests';
import { t } from '../../../service/localization/i18n';

export const useCategories = () => {
  const { getCatalogCodesList } = useRequests();
  const { catalogsState, setCatalogsState, slideMenuRef } = useCatalogsContext();
  const activeIndex = catalogsState.activeSubTabIndex;
  const currentCategory = catalogsState.currentCategory;

  const setInitCategoriesList = () => {
    const categories = currentCategory?.tabs[activeIndex]?.categories || [];
    recursion({ arr: categories, catalogsState, setCatalogsState, slideMenuRef, getCatalogCodesList });
    setCatalogsState((prevState) => ({
      ...prevState,
      menuModel: categories || [],
      emptyMessage: `${t('Loading')}...`
    }));
  };

  return { setInitCategoriesList };
};
