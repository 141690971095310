import actions from './demos.action.types';
import axios from 'axios';
import { getApiBase } from '../../../config';
import { defaultPageSize } from '../../../modules/config/defaultValuesConfig';
import { deleteDemoSuccessMessage } from '../components/demosView/helpers/toastMessages';
import { setToastMessage } from '../../../modules/core/actions/core.action.creators';

// DONT FORGET TO RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo", "Demo" or "DEMO" prefix

export const getDemosData = (params, setItemDetails, dir) => (dispatch, state) => {
  const currentState = state();
  const isMobile = currentState.core.window.isMobileOnly;
  const showDataScroller = currentState.core.showDataScroller;
  const isLoadData4Scroll = isMobile && showDataScroller ? true : false;
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const demoItemIndex = currentState.demos.demoItemIndex;
  const PatientGuid = params.PatientGuid;
  const filter = params.filters;
  const sortby = params.sortby;
  const page = params.page || 1;
  const pagesize = params.rows || defaultPageSize;
  const showOnDetailsView = params.showOnDetailsView || false;
  const query = `pid=${PatientGuid}` + (sortby ? `&sortby=${sortby}` : '') + (filter ? `&q=${JSON.stringify(filter)}` : '');

  dispatch(isFetchingDemos(true));

  // DONT FORGET TO CHANGE /claims TO DESIRED API VALUE
  axios
    .get(`${getApiBase()}/claims/${DCNGuid}/${page}/${pagesize}/?${query}`)
    .then((results) => {
      dispatch(isFetchingDemos(false));

      if (showOnDetailsView) {
        const nextItem = results.data.claimList.find((i, index) => index === demoItemIndex);
        dispatch(setDemoDetails(nextItem));
      }

      //paginator for DemoDetailsView
      //set demo to highlight in DataTable/DataScroller
      if (setItemDetails && (dir === 'nextPage' || dir === 'firstPage')) {
        const item = results.data.claimList[0];
        dispatch(setDemoDetails(item));
      }
      if (setItemDetails && (dir === 'prevPage' || dir === 'lastPage')) {
        const item = [results.data.claimList[results.data.claimList.length - 1]];
        dispatch(setDemoDetails(item[0]));
      }
      //==================//

      return dispatch({
        type: actions.FETCH_DEMOS_DATA_SUCCESS,
        results: { ...results, isLoadData4Scroll }
      });
    })
    .catch((reason) => {
      dispatch(isFetchingDemos(false));
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const deleteDemo = (DCNGuid, id, PatientGuid, showOnDetailsView) => (dispatch, state) => {
  const currentState = state();
  const filters = currentState.demos.filters;
  const pageSize = currentState.demos.pageSize;
  const page = currentState.demos.page;
  const sortby = currentState.demos.sortby;
  const params = {
    PatientGuid,
    filters,
    sortby,
    page,
    rows: pageSize,
    showOnDetailsView: showOnDetailsView ? showOnDetailsView : false
  };

  dispatch(isFetchingDemos(true));

  // DONT FORGET TO CHANGE /claims TO DESIRED API VALUE
  axios
    .delete(`${getApiBase()}/claims/${DCNGuid}/${id}`)
    .then(() => {
      dispatch(isFetchingDemos(false));
      dispatch(getDemosData(params));
      dispatch(setToastMessage({ type: 'success', message: deleteDemoSuccessMessage }));
    })
    .catch((reason) => {
      dispatch(isFetchingDemos(false));
      dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};

export const isFetchingDemos = (params) => {
  return {
    type: actions.IS_FETCHING_DEMOS,
    results: params
  };
};

export const setDemoDetails = (data) => {
  return {
    type: actions.SET_DEMO_DETAILS,
    results: data
  };
};

export const setDemoItemIndex = (idx) => ({
  type: actions.SET_DEMO_ITEM_INDEX,
  results: idx
});

export const setFilterByPatientForDemos = (params) => ({
  type: actions.FILTER_BY_PATIENT_FOR_DEMOS,
  results: params
});
