import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const InvalidLocationForMidwifeInductionDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideDialog = () => {
    setLocalState((prevState) => ({
      ...prevState,
      invalidLocationForMidwifeInductionDialog: false
    }));
  };

  const footer = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHideDialog} autoFocus />;
  };

  return (
    <Dialog
      id={elementIDs.invalidLocationForMidwifeInductionDialog}
      header={t('Warning')}
      visible={localState.invalidLocationForMidwifeInductionDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={footer()}
      onHide={() => onHideDialog()}
    >
      {t('Service_must_be_performed_in_hospital')}
    </Dialog>
  );
};

export default InvalidLocationForMidwifeInductionDialog;
