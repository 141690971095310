import { calculateRowTotal } from '../reviewPanelHelpers/calculateRowTotal';
import { multipleDoctorGuid } from '../../../../config/defaultValuesConfig';
import { inputs } from './inputs';

export const formatServices = (data, responceUpdate) => {
  const formattedData = data && data[inputs().privateService.descriptionName]?.map((i) => formattedService(i, data, responceUpdate));
  return formattedData;
};

export const formattedService = (service, privateRecord, responceUpdate = false) => {
  const doctorGuid =
    service?.doctorGuid ||
    (!privateRecord ||
    privateRecord?.DoctorGuid === multipleDoctorGuid ||
    privateRecord?.DoctorGuid?.toUpperCase() === privateRecord?.DCNGuid?.toUpperCase()
      ? ''
      : privateRecord?.DoctorGuid);

  const treatedBy =
    service?.treatedBy ||
    (!privateRecord ||
    privateRecord?.DoctorGuid === multipleDoctorGuid ||
    privateRecord?.DoctorGuid?.toUpperCase() === privateRecord?.DCNGuid?.toUpperCase()
      ? ''
      : privateRecord?.TreatedBy);

  const formatted = {
    ...service,
    doctorGuid,
    treatedBy,
    id: service?.value || '-1',
    licenceNumber: service?.licenceNumber || privateRecord?.LicenceNumber || '',
    duration: service?.duration || '1',
    order: service?.order || 0,
    quantity: service?.quantity || 1,
    amount: service?.amount || 0,
    inStoreCredit: service?.inStoreCredit || 0,
    discount: service?.discount || 0,
    discountUnits: service?.discountUnits || 'percent', // avalible values: "currency" or "percent"
    salesTax: service?.salesTax || 'exempt',
    note: service?.note || '',
    label: service?.label
  };

  const calculated = calculateRowTotal(formatted);

  const updatedService = responceUpdate
    ? formatted
    : {
        ...formatted,
        feeAmount: Number(calculated.feeAmount) || 0,
        gst: Number(calculated.gst) || 0,
        pst: Number(calculated.pst) || 0,
        total: Number(calculated.total) || 0,
        balanceDue: Number(calculated.balanceDue) || 0
      };

  return updatedService;
};
