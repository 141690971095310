import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { POLICY_NUMBER_INFO_URL } from '../../../../../../../config';
import { useValidation } from '../../../hooks/useValidations';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const PolicyNumber = () => {
  const name = inputs.policyNumber.name;
  const { policyNumberValidation } = useValidation();
  const { control, errors } = useFormContext();
  const errorMessage = errors[name]?.message;

  const onInfoClick = (e) => {
    e.preventDefault();
    window.open(POLICY_NUMBER_INFO_URL, '_blank', 'width=800,height=600');
  };

  const labelOptionalContent = <i id={`${name}_infoIcon`} className="pi pi-info-circle cursor-pointer" onClick={onInfoClick} />;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => policyNumberValidation(value) }}
      render={({ field }) => (
        <InputWrapper name={name} label={inputs.policyNumber.label} required errorMessage={errorMessage} labelOptionalContent={labelOptionalContent}>
          <InputText
            ref={field.ref}
            id={field.name}
            className={cx({ 'p-invalid': errorMessage }, 'flex')}
            style={{ width: 'inherit' }}
            type="text"
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default PolicyNumber;
