import moment from 'moment';

export const getAge = (dob) => moment().diff(dob, 'years', false);
export const getAgeAtDOS = (dob, dos) => moment(dos).diff(dob, 'years', false);
export const isOlder18 = (dob) => {
  const diff = moment().diff(moment(dob), 'years');
  return diff >= 18;
};
export const isAllPatientsOlder18 = (patients = []) => {
  if (!patients) return true;
  const patientsList = Array.isArray(patients) ? patients : [patients];
  return patientsList?.every((i) => isOlder18(i.BirthDay));
};
