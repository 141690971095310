import { columnValues } from './columnValues';

export const columnsForPDF = [
  {
    field: 'Summary',
    header: columnValues.lastName.header
  },

  {
    field: columnValues.phn.field,
    header: columnValues.phn.header
  },

  {
    field: columnValues.dob.field,
    header: columnValues.dob.header
  }
];

export const recordsForExcel = (patientsList) => {
  const formattedList = patientsList?.map((i) => {
    return {
      [columnValues.lastName.header]: i.Summary,
      [columnValues.phn.header]: i[columnValues.phn.field],
      [columnValues.dob.header]: i[columnValues.dob.field]
    };
  });

  return formattedList;
};
