import React from 'react';
import RefToByDropdown from '../../../../../modules/TeleplanInvoiceForm/Inputs/Common/RefToByDropdown';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';

const RefToByEditor = ({ rowOptions }) => {
  const { onRefToByChange, disabledReferralInput } = useGroupPickerInputs();
  const value = rowOptions.rowData[inputs.refToBy.name];
  const disabled = disabledReferralInput(rowOptions);

  return (
    <div style={{ width: '90px' }}>
      <RefToByDropdown value={value} disabled={disabled} onChange={(value) => onRefToByChange(value, rowOptions)} />
    </div>
  );
};

export default RefToByEditor;
