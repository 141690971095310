import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs, requiredInputs } from '../../../helpers/inputs';
import { feeCodesDropdownOptions, formTypeValues } from '../../../helpers/dropdownOptions';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { formatFormTypeValue } from '../../../helpers/formTypeActions';
import { isChiropractor, isPhysician } from '../../../../../../config/specialitiesConfig';

const FeeCode = () => {
  const { errors, control, watch } = useFormContext();
  const name = inputs.feeCodes.name;
  const { details } = useSelector((state) => state.user);
  const speciality = details.Speciality;

  const options = () => {
    const initFormType = watch(inputs.formType.name);

    if (formatFormTypeValue(initFormType, speciality) === formTypeValues.firstReport) {
      if (isPhysician(speciality)) {
        return feeCodesDropdownOptions.physician.firstReport;
      }
      if (isChiropractor(speciality)) {
        return feeCodesDropdownOptions.chiropractor.firstReport;
      }
    }

    if (formatFormTypeValue(initFormType, speciality) === formTypeValues.nextReport) {
      if (isPhysician(speciality)) {
        return feeCodesDropdownOptions.physician.nextReport;
      }
      if (isChiropractor(speciality)) {
        return feeCodesDropdownOptions.chiropractor.nextReport;
      }
    }
  };

  const codeDescription = (field) => options()?.find((i) => i.value === field.value)?.description;
  const itemTemplate = (code) => <span className="white-space-normal">{`${code?.label} - ${code?.description}`}</span>;

  const feeCodeInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.feeCodes.label}
        required={!!requiredInputs[name]}
        errorMessage={errors[name]?.message}
        description={codeDescription(field)}
      >
        <Dropdown
          id={field.name}
          inputRef={field.ref}
          value={field.value}
          itemTemplate={itemTemplate}
          panelStyle={{ maxWidth: '200px' }}
          scrollHeight={dropdownScrollHeight()}
          options={options()}
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => feeCodeInput(field)} />;
};

export default FeeCode;
