import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidateGoupPicker } from '../hooks/useValidateGroupPicker';
import { closeToastMessage } from '../../../../../../core/actions/core.action.creators';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { t } from '../../../../../../../service/localization/i18n';
import { steps } from '../../../helpers/steps';
import { useGroupActionButtons } from '../hooks/useGroupActionButtons';

const GroupServicesPickerActionButtons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { toastMessage } = useSelector((state) => state.core);
  const { isFeeValid } = useValidateGoupPicker();
  const { handleNextForServicesPicker } = useGroupActionButtons();

  const { localState, setLocalState } = useFormContext();

  const onBack = () => {
    const isValidFeeCodes = isFeeValid();
    if (isValidFeeCodes) {
      toastMessage.message && dispatch(closeToastMessage(true));
      setLocalState((prevState) => ({ ...prevState, step: steps.form }));
    }
  };

  const onNext = () => {
    handleNextForServicesPicker();
  };

  return (
    <div className="flex gap-3">
      <Button
        id={elementIDs.next}
        type="button"
        className="recordsDataTableActionButtons"
        label={t('Next')}
        disabled={!localState.groupRecords?.length}
        onClick={onNext}
      />

      <Button id={elementIDs.back} type="button" className="p-button-outlined recordsDataTableActionButtons" label={t('Back')} onClick={onBack} />

      <Button
        id={elementIDs.cancel}
        type="button"
        className="p-button-outlined recordsDataTableActionButtons"
        label={t('Cancel')}
        onClick={() => history.goBack()}
      />
    </div>
  );
};

export default GroupServicesPickerActionButtons;
