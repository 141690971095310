import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSubmit } from './useSubmit';

export const useSingIn = () => {
  const methods = useForm({ defaultValues: { username: '', password: '' } });
  const [signInState, setSignInState] = useState({
    step: 1,
    impersonateKey: '',
    isSubmitting: false
  });

  const { onSubmit } = useSubmit({ signInState, setSignInState });

  return { ...methods, signInState, setSignInState, onSubmit };
};
