import React from 'react';
import { usePatientBillingHistoryContext } from '../../../hooks/usePatientBillingHistoryContext';
import { currencyFormat } from '../../../../../../modules/utils/currencyFormat';
import { t } from '../../../../../../service/localization/i18n';

const PaginatorRight = () => {
  const { state } = usePatientBillingHistoryContext();

  return <div className="hidden md:flex md:flex-column gap-1">{`${t('A_R')}: ${currencyFormat(state.totalDue)}`}</div>;
};

export default PaginatorRight;
