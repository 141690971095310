import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { onFormTypeChange, formatFormTypeValue } from '../../../helpers/formTypeActions';
import { formTypeDropdownOptions, formTypeValues } from '../../../helpers/dropdownOptions';
import { feeCodeInit } from '../../../helpers/defaultValues';

const FormType = () => {
  const { control, setValue, watch } = useFormContext();
  // const speciality = watch(inputs.speciality.name);
  const { details } = useSelector((state) => state.user);
  const speciality = details.Speciality;
  const name = inputs.formType.name;

  const onChange = (value) => {
    const firstReport = value === formTypeValues.firstReport ? '1' : '0';
    setValue(inputs.firstReportYN.name, firstReport, { shouldDirty: true });

    // Update fee code
    setValue(inputs.feeCodes.name, feeCodeInit(speciality, value));

    onFormTypeChange({
      value,
      setValue,
      speciality: speciality, //watch(inputs.speciality.name),
      serviceDate: watch(inputs.serviceDate.name)
    });
  };

  const reportInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.formType.label}>
        {/* Dropdown */}
        <Dropdown
          id={field.name}
          value={formatFormTypeValue(field.value, speciality)}
          options={formTypeDropdownOptions}
          onChange={(e) => onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => reportInput(field)} />;
};

export default FormType;
