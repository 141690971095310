import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NoteTable from '../../../mails/components/NoteTable';
import usePatientNotes from '../../hooks/usePatientNotes';
import { rowActionsMenuItems } from './helpers/rowActionsMenuItems';
import MailsLayout from '../../../mails/components/MailsLayout';
import { updateNoteStatus } from '../../../claims/actions/claims.action.creators';
import { setPatientNoteDetails } from '../../../notes/actions/actions';
import { routes } from '../../../../routes/routes';

const PatientNotesInbox = () => {
  const { onDeleteNote, fetchAllNotes, onSearchAll, loadingAllNotes } = usePatientNotes();
  const history = useHistory();
  const dispatch = useDispatch();
  const patientNotes = useSelector((state) => state.notes.patientNotes);

  useEffect(() => {
    // Fetch all notes
    fetchAllNotes({ page: patientNotes.page, pageSize: patientNotes.pageSize });
  }, []);

  const onRowSelect = (note) => {
    updateNoteStatus(note, 1);
    dispatch(setPatientNoteDetails(note));
    history.push(`${routes.patientNotesInbox.path}/view/${note.NoteGuid}`);
  };

  const handleDeleteNote = async (noteGuid) => {
    await onDeleteNote(noteGuid);
  };

  return (
    <MailsLayout>
      {window.innerWidth < 768 && <h3 style={{ textAlign: 'center' }}>Patient Notes</h3>}
      <NoteTable
        notes={patientNotes}
        loading={loadingAllNotes}
        onSearchAll={onSearchAll}
        onSelectRowCallback={onRowSelect}
        fetchNotes={fetchAllNotes}
        onDeleteNote={handleDeleteNote}
        rowActionsMenuItems={rowActionsMenuItems}
      />
    </MailsLayout>
  );
};

export default PatientNotesInbox;
