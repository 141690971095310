import React from 'react';
import Notes from '../../../../components/Widgets/Notes/NotesView/Notes';
import useClaimNotes from './useClaimNotes';

const ClaimNotes = ({ invoiceGuid, renderMenu = true, renderHeader = true, notesListRootStyles }) => {
  const { notes, onCreateNote, onEditNote, onDeleteNote, onSearch, onScroll } = useClaimNotes(invoiceGuid);

  return (
    <Notes
      entityGuid={invoiceGuid}
      notesList={notes.notesList}
      page={notes.page}
      totalPages={notes.totalPages}
      onScroll={onScroll}
      onSearch={onSearch}
      onEditNote={onEditNote}
      onCreateNote={onCreateNote}
      onDeleteNote={onDeleteNote}
      renderMenu={renderMenu}
      renderHeader={renderHeader}
      notesListRootStyles={notesListRootStyles}
    />
  );
};

export default ClaimNotes;
