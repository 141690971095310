import { setClaimFilters, setClaimFiltersWithRequest } from '../claims/actions/claims.action.creators';
import { setDropdownValueWithKeyboard } from './setDropdownWithKeyboard';

export const setFilter = (
  e,
  {
    statuses,
    filters,
    dispatch,
    isTagCloud,
    customPeriod,
    setCustomPeriod,
    submissionCustomPeriod,
    setSubmissionCustomPeriod,
    remittanceCustomPeriod,
    setRemittanceCustomPeriod,
    isSingleDate
  }
) => {
  let value = e.target ? e.target.value : e.value;
  let field = e.field ? e.field : e.target.id;
  field = field.replace('_dd', '');

  switch (field) {
    case 'Period':
      if (isTagCloud) value = statuses.reportPeriods.find((x) => x.cloudTag === value).value;
      if (value === '7' || value === '8') {
        if (customPeriod.from?.length > 0 || customPeriod.to?.length > 0) {
          setCustomPeriod({ from: '', to: '' });
          dispatch(
            setClaimFilters({
              ...filters,
              Period: value,
              PeriodFrom: '',
              PeriodTo: ''
            })
          );
        } else dispatch(setClaimFilters(value, 'Period'));
      } else {
        dispatch(setClaimFilters('', 'ServiceDate'));
        dispatch(setClaimFiltersWithRequest(value, field));
      }
      break;

    case 'from':
      if (isSingleDate) {
        dispatch(setClaimFiltersWithRequest(value));
      } else {
        dispatch(setClaimFiltersWithRequest(value, 'PeriodFrom'));
      }
      break;

    case 'to':
      dispatch(setClaimFiltersWithRequest(value, 'PeriodTo'));
      break;

    case 'SubmissionPeriod':
      if (value === '7' || value === '8') {
        if (submissionCustomPeriod.submissionFrom?.length > 0 || submissionCustomPeriod.submissionTo?.length > 0) {
          setSubmissionCustomPeriod({ submissionFrom: '', submissionTo: '' });
          dispatch(
            setClaimFilters({
              ...filters,
              SubmissionPeriod: value,
              SubmissionPeriodFrom: '',
              SubmissionPeriodTo: ''
            })
          );
        } else dispatch(setClaimFilters(value, 'SubmissionPeriod'));
      } else {
        dispatch(setClaimFilters('', 'SubmissionDate'));
        dispatch(setClaimFiltersWithRequest(value, field));
      }
      break;

    case 'submissionFrom':
      if (isSingleDate) {
        dispatch(setClaimFiltersWithRequest(value));
      } else {
        dispatch(setClaimFiltersWithRequest(value, 'SubmissionPeriodFrom'));
      }
      break;

    case 'submissionTo':
      dispatch(setClaimFiltersWithRequest(value, 'SubmissionPeriodTo'));
      break;

    case 'RemittancePeriod':
      if (value === '7' || value === '8') {
        if (remittanceCustomPeriod.remittanceFrom?.length > 0 || remittanceCustomPeriod.remittanceTo?.length > 0) {
          setRemittanceCustomPeriod({ remittanceFrom: '', remittanceTo: '' });
          dispatch(
            setClaimFilters({
              ...filters,
              RemittancePeriod: value,
              RemittancePeriodFrom: '',
              RemittancePeriodTo: ''
            })
          );
        } else dispatch(setClaimFilters(value, 'RemittancePeriod'));
      } else {
        dispatch(setClaimFilters('', 'RemittanceDate'));
        dispatch(setClaimFiltersWithRequest(value, field));
      }
      break;

    case 'remittanceFrom':
      if (isSingleDate) {
        dispatch(setClaimFiltersWithRequest(value));
      } else {
        dispatch(setClaimFiltersWithRequest(value, 'RemittancePeriodFrom'));
      }
      break;

    case 'remittanceTo':
      dispatch(setClaimFiltersWithRequest(value, 'RemittancePeriodTo'));
      break;

    case 'StatusText':
      const dropdownValue = setDropdownValueWithKeyboard(e, statuses.allStatuses);
      dispatch(setClaimFiltersWithRequest(dropdownValue, field));
      break;

    case 'PatientFilter':
      if (Array.isArray(value)) {
        const multiple = value.map((x) => `${x.PatientGuid}|${x.value}`).join(',');
        dispatch(setClaimFiltersWithRequest(multiple, 'PatientGuid'));
      } else {
        const patient = value.length > 0 ? `${e.target.PatientGuid}|${value}` : '';
        dispatch(setClaimFiltersWithRequest(patient, 'PatientGuid'));
      }
      break;

    case 'DoctorGuid':
      if (value === '-1' || value === -1) {
        dispatch(setClaimFiltersWithRequest(value, field));
      } else {
        dispatch(setClaimFiltersWithRequest(value, field));
        dispatch(setClaimFilters('', 'DoctorName'));
      }
      break;

    default:
      dispatch(setClaimFiltersWithRequest(value, field));
      break;
  }
};

export const setMultiValueFilter = (e, field, { filters, dispatch }) => {
  field = field.replace('_dd', '');
  const value = e.map((x) => x.value).join();
  const combined = {
    ...filters,
    [field]: value
  };

  dispatch(setClaimFiltersWithRequest(combined));
};
