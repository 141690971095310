import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { useValidation } from '../../../hooks/useValidation';
import { useConditions } from '../../../hooks/useConditions';
import { inputs } from '../../../helpers/inputs';
import cx from 'classnames';

const Duration = ({ index }) => {
  const name = `${inputs.services.name}.${index}.${inputs.duration.name}`;
  const { control, isView, clearErrors } = useFormContext();
  const { durationValidation } = useValidation();
  const { isPractitionerSelected } = useConditions();
  const disabled = isView || !isPractitionerSelected;

  const onChange = (e, field) => {
    field.onChange(e);
    if (e.target.value) clearErrors(name);
  };

  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <InputWrapper require={!isView} name={name} label={inputs.duration.label} errorMessage={error?.message}>
          <InputText
            id={name}
            {...field}
            disabled={disabled}
            style={{ width: 'inherit' }}
            className={cx({ 'p-invalid': error?.message })}
            onChange={(e) => onChange(e, field)}
          />
        </InputWrapper>
      )}
      name={name}
      control={control}
      rules={{ validate: (value) => durationValidation(value) }}
    />
  );
};

export default Duration;
