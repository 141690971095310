import React from 'react';
import RecommendedTime from '../../Layouts/RecommendedTime';
import Asterisk from '../../../../../../../components/Misc/Asterisk/Asterisk';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { parseMilitaryTime } from '../../../../../../utils/formatTime';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { t } from '../../../../../../../service/localization/i18n';
import { isRecommendetTime } from '../../../helpers/isRecommendetTime';
import { feeCodeNeedsOnlyStartTime, feeCodeNeedsTime } from '../../../helpers/claimFormValidation';

const TimeBodyTemplate = ({ rowData, field }) => {
  const { isRequiredInput } = useUpdateRecords();
  const isRequired = isRequiredInput(rowData.id, field);
  const feeCode = rowData.fee_code;
  const isStartTimeInput = field === 'service_start';
  const isEndTimeInput = field === 'service_finish';
  const isRecommendet = isRecommendetTime({ feeCodes: [{ value: rowData.fee_code }] });
  const isStartTimeInputRequired = isStartTimeInput && (feeCodeNeedsOnlyStartTime(feeCode) || feeCodeNeedsTime(feeCode));
  const isEndTimeInputRequired = isEndTimeInput && feeCodeNeedsTime(feeCode);

  if (isRecommendet && !rowData[field]) return <RecommendedTime feeCode={rowData.fee_code} />;

  if (isRequired) {
    return (
      <span id={`${elementIDs.invoiceRecordsTimeBodyTemplate}_${field}`} className="p-error">
        {t('Required')}
      </span>
    );
  }

  if (rowData[field] < 0 || rowData[field] === '') {
    return (
      <span id={`${elementIDs.invoiceRecordsTimeBodyTemplate}_${field}`} style={{ color: 'var(--primary-color)' }}>
        ―
      </span>
    );
  }

  return (
    <div className="flex gap-2">
      <span id={`${elementIDs.invoiceRecordsTimeBodyTemplate}_${field}`} style={{ color: 'var(--primary-color)' }}>
        {parseMilitaryTime(rowData[field])}
      </span>
      {(isStartTimeInputRequired || isEndTimeInputRequired) && <Asterisk tooltipId={rowData.id} />}
    </div>
  );
};

export default TimeBodyTemplate;
