import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const ViewPanelSkeleton = () => {
  return (
    <>
      <div className="mt-3">
        <Skeleton height="40px" />
      </div>

      <div className="mt-3">
        <Skeleton height="500px" />
      </div>
    </>
  );
};

export default ViewPanelSkeleton;
