import React from 'react';
import InputWrapper from '../InputWrapper/InputWrapper';
import TimeInput from './TimeInput';

// interface IWrappedTimeInput {
//   name: string,
//   label: string,
//   value: string // time format should be "HH:mm"
//   onChange: (time) => void,

//   ref?: ref
//   id?: string,
//   className?: string,
//   errorMessage?: string,
//   required?: boolean,
//   disabled?: boolean,
//   isInvalid?: boolean,
//   hideLabel?: boolean,
//   labelOptionalContent?: JSX,
//   footerContent?: () => JSX.Element | JSX.Element
//   style?: CSS styles,
//   onBlur?: (time) => void
// }

const WrappedTimeInput = React.forwardRef((props, ref) => {
  return (
    <InputWrapper
      name={props.name}
      label={props.label}
      required={props.required}
      hideLabel={props.hideLabel}
      errorMessage={props.errorMessage}
      footerContent={props.footerContent}
      labelOptionalContent={props.labelOptionalContent}
      style={props.style}
    >
      <TimeInput {...props} ref={ref} />
    </InputWrapper>
  );
});

export default WrappedTimeInput;
