import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import { commonEClaimsActions, patientButton } from '../../../../helpers/rowActions';
import { inputs } from '../../helpers/inputs';

const ViewClaimOptionButton = () => {
  const menu = useRef(null);
  const history = useHistory();
  const { getValues, setLocalState } = useFormContext();
  const eClaim = getValues();
  const patientGuid = getValues(inputs.patientGuid.name);
  const patientActionButton = patientButton({ patientGuid, history, replaceHistory: true });

  const setConfirmDialog = (data) => {
    setLocalState((prevState) => ({ ...prevState, archiveEClaimDialog: { showDialog: true, eClaim: data } }));
  };

  const eClaimButtons = commonEClaimsActions({ eClaim, history, replaceHistory: true, setConfirmDialog });
  const items = [...eClaimButtons, patientActionButton];

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        className="p-button-text p-button-rounded"
        icon="pi pi-ellipsis-v"
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </>
  );
};

export default ViewClaimOptionButton;
