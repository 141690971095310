import React from 'react';
import { useFormContext } from 'react-hook-form';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';
import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { inputs } from '../../helpers/inputs';
import { isRebillStatus } from '../../../../../utils/getStatus';

const CardTitle = () => {
  const { isNew, watch } = useFormContext();
  const recordNumber = watch(inputs.recordNumber.name);
  const sequenceNumber = watch(inputs.sequenceNum.name);
  const status = watch(inputs.status.name);
  const recordNum = sequenceNumber ? `#${trimLeftZeros(sequenceNumber)} (s)` : `#${trimLeftZeros(recordNumber)} (o)`;
  const editText = isRebillStatus(status) ? t('Rebill_WSBC_Report') : t('Edit_WSBC_Report');

  return (
    <div id={elementIDs.wsbcReportTitle} className="text-center text-2xl font-bold">
      {isNew ? t('Create_WSBC_Report') : `${editText} ${recordNum}`}
    </div>
  );
};

export default CardTitle;
