import { store } from '../../../store';
import { prefsCodes } from '../../config/prefsCodesConfig';
import { multipleDoctorGuid, NA_DoctorGuid } from '../../config/defaultValuesConfig';
import actions from './preferences.action.types';
import preferencesForNA from '../../../mocks/db/preferences/preferencesForNA.json';
import currentUserPreferences from '../../../mocks/db/preferences/currentUserPreferences.json';
import preferencesForSimpsonDavid from '../../../mocks/db/preferences/preferencesForSimpsonDavid.json';
import preferencesForDiamondBeata from '../../../mocks/db/preferences/preferencesForDiamondBeata.json';
import preferencesForSimpsonKelsey from '../../../mocks/db/preferences/preferencesForSimpsonKelsey.json';

export const parsedDataTablePrefs = (prefs) => {
  const parsed = prefs?.map((i) => {
    if (i.label === 'display') {
      const updatedContent = i.content?.map((x) => {
        if (x.label === prefsCodes.dataTable) {
          return { ...x, value: JSON.parse(x.value) };
        }
        return x;
      });
      return { ...i, content: updatedContent };
    }
    return i;
  });

  return parsed;
};

export const getPreferencesResponseAction = (responseData, callback) => {
  if (callback) return callback(responseData.prefs);

  store.dispatch({
    type: actions.GET_CURRENT_USER_PREFS_SUCCESS,
    results: { ...responseData, prefs: parsedDataTablePrefs(responseData.prefs) }
  });
};

export const getPreferencesResponseActionForTests = (userId, callback) => {
  const prefs = callback ? getPrefersForTests(userId) : currentUserPreferences;
  getPreferencesResponseAction(prefs, callback);
};

export const getPrefersForTests = (userId) => {
  if (userId) {
    const DCNGuid = store.getState().clinic.details.DCNGuid;
    const id = userId.toLowerCase();
    const practitionerPreferences = {
      'd4b6e600-de4e-414c-88d7-1f22c4697b89': preferencesForSimpsonDavid,
      '1542875f-6b30-4aff-bd59-4c9016d33830': preferencesForDiamondBeata,
      'ee7b6017-6a19-430b-bd08-4a7a98875e3e': preferencesForSimpsonKelsey,
      [DCNGuid]: preferencesForNA,
      [NA_DoctorGuid]: preferencesForNA,
      [multipleDoctorGuid]: preferencesForNA
    };
    return practitionerPreferences[id] || currentUserPreferences;
  } else {
    return currentUserPreferences;
  }
};
