import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';
import { stringFormat } from '../../../../../utils/stringFormat';
import { inputs } from '../../helpers/inputs';
import { patientFullName } from '../../../../../utils/patientFullName';
import { findIndex } from 'lodash';
import { t } from '../../../../../../service/localization/i18n';

const ValidationUnitsDialog = () => {
  const { localState, setLocalState, isGroup } = useFormContext();
  const { showDialog } = localState.unitsValidationDialog;

  if (!showDialog) return null;

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      unitsValidationDialog: localStateInitValues.unitsValidationDialog
    }));
  };

  const actionButtons = <Button label={t('Ok')} onClick={onHide} />;

  const messageLayout = () => {
    if (isGroup) {
      return (
        <div className="text-lg flex flex-column gap-4">
          {localState.groupRecords?.map((i) => {
            const groupRowId = i[inputs.groupRowId.name];
            const patient = i[inputs.patient.name]?.[0];
            const message = getMessageForGroup(i);
            const rowIndex = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: groupRowId });

            if (!message) return null;
            return (
              <div className="flex flex-column gap-2" key={i[inputs.groupRowId.name]}>
                <h5 className="m-0">{`Line ${rowIndex + 1} - ${patientFullName(patient)}`}</h5>
                {`${message}`}
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="text-lg flex flex-column gap-2">
        {localState.records?.map((i) => {
          const message = getMessageForRecordsStep(i);
          if (!message) return null;
          return (
            <div key={i.id}>
              {/* Add bullet point before each message */}
              <span>&#8226; </span>
              {message}
            </div>
          );
        })}
      </div>
    );
  };

  const getMessageForRecordsStep = (rowData) => {
    const units = Number(rowData.units);
    const maxUnits = Number(rowData.max_unit);

    if (maxUnits !== -1) {
      if (units === 0) return stringFormat(t('Code_X_min_units'), rowData.fee_code);
      if (units > maxUnits) return stringFormat(t('Code_X_max_units'), rowData.fee_code, maxUnits);
    }
    return '';
  };

  const getMessageForGroup = (rowData) => {
    const feeCodes = rowData[inputs.feeCodes.codeType];
    const feeCode = feeCodes?.[0];
    const units = Number(rowData[inputs.units.name]);
    const maxUnits = feeCode?.max_unit ? Number(feeCode?.max_unit) : undefined;

    if (maxUnits && maxUnits !== -1) {
      if (units === 0) return stringFormat(t('Code_X_min_units'), feeCode.value);
      if (units > maxUnits) return stringFormat(t('Code_X_max_units'), feeCode.value, maxUnits);
    }
  };

  return (
    <Dialog
      header={t('Warning')}
      visible={showDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      {messageLayout()}
    </Dialog>
  );
};

export default ValidationUnitsDialog;
