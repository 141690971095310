import React from 'react';
import { Avatar } from 'primereact/avatar';
import { isNumeric } from '../../utils/isNumeric';
import { colorsCollection } from '../../../themes/colorsCollection';

// const colors = [
//   '#f44336',  //red
//   '#e91e63',  //pink
//   '#9c27b0',  //purple
//   '#3f51b5',  //indigo
//   '#2196f3',  //blue
//   '#00bcd4',  //cyan
//   '#009688',  //teal
//   '#4caf50',  //green
//   '#8bc34a',  //lightGreen
//   '#ff9800',  //orange
//   '#795548',  //brown
//   '#607d8b'  //blueGrey
// ];

// const colors = [
//   '#689F38',  //success
//   '#2196F3',  //info
//   '#FBC02D',  //warning
//   '#9C27B0',  //help
//   '#D32F2F',  //danger
//   '#a0a0a0',  //gray
// ];

const CustomAvatar = ({ id, label, style, className = '', showStatus = false, shape = 'circle', color }) => {
  // shape - 	Shape of the element, valid options are "square" and "circle".

  const seed = id === 'u' ? 8 : isNumeric(id) ? id : parseInt(`0x${id}`);
  const idx = seed % colorsCollection.length; // Math.round(8* Math.random());
  const colors = colorsCollection.map((i) => i[Object.keys(i)]);

  return (
    <Avatar
      className={className}
      label={label}
      shape={shape ? shape : ''}
      style={{
        backgroundColor: showStatus ? '' : color || colors[idx],
        color: showStatus ? '' : '#ffffff',
        fontWeight: 700,
        width: '3em',
        height: '3em',
        ...style
      }}
    />
  );
};

export default CustomAvatar;
