import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Context from '../../../../Context';
import { Dropdown } from 'primereact/dropdown';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { rowHeight } from './reviewPanelHelpers/columns';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';

const ReviewPractitioner = ({ params, records, setIsReviewDirty, setRecords }) => {
  const { isMobileOnly, isLandscape } = useSelector((state) => state.core.window);
  const { users } = useContext(Context);
  const [doctor, setDoctor] = useState(null);
  const [practitionersList, setPractitionersList] = useState([]);

  // initial value for doctor
  useEffect(() => {
    const currentRow = { ...records[params.rowIndex] };
    const currentDoc = users?.find((i) => i.DoctorGuid === currentRow['doctorGuid']);
    currentDoc
      ? setDoctor({ doctorGuid: currentDoc?.DoctorGuid, label: `${currentDoc.LastName}, ${currentDoc.FirstName}` })
      : setDoctor({ doctorGuid: '', label: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.rowData?.doctorGuid]);

  // init list of practitioners
  useEffect(() => {
    const doctor_list = users
      ? users
          .filter((x) => x.PractitionerNumber !== '0')
          .map((x) => {
            return { doctorGuid: x.DoctorGuid, label: `${x.LastName}, ${x.FirstName}` };
          })
      : [];
    setPractitionersList(doctor_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectDoctor = (doc) => {
    let data = [...records];
    let currentRow = { ...data[params.rowIndex] };
    currentRow['doctorGuid'] = doc?.doctorGuid;
    currentRow['treatedBy'] = doc?.label;

    const updatedData = data.map((i) => (i.id === currentRow.id ? currentRow : i));
    setRecords(updatedData);
    setIsReviewDirty(true);
  };

  return (
    <>
      <Dropdown
        id={elementIDs.reviewPractitioner}
        className="salesTaxEditor"
        optionLabel="label"
        value={doctor}
        options={practitionersList}
        scrollHeight={dropdownScrollHeight()}
        placeholder={t('N_A')}
        style={{ width: isMobileOnly && !isLandscape ? '14em' : '15em', height: rowHeight, zIndex: '69' }}
        onChange={(e) => onSelectDoctor(e.value)}
      />
    </>
  );
};

export default ReviewPractitioner;
