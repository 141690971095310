import MailsLayout from "../../mails/components/MailsLayout";
import RemittancesTable from "./RemittancesTable";

const Remittances = () => {
  return (
    <MailsLayout>
      {window.innerWidth < 768 && <h3 style={{ textAlign: 'center' }}>Remittances</h3>}
      <RemittancesTable />
    </MailsLayout>
  );
};

export default Remittances;
