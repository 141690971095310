import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { dataTableActionTooltip } from '../../../../../helpers/tooltips';
import { t } from '../../../../../../../../../service/localization/i18n';
import { v4 as uuidv4 } from 'uuid';

const DuplicateRow = ({ rowOptions }) => {
  const { setLocalState } = useFormContext();
  const { isBonusFeeCode, applySameDurationForAllRecords } = useGroupPickerInputs();
  const { requiredInputsForDuplicateRecord, updatedValuesForDuplicateRecord } = useValidateGoupPicker();
  const disabled = isBonusFeeCode(rowOptions.rowData);
  const tooltip = dataTableActionTooltip(t('Duplicate_group_record'), rowOptions.rowData);

  const onClick = () => {
    setLocalState((prevState) => {
      const rowId = uuidv4();

      const cloneItem = {
        ...rowOptions.rowData,
        ...updatedValuesForDuplicateRecord,
        [inputs.groupRowId.name]: rowId,
        [inputs.startTime.name]: '',
        [inputs.endTime.name]: '',
        [inputs.duration.name]: 0,
        [inputs.units.name]: 1,
        [inputs.comment.name]: ''
      };

      // Insert the duplicated item right after the original item
      prevState.groupRecords.splice(rowOptions.rowIndex + 1, 0, cloneItem);

      let updatedGroupRecords = [...prevState.groupRecords]; // Create a new array to trigger reactivity

      // CMO-2765 - Calculate end time if same duration is on and user created duplicate claim
      if (prevState.applyToAllTime) {
        updatedGroupRecords = applySameDurationForAllRecords(updatedGroupRecords);
      }

      return {
        ...prevState,
        groupRecords: updatedGroupRecords,

        // Set required inputs
        groupRecordsRequiredInputs: {
          ...prevState.groupRecordsRequiredInputs,

          // Set original row time inputs as required
          [rowOptions.rowData[inputs.groupRowId.name]]: requiredInputsForDuplicateRecord,

          // Set duplicated row time inputs as required
          [rowId]: requiredInputsForDuplicateRecord
        }
      };
    });
  };

  return (
    <div className="flex justify-content-end w-full">
      <Button
        className="p-button-text p-button-rounded"
        icon="pi pi-clone"
        tooltip={tooltip}
        tooltipOptions={{ position: 'top', showOnDisabled: true }}
        tabIndex={-1}
        disabled={disabled}
        onClick={onClick}
      />
    </div>
  );
};

export default DuplicateRow;
