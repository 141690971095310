export const checkAppliedFilters = (filters) => {
  const suspect = [-1, -2, '', '-1', '-2', 'All', 'All Statuses', 'derived', 'True', filters.freetext, filters.DoctorGuid];
  const DOSSuspect = [...suspect, filters.PeriodFrom, filters.PeriodTo, filters.Period];

  const appliedFilters = filters
    ? Object.keys(filters).filter((key) => {
        let defaultValues = '';

        if (filters.ServiceDate?.length > 0) {
          defaultValues = checkSuspect(DOSSuspect, defaultValues, filters, key);
        } else {
          defaultValues = checkSuspect(suspect, defaultValues, filters, key);
        }

        return defaultValues;
      })
    : [];

  return appliedFilters;
};

const checkSuspect = (suspect, defaultValue, filters, key) => {
  if (suspect.find((i) => filters[key] === i)) {
    defaultValue = '';
  } else defaultValue = filters[key];

  return defaultValue;
};
