import React from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from '../../actions/auth.actions.creators';
import { useEffect } from 'react';
const query_str = window.location.search ? window.location.search.replace('?', '') : '';

const Redirect = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(redirect(query_str));
  }, []);
  return (
    <div className="login-body">
      <div className="login-topbar"></div>
      <div className="flex justify-content-center align-items-center text-white" style={{ height: '80vh' }}>
        <h1>Please wait while we retrieve your data.</h1>
      </div>
    </div>
  );
};

export default Redirect;
