import React from 'react';
import AddressLayout from '../../../../../modules/common/components/AddressLayout';
import { inputs } from '../../../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/inputs';
import { formatPhoneNumber } from '../../../../../modules/utils/formatPhoneNumber';
import { patientFullName } from '../../../../../modules/utils/patientFullName';
import { t } from '../../../../../service/localization/i18n';

const BillToSection = ({ data }) => {
  const billTo = data[inputs().billTo.name] && Array.isArray(data[inputs().billTo.name]) ? data[inputs().billTo.name][0] : data[inputs().billTo.name];

  return (
    <div>
      <div className="bill-to">{t('Bill_To').toUpperCase()}</div>
      <div className="flex col-12 p-0 mb-3 font-bold">
        {patientFullName(billTo)}
        {/* {`${billTo?.LastName}, ${billTo?.FirstName}`} */}
      </div>

      <div className="flex">
        <div className="addressWrap">
          <AddressLayout data={billTo} />

          {(billTo?.Phone || billTo?.Email) && (
            <div className="mt-3">
              {billTo?.Phone && <div className="mb-1">{formatPhoneNumber(billTo?.Phone)}</div>}
              <div className="mb-1">{billTo?.Email}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillToSection;
