import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const PreviewDialog = ({ url, visible, onHide }) => {
    return (
        <Dialog
            header="Preview"
            visible={visible}
            style={{ width: '95vw', height: '100vh' }}
            onHide={onHide}
            footer={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <a href={url} target="_blank" rel="">
                    <Button
                        type="button"
                        icon="pi pi-external-link"
                        label="Link"
                        tooltip="Go to Report"
                        tooltipOptions={{ position: 'top' }}
                    />
                    </a>
                    <Button type="button" label="Close" outlined onClick={onHide} style={{margin: '0'}} />
                </div>
                
                
            }
        >
            <iframe
                src={url}
                style={{ width: '100%', height: '80vw', border: 'none' }}
                title="Preview"
            />
        </Dialog>
    );
};

export default PreviewDialog;
