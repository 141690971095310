import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { getRemittanceLinkFilter } from '../../utils/getLink';
import { activePractitionerStatuses } from '../../config/statuses';
import PreviewDialog from './PreviewDialog';

const PopupWithRadioButtons = ({ remittance }) => {
    const { isMobile } = useSelector((state) => state.core.window);
    const userList = useSelector((state) => state.clinic?.members) || [];
    const activeOnlyMembers = userList?.filter((i) => 
        i.PractitionerNumber.length > 1 && 
        activePractitionerStatuses.includes(Number(i.UserStatus)));
    const memberNamesSet = new Set(activeOnlyMembers?.map((item) => item.FullName));
    const fullNames = [...memberNamesSet];    
    const payeeList = [...new Set(userList.map(user => user?.PayeeNumbers).flat().filter(x=>x.length>1))];

    const [visible, setVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');

    const handleOpenModal = () => {
        setVisible(true);
    };

    const handleCloseModal = () => {
        setVisible(false);
    };

    const handleSubmit = () => {
        let result;
        let type;
        if (fullNames.includes(selectedOption)) {
            const selectedUser = userList.find(user => user.FullName === selectedOption);
            result = selectedUser?.DCNGuid;
            type = 'unum';
        } else if (payeeList.includes(selectedOption)) {
            result = selectedOption;
            type = 'pnum';
        }

        const url = getRemittanceLinkFilter(remittance, result, type);
        setPreviewUrl(url);
        setVisible(false); // Close the radio button dialog
        setPreviewVisible(true); // Open the preview dialog
    };

    return (
        <div className='flex justify-content-center'>
            <Button 
                icon="pi pi-eye"
                className="p-button-rounded"
                tooltip="Remittance report records filtered by practitioner"
                tooltipOptions={{ position: 'top' }}
                onClick={handleOpenModal} 
            />

            {/* Radio Buttons Popup */}
            <Dialog
                header={<h3 style={{ textAlign: 'center', margin: 0 }}>Remittance Report Filters</h3>}
                visible={visible}
                style={{ width: isMobile ? '90vw' : '50vw', maxWidth: "500px" }}
                onHide={handleCloseModal}
                dismissableMask
            >
                <div className='p-grid p-dir-col'>
                    <div className='flex' style={{ flexDirection: 'column' }}>
                        <div className='p-col px-2' style={{ margin: 'auto', padding: isMobile ? '1rem 0' : '0' }}>
                            <p style={{ fontWeight: "bold" }}>Filter report by service provider</p>
                            {fullNames.map((option, index) => (
                                <div key={index} className="p-field-radiobutton" style={{ margin: '1rem 1.5rem' }}>
                                    <RadioButton 
                                        inputId={option} 
                                        name="option" 
                                        value={option}
                                        onChange={(e) => setSelectedOption(e.value)} 
                                        checked={selectedOption === option} 
                                    />
                                    <label htmlFor={option} style={{ marginLeft: '0.5rem' }}>{option}</label>
                                </div>
                            ))}
                        </div>
                        <Divider layout={'horizontal'} style={{ margin: '1rem 0' }} />
                        <div className='p-col px-2' style={{ margin: 'auto', padding: isMobile ? '1rem 0' : '0' }}>
                            <p style={{ fontWeight: "bold" }}>Filter report by payee number</p>
                            {payeeList.map((option, index) => (
                                <div key={index} className="p-field-radiobutton" style={{ margin: '1rem 3.5rem' }}>
                                    <RadioButton 
                                        inputId={option} 
                                        name="option" 
                                        value={option}
                                        onChange={(e) => setSelectedOption(e.value)} 
                                        checked={selectedOption === option} 
                                    />
                                    <label htmlFor={option} style={{ marginLeft: '0.5rem' }}>{option}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='p-col py-2 flex justify-content-center'>
                        <Button label="Display Records" onClick={handleSubmit} style={{ marginRight: '10px' }} />
                        <Button type="button" outlined label="Close" onClick={handleCloseModal} />
                    </div>
                </div>
            </Dialog>

            {/* Preview Dialog */}
            <PreviewDialog
                url={previewUrl}
                visible={previewVisible}
                onHide={() => setPreviewVisible(false)}
            />
        </div>
    );
};

export default PopupWithRadioButtons;
