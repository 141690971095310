import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputCharacterCounter, InputWrapper } from '../../Inputs';
import { InputTextarea } from 'primereact/inputtextarea';

import { setDebitRequest } from '../../../modules/claims/actions/claims.action.creators';
import { currencyFormat } from '../../../modules/utils/currencyFormat';
import { formatDate } from '../../../modules/utils/formatDate';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';
import * as yup from 'yup';

const debitRequestMessageMaxLength = 400;
const validationSchema = () => {
  return yup.object().shape({
    reason: yup
      .string()
      .max(debitRequestMessageMaxLength, String.format(t('The_message_must_not_be_longer_than_X_characters'), debitRequestMessageMaxLength))
      .required(t('Mandatory_field.1'))
  });
};

const DebitRequestDialog = ({ claimRecord, PatientGuid, showDebitConfirm, setShowDebitConfirm, setClaimDataForAction }) => {
  const dispatch = useDispatch();
  const { isFetchingDebitRequest } = useSelector((state) => state.claims);

  const { handleSubmit, formState, control, reset, register } = useForm({
    defaultValues: { reason: '' },
    resolver: yupResolver(validationSchema())
  });

  const reason = useWatch({ control, name: 'reason' });
  const errorMessage = formState.errors['reason']?.message;

  const closeDebitRequestDialog = () => {
    setShowDebitConfirm(false);
    reset();
  };

  const onSubmit = (params) => {
    dispatch(setDebitRequest(params, PatientGuid, claimRecord.InvoiceGuid, closeDebitRequestDialog));
  };

  const onDebitRequestCancel = () => {
    setClaimDataForAction && setClaimDataForAction({});
    closeDebitRequestDialog();
  };

  const inputFooterContent = () => <InputCharacterCounter text={reason} countLimit={debitRequestMessageMaxLength} />;

  const header = (
    <div className="flex justify-content-between w-full">
      <div>{t('Debit_Request').toUpperCase()}</div>
      <div className="pr-5">{`#${claimRecord?.SequenceNumber}`}</div>
    </div>
  );

  return (
    <Dialog
      header={header}
      visible={showDebitConfirm}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '30vw' }}
      onHide={onDebitRequestCancel}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="mb-3">
          <span>
            {String.format(
              t('Enter_the_reason_why_the_claim_was_submitted_in_error'),
              claimRecord?.SequenceNumber,
              formatDate(claimRecord?.ServiceDate),
              claimRecord?.FullName,
              currencyFormat(claimRecord?.FeeTotal)
            )}
          </span>
        </div>

        <InputWrapper name="reason" errorMessage={errorMessage} footerContent={inputFooterContent} style={{ height: 'auto' }}>
          <InputTextarea
            rows={1}
            cols={30}
            autoResize
            // autoFocus // Commented because app crash (check VER-689 - Money table - Debit - displaying error)
            maxLength={debitRequestMessageMaxLength}
            className={cx({ 'p-invalid': errorMessage })}
            {...register('reason')}
          />
        </InputWrapper>

        <div className="my-3">
          <span>{`${t('Note')}:`}</span>
        </div>

        <div className="mb-3">
          <span>{`(1) ${t('If_you_want_to_cancel_in_process_claim_consider_calling_teleplan_support_line')}`}</span>
        </div>

        <div className="mb-3">
          <span>{`(2) ${t('If_the_claim_was_paid_use_this_feature_to_reverse_it')}`}</span>
        </div>

        <div className="mb-3">
          <span>{`(3) ${t('If_you_submit_debit_request_using_this_feature_the_claim_will_be_cancelled_or_reversed')}`}</span>
        </div>

        <div>
          <span>{`(4) ${t('No_need_to_return_payment_to_MSP_for_a_reversed_claim')}`}</span>
        </div>

        <div className="flex pt-5 gap-3">
          <Button type="submit" label={t('Submit_Debit_Request')} loading={isFetchingDebitRequest} style={{ minWidth: '185px' }} />

          <Button type="button" className="p-button-outlined" label={t('Cancel')} onClick={onDebitRequestCancel} />
        </div>
      </form>
    </Dialog>
  );
};

export default DebitRequestDialog;
