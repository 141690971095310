import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputSwitch } from 'primereact/inputswitch';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';

const IsPrimary = () => {
  const name = inputs.isPrimary.name;
  const { control, setValue, isEdit } = useFormContext();

  const onChange = (checked, field) => {
    field.onChange(checked);
    setValue(inputs.insurerID.name, 'NA');
    setValue(inputs.insurerNameEN.name, '');
  };

  const saveAsDraftInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.isPrimary.label} style={{ height: 'auto' }} dir="row">
        <InputSwitch type="checkbox" id={field.name} checked={field.value} disabled={isEdit} onChange={(e) => onChange(e.value, field)} />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => saveAsDraftInput(field)} />;
};

export default IsPrimary;
