import React from 'react';
import { commonInputs } from '../../../../../modules/config/commonInputsConfig';
import { patientFullNameWithAge } from '../../../../../modules/utils/patientFullName';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const ServiceDateSection = ({ data }) => {
  const fullName = () => {
    return data[commonInputs.patients.descriptionName] ? patientFullNameWithAge(data[commonInputs.patients.descriptionName]?.[0]) : t('N_A');
  };

  return (
    <div className="invoiceSectionWrap">
      <div className="flex align-items-center bill-to mb-2">
        <div className="serviceDate">{`${t('Service_date')}:`}</div>
        <div className="w-full text-right">{moment(data?.ServiceDate).format('MM/DD/YYYY')}</div>
      </div>

      <div className="flex align-items-start mb-2">
        <div className="mr-2 font-semibold">{`${t('Patient.1')}:`}</div>
        <div className="w-full text-right font-bold">
          {data[commonInputs.patients.descriptionName]?.length && fullName()}
          {!data[commonInputs.patients.descriptionName]?.length && `${data?.LastName}, ${data?.FirstName}`}
        </div>
      </div>
    </div>
  );
};

export default ServiceDateSection;
