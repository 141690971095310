import React from 'react';
import LeftCardWrap from './LeftCardWrap';
import RightCardWrap from './RightCardWrap';
import cx from 'classnames';
import './FormWithCatalogsWrap.scss';

// interface IFormWithCatalogsWrap {
//   leftCardContent?: JSX // content for left card
//   leftCardWidth?: string // width of the left card. Example - "200px" | "20rem"
//   rightCardContent?: JSX // content for right card
//   hideRightCard?: boolean // define to hide right card
//   leftCardActionButtons?: JSX // action buttons for left card. Dont forget add tag "form" for buttons with "submit" type if you work with form
//   inverseCards?: boolean // swap the cards (left card to the right side, right card to the left side)
//   showInDialog?: boolean
// }

const CardsWrap = ({ children, showInDialog }) => {
  return (
    <div className={cx('flex justify-content-center w-full relative', showInDialog ? 'h-full' : 'form_with_catalogs_wrap')}>
      <div className="flex justify-content-center form_with_catalogs_container">{children}</div>
    </div>
  );
};

const FormWithCatalogsWrap = ({
  leftCardContent,
  rightCardContent,
  leftCardActionButtons,
  inverseCards,
  leftCardWidth,
  hideRightCard,
  showInDialog
}) => {
  if (inverseCards) {
    return (
      <CardsWrap showInDialog={showInDialog}>
        <RightCardWrap>{rightCardContent}</RightCardWrap>

        <LeftCardWrap leftCardActionButtons={leftCardActionButtons}>{leftCardContent}</LeftCardWrap>
      </CardsWrap>
    );
  }

  return (
    <CardsWrap showInDialog={showInDialog}>
      <LeftCardWrap leftCardActionButtons={leftCardActionButtons} leftCardWidth={leftCardWidth}>
        {leftCardContent}
      </LeftCardWrap>

      <RightCardWrap hideRightCard={hideRightCard}>{rightCardContent}</RightCardWrap>
    </CardsWrap>
  );
};

export default FormWithCatalogsWrap;
