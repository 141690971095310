import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NoteEditorWithFormControl } from '../../../../Inputs';
import { inputs } from '../helpers/inputs';

const Note = () => {
  const name = inputs.note.name;
  const { control } = useFormContext();

  return <NoteEditorWithFormControl control={control} name={name} />;
};

export default Note;
