import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Gender as GenderPicker, InputWrapper } from '../../../../../../../components/Inputs';
import { inputs } from '../../../helpers/inputs';

const Gender = () => {
  const name = inputs.gender.name;
  const { errors, control } = useFormContext();

  const genderInput = (field) => {
    return (
      <InputWrapper name={name} label={inputs.gender.label} required errorMessage={errors[name]?.message}>
        <GenderPicker name={name} value={field.value} errorMessage={errors[name]?.message} onChange={(value) => field.onChange(value)} />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => genderInput(field)} />;
};

export default Gender;
