import React from 'react';
import { Card } from 'primereact/card';
import { ScrollPanel } from 'primereact/scrollpanel';
import './FormWithCatalogsWrap.scss';

const ActionButtonsWrap = ({ children }) => {
  return <div className="flex mt-4 gap-2 left_card_action_buttons">{children}</div>;
};

const LeftCardWrap = ({ children, leftCardActionButtons, leftCardWidth }) => {
  const showMobileView = !process.env.REACT_APP_CYPRESS;

  return (
    <>
      {showMobileView && (
        <div className="left_card_mobile_form_container">
          <Card>
            {children}
            <ActionButtonsWrap>{leftCardActionButtons}</ActionButtonsWrap>
          </Card>
        </div>
      )}

      <div className="left_card_desktop_form_container" style={{ maxWidth: leftCardWidth, minWidth: leftCardWidth }}>
        <Card className="left_card_content">
          <ScrollPanel className="left_card_scroll_panel">
            <div className="m-0">{children}</div>
          </ScrollPanel>
          <ActionButtonsWrap>{leftCardActionButtons}</ActionButtonsWrap>
        </Card>
      </div>
    </>
  );
};

export default LeftCardWrap;
