import React, { useContext } from 'react';

import Context from '../../../../../Context';
import { inputs } from '../../../../../modules/claims/components/WSBCReport/helpers/inputs';
import { getRadioButtonValue } from '../helpers/getRadioButtonValue';
import { formatPhoneNumber } from '../../../../../modules/utils/formatPhoneNumber';
import { formatFormTypeValue } from '../../../../../modules/claims/components/WSBCReport/helpers/formTypeActions';
import { formTypeDropdownOptions, patientPeriodDropdownOptions } from '../../../../../modules/claims/components/WSBCReport/helpers/dropdownOptions';
import { specPHN } from '../../../../../modules/config/defaultValuesConfig';
import { commonInputs } from '../../../../../modules/config/commonInputsConfig';
import { t } from '../../../../../service/localization/i18n';
import moment from 'moment';

const DemographicsInfo = () => {
  const { data } = useContext(Context);
  const termPrimary = patientPeriodDropdownOptions.find((i) => i.value === data[inputs.termPrimary.name])?.label;
  const type = formatFormTypeValue(data[inputs.formType.name], data[inputs.speciality.name]);
  const formType = formTypeDropdownOptions.find((i) => i.value === type)?.label;

  const getPateientData = () => {
    if (data[inputs.patient.name]) {
      if (Array.isArray(data[inputs.patient.name])) return data[inputs.patient.name][0];
      return data[inputs.patient.name];
    }

    return {};
  };

  const patient = getPateientData();

  const workersMailingAddress = () => {
    const addressLine = patient?.AddressLine;
    const city = patient?.City;
    const zip = patient?.Zip;

    if (city) return `${addressLine} ${zip || ''}, ${city}`;
    return `${addressLine} ${zip || ''}`;
  };

  const mailingAddressLayout = () => {
    if (!patient?.AddressLine && !patient?.City) return <span className="p-error">{t('Mandatory')}</span>;
    if (!patient?.AddressLine && patient?.City) return <span className="p-error">{t('Missing_address')}</span>;
    if (patient?.AddressLine && !patient?.City) return <span className="p-error">{t('Missing_city')}</span>;
    return <span className="coloredText">{workersMailingAddress()}</span>;
  };

  const workersPhoneLayout = () => {
    if (patient?.Phone) {
      return <span className="coloredText">{formatPhoneNumber(patient?.Phone)}</span>;
    }
    return <span>&nbsp;</span>;
  };

  const workersPHNLayout = () => {
    if (Number(data[commonInputs.submitWithGenericPHN.name]) === 1) {
      return <span className="coloredText">{specPHN}</span>;
    }

    if (patient?.PHN && patient?.PHN !== 'N/A') {
      return <span className="coloredText">{patient?.PHN}</span>;
    }

    return <span className="p-error">{t('Mandatory')}</span>;
  };

  return (
    <div className="text-sm">
      <div className="pb-1">
        <table>
          <tbody>
            <tr className="flex">
              <td className="font-semibold" style={{ minWidth: '80px' }}>
                {inputs.formType.label}:
              </td>
              <td>{formType}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <table className="demographicsInfoTablePrint ">
        <tbody>
          <tr>
            <td style={{ width: '55%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Date_of_service')}</span>
                <span className="coloredText">{moment(data[inputs.serviceDate.name]).format('YYYY/MM/DD')}</span>
              </div>
            </td>

            <td style={{ width: '22.5%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Day_of_birthday.3')}</span>
                <span className="coloredText">{moment(patient?.BirthDay)?.format('YYYY/MM/DD')}</span>
              </div>
            </td>

            <td colSpan="2" style={{ width: '22.5%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('WCB_claim_number')}</span>
                {data[inputs.claimNumber.name] ? <span className="coloredText">{data[inputs.claimNumber.name]}</span> : <div>&nbsp;</div>}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Employers_name')}</span>
                <span className="coloredText">{data[inputs.employer.name]}</span>
              </div>
            </td>

            <td colSpan="3">
              <div className="flex flex-column">
                <span className="font-semibold">{t('Workers_last_name')}</span>
                <span className="coloredText">{patient?.LastName}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td style={{ width: '55%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Employers_telephone_number')}</span>
                <span className="coloredText">{formatPhoneNumber(data[inputs.phone.name])}</span>
              </div>
            </td>

            <td style={{ width: '13%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('First_Name')}</span>
                <span className="coloredText">{patient?.FirstName}</span>
              </div>
            </td>

            <td style={{ width: '13%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Middle_Initial')}</span>
                {patient?.MidName ? <span className="coloredText">{patient?.MidName ? patient?.MidName?.charAt(0) : ''}</span> : <div>&nbsp;</div>}
              </div>
            </td>

            <td style={{ width: '13%' }}>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Gender')}</span>
                <span className="coloredText">{patient?.Sex}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold">{t('Operating_Location_Address')}</span>
                <span className="coloredText">{`${data[inputs.address.name]}, ${data[inputs.city.name]}`}</span>
              </div>
            </td>
            <td colSpan="3">
              <div className="flex flex-column">
                <span className="font-semibold">{t('Mailing_address')}</span>
                {mailingAddressLayout()}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold"> {`${t('Date_of_injury.2')} (yyyy/mm/dd)`}</span>
                <span className="coloredText">{moment(data[inputs.injuryDate.name]).format('YYYY/MM/DD')}</span>
              </div>
            </td>
            <td colSpan="3">
              <div className="flex flex-column">
                <span className="font-semibold">{t('Workers_contact_telephone_number')}</span>
                {workersPhoneLayout()}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div className="flex flex-column">
                <span className="font-semibold"> {t('Who_rendered_the_first_treatment')}</span>
                <span className="coloredText">{data[inputs.firstTreatment.name]}</span>
              </div>
            </td>
            <td colSpan="3">
              <div className="flex flex-column">
                <span className="font-semibold">{t('Workers_personal_health_number')}</span>
                {workersPHNLayout()}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="w-full">
        <tbody>
          {data[inputs.priorProblems.name]?.length > 55 && (
            <div className="flex flex-column">
              <span className="font-semibold">{`${t('Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability')}:`}</span>
              <span className="wordBreakTextPrint">{data[inputs.priorProblems.name]}</span>
            </div>
          )}

          <tr>
            <td className="font-semibold demographicInfoRowLabelPrint">{`${t('Are_you_the_workers_regularphysician')}`}</td>
            <td className="pl-2">
              <div className="demographicInfoRowValuePrint coloredText">{`${getRadioButtonValue(data[inputs.isPrimary.name])}`}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold demographicInfoRowLabelPrint">{`${t('If_Yes_how_long_has_the_worker_been_your_patient')}`}</td>
            <td className="pl-2">
              <div className="demographicInfoRowValuePrint coloredText">{`${termPrimary}`}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold demographicInfoRowLabelPrint">{`${t('From_injury_or_since_last_report')}?`}</td>
            <td className="pl-2">
              <div className="demographicInfoRowValuePrint coloredText">{getRadioButtonValue(data[inputs.disabled.name])}</div>
            </td>
          </tr>

          <tr>
            <td className="font-semibold demographicInfoRowLabelPrint">{`${t('If_Yes_as_of_what_date')} (yyyy/mm/dd)`}</td>
            <td className="pl-2">
              <div className="demographicInfoRowValuePrint coloredText">
                {data[inputs.disabledDate.name] ? moment(data[inputs.disabledDate.name]).format('YYYY/MM/DD') : ''}
              </div>
            </td>
          </tr>

          {data[inputs.priorProblems.name]?.length <= 55 && (
            <tr>
              <td className="font-semibold demographicInfoRowLabel">{`${t('Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability')}:`}</td>
              <td className="pl-2">
                <div className="demographicInfoRowValue coloredText wordBreakText text-justify pr-2">{data[inputs.priorProblems.name]}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DemographicsInfo;
