import { useFormContext } from 'react-hook-form';
import { useValidateGoupPicker } from './useValidateGroupPicker';
import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';
import _, { every, get, isEqual, keyBy, map } from 'lodash';

export const useDuplicateCodes = () => {
  const { localState, setLocalState } = useFormContext();
  const { setRequiredInput } = useValidateGoupPicker();
  const records = localState.groupRecords;

  const onDuplicateCode = (input) => {
    const inputName = input.name;
    const inputDescription = input.codeDescription;
    const inputType = input.codeType;
    const isReferral = inputName === inputs.referral.name;

    // Group records by patient ID
    const groupedById = _(records)
      .groupBy((record) => get(record, `${inputs.patient.name}[0].${inputs.patientGuid.name}`))
      .values()
      .value();

    // Check if there are duplicated patients
    const isDuplicatedPatients = groupedById.some((group) => group.length > 1);

    if (isDuplicatedPatients) {
      // Filter groups with both filled and empty `inputType`
      const matchingGroups = groupedById.filter((group) => {
        const isReferralCodesFilled = group.some((i) => i[inputType]?.length);
        const isReferralCodesEmpty = group.some((i) => !i[inputType]?.length);
        return isReferralCodesFilled && isReferralCodesEmpty;
      });

      matchingGroups.forEach((group) => {
        // Separate filled and empty codes within the group
        const filledCodes = group.filter((item) => item[inputType]?.length > 0);
        const emptyCodes = group.filter((item) => !item[inputType]?.length);

        if (filledCodes.length > 0) {
          // Extract unique code arrays
          const uniqueCodeArrays = Array.from(new Set(filledCodes.map((item) => item[inputType])));

          // Check if all code arrays are equal
          const isEqualReferralCodes = every(uniqueCodeArrays, (i) => isEqual(i, uniqueCodeArrays[0]));

          if (isEqualReferralCodes) {
            // All filled codes have the same value arrays, fill empty codes with that value array
            emptyCodes.forEach((emptyCode) => {
              const referralCodes = uniqueCodeArrays[0];
              const referralCodesOnly = referralCodes.map((code) => code.value);
              const referralCodesDescriptions = referralCodes.map((code) => code.text);

              // Update the empty code properties
              if (isReferral) emptyCode[inputs.refToBy.name] = 'B';
              emptyCode[inputName] = referralCodesOnly;
              emptyCode[inputDescription] = referralCodesDescriptions;
              emptyCode[inputType] = referralCodes;

              // Reset required Dx inputs
              setLocalState((prevState) => {
                const groupRecordsRequiredInputs = setRequiredInput({
                  groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
                  rowId: emptyCode[inputs.groupRowId.name],
                  inputName,
                  isRequired: false,
                  isAsterisk: false
                });

                return { ...prevState, groupRecordsRequiredInputs };
              });
            });

            // Update the local state with records replaced by empty codes
            setLocalState((prevState) => {
              // Create a mapping of emptyCodes using inputs.groupRowId.name as the key
              const emptyCodesMap = keyBy(emptyCodes, inputs.groupRowId.name);

              // Replace items in records with items from emptyCodes based on inputs.groupRowId.name
              const updatedRecords = map(prevState.groupRecords, (record) => {
                const replacement = emptyCodesMap[record[inputs.groupRowId.name]];
                return replacement ? { ...replacement } : record;
              });

              return { ...prevState, groupRecords: updatedRecords };
            });
          } else {
            // Handle the case where filled codes are not equal
            emptyCodes.forEach((emptyCode) => {
              setLocalState((prevState) => {
                const groupRecordsRequiredInputs = setRequiredInput({
                  groupRecordsRequiredInputs: prevState.groupRecordsRequiredInputs,
                  rowId: emptyCode[inputs.groupRowId.name],
                  inputName,
                  isRequired: false,
                  message: t('Select_manualy')
                });

                return { ...prevState, groupRecordsRequiredInputs };
              });
            });
          }
        }
      });
    }
  };

  const isDisabledDuplicateCodesButton = (input) => {
    const inputType = input.codeType;
    let disabled = true;

    // Group records by patient ID
    const groupedById = _(records)
      .groupBy((record) => get(record, `${inputs.patient.name}[0].${inputs.patientGuid.name}`))
      .values()
      .value();

    // Check if there are duplicated patients
    const isDuplicatedPatients = groupedById.some((group) => group.length > 1);

    if (isDuplicatedPatients) {
      // Filter groups with both filled and empty `inputType`
      const matchingGroups = groupedById.filter((group) => {
        const isReferralCodesFilled = group.some((i) => i[inputType]?.length);
        const isReferralCodesEmpty = group.some((i) => !i[inputType]?.length);
        return isReferralCodesFilled && isReferralCodesEmpty;
      });

      // Iterate through matching groups
      matchingGroups.forEach((group) => {
        // Filter items with filled `inputType`
        const filledCodes = group.filter((item) => item[inputType]?.length > 0);

        if (filledCodes.length > 0) {
          // Extract unique code arrays
          const uniqueCodeArrays = Array.from(new Set(filledCodes.map((item) => item[inputType])));

          // Check if all code arrays are equal
          const isEqualReferralCodes = every(uniqueCodeArrays, (i) => isEqual(i, uniqueCodeArrays[0]));

          // Update the disabled flag
          disabled = !isEqualReferralCodes;
        }
      });
    }

    return disabled;
  };

  return { onDuplicateCode, isDisabledDuplicateCodesButton };
};
