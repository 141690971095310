import React, { useEffect, useState } from 'react';

import { InputWrapper } from '../../../../../components/Inputs';
import { AutoComplete } from 'primereact/autocomplete';

import { usePaidReport } from '../hooks/usePaidReport';
import { usePayeeReportsContext } from '../hooks/usePayeeReportsContext';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { dropdownScrollHeight } from '../../../../config/stylesConfig';
import { t } from '../../../../../service/localization/i18n';

const PayeeNumbersFilter = () => {
  const { reportsState } = usePayeeReportsContext();
  const { onPayeeNumberFilterChange } = usePaidReport();
  // const value = reportsState?.paidReports?.filteredByPayeeNumberValue;
  const allSuggestions = reportsState?.paidReports?.payeesWithReports;

  const [value, setValue] = useState(null);
  const [suggestions, setSuggestions] = useState(allSuggestions);
  const [isSuggestionsFound, setIsSuggestionsFound] = useState(true);

  useEffect(() => {
    reportsState?.paidReports?.filteredByPayeeNumberValue && setValue(reportsState?.paidReports?.filteredByPayeeNumberValue);
  }, [reportsState?.paidReports?.filteredByPayeeNumberValue]);

  const search = (e) => {
    let _filtered;
    if (!e.query.trim().length) {
      setIsSuggestionsFound(true);
      _filtered = [...allSuggestions];
    } else {
      const criteria = e.query.toLowerCase();
      _filtered = allSuggestions.filter((i) => i?.PayeeNumber?.toLowerCase().startsWith(criteria));
      const isFound = _filtered?.length;
      setIsSuggestionsFound(isFound);
    }

    setSuggestions(_filtered);
  };

  const onChange = (e) => {
    if (typeof e.value === 'string') {
      setValue(e.value);
    } else {
      if (value?.PayeeNumber === e.value?.PayeeNumber) return;
      onPayeeNumberFilterChange(e.value);
    }
  };

  const onBlur = (e) => {
    if (!e.target.value) {
      setValue(reportsState?.paidReports?.filteredByPayeeNumberValue);
      onPayeeNumberFilterChange(reportsState?.paidReports?.filteredByPayeeNumberValue);
      !isSuggestionsFound && setIsSuggestionsFound(true);
    }
  };

  const onFocus = (e) => {
    e.target.select();
  };

  return (
    <div className="w-full max-w-15rem pb-4">
      <InputWrapper name="payeeNumbersFilter" label="Payment #">
        <AutoComplete
          id={elementIDs.payeeNumberPickerDropdown}
          inputId={elementIDs.payeeNumberPickerDropdownInput}
          name="payeeNumber"
          value={value}
          suggestions={suggestions}
          completeMethod={search}
          type="text"
          field="PayeeNumber"
          dropdown
          autoFocus
          // autoHighlight
          forceSelection
          scrollHeight={dropdownScrollHeight()}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {!isSuggestionsFound && <span className="p-error text-sm">{t('No_results_found')}</span>}
      </InputWrapper>
    </div>
  );
};

export default PayeeNumbersFilter;
