import SectionTitle from './SectionTitle';
import Disabled from '../Inputs/Disabled';
import DisabledDate from '../Inputs/DisabledDate';
import AnatomicCode from '../Inputs/AnatomicCode';
import ICD9Code from '../Inputs/ICD9Code';
import ICD9Text from '../Inputs/ICD9Text';
import AOICode from '../Inputs/AOICode';
import NOICode from '../Inputs/NOICode';
import { t } from '../../../../../../../service/localization/i18n';

const InjurySection = () => {
  return (
    <>
      <SectionTitle title={t('Injury')} className="mb-3" />

      <ICD9Code />

      <ICD9Text />

      <AOICode />

      <NOICode />

      <AnatomicCode />

      <Disabled />

      <DisabledDate />
    </>
  );
};

export default InjurySection;
