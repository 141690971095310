import React from 'react';

const TableCard = ({ children, maxWidth, height }) => {
  return (
    <div className="flex justify-content-center w-full">
      <div className="card w-full pt-3 m-0" style={{ maxWidth: maxWidth || 'auto', height: height || 'calc(100vh - 80px)' }}>
        {children}
      </div>
    </div>
  );
};

export default TableCard;
