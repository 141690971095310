import React, { useEffect } from 'react';

import SearchPanel from '../Search/SearchPanel';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import Categories from '../Categories/Categories';
import CatalogsDataScroller from '../DataTable/CatalogsDataScroller';
import { TabPanel, TabView } from 'primereact/tabview';

import { useCatalogContentSizeDesktop } from '../../hooks/useCatalogContentSizeDesktop';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { useCategories } from '../../hooks/useCategories';
import { useTabs } from '../../hooks/useTabs';

const CatalogsSubTabs = () => {
  const { hideCatalogsHeader, catalogsState, setCatalogsState, slideMenuRef, breadcrumbRef, hideTabs, isMobile } = useCatalogsContext();
  const { onSubTabChange } = useTabs();
  const { setInitCategoriesList } = useCategories();
  const activeIndex = catalogsState.activeSubTabIndex;
  const currentCategory = catalogsState.currentCategory;
  const sizes = useCatalogContentSizeDesktop({ slideMenuRef, breadcrumbRef, currentCategory, hideTabs });

  // Set menu model
  // Get init codes list for data scroller
  useEffect(() => {
    setInitCategoriesList();

    // Get init codes list for data scroller
    const categories = currentCategory?.tabs[activeIndex]?.categories || [];
    categories?.[0]?.command();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  // Set breadcrumbLabel
  // Set active classname for first menu item
  useEffect(() => {
    const breadcrumbLabel = catalogsState.menuModel?.length ? catalogsState.menuModel[0]?.label : '';

    // Remove all active classes for menu item list
    document.querySelectorAll('.p-menuitem-highlight').forEach((i) => i.classList.remove('p-menuitem-highlight'));

    // Set active class for menu item
    slideMenuRef?.current?.slideMenuContent?.children[0]?.children[0]?.classList.add('p-menuitem-highlight');

    setCatalogsState((prevState) => ({
      ...prevState,
      trail: [{ label: breadcrumbLabel }]
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogsState.menuModel]);

  if (isMobile) return null;

  return (
    <TabView
      className={hideCatalogsHeader ? 'catalog_tab_without_header_view' : 'catalog_tab_view'}
      activeIndex={activeIndex}
      onTabChange={onSubTabChange}
    >
      {currentCategory?.tabs?.map((i, index) => {
        return (
          <TabPanel className="h-full" header={i.label} key={index}>
            <div id={`${catalogsState.activeTab?.name}_catalogContent`} className="h-full">
              <BreadCrumbs />
              <SearchPanel />

              {/* Data table with menu */}
              <div className="flex">
                <div id={`${catalogsState.activeTab?.name}_catalogCategories`} className="desktopCatalogSlideMenuWrap">
                  <Categories menuHeight={sizes.menuHeight} menuWidth={sizes.desktopSidebarMenuWidth} />
                </div>

                {/* Data table */}
                <div id={`${catalogsState.activeTab?.name}_catalogTable`} className="w-full">
                  <CatalogsDataScroller scrollableTableHeight={sizes.scrollableTableHeight} />
                </div>
              </div>
            </div>
          </TabPanel>
        );
      })}
    </TabView>
  );
};

export default CatalogsSubTabs;
