import { location_codes } from '../../common/contants';
import { remPeriods, reportPeriods, reportTypes0, allStatuses, submissionCodes } from './mapper';

export const extraFields = (ruralOptions, userList, filters) => {
  return [
    { id: 'Period', field: 'Period', header: 'DOS', list: reportPeriods },
    { id: 'SubmissionPeriod', field: 'SubmissionPeriod', header: 'Submission Period', list: remPeriods },
    { id: 'SubmissionDate', field: 'SubmissionDate', header: 'Submission', list: remPeriods },
    { id: 'SubmissionCode', field: 'SubmissionCode', header: 'Submission Code', list: submissionCodes },
    { id: 'RemittanceDate', field: 'RemittanceDate', header: 'Remittance', list: remPeriods },
    { id: 'RemittancePeriod', field: 'RemittancePeriod', header: 'Remittance Period', list: remPeriods },
    { id: 'SCCode', field: 'SCCode', header: 'Rural Code', list: ruralOptions },
    { id: 'DoctorGuid', field: 'DoctorGuid', header: 'Practitioner', list: userList },
    { id: 'PatientGuid', field: 'PatientGuid', header: 'Patient', list: filters },
    { id: 'RecordType', field: 'RecordType', header: 'Record Type', list: reportTypes0 },
    { id: 'InvoiceStatus', field: 'InvoiceStatus', header: 'Status', list: allStatuses },
    { id: 'LocationCode', field: 'LocationCode', header: 'Location Code', list: location_codes }
  ];
};
