import moment from 'moment';
import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Patient, InputWrapper } from '../../../../components/Inputs';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { logsDefaultFilters, logsDefaultSortBy } from '../../helpers/defaultValues';
import TableCard from '../../../../components/Wraps/PageWrap/TableCard';
import { getLogsReport } from '../../actions/logs.action.creators';

const LogsTable = ({ logs, loading, fetchData }) => {
  const { members } = useSelector((state) => state.clinic);
  const memberNamesSet = new Set(members?.map((item) => item.FullName));
  const memberNames = [...memberNamesSet];
  const dt = useRef(null);

  const [memberValue, setMemberValue] = useState('');
  const [memberItems, setMemberItems] = useState(memberNames);
  const [visible, setVisible] = useState(false);
  const [patientValue, setPatientValue] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [query, setQuery] = useState(logsDefaultFilters);
  const [sortField, setSortField] = useState(logsDefaultSortBy.sortField);
  const [sortOrder, setSortOrder] = useState(logsDefaultSortBy.sortOrder);
  const [filters, setFilters] = useState({
    Role: { value: null, matchMode: FilterMatchMode.EQUALS },
    Outcome: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  useEffect(() => {
    if (patientValue && typeof patientValue === 'object') {
      setQuery((prevState) => ({
        ...prevState,
        PatientGuid: patientValue.PatientGuid
      }));
    } else {
      setQuery((prevState) => {
        const newQuery = { ...prevState };
        newQuery.PatientGuid = logsDefaultFilters.PatientGuid;
        return newQuery;
      });
    }
  }, [patientValue]);

  useEffect(() => {
    const selectedMember = members.find((item) => item.FullName === memberValue);
    if (selectedMember) {
      setQuery((prevState) => ({
        ...prevState,
        DoctorGuid: selectedMember.DoctorGuid
      }));
    } else {
      setQuery((prevState) => {
        const newQuery = { ...prevState };
        newQuery.DoctorGuid = logsDefaultFilters.DoctorGuid;
        return newQuery;
      });
    }
  }, [memberValue, members]);

  useEffect(() => {
    if (fromDate) {
      setQuery((prevState) => ({
        ...prevState,
        PeriodFrom: moment(new Date(fromDate)).format('YYYY/MM/DD')
      }));
    } else {
      setQuery((prevState) => {
        const newQuery = { ...prevState };
        newQuery.PeriodFrom = logsDefaultFilters.PeriodFrom;
        return newQuery;
      });

    }
  }, [fromDate]);
  
  useEffect(() => {
    if (toDate) {
      setQuery((prevState) => ({
        ...prevState,
        PeriodTo: moment(new Date(toDate)).format('YYYY/MM/DD')
      }));
    } else {
      setQuery((prevState) => {
        const newQuery = { ...prevState };
        newQuery.PeriodTo = logsDefaultFilters.PeriodTo;
        return newQuery;
      });
    }
  }, [toDate]);

  const formatDateTime = (dateTimeString) => {
    return moment(dateTimeString).format('MMM DD, YYYY HH:mm');
  };

  const onMemberSearch = (event) => {
    const query = event.query;
    if (query.trim() === '') {
      setMemberItems(memberNames);
    } else {
      const filteredMembers = memberNames.filter((member) => member.toLowerCase().includes(query.toLowerCase()));
      setMemberItems(filteredMembers);
    }
  };

  const onFilterButtonClick = () => {
    fetchData({
      page: 1,
      pageSize: logs.pageSize,
      query
    });
    setVisible(false);
  };

  const onClearButtonClick = () => {
    setFromDate(null);
    setToDate(null);
    setQuery(logsDefaultFilters);
    setMemberValue('');
    setPatientValue(null);
    fetchData({
      page: 1,
      pageSize: 20,
      query: logsDefaultFilters
    });
  };

  const onPage = (e) => {
    const currentPage = (e.page ?? 0) + 1;
    fetchData({ page: currentPage, pageSize: logs.pageSize, query });
  };

  const onSort = (e) => {
    if (e.sortField === null) {
      setSortField(logsDefaultSortBy.sortField);
    } else {
      setSortField(e.sortField);
    }
    setSortOrder(e.sortOrder);
    fetchData({ page: 1, pageSize: logs.pageSize, query, sortby: `${e.sortField}_${e.sortOrder === 1 ? 'asc' : 'desc'}` });
  };

  const handleDownloadReport = async () => {
    await getLogsReport({ query });
  };

  const first = logs.first < 0 ? 0 : logs.first;

  const roles = Array.from(new Set(logs.recordList.map((item) => item.Role))).map((name) => ({ name }));
  const [statuses] = useState(['success', 'failure']);

  const getSeverity = (status) => {
    switch (status) {
      case 'failure':
        return 'danger';
      case 'success':
        return 'success';
      default:
        return null;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.Outcome} severity={getSeverity(rowData.Outcome)} />;
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const optionItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: '12rem'}}
      />
    );
  };

  const roleRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={roles}
        itemTemplate={optionItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  const resetFiltersButton = <Button className="p-button-text p-button-rounded" tooltip='Reset Filters' tooltipOptions={{ position: 'top' }} icon="pi pi-undo" onClick={onClearButtonClick} />;

  const downloadReportButton = <Button className="p-button-text p-button-rounded" tooltip='Download' tooltipOptions={{ position: 'top' }} icon="pi pi-download" onClick={handleDownloadReport} />;

  const headerFilters = (
    <div className="flex flex-column md:flex-row justify-content-start align-items-center gap-3">
      <InputWrapper label="User">
        <AutoComplete
          inputId="userInput"
          value={memberValue}
          suggestions={memberItems}
          completeMethod={onMemberSearch}
          onChange={(e) => setMemberValue(e.value)}
          dropdown
          placeholder="Select user"
        />
      </InputWrapper>
      <Patient
        name="patient"
        placeholder="Enter patient details"
        forceSelection={false}
        showAddPatientButton={false}
        value={patientValue}
        multiple={false}
        onChange={(e) => setPatientValue(e.target.value)}
      />
      <InputWrapper label="From">
        <Calendar
          value={fromDate}
          minDate={moment(logsDefaultFilters.PeriodFrom, 'YYYY/MM/DD').toDate()}
          maxDate={moment().toDate()}
          onChange={(e) => {
            setFromDate(e.value);
            setQuery((prevValue) => ({
              ...prevValue,
              PeriodFrom: moment(new Date(e.value)).format('YYYY/MM/DD')
            }));
          }}
          inputStyle={{ width: '150px' }}
          placeholder="Select start date"
        />
      </InputWrapper>
      <InputWrapper label="To">
        <Calendar
          value={toDate}
          minDate={fromDate}
          onChange={(e) => {
            setToDate(e.value);
            setQuery((prevValue) => ({
              ...prevValue,
              PeriodTo: moment(new Date(e.value)).format('YYYY/MM/DD')
            }));
          }}
          inputStyle={{ width: '150px' }}
          placeholder="Select end date"
        />
      </InputWrapper>
      <div className="flex justify-content-start align-items-center gap-2">
        <Button style={{ minWidth: '100px' }} onClick={onFilterButtonClick} label="Show report" />
        {resetFiltersButton}
        {downloadReportButton}
      </div>
    </div>
  );

  const filtersButton = (
    <div className="block md:hidden">
      <div className="flex justify-content-center align-items-center w-full gap-1">
        <Button label="Show filters" onClick={() => setVisible(true)} />
        {resetFiltersButton}
        {downloadReportButton}
      </div>
    </div>
  );

  const header = (
    <>
      {filtersButton}
      <div className="hidden md:block">{headerFilters}</div>
    </>
  );

  return (
    <TableCard height={'calc(100vh - 120px)'}>
      <DataTable
        lazy
        ref={dt}
        value={logs.recordList}
        totalRecords={logs.totalRecords}
        scrollable
        onSort={onSort}
        onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate={`${first} to {last} of {totalRecords}`}
        scrollHeight="flex"
        filterDisplay="menu"
        header={header}
        paginator
        first={logs.first}
        filters={filters}
        rows={20}
        loading={loading}
        sortOrder={sortOrder}
        sortField={sortField}
        pt={{
          header: {
            className: 'md:pb-0'
          }
        }}
      >
        <Column
          sortable
          body={(rowData) => (loading ? <Skeleton /> : formatDateTime(rowData.ActionDate))}
          field="ActionDate"
          header="Date/time"
          style={{ minWidth: '150px', maxWidth: '150px' }}
        />
        <Column sortable field="FullName" header="Username" body={loading && <Skeleton />} style={{ minWidth: '200px', maxWidth: '200px' }} />
        <Column
          field="Role"
          header="User role"
          body={loading && <Skeleton />}
          // filterField="Role"
          // filter
          // filterElement={roleRowFilterTemplate} 
          // showFilterMatchModes={false} 
          style={{ minWidth: '120px', maxWidth: '120px' }}
        />
        <Column
          field="Outcome"
          header="Status"
          body={loading ? <Skeleton /> : statusBodyTemplate}
          // filterField="Outcome"
          // filter
          // filterElement={statusRowFilterTemplate}
          // showFilterMatchModes={false}
          style={{ minWidth: '150px', maxWidth: '150px' }}
        />
        <Column field="Origin" header="Origin of event" body={loading && <Skeleton />} style={{ minWidth: '200px', maxWidth: '200px' }} />
        <Column header="User IP Address" field="UserIP" body={loading && <Skeleton />} style={{ minWidth: '150px', maxWidth: '150px' }} />
        <Column header="Action" field="Action" body={loading && <Skeleton />} style={{ minWidth: '150px', maxWidth: '150px' }} />
        <Column header="Name of affected data" field="Resource" body={loading && <Skeleton />} style={{ minWidth: '200px', maxWidth: '200px' }} />
        <Column header="Repository" field="Domain" body={loading && <Skeleton />} style={{ minWidth: '150px', maxWidth: '150px' }} />
        <Column header="Transaction ID" field="ActionGuid" body={loading && <Skeleton />} style={{ minWidth: '120px', maxWidth: '120px' }} />
      </DataTable>

      <Dialog header="Filters" visible={visible} style={{ minWidth: '50vw' }} onHide={() => setVisible(false)}>
        {headerFilters}
      </Dialog>
    </TableCard>
  );
};

export default LogsTable;
