import React from 'react';
import { isFunction } from 'lodash';

const FooterContentLayout = (props) => {
  const footerContentLayout = isFunction(props.footerContent) ? props.footerContent() : props.footerContent;

  return <>{footerContentLayout}</>;
};

export default FooterContentLayout;
