import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chips from '../Chips';

import { Sidebar } from 'primereact/sidebar';

import { toggleFilters } from '../../../modules/core/actions/core.action.creators';
import { t } from '../../../service/localization/i18n';
import classes from './Filters.module.scss';
import tableClasses from '../Table.module.scss';
import cx from 'classnames';

const Filters = ({ title = t('Filters'), children, ...props }) => {
  const dispatch = useDispatch();
  const { showFiltersSidebar } = useSelector((state) => state.core);

  const header = () => (
    <div className="w-full px-3">
      <h5 className={cx('m-0')}>{title}</h5>
    </div>
  );

  return (
    <Sidebar
      className={classes.sidebar}
      visible={showFiltersSidebar}
      position="right"
      blockScroll
      baseZIndex={9999999999}
      icons={header()}
      onHide={() => dispatch(toggleFilters(!showFiltersSidebar))}
    >
      <div className="mt-3 px-3">
        {props.filters?.length > 0 && (
          <div className={tableClasses.sideBarFiltersChipsWrap}>
            <Chips filters={props.filters} onChipRemove={props.onChipRemove} clearAllFilters={props.clearAllFilters} />
          </div>
        )}

        {children}
      </div>
    </Sidebar>
  );
};

export default Filters;
