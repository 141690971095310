import React from 'react';
import cx from 'classnames';
import './BadgeWrap.scss';

// interface IBadgeWrap {
//   children: JSX.Element,
//   className: string // Color styling for badge. Provide `background` and `color` styles
//   onClick?: () => void // on badge click
// }

const BadgeWrap = ({ children, className, onClick }) => {
  return (
    <div className={cx('status-badge-wrap', className, onClick ? 'pointer' : '')} onClick={onClick}>
      {children}
    </div>
  );
};

export default BadgeWrap;
