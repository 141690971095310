import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import Context from '../../../../Context';
import ClaimInputField from './ClaimInputField';
import DatePicker from './DatePicker';
import { updatePrivateRecord } from '../../actions/claims.action.creators';
import { inputs } from './claimDetailsHelpers/inputs';
import { termsOptions } from './claimDetailsHelpers/termsOptions';
import { addTermsToDueDate } from './claimDetailsHelpers/addTermsToDueDate';
import { elementIDs } from '../../../config/elementIDsConfig';

const PrivateDatePicker = () => {
  const dispatch = useDispatch();
  const { privateRecord, errors } = useContext(Context);
  const [terms, setTerms] = useState(null);

  // initial value for Terms
  useEffect(() => {
    const newValue = termsOptions.find((i) => i.net === Number(privateRecord.Terms));
    setTerms(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateRecord?.Terms]);

  const onChangeTerms = (value) => {
    // setTerms(value);
    dispatch(
      updatePrivateRecord({
        [inputs().terms.name]: value?.net || 0,
        [inputs().dueDate.name]: addTermsToDueDate(value?.net, privateRecord[inputs().invoiceDate.name])
      })
    );
  };

  return (
    <div>
      <ClaimInputField label={inputs().invoiceDate.label} required={true}>
        <DatePicker
          id={elementIDs.invoiceDate}
          initialDate={privateRecord[inputs().invoiceDate.name]}
          required={true}
          errors={errors[inputs().invoiceDate.name]}
          dateField={inputs().invoiceDate.name}
        />
      </ClaimInputField>

      <ClaimInputField label={inputs().terms.label} required={false}>
        <Dropdown
          id={elementIDs.termsDropdown}
          className="flex"
          value={terms}
          options={termsOptions}
          optionLabel="label"
          placeholder=""
          onChange={(e) => onChangeTerms(e.value)}
        />
      </ClaimInputField>

      {/* CMO-1533 - Remove Due Date at all */}
      {/* <ClaimInputField label={inputs().dueDate.label} required={true}>
        <DatePicker
          id={`due_date_${inputs().dueDate.name}`}
          initialDate={privateRecord[inputs().dueDate.name]}
          required={true}
          errors={errors[inputs().dueDate.name]}
          dateField={inputs().dueDate.name}
        />
      </ClaimInputField> */}
    </div>
  );
};

export default PrivateDatePicker;
