import React, { useState, useEffect, useRef, memo } from 'react';

import { ContextMenu } from 'primereact/contextmenu';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import PaginatorRight from './PaginatorRight';
import { defaultPageSize, showInCardPageSize } from '../config/defaultValuesConfig';
import { getItemFromArray } from '../../Helper';
import { getColumns } from './helpers/getColumns';
import { loadData } from './helpers/loadData';
import classes from './LegoTable.module.scss';
import { t } from '../../service/localization/i18n';
import cx from 'classnames';
import _ from 'lodash';

const LegoTable = ({
  filters,
  setFieldFilter,
  columns,
  actions,
  dataFieldFilters,
  fitRows,
  isExpanded,
  setIsExpanded,
  page_info,
  setExpanded,
  invalidFields,
  dataOnPageInplace,
  setRowCoordinate,
  isMobileOnly,
  showInCard,
  isFetching,
  resetPreSelected,
  showExpandButton = false,
  ...props
}) => {
  const showExtras = showExpandButton;
  const defaultRowOption = showInCard ? showInCardPageSize : defaultPageSize;
  const rowOptions = [defaultRowOption, 40, 60];

  // const [isExpanded, setIsExpanded] = useState(false);
  const [selectedRows, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [autoscrollToHighlightedElement, setAutoscrollToHighlightedElement] = useState(true);
  // const [toggleRow, setToggleRow] = useState({});
  const contextMenuRef = useRef(null);

  useEffect(() => {
    props.preSelected && setSelected(props.preSelected);
  }, [props.preSelected]);

  //auto scroll to highlighted element
  useEffect(() => {
    if (autoscrollToHighlightedElement) {
      if (props.isMobile && !showInCard) {
        scrollToHighlightedElement();
      } else {
        const scrollToParam = props.isMobile ? 'nearest' : 'center';
        const getClassName = document.getElementsByClassName('p-highlight p-selectable-row');
        props.preSelected.length > 0 && !showInCard && getClassName[0]?.scrollIntoView({ block: scrollToParam });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preSelected]);

  const scrollToHighlightedElement = () => {
    const element = document.getElementsByClassName('p-highlight p-selectable-row');
    if (element?.length) {
      const offset = 240;
      const highlightedElement = element[0];
      const elementPosition = highlightedElement && highlightedElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;
      window.scrollTo({
        top: offsetPosition
        // behavior: "smooth"
      });

      // [KS] CMO-1206 - [Mobile] Do not autoscroll to the top when user scrolling screen with the data table
      setAutoscrollToHighlightedElement(false);
    }
  };

  useEffect(() => {
    isExpanded ? expandAll() : setExpanded((prevRows) => prevRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSourceInplace]);

  const smartToggle = (e) => {
    try {
      const lastIndex = Object.keys(e).length;
      const newRow = lastIndex === 0 ? Object.keys(e)[0] : Object.keys(e)[lastIndex - 1];
      const selected = getItemFromArray(props.dataSourceInplace, newRow, props.dataKey);
      setSelected(selected);
    } catch (err) {}
    setExpanded(e);
  };

  const rowSelectToggle = (e) => {
    // [VB] disabled for now to allow multicelect for group operation
    // const isRowToggled = Object.keys(toggleRow).length === 0
    //   ? e[props.dataKey] : toggleRow !== e[props.dataKey]
    //     ? e[props.dataKey] : toggleRow === e[props.dataKey]
    //       ? {} : {};
    // setToggleRow(isRowToggled);
    // isRowToggled === e[props.dataKey] ?
    //   setExpanded({
    //     [isRowToggled]: true
    //   }) : setExpanded({})
  };

  const onSort = (sortField, sortOrder) => {
    const sortOrderValue = sortOrder === 1 ? 'asc' : 'desc';
    const params = {
      // sortField,
      // sortOrder: sortOrderValue,
      filters: filters,
      sortby: `${sortField}_${sortOrderValue}`,
      query: page_info.query,
      page: 0, // page_info.page <-- when we change sort, server need to start with first page
      rows: page_info.rows
    };
    isMobileOnly ? props.dataOnPageAppend(params) : dataOnPageInplace(params);
  };

  const expandAll = () => {
    let rowList = {};
    props?.dataSourceInplace?.forEach((p) => (rowList[`${p[props.dataKey]}`] = true));
    setExpanded(rowList);
  };

  const collapseAll = () => {
    setExpanded({});
  };

  const onExpand = () => {
    setIsExpanded((isExpanded) => !isExpanded);
    isExpanded ? collapseAll() : expandAll();
  };

  const handlePage = (e) => {
    !_.isEmpty(props.preSelected) && resetPreSelected();
    loadData(e, { ...loadDataParams, isPaginator: true });
    showInCard === false && window[`scrollTo`]({ top: 0 }); // CMO-785 - Table (patients and invoices) should discard scrolling position when user flips the pages. The scrolling have to go up on the new page.
  };

  const columnsParams = {
    showExtras,
    columns,
    actions,
    dataFieldFilters,
    filters,
    invalidFields,
    onSort,
    setFieldFilter,
    isFetching
  };

  const loadDataParams = {
    sortOrder: props.sortOrder,
    page_info,
    sortField: props.sortField,
    fitRows,
    dataOnPageInplace
  };

  // CMO-1625 - New skeleton for patients profile
  const mobileRowTemplate =
    isFetching || props.showSkeleton ? <Column body={props.mobileRowExpansionSkeleton}></Column> : <Column body={props.rowTemplateAppend} />;

  // const mobileRowTemplate = <Column body={props.rowTemplateAppend} />

  const last = props.first + props.rows - 1 < props.totalRecords ? props.first + props.dataSourceInplace?.length - 1 : props.totalRecords;

  const first = props.first < 0 || !props.dataSourceInplace?.length ? 0 : props.first;

  const paginatorRight = (
    <PaginatorRight
      filters={filters}
      isMobileDataTableView={props.isMobileDataTableView}
      showDataScroller={props.showDataScroller}
      isExpanded={isExpanded}
      onExpand={onExpand}
      onExportClick={props.onExportClick}
      setFieldFilter={setFieldFilter}
      showExpandButton={showExpandButton}
      showExportButton={props.showExportButton}
      showArchivedButton={props.showArchivedButton}
    />
  );

  const headerTemplate = (data) => {
    return <span>{data.FullName}</span>;
  };

  const footerTemplate = (data) => {
    return (
      <React.Fragment>
        <td colSpan="6" style={{ textAlign: 'right' }}>
          Total:
        </td>
        <td>{data.FeeTotal}</td>
        <td>$241.21</td>
      </React.Fragment>
    );
  };

  const paginatorTemplate = `CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink ${
    !isMobileOnly && !showInCard ? 'RowsPerPageDropdown' : ''
  }`;

  const rootMarginTopMobile = props.showRemoveFiltersForMoreRecords ? classes.rootMarginTopMobileWithMoreRecords : classes.rootMarginTopMobile;

  const dataTableClasses = cx(
    // props.showDataScroller ? "" : "p-datatable-customers p-datatable-responsive p-datatable-sm px-2",
    isMobileOnly && !props.showOnPatientDetailsScreen ? rootMarginTopMobile : '',
    props.isTablet && !props.showOnPatientDetailsScreen ? classes.rootMarginTopTablet : '',
    props.isMobile ? classes.rootMarginBot : ''
  );

  const iPadProInLandscapeViewDataTableClass = props.layoutMode === 'overlay' ? 'iPadProInLandscapeOverlayRoot' : 'iPadProInLandscapeStaticRoot';
  const mobileViewDataTableClass = props.iPadProInLandscape ? iPadProInLandscapeViewDataTableClass : 'legoViewDataTableWrapMobile';

  return (
    <div
      className={cx(showInCard ? '' : props.isMobile ? mobileViewDataTableClass : 'legoViewDataTableWrapDesktop', showInCard ? '' : dataTableClasses)}
    >
      <ContextMenu model={props.contextMenu(selectedItem)} ref={contextMenuRef} onHide={() => setSelectedItem(null)} />

      <DataTable
        className={cx(
          'legoTable',
          props.showDataScroller ? '' : 'p-datatable-customers p-datatable-responsive p-datatable-sm',
          props.isTablet && showInCard ? '' : '',
          props.isMobile || showInCard ? 'hideDataTableHeader' : ''
        )}
        dataKey={props.dataKey}
        value={props.dataSourceInplace}
        rowClassName={props.rowClassName}
        lazy={true}
        responsive={true}
        resizableColumns
        displayField="value"
        filterDisplay="row"
        // scrollable
        // scrollHeight="700px"

        rows={props.rows}
        first={props.first}
        totalRecords={props.totalRecords}
        paginator={props.dataSourceInplace}
        paginatorPosition="bottom"
        rowsPerPageOptions={rowOptions}
        currentPageReportTemplate={`${first} to ${last} of ${Number(props.totalRecords)?.toLocaleString()}`}
        pageLinkSize={isMobileOnly ? 2 : 3}
        paginatorTemplate={paginatorTemplate}
        paginatorLeft={!isMobileOnly && !showInCard && props.paginatorLeft()}
        paginatorRight={!isMobileOnly && !showInCard && paginatorRight}
        sortField={props.sortField}
        sortOrder={props.sortOrder}
        // rowGroupMode={props.sortField === "FullName" && "subheader"}
        // groupField={props.sortField === "FullName" && props.sortField}
        // rowGroupHeaderTemplate={props.sortField === "FullName" && headerTemplate}
        // rowGroupFooterTemplate={props.sortField === "FullName" && footerTemplate}

        // [KS] - Context menu
        contextMenuSelection={selectedItem}
        onContextMenuSelectionChange={(e) => setSelectedItem(e.value)}
        onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
        onSort={(e) => onSort(e.sortField, e.sortOrder)}
        expandedRows={props.expandedRows}
        emptyMessage={t('No_records_found')}
        // onRowExpand={() => setIsExpanded(isExpanded => !isExpanded)}
        onRowSelect={(e) => rowSelectToggle(e.data)}
        onRowToggle={(e) => smartToggle(e.data)}
        selectionMode="multiple"
        selection={selectedRows}
        onSelectionChange={(e) => setSelected(e.value)}
        rowExpansionTemplate={props.rowExpansionTemplate}
        onPage={(e) => handlePage(e)}
      >
        {(props.isMobileDataTableView && !props.showDataScroller) || showInCard ? mobileRowTemplate : getColumns(columnsParams)}
      </DataTable>
    </div>
  );
};

export default memo(LegoTable, _.isEqual);
