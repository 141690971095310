import React from 'react';
import PatientBillingHistoryProvider from './PatientBillingHistoryProvider/PatientBillingHistoryProvider';
import PatientBillingHistoryTable from './modules/table/PatientBillingHistoryTable';

// interface IPatientBillingHistory {
//   patientsList: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }[]
//   initPatient?: {
//     PatientGuid: string
//     FirstName: string
//     LastName: string
//     MidName?: string
//     BirthDay: Date
//     label: string // required for patient autocomplete
//   }
// }

const PatientBillingHistory = (props) => {
  return (
    <PatientBillingHistoryProvider {...props}>
      <PatientBillingHistoryTable />
    </PatientBillingHistoryProvider>
  );
};

export default PatientBillingHistory;
