import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggleCatalogs } from '../../hooks/useToggleCatalogs';
import { ToggleCatalogs as Toggle } from '../../../../../../components/Catalogs/Modules/ActionButtons/ToggleCatalogs';

const ToggleCatalogsButton = () => {
  const { localState } = useFormContext();
  const { toggleCatalogs } = useToggleCatalogs();

  return <Toggle showCatalogs={localState.showCatalogs} toggleCatalogs={toggleCatalogs} hideToggleCatalogsButton={localState.showCatalogs.desktop} />;
};

export default ToggleCatalogsButton;
