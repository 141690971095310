import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { t } from '../../../service/localization/i18n';
import { elementIDs } from '../../config/elementIDsConfig';

const CommonConfirmDialog = ({ visible, body, bodyText, accept, reject, header, footer, multipleButtons = true, loading = false }) => {
  const basicFooter = (
    <>
      {multipleButtons ? (
        <div>
          <Button id={elementIDs.yes} label={t('Yes')} autoFocus loading={loading} onClick={accept} />

          <Button id={elementIDs.no} className="p-button-outlined" label={t('No')} onClick={reject} />
        </div>
      ) : (
        <Button id={elementIDs.dialogClose} label={t('Close')} autoFocus onClick={accept} />
      )}
    </>
  );

  return (
    <Dialog
      id={elementIDs.warningConfirmation}
      header={header ? header : t('Confirmation')}
      visible={visible}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={footer ? footer : basicFooter}
      onHide={reject}
    >
      <div className="flex align-items-center">
        <i className="mr-3 pi pi-exclamation-triangle" style={{ fontSize: '2.6em' }} />
        {bodyText ? bodyText : body}
      </div>
    </Dialog>
  );
};

export default CommonConfirmDialog;
