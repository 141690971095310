import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { setToastMessage } from '../../../../modules/core/actions/core.action.creators';
import { recursion } from '../../helpers/recursion';
import { useRequests } from '../../hooks/useRequests';

const BreadCrumbs = () => {
  const dispatch = useDispatch();
  const { catalogCategories } = useSelector((state) => state.claims);
  const { getCatalogCodesList } = useRequests();
  const { catalogsState, setCatalogsState, slideMenuRef, breadcrumbRef } = useCatalogsContext();
  const categoryType = catalogsState.activeTab?.categoryType;
  const activeIndex = catalogsState.activeSubTabIndex;

  const getCurrentCategoryCodes = async (menuItems = null) => {
    try {
      const categories = catalogCategories?.[categoryType];
      const menuItem = categories?.tabs[0]?.categories[0];
      const updatedCatalogsState = { ...catalogsState, searchValue: '', tableData: [] };
      const response = await getCatalogCodesList({ catalogsState: updatedCatalogsState, menuItem });
      if (menuItems) {
        recursion({ arr: menuItems, catalogsState: updatedCatalogsState, setCatalogsState, slideMenuRef, getCatalogCodesList });
      }

      setCatalogsState((prevState) => ({
        ...prevState,
        categoryValue: `${categoryType}_recent`,
        tableData: response,
        searchLoader: false,
        searchValue: '',
        menuModel: menuItems || prevState.menuModel,
        drillDown: [],
        trail: []
      }));
    } catch (error) {
      dispatch(setToastMessage({ type: 'error', message: 'Opps, something went wrong' }));
    }
  };

  const onHome = () => {
    const categories = catalogCategories?.[categoryType];
    const menuItems = categories?.tabs[activeIndex]?.categories || [];
    setCatalogsState((prevState) => ({ ...prevState, menuModel: menuItems?.slice(-1) })); // Reset menu model first to re-render menu
    getCurrentCategoryCodes(menuItems);
  };

  return (
    <div ref={breadcrumbRef}>
      <BreadCrumb
        id={`${catalogsState.activeTab?.name}_catalogBreadcrumb`}
        model={catalogsState.trail}
        home={{ icon: 'pi pi-home', command: () => onHome() }}
      />
    </div>
  );
};

export default BreadCrumbs;
