import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useGroupPickerInputs } from '../../views/TeleplanGroup/hooks/useGroupPickerInputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const SameDurationDialog = () => {
  const { applySameDuration } = useGroupPickerInputs();
  const { localState, setLocalState } = useFormContext();

  const onHideOrderDialog = () => {
    setLocalState((prevState) => ({ ...prevState, sameDurationDialog: false }));
  };

  const onAccept = () => {
    onHideOrderDialog();
    setLocalState((prevState) => ({ ...prevState, applyToAllTime: true }));
    applySameDuration();
  };

  const sameDurationDialogFooter = () => {
    return (
      <>
        <Button id={elementIDs.dialogAccept} label={t('Yes')} onClick={onAccept} autoFocus />

        <Button className="p-button-outlined" id={elementIDs.dialogClose} label={t('No')} onClick={onHideOrderDialog} />
      </>
    );
  };

  return (
    <Dialog
      id={elementIDs.sameDurationDialog}
      header={t('Warning')}
      visible={localState.sameDurationDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={sameDurationDialogFooter()}
      onHide={onHideOrderDialog}
    >
      {t('The_data_that_you_entered_on_this_screen_will_be_cleared')}
    </Dialog>
  );
};

export default SameDurationDialog;
