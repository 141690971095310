import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CheckNameAndEligibilityButton from './CheckNameAndEligibilityButton';
import MismatchDialog from '../../Dialogs/MismatchDialog/MismatchDialog';

import { duplicatePatientCheck } from '../../../modules/patients/actions/patients.action.creators';
import { getEveryFirstLetterOfString } from '../../../modules/utils/getEveryFirstLetterOfString';
import { commonInputs } from '../../../modules/claims/../config/commonInputsConfig';
import { isEmpty } from 'lodash';
import moment from 'moment';

// interface ICheckNameAndEligibility {
//   patientData: {
//     PHN: string,
//     PHNProvince: string,
//     BirthDay: string | Date,
//     FirstName: string,
//     LastName: string,
//     MidName: string,
//     Sex: string,
//     DCNGuid: string
//     PatientGuid?: string  // add only in edit mode
//   },
//   isNew: boolean
//   isPHNValid: boolean
//   setValue: () => void // react-hook-form method
//   onClick?: (responseData) => void
//   duplicatePatientCheckCallback?: (responseData) => void
//   className?: string
// }

const CheckNameAndEligibility = (props) => {
  const dispatch = useDispatch();
  const [showMismatchDialog, setShowMismatchDialog] = useState(false);
  const [differentPatientsDemographics, setDifferentPatientsDemographics] = useState(false);
  const [eligibility, setEligibility] = useState(null);

  const handleClick = (data) => {
    props.onClick && props.onClick(data);
    setEligibility(data.Parsed);

    // [KS] CMO-1380 - Edit Demographics -> Check Eligibility - Gender is not updated
    if (!props.isNew && !props.patientData.Sex) {
      props.setValue(commonInputs.gender.name, getEveryFirstLetterOfString(data?.Parsed?.GENDER));
    }

    // [KS] CMO-1235 - Eligibility report for a Create New patient does not fill in name and gender
    if (props.patientData.Sex || props.patientData.FirstName || props.patientData.LastName || props.patientData.MidName) {
      const isDifferentDemographics = checkIfPatientDemographicsAreDifferent(data.Parsed);
      setDifferentPatientsDemographics(isDifferentDemographics);
      setShowMismatchDialog(true);
    } else {
      updateFormState(data.Parsed);
      differentPatientsDemographics && setDifferentPatientsDemographics(false);
    }
  };

  const updateFormState = (eligibilityData) => {
    const firstName = eligibilityData.FIRST_NAME || '';
    const lastName = eligibilityData.LAST_NAME || '';
    const midName = eligibilityData.MID_NAME || '';
    const gender = getEveryFirstLetterOfString(eligibilityData.GENDER);

    props.setValue(commonInputs.firstName.name, firstName);
    props.setValue(commonInputs.lastName.name, lastName);
    props.setValue(commonInputs.midName.name, midName);
    props.setValue(commonInputs.gender.name, gender);

    if (props.duplicatePatientCheckCallback) {
      const _patientDetails = {
        DCNGuid: props.patientData.DCNGuid,
        DOB: moment(props.patientData.BirthDay).format('MM-DD-YYYY'),
        LastName: lastName || ''
      };

      dispatch(
        duplicatePatientCheck(_patientDetails, (responseData) => {
          if (responseData?.length) props.duplicatePatientCheckCallback(responseData);
        })
      );
    }
  };

  //[KS] CMO-1106 - Eligibility returns different name but we do not alert the user
  const checkIfPatientDemographicsAreDifferent = (eligibility) => {
    let checkIfDifferent = false;

    if (!isEmpty(eligibility)) {
      const currentFullName = `${props.patientData.LastName} ${props.patientData.FirstName} ${props.patientData.MidName}`?.trim()?.toUpperCase();
      const eligibilityFullName = eligibility?.NAME?.trim()?.toUpperCase();

      if (
        currentFullName !== eligibilityFullName ||
        (props.patientData.Sex?.length && props.patientData.Sex !== eligibility.GENDER?.substring(0, 1))
      ) {
        checkIfDifferent = true;
      }
    }

    return checkIfDifferent;
  };

  const useEligibilityDemographics = (eligibility) => {
    updateFormState(eligibility);
    differentPatientsDemographics && setDifferentPatientsDemographics(false);
  };

  const patientDetails = {
    PHN: props.patientData.PHN,
    PHNProvince: props.patientData.PHNProvince,
    BirthDay: props.patientData.BirthDay,
    PatientGuid: props.patientData.PatientGuid
  };

  return (
    <>
      <CheckNameAndEligibilityButton patientData={patientDetails} className={props.className} isPHNValid={props.isPHNValid} onClick={handleClick} />

      <MismatchDialog
        isNew={props.isNew}
        visible={showMismatchDialog}
        patientData={props.patientData}
        eligibility={eligibility}
        differentPatientsDemographics={differentPatientsDemographics}
        closeDialog={() => setShowMismatchDialog(false)}
        useEligibilityDemographics={useEligibilityDemographics}
      />
    </>
  );
};

export default CheckNameAndEligibility;
