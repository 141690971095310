import { t } from '../../../../../service/localization/i18n';
import { inputs } from './inputs';

export const catalogTabs = [
  {
    name: 'MostRecent',
    label: t('Most_Recent'),
    codeType: 'mostRecent'
  },

  {
    name: inputs.icd9.name,
    label: inputs.icd9.label,
    codeType: inputs.icd9.codeType,
    codeDescription: inputs.icd9.codeDescription,
    searchAllType: 'icd9_all'
  },

  {
    name: inputs.aoi.name,
    label: inputs.aoi.label,
    codeType: inputs.aoi.codeType,
    codeDescription: inputs.aoi.codeDescription,
    searchAllType: 'aoi_all'
  },

  {
    name: inputs.noi.name,
    label: inputs.noi.label,
    codeType: inputs.noi.codeType,
    codeDescription: inputs.noi.codeDescription,
    searchAllType: 'noi_all'
  }
];
