import React from 'react';
import PaidReport from './PaidReport/PaidReport';
import ReportsProvider from './ReportsProvider/ReportsProvider';

const PayeeReports = () => {
  return (
    <ReportsProvider>
      <div className="card w-full m-0">
        <PaidReport />
      </div>
    </ReportsProvider>
  );
};

export default PayeeReports;
