import React, { useContext } from 'react';

import Context from '../../../../../../../Context';
import { inputs } from '../../../helpers/inputs';
import { commonInputs } from '../../../../../../config/commonInputsConfig';
import { specPHN } from '../../../../../../config/defaultValuesConfig';
import { formatPhoneNumber } from '../../../../../../utils/formatPhoneNumber';
import { formatFormTypeValue } from '../../../helpers/formTypeActions';
import { getRadioButtonValue } from '../../../helpers/getRadioButtonValue';
import { formTypeDropdownOptions, patientPeriodDropdownOptions } from '../../../helpers/dropdownOptions';
import { t } from '../../../../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';
import StatusBadge from '../../../../../../../components/Misc/Badges/Status/StatusBadge';

const DemographicsInfo = () => {
  const { data } = useContext(Context);
  const termPrimary = patientPeriodDropdownOptions.find((i) => i.value === data[inputs.termPrimary.name])?.label;
  const type = formatFormTypeValue(data[inputs.formType.name], data[inputs.speciality.name]);
  const formType = formTypeDropdownOptions.find((i) => i.value === type)?.label;

  const getPateientData = () => {
    if (data[inputs.patient.name]) {
      if (Array.isArray(data[inputs.patient.name])) return data[inputs.patient.name][0];
      return data[inputs.patient.name];
    }

    return {};
  };

  const patient = getPateientData();

  const workersMailingAddress = () => {
    const addressLine = patient?.AddressLine;
    const city = patient?.City;
    const zip = patient?.Zip;

    if (city) return `${addressLine} ${zip || ''}, ${city}`;
    return `${addressLine} ${zip || ''}`;
  };

  const mailingAddressLayout = () => {
    if (!patient?.AddressLine && !patient?.City) return <span className="p-error">{t('Mandatory')}</span>;
    if (!patient?.AddressLine && patient?.City) return <span className="p-error">{t('Missing_address')}</span>;
    if (patient?.AddressLine && !patient?.City) return <span className="p-error">{t('Missing_city')}</span>;
    return <span className="coloredText">{workersMailingAddress()}</span>;
  };

  const workersPhoneLayout = () => {
    if (!patient?.Phone) return null;
    return <span className="coloredText">{formatPhoneNumber(patient?.Phone)}</span>;
  };

  const workersPHNLayout = () => {
    if (Number(data[commonInputs.submitWithGenericPHN.name]) === 1) {
      return <span className="coloredText">{specPHN}</span>;
    }

    if (patient?.PHN && patient?.PHN !== 'N/A') {
      return <span className="coloredText">{patient?.PHN}</span>;
    }

    return <span className="p-error">{t('Mandatory')}</span>;
  };

  const formTypeLayout = (
    <div>
      <span className="font-semibold pr-3">{inputs.formType.label}:</span>
      <span>{formType}</span>
    </div>
  );

  const statusLayout = (
    <div className="demographicsInfoStatusWrap">
      <StatusBadge status={data[inputs.status.name]} />
    </div>
  );

  const headerLayout = (
    <>
      <div className="demographicsInfoHederDesktop">
        {formTypeLayout}
        {statusLayout}
      </div>

      <div className="demographicsInfoHederMobile">
        {statusLayout}
        {formTypeLayout}
      </div>
    </>
  );

  const priorDesktopLayout = (
    <tr className="priorDesktopLayout">
      <td className="font-semibold demographicInfoRowLabel">{`${t('Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability')}:`}</td>
      <td className="pl-2">
        <div className="demographicInfoRowValue wordBreakText text-justify pr-2 coloredText">{data[inputs.priorProblems.name]}</div>
      </td>
    </tr>
  );

  const priorMobileLayout = (
    <div className="px-1 pb-1 priorMobileLayout">
      <div className="font-semibold">{`${t('Prior_Other_Problems_Affecting_Injury_Recovery_and_Disability')}:`}</div>
      <div className="text-justify coloredText">{data[inputs.priorProblems.name]}</div>
    </div>
  );

  return (
    <div className="text-sm">
      {headerLayout}

      {/* Desktop view */}
      <div className="demographicsInfoTableDesktop">
        <table className="demographicsInfoTable ">
          <tbody>
            <tr>
              <td style={{ width: '55%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Date_of_service')}</span>
                  <span className="coloredText">{moment(data[inputs.serviceDate.name]).format('YYYY/MM/DD')}</span>
                </div>
              </td>

              <td style={{ width: '22.5%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Day_of_birthday.3')}</span>
                  <span className="coloredText">{moment(patient?.BirthDay)?.format('YYYY/MM/DD')}</span>
                </div>
              </td>

              <td colSpan="2" style={{ width: '22.5%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('WCB_claim_number')}</span>
                  {data[inputs.claimNumber.name] ? <span className="coloredText">{data[inputs.claimNumber.name]}</span> : <div>&nbsp;</div>}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Employers_name')}</span>
                  <span className="coloredText">{data[inputs.employer.name]}</span>
                </div>
              </td>

              <td colSpan="3">
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Workers_last_name')}</span>
                  <span className="coloredText">{patient?.LastName}</span>
                </div>
              </td>
            </tr>

            <tr>
              <td style={{ width: '55%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Employers_telephone_number')}</span>
                  <span className="coloredText">{formatPhoneNumber(data[inputs.phone.name])}</span>
                </div>
              </td>

              <td style={{ width: '13%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('First_Name')}</span>
                  {patient?.FirstName ? <span className="coloredText">{patient?.FirstName}</span> : <div>&nbsp;</div>}
                </div>
              </td>

              <td style={{ width: '13%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Middle_Initial')}</span>
                  {patient?.MidName ? <span className="coloredText">{patient?.MidName ? patient?.MidName?.charAt(0) : ''}</span> : <div>&nbsp;</div>}
                </div>
              </td>

              <td style={{ width: '13%' }}>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Gender')}</span>
                  {patient?.Sex ? <span className="coloredText">{patient?.Sex}</span> : <div>&nbsp;</div>}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Operating_Location_Address')}</span>
                  <span className="coloredText">{`${data[inputs.address.name]}, ${data[inputs.city.name]}`}</span>
                </div>
              </td>
              <td colSpan="3">
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Mailing_address')}</span>
                  {mailingAddressLayout()}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div className="flex flex-column">
                  <span className="font-semibold"> {`${t('Date_of_injury.2')} (yyyy/mm/dd)`}</span>
                  <span className="coloredText">{moment(data[inputs.injuryDate.name]).format('YYYY/MM/DD')}</span>
                </div>
              </td>
              <td colSpan="3">
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Workers_contact_telephone_number')}</span>
                  {workersPhoneLayout()}
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div className="flex flex-column">
                  <span className="font-semibold"> {t('Who_rendered_the_first_treatment')}</span>
                  <span className="coloredText">{data[inputs.firstTreatment.name]}</span>
                </div>
              </td>
              <td colSpan="3">
                <div className="flex flex-column">
                  <span className="font-semibold">{t('Workers_personal_health_number')}</span>
                  {workersPHNLayout()}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="demographicsInfoTableMobile">
        <table className="demographicsInfoTable">
          <tbody>
            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{`${t('Date_of_service')} (yyyy/mm/dd)`}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{moment(data[inputs.serviceDate.name]).format('YYYY/MM/DD')}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Employers_name')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{data[inputs.employer.name]}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Employers_telephone_number')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{data[inputs.phone.name]}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Operating_Location_Address')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{data[inputs.address.name]}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{`${t('Date_of_injury.2')} (yyyy/mm/dd)`}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{moment(data[inputs.injuryDate.name]).format('YYYY/MM/DD')}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{`${t('Who_rendered_the_first_treatment')}`}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{data[inputs.firstTreatment.name]}</div>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="demographicsInfoTable my-2">
          <tbody>
            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{`${t('Day_of_birthday.3')} (yyyy/mm/dd)`}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{moment(patient?.BirthDay)?.format('YYYY/MM/DD')}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('WCB_claim_number')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{data[inputs.claimNumber.name]}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Workers_last_name')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{patient?.LastName}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('First_Name')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{patient?.FirstName}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Middle_Initial')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{patient?.MidName?.charAt(0)}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Gender')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{patient?.Sex}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Mailing_address')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{mailingAddressLayout()}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Workers_contact_telephone_number')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{workersPhoneLayout()}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold caption demographicsTableMobileCaption">{t('Workers_personal_health_number')}</td>
              <td>
                <div className="flex justify-content-end text-right coloredText">{workersPHNLayout()}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <>
        {priorMobileLayout}

        <table className="w-full">
          <tbody>
            {priorDesktopLayout}

            <tr>
              <td className="font-semibold demographicInfoRowLabel">{`${t('Are_you_the_workers_regularphysician')}`}</td>
              <td className="pl-2">
                <div className="demographicInfoRowValue coloredText">{`${getRadioButtonValue(data[inputs.isPrimary.name])}`}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold demographicInfoRowLabel">{`${t('If_Yes_how_long_has_the_worker_been_your_patient')}`}</td>
              <td className="pl-2">
                <div className="demographicInfoRowValue coloredText">{`${termPrimary}`}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold demographicInfoRowLabel">{`${t('From_injury_or_since_last_report')}?`}</td>
              <td className="pl-2">
                <div className="demographicInfoRowValue coloredText">{getRadioButtonValue(data[inputs.disabled.name])}</div>
              </td>
            </tr>

            <tr>
              <td className="font-semibold demographicInfoRowLabel">{`${t('If_Yes_as_of_what_date')} (yyyy/mm/dd)`}</td>
              <td className="pl-2">
                <div className="demographicInfoRowValue coloredText">
                  {data[inputs.disabledDate.name] ? moment(data[inputs.disabledDate.name]).format('YYYY/MM/DD') : ''}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </div>
  );
};

export default DemographicsInfo;
