import { inputs } from './inputs';
import { formTypeValues, rehabilitationDropdownOptions } from './dropdownOptions';
import { checkDateDiff } from '../../../../utils/formatDate';
import { isChiropractor, isPhysician } from '../../../../config/specialitiesConfig';

export const formTypeCodes = {
  physician: {
    physician11p: '10',
    physician8p: '11',
    physician11pr: '12',
    physician8pr: '13',
    listFor8: [
      { value: '11', type: '8P' },
      { value: '13', type: '8PR' }
    ],
    listFor11: [
      { value: '10', type: '11P' },
      { value: '12', type: '11PR' }
    ]
  },

  chiropractic: {
    chiropractic11c: '0',
    chiropractic8c: '1',
    chiropractic11cr: '2',
    chiropractic8cr: '3',
    listFor8: [
      { value: '1', type: '8C' },
      { value: '3', type: '8CR' }
    ],
    listFor11: [
      { value: '0', type: '11C' },
      { value: '2', type: '11CR' }
    ]
  }
};

export const getFormTypeValue = (value, speciality) => {
  // Valid values: '8P', '8PR', '11P', '11PR', '8C', '8CR', '11C', '11CR'

  // Phisitian
  if (isPhysician(speciality)) {
    if (formTypeCodes.physician.listFor8.map((i) => i.type).some((i) => i === value)) {
      return formTypeCodes.physician.listFor8.find((i) => i.type === value).value;
    }

    if (formTypeCodes.physician.listFor11.map((i) => i.type).some((i) => i === value)) {
      return formTypeCodes.physician.listFor11.find((i) => i.type === value).value;
    }
  }

  // Chiropractic
  if (isChiropractor(speciality)) {
    if (formTypeCodes.chiropractic.listFor8.map((i) => i.type).some((i) => i === value)) {
      return formTypeCodes.chiropractic.listFor8.find((i) => i.type === value).value;
    }

    if (formTypeCodes.chiropractic.listFor11.map((i) => i.type).some((i) => i === value)) {
      return formTypeCodes.chiropractic.listFor11.find((i) => i.type === value).value;
    }
  }
};

export const formatFormTypeValue = (value, speciality) => {
  // Physician
  if (isPhysician(speciality)) {
    if (formTypeCodes.physician.listFor8.map((i) => i.value).some((i) => i === value)) return formTypeValues.firstReport;
    if (formTypeCodes.physician.listFor11.map((i) => i.value).some((i) => i === value)) return formTypeValues.nextReport;
  }

  // Chiropractic
  if (isChiropractor(speciality)) {
    if (formTypeCodes.chiropractic.listFor8.map((i) => i.value).some((i) => i === value)) return formTypeValues.firstReport;
    if (formTypeCodes.chiropractic.listFor11.map((i) => i.value).some((i) => i === value)) return formTypeValues.nextReport;
  }
};

export const onFormTypeChange = (props) => {
  const dayDiff = checkDateDiff(props.serviceDate) || 0;

  // Physician
  if (isPhysician(props.speciality)) {
    if (props.value === formTypeValues.nextReport) {
      return props.setValue(inputs.formType.name, formTypeCodes.physician.physician11p);
    }
    if (props.value === formTypeValues.firstReport) {
      return props.setValue(inputs.formType.name, formTypeCodes.physician.physician8p);
    }
    // if (dayDiff < -10 && props.value === formTypeValues.nextReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.physician.physician11pr);
    // };
    // if (dayDiff < -10 && props.value === formTypeValues.firstReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.physician.physician8pr);
    // };
  }

  // Chiropractic
  if (isChiropractor(props.speciality)) {
    if (props.value === formTypeValues.nextReport) {
      return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic11c);
    }
    if (props.value === formTypeValues.firstReport) {
      return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic8c);
    }
    // if (dayDiff < -5 && props.value === formTypeValues.nextReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic11c);
    // };
    // if (dayDiff < -5 && props.value === formTypeValues.firstReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic8c);
    // };
    // if (dayDiff < -10 && props.value === formTypeValues.nextReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic11cr);
    // };
    // if (dayDiff < -10 && props.value === formTypeValues.firstReport) {
    //   return props.setValue(inputs.formType.name, formTypeCodes.chiropractic.chiropractic8cr);
    // };
  }
};

export const getRehabilitation = (type) => {
  return rehabilitationDropdownOptions.find((i) => i.value === type);
};
