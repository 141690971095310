import { useFormContext } from 'react-hook-form';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { getAge } from '../../../../utils/getAge';
import { checkIfCodeValid4Age } from '../helpers/claimFormValidation';
import { formatPatientsListForFirstVisit } from '../helpers/dataForSubmit';
import { inputs } from '../helpers/inputs';

export const useFeeCode = () => {
  const name = inputs.feeCodes.name;
  const { watch, firstVisit } = useFormContext();

  const feeCodeItemTemplate = (code) => `${code.label} (${currencyFormat(code.amount)})`;

  // CMO-1705 - Validate that the selected billing code matches the age before Generate Invoice
  // check if fee codes valid for age
  const selectedItemTemplateClass = (selectedCode) => {
    const patient = firstVisit ? formatPatientsListForFirstVisit(watch()) : watch(inputs.patient.name);
    const validateAge = patient?.reduce((acc, currentValue) => {
      const invalidCodes =
        watch(name)?.filter((i) => {
          return !checkIfCodeValid4Age(i, getAge(currentValue.BirthDay));
        }) || [];
      return [...acc, ...invalidCodes];
    }, []);

    return validateAge?.some((i) => i.trim() === selectedCode) ? 'p-error' : '';
  };

  return { feeCodeItemTemplate, selectedItemTemplateClass };
};
