import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useValidation } from '../../hooks/useValidation';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const StepOneActionButtons = () => {
  const history = useHistory();
  const { isDirty } = useFormContext();
  const { handleNext } = useValidation();

  return (
    <div className="flex justify-content-center mt-4 stickyActionButtons pt-5">
      <div className="flex w-full gap-3" style={{ maxWidth: '300px' }}>
        {!isDirty && <Button id={elementIDs.close} className="w-full" type="button" label={t('Close')} onClick={() => history.goBack()} />}

        {isDirty && (
          <>
            <Button id={elementIDs.next} className="w-full" type="button" label={t('Next')} onClick={handleNext} />

            <Button id={elementIDs.cancel} className="p-button-outlined w-full" type="button" label={t('Cancel')} onClick={() => history.goBack()} />
          </>
        )}
      </div>
    </div>
  );
};

export default StepOneActionButtons;
