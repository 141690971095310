export const getEClaimFormName = (type) => {
  const names = {
    AA: 'EOB',
    AR: 'REJ',
    AS: 'ACK',
    AE: 'error'
  };

  return names[type] || '';
};
