import { store } from '../../../store';
import { formatPatientDetails } from '../helpers/formatPatientDetails';
import patientsForAutocomplete from '../../../mocks/db/patients/patientsForAutocomplete.json';
import actions from './patients.action.types';
import { setPatientIdForHighlightInTable } from './patients.action.creators';
import cypressTestPatientInfoResponse from '../../../mocks/db/patients/getPatientInfo/cypressTestPatientInfoResponse.json';
import corVisitsPatientInfoResponse from '../../../mocks/db/patients/getPatientInfo/corVisitsPatientInfoResponse.json';
import validBCPHN from '../../../mocks/db/patients/phn/validBCPHN.json';
import invalidBCPHN from '../../../mocks/db/patients/phn/invalidBCPHN.json';
import duplicatePatientsListForLastNameValidation from '../../../mocks/db/patients/duplicatePatients/duplicatePatientsListForLastNameValidation.json';
import duplicatePatientsListForValidPHN from '../../../mocks/db/patients/duplicatePatients/duplicatePatientsListForValidPHN.json';
import duplicateArchivedPatientsListForValidPHN from '../../../mocks/db/patients/duplicatePatients/duplicateArchivedPatientsListForValidPHN.json';
import defaultEligibility from '../../../mocks/db/patients/eigibility/defaultEligibility.json';
import { setToastMessage } from '../../core/actions/core.action.creators';

export const createPatientResponseAction = (patient, callback) => {
  callback && callback(formatPatientDetails(patient));
};

export const createPatientResponseActionForTests = (patientGuid, callback) => {
  const defaultPatientGuid = 'ff6b9c13-a196-44a0-b4c1-b8fc23b4392a';
  let patient = patientsForAutocomplete.find((i) => i.PatientGuid?.toUpperCase() === patientGuid?.toUpperCase());
  if (!patient) patient = patientsForAutocomplete.find((i) => i.PatientGuid?.toUpperCase() === defaultPatientGuid?.toLocaleUpperCase());
  createPatientResponseAction(patient, callback);
};

export const getPatientInfoResponseAction = (responce, callback) => {
  if (callback) {
    return callback(formatPatientDetails(responce.data));
  } else {
    store.dispatch(setPatientIdForHighlightInTable(responce.data));
    return store.dispatch({ type: actions.FETCH_PATIENT_DETAILS_SUCCESS, results: responce });
  }
};

export const getPatientInfoResponseActionForTests = (id, callback) => {
  const patientInfo = getPatientInfoForTests(id);
  getPatientInfoResponseAction({ data: patientInfo }, callback);
};

export const getPatientInfoForTests = (id) => {
  const patients = {
    '4329b102-5196-4531-8bd6-d983b1c9d2a9': cypressTestPatientInfoResponse,
    '3c5f1516-9aff-456b-b1ec-367e57e8727b': corVisitsPatientInfoResponse
  };

  const patientInfo = patients[id] || cypressTestPatientInfoResponse;
  return formatPatientDetails(patientInfo.data);
};

export const validatePHNAction = (responceData, callback) => {
  callback && callback(responceData);
};

export const validatePHNActionForTests = (phn, callback) => {
  // If province is not BC, then any PHN is valid
  if (phn.slice(0, 2) !== 'BC') return validatePHNAction(true, callback);

  const phnList = {
    BC4242424242: true, // valid BC PHN
    BC9876543218: validBCPHN.result, // valid BC PHN
    BC9876543211: true, // valid BC PHN for archived patient
    BC1234567890: invalidBCPHN.result // invalid BC PHN
  };

  const currentPHN = phnList[phn] || invalidBCPHN.result;
  validatePHNAction(currentPHN, callback);
};

export const duplicatePatientCheckAction = (responceData, callback) => {
  callback && callback(responceData);
};

export const duplicatePatientCheckctionForTests = (patientData, callback) => {
  let duplicateList = [];

  if (patientData.LastName === 'NEW' && !patientData.PHN) {
    duplicateList = duplicatePatientsListForLastNameValidation;
  }

  if (!patientData.LastName && patientData.PHN?.replaceAll(' ', '') === '9876543218') {
    duplicateList = duplicatePatientsListForValidPHN;
  }

  // Archived patient with PHN
  if (!patientData.LastName && patientData.PHN?.replaceAll(' ', '') === '9876543211') {
    duplicateList = duplicateArchivedPatientsListForValidPHN;
  }

  duplicatePatientCheckAction(duplicateList, callback);
};

export const getEligibilityPHNAction = (responceData, callback) => {
  if (responceData.ErrorMessage) {
    return store.dispatch(setToastMessage({ type: 'error', message: responceData.ErrorMessage }));
  } else {
    callback && callback(responceData);
  }
};

export const getEligibilityActionForTests = (params, callback) => {
  const phnList = {
    9876543211: defaultEligibility,
    4242424242: defaultEligibility
  };

  const currentPHN = phnList[params.PHN.replaceAll(' ', '')] || invalidBCPHN.result;
  getEligibilityPHNAction(currentPHN, callback);
};
