import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';

import { inputs } from '../../../helpers/inputs';
import { useValidation } from '../../../hooks/useValidations';
import { useInsuredType } from '../../../hooks/useInsuredType';
import { insuredTypeOptions } from '../../../helpers/dropdownOptions';
import { InputWrapper } from '../../../../../../../components/Inputs';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const LabelLayout = () => {
  const name = inputs.insuredType.name;

  return (
    <>
      <Tooltip target=".insuredType_tooltip" />

      <i
        id={`${name}_infoIcon`}
        className="pi pi-info-circle cursor-pointer insuredType_tooltip"
        data-pr-tooltip={t('Who_is_the_insured_member_on_this_plan')}
        data-pr-position="top"
      />
    </>
  );
};

const InsuredType = () => {
  const name = inputs.insuredType.name;
  const { insurerTypeValidation } = useValidation();
  const { control, errors } = useFormContext();
  const { onChange } = useInsuredType();
  const errorMessage = errors[name]?.message;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => insurerTypeValidation(value) }}
      render={({ field }) => (
        <InputWrapper name={field.name} label={inputs.insuredType.label} required errorMessage={errorMessage} labelOptionalContent={<LabelLayout />}>
          <Dropdown
            inputRef={field.ref}
            inputId={field.name}
            className={cx({ 'p-invalid': errorMessage }, 'flex')}
            type="text"
            value={field.value}
            options={insuredTypeOptions}
            scrollHeight={dropdownScrollHeight()}
            onChange={(e) => onChange(e.target.value, field)}
          />
        </InputWrapper>
      )}
    />
  );
};

export default InsuredType;
