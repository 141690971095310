import React from 'react';
import { useHistory } from 'react-router-dom';
import { viewPatientProfile } from '../helpers/rowActionsMenuItems';
import CustomAvatar from '../../../../common/components/CustomAvatar';
import DeceasedBadge from '../../../../../components/Misc/Badges/Deceased/DeceasedBadge';
import { patientFullNameWithAge } from '../../../../utils/patientFullName';

const NameTemplate = ({ rowData }) => {
  const history = useHistory();

  const avatarLetter = `${rowData.LastName.charAt(0).toUpperCase()}${rowData.FirstName.charAt(0).toUpperCase()}`;

  return (
    <div className="flex align-items-center gap-2">
      <div className="fullname flex align-items-center" data-pr-tooltip="" style={{ cursor: 'pointer' }}>
        <div onClick={() => viewPatientProfile(rowData, history)}>
          <CustomAvatar id={rowData.PatientGuid} className="mr-4" label={avatarLetter} />
        </div>
        {patientFullNameWithAge(rowData)}
      </div>
      {/* <div>{rowData?.IsDeceased && <DeceasedBadge />}</div> */}
    </div>
  );
};

export default NameTemplate;
