import axios from 'axios';
import { setAuthHeader } from '../../../config';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { getApiBase } from '../../../config';
import { store } from '../../../store';
import { t } from '../../../service/localization/i18n';

export const getRoles = () => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  const baseUrl = `${getApiBase()}/clinic/${DCNGuid}/roles`;
  const url = new URL(baseUrl);

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getRoles', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getRoleById = (id) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  const baseUrl = `${getApiBase()}/clinic/${DCNGuid}/roles/${id}`;
  const url = new URL(baseUrl);

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getRoleById', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const createRole = (role) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/clinic/${DCNGuid}/roles`, {
      ...role,
      DCNGuid,
      Status: 1
    })
    .then((results) => {
      return true;
    })
    .catch((error) => {
      console.log('!!!createRole', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const updateRole = (role, id) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/clinic/${DCNGuid}/roles/${id}`, {
      ...role,
      DCNGuid
    })
    .then((results) => {
      return true;
    })
    .catch((error) => {
      console.log('!!!updateRole', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
export const updateRoleStatus = (role, id, status) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  var filter = {status};
  return axios
    .put(`${getApiBase()}/clinic/${DCNGuid}/roles/${id}?q=` + JSON.stringify(filter), {
      ...role,
      DCNGuid
    })
    .then((results) => {
      return true;
    })
    .catch((error) => {
      console.log('!!!updateRole', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};
export const deleteRoleById = (id) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  const baseUrl = `${getApiBase()}/clinic/${DCNGuid}/roles/${id}`;
  const url = new URL(baseUrl);

  return axios
    .delete(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!deleteRoleById', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};
