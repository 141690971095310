import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { catalogPatientChipsWrap, mainHeaderHeight } from '../../../modules/config/stylesConfig';

export const useCatalogContentSizeDesktop = ({ breadcrumbRef, slideMenuRef, currentCategory, hideTabs } = {}) => {
  const { clientHeight } = useSelector((state) => state.core.window);

  const [catalogBreadcrumbsHeight, setCatalogBreadcrumbsHeight] = useState(35);
  const [menuHeight, setMenuHeight] = useState(100);

  const desktopSidebarMenuWidth = 242;
  const catalogPatientChips = catalogPatientChipsWrap; // Selected patient chip
  const catalogTabs = hideTabs ? 0 : 36; // Main tabs (Dx, AOI, NOI)
  const catalogSubTabs = 36;
  const chipWrapHeight = 60;
  const quickPickWrapHeight = 48;
  const scrollableTableHeight =
    clientHeight - mainHeaderHeight - catalogPatientChips - catalogTabs - catalogSubTabs - quickPickWrapHeight - catalogBreadcrumbsHeight - 100;
  const tabLayoutWithSearchPanelHeight = clientHeight - mainHeaderHeight - catalogPatientChips - catalogTabs - catalogSubTabs - 110;
  const tabLayoutWithoutSearchPanelHeight = clientHeight - mainHeaderHeight - catalogTabs - catalogSubTabs - 110;
  const scrollableReferralTableHeight = clientHeight - mainHeaderHeight - catalogPatientChips - catalogTabs - quickPickWrapHeight - 110;

  // set breadcrumb container height
  useEffect(() => {
    //The height of the container can be changed if the breadcrumbs are too long and begin to wrap.
    if (breadcrumbRef?.current?.clientHeight) {
      setCatalogBreadcrumbsHeight(breadcrumbRef?.current?.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbRef?.current?.clientHeight]);

  //set slide menu height
  useEffect(() => {
    setMenuHeight(scrollableTableHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // scrollableTableHeight,
    currentCategory?.activeTabIndex,
    catalogBreadcrumbsHeight,
    slideMenuRef?.current?.slideMenuContent?.clientWidth
  ]);

  return {
    tabLayoutWithoutSearchPanelHeight,
    tabLayoutWithSearchPanelHeight,
    scrollableReferralTableHeight,
    desktopSidebarMenuWidth,
    scrollableTableHeight,
    quickPickWrapHeight,
    catalogPatientChips,
    chipWrapHeight,
    menuHeight
  };
};
