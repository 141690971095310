export const localStateInitValues = {
  showBatchRecordsWarn: false,
  initialDataForEdit: null,
  sendNow: false,
  resetWSBCR: true,
  searchValue: '',
  catalogIndex: 0,
  step: 1,
  focusFieldParams: {},
  claimData: {},
  showCatalogs: {
    desktop: true,
    mobile: false
  }
};
