import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { estimatedTimeDropdownOptions } from '../../../helpers/dropdownOptions';
import { dropdownScrollHeight } from '../../../../../../config/stylesConfig';
import { inputs, requiredInputs } from '../../../helpers/inputs';
import cx from 'classnames';

const EstimatedReturn = () => {
  const name = inputs.estimatedReturn.name;
  const { control, errors } = useFormContext();

  const estimatedTimeInput = (field) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.estimatedReturn.label}
        errorMessage={errors[name]?.message}
        required={!!requiredInputs[inputs.estimatedReturn.name]}
        style={{ height: '110px' }}
        labelStyle={{ height: 'unset' }}
      >
        <Dropdown
          id={field.name}
          inputRef={field.ref}
          className={cx({ 'p-invalid': errors[name]?.message })}
          value={field.value}
          scrollHeight={dropdownScrollHeight()}
          options={estimatedTimeDropdownOptions}
          onChange={(e) => field.onChange(e.value)}
        />
      </InputWrapper>
    );
  };

  return <Controller name={name} control={control} render={({ field }) => estimatedTimeInput(field)} />;
};

export default EstimatedReturn;
