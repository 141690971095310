import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import NewPayorFormLayout from './NewPayorFormLayout';
import { provinces_list } from '../../contants';
import { validateCanadianZip } from '../../../regex/regex';
import { t } from '../../../../service/localization/i18n';
import * as yup from 'yup';

const AddNewPayorForm = ({ formikInitValues, header, visible, onClose, onSave, showInDialog = false }) => {
  const [selectedProvince, setSelectedProvince] = useState('British Columbia');

  const onSubmit = (data) => {
    onSave(data);
  };

  const validationSchema = yup.object().shape({
    Province: yup
      .string()
      .required(t('Mandatory_field.1'))
      .test('test-province', t('Invalid_entry'), (value) => {
        return provinces_list.some((i) => {
          return i.label.toUpperCase() === value?.toUpperCase() || i.value.toUpperCase() === value?.toUpperCase();
        });
      }),
    Zip: yup.string().matches(validateCanadianZip, t('Invalid_entry'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitValues || {
      CompanyName: '',
      LastName: '',
      FirstName: '',
      AddressLine: '',
      City: '',
      Zip: '',
      Province: selectedProvince,
      AddressType: '',
      Email: '',
      Phone: ''
    },
    validationSchema,
    onSubmit
  });

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const newPayorForm = (
    <form onSubmit={formik.handleSubmit} className="p-fluid">
      <NewPayorFormLayout
        formik={formik}
        selectedProvince={selectedProvince}
        isFormFieldValid={isFormFieldValid}
        setSelectedProvince={setSelectedProvince}
        getFormErrorMessage={getFormErrorMessage}
      />
      <div className="flex">
        <Button label={t('Save')} type="submit" disabled={!formik.dirty} />
        <Button label={t('Cancel')} onClick={onClose} className="p-button-text" type="button" />
      </div>
    </form>
  );

  return (
    <div>
      {showInDialog ? (
        <Dialog
          header={header}
          visible={visible}
          breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
          style={{ width: '25vw' }}
          onHide={onClose}
        >
          {newPayorForm}
        </Dialog>
      ) : (
        <>{newPayorForm}</>
      )}
    </div>
  );
};

export default AddNewPayorForm;
