import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';

import { getPatients } from '../../../actions/patients.action.creators';
import { columnValues } from '../helpers/columnValues';
import { t } from '../../../../../service/localization/i18n';

const options = [
  { name: t('All'), code: -1 },
  { name: t('LFP'), code: 1 },
  { name: t('Non_LFP'), code: 0 }
];

const LFPFilter = () => {
  const currentColumn = columnValues['lfp'];
  const currentField = currentColumn.filter;
  const defaultValue = options?.find((i) => i.code === -1);

  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.patients);
  const [value, setValue] = useState(defaultValue);

  // Update local state when chip is deleted
  useEffect(() => {
    const initValue = options?.find((i) => i.code === filters?.[currentField]);
    setValue(initValue || defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.[currentField]]);

  const onChange = (e) => {
    const _value = e.target.value;
    const updatedFilters = { ...filters, [currentField]: `${_value?.code}` };
    setValue(_value);
    dispatch(getPatients({ filters: updatedFilters, page: 0 }));
  };

  return (
    <Dropdown
      value={value}
      options={options}
      optionLabel="name"
      placeholder={currentColumn.placeholder}
      style={{ minWidth: '110px' }}
      onChange={onChange}
    />
  );
};

export default LFPFilter;
