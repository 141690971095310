import React from 'react';
import { submissionCodes } from '../../../../../../modules/claims/helpers/mapper';

const SubmissionCodeLayout = ({ rowData }) => {
  const submissionCode = rowData?.SubmissionCode ? submissionCodes?.find((i) => i.value === rowData?.SubmissionCode) : submissionCodes[0];

  // return <>{submissionCode.label}</>;
  return rowData?.SubmissionCode === '0' ? 'O' : rowData?.SubmissionCode;
};

export default SubmissionCodeLayout;
