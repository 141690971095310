import React from 'react';
import { useFormContext } from 'react-hook-form';
import StepOne from './StepOne';

const Steps = () => {
  const { localState } = useFormContext();

  return (
    <>
      {/* Show Teleplan Invoice form */}
      {localState.step === 1 && <StepOne />}
    </>
  );
};

export default Steps;
