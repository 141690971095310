import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import EligibilityButtonsSection from './EligibilityButtonsSection';
import Eligibility from '../../Layouts/Eligibility';
import StreetAdress from '../Inputs/StreetAdress';
import BirthDate from '../Inputs/BirthDate';
import FirstName from '../Inputs/FirstName';
import Province from '../Inputs/Province';
import LastName from '../Inputs/LastName';
import MidName from '../Inputs/MidName';
import Gender from '../Inputs/Gender';
import City from '../Inputs/City';
import Zip from '../Inputs/Zip';
import PHN from '../Inputs/PHN';

import { inputs } from '../../../helpers/inputs';
import Nickname from '../Inputs/Nickname';
import AccessWord from '../Inputs/AccessWord';

const FirstVisitSection = () => {
  const { control, firstVisit } = useFormContext();
  const phnProvince = useWatch({ control, name: inputs.phnProvince.name });

  if (!firstVisit) return null;

  return (
    <>
      <div className="phn_birthday_inputs_wrap">
        <PHN />
        <BirthDate />
      </div>

      <EligibilityButtonsSection />

      <div className="two_inputs_in_a_row">
        <LastName />
        <FirstName />
      </div>

      <div className="two_inputs_in_a_row">
        <MidName />
        <Gender />
      </div>

      {/* <div className="two_inputs_in_a_row">
        <Nickname />
        <AccessWord />
      </div> */}

      {/* <Eligibility /> */}

      {phnProvince !== 'BC' && (
        <>
          <div className="two_inputs_in_a_row">
            <StreetAdress />
            <City />
          </div>

          <div className="two_inputs_in_a_row">
            <Province />
            <Zip />
          </div>
        </>
      )}
    </>
  );
};

export default FirstVisitSection;
