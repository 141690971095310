import React from 'react';
import PromptDialog from './PromptDialog';

const Dialogs = () => {
  return (
    <>
      {/* HERE GOES ALL DIALOG WINDOWS */}
      <PromptDialog />
    </>
  );
};

export default Dialogs;
