import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { groupBy, values } from 'lodash';

const RowNumberBodyTemplate = ({ rowData }) => {
  const { localState } = useFormContext();
  const records = localState.records;

  // Memoize the grouped records by service_date to avoid unnecessary recalculations
  const groupedRecordsByServiceDate = useMemo(() => groupBy(records, 'service_date'), [records]);

  // Convert the grouped records into an array of arrays
  const arrayOfArrays = useMemo(() => values(groupedRecordsByServiceDate), [groupedRecordsByServiceDate]);

  // Function to get the row number of the given rowData
  const getRowNumber = () => {
    for (let arr of arrayOfArrays) {
      // Find the index of the rowData in the current array
      const index = arr.findIndex((i) => i.id === rowData.id);
      if (index !== -1) {
        // Return 1-based index if found
        return index + 1;
      }
    }
    // Return -1 if rowData.id is not found
    return -1;
  };

  // Calculate the row number for the given rowData
  const rowNumber = getRowNumber();

  // Render the row number
  return (
    <span id="rowNumber" className="font-bold">
      {rowNumber}.
    </span>
  );
};

export default RowNumberBodyTemplate;
