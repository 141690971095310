import React from 'react';
import { useDispatch } from 'react-redux';
import InputWithClear from '../../common/components/InputWithClear';
import { statusOptionsFilter } from '../../utils/statusOptionsFilter';
import { setFilter } from '../../utils/legoSortFilter';
import { t } from '../../../service/localization/i18n';

const ClaimSearch = ({ statuses, filters, isMobile, isMobileOnly, isLandscape, showOnPatientDetailsScreen }) => {
  const dispatch = useDispatch();
  const init = filters.freetext?.length ? filters.freetext : ''; // [KS] CMO-832 - Top search filter looses the keyword after opening and closing a record, but the filter stays on

  const filterParameters = { statuses, filters, statusOptionsFilter, dispatch };

  return (
    <InputWithClear
      id="freetext_dd"
      initValue={init}
      isMobile={isMobile}
      isMobileOnly={isMobileOnly}
      isLandscape={isLandscape}
      showOnPatientDetailsScreen={showOnPatientDetailsScreen}
      placeholder={isMobileOnly && !isLandscape ? t('Search') : t('Find claim.desktop')}
      onChange={(e) => setFilter(e, filterParameters)}
    />
  );
};

export default ClaimSearch;
