import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { quickPickCodeChipCommonClass } from '../../../../../../../../config/stylesConfig';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { isTimeValid } from '../../../../../../../../utils/formatTime';
import moment from 'moment';

const durationList = [
  {
    value: '8',
    label: '8 min'
  },
  {
    value: '10',
    label: '10 min'
  },
  {
    value: '15',
    label: '15 min'
  },
  {
    value: '23',
    label: '23 min'
  },
  {
    value: '30',
    label: '30 min'
  },
  {
    value: '45',
    label: '45 min'
  },
  {
    value: '60',
    label: '60 min'
  },
  {
    value: '90',
    label: '90 min'
  }
];

const Duration = ({ rowOptions }) => {
  const { localState } = useFormContext();
  const { onTimeChange } = useGroupPickerInputs();
  const rowIndex = rowOptions.rowIndex;
  const startTime = rowOptions.rowData[inputs.startTime.name];
  const disabled =
    (rowIndex > 0 && localState.applyToAllTime) || (rowIndex > 0 && !isTimeValid(startTime) && localState.applyToAllTime) || !isTimeValid(startTime);

  const onTimeClick = (time, isSelected) => {
    const formattedTime = moment(startTime, 'HH:mm').add(time, 'minutes').format('HH:mm');
    const newTime = isSelected ? '' : formattedTime;
    onTimeChange(newTime, inputs.endTime.name, rowOptions);
  };

  return (
    <div className="quick_pick_items_list">
      {durationList?.map((i) => {
        const isSelected = Number(i.value) === rowOptions.rowData[inputs.duration.name];
        return (
          <Button
            key={i.value}
            tabIndex={-1}
            className={quickPickCodeChipCommonClass(isSelected, { order: i.order })}
            label={i.label}
            disabled={disabled}
            onClick={() => onTimeClick(i.value, isSelected)}
          />
        );
      })}
    </div>
  );
};

export default Duration;
