import React from 'react';

import DatePicker from './Common/DatePicker';

import { inputs } from '../../../helpers/inputs';
import { useLastName } from '../../../hooks/useLastName';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import moment from 'moment';

const BirthDate = () => {
  const { lastNameOnBlur } = useLastName();

  return (
    <DatePicker
      id={elementIDs.birthDate}
      input={inputs.birthDay}
      required
      yearRange={`1900:${moment().year()}`}
      onBlur={(value, field) => lastNameOnBlur(value, field)}
    />
  );
};

export default BirthDate;
