import React from 'react';
import ApplyToAllButton from './ApplyToAllButton';
import ClearAllButton from './ClearAllButton';
import PatientsPicker from './PatientsPicker';
import DuplicateDxButton from './DuplicateDxButton';
import DuplicateReferralButton from './DuplicateReferralButton';
import SettingsButton from './SettingsButton';

const GroupPickerTableHeader = () => {
  return (
    <div
      className="flex flex-column md:flex-row justify-content-between align-items-center font-normal"
      style={{ height: '45px', rowGap: '1.5rem', columnGap: '1.5rem' }}
    >
      <PatientsPicker />

      <div className="flex justify-content-center align-items-center" style={{ rowGap: '3rem', columnGap: '3rem' }}>
        <SettingsButton />
        <DuplicateDxButton />
        <DuplicateReferralButton />
        <ApplyToAllButton />
        <ClearAllButton />
      </div>
    </div>
  );
};

export default GroupPickerTableHeader;
