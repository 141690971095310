import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import TeleplanCatalogs from '../TeleplanCatalogs/TeleplanCatalogs';
import TeleplanInvoiceForm from '../TeleplanInvoiceForm/TeleplanInvoiceForm';
import RotateYoutDeviseMessage from '../../views/TeleplanGroup/Layouts/RotateYoutDeviseMessage';
import FormWithCatalogsWrap from '../../../../../../components/Wraps/FormWithCatalogsWrap/FormWithCatalogsWrap';
import FormActionButtons from '../ActionButtons/FormActionButtons';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { setToastPosition } from '../../../../../core/actions/core.action.creators';

const StepOne = () => {
  const dispatch = useDispatch();
  const { firstVisit, pos, isGroup, localState } = useFormContext();
  const { toastMessagePosition } = useSelector((state) => state.claims);
  const { showNewTeleplanButtons } = useSelector((state) => state.core.window);
  const rootId = firstVisit ? `${elementIDs.teleplanInvoiceFormContainer}_firstVisit` : elementIDs.teleplanInvoiceFormContainer;

  useEffect(() => {
    // Set toast messages postion to the bottom-left
    if (pos || isGroup) {
      dispatch(setToastPosition('bottom-left'));
    } else {
      toastMessagePosition !== 'bottom-right' && dispatch(setToastPosition('bottom-right'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showNewTeleplanButtons && isGroup) return <RotateYoutDeviseMessage />;

  return (
    <div id={rootId}>
      <div id={elementIDs.teleplanInvoiceForm} className="flex">
        <FormWithCatalogsWrap
          hideRightCard={!localState.showCatalogs.desktop}
          inverseCards={pos || isGroup}
          leftCardContent={<TeleplanInvoiceForm />}
          leftCardActionButtons={<FormActionButtons />}
          rightCardContent={<TeleplanCatalogs />}
        />
      </div>
    </div>
  );
};

export default StepOne;
