import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';

const FeeEditor = ({ options }) => {
  const { onFeeEditorChange } = useUpdateRecords();

  return (
    <div className="relative">
      <InputNumber
        inputStyle={{ width: 'inherit' }}
        style={{ width: '10rem' }}
        className="flex"
        type="text"
        value={options.rowData.fee}
        mode="currency"
        currency="USD"
        locale="en-US"
        onChange={(e) => onFeeEditorChange(e.value, options)}
      />
    </div>
  );
};

export default FeeEditor;
