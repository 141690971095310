import React from 'react';
import { useFormContext } from 'react-hook-form';

import Catalogs from '../../../../../../components/Catalogs/Catalogs';

import { inputs } from '../../helpers/inputs';
import { usePatient } from '../../hooks/usePatient';
import { useCatalogTabs } from '../../hooks/useCatalogTabs';
import { useToggleCatalogs } from '../../../TeleplanInvoice/hooks/useToggleCatalogs';

const EClaimPolicyCatalogs = () => {
  const { watch, localState } = useFormContext();
  const { toggleCatalogs } = useToggleCatalogs();
  const { catalogTabs } = useCatalogTabs();
  const { onPatientChange } = usePatient();
  const patients = watch(inputs.patient.name);

  return (
    <Catalogs
      patients={patients}
      catalogTabs={catalogTabs}
      activeTabIndex={0}
      showCloseButton
      removablePatientChip={false}
      showCatalogs={localState.showCatalogs}
      toggleCatalogs={toggleCatalogs}
      // Handlers
      onPatientChipRemove={onPatientChange}
    />
  );
};

export default EClaimPolicyCatalogs;
