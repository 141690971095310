import React from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

import QuickPickItem from './QuickPickItem';
import QuickPickEmergency from './QuickPickEmergency';
import PatientsCout from '../../Layouts/PatientsCout';
import RefToBy from '../../TeleplanInvoiceForm/Inputs/RefToBy';
import AnatomicCode from '../../TeleplanInvoiceForm/Inputs/AnatomicCode';
import PatientPicker from '../../TeleplanInvoiceForm/Inputs/PatientPicker';
import Asterisk from '../../../../../../../components/Misc/Asterisk/Asterisk';
import ReferralQuickPickAlphabetFilter from './ReferralQuickPickAlphabetFilter';
import LockPrefsButton from '../../../../../../../components/Buttons/LockButton/LockPrefsButton';

import { usePreferences } from '../../../hooks/usePreferences';
import { inputs } from '../../../helpers/inputs';
import { isSupplementary } from '../../../../../../config/specialitiesConfig';
import { getGroupInvoicesTemplates } from '../../../../../actions/claims.action.creators';
import { getCategoryType } from '../../../../../helpers/getCategoryType';
import {
  aoiInputRequirements,
  feeCodeInputRequirements,
  icd9InputRequirements,
  noiInputRequirements,
  referralInputRequirements
} from '../../../helpers/validationSchema';
import { prefsCodes } from '../../../../../../config/prefsCodesConfig';
import { NA_DoctorGuid } from '../../../../../../config/defaultValuesConfig';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

// interface = {
//   item: {
//     name: string,
//     label: string,
//     codeDescription: string,
//     codeType: string,
//     searchAllType?: string,
//     showDivider?: boolean,
//     isCodePicker?: boolean,
//     showSwitchButton?: boolean,
//     openCatalogButton?: boolean,
//   }
// }

const CodePickerLayout = (props) => {
  const name = props.item.name;
  const { watch, isNew } = useFormContext();
  const { catalogMostRecentCodes } = useSelector((state) => state.claims);
  const invoiceType = watch(inputs.payor.name);

  const type =
    props.item.codeType === inputs.feeCodes.codeType && isNew && !invoiceType
      ? `${inputs.feeCodes.codeType}_msp`
      : getCategoryType(props.item.codeType, invoiceType);
  const mostRecentCodes = catalogMostRecentCodes[type];

  if (!props.item.isCodePicker) return null;

  return (
    <div id={`${name}_items`}>
      <QuickPickItem item={props.item} mostRecentCodes={mostRecentCodes} showActionButtons />
    </div>
  );
};

const Patient = ({ name }) => {
  const { isGettingPrefs } = useSelector((state) => state.preferences);
  const { isGroup, setLocalState, watch } = useFormContext();
  const showPatientInput = name === inputs.patient.name;
  const practitionerGuid = watch(inputs.practitioner.name);

  if (!showPatientInput) return null;

  if (isGroup) {
    const onTemplateClick = async () => {
      setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: true, groupTemplateListDialog: true }));
      const groupTemplate = await getGroupInvoicesTemplates(watch(inputs.practitioner.name));
      setLocalState((prevState) => ({ ...prevState, gettingGroupInvoicesTemplate: false }));
      if (groupTemplate) setLocalState((prevState) => ({ ...prevState, templatesList: groupTemplate }));
    };

    return (
      <div className="flex w-full buttonsGap">
        <PatientPicker hideLabel={true} />
        <Button
          style={{ maxHeight: '40px', width: '40px' }}
          icon="pi pi-th-large"
          className="p-button-outlined"
          tooltip={t('Batch_Templates')}
          tooltipOptions={{ position: 'top' }}
          disabled={isGettingPrefs || practitionerGuid === NA_DoctorGuid}
          onClick={onTemplateClick}
        />
      </div>
    );
  }

  return <PatientPicker hideLabel={true} />;
};

const RefToByRadio = () => {
  const { control } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const hideReferral = isSupplementary(speciality, invoiceType);

  if (hideReferral) return null;

  return (
    <div className="pl-2">
      <RefToBy hideLabel />
    </div>
  );
};

const Label = (props) => {
  const name = props.item.name;
  const { watch, errors } = useFormContext();
  const { isLockedPrefsForEmergency, onSavePreferencesForEmergency, isLocked, onSaveRefToByPreferences } = usePreferences();
  const label = props.item.label ? `${props.item.label}:` : '';

  const labelIcon = (name) => {
    const saveAsDraft = watch(inputs.saveAsDraft.name);
    const invoiceType = watch(inputs.payor.name);
    const refToBy = watch(inputs.refToBy.name);
    const isServiceDateRequired = name === inputs.serviceDate.name;
    const isFeeCodeRequired = name === inputs.feeCodes.name && feeCodeInputRequirements(saveAsDraft);
    const isDxCodeRequired = name === inputs.icd9.name && icd9InputRequirements(saveAsDraft);
    const isAOICodeRequired = name === inputs.aoi.name && aoiInputRequirements(invoiceType, saveAsDraft);
    const isNOICodeRequired = name === inputs.noi.name && noiInputRequirements(invoiceType, saveAsDraft);
    const asteriskIcon = <Asterisk iconId={`${name}_catalogsMandatoryFieldIcon`} tooltipId={name} />;

    if (name === inputs.emergency.name) {
      return (
        <div>
          <LockPrefsButton name={name} isLocked={isLockedPrefsForEmergency()} onSavePrefs={onSavePreferencesForEmergency} />
        </div>
      );
    }

    if (isServiceDateRequired) return asteriskIcon;
    if (isFeeCodeRequired) return asteriskIcon;
    if (isDxCodeRequired) return asteriskIcon;
    if (isAOICodeRequired) return asteriskIcon;
    if (isNOICodeRequired) return asteriskIcon;

    if (name === inputs.referral.name) {
      const isReferralCodeRequired = referralInputRequirements(refToBy, saveAsDraft);
      const defaultCode = prefsCodes.defaultReferralDirection;

      return (
        <div className="flex" style={{ columnGap: '10px' }}>
          <div>
            <LockPrefsButton name={name} isLocked={isLocked(refToBy, defaultCode)} onSavePrefs={onSaveRefToByPreferences} />
          </div>
          {isReferralCodeRequired && asteriskIcon}
        </div>
      );
    }

    return true;
  };

  return (
    <div className="pb-2">
      <div className={cx('flex large-text', !!errors[name] ? 'p-error' : '')} style={{ width: '155px', columnGap: '10px' }}>
        {label}
        {label && labelIcon(name)}
      </div>

      {name === inputs.patient.name && (
        <div className="pt-1">
          <PatientsCout />
        </div>
      )}
    </div>
  );
};

const QuickPickItemsBlock = (props) => {
  const name = props.item.name;
  const showEmergency = name === inputs.emergency.name;
  const { isGroup, control } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });
  const invoiceType = useWatch({ control, name: inputs.payor.name });
  const showRefToBy = name === inputs.referral.name && !isGroup && !isSupplementary(speciality, invoiceType);
  const showAnatomicCode = name === inputs.anatomicCode.name;
  const showAlphabetFilter = name === inputs.referral.name;

  return (
    <>
      <div className="quickPickBlockWrap">
        <Label item={props.item} />

        <Patient name={name} />

        {showEmergency && <QuickPickEmergency />}

        <div>
          {showAnatomicCode && (
            <div style={{ height: '45px' }}>
              <AnatomicCode hideLabel />
            </div>
          )}
          {showRefToBy && <RefToByRadio />}
          {showAlphabetFilter ? <ReferralQuickPickAlphabetFilter /> : null}
          <CodePickerLayout item={props.item} />
        </div>
      </div>

      {props.item.showDivider && <Divider />}
    </>
  );
};

export default QuickPickItemsBlock;
