import React from 'react';
import { useFormContext } from 'react-hook-form';

import { InputSwitch } from 'primereact/inputswitch';

import { isTimeValid } from '../../../../../../../../utils/formatTime';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';

const ApplyToAllTime = () => {
  const { localState, setLocalState } = useFormContext();
  const { applySameDuration } = useGroupPickerInputs();

  const onClick = (checkValue) => {
    if (checkValue) {
      const removeFirstRow = localState.groupRecords.slice(1);
      const isEdnTimeOnSecondRow = removeFirstRow?.length ? isTimeValid(removeFirstRow[0][inputs.endTime.name]) : false;
      const isTimeInAnyRow = removeFirstRow.slice(1).some((i) => i[inputs.startTime.name] || i[inputs.endTime.name]);
      const hasTimeValues = isTimeInAnyRow || isEdnTimeOnSecondRow;

      if (hasTimeValues) {
        return setLocalState((prevState) => ({ ...prevState, sameDurationDialog: true }));
      } else {
        applySameDuration();
        setLocalState((prevState) => ({ ...prevState, applyToAllTime: checkValue }));
      }
    } else {
      setLocalState((prevState) => ({ ...prevState, applyToAllTime: checkValue }));
    }
  };

  return (
    <div className="flex align-items-center w-full" style={{ rowGap: '1.5rem', columnGap: '1.5rem' }}>
      {t('Same_Duration')}

      <InputSwitch
        checked={localState.applyToAllTime}
        tooltip={t('Apply_to_all_time')}
        tooltipOptions={{ showDelay: 500, position: 'top' }}
        onChange={(e) => onClick(e.value)}
      />
    </div>
  );
};

export default ApplyToAllTime;
