import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Prompt } from 'react-router-dom';
import { useFormik } from 'formik';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Skeleton } from 'primereact/skeleton';
import { SplitButton } from 'primereact/splitbutton';

import Success from './Success';
import CommonInputField from '../../../common/components/CommonInputField';
import DeleteConfirmation from '../../../common/components/DeleteConfirmation';

import { paymentMethodOptions } from './helpers/paymentMethodOptions';
import { InputNumber } from 'primereact/inputnumber';
import { integersOnly } from '../../../regex/regex';
import { currencyFormat } from '../../../utils/currencyFormat';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { deletePayment, getClaimInfo, saveReceivePayment, setPaymentDetails } from '../../actions/claims.action.creators';
import { trimLeftZeros } from '../../../utils/trimLeftZeros';
import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { icons } from '../../../config/stylesConfig';
import { t } from '../../../../service/localization/i18n';
import classes from './ReceivePayment.module.scss';
import cx from 'classnames';
import moment from 'moment';
import * as yup from 'yup';
// import YearNavigator from '../../../../components/Inputs/DatePicker/YearNavigator';
// import MonthNavigator from '../../../../components/Inputs/DatePicker/MonthNavigator';

const validationSchema = yup.object().shape({
  PaymentDate: yup.date().required(t('Mandatory_field.1')).nullable(),
  PaymentMethod: yup.string().required(t('Mandatory_field.1')).nullable(),
  FeePaid: yup.number().required(t('Mandatory_field.1')).nullable().moreThan(0, t('Fee_Paid_cannot_be_zero')) // Added validation to ensure FeePaid is greater than 0
});

const ReceivePayment = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isMobileOnly } = useSelector((state) => state.core.window);
  const { paymentDetails, savingReceivePayment, deletingPayment, gettingClaimInfo } = useSelector((state) => state.claims);

  const isNew = location?.pathname?.indexOf(routes.receivePayment.path) > -1;
  const feePaidRef = useRef(null);

  const balanceDue = () => paymentDetails.FeeTotal - paymentDetails.FeePaid;

  const initValues = () => {
    if (isNew) {
      return {
        DCNGuid: paymentDetails?.DCNGuid,
        InvoiceGuid: paymentDetails?.InvoiceGuid,
        PaymentDate: new Date(),
        PaymentMethod: '',
        LastDigitsOfCreditCard: '',
        FeePaid: balanceDue()
      };
    }

    if (!isNew) {
      const currentPayment = paymentDetails?.Payments?.find((i) => i.PaymentGuid === id);
      return {
        ...currentPayment,
        PaymentDate: new Date(currentPayment?.PaymentDate)
      };
    }
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [amountRecieved, setAmountRecieved] = useState(0);
  const [formikInitValues, setFormikInitValues] = useState(initValues());

  useEffect(() => {
    if (isNew) {
      dispatch(
        getClaimInfo(paymentDetails?.InvoiceGuid, (responseData) => {
          const feePaid = responseData.FeeTotal - responseData.FeePaid;
          dispatch(setPaymentDetails(responseData));
          setFormikInitValues({
            ...formikInitValues,
            DCNGuid: responseData?.DCNGuid,
            InvoiceGuid: responseData?.InvoiceGuid,
            FeePaid: feePaid
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    dispatch(
      saveReceivePayment(data, isNew, () => {
        dispatch(setPaymentDetails({ ...paymentDetails, FeePaid: data?.FeePaid }));
        // setShowSuccessPage(true);
        formik.resetForm();
        history.replace(`${routes.privetInvoice.path}/${paymentDetails?.InvoiceGuid}`);
      })
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitValues,
    validationSchema,
    onSubmit
  });

  const onChangeLastDigitsOfCreditCard = (e) => {
    if (e.target.value.length > 4) return;
    formik.handleChange(e);
  };

  const onAcceptDeletePayment = () => {
    dispatch(
      deletePayment(formik.values.PaymentGuid, () => {
        formik.resetForm();
        dispatch(setToastMessage({ type: 'success', message: t('The_record_was_successfully_deleted') }));
        history.goBack();
        dispatch(setPaymentDetails({}));
      })
    );
  };

  const onAmountChange = (e) => {
    const value = e.value > balanceDue() ? balanceDue() : e.value;
    setAmountRecieved(value);
    setDirty(true);
  };

  const onFeePaidClear = () => {
    formik.setFieldValue('FeePaid', null);
    setAmountRecieved(0);
    feePaidRef?.current?.focus();
  };

  // Disabled because of CMO-2050 - program overrides amount received to match the amount owned by the patient
  const maxFeePaid = () => {
    if (balanceDue() === 0) return null;
    return balanceDue() < 0 ? 0 : balanceDue();
  };

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const promptMessage = () => {
    return JSON.stringify({ isDirty: formik.dirty });
  };

  const splitButtonItems = [
    {
      label: t('Delete'),
      icon: icons.delete,
      command: () => setShowDeleteConfirmation(true)
    }
  ];

  const cancelButton = (
    <Button id={elementIDs.cancel} className="p-button-outlined" type="button" label={t('Cancel')} onClick={() => history.goBack()} />
  );

  return (
    <div
      id={elementIDs.recordPayment}
      className={cx('px-3', showSuccessPage ? '' : cx('flex justify-content-center', isMobileOnly ? 'mt-3 mb-6' : 'mt-5'))}
    >
      <Prompt when={formik.dirty} message={promptMessage} />

      {!showSuccessPage && (
        <div className={cx('card', classes.receivePaymentCard)}>
          <h5 className="text-center">{isNew ? t('Receive_Payment') : t('Edit_Payment')}</h5>

          <div className="flex mt-3">
            <div className={cx('flex mr-2', classes.currenciesWrap)}>
              <div className="mr-2">{`${t('Invoices.3')} `}</div>
              <div id={elementIDs.invoiceNumber} className="font-bold">
                {`#${trimLeftZeros(paymentDetails?.RecordNo)}`}
              </div>
            </div>

            <div className="flex align-items-center px-2">
              <div className="mr-2">{`${t('for')}:`}</div>
              <div id={elementIDs.for} className="flex align-items-center font-bold">
                {(gettingClaimInfo || isNaN(balanceDue())) && <Skeleton width="70px" />}
                {!gettingClaimInfo && !isNaN(balanceDue()) && currencyFormat(balanceDue())}
              </div>
            </div>
          </div>

          <div className="flex my-3">
            <div className="mr-2">{`${t('Payer')}: `}</div>
            <div id={elementIDs.payer} className="font-bold">
              {`${paymentDetails?.BillTo?.LastName}, ${paymentDetails?.BillTo?.FirstName}`}
            </div>
          </div>

          <div className="flex align-items-center justify-content-between mb-3">
            <div className={cx('flex align-items-center mr-2', classes.currenciesWrap)}>
              <div className={cx('mr-2', !(balanceDue() - amountRecieved) ? '' : 'font-bold p-error')}>{`${t('Due')}:`}</div>
              <div id={elementIDs.due} className={cx('flex align-items-center font-bold', !(balanceDue() - amountRecieved) ? '' : 'p-error')}>
                {(gettingClaimInfo || isNaN(balanceDue())) && <Skeleton width="70px" />}
                {!gettingClaimInfo && !isNaN(balanceDue()) && `${currencyFormat(balanceDue() - amountRecieved)}`}
              </div>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <CommonInputField
              className="col-12 p-0 mb-1"
              required
              label={t('Payment_Date')}
              labelId={elementIDs.paymentDateLabel}
              errorMessage={getFormErrorMessage('PaymentDate')}
            >
              <Calendar
                className={cx({
                  'p-invalid': isFormFieldValid('PaymentDate')
                })}
                inputId={elementIDs.paymentDate}
                id="PaymentDate"
                name="PaymentDate"
                placeholder={t('MM_DD_YYYY')}
                dateFormat="mm/dd/yy"
                mask="99/99/9999"
                showIcon={true}
                showButtonBar
                inputMode="text"
                selectOtherMonths
                // monthNavigator
                // yearNavigator
                baseZIndex={1000000}
                yearRange={`1900:${moment().year()}`}
                // monthNavigatorTemplate={(e) => <MonthNavigator event={e} />}
                // yearNavigatorTemplate={(e) => <YearNavigator event={e} />}
                value={formik.values.PaymentDate}
                onChange={formik.handleChange}
              />
            </CommonInputField>

            <CommonInputField className="col-12 p-0 mb-1" label={t('Payment_Method')} required errorMessage={getFormErrorMessage('PaymentMethod')}>
              <Dropdown
                className={cx(classes.feePaidInput, {
                  'p-invalid': isFormFieldValid('PaymentMethod')
                })}
                id={elementIDs.paymentMethodDropdown}
                name="PaymentMethod"
                options={paymentMethodOptions}
                value={formik.values.PaymentMethod}
                onChange={formik.handleChange}
              />
            </CommonInputField>

            <CommonInputField
              className="col-12 p-0 mb-1"
              label={t('Last_Digits_Of_Credit_Card')}
              errorMessage={getFormErrorMessage('LastDigitsOfCreditCard')}
            >
              <InputText
                className={cx({
                  'p-invalid': isFormFieldValid('LastDigitsOfCreditCard')
                })}
                id={elementIDs.lastDigitsOfCreditCard}
                name="LastDigitsOfCreditCard"
                type="tel"
                placeholder="xxxx"
                keyfilter={integersOnly}
                value={formik.values.LastDigitsOfCreditCard?.substring(0, 4)?.trim()}
                onChange={onChangeLastDigitsOfCreditCard}
              />
            </CommonInputField>

            <CommonInputField className="col-12 p-0 mb-1" label={t('Amount_Received')} required errorMessage={getFormErrorMessage('FeePaid')}>
              <span className="p-input-icon-right">
                <InputNumber
                  className={cx(classes.feePaidInput, {
                    'p-invalid': isFormFieldValid('FeePaid')
                  })}
                  inputRef={feePaidRef}
                  id="FeePaid"
                  name="FeePaid"
                  inputId={elementIDs.localeUS}
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                  // max={maxFeePaid()} // Disabled because of CMO-2050 - program overrides amount received to match the amount owned by the patient
                  minFractionDigits={2}
                  value={formik.values.FeePaid}
                  onChange={onAmountChange}
                  onValueChange={formik.handleChange}
                />
                {formik.values.FeePaid ? <i className="pi pi-times pointer" onClick={onFeePaidClear} /> : null}
              </span>
            </CommonInputField>

            {/* Action buttons */}
            <div className="flex justify-content-between pb-2 gap-3 stickyActionButtons">
              {(formik.dirty || dirty) && (
                <>
                  {isNew && <Button id={elementIDs.save} type="submit" loading={savingReceivePayment} label={t('Save')} />}

                  {!isNew && (
                    <SplitButton
                      className="w-full"
                      type="submit"
                      // loading={savingReceivePayment || deletingPayment}
                      disabled={savingReceivePayment || deletingPayment}
                      label={t('Save')}
                      model={splitButtonItems}
                      onClick={() => formik.submitForm()}
                    />
                  )}

                  {cancelButton}
                </>
              )}

              {!formik.dirty && !dirty && (
                <>
                  {!isNew && (
                    <Button
                      id={elementIDs.delete}
                      className="p-button-outlined"
                      type="button"
                      label={t('Delete')}
                      loading={deletingPayment}
                      onClick={() => setShowDeleteConfirmation(true)}
                    />
                  )}
                  {isNew && <Button id={elementIDs.save} type="submit" loading={savingReceivePayment} label={t('Save_and_Close')} />}
                  {cancelButton}
                </>
              )}
            </div>
          </form>
        </div>
      )}

      {/* {showSuccessPage && (
        <Success isNew={isNew} paymentDetails={paymentDetails} />
      )} */}

      <DeleteConfirmation
        visible={showDeleteConfirmation}
        header={t('Delete_record')}
        message={t('Do_you_want_to_delete_this_record')}
        accept={onAcceptDeletePayment}
        onHide={() => setShowDeleteConfirmation(false)}
        reject={() => setShowDeleteConfirmation(false)}
      />
    </div>
  );
};

export default ReceivePayment;
