import React from 'react';
import InvoicesCard from './InvoicesCard';
import ContactInfoCard from './ContactInfoCard';
import EligibilityCard from './EligibilityCard';
import cx from 'classnames';

const Profile = ({ isMobileOnly, patient_details, readonly, claimsPage, showSkeleton, isFetchingClaims }) => {
  return (
    <div className="flex justify-content-center">
      <div className="grid col-12 mt-4 mx-0 p-0">
        <div className={cx('col-12 lg:col-6', isMobileOnly ? 'p-0' : 'py-0')}>
          {/* CONTACT INFO */}
          <div id="patient-profile-contact-card">
            <ContactInfoCard patient_details={patient_details} readonly={readonly} showSkeleton={showSkeleton} />
          </div>

          <div id="patient-profile-eligibility-card" className="my-3">
            {/* ELIGIBILITY INFO */}
            <EligibilityCard showSkeleton={showSkeleton} patient_details={patient_details} />
          </div>
        </div>

        {/* INVOICES */}
        <div id="patient-profile-invoices-card" className={cx('col-12 lg:col-6 py-0', isMobileOnly ? 'p-0' : 'py-0')}>
          <InvoicesCard claimsPage={claimsPage} showSkeleton={showSkeleton} patient_details={patient_details} isFetchingClaims={isFetchingClaims} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
