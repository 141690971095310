import React from 'react';

import { Skeleton } from 'primereact/skeleton';

import { trimLeftZeros } from '../../../../../utils/trimLeftZeros';
import { inputs } from '../../claimDetailsHelpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { commonInputs } from '../../../../../config/commonInputsConfig';
import { t } from '../../../../../../service/localization/i18n';
import moment from 'moment';
import cx from 'classnames';

const InvoiceDetailsSection = ({ data, isFetching }) => {
  return (
    <div className="invoice-details invoiceSectionWrap">
      <div className="invoice-label mb-0 mt-1">{t('Status').toUpperCase()}</div>
      <div id={elementIDs.dueDate} className="invoice-value flex justify-content-end">
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <span className={cx('customer-badge-mobile py-1', `cm_status_${data?.Status}`)}>{data?.StatusText}</span>
        )}
      </div>

      <div className="invoice-label">{`${t('Invoices.3').toUpperCase()} #`}</div>
      <div id={elementIDs.invoiceNumber} className="invoice-value flex justify-content-end">
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="invoice-value flex justify-content-end">{trimLeftZeros(data[inputs().invoiceNumber.name])}</div>
        )}
      </div>

      <div className="invoice-label">{t('Date').toUpperCase()}</div>
      <div id={elementIDs.serviceDate} className="invoice-value flex justify-content-end">
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="invoice-value flex justify-content-end">
            {data[commonInputs.serviceDate.name] ? moment(data[commonInputs.serviceDate.name]).format('MM/DD/YYYY') : ''}
          </div>
        )}
      </div>

      <div className="invoice-label">{t('Due_Date').toUpperCase()}</div>
      <div id={elementIDs.dueDate} className="invoice-value flex justify-content-end">
        {isFetching ? (
          <Skeleton width="7rem" className="mt-1" />
        ) : (
          <div className="invoice-value flex justify-content-end">{moment(data[inputs().dueDate.name]).format('MM/DD/YYYY')}</div>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetailsSection;
