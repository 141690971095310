import React from 'react';
import { useController } from 'react-hook-form';

import WrappedDatePicker from '../DatePicker/WrappedDatePicker';

import { serviceDateFormat } from '../../../modules/utils/serviceDateFormat';
import { localStorageKeys } from '../../../modules/config/localStorageKeysConfig';
import { commonInputs } from '../../../modules/config/commonInputsConfig';
import { t } from '../../../service/localization/i18n';

// interface = {
//  control: UseControllerProps<FormValues>, // react-hook-form control

//  disabled?: boolean,
//  selectionMode?: single | multiple,
//  onChange?: (value: Date) => void,
//  onBlur?: (value: Date) => void
// };

const ServiceDateWithFormControl = (props) => {
  const name = commonInputs.serviceDate.name;
  const label = commonInputs.serviceDate.label;
  const {
    field,
    formState: { errors }
  } = useController({
    name,
    control: props.control,
    rules: { validate: (value) => validation(value) }
  });
  const errorMessage = errors[name]?.message;
  const input = { name, label };

  const validation = (value) => {
    // Check if serviceDate is an array and has at least one date element
    if (Array.isArray(value) && value.length > 0) {
      return true;
    }

    // Check if serviceDate is a single date and it's not null or undefined
    if (!Array.isArray(value) && value !== null && value !== undefined) {
      return true;
    }

    return t('Mandatory_field.1');
  };

  const handleChange = (value) => {
    if (props.selectionMode !== 'multiple') {
      // [KS] CMO-1423 - Persist service date during session
      localStorage.setItem(localStorageKeys.serviseDate, JSON.stringify(value ? serviceDateFormat(value) : null));
    }

    props.onChange ? props.onChange(value) : field.onChange(value);
  };

  const handleBlur = (value) => {
    if (props.onBlur) props.onBlur(value, field);
  };

  return (
    <WrappedDatePicker
      {...field}
      required
      input={input}
      errorMessage={errorMessage}
      disabled={props.disabled}
      selectionMode={props.selectionMode || 'single'}
      onChange={(value) => handleChange(value)}
      onBlur={(value) => handleBlur(value)}
    />
  );
};

export default ServiceDateWithFormControl;
