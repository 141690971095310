import { columnValues } from "./columnValues";
import { InputText } from "primereact/inputtext";
import { t } from "../../../../../service/localization/i18n";
import { Dropdown } from 'primereact/dropdown';
import DoctorNameTemplate from "./DoctorNameTemplate";

export const columns = (props, rowData) => {
  return [

    {
      id: columnValues.firstName.id,
      field: columnValues.firstName.field,
      header: columnValues.firstName.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.firstName.placeholder,
      filterElement: textFilterElement('firstName', props),
      body: (rowData) => <DoctorNameTemplate rowData={rowData} />
    },
    {
      id: columnValues.lastName.id,
      field: columnValues.lastName.field,
      header: columnValues.lastName.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.lastName.placeholder,
      filterElement: textFilterElement('lastName', props)
    },
    {
      id: columnValues.number.id,
      field: columnValues.number.field,
      header: columnValues.number.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.number.placeholder,
      body: (rowData) => rowData.PractitionerNumber || t('N_A'),
      filterElement: textFilterElement('number', props),
      style: { minWidth: '190px', maxWidth: '190px' }
    },
    {
      id: columnValues.phone.id,
      field: columnValues.phone.field,
      header: columnValues.phone.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.phone.placeholder,
      body: (rowData) => rowData.Phone || t('N_A'),
      filterElement: textFilterElement('phone', props)
    },
    {
      id: columnValues.email.id,
      field: columnValues.email.field,
      header: columnValues.email.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.email.placeholder,
      body: (rowData) => rowData.Email || t('N_A'),
      filterElement: textFilterElement('email', props)
    },
    // {
    //   id: 'role',
    //   field: 'role',
    //   header: 'Role',
    //   sortable: true,
    //   filter: false, // Disable filtering for this column
    //   frozen: false,
    //   body: rowData => (
    //     <Dropdown
    //       value={rowData.Role || 'Guest'}
    //       options={['Guest']}
    //       onChange={(e) => {
    //         // Update the role for the current row
    //       }}
    //       placeholder="Select a role"
    //     />
    //   ),
    // }
  ];
};

const textFilterElement = (columnKey, props) => {
  const currentColumn = columnValues[columnKey];
  const value = props.filters.find((i) => i.field === currentColumn.field)?.value || '';

  return (
    <span className="p-input-icon-right">
      <InputText
        // type="search" // not support in Firefox
        value={value}
        placeholder={currentColumn.placeholder}
        onChange={(e) => onChangeTextFilter(e, { ...props, currentColumn })}
      />
      {value && <i className="pi pi-times" onClick={() => onChangeTextFilter({ target: { value: '' } }, { ...props, currentColumn })} />}
    </span>
  );
};

const onChangeTextFilter = (e, props) => {
  const value = e.target.value;
  props.dtRef.current?.filter(value, props.currentColumn.field, props.currentColumn.matchMode);
  updateChip({ ...props, value });
};

const updateChip = (props) => {
  const getCurrentChip = props.filters?.find((i) => i.label === props.currentColumn.header);

  if (props.value) {
    if (getCurrentChip) {
      const updatedList = props.filters?.map((i) => {
        return i.field === props.currentColumn.field
          ? {
              label: props.currentColumn.header,
              value: props.value,
              field: props.currentColumn.field
            }
          : i;
      });
      return props.setFilters(updatedList);
    }
    return props.setFilters([
      ...props.filters,
      {
        label: props.currentColumn.header,
        value: props.value,
        field: props.currentColumn.field
      }
    ]);
  } else {
    const removeChip = props.filters?.filter((i) => i.field !== props.currentColumn.field);
    return props.setFilters(removeChip);
  }
};
