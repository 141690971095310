import React from 'react';
import Asterisk from '../../../../../../../../../components/Misc/Asterisk/Asterisk';
import { inputs } from '../../../../../helpers/inputs';

const AsterisksForReferralCodeInput = ({ rowOptions }) => {
  const inputName = inputs.comment.name;
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const showAsterisks = rowOptions.rowData[inputs.refToBy.name] !== 'N';

  if (!showAsterisks) return <div style={{ width: '6px' }}></div>;

  return <Asterisk tooltipId={`${inputName}_${rowId}`} />;
};

export default AsterisksForReferralCodeInput;
