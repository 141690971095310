import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputCharacterCounter, InputWrapper } from '../../../../../../components/Inputs';

import { setToastMessage } from '../../../../../core/actions/core.action.creators';
import { revokeEClaim } from '../../../../actions/claims.action.creators';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { localStateInitValues } from '../../helpers/initialState';
import { inputs } from '../../helpers/inputs';
import { steps } from '../../config/steps';
import { t } from '../../../../../../service/localization/i18n';
import cx from 'classnames';

const RevokeDialog = () => {
  const dispatch = useDispatch();
  const { localState, setLocalState, setValue } = useFormContext();
  const messageMaxLength = 400;

  const { watch, handleSubmit, formState, reset, register } = useForm({
    defaultValues: { reason: '' }
  });

  const reason = watch('reason');
  const errorMessage = formState.errors['reason']?.message;

  const onSubmit = async (data) => {
    const payload = { ...data, claimGuid: localState.revokeDialog.data.ClaimGuid };
    const result = await revokeEClaim(payload);

    if (result) {
      onHideDialog();
      setValue(inputs.claimGuid.name, result?.ClaimGuid);
      setLocalState((prevState) => ({ ...prevState, step: steps.submitProcessing }));
    } else {
      dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
    }
  };

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, revokeDialog: localStateInitValues.revokeDialog }));
    reset();
  };

  const actionButtons = (
    <>
      <Button type="submit" form="revokeForm" id={elementIDs.dialogSave} label={t('Submit')} loading={formState.isSubmitting} />

      <Button type="button" id={elementIDs.dialogCancel} className="p-button-outlined" label={t('Cancel')} onClick={onHideDialog} />
    </>
  );

  const inputFooterContent = () => <InputCharacterCounter text={reason} countLimit={messageMaxLength} />;

  return (
    <Dialog
      id={elementIDs.revokeDialog}
      header={t('Revoke')}
      visible={localState.revokeDialog.showDialog}
      breakpoints={{ '1366px': '50vw', '960px': '70vw', '768px': '90vw' }}
      style={{ width: '30vw' }}
      footer={actionButtons}
      onHide={onHideDialog}
    >
      <form id="revokeForm" onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <InputWrapper name="reason" errorMessage={errorMessage} footerContent={inputFooterContent} style={{ height: 'auto' }}>
          <InputTextarea
            rows={1}
            cols={30}
            autoResize
            autoFocus
            maxLength={messageMaxLength}
            className={cx({ 'p-invalid': errorMessage })}
            {...register('reason', {
              required: t('Mandatory_field.1')
            })}
          />
        </InputWrapper>
      </form>
    </Dialog>
  );
};

export default RevokeDialog;
