import { t } from '../../service/localization/i18n';
import { getAge } from './getAge';

const getFullName = (patient) => {
  const nameParts = [];

  if (patient?.LastName) {
    nameParts.push(patient?.LastName);
  }

  if (patient?.FirstName) {
    if (nameParts.length > 0) {
      nameParts.push(', ');
    }
    nameParts.push(patient?.FirstName);
  }

  if (patient?.MidName) {
    if (nameParts.length > 0) {
      nameParts.push(' ');
    }
    nameParts.push(patient?.MidName);
  }

  let patientName = nameParts.join('');

  return patientName;
};

export const patientFullNameWithAge = (patient) => {
  if (!patient) return '';

  let patientName = getFullName(patient);

  const age = getAge(patient?.BirthDay || patient?.DOB);
  const sex = patient?.Sex || ''; // Handle null, undefined, or empty string

  if (age >= 0) {
    if (patientName.length > 0) {
      patientName += ' ';
    }
    patientName += `(${age}${sex})`;
  }

  if (patient?.LfpPanel) {
    patientName += `, ${t('LFP')}`;
  }

  if (patient?.IsDeceased) {
    patientName += `, ${t('DCD')}`;
  }

  return patientName;
};

export const patientFullNameWithNicknameAndAge = (patient) => {
  if (!patient) return '';

  let patientName = getFullName(patient);

  if (patient?.AlterName) {
    patientName += ` "${patient?.AlterName}"`;
  }

  const age = getAge(patient?.BirthDay);
  const sex = patient?.Sex || ''; // Handle null, undefined, or empty string

  if (age >= 0) {
    if (patientName.length > 0) {
      patientName += ' ';
    }
    patientName += `(${age}${sex})`;
  }

  if (patient?.LfpPanel) {
    patientName += `, ${t('LFP')}`;
  }

  if (patient?.IsDeceased) {
    patientName += `, ${t('DCD')}`;
  }

  return patientName;
};

export const patientFullName = (patient) => {
  if (!patient) return '';

  let patientName = getFullName(patient);

  if (patient?.LfpPanel) {
    patientName += `, ${t('LFP')}`;
  }

  if (patient?.IsDeceased) {
    patientName += `, ${t('DCD')}`;
  }

  return patientName;
};

export const patientLastNameWithInitials = (patient, showAge = false) => {
  if (!patient) return '';

  const nameParts = [];

  if (patient?.LastName) {
    nameParts.push(patient?.LastName);
  }

  if (patient?.FirstName) {
    if (nameParts.length > 0) {
      nameParts.push(', ');
    }
    nameParts.push(`${patient?.FirstName.charAt(0)}.`);
  }

  if (patient?.MidName) {
    if (nameParts.length > 0) {
      nameParts.push(' ');
    }
    nameParts.push(`${patient?.MidName.charAt(0)}.`);
  }

  let patientName = nameParts.join('');

  if (showAge) {
    const age = getAge(patient?.BirthDay);
    const sex = patient?.Sex || ''; // Handle null, undefined, or empty string

    patientName += ` (${age}${sex})`;
  }

  if (patient?.LfpPanel) {
    patientName += `, ${t('LFP')}`;
  }

  if (patient?.IsDeceased) {
    patientName += `, ${t('DCD')}`;
  }

  return patientName;
};
