import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import EmergencyTooltipLayout from '../Layouts/EmergencyTooltipLayout';

import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const EmergencyInfoDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideEmergencyDialog = () => {
    setLocalState((prevState) => ({
      ...prevState,
      emergencyInfoDialog: false
    }));
  };

  const emergencyDialogFooter = () => {
    return <Button id={elementIDs.dialogClose} label={t('Close')} onClick={onHideEmergencyDialog} autoFocus />;
  };

  return (
    <Dialog
      id={elementIDs.emergencyInfoDialog}
      header={t('Out-Of-Office_Hours_Premiums')}
      visible={localState.emergencyInfoDialog}
      breakpoints={{ '1366px': '40vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '30vw' }}
      footer={emergencyDialogFooter()}
      onHide={onHideEmergencyDialog}
    >
      <EmergencyTooltipLayout />
    </Dialog>
  );
};

export default EmergencyInfoDialog;
