import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { multipleDoctorGuid, NA_DoctorGuid } from '../../../../config/defaultValuesConfig';
import { getEClaimLocations, getPrefs } from '../../../../../service/Lookup';
import { getPractitionerByGuid, getPractitionerGuid, getPractitionersList } from '../../../../utils/getPractitioner';
import { practitionerOptions } from '../helpers/practitionerOptions';
import { getPromiseAll } from '../../../../utils/getPromiseAll';
import { inputs } from '../helpers/inputs';
import { useLocation } from './useLocation';
import { getEClaimsServices } from '../../../actions/claims.action.creators';
import { useConditions } from './useConditions';
import { initPayee } from '../helpers/defaultValues';

export const usePractitioner = () => {
  const user = useSelector((state) => state.user.details);
  const { setLocalState, setValue, clearErrors } = useFormContext();
  const { getLocation } = useLocation();
  const { catalogType } = useConditions();

  const getListOfPractitioners = () => {
    const practitioenrs = getPractitionersList({ naOption: true });
    const tecPractitioners = practitioenrs.filter((i) => i.canBillTEC || i.DoctorGuid === NA_DoctorGuid);
    return tecPractitioners || [];
  };

  const onChange = async (doctorGuid, field) => {
    const practitionerGuid = getPractitionerGuid(doctorGuid);
    const selectedPractitioner = getPractitionerByGuid(doctorGuid, practitionerOptions);
    const speciality = Number(selectedPractitioner.Speciality);

    field.onChange(doctorGuid); // Update practitioner
    setValue(inputs.speciality.name, speciality); // Update speciality;

    // Prepare requests for e-Claim locations
    const practitionerLocationsListRequest =
      doctorGuid !== NA_DoctorGuid && doctorGuid !== multipleDoctorGuid
        ? {
            pbc: getEClaimLocations(doctorGuid, 'pbc'),
            tec: getEClaimLocations(doctorGuid, 'tec')
          }
        : {};

    // Prepare requests for e-Claim services
    const servicesListRequest =
      doctorGuid !== NA_DoctorGuid && doctorGuid !== multipleDoctorGuid ? { servicesList: getEClaimsServices({ catalogType, speciality }) } : {};

    const requests = {
      ...servicesListRequest,
      ...practitionerLocationsListRequest,
      prefs: getPrefs(user.DCNGuid, practitionerGuid)
    };

    // Execute all requests concurrently and merge results into a single object
    const results = await getPromiseAll(requests);

    const practitionerPrefs = results?.prefs?.prefs?.find((i) => i.label === 'default') || {};
    const pbc = results.pbc || [];
    const tec = results.tec || [];
    const servicesList = results.servicesList || [];

    // Update location
    const location = getLocation({ pbcOptions: pbc, tecOptions: tec, practitionerPrefs });
    setValue(inputs.location.name, location);
    // Update payee (Pay To)
    const payee = initPayee({ practitionerPrefs });
    setValue(inputs.payee.name, payee);

    // Clear errors for Location and Pay To inputs
    clearErrors([inputs.location.name, inputs.payee.name]);

    setLocalState((prevState) => ({
      ...prevState,
      servicesList,
      isLoading: false,
      practitionerPrefs,
      locationsList: { pbc, tec }
    }));
  };

  return { practitionerOptions, onChange, getListOfPractitioners };
};
