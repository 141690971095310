import React from 'react';
import MobileCatalogs from './MobileCatalogs';
import DesktopCatalogs from './DesktopCatalogs';
import { useCatalogsContext } from '../hooks/useCatalogsContext';

const CatalogsView = () => {
  const { id } = useCatalogsContext();

  return (
    <>
      <div className="hidden lg:inline" id={id}>
        <DesktopCatalogs />
      </div>
      <div className="block lg:hidden" id={id}>
        <MobileCatalogs />
      </div>
    </>
  );
};

export default CatalogsView;
