export const addNewItemToArray = (arr, index, newItem) => {
  let updatedArray = [...arr];

  if (Array.isArray(newItem)) {
    // If `newItem` is an array of objects, insert each object individually
    updatedArray.splice(index, 0, ...newItem);
  } else {
    // If `newItem` is a single object, insert it at the specified index
    updatedArray.splice(index, 0, newItem);
  }

  return updatedArray;
};
