import React from 'react';
import { currencyFormat } from '../../utils/currencyFormat';
import { t } from '../../../service/localization/i18n';
import { trimLeftZeros } from '../../utils/trimLeftZeros';
import { stringFormat } from '../../utils/stringFormat';

export const submitNewClaimSuccessMessage = (data) =>
  String.format(t('Claim_is_submitted'), trimLeftZeros(data?.RecordNo), `${data?.LastName}, ${data?.FirstName}`.toUpperCase());
export const saveNewClaimSuccessMessage = t('Your_changes_are_saved');
export const archiveClaimSuccessMessage = t('Claim_has_been_archived');
export const deleteClaimSuccess = t('Claim_deleted_successfully');
export const declinedClaimWithFiltersIsEditedSuccessMessage = t('Updated_record_is_hidden_becuase_of_the_filters');
export const patientsLimitWarnMessage = t('You_selected_too_many_patients');
export const checkIfFeeCode03333WarnMessage = t('Please_create_a_claim_for_03333_code_separately');
export const submitAllClaimsSuccessMessage = (data) => {
  const totalValid = data?.TotalValid;
  const totalInValid = data?.TotalInValid;
  const totalAmount = currencyFormat(data?.TotalAmount);

  if (totalInValid) {
    const messageText = t('You_have_successfully_submitted_x_claims_for_y_z_records_did_not_pass');
    return stringFormat(messageText, totalValid, totalAmount, totalInValid);
  }

  return stringFormat(t('You_have_successfully_submitted_x_claims_for_y'), totalValid, totalAmount);
};

export const emergencyHelperText = {
  title: t('Out-Of-Office_Hours_Premiums'),
  detail: (
    <div>
      <p>{t('YES_premiums')}</p>
      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-2">{t('the_physician_is_specially_called')}</li>
        <li>{t('the_service_is_emergency')}</li>
      </ul>
      <p>{t('The_applicable_fee_for_out-of-office_hours')}</p>
    </div>
  )
};

export const immdiateTravelHelperText = {
  title: t('Call-Out_Charges'),
  detail: (
    <div>
      <p>{t(`YES_charges`)}</p>
      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-2">{t('had_to_travel_to_see_the_patient')}</li>
        <li className="pb-2">{t('had_to_leave_immediately')}</li>
        <li>{t('first_patient_examined')}</li>
      </ul>
      <p>{t('The_out-of-office_hours_premium_listings')}</p>
    </div>
  )
};

export const CCFPPHelperText = {
  title: t('Continuing_Care_Surcharges'),
  detail: (
    <div>
      <p>{t(`YES_indicates`)}</p>
      <ul style={{ listStyleType: 'disc' }}>
        <li className="pb-2">{t('add_CCFPP_note_to_the_claim')}</li>
        <li>{t('calculate_the_timing')}</li>
      </ul>
      <p>{t('Select_No_to_stat')}</p>
    </div>
  )
};

export const feeItemsHelperText = {
  title: t('Fee_Items_Codes'),
  detail: t('Select_procedures_and_services')
};

export const patientHelperText = {
  title: t('Patient_field_info'),
  detail: t('Select_multiple_patients')
};
