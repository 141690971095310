import { InputText } from 'primereact/inputtext';
import { integersOnly } from '../../../../regex/regex';
import { rowHeight } from './columns';
import { onEditorValueChange } from './onEditorValueChange';
import { elementIDs } from '../../../../config/elementIDsConfig';
import { t } from '../../../../../service/localization/i18n';
import cx from 'classnames';

export const quantityEditor = (params, records, setRecords, setIsReviewDirty, mobileView) => {
  const onBlur = (e) => {
    if (Number(e.target.value) === 0) {
      onEditorValueChange(params, '1', 'quantity', records, setRecords, setIsReviewDirty);
    }
  };

  return (
    <div
      className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')}
      style={mobileView ? {} : { position: 'absolute', top: '10px', zIndex: '1' }}
    >
      {/* If row is empty */}
      {records[params.rowIndex]?.id === '-1' && (
        <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ color: `rgba(0, 0, 0, 0.87)` }}>
          <span className="p-column-title">{t('Quantity.1').toUpperCase()}</span>
          <div className={cx('flex', mobileView ? 'align-items-start' : 'align-items-center')} style={{ height: rowHeight }}>
            {records[params.rowIndex]?.quantity}
          </div>
        </div>
      )}

      {records[params.rowIndex]?.id !== '-1' && (
        <>
          <span className="p-column-title">{t('Quantity.1').toUpperCase()}</span>
          <InputText
            id={elementIDs.quantityInput}
            value={records[params.rowIndex]?.quantity}
            keyfilter={integersOnly}
            autoFocus
            style={{ height: rowHeight, width: '5em' }}
            onChange={(e) => onEditorValueChange(params, e.target.value, 'quantity', records, setRecords, setIsReviewDirty)}
            onBlur={onBlur}
          />
        </>
      )}
    </div>
  );
};
