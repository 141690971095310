import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from 'primereact/chart';
import ChartCard from './ChartCard';
import { currencyFormat } from '../../../utils/currencyFormat';
import { colors } from '../../../config/stylesConfig';
import { isChartDataEmpty } from '../../helpers/isChartsDataEmpty';
import { onBarClick } from '../../helpers/onBarClick';
import moment from 'moment';

export default function BillingTotals() {
  const data = useSelector((state) => state.cashFlow.data);
  const loading = useSelector((state) => state.cashFlow.cashFlowLoading);
  const dateFormat = 'MMM Do, YYYY';

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const options = useMemo(() => {
    return {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              return dataset.tooltipLabels[tooltipItem.dataIndex]; // Use tooltipLabels from dataset
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true, // Stack the X axis
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          stacked: true, // Stack the Y axis
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      },
      onClick: onBarClick
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textColor, textColorSecondary, surfaceBorder]);

  const chartData = useMemo(() => {
    if (data && data.ListSideBySide) {
      // Step 1: Gather all unique periods across all datasets
      const allPeriodsSet = new Set();
      data.ListSideBySide.forEach((dataset) => {
        dataset.StackData.forEach((item) => {
          allPeriodsSet.add(moment(item.PeriodStart).format(dateFormat));
        });
      });
      const allPeriods = Array.from(allPeriodsSet).sort((a, b) => new Date(a) - new Date(b));

      // Step 2: Extract data for Billed, Paid, In Process, and Declined, aligning with all periods
      const billedData = data.ListSideBySide.find((d) => d.DataName === 'Billed')?.StackData || [];
      const paidData = data.ListSideBySide.find((d) => d.DataName === 'Paid')?.StackData || [];
      const inProcessData = data.ListSideBySide.find((d) => d.DataName === 'In Process')?.StackData || [];
      const declinedData = data.ListSideBySide.find((d) => d.DataName === 'Declined')?.StackData || [];

      // Step 3: Align data with all periods, ensuring every period has a value
      const alignDataWithPeriods = (dataset, valueKey) => {
        return allPeriods.map((period) => {
          const item = dataset.find((i) => moment(i.PeriodStart).format(dateFormat) === period);
          return item ? item[valueKey] : 0; // If period not found, default to 0
        });
      };

      const billedValues = alignDataWithPeriods(billedData, 'TotalBilled');
      const paidValues = alignDataWithPeriods(paidData, 'TotalPaid');
      const inProcessValues = alignDataWithPeriods(inProcessData, 'TotalBilled');
      const declinedValues = alignDataWithPeriods(declinedData, 'TotalBilled');

      const formatClaimsLabel = (count) => (count === 1 ? 'claim' : 'claims');

      // Step 4: Create tooltip labels for each dataset, aligning with all periods
      const createTooltipLabels = (dataset, labelPrefix, valueKey) => {
        return allPeriods.map((period) => {
          const item = dataset.find((i) => moment(i.PeriodStart).format(dateFormat) === period);
          return item ? `${labelPrefix}: ${item.TotalCount} ${formatClaimsLabel(item.TotalCount)}, ${currencyFormat(item[valueKey])}` : '';
        });
      };
      const totalBilled = data?.TotalBilled;
      const billedTooltipLabels = createTooltipLabels(billedData, 'Billed', 'TotalBilled');
      const paidTooltipLabels = createTooltipLabels(paidData, 'Paid', 'TotalPaid');
      const inProcessTooltipLabels = createTooltipLabels(inProcessData, 'In Process', 'TotalBilled');
      const declinedTooltipLabels = createTooltipLabels(declinedData, 'Declined', 'TotalBilled');

      // Step 5: Prepare datasets for the chart
      const datasets = [
        {
          id: 'billed',
          label: `Billed (${currencyFormat(totalBilled)})`,
          backgroundColor: colors.billed,
          data: billedValues,
          tooltipLabels: billedTooltipLabels
        },
        {
          id: 'paid',
          label: `Paid (${currencyFormat(paidData.reduce((acc, item) => acc + item.TotalPaid, 0))})`,
          backgroundColor: colors.success,
          data: paidValues,
          tooltipLabels: paidTooltipLabels,
          stack: 'Stack2'
        },
        {
          id: 'in_process',
          label: `In Process (${currencyFormat(inProcessData.reduce((acc, item) => acc + item.TotalBilled, 0))})`,
          backgroundColor: colors.info,
          data: inProcessValues,
          tooltipLabels: inProcessTooltipLabels,
          stack: 'Stack2'
        },
        {
          id: 'declined',
          label: `Declined (${currencyFormat(declinedData.reduce((acc, item) => acc + item.TotalBilled, 0))})`,
          backgroundColor: colors.danger,
          data: declinedValues,
          tooltipLabels: declinedTooltipLabels,
          stack: 'Stack2'
        }
      ];

      return {
        labels: allPeriods, // Use all unique periods as labels
        datasets
      };
    }

    return null;
  }, [data]);

  return (
    <ChartCard title="Billed and Paid Claims, Excluding Adjustments (CAD)" loading={loading} isChartDataEmpty={isChartDataEmpty(chartData)}>
      <Chart type="bar" data={chartData} options={options} />
    </ChartCard>
  );
}
