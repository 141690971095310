import { useState } from 'react';
import ClaimNotes from './ClaimNotes';
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';

const NotesCounter = ({ totalNotes, invoiceGuid }) => {
  const [visible, setVisible] = useState(false);

  if (!totalNotes || totalNotes === 0 || totalNotes === '0') {
    return null;
  }

  return (
    <>
      <Badge className="cursor-pointer" value={totalNotes} severity="info" onClick={() => setVisible(true)} />
      <Dialog
        header="Notes"
        visible={visible}
        blockScroll
        breakpoints={{ '1366px': '60vw', '960px': '70vw', '768px': '95vw' }}
        style={{ width: '50vw', height: '90vh' }}
        onHide={() => setVisible(false)}
      >
        <ClaimNotes invoiceGuid={invoiceGuid} />
      </Dialog>
    </>
  );
};

export default NotesCounter;
