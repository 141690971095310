import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { inputs } from '../../../../../modules/claims/components/PrivateDetails/claimDetailsHelpers/inputs';
import { currencyFormat } from '../../../../../modules/utils/currencyFormat';
import { getDiscountCurrency } from '../../../../../modules/claims/components/PrivateDetails/reviewPanelHelpers/calculateTotal';
import { salesTaxList } from '../../../../../modules/claims/components/PrivateDetails/reviewPanelHelpers/salesTaxList';
import { t } from '../../../../../service/localization/i18n';

const DataTableSection = ({ data }) => {
  const clinic = useSelector((state) => state.clinic);
  const [expandedRows, setExpandedRows] = useState({});
  const tableData = data[inputs().privateService.descriptionName];

  //set expanded rows
  useEffect(() => {
    const rowsWithNote = tableData?.filter((i) => i.note);
    tableData && setExpandedRows(Object.fromEntries(rowsWithNote?.map((i) => [i.id, true])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const rowExpansionTemplate = (data) => {
    return <div className="flex">{data.note}</div>;
  };

  const quantityBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{rowData?.quantity}</span>
      </div>
    );
  };

  const discountBodyTemplate = (rowData) => {
    let discountValue = rowData['discount'] === 0 || !rowData['discount'] ? '―' : currencyFormat(getDiscountCurrency(rowData));

    if (rowData['discount'] === 0 || !rowData['discount']) {
      discountValue = '―';
    } else {
      if (rowData.discountUnits === 'percent') discountValue = `${rowData.discount}%`;
      if (rowData.discountUnits !== 'percent') discountValue = currencyFormat(getDiscountCurrency(rowData));
    }

    return (
      <div className="flex">
        <span>{discountValue}</span>
      </div>
    );
  };

  const providerBodyTemplate = (rowData) => {
    const practitioner = rowData.licenceNumber ? `${rowData.treatedBy}, #${rowData.licenceNumber}` : `${rowData.treatedBy}`;
    return (
      <div className="flex">
        <span className="text-left">
          {practitioner}
          {ractitionerNumbersLayout()}
        </span>
      </div>
    );
  };

  const ractitionerNumbersLayout = (practitionerGuid) => {
    const currentPractitioner = clinic.members?.find((i) => i.UserGuid === practitionerGuid);
    const licenceNumber = currentPractitioner?.LicenceNumber;
    const practitionerNumber = currentPractitioner?.PractitionerNumber;
    let numbers = '';

    if (licenceNumber && practitionerNumber) numbers = `${licenceNumber}/${t('MSP')} ${licenceNumber}`;
    if (licenceNumber && !practitionerNumber) numbers = `${licenceNumber}`;
    if (!licenceNumber && practitionerNumber) numbers = `${practitionerNumber}`;

    if (!numbers) return null;

    return (
      <>
        <br />
        <br />
        <span>{numbers}</span>
      </>
    );
  };

  const salesTaxBodyTemplate = (rowData) => {
    const currentTax = salesTaxList.find((i) => i.name === rowData.salesTax)?.text;
    return (
      <div className="flex">
        <span className="text-left">{currentTax}</span>
      </div>
    );
  };

  const serviceBodyTemplate = (rowData) => {
    const isService = rowData.category !== 'product';
    const serviceText = rowData.unit && isService ? `${rowData.label}, ${rowData.duration} ${rowData.unit}` : `${rowData.label}`;

    return (
      <div className="flex">
        <span className="text-left">{serviceText}</span>
      </div>
    );
  };

  const serviceNumberBodyTemplate = (_, { rowIndex }) => {
    return rowIndex + 1;
  };

  const amountBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{currencyFormat(rowData?.amount)}</span>
      </div>
    );
  };

  const feeAmountBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{currencyFormat(rowData?.feeAmount)}</span>
      </div>
    );
  };

  return (
    <DataTable dataKey="id" value={tableData} expandedRows={expandedRows} rowExpansionTemplate={(e) => rowExpansionTemplate(e)}>
      <Column header="No." body={serviceNumberBodyTemplate} style={{ width: '4%' }} />
      <Column field="label" header={t('Service_Product.2').toUpperCase()} body={serviceBodyTemplate} style={{ width: '25%' }} />
      <Column field="label" header={t('Provider').toUpperCase()} body={providerBodyTemplate} style={{ width: '23%' }} />
      <Column field="quantity" header={t('Quantity.1').toUpperCase()} body={quantityBodyTemplate} style={{ width: '8%' }} />
      <Column field="amount" header={t('Fee').toUpperCase()} body={amountBodyTemplate} style={{ width: '10%' }} />
      <Column field="feeAmount" header={t('Amount').toUpperCase()} body={feeAmountBodyTemplate} style={{ width: '10%' }} />
      {tableData?.some((i) => i.discount) && (
        <Column field="discount" header={t('Discount.1').toUpperCase()} body={(params) => discountBodyTemplate(params)} style={{ width: '8%' }} />
      )}
      <Column field="salesTax" header={t('Tax').toUpperCase()} body={salesTaxBodyTemplate} style={{ width: '12%' }} />
    </DataTable>
  );
};

export default DataTableSection;
