import { useFormContext } from 'react-hook-form';
import { inputs } from '../helpers/inputs';
import { localStateInitValues } from '../helpers/initialState';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const useEClaimPolicy = () => {
  const { setLocalState, getValues, setValue, isDirty } = useFormContext();

  const onCreateEClaimPolicy = (data) => {
    const formattedData = {
      ...data,
      RowGuid: data.RowGuid || getValues(inputs.dcnguid.name)
    };

    setLocalState((prevState) => ({
      ...prevState,
      policiesList: [formattedData, ...prevState.policiesList],
      eClaimPolicy: localStateInitValues.eClaimPolicy
    }));

    // VER-321 - eclaims->new patient->new eclaims->add policy->no No next button
    // Make form dirty after adding new policy
    !isDirty && setValue(inputs.fantom.name, `${Math.random()}`, { shouldDirty: true });
  };

  const onEditEClaimPolicy = (data) => {
    setLocalState((prevState) => {
      const formattedData = {
        ...data,
        RowGuid: data.RowGuid || getValues(inputs.dcnguid.name)
      };

      const policiesList = prevState.policiesList?.map((i) => {
        const rowGuidKey = commonInputs.rowGuid.name;
        const insurerGuid = commonInputs.insurerGuid.name;
        const replacePolicy = i?.[rowGuidKey] === formattedData?.[rowGuidKey] && i?.[insurerGuid] === formattedData?.[insurerGuid];
        if (replacePolicy) return formattedData;
        return i;
      });

      return {
        ...prevState,
        policiesList,
        eClaimPolicy: localStateInitValues.eClaimPolicy
      };
    });
  };

  const onCloseEClaimPolicy = () => {
    setLocalState((prevState) => ({ ...prevState, eClaimPolicy: localStateInitValues.eClaimPolicy }));
  };

  return { onCreateEClaimPolicy, onEditEClaimPolicy, onCloseEClaimPolicy };
};
