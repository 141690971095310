import React from 'react';
import PolicyItem from './PolicyItem';
import { usePatientPoliciesContext } from '../../hooks/usePatientPoliciesContext';
import { t } from '../../../../../service/localization/i18n';

const PoliciesList = () => {
  const { state } = usePatientPoliciesContext();
  const policies = state.policiesList;
  const loadingList = state.loadingPoliciesList;

  // if (loadingList) {
  //   return (
  //     <div className='flex justify-content-center align-items-center w-full text-lg'>
  //       {`${t('Loading')}...`}
  //     </div>
  //   )
  // };

  if (!policies?.length) {
    return <div className="text-center px-0 md:px-5 text-xl">{t('No_policies_found')}</div>;
  }

  return (
    <div className="flex flex-wrap gap-3">
      {state.policiesList?.map((i) => {
        return <PolicyItem policy={i} />;
      })}
    </div>
  );
};

export default PoliciesList;
