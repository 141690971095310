import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import classes from './LegoAlphabetLinks.module.scss';
import cx from 'classnames';
import { t } from '../../service/localization/i18n';

const LegoAlphabetLinks = ({ setFieldFilter, page_info, setExpanded, dataOnPageAppend, dataOnPageInplace }) => {
  const [alpha, setAlpha] = useState('All');
  const whereAmI = useSelector((state) => state.core.window);

  const setFilter = (e, field_id) => {
    setAlpha(e.target.value);
    setFieldFilter(e, field_id);
  };

  const resetFilter = () => {
    setAlpha('All');
    setExpanded({});
    page_info.filters = {};
    page_info.page = 0;
    whereAmI.isMobile ? dataOnPageAppend() : dataOnPageInplace();
    return true;
  };

  const root = whereAmI.isMobile ? classes.root : '';
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const all = (
    <span className={classes.letters} onClick={() => resetFilter()} id={'flt_all'}>
      {t('All')}
    </span>
  );

  const links = alphabet.map((letter) => {
    const currentStyle = letter === alpha ? classes.selectedLetter : classes.letters;
    return (
      <span key={letter} id={'flt_a_' + letter} className={currentStyle} onClick={() => setFilter({ target: { value: letter } }, 'Name')}>
        {letter.toUpperCase()}
      </span>
    );
  });

  return (
    <div className={cx('mt-4', root)}>
      <div className={cx('mt-1', 'mb-1', classes.lettersWrap)}>
        {all}
        {links}
      </div>
    </div>
  );
};

export default LegoAlphabetLinks;
