import { currencyFormat } from '../../../../utils/currencyFormat';

export const formatMostResentService = (service) => {
  let _label = service.label;
  if (service.amount && service.duration && service.unit) {
    _label = `${service.label} - ${service.duration} ${service.unit}, ${currencyFormat(service.amount)}`;
  }

  if (service.amount && (!service.duration || !service.unit)) {
    _label = `${service.label}, ${currencyFormat(service.amount)}`;
  }

  return {
    ...service,
    text: _label
  };
};
