import React from 'react';
import DatePicker from './Common/DatePicker';
import { inputs } from '../../../helpers/inputs';
import { useFormContext } from 'react-hook-form';

const RecoveryDate = () => {
  const { watch } = useFormContext();
  const status = watch(inputs.status.name);

  return <DatePicker input={inputs.recoveryDate} optionalRequired status={status} />;
};

export default RecoveryDate;
