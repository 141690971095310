import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCatalogTabs } from '../../../hooks/useCatalogTabs';
import { useGroupPickerInputs } from './useGroupPickerInputs';
import { inputs } from '../../../helpers/inputs';
import { isOverMaxEntries } from '../../../helpers/isOverMaxEntries';
import { setToastMessage } from '../../../../../../core/actions/core.action.creators';
import { useCreateNewRecord } from './useCreateNewRecord';
import { findIndex, flatMap, last, uniqBy } from 'lodash';
import { useDeleteRecord } from './useDeleteRecord';
import { stringFormat } from '../../../../../../utils/stringFormat';
import { patientFullName } from '../../../../../../utils/patientFullName';
import { t } from '../../../../../../../service/localization/i18n';

export const useGroupCatalogs = () => {
  const dispatch = useDispatch();
  const { localState, isNew, setLocalState, getValues } = useFormContext();
  const { tabsListForGroup } = useCatalogTabs();
  const { onDeleteClick } = useDeleteRecord();
  const { createNewRecord } = useCreateNewRecord();
  const { onFeeChange, onDxChange, onReferralChange, getRowsWithSamePatient } = useGroupPickerInputs();
  const batchCatalogs = localState.batchCatalogsDialog;
  const rowOptions = batchCatalogs.rowOptions;
  const rowData = rowOptions.rowData;
  const name = rowOptions.input?.name;
  const codeType = rowOptions.input?.codeType;
  const currentPatient = rowData?.[inputs.patient.name]?.[0];
  const currentPatientGuid = currentPatient?.PatientGuid;
  const catalogsTab = tabsListForGroup?.find((i) => i.name === name);

  const getSelectedCodes = () => {
    if (name === inputs.feeCodes.name) {
      const rowsWithSamePatient = getRowsWithSamePatient(currentPatientGuid);
      const codesList = rowsWithSamePatient?.map((i) => i?.[codeType]);
      const uniqueCodesList = uniqBy(flatMap(codesList), 'value');
      return uniqueCodesList;
    } else {
      const currentRowData = localState.groupRecords?.find((i) => i?.[inputs.groupRowId.name] === rowData?.[inputs.groupRowId.name]);
      return currentRowData?.[codeType];
    }
  };

  const onChipRemove = (code) => {
    // const codes = getSelectedCodes().filter((i) => i.value !== code.value);
    // if (name === inputs.feeCodes.name) return onFeeChange(codes, rowOptions);
    // if (name === inputs.icd9.name) return onDxChange(codes, rowOptions);
    // if (name === inputs.referral.name) return onReferralChange(codes, rowOptions);

    // if (name === inputs.feeCodes.name) {
    //   const rowsWithSamePatient = getRowsWithSamePatient(currentPatientGuid);
    //   const rowForDelete = rowsWithSamePatient?.find(i => i?.[inputs.feeCodes.codeType]?.[0]?.value === code?.value);
    //   const indexOfRowForDelete = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: rowForDelete?.[inputs.groupRowId.name] });
    //   const patientForDelete = patientFullName(rowForDelete?.[inputs.patient.name]?.[0]);
    //   const feeCodesForDelete = rowForDelete?.[inputs.feeCodes.codeType]?.[0]?.value;
    //   const message = stringFormat(t('Are_you_sure_you_want_to_remove_fee_codes_for_patient'), feeCodesForDelete, patientForDelete);
    //   return onDeleteClick({ rowData: rowForDelete, rowIndex: indexOfRowForDelete, message });
    // } else {
    //   const codes = getSelectedCodes().filter((i) => i.value !== code.value);
    //   if (name === inputs.icd9.name) return onDxChange(codes, rowOptions);
    //   if (name === inputs.referral.name) return onReferralChange(codes, rowOptions);
    // }

    if (name === inputs.feeCodes.name) {
      const rowsWithSamePatient = getRowsWithSamePatient(currentPatientGuid);
      const rowForDelete = rowsWithSamePatient?.find((i) => i?.[inputs.feeCodes.codeType]?.[0]?.value === code?.value);
      const indexOfRowForDelete = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: rowForDelete?.[inputs.groupRowId.name] });
      return onFeeChange([], { rowData: rowForDelete, rowIndex: indexOfRowForDelete });
    } else {
      const currentRowData = localState.groupRecords?.find((i) => i?.[inputs.groupRowId.name] === rowData?.[inputs.groupRowId.name]);
      const codes = currentRowData?.[codeType]?.filter((i) => i.value !== code.value);
      if (name === inputs.icd9.name) return onDxChange(codes, rowOptions);
      if (name === inputs.referral.name) return onReferralChange(codes, rowOptions);
    }
  };

  const onCodeSelect = (code, { catalogsState }) => {
    const selectedCodes = getSelectedCodes() || [];
    const codesList = [...selectedCodes, code];

    if (name === inputs.feeCodes.name) {
      const rowWithEmptyFeeCode = localState.groupRecords?.find((i) => !i?.[inputs.feeCodes.codeType]?.length);
      if (rowWithEmptyFeeCode) {
        // If no selected fee code
        const indexOfRowWithEmptyFeeCode = findIndex(localState.groupRecords, {
          [inputs.groupRowId.name]: rowWithEmptyFeeCode?.[inputs.groupRowId.name]
        });
        return onFeeChange([code], { rowData: rowWithEmptyFeeCode, rowIndex: indexOfRowWithEmptyFeeCode });
      } else {
        // If selected fee code
        const initValues = createNewRecord({ record: getValues(), patient: currentPatient, feeCode: code });
        const rowsWithSamePatient = getRowsWithSamePatient(currentPatientGuid);
        const lastItem = last(rowsWithSamePatient);
        const indexOfLastItem = findIndex(localState.groupRecords, { [inputs.groupRowId.name]: lastItem?.[inputs.groupRowId.name] });

        setLocalState((prevState) => {
          const newRecordsList = [...prevState.groupRecords];
          newRecordsList.splice(indexOfLastItem + 1, 0, initValues);
          return {
            ...prevState,
            groupRecords: newRecordsList
          };
        });
      }
    } else {
      // Show a warning message if the user tries to add more codes than the maximum number of entries
      if (isOverMaxEntries(codesList, name, isNew)) {
        return dispatch(
          setToastMessage({
            type: 'warn',
            message: `${t('Maximum_number_of_entries_is_X')} ${catalogsState.activeTab.maxEntries}`
          })
        );
      }

      if (name === inputs.icd9.name) return onDxChange(codesList, rowOptions);
      if (name === inputs.referral.name) return onReferralChange(codesList, rowOptions);
    }
  };

  return {
    catalogsTab,
    onCodeSelect,
    onChipRemove,
    getSelectedCodes
  };
};
