import moment from 'moment';
import { inputs } from './inputs';
import { inputsForFirstVisit, invoiceTypes } from './defaultValues';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { formatBMI } from './formatBMI';
import { isArray } from 'lodash';
import { encodeText } from '../../../../utils/decodeText';

export const formatPatientsListForFirstVisit = (invoice) => {
  let patient = {};

  patient[inputs.patientGuid.name] = invoice[inputs.patientGuid.name];
  patient[inputs.dcnguid.name] = invoice[inputs.dcnguid.name];

  Object.keys(inputsForFirstVisit).forEach((key) => {
    patient[key] = invoice[key];
  });

  return [patient];
};

export const recordsForSubmit = (records) => {
  return records.map((record) => ({
    ...record,
    note: encodeText(record.note)
  }));
};

export const dataForSubmit = ({ invoice, isNew, firstVisit }) => {
  if (firstVisit && !invoice[inputs.patient.name]?.length) {
    const patientsList = formatPatientsListForFirstVisit(invoice);
    invoice = { ...invoice, [inputs.patient.name]: patientsList };
  }

  const injuryDate = invoice[inputs.injuryDate.name];
  const currentServiceDate = invoice[inputs.serviceDate.name];
  const serviceDate = isArray(currentServiceDate) && currentServiceDate?.length ? currentServiceDate[0] : currentServiceDate;
  const birthDay = invoice[inputs.patient.name][0][inputs.birthDay.name];

  const dataForRequest = {
    ...invoice,
    [inputs.ruralCode.name]: invoice[inputs.ruralCode.name]?.value || '',
    [inputs.patientList.name]: invoice[inputs.patient.name]?.map((i) => i.PatientGuid),
    [inputs.patientGuid.name]: invoice[inputs.patient.name][0]?.PatientGuid, // Need for edit
    [inputs.birthDay.name]: birthDay ? moment(birthDay).format('YYYY-MM-DD') : null,
    [inputs.injuryDate.name]: injuryDate ? moment(injuryDate).format('YYYY-MM-DD') : injuryDate,
    [inputs.serviceDate.name]: serviceDate ? moment(serviceDate).format('YYYY-MM-DD') : serviceDate,
    [inputs.bmi.name]: formatBMI(invoice[inputs.bmi.name]),
    [inputs.feeCodes.name]: invoice[inputs.feeCodes.name]?.join(','),
    [inputs.icd9.name]: invoice[inputs.icd9.name]?.join(','),
    [inputs.aoi.name]: invoice[inputs.aoi.name]?.join(','),
    [inputs.noi.name]: invoice[inputs.noi.name]?.join(','),
    [inputs.referral.name]: invoice[inputs.referral.name]?.join(','),
    [inputs.comment.name]: encodeText(invoice[inputs.comment.name])
  };

  if (!isNew) {
    delete dataForRequest[inputs.emergency.name];
    delete dataForRequest[inputs.travel.name];
    delete dataForRequest[inputs.ccfpp.name];
    delete dataForRequest.TotalNotes;
  }

  if (invoice[inputs.payor.name] !== invoiceTypes.wsbc) {
    delete dataForRequest[commonInputs.submitWithGenericPHN.name];
  }

  delete dataForRequest[inputs.patient.name];
  delete dataForRequest[inputs.patientClaims.name];
  delete dataForRequest[inputs.feeCodes.codeDescription];
  delete dataForRequest[inputs.reasonFor.name];
  delete dataForRequest[inputs.icd9.codeDescription];
  delete dataForRequest[inputs.aoi.codeDescription];
  delete dataForRequest[inputs.noi.codeDescription];
  delete dataForRequest[inputs.referral.codeDescription];
  delete dataForRequest[inputs.mspCodes.name];
  delete dataForRequest[inputs.dateCreated.name];
  delete dataForRequest[inputs.dateUpdated.name];
  delete dataForRequest[inputs.sentDate.name];
  delete dataForRequest[inputs.statusText.name];
  delete dataForRequest[inputs.duration.name];
  delete dataForRequest[inputs.isDuplicated.name];
  delete dataForRequest[commonInputs.fantom.name];

  // Remove full codes data
  delete dataForRequest[commonInputs.feeCodes.codeType];
  delete dataForRequest[commonInputs.icd9.codeType];
  delete dataForRequest[commonInputs.aoi.codeType];
  delete dataForRequest[commonInputs.noi.codeType];
  delete dataForRequest[commonInputs.referral.codeType];

  return dataForRequest;
};
