import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import CommonInputField from '../../common/components/CommonInputField';
import PageTitle from '../../../components/Layouts/Titles/PageTitle';
import { themes } from '../../../themes/themes';
import { prefsCodes } from '../../config/prefsCodesConfig';
import { setTheme } from '../../core/actions/core.action.creators';
import { savePreferences } from '../actions/preferences.action.creators';
import { dropdownScrollHeight } from '../../config/stylesConfig';
import { isEmpty } from 'lodash';
import { updatePreference } from '../helpers/updatePreference';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';
import { localStorageKeys } from '../../config/localStorageKeysConfig';

const UserPreferences = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user_details = useSelector((state) => state.user.details);
  const currentTheme = useSelector((state) => state.core.theme);
  const { isMobileOnly } = useSelector((state) => state.core.window);
  const { currentUserPrefs, savingPreferences } = useSelector((state) => state.preferences);
  const [displayPrefs, setDisplayPrefs] = useState(currentTheme);
  const [prevTheme, setprevTheme] = useState(currentTheme);
  const [selectedTheme, setSelected] = useState(currentTheme);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setprevTheme(currentTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //set "display" prefs
  useEffect(() => {
    currentUserPrefs?.prefs && setDisplayPrefs(currentUserPrefs?.prefs?.find((i) => i.label === 'display'));
  }, [currentUserPrefs.prefs]);

  const onThemeChange = (theme) => {
    !isDirty && setIsDirty(true);
    setSelected(theme);
    dispatch(setTheme(theme));

    if (!isEmpty(currentUserPrefs)) {
      setDisplayPrefs((prevState) => updatePreference(prevState, theme, prefsCodes.theme));
    }
  };

  const onSave = () => {
    dispatch(
      savePreferences(currentUserPrefs?.UserGuid, displayPrefs, () => {
        const theme = displayPrefs?.content?.find((i) => i.label === prefsCodes.theme)?.value;
        setIsDirty(false);
        setprevTheme(selectedTheme);
        localStorage.setItem(localStorageKeys.themeKey, JSON.stringify(theme));
      })
    );
  };

  const onCancel = () => {
    // dispatch(setTheme(prevTheme));
    history.goBack();
  };

  const promptMessage = () => {
    return JSON.stringify({
      isDirty,
      data: prevTheme, //add "data" for callback params for prompt accept action
      name: 'userPreferences' //add "name" to check which form should be nulled (see customPrompt for nulling)
    });
  };

  const actionButtonst = () => {
    if (isDirty) {
      return (
        <div className="flex w-full gap-3">
          <Button className="w-full" label={t('Save')} onClick={onSave} loading={savingPreferences} />
          <Button className="w-full p-button-outlined" label={t('Cancel')} onClick={onCancel} />
        </div>
      );
    }

    return <Button className="w-full" label={t('Close')} onClick={onCancel} />;
  };

  return (
    <div className="flex justify-content-center p-3">
      <div className="card">
        <Prompt when={isDirty} message={promptMessage} />

        {/* Title */}
        <div className="mb-3">
          <PageTitle>{t('User_Preferences')}</PageTitle>
        </div>

        <div className="p-fluid">
          {user_details?.DoctorGuid !== '' && (
            <CommonInputField label={t('Colors')} className={cx('p-0', isMobileOnly ? '' : 'col-3')}>
              <Dropdown
                style={{ width: '300px' }}
                value={selectedTheme}
                options={themes}
                scrollHeight={dropdownScrollHeight()}
                onChange={(e) => onThemeChange(e.value)}
              />
            </CommonInputField>
          )}
        </div>

        {/* Action buttons */}
        <div className={cx('flex mt-4 p-0 w-full', isMobileOnly ? '' : 'col-3')}>{actionButtonst()}</div>
      </div>
    </div>
  );
};

export default UserPreferences;
