import { store } from '../../../../../store';
import DateInput from '../filters/DateInput';
import FacilityNameFilter from '../filters/FacilityNameFilter';
import LFPFilter from '../filters/LFPFilter';
import ProvinceFilter from '../filters/ProvinceFilter';
import TextInput from '../filters/TextInput';
import LFPTemplate from '../rowTemplates/LFPTemplate';
import NameTemplate from '../rowTemplates/NameTemplate';
import ProvinceTemplate from '../rowTemplates/ProvinceTemplate';

import { columnValues } from './columnValues';

export const columns = () => {
  const clinic = store.getState().clinic.details;

  const columnsList = [
    {
      id: columnValues.lastName.id,
      field: columnValues.lastName.field,
      header: columnValues.lastName.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.lastName.placeholder,
      filterElement: textFilterElement('lastName'),
      style: { minWidth: '400px' },
      body: (rowData) => <NameTemplate rowData={rowData} />
    },
    {
      id: columnValues.province.id,
      field: columnValues.province.field,
      header: columnValues.province.header,
      sortable: true,
      filter: true,
      frozen: false,
      style: { minWidth: '152px', maxWidth: '152px' },
      body: (rowData) => <ProvinceTemplate rowData={rowData} />,
      filterElement: <ProvinceFilter />
    },
    {
      id: columnValues.phn.id,
      field: columnValues.phn.field,
      header: columnValues.phn.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.phn.placeholder,
      style: { minWidth: '200px' },
      filterElement: textFilterElement('phn')
    },
    {
      id: columnValues.dob.id,
      field: columnValues.dob.field,
      header: columnValues.dob.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.dob.placeholder,
      style: { minWidth: '170px', maxWidth: '170px' },
      filterElement: dateFilterElement('dob')
    },
    {
      id: columnValues.facility.id,
      field: columnValues.facility.field,
      header: columnValues.facility.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.facility.placeholder,
      style: { minWidth: '200px' },
      filterElement: <FacilityNameFilter />
    }
  ];

  // Conditionally add a column if clinic.Lfp is true
  if (clinic?.Lfp) {
    columnsList.push({
      id: columnValues.lfp.id,
      field: columnValues.lfp.field,
      header: columnValues.lfp.header,
      sortable: true,
      filter: true,
      frozen: false,
      filterPlaceholder: columnValues.lfp.placeholder,
      style: { minWidth: '100px' },
      body: (rowData) => <LFPTemplate rowData={rowData} />,
      filterElement: () => <LFPFilter />
    });
  }

  return columnsList;
};

const textFilterElement = (columnKey) => {
  return <TextInput columnKey={columnKey} />;
};

const dateFilterElement = (columnKey) => {
  return <DateInput columnKey={columnKey} />;
};
