import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Divider } from 'primereact/divider';

import { removeAllSpecialCharacters } from '../../../../../regex/regex';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { formatCodeDescription } from '../../../../helpers/getCodeDescriptionOnly';
import { invoiceTypes } from '../../helpers/defaultValues';
import { inputs } from '../../helpers/inputs';

const CodesDescriptionTemplate = ({ record, showDivider = true }) => {
  const { watch, isGroup } = useFormContext();

  return (
    <>
      <div className="flex col-12 p-0 white-space-normal">
        <div id={elementIDs.previewFeeCodeTooltip} className="codeDescriptionValue">
          {`${record.fee_code}: `}
        </div>
        <div id={elementIDs.previewFeeCodeTextTooltip}>{record.fee_code_text}</div>
      </div>

      {showDivider ? <Divider /> : <br />}

      {!isGroup &&
        record?.icd9?.split(',').map((i, index) => {
          const dxId = i.replaceAll(removeAllSpecialCharacters, '');
          const codeDescription = formatCodeDescription(record?.icd9?.split(','), record?.icd9_text?.split('|'), 1, index);

          return (
            <div className="flex col-12 mb-1 p-0 white-space-normal" key={index}>
              <div id={`previewICD9CodeTooltip_${dxId}`} className="codeDescriptionValue">
                {`${formatCodeDescription(record?.icd9?.split(','), record?.icd9_text?.split('|'), 0, index)}:`}
              </div>
              <div id={`previewICD9CodeTextTooltip_${dxId}_${index}`}>{codeDescription?.trim()}</div>
            </div>
          );
        })}

      {watch(inputs.payor.name) === invoiceTypes.wsbc && (
        <>
          {showDivider ? <Divider /> : <br />}
          <div className="flex col-12 p-0 mb-1 white-space-normal">
            <div id={elementIDs.previewAOICodeTooltip} className="codeDescriptionValue">
              {`${formatCodeDescription(watch(inputs.aoi.name), watch(inputs.aoi.codeDescription), 0)}:`}
            </div>
            <div id={elementIDs.previewAOICodeTextTooltip}>{formatCodeDescription(watch(inputs.aoi.name), watch(inputs.aoi.codeDescription), 1)}</div>
          </div>

          <div className="flex col-12 p-0 white-space-normal">
            <div id={elementIDs.previewNOICodeTooltip} className="codeDescriptionValue">
              {`${formatCodeDescription(watch(inputs.noi.name), watch(inputs.noi.codeDescription), 0)}:`}
            </div>
            <div id={elementIDs.previewNOICodeTextTooltip}>{formatCodeDescription(watch(inputs.noi.name), watch(inputs.noi.codeDescription), 1)}</div>
          </div>
        </>
      )}
    </>
  );
};

export default CodesDescriptionTemplate;
