import { checkDateDiff } from '../../../../utils/formatDate';
import { invoiceTypes } from './defaultValues';
import { submissionCodesDropdownOptions } from './dropdownOptions';

export const getSubmissionCode = ({ invoiceType, status, serviceDate, isNew, receivedDate, submissionCode } = {}) => {
  const dateDiff = serviceDate && checkDateDiff(serviceDate);
  const receivedDateDiff = receivedDate && checkDateDiff(receivedDate);
  const isMSPorICBC = invoiceType === invoiceTypes.msp || invoiceType === invoiceTypes.icbc;
  const isWSBC = invoiceType === invoiceTypes.wsbc;
  const isDeclined = Number(status) === 3 || Number(status) === -3 || Number(status) === 6 || Number(status) === 9;
  const isPaid = Number(status) === 2;
  const isInProcess = Number(status) === 1;
  const isOutstanding = Number(status) === 0;

  // Create new
  if (isNew) {
    if (isMSPorICBC) {
      // more than 90 days
      if (dateDiff < -90) return 'A';
    }

    if (isWSBC) {
      // more than 90 days
      if (dateDiff < -90) return 'W';
    }
  }

  // Edit
  if (!isNew) {
    // Only ICBC or MSP
    if (isMSPorICBC) {
      // CMO-3145 - Only ICBC and MSP and only ACCEPTED claims
      if (isPaid) return 'X';

      //  Outstanding
      if (isOutstanding) {
        // more than 90 days
        if (dateDiff < -90) return 'A';
      }

      // CMO-3145 - Only ICBC and MSP and only IN PROCESS
      // CMO-3145 - Rebill In Process ICBC and MSP claims if the date of service is LESS than 90 days - X + note (please replace R code with X code as per this edit - Feb 5, 2024)
      if (isInProcess) {
        // more than 90 days
        if (dateDiff < -90) return 'X';
        // less than 90 days
        if (dateDiff >= -90) return 'X';
      }

      if (isDeclined) {
        // more than 90 days
        if (dateDiff < -90) return 'X';
        // less than 90 days
        if (dateDiff >= -90) return 'R';
      }

      // // more than 90 days
      // if (dateDiff < -90) {
      //   if (receivedDate && receivedDateDiff >= -90) return "X";
      //   return "A";
      // }
    }

    // Only WSBC
    if (isWSBC) {
      // CMO-3145, CMO-3190
      if (isPaid || isInProcess || isDeclined) return 'W';
    }
  }

  const defaultSubmissionCode = submissionCodesDropdownOptions.find((i) => i.value === '0').value;
  return submissionCode || defaultSubmissionCode;
};
