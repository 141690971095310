import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Divider } from 'primereact/divider';
import CommonCard from '../../../../common/components/CommonCard';
import { email_types, phone_types, address_types } from '../../../../common/contants';
import { useHistory } from 'react-router-dom';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';
import { routes } from '../../../../../routes/routes';
import { t } from '../../../../../service/localization/i18n';
import classes from './../PatientDetails.module.scss';
import cx from 'classnames';
import { setActiveTabIndexForPatientForm } from '../../../actions/patients.action.creators';
import { elementIDs } from '../../../../config/elementIDsConfig';
import VerifyAccessWordDialog from './VerifyAccessWordDialog';
import { commonInputs } from '../../../../config/commonInputsConfig';

const InvoicesCard = ({ patient_details, readonly, showSkeleton }) => {
  const { isMobile, clientWidth } = useSelector((state) => state.core.window);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showVerifyWordDialog, setShowVerifyWordDialog] = useState(false);

  const onEdit = () => {
    isMobile && dispatch(setActiveTabIndexForPatientForm(1));
    history.push(`${routes.editProfile.path}/${patient_details.PatientGuid}`);
  };

  const headerActionButton = <Button className="p-button-text p-button-rounded" icon="pi pi-pencil" onClick={() => onEdit()} />;

  const emailTypeTooltip = email_types.find((i) => i.value === patient_details.EmailType)?.label;
  const phoneTypeTooltip = phone_types.find((i) => i.value === patient_details.PhoneType)?.label;
  const addressTypeTooltip = address_types.find((i) => i.value === patient_details.AddressType)?.label;

  const contactsLayout = (
    <>
      {!patient_details.Email && !patient_details.Phone ? (
        <div className="flex align-items-center col-11 p-0">{t('No_email_or_phone_number')}</div>
      ) : (
        <div className="col-12 p-0">
          {patient_details.Email && (
            <div className="flex col-12 p-0 medium-size-text mb-1">
              <div className={cx('col-1 p-0', classes.iconContainer)}>
                <i className="pi pi-envelope mr-4" />
              </div>
              <div className="flex flex-column col-11 p-0">
                {patient_details.EmailType ? (
                  <div className="flex">
                    <Tooltip target=".emailType" />
                    <span id={elementIDs.patientEmail} className="mr-2">
                      {patient_details.Email}
                    </span>
                    <span id={elementIDs.patientEmailType} className={cx('emailType', classes.typeWrap)} data-pr-tooltip={emailTypeTooltip}>
                      {`(${patient_details.EmailType.toLowerCase()})`}
                    </span>
                  </div>
                ) : (
                  <span id={elementIDs.patientEmail}>{patient_details.Email}</span>
                )}
              </div>
            </div>
          )}

          {patient_details.Phone && (
            <div className="flex col-12 p-0 medium-size-text">
              <div className={cx('col-1 p-0', classes.iconContainer)}>
                <i className="pi pi-phone mr-4" />
              </div>
              <div className="flex flex-column col-11 p-0">
                {patient_details.PhoneType ? (
                  <div className="flex">
                    <Tooltip target=".phoneType" />
                    <span id={elementIDs.patientPhone} className="mr-2">
                      {formatPhoneNumber(patient_details.Phone)}
                    </span>
                    <span id={elementIDs.patientPhoneType} className={cx('phoneType', classes.typeWrap)} data-pr-tooltip={phoneTypeTooltip}>
                      {`(${patient_details.PhoneType.toLowerCase()})`}
                    </span>
                  </div>
                ) : (
                  <span id={elementIDs.patientPhone}>{formatPhoneNumber(patient_details.Phone)}</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );

  const addressLayout = (
    <>
      {!patient_details.AddressLine?.trim() && !patient_details.City?.trim() ? (
        <div className="flex align-items-center col-11 p-0">{t('No_address')}</div>
      ) : (
        <div className="col-12 p-0">
          {(patient_details.AddressLine || patient_details.City) && (
            <div className="flex col-12 p-0 medium-size-text">
              <div className={cx('col-1 p-0', classes.iconContainer)}>
                <Tooltip target=".addressType" />
                {patient_details.AddressType === 'M' ? (
                  <i className={cx('pi pi-envelope mr-4 addressType', classes.typeWrap)} data-pr-tooltip={addressTypeTooltip} />
                ) : (
                  <i className={cx('pi pi-home mr-4 addressType', classes.typeWrap)} data-pr-tooltip={addressTypeTooltip} />
                )}
              </div>

              <div className="flex flex-column col-11 p-0">
                <span id={elementIDs.patientAddressLine}>{patient_details.AddressLine}</span>

                {(patient_details.City || patient_details.Province) && (
                  <div className="mb-1">
                    {patient_details.City && patient_details.Province ? (
                      <span id={elementIDs.patientCity}>{`${patient_details.City}, ${patient_details.Province && patient_details.Province}`}</span>
                    ) : (
                      <>
                        <i className="pi pi-home mr-4" />
                        <span id={elementIDs.patientCity}>{patient_details.Province && patient_details.Province}</span>
                      </>
                    )}
                  </div>
                )}

                {patient_details.Zip && (
                  <div id={elementIDs.patientZip} className="mb-1">
                    {patient_details.ZipFormatted}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );

  const mrp = patient_details?.MRPNumber ? `${patient_details?.MRPName} #${patient_details?.MRPNumber}` : t('N_A');
  const facilityLayout = (
    <div className="flex flex-column gap-1">
      <div>{`${t('Facility')}: ${patient_details?.Facility || t('N_A')}`}</div>
      <div>{`${t('MRP')}: ${mrp}`}</div>
    </div>
  );

  const footerContent = () => {
    return (
      patient_details?.[commonInputs.accessWord.name] && (
        <Button className="p-button-text w-auto" label="Verify Patient Access Word" onClick={() => setShowVerifyWordDialog(true)} />
      )
    );
  };

  return (
    <>
      <CommonCard title={t('Contact_Info')} headerActionButton={!readonly && headerActionButton} footerContent={footerContent}>
        <div className="flex flex-column sm:flex-row mt-3">
          {/* CONTACT INFO */}
          <div className="col-12 sm:col-6 p-0">
            <div className="flex col-12 p-0">
              {showSkeleton ? (
                <div className="flex flex-column">
                  <Skeleton width="8rem" />
                  <Skeleton className="mt-2" width="8rem" />
                </div>
              ) : (
                contactsLayout
              )}
            </div>
          </div>

          <Divider className={classes.verticalDevider} layout={clientWidth > 556 ? 'vertical' : 'horizontal'} />

          {/* ADDRESS */}
          <div className="col-12 sm:col-6 p-0">
            <div className="flex col-12 p-0">
              {showSkeleton ? (
                <div className="flex flex-column">
                  <Skeleton width="8rem" />
                  <Skeleton className="mt-2" width="8rem" />
                </div>
              ) : (
                addressLayout
              )}
            </div>
          </div>
        </div>

        <div className="flex pt-3">
          <div className="flex col-12 p-0">
            {showSkeleton ? (
              <div className="flex flex-column">
                <Skeleton width="8rem" />
                <Skeleton className="mt-2" width="8rem" />
              </div>
            ) : (
              facilityLayout
            )}
          </div>
        </div>
      </CommonCard>

      <VerifyAccessWordDialog
        patientGuid={patient_details.PatientGuid}
        visible={showVerifyWordDialog}
        onHide={() => setShowVerifyWordDialog(false)}
      />
    </>
  );
};

export default InvoicesCard;
