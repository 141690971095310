import React from 'react';
import { useFormContext } from 'react-hook-form';

import Catalogs from '../../../../../../components/Catalogs/Catalogs';

import { inputs } from '../../helpers/inputs';
import { useCatalogTabs } from '../../hooks/useCatalogTabs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useCatalogs } from '../../hooks/useCatalogs';
import { useToggleCatalogs } from '../../hooks/useToggleCatalogs';
import { useChipRemove } from '../../hooks/useChipRemove';
import { onRemovePatient } from '../../helpers/onRemovePatient';

const WSBCReportCatalogs = () => {
  const { onChipRemove } = useChipRemove();
  const { toggleCatalogs } = useToggleCatalogs();
  const { getSelectedCodes, onSelectCodeFromCatalogsTable, catalogsTableValues } = useCatalogs();
  const { catalogTabs, onTabChange } = useCatalogTabs();
  const { setValue, watch, isNew, localState } = useFormContext();
  const patients = watch(inputs.patient.name);

  return (
    <Catalogs
      id={elementIDs.wsbcrDesktopCatalogs}
      patients={patients}
      removablePatientChip={isNew}
      activeTabIndex={localState.catalogIndex}
      showCatalogs={localState.showCatalogs}
      catalogTabs={catalogTabs}
      showCloseButton
      catalogsTableValues={catalogsTableValues}
      // Handlers
      onTabChange={onTabChange}
      toggleCatalogs={toggleCatalogs}
      getSelectedCodes={getSelectedCodes}
      onCodeChipClick={onChipRemove}
      onSelectCodeFromCatalogsTable={onSelectCodeFromCatalogsTable}
      onPatientChipRemove={() => onRemovePatient(setValue)}
    />
  );
};

export default WSBCReportCatalogs;
