import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { reasonFor01080InputRequirements } from '../../../helpers/validationSchema';
import { t } from '../../../../../../../service/localization/i18n';
import cx from 'classnames';

const reasonList = {
  [elementIDs.asa4]: t('ASA4'),
  [elementIDs.asa4e]: t('ASA4E'),
  [elementIDs.asa5]: t('ASA5'),
  [elementIDs.asa5e]: t('ASA5E'),
  [elementIDs.iabp]: t('IABP'),
  [elementIDs.mechanicalAssistDevice]: t('Mechanical_assist_device'),
  [elementIDs.timeOver8Hours]: t('Time_over_8_hours')
};

const ReasonFor01080 = () => {
  const name = inputs.reasonFor.name;
  const { control, setValue, isNew, errors, clearErrors, isGroup } = useFormContext();
  const feeCodes = useWatch({ control, name: inputs.feeCodes.codeType });
  const required = reasonFor01080InputRequirements(feeCodes, isGroup, isNew);

  const onChange = (e, field) => {
    let selectedReasons = [...field.value];

    if (e.checked) selectedReasons.push(e.value);
    else selectedReasons.splice(selectedReasons.indexOf(e.value), 1);

    clearErrors(name);
    setValue(field.name, selectedReasons);
  };

  const reasonForInput = (field, fieldState) => {
    return (
      <InputWrapper
        name={name}
        label={inputs.reasonFor.label}
        required={required}
        errorMessage={errors[name]?.message}
        style={{ height: 'auto', paddingBottom: '25px' }}
      >
        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.asa4}
            inputId={name}
            value={reasonList[elementIDs.asa4]}
            checked={field.value?.indexOf(reasonList[elementIDs.asa4]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.asa4}>{reasonList[elementIDs.asa4]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.asa4e}
            inputId={name}
            value={reasonList[elementIDs.asa4e]}
            checked={field.value?.indexOf(reasonList[elementIDs.asa4e]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.asa4e}>{reasonList[elementIDs.asa4e]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.asa5}
            inputId={name}
            value={reasonList[elementIDs.asa5]}
            checked={field.value?.indexOf(reasonList[elementIDs.asa5]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.asa5}>{reasonList[elementIDs.asa5]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.asa5e}
            inputId={name}
            value={reasonList[elementIDs.asa5e]}
            checked={field.value?.indexOf(reasonList[elementIDs.asa5e]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.asa5e}>{reasonList[elementIDs.asa5e]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.iabp}
            inputId={name}
            value={reasonList[elementIDs.iabp]}
            checked={field.value?.indexOf(reasonList[elementIDs.iabp]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.iabp}>{reasonList[elementIDs.iabp]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.mechanicalAssistDevice}
            inputId={name}
            value={reasonList[elementIDs.mechanicalAssistDevice]}
            checked={field.value?.indexOf(reasonList[elementIDs.mechanicalAssistDevice]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.mechanicalAssistDevice}>{reasonList[elementIDs.mechanicalAssistDevice]}</label>
        </div>

        <div className="p-field-checkbox pt-2">
          <Checkbox
            className={cx('mr-3', { 'p-invalid': fieldState.invalid })}
            type="checkbox"
            id={elementIDs.timeOver8Hours}
            inputId={name}
            value={reasonList[elementIDs.timeOver8Hours]}
            checked={field.value?.indexOf(reasonList[elementIDs.timeOver8Hours]) !== -1}
            onChange={(e) => onChange(e, field)}
          />
          <label htmlFor={elementIDs.timeOver8Hours}>{reasonList[elementIDs.timeOver8Hours]}</label>
        </div>
      </InputWrapper>
    );
  };

  if (required && isNew) {
    return <Controller name={name} control={control} render={({ field, fieldState }) => reasonForInput(field, fieldState)} />;
  }

  return null;
};

export default ReasonFor01080;
