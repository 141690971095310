import React from 'react';
import { Button } from 'primereact/button';
import { icons } from '../../../../../../config/stylesConfig';
import { t } from '../../../../../../../service/localization/i18n';

const IconButton = ({ onClick }) => {
  return (
    <div className="pb-1">
      <Button
        type="button"
        className="p-button-text p-button-rounded"
        icon={icons.add}
        tooltip={t('Add_service')}
        tooltipOptions={{ position: 'top' }}
        onClick={onClick}
      />
    </div>
  );
};

const TextButton = ({ onClick }) => {
  return (
    <Button
      type="button"
      className="p-button-outlined w-max"
      icon={icons.add}
      label={t('Add_service')}
      tooltip={t('Add_service')}
      tooltipOptions={{ position: 'top' }}
      onClick={onClick}
    />
  );
};

const AddService = ({ onClick }) => {
  return (
    <>
      <div className="hidden lg:block">
        <IconButton onClick={onClick} />
      </div>

      <div className="block lg:hidden">
        <TextButton onClick={onClick} />
      </div>
    </>
  );
};

export default AddService;
