import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Tooltip } from 'primereact/tooltip';

import MidName from './MidName';
import LastName from './LastName';
import FirstName from './FirstName';
import BirthDate from './BirthDate';
import Relationship from './Relationship';

import { inputs } from '../../../helpers/inputs';
import { t } from '../../../../../../../service/localization/i18n';

const NameInputs = () => {
  const { control } = useFormContext();
  const insuredType = useWatch({ control, name: inputs.insuredType.name });

  if (insuredType === 'FAMDEP') return null;

  return (
    <>
      <div className="two_inputs_in_a_row">
        <LastName />
        <FirstName />
      </div>

      <MidName />
    </>
  );
};

const NameAndRelationshipInputs = () => {
  const { control } = useFormContext();
  const insuredType = useWatch({ control, name: inputs.insuredType.name });

  if (insuredType === 'SELF') return null;

  return (
    <>
      <div className="two_inputs_in_a_row">
        <LastName />
        <FirstName />
      </div>

      <div className="two_inputs_in_a_row">
        <MidName />
        <BirthDate />
      </div>

      <Relationship />
    </>
  );
};

const InsuredMemberInfoIcon = () => {
  return (
    <>
      <Tooltip target=".insuredType_tooltip" />

      <i
        id={`insuredMember_infoIcon`}
        className="pi pi-info-circle cursor-pointer insuredType_tooltip"
        data-pr-tooltip={t('Change_the_name_to_match_the_name_on_the_insurance_card')}
        data-pr-position="top"
      />
    </>
  );
};

const InsuredMember = () => {
  const { control } = useFormContext();
  const insuredType = useWatch({ control, name: inputs.insuredType.name });

  if (!insuredType || insuredType === 'NA') return null;

  return (
    <>
      <div className="flex">
        <h6 className="pr-3">{t('Insured_Members_name_as_shown_on_the_card')}</h6>
        <InsuredMemberInfoIcon />
      </div>

      <NameInputs />
      <NameAndRelationshipInputs />
    </>
  );
};

export default InsuredMember;
