import React from 'react';
import { Skeleton } from 'primereact/skeleton';

// interface IProps {
//   itemsList?: [], // any array of items
// }

const ChipsListSkeleton = (props) => {
  const itemsList = Array.from({ length: props?.itemsList?.length || 20 }, () => 'item');

  return (
    <>
      {itemsList?.map((_, index) => {
        return <Skeleton key={index} borderRadius="2rem" width="56px" height="24px" />;
      })}
    </>
  );
};

export default ChipsListSkeleton;
