import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'primereact/button';

// import TextInput from './TextInput';
import TextInput from './TextInput';
import CommonInputField from '../../../../common/components/CommonInputField';

import { toggleFilters } from '../../../../core/actions/core.action.creators';
import { columnValues } from '../helpers/columnValues';
import { t } from '../../../../../service/localization/i18n';
import classes from './SidebarFilters.module.scss';
import cx from 'classnames';

const SidebarFilters = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const { showFiltersSidebar } = useSelector((state) => state.core);

  const closeSidebar = () => dispatch(toggleFilters(!showFiltersSidebar));

  const actionButtonst = () => {
    if (filters?.length) {
      return (
        <div className="flex">
          <Button className="w-full" label={String.format(t('Show_x_records'))} onClick={closeSidebar} />
        </div>
      );
    }

    return <Button className="p-button-text w-full" label={t('Close')} onClick={closeSidebar} />;
  };

  return (
    <div className={cx('sidebarFilters', classes.rootWrap)}>
      <form className="p-fluid mt-3">
        <CommonInputField label={columnValues.firstName.header}>
          <TextInput columnKey="firstName" filters={filters} setFilters={setFilters} />
        </CommonInputField>

        <CommonInputField label={columnValues.lastName.header}>
          <TextInput columnKey="lastName" filters={filters} setFilters={setFilters} />
        </CommonInputField>

        <CommonInputField label={columnValues.number.header}>
          <TextInput columnKey="number" filters={filters} setFilters={setFilters} />
        </CommonInputField>

        <CommonInputField label={columnValues.phone.header}>
          <TextInput columnKey="phone" filters={filters} setFilters={setFilters} />
        </CommonInputField>

        <CommonInputField label={columnValues.email.header}>
          <TextInput columnKey="email" filters={filters} setFilters={setFilters} />
        </CommonInputField>
      </form>

      <div className={classes.actionButtonsWrap}>{actionButtonst()}</div>
    </div>
  );
};

export default SidebarFilters;
