import React from 'react';
import { useFormContext } from 'react-hook-form';
import EClaimPolicyCatalogs from '../EClaimPolicyCatalogs/EClaimPolicyCatalogs';
import EClaimPolicyForm from '../EClaimPolicyForm/EClaimPolicyForm';
import FormWithCatalogsWrap from '../../../../../../components/Wraps/FormWithCatalogsWrap/FormWithCatalogsWrap';
import FormActionButtons from '../ActionButtons/FormActionButtons';

const StepOne = () => {
  const { localState, showInDialog } = useFormContext();

  return (
    <FormWithCatalogsWrap
      showInDialog={showInDialog}
      hideRightCard={!localState.showCatalogs.desktop}
      leftCardContent={<EClaimPolicyForm />}
      leftCardActionButtons={<FormActionButtons />}
      rightCardContent={<EClaimPolicyCatalogs />}
    />
  );
};

export default StepOne;
