import React from 'react';
import { useFormContext } from 'react-hook-form';

import Payor from '../Payor';
import ServiceDate from '../ServiceDate';
import MultipleServiceDates from '../MultipleServiceDates';

const PayorAndServiseDateSection = () => {
  const { localState } = useFormContext();
  const showMultipleDates = localState.isMultipleDates;

  if (localState.isEmptyPractitioner) return <ServiceDate />;

  if (showMultipleDates) {
    return (
      <>
        <Payor />
        <MultipleServiceDates />
      </>
    );
  }

  return (
    <>
      <Payor />
      <ServiceDate />
    </>
  );
};

export default PayorAndServiseDateSection;
