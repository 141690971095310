import { inputs } from './inputs';

export const updateRecord = (invoice) => {
  const feeCodeArray = Array.isArray(invoice[inputs.feeCodes.codeType]) ? invoice[inputs.feeCodes.codeType] : [invoice[inputs.feeCodes.codeType]];

  const feeCode = feeCodeArray[0]?.value;
  const feeCodeText = feeCodeArray[0]?.text;

  const dxCodeArray = Array.isArray(invoice[inputs.icd9.codeType]) ? invoice[inputs.icd9.codeType] : [invoice[inputs.icd9.codeType]];

  const icd9Code = dxCodeArray.map((i) => i.value)?.toString();
  const icd9Text = dxCodeArray.map((i) => i.text)?.join('|');

  const billed = (invoice[inputs.feeAmount.name] * invoice[inputs.units.name] * invoice[inputs.percent.name]) / 100;

  return {
    alert: null,
    base_codes: null,
    id: invoice?.id || feeCode?.replaceAll('.', '') || '',
    billed,
    category: null,
    cc_flag: '0',
    deduct_unit: -1,
    delay: -1,
    extra_note: null,
    fee: invoice[inputs.feeAmount.name],
    fee_code: feeCode,
    fee_code_text: feeCodeText,
    fee_extra: 0,
    fee_msp: 0,
    from_time: '',
    icd9: icd9Code,
    icd9_text: icd9Text,
    index: invoice?.index,
    interval: '',
    max_unit: -1,
    note: invoice[inputs.comment.name],
    per_unit: 0,
    percent: invoice[inputs.percent.name],
    portion: 0,
    record_date: '',
    service_date: invoice[inputs.serviceDate.name],
    service_start: invoice[inputs.startTime.name],
    service_finish: invoice[inputs.endTime.name],
    soft_max_unit: -1,
    to_time: '',
    total_time: '',
    units: invoice[inputs.units.name],
    user_selected: true,
    warning: '',
    weekend: false,
    xcode: ''
  };
};
