/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuLayout from './MenuLayout';
import MenuItemRender from './MenuItemRender';
import GlobalSearch from '../GlobalSearch';

import { routes } from '../../../../routes/routes';
import { addNewTeleplan } from '../../../claims/helpers/addNewInvoice';
import { t } from '../../../../service/localization/i18n';
import { elementIDs } from '../../../config/elementIDsConfig';

const CreateTeleplan = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);
  const { showNewTeleplanButtons } = useSelector((state) => state.core.window);

  const items = useMemo(() => {
    const items = [
      {
        label: <div className="font-bold">{`Create Teleplan claims`}</div>,
        items: [
          {
            id: elementIDs.createFirstVisitInvoiceButton,
            label: t('First_visit'),
            icon: 'pi pi-plus',
            command: () => addNewTeleplan({ history, firstVisit: true }),
            template: (item) => <MenuItemRender item={item} />
          }
        ]
      }
    ];

    if (!showNewTeleplanButtons) {
      items.push({
        id: 'picker',
        label: t('Picker'),
        icon: 'pi pi-plus',
        command: () => addNewTeleplan({ history, pos: true }),
        template: (item) => <MenuItemRender item={item} />
      });

      items.push({
        id: 'batch',
        label: t('Batch'),
        icon: 'pi pi-plus',
        command: () => history.push(routes.teleplanGroup.path),
        template: (item) => <MenuItemRender item={item} />
      });
    }

    items.push({
      id: elementIDs.createTeleplanInvoiceButton,
      label: t('Teleplan'),
      icon: 'pi pi-plus',
      command: () => addNewTeleplan({ history, pos: false }),
      template: (item) => <MenuItemRender item={item} />
    });

    items.push({
      template: () => (
        <div className="pt-3 pb-2 px-2">
          <GlobalSearch name="claim" />
        </div>
      )
    });

    return items;
  }, [history, showNewTeleplanButtons]);

  if (!user.canBillMSP) {
    return null;
  }

  return <MenuLayout model={items} />;
};

export default CreateTeleplan;
