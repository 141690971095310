import { t } from '../../service/localization/i18n';
import { aoiCodeMaxEntry, feecodesMaxEntry, icd9CodeMaxEntry, noiCodeMaxEntry, referralCodeMaxEntry } from './defaultValuesConfig';

export const commonInputs = {
  mostRecent: {
    name: 'MostRecent',
    label: t('Most_Recent'),
    codeType: 'mostRecent'
  },

  claimGuid: {
    name: 'ClaimGuid'
  },

  claimNumber: {
    name: 'ClaimNumber'
  },

  fantom: {
    name: 'Fantom'
  },

  phnProvince: {
    name: 'PHNProvince'
  },

  phn: {
    name: 'PHN',
    label: t('PHN')
  },

  birthDay: {
    name: 'BirthDay',
    label: t('Day_of_birthday.2'),
    placeholder: t('MM_DD_YYYY')
  },

  lastName: {
    name: 'LastName',
    label: t('Last_Name')
  },

  firstName: {
    name: 'FirstName',
    label: t('First_Name')
  },

  midName: {
    name: 'MidName',
    label: t('Middle_Name')
  },

  nickname: {
    name: 'AlterName',
    label: t('Nickname')
  },

  accessWord: {
    name: 'AccessWord',
    label: 'Access Word'
  },

  gender: {
    name: 'Sex',
    label: t('Gender')
  },

  addressLine: {
    name: 'AddressLine',
    label: t('Street Address')
  },

  city: {
    name: 'City',
    label: t('City')
  },

  province: {
    name: 'Province',
    label: t('Province')
  },

  zip: {
    name: 'Zip',
    label: t('Postal_Code')
  },

  location: {
    name: 'LocationName',
    label: t('Location'),
    codeType: 'location'
  },

  practitioner: {
    name: 'TreatedBy',
    id: 'DoctorGuid',
    licenceNumber: 'LicenceNumber',
    label: t('Practitioner'),
    codeType: 'practitioner'
  },

  practitionerGuid: {
    name: 'DoctorGuid',
    label: t('Practitioner')
  },

  patients: {
    name: 'PatientGuid',
    descriptionName: 'Patients',
    label: t('Patient.2'),
    codeType: 'patients'
  },

  patient: {
    name: 'PatientDetails',
    label: t('Patient.1')
  },

  serviceDate: {
    name: 'ServiceDate',
    label: t('Service_date'),
    codeType: 'serviceDate'
  },

  feeCodes: {
    name: 'FeeCode',
    label: t('Fees_Item'),
    tabLabel: t('Fees_Item'),
    codeType: 'feecodes',
    descriptionName: 'FeeCodeText',
    maxEntries: feecodesMaxEntry,
    searchAllType: 'fee_all'
  },

  icd9: {
    name: 'ICD9Code',
    label: t('Dx'),
    tabLabel: t('Dx'),
    codeType: 'icd9',
    descriptionName: 'ICD9Text',
    maxEntries: icd9CodeMaxEntry,
    searchAllType: 'icd9_all'
  },

  noi: {
    name: 'NatureOfInjury',
    label: t('Nature_of_Injury.1'),
    tabLabel: t('Nature_of_Injury.2'),
    codeType: 'noi',
    descriptionName: 'NOIText',
    maxEntries: noiCodeMaxEntry,
    searchAllType: 'noi_all'
  },

  aoi: {
    name: 'AreaOfInjury',
    label: t('Area_of_Injury.1'),
    tabLabel: t('Area_of_Injury.2'),
    codeType: 'aoi',
    descriptionName: 'AOIText',
    maxEntries: aoiCodeMaxEntry,
    searchAllType: 'aoi_all'
  },

  referral: {
    name: 'RefNumber',
    label: t('Ref_Practitioner'),
    tabLabel: t('Referral'),
    codeType: 'referral',
    descriptionName: 'RefName',
    maxEntries: referralCodeMaxEntry
  },

  submitWithGenericPHN: {
    name: 'SpecPHN',
    label: t('Submit_With_Generic_PHN')
  },

  speciality: {
    name: 'Speciality'
  },

  isPrimary: {
    name: 'IsPrimary',
    label: t('Primary')
  },

  rowGuid: {
    name: 'RowGuid'
  },

  insurerGuid: {
    name: 'InsurerGuid'
  },

  sendNow: {
    name: 'sendNow'
  },

  insurerNameEN: {
    name: 'InsurerNameEn'
  },

  policyNumber: {
    name: 'PolicyNum',
    label: t('Policy_Number')
  },

  memberID: {
    name: 'MemberID',
    label: t('Member_ID')
  },

  primaryPolicy: {
    name: 'PrimaryPolicy',
    label: t('Primary_Insurance_Policy')
  },

  secondaryPolicy: {
    name: 'SecondaryPolicy',
    label: t('Secondary_Insuranc_Policy')
  }
};
