import React from 'react';
import { useFormContext } from 'react-hook-form';
import { usePractitioner } from '../../../hooks/usePractitioner';
import PractitionerWithFormControl from '../../../../../../../components/Inputs/Practitioner/PractitionerWithFormControl';

const Practitioner = () => {
  const { control, isNew, isView } = useFormContext();
  const { onChange, getListOfPractitioners } = usePractitioner();
  const practitionersList = getListOfPractitioners();

  return (
    <PractitionerWithFormControl control={control} naOption={isNew} disabled={isView} practitionersList={practitionersList} onChange={onChange} />
  );
};

export default Practitioner;
