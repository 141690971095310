import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import { updateWSBCRecord } from '../../../../actions/claims.action.creators';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { useURLQuery } from '../../../../../hooks/useURLQuery';
import { t } from '../../../../../../service/localization/i18n';
import { isEmpty } from 'lodash';

const FormActionButtons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useURLQuery();
  const { wsbcRecord, isSavingClaims } = useSelector((state) => state.claims);
  const { isDirty } = useFormContext();

  const onClose = () => {
    !isEmpty(wsbcRecord) && dispatch(updateWSBCRecord({}));
    query.get('replaceTo') ? history.replace(query.get('replaceTo')) : history.goBack();
  };

  const closeButton = <Button id={elementIDs.close} className="w-full" type="button" label={t('Close')} onClick={onClose} />;

  return (
    <>
      {!isDirty && closeButton}

      {isDirty && (
        <>
          <Button
            id={elementIDs.createReportButton}
            className="mr-1 w-full"
            type="submit"
            form="wsbcReportForm"
            label={t('Save_and_Review')}
            loading={isSavingClaims}
          />

          <Button
            id={elementIDs.cancel}
            className="p-button-outlined ml-1 w-full"
            type="button"
            label={t('Cancel')}
            onClick={() => history.goBack()}
          />
        </>
      )}
    </>
  );
};

export default FormActionButtons;
