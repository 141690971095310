import { castArray, compact, isArray, isEqual, mapValues, mergeWith, orderBy, unionBy } from 'lodash';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import actions from './claims.action.types';
import { getApiBase, setAuthHeader } from '../../../config';
import { getAge } from '../../utils/getAge';
import { trimLeftZeros } from '../../utils/trimLeftZeros';
import { getStatusCode, getStatusLable } from '../../utils/getStatusCode';
import { prefsCodes } from '../../config/prefsCodesConfig';
import { convertToMilitaryTimeFormat, toMilitaryTime } from '../../utils/formatTime';
import { provinces, getItemFromArray, findDiff } from '../../../Helper';
import { currencyFormat } from '../../utils/currencyFormat';
import { location_codes } from '../../common/contants';
import { getPresetPrefs, getDefaultPrefs } from '../helpers/getDefaultPrefs';
import { submissionCodes, privateStatuses, allStatuses, invoiceTypes } from '../helpers/mapper';
import { stripHTMLTags } from '../../regex/regex';
import { setClean, setToastMessage, setDirty } from '../../core/actions/core.action.creators';
import { defaultPageSize, claimsFiltersWithPeriod, claimsDefaultSortBy, claimsDefaultFilters } from '../../config/defaultValuesConfig';
import { deleteClaimSuccess, archiveClaimSuccessMessage } from '../helpers/toastMessages';
import { formatDate } from '../../utils/formatDate';
import { commonInputs } from '../../config/commonInputsConfig';
import { t } from '../../../service/localization/i18n';
import { multipleDoctorGuid, NA_DoctorGuid } from '../../config/defaultValuesConfig';
import { decodeText } from '../../utils/decodeText';
import { formatWSBCRInitialValues } from '../components/WSBCReport/helpers/formatWSBCRInitialValues';
import { inputs as teleplantInputs } from '../components/TeleplanInvoice/helpers/inputs';
import * as responseActions from './responseActions';
import { store } from '../../../store';
import { codePickerPageSize } from '../../config/defaultValuesConfig';
import invoicesCatalogCategories from '../../../mocks/db/claims/codesForCatalogs/invoicesCatalogCategories.json';
import { defaultLocationCode } from '../../config/defaultValuesConfig';
import { getExactMatchesFirst } from '../../utils/getExactMatchesFirst';
import mostRecentCodes from '../../../mocks/db/claims/mostRecentCodes/mostRecentCodes.json';
import { calculateFirst } from '../../utils/calculate';
import { initNotesQuery } from '../components/ClaimDetails/useClaimNotes';
import { getTotalUnreadNotes } from '../../notes/actions/actions';

const dayOfWeekAsString = (dayIndex) => {
  //return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][dayIndex] || '';
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][dayIndex] || '';
};

const formatClaimList = (arr) => {
  return arr.map((x) => {
    x.Age = getAge(x.BirthDay);
    const dow = new Date(x.ServiceDate).getDay();
    const SDateDow = isNaN(dow) ? '' : dayOfWeekAsString(dow);
    var rectype =
      x.InvoiceType === 'M'
        ? 'MSP'
        : x.InvoiceType === 'W'
        ? 'WSBC'
        : x.InvoiceType === 'R'
        ? 'WSBCR'
        : x.InvoiceType === 'I'
        ? 'ICBC'
        : x.InvoiceType === 'P'
        ? 'Private'
        : x.InvoiceType === 'E'
        ? 'Extended'
        : '';
    var diagnos = x.InvoiceType === 'P' ? '' : x.DiagnosticCodeID || x.ICD9Code;
    if (x.DiagnosticCode2) diagnos += ',' + x.DiagnosticCode2;
    if (x.DiagnosticCode3) diagnos += ',' + x.DiagnosticCode3;
    var reciprocal = x.PHN ? x.PHN.substring(0, 2) : '';
    var suspect = provinces.find((x) => x.value === reciprocal.toUpperCase());
    var payor = suspect ? suspect.value : x.Province || 'BC';
    // if (suspect && reciprocal.length === 2) {
    //   x.PHN = x.PHN.replace(reciprocal, '');
    // }
    var percent = x.NoOfServiceUnits < 1 ? x.NoOfServiceUnits * 100 : 100;
    var units = x.NoOfServiceUnits < 1 ? 1 : x.NoOfServiceUnits;
    var feeTotal = x.FeeTotal ? x.FeeTotal : x.FeeAmount * x.NoOfServiceUnits ?? 0;
    var feeDue = feeTotal - x.FeePaid ?? 0;
    if (feeDue < 0 && 'MIWR'.indexOf(x.InvoiceType) > -1) feeDue = 0; // paid more due to adjustment
    if (x.Status === 2 && 'MIWR'.indexOf(x.InvoiceType) > -1) feeDue = 0; // final MSP decision
    var feecode = x.FeeCode;
    var feecodetext = x.InvoiceType === 'P' ? x.FeeCode : x.FeeCodeText;
    var sentAt = x.SDate ? x.SDate.replace('T', ' ').substring(0, 19) : '';
    var gotAt = x.Received ? x.Received.split('T')[0] : '';
    var injDate = x.InjuryDay ? x.InjuryDay.replace('T00:00:00', '') : '';
    var submissionCodeText = '';
    const treatedBy =
      x.DoctorGuid?.toUpperCase() === x.DCNGuid?.toUpperCase() ? t('Multiple_practitioners') : `${x.DLastName}, ${x.DFirstName}`.toUpperCase();

    if (x.Status !== 0 && x.Status !== 4 && x.Status !== -1) {
      x.SubmissionCode = x.SubmissionCode ?? '0'; // fallback to Normal
      var defaultSubmissionCode = submissionCodes.find((x) => x.value === '0');
      var findSubmissionCode = submissionCodes.find((i) => i.value === x.SubmissionCode) || defaultSubmissionCode;
      x.SubmissionCode = findSubmissionCode.value;
      submissionCodeText = x.SubmissionCode && `${findSubmissionCode?.value} - ${findSubmissionCode?.text}`;
    }

    var errorCodes = [];
    if (x.MSPCodes) {
      const parts = x.MSPCodes.split(',<br/>');
      errorCodes = parts.map((i) => i.replace(stripHTMLTags, '')?.replace('[', '')?.replace(']', ''));
    }
    var adjCodes = [];
    if (x.AdjText) {
      const parts = x.AdjText.split(',<br/>');
      adjCodes = parts.map((i) => parts.map((i) => i.replace(stripHTMLTags, '')?.replace('[', '')?.replace(']', '')));
    }

    const _formatted = {
      ...x,
      InvoiceGuid: x.InvoiceGuid,
      ServiceDate: x.ServiceDate,
      SDow: SDateDow,
      RecordNo: trimLeftZeros(x.RecordNo),
      InvoiceType: rectype,
      RecType: rectype,
      DCNGuid: x.DCNGuid,
      ClaimGuid: x.ClaimGuid,
      PatientGuid: x.PatientGuid,
      DoctorGuid: x.DoctorGuid,
      // EditedBy: currentUser?.DoctorGuid || "",
      TreatedBy: treatedBy,
      FullName: `${x.LastName}, ${x.FirstName}`.toUpperCase(),
      Payor: payor,
      FeeCode: feecode,
      FeeCodeText: feecodetext,
      SequenceNumber: trimLeftZeros(x.SequenceNum),
      StartTime: convertToMilitaryTimeFormat(x.StartTime),
      StartTimeText: x.StartTime ? x.StartTime : t('N_A'),
      FinishTime: convertToMilitaryTimeFormat(x.FinishTime),
      FinishTimeText: x.FinishTime ? x.FinishTime : t('N_A'),
      CallTime: convertToMilitaryTimeFormat(x.CallTime),
      SCCode: x.SCCode,
      FeeAmount: x.FeeAmount,
      FeePaid: x.FeePaid,
      FeeDue: Math.round(feeDue * 1e2) / 1e2,
      FeeTotal: Math.round(feeTotal * 1e2) / 1e2,
      ICD9Code: diagnos?.replaceAll(',', ', '),
      // ICD9Code2: x.DiagnosticCode2,
      // ICD9Code3: x.DiagnosticCode3,
      ICD9Text: decodeText(x.ICD9Text).split('|'),
      ICD9Note: x.ICD9Note,
      MSPCodes: errorCodes,
      PHN: x.PHN,
      PHNProvince: x.PHNProvince,
      Province: x.Province,
      BirthDay: x.BirthDay,
      Age: x.Age,
      Gender: x.Sex,
      PractitionerNumber: x.PractitionerNumber,
      PayeeNumber: x.PayeeNumber,
      RuralCode: x.RuralCode === '00' || !x.RuralCode ? 'N/A' : x.RuralCode,
      RuralName: x.RuralName === '00' || !x.RuralName ? 'N/A' : x.RuralName,
      RefToBy: x.RefToBy ? x.RefToBy : 'N',
      RefNumber: x.RefNumber,
      RefName: x.RefName,
      Comment: x.Comment,
      LocationCode: x.LocationCode,
      AACText: x.AACText,
      LineCount: x.LineCount,
      LineDetails: x.LineDetails ? JSON.parse(x.LineDetails) : null,
      AOIText: x.AOIText,
      NOIText: x.NOIText,
      Included: x.Included,
      DateCreated: x.DateCreated,
      DateUpdated: x.DateUpdated,
      SDate: sentAt,
      Received: gotAt,
      SubmissionGuid: x.SubmissionGuid,
      SubmissionCode: x.SubmissionCode,
      SubmissionCodeText: x.SubmissionCode ? submissionCodeText : '',
      RemittanceGuid: x.RemittanceGuid,
      RemittanceCode: x.RemittanceCode,
      InjuryDay: injDate,
      AnatomicCode: x.AnatomicCode,
      ClaimNumber: x.ClaimNumber,
      AreaOfInjury: x.AreaOfInjury,
      AreaOfTreatment: x.AreaOfTreatment,
      NatureOfInjury: x.NatureOfInjury,
      Units: units,
      Percent: percent,
      Status: x.Status,
      StatusText: x.StatusText,
      SaveAsDraft: x.Status === 4,
      RemDiff: x.RemDiff,
      AdjCode: x.AdjCode,
      AdjText: adjCodes,
      AdjTotal: x.AdjTotal,
      Speciality: x.Speciality ? x.Speciality : x.Specialty
    };

    delete _formatted.Specialty;

    return _formatted;
  });
};

export const formatted = (arr, preferences, users) => {
  const currentUser = getItemFromArray(users, preferences?.UserGuid, 'DoctorGuid');
  const defaultPrefs = preferences?.prefs?.find((i) => i.label === 'default')?.content;

  if (Array.isArray(arr)) {
    return arr.map((x) => formatItem(x, currentUser, defaultPrefs));
  } else {
    return formatItem(arr, currentUser, defaultPrefs);
  }
};

// Function to format a single item
const formatItem = (x, currentUser, defaultPrefs) => {
  x.Age = getAge(x.BirthDay);
  const dow = new Date(x.ServiceDate).getDay();
  const SDateDow = isNaN(dow) ? '' : dayOfWeekAsString(dow);
  var rectype =
    x.InvoiceType === 'M'
      ? 'MSP'
      : x.InvoiceType === 'W'
      ? 'WSBC'
      : x.InvoiceType === 'R'
      ? 'WSBCR'
      : x.InvoiceType === 'I'
      ? 'ICBC'
      : x.InvoiceType === 'P'
      ? 'Private'
      : x.InvoiceType === 'E'
      ? 'Extended'
      : '';
  var diagnos = x.InvoiceType === 'P' ? '' : x.DiagnosticCodeID || x.ICD9Code;
  if (x.DiagnosticCode2) diagnos += ',' + x.DiagnosticCode2;
  if (x.DiagnosticCode3) diagnos += ',' + x.DiagnosticCode3;
  var reciprocal = x.PHN ? x.PHN.substring(0, 2) : '';
  var suspect = provinces.find((x) => x.value === reciprocal.toUpperCase());
  var payor = suspect ? suspect.value : x.Province || 'BC';
  // if (suspect && reciprocal.length === 2) {
  //   x.PHN = x.PHN.replace(reciprocal, '');
  // }
  var percent = x.NoOfServiceUnits < 1 ? x.NoOfServiceUnits * 100 : 100;
  var units = x.NoOfServiceUnits < 1 ? 1 : x.NoOfServiceUnits;
  var feeTotal = x.FeeTotal ? x.FeeTotal : x.FeeAmount * x.NoOfServiceUnits ?? 0;
  var feeDue = feeTotal - x.FeePaid ?? 0;
  if (feeDue < 0 && 'MIWR'.indexOf(x.InvoiceType) > -1) feeDue = 0; // paid more due to adjustment
  if (x.Status === 2 && 'MIWR'.indexOf(x.InvoiceType) > -1) feeDue = 0; // final MSP decision
  var feecode = x.FeeCode;
  var feecodetext = x.InvoiceType === 'P' ? x.FeeCode : x.FeeCodeText;
  var sentAt = x.SDate ? x.SDate.replace('T', ' ').substring(0, 19) : '';
  var gotAt = x.Received ? x.Received.split('T')[0] : '';
  var injDate = x.InjuryDay ? x.InjuryDay.replace('T00:00:00', '') : '';
  var submissionCodeText = '';
  const treatedBy =
    x.DoctorGuid?.toUpperCase() === x.DCNGuid?.toUpperCase() ? t('Multiple_practitioners') : `${x.DLastName}, ${x.DFirstName}`.toUpperCase();

  if (x.Status !== 0 && x.Status !== 4 && x.Status !== -1) {
    x.SubmissionCode = x.SubmissionCode ?? '0'; // fallback to Normal
    var defaultSubmissionCode = submissionCodes.find((x) => x.value === '0');
    var findSubmissionCode = submissionCodes.find((i) => i.value === x.SubmissionCode) || defaultSubmissionCode;
    x.SubmissionCode = findSubmissionCode.value;
    submissionCodeText = x.SubmissionCode && `${findSubmissionCode?.value} - ${findSubmissionCode?.text}`;
  }

  // //Location Code
  // //[KS] CMO-1112 - Edit Declined Claim screen->empty Rural code and Location code fields
  let locationCode = '';
  if (!x.LocationCode || x.LocationCode === 'A') {
    if (getPresetPrefs(defaultPrefs, prefsCodes.presetLocation) === '1') {
      locationCode = getDefaultPrefs(defaultPrefs, prefsCodes.defaultLocation);
    } else {
      currentUser?.LocationCode ? (locationCode = currentUser?.LocationCode) : (locationCode = defaultLocationCode(currentUser?.Speciality).value);
    }
  } else {
    locationCode = x.LocationCode;
  }
  const loc = location_codes.find((item) => item.value === locationCode);
  const locationText = loc ? loc.label : locationCode;
  // //==============================//

  var errorCodes = [];
  if (x.MSPCodes) {
    const parts = x.MSPCodes.split(',<br/>');
    errorCodes = parts.map((i) => i.replace(stripHTMLTags, '')?.replace('[', '')?.replace(']', ''));
  }
  var adjCodes = [];
  if (x.AdjText) {
    const parts = x.AdjText.split(',<br/>');
    adjCodes = parts.map((i) => parts.map((i) => i.replace(stripHTMLTags, '')?.replace('[', '')?.replace(']', '')));
  }

  const _formatted = {
    ...x,
    InvoiceGuid: x.InvoiceGuid,
    ServiceDate: x.ServiceDate,
    SDow: SDateDow,
    RecordNo: trimLeftZeros(x.RecordNo),
    InvoiceType: rectype,
    RecType: rectype,
    DCNGuid: x.DCNGuid,
    ClaimGuid: x.ClaimGuid,
    PatientGuid: x.PatientGuid,
    DoctorGuid: x.DoctorGuid,
    // EditedBy: currentUser?.DoctorGuid || "",
    TreatedBy: treatedBy,
    FullName: `${x.LastName}, ${x.FirstName}`.toUpperCase(),
    Payor: payor,
    FeeCode: feecode,
    FeeCodeText: feecodetext,
    SequenceNumber: trimLeftZeros(x.SequenceNum),
    StartTime: convertToMilitaryTimeFormat(x.StartTime),
    StartTimeText: x.StartTime ? x.StartTime : t('N_A'),
    FinishTime: convertToMilitaryTimeFormat(x.FinishTime),
    FinishTimeText: x.FinishTime ? x.FinishTime : t('N_A'),
    CallTime: convertToMilitaryTimeFormat(x.CallTime),
    SCCode: x.SCCode,
    FeeAmount: x.FeeAmount,
    FeePaid: x.FeePaid,
    FeeDue: Math.round(feeDue * 1e2) / 1e2,
    FeeTotal: Math.round(feeTotal * 1e2) / 1e2,
    ICD9Code: diagnos?.replaceAll(',', ', '),
    // ICD9Code2: x.DiagnosticCode2,
    // ICD9Code3: x.DiagnosticCode3,
    ICD9Text: decodeText(x.ICD9Text).split('|'),
    ICD9Note: x.ICD9Note,
    MSPCodes: errorCodes,
    PHN: x.PHN,
    PHNProvince: x.PHNProvince,
    Province: x.Province,
    BirthDay: x.BirthDay,
    Age: x.Age,
    Gender: x.Sex,
    PractitionerNumber: x.PractitionerNumber,
    PayeeNumber: x.PayeeNumber,
    RuralCode: x.RuralCode === '00' || !x.RuralCode ? 'N/A' : x.RuralCode,
    RuralName: x.RuralName === '00' || !x.RuralName ? 'N/A' : x.RuralName,
    RefToBy: x.RefToBy ? x.RefToBy : 'N',
    RefNumber: x.RefNumber,
    RefName: x.RefName,
    Comment: decodeText(x.Comment),
    LocationCode: locationCode,
    LocationText: locationText,
    AACText: x.AACText,
    LineCount: x.LineCount,
    LineDetails: x.LineDetails ? JSON.parse(x.LineDetails) : null,
    AOIText: x.AOIText,
    NOIText: x.NOIText,
    Included: x.Included,
    DateCreated: x.DateCreated,
    DateUpdated: x.DateUpdated,
    SDate: sentAt,
    Received: gotAt,
    SubmissionGuid: x.SubmissionGuid,
    SubmissionCode: x.SubmissionCode,
    SubmissionCodeText: x.SubmissionCode ? submissionCodeText : '',
    RemittanceGuid: x.RemittanceGuid,
    RemittanceCode: x.RemittanceCode,
    InjuryDay: injDate,
    AnatomicCode: x.AnatomicCode,
    ClaimNumber: x.ClaimNumber,
    AreaOfInjury: x.AreaOfInjury,
    AreaOfTreatment: x.AreaOfTreatment,
    NatureOfInjury: x.NatureOfInjury,
    Units: units,
    Percent: percent,
    Status: x.Status,
    StatusText: x.StatusText,
    SaveAsDraft: x.Status === 4,
    RemDiff: x.RemDiff,
    AdjCode: x.AdjCode,
    AdjText: adjCodes,
    AdjTotal: x.AdjTotal,
    Speciality: x.Speciality ? x.Speciality : x.Specialty
  };

  delete _formatted.Specialty;

  return _formatted;
};

const formatList = (actionType, results, preferences, users) => {
  const claims = results.data.claimList;

  return {
    type: actionType,
    results: {
      PatientGuid: results.data.PatientGuid,
      data: { claimList: formatted(claims, preferences, users) },
      statusList: results.data.statusList,
      query: results.data.query,
      sortby: results.data.sortby,
      page: results.data.page,
      pageSize: results.data.pageSize,
      active_item: results.data.active_item,
      totalRecords: results.data.totalRecords,
      totalRecordsAlter: results.data.totalRecordsAlter,
      totalPages: results.data.totalPages,
      totalBilled: results.data.totalBilled,
      totalPaid: results.data.totalPaid,
      totalDue: results.data.totalDue
    }
  };
};

const formatCounters = (data) => {
  const formattedCounters = data?.map((i) => {
    const parsed = i.StatusText.split('_');
    const statusList = parsed[0] === 'pp' ? privateStatuses : allStatuses;

    return {
      ...i,
      InvoiceType: invoiceTypes.find((i) => i.type === parsed[0])?.label,
      Total: currencyFormat(i.TotalFee),
      StatusCode: getStatusCode(parsed[1], statusList),
      StatusCodeText: getStatusLable(getStatusCode(parsed[1], statusList)),
      StatusTextNormialized: parsed[1]
    };
  });

  return formattedCounters;
};

const formatMSPCodes = (txt = '') => {
  return txt?.replaceAll(',<br/>', ' ').replaceAll(stripHTMLTags, '')?.replaceAll('[', '')?.replaceAll(']', '');
};

export const setClaimIndex = (idx) => ({
  type: actions.SET_CLAIM_INDEX,
  results: idx
});

export const setDirection = (dir) => ({
  type: actions.SET_CLAIM_DIR,
  results: dir
});

export const statusOptionsAction = (value) => ({
  type: actions.CLAIM_STATUS_OPTIONS,
  results: value
});

export const setClaimFiltersWithRequest = (value, id) => (dispatch, state) => {
  const currentState = state();
  const { pageSize, filters = '', sortby = '', PatientGuid } = currentState.claims;
  const userList = currentState.clinic.members;
  const whereAmI = currentState.core.window;
  const showDataScroller = currentState.core.showDataScroller;
  let combined = id ? { ...filters, [id]: value } : { ...claimsFiltersWithPeriod, ...value };
  if (id === 'DoctorGuid') {
    const doctor = getItemFromArray(userList, value, 'DoctorGuid');
    if (doctor) {
      combined.DoctorName = `${doctor.FirstName} ${doctor.LastName}`;
    }
  }
  if (id === 'PeriodFrom' || id === 'PeriodTo') {
    combined.ServiceDate = 'derived';
  }

  // Commented because of VER-554
  // if (combined.showArchived === '') {
  //   combined.showArchived = true;
  // }

  const filter = JSON.stringify(combined);
  const query = `pid=${PatientGuid}&sortby=${sortby}&q=${filter}`;
  const updatedPageSizze = pageSize >= defaultPageSize ? pageSize : defaultPageSize;

  const page_info = { page: 1, pageSize: updatedPageSizze, query, filter };
  if (whereAmI.isMobileOnly && showDataScroller) {
    getClaims4Scroll(page_info)(dispatch, state);
  } else {
    getClaimList(page_info)(dispatch, state);
  }
  dispatch({
    type: actions.CLAIM_FILTERS,
    results: combined
  });
};

export const setClaimFilters = (value, id) => (dispatch, state) => {
  const currentState = state();
  let combined = id ? { ...currentState.claims.filters, [id]: value } : value;
  if (value.ServiceDate && value.ServiceDate !== '' && value.ServiceDate !== 'derived') {
    const hint = 'YYYY/MM/DD';
    const real_value = findDiff(hint, value.ServiceDate);

    const period = getPeriodFromDateInput(real_value);

    combined.Period = '7';
    combined.PeriodFrom = period.PeriodFrom;
    combined.PeriodTo = period.PeriodTo;
  }
  dispatch({
    type: actions.CLAIM_FILTERS,
    results: combined
  });
};

export const setDefaultClaimsTableValues = () => {
  return {
    type: actions.SET_CLAIMS_DEFAULT_TABLE_VALUES
  };
};

export const deleteClaim =
  ({ invoiceGuid, patientGuid, showOnDetailsView = false, callback }) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState.user.details.DCNGuid;
    const isMobileOnly = currentState.core.window.isMobileOnly;
    const showDataScroller = currentState.core.showDataScroller;

    const getClaimListParams = () => {
      const claims = currentState.claims;
      const sortby = claims.sortby;
      const pageSize = claims.pageSize;
      const listOfClaims = isMobileOnly && showDataScroller ? claims?.complete_list : claims.claim_list;
      const page = claims?.page === claims?.totalPages && listOfClaims?.length === 1 ? claims.page - 1 : claims.page;
      const filters = claims.filters;
      const query = `pid=${patientGuid}&sortby=${sortby}&q=${JSON.stringify(filters)}`;
      return {
        page: page,
        pageSize: pageSize,
        filter: JSON.stringify(filters),
        query,
        invoiceGuid,
        action: 'delete',
        showOnDetailsView
      };
    };

    dispatch({
      type: actions.DELETING_CLAIM,
      results: true
    });

    axios
      .delete(`${getApiBase()}/claims/${DCNGuid}/${invoiceGuid}`)
      .then(() => {
        dispatch({
          type: actions.DELETING_CLAIM,
          results: false
        });

        if (callback) callback();
        if (!callback) {
          dispatch(getClaimList(getClaimListParams()));
          dispatch(setToastMessage({ type: 'success', message: deleteClaimSuccess }));
        }
      })
      .catch((reason) => {
        dispatch({
          type: actions.DELETING_CLAIM,
          results: false
        });
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const getClaimHistory = (invoiceId, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .get(`${getApiBase()}/claims/${DCNGuid}/${invoiceId}/history`)
    .then((results) => {
      callback(results.data);
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// copy-paste from saveClaimRecord
export const archiveClaimRecord = (claimRecord, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;
  const isMobileOnly = currentState.core.window.isMobileOnly;
  const showDataScroller = currentState.core.showDataScroller;
  const claims = currentState.claims;
  const sortby = claims.sortby;
  const pageSize = claims.pageSize;
  const page = claims.page;
  const filters = claims.filters;
  const dataTableParams = {
    page: page,
    sortby: sortby,
    pageSize: pageSize,
    filter: JSON.stringify(filters)
  };

  dispatch({ type: actions.IS_ARCHIVING, results: true });

  axios
    .put(`${getApiBase()}/claims/${DCNGuid}/${claimRecord.InvoiceGuid}/archive`, qs.stringify(claimRecord))
    .then(() => {
      dispatch({ type: actions.IS_ARCHIVING, results: false });
      dispatch(
        setToastMessage({
          type: 'success',
          message: archiveClaimSuccessMessage
        })
      );

      callback &&
        callback({
          isMobileOnly,
          showDataScroller,
          getClaimListParams: dataTableParams
        });
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_ARCHIVING, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getClaimInfo =
  (invoiceGuid, callback, recordType = false) =>
  (dispatch, state) => {
    // Mock data for tests
    if (process.env.REACT_APP_CYPRESS) {
      return responseActions.getClaimInfoResponseActionForTests(invoiceGuid, callback);
    }

    setAuthHeader(state);
    const currentState = state();
    const DCNGuid = currentState.user.details.DCNGuid;
    const commonURL = `${getApiBase()}/claims/${DCNGuid}/${invoiceGuid}`;
    const url = recordType ? `${commonURL}?RecordType=${recordType}` : commonURL;

    !recordType && dispatch({ type: actions.GETTING_CLAIM_INFO, results: true });

    axios
      .get(url)
      .then((results) => {
        !recordType && dispatch({ type: actions.GETTING_CLAIM_INFO, results: false });
        return responseActions.getClaimInfoResponseAction(results.data, callback);
      })
      .catch((reason) => {
        !recordType && dispatch({ type: actions.GETTING_CLAIM_INFO, results: false });
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const getClaimDetails = (invoiceGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const users = currentState.clinic.members;
  const preferences = currentState.preferences.currentUserPrefs;

  return axios
    .get(`${getApiBase()}/claims/${DCNGuid}/${invoiceGuid}`)
    .then((results) => {
      return formatted(results.data, preferences, users);
    })
    .catch((reason) => {
      console.log('!!!getClaimDetails', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getClaimNotes = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize = 20, page = 1, invoiceGuid, freetext = '' } = payload;

  const baseUrl = `${getApiBase()}/notes/${DCNGuid}/claims/${invoiceGuid}/${page}/${pageSize}`;
  const url = new URL(baseUrl);

  if (freetext) {
    const queryParams = new URLSearchParams(url.search);
    queryParams.set('q', JSON.stringify({ freetext: freetext }));
    url.search = queryParams.toString();
  }

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getClaimNotes', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getClaimNoteDetails = (id) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .get(`${getApiBase()}/notes/${DCNGuid}/claims/${id}`)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getClaimNoteDetails', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const getAllClaimNotes = (payload) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const { pageSize, page, freetext = '', sortby = 'NoteDate_desc', query = {} } = payload;
  const queryParams = new URLSearchParams();
  //sortby && queryParams.set('sortby', sortby);
  query && queryParams.set('q', JSON.stringify(query));

  const baseUrl = `${getApiBase()}/notes/${DCNGuid}/claims/${page}/${pageSize}`;
  const url = new URL(baseUrl);
  url.search = queryParams.toString();

  if (freetext) {
    const queryParams = new URLSearchParams(url.search);
    queryParams.set('q', JSON.stringify({ freetext: freetext }));
    url.search = queryParams.toString();
  }

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getClaimNotes', error);
      store.dispatch(setToastMessage({ type: 'error', lifeTime: 10000, message: t('Something_went_wrong') }));
    });
};

export const createClaimNote = (note) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/notes/${DCNGuid}/claims`, note)
    .then((results) => {
      getTotalUnreadNotes();
      return results.data;
    })
    .catch((error) => {
      console.log('!!!createClaimNote', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const editClaimNote = (note) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/notes/${DCNGuid}/claims/${note.NoteGuid}`, note)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!editClaimNote', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const updateNoteStatus = (note, status) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .put(`${getApiBase()}/notes/${DCNGuid}/${note.NoteGuid}/${status}`)
    .then((results) => {
      getTotalUnreadNotes();
      return results.data;
    })
    .catch((error) => {
      console.log('!!!updateNoteStatusNote', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const deleteClaimNote = (noteGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .delete(`${getApiBase()}/notes/${DCNGuid}/${noteGuid}`)
    .then(() => {
      getTotalUnreadNotes();
      return true;
    })
    .catch((error) => {
      console.log('!!!deleteClaimNote', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getPatientsMostResentClaim = (patientGuid, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;
  const filters = JSON.stringify({
    InvoiceType: 'Patient',
    Period: '-2',
    PeriodText: 'All'
  });
  const query = `pid=${patientGuid}&sortby=DateUpdated_desc&q=${filters}`;
  const url = `${getApiBase()}/claims/${DCNGuid}/${1}/${1}/?${query}`;

  dispatch({ type: actions.GET_PATIENTS_MOST_REASENT_CLAIM, results: true });

  axios
    .get(url)
    .then((results) => {
      results.data.claimList.length ? callback(results.data.claimList[0]) : callback(null);
      dispatch({
        type: actions.GET_PATIENTS_MOST_REASENT_CLAIM,
        results: false
      });
    })
    .catch((reason) => {
      dispatch({
        type: actions.GET_PATIENTS_MOST_REASENT_CLAIM,
        results: false
      });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessagem
        })
      );
    });
};

export const getListOfClaims = (params) => {
  const { patientGuid, page = 1, pageSize = 20, sortby = claimsDefaultSortBy.sortOrder, query = claimsDefaultFilters } = params;

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  const queryParams = new URLSearchParams();
  patientGuid && queryParams.set('pid', patientGuid);
  sortby && queryParams.set('sortby', sortby);
  query && queryParams.set('q', JSON.stringify(query));

  const url = new URL(`${getApiBase()}/claims/${DCNGuid}/${page}/${pageSize}/`);
  url.search = queryParams.toString();

  return axios
    .get(url.toString())
    .then((results) => ({
      ...results.data,
      query: JSON.parse(results.data.query),
      first: calculateFirst({
        page: results.data.page,
        pageSize: results.data.pageSize
      }),
      claimList: formatClaimList(results.data.claimList)
    }))
    .catch((reason) => {
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getClaimReport = ({ query, sortby }) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const queryParams = new URLSearchParams({ sortby });
  if (query) queryParams.append('q', JSON.stringify(query));
  const url = `${getApiBase()}/claims/${DCNGuid}/report/?pid=null&${queryParams.toString()}`;

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!getClaimReport', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// export const getListOfClaims = (params) => {
//   const { patientGuid, page = 1, pageSize = 20, sortby = claimsDefaultSortBy.sortOrder, filters = claimsDefaultFilters } = params;
//   const currentState = store.getState();
//   setAuthHeader(currentState);
//   const DCNGuid = currentState.user.details.DCNGuid;
//   const query = `pid=${patientGuid}&sortby=${sortby}&q=${filters}`.replace('q=""', 'q={}');

//   return axios.get(`${getApiBase()}/claims/${DCNGuid}/${page}/${pageSize}/?${query}`)
//     .then((results) => {
//       return ({
//         ...results.data,
//         claimList: formatClaimList(results.data.claimList)
//       });
//     })
//     .catch(reason => {
//       store.dispatch(setToastMessage({ type: "error", message: reason?.response?.data?.ExceptionMessage }));
//     })
// };

export const getClaimsList =
  ({ requestParams = {}, callback }) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const currentState = state();
    const claims = currentState.claims;
    const users = currentState.clinic.members;
    const preferences = currentState.preferences.currentUserPrefs;
    const DCNGuid = currentState.user.details.DCNGuid;
    const patientGuid = requestParams?.patientGuid;
    const page = requestParams?.page || claims.page || 1;
    const pageSize = requestParams?.pageSize || claims.pageSize || 20;
    const sortby = requestParams?.sortby || claims.sortby || claimsDefaultSortBy.sortOrder;
    const filters = JSON.stringify(requestParams?.filters || claims.filters || claimsFiltersWithPeriod);
    const query = `pid=${patientGuid}&sortby=${sortby}&q=${filters}`.replace('q=""', 'q={}');

    dispatch(isFetchingClaims(true));

    axios
      .get(`${getApiBase()}/claims/${DCNGuid}/${page}/${pageSize}/?${query}`)
      .then((results) => {
        dispatch(isFetchingClaims(false));
        dispatch(formatList(actions.FETCH_CLAIMS_SUCCESS, results, preferences, users));
        callback && callback(formatted(results.data.claimList, preferences, users));
      })
      .catch((reason) => {
        dispatch(isFetchingClaims(false));
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const getClaimList = (req4ac, setClaimRecord, dir, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const current = currentState.claims;
  const isFetching = current.isFetchingClaims;

  // VER-353 - Money -> never ending loading
  if (isFetching) return;

  const users = currentState.clinic.members;
  const preferences = currentState.preferences.currentUserPrefs;
  const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : req4ac.dcnguid;
  const dataPage = current.totalRecords > req4ac.pageSize;
  const query = (req4ac.query ? req4ac.query : '').replace('q=""', 'q={}');
  const showOnDetailsView = req4ac.showOnDetailsView || false;
  const q = JSON.parse(req4ac.filter);

  const baseUrl = getApiBase();
  const url = new URL(`api/claims/${DCNGuid}/${req4ac.page}/${req4ac.pageSize}/`, baseUrl);
  url.search = query;

  // Add optional parameters if available
  if (req4ac.action) {
    url.searchParams.append('action', req4ac.action);
  }
  if (req4ac.invoiceGuid) {
    url.searchParams.append('InvoiceGuid', req4ac.invoiceGuid);
  }

  if (current.page === req4ac.page && current.pageSize === req4ac.pageSize && current.sortby === req4ac.sortby && isEqual(q, current.filters)) {
    return;
  } else {
    dispatch(isFetchingClaims(true));
    axios
      .get(url)
      .then((results) => {
        if (req4ac.prefixItems && req4ac.prefixItems.length > 0 && dataPage === 1) {
          results.data.claimList = [...req4ac.prefixItems, ...results.data.claimList];
        }

        if (showOnDetailsView) {
          if (results?.data?.claimList?.length) {
            const nextItem =
              results?.data?.claimList?.length === current.claim_index
                ? results.data.claimList[results.data.claimList.length - 1]
                : results.data.claimList.find((i, index) => index === current.claim_index);
            const formattedNextItem = formatted([nextItem], preferences, users)[0];
            dispatch(setClaimInfo(formattedNextItem));
          } else {
            dispatch(setClaimInfo({}));
          }
        }

        //paginator for ViewPanel
        if (setClaimRecord && (dir === 'nextPage' || dir === 'firstPage')) {
          dispatch(setClaimInfo(formatted(results.data.claimList, preferences, users)[0]));
        }
        if (setClaimRecord && (dir === 'prevPage' || dir === 'lastPage')) {
          const claim = [results.data.claimList[results.data.claimList.length - 1]];
          dispatch(setClaimInfo(formatted(claim, preferences, users)[0]));
        }
        //==================//

        dispatch(isFetchingClaims(false));
        callback && callback(formatted(results.data.claimList, preferences, users));
        return dispatch(formatList(actions.FETCH_CLAIMS_SUCCESS, results, preferences, users));
      })
      .catch((reason) => {
        dispatch(isFetchingClaims(false));
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  }
};

export const getClaims4Scroll = (req4ac, setClaimRecord, dir) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const current = currentState.claims;
  const users = currentState.clinic.members;
  const preferences = currentState.preferences.currentUserPrefs;
  const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : req4ac.dcnguid;
  const page = req4ac.page ? req4ac.page : '1';
  const query = req4ac.query ? req4ac.query : '';
  const pageSize = req4ac.pageSize ? req4ac.pageSize : defaultPageSize;
  const showOnDetailsView = req4ac.showOnDetailsView || false;

  const url = `${getApiBase()}/claims/${DCNGuid}/${page}/${pageSize}/?${query}`;

  dispatch(isFetchingClaims(true));
  axios
    .get(url)
    .then((results) => {
      if (showOnDetailsView) {
        if (results?.data?.claimList?.length) {
          const nextItem =
            results?.data?.claimList?.length === current.claim_index
              ? results.data.claimList[results.data.claimList.length - 1]
              : results.data.claimList.find((i, index) => index === current.claim_index);
          const formattedNextItem = formatted([nextItem], preferences, users)[0];
          dispatch(setClaimInfo(formattedNextItem));
        } else {
          dispatch(setClaimInfo({}));
        }
      }

      //paginator for ViewPanel
      if (setClaimRecord && dir === 'nextPage') {
        const firstClaim = formatted(results.data.claimList, preferences, users)[0];
        dispatch(setClaimInfo(firstClaim));
      }
      if (setClaimRecord && dir === 'firstPage') {
        const firstClaim = formatted(results.data.claimList, preferences, users)[0];
        dispatch(resetCompleteList());
        dispatch(setClaimInfo(firstClaim));
      }
      if (setClaimRecord && dir === 'prevPage') {
        const claim = [results.data.claimList[results.data.claimList.length - 1]];
        const lastClaim = formatted(claim, preferences, users)[0];
        dispatch(setClaimInfo(lastClaim));
      }
      if (setClaimRecord && dir === 'lastPage') {
        const claim = [results.data.claimList[results.data.claimList.length - 1]];
        const lastClaim = formatted(claim, preferences, users)[0];
        dispatch(resetCompleteList());
        dispatch(setClaimInfo(lastClaim));
      }
      //==================//

      dispatch(isFetchingClaims(false));
      return dispatch(formatList(actions.FETCH_CLAIMS_LIST_SUCCESS, results, preferences, users));
    })
    .catch((reason) => {
      dispatch(isFetchingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const setDebitRequest = (params, PatientGuid, InvoiceGuid, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;
  const isMobileOnly = currentState.core.window.isMobileOnly;
  const showDataScroller = currentState.core.showDataScroller;
  const claims = currentState.claims;
  const sortby = claims.sortby;
  const pageSize = claims.pageSize;
  const page = claims.page;
  const filters = claims.filters;
  const query = `pid=${PatientGuid}&sortby=${sortby}&q=${JSON.stringify(filters)}`;
  const dataTableParams = {
    page: page,
    pageSize: pageSize,
    filter: JSON.stringify(filters),
    query
  };

  dispatch({ type: actions.IS_FETCHING_DEBID_REQUEST, results: true });
  axios
    .post(`${getApiBase()}/claims/${DCNGuid}/${InvoiceGuid}/revoke`, params)
    .then(() => {
      dispatch({ type: actions.IS_FETCHING_DEBID_REQUEST, results: false });
      dispatch(
        setToastMessage({
          type: 'success',
          message: t('Debit_request_success')
        })
      );

      //[KS] CMO-1053 - Update invoices data table if Debit Request is success
      isMobileOnly && showDataScroller ? dispatch(getClaims4Scroll(dataTableParams)) : dispatch(getClaimList(dataTableParams));

      callback && callback();
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_DEBID_REQUEST, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const resetCompleteList = () => {
  return {
    type: actions.SET_COMPLETE_LIST,
    results: []
  };
};

export const setClaimInfo = (claim) => {
  //set claim to highlight in in DataTable/DataScroller
  return {
    type: actions.FETCH_CLAIM_DETAILS_SUCCESS,
    results: claim
  };
};

export const getCounters = (DCNGuid) => (dispatch, state) => {
  setAuthHeader(state);
  const url = `${getApiBase()}/claims/${DCNGuid}/counters`;
  dispatch({ type: actions.IS_FETCHING_COUNTERS, results: true });

  axios
    .get(url)
    .then((results) => {
      dispatch({ type: actions.IS_FETCHING_COUNTERS, results: false });
      return dispatch({
        type: actions.FETCH_CLAIMS_COUNTERS_SUCCESS,
        results: formatCounters(results?.data)
      });
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_FETCHING_COUNTERS, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const claimFieldChange = (claim_record, field_id, field_value) => (dispatch, state) => {
  return dispatch({
    type: actions.CLAIM_FIELD_UPDATED,
    results: { claim_record, [field_id]: field_value }
  });
};

export const claimValidationResult = (claim_record, isValid) => (dispatch, state) => {
  return dispatch({
    type: isValid ? actions.CLAIM_VALIDATION_SUCCESS : actions.CLAIM_VALIDATION_FAILURE,
    results: { claim_record }
  });
};

export const submitAllClaims = (action, callback) => (dispatch, state) => {
  //Use action=preview  to get JSON with summary of what will be submitted.
  //Use action=submit to actually submit all those claims and it returns back summary of submitted claims (same json structure)
  //Use action=submit_valid to submit all claims with valid PHNs
  //Use action=smartsubmit - move to draft and submit the rest

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.IS_SUBMITTING_ALL_CLAIMS, results: true });

  axios
    .post(`${getApiBase()}/claims/${DCNGuid}/submit?action=${action}`)
    .then((results) => {
      dispatch({ type: actions.IS_SUBMITTING_ALL_CLAIMS, results: false });
      callback && callback(results.data);
      dispatch(getCounters(DCNGuid)); // refresh counters list
    })
    .catch((reason) => {
      dispatch({ type: actions.IS_SUBMITTING_ALL_CLAIMS, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const createWSBCReport = (report, callback) => (dispatch, state) => {
  setAuthHeader(state);
  dispatch(isSavingClaims(true));

  if (report.InvoiceGuid === '') {
    report.InvoiceGuid = report.RowGuid;
  }
  axios
    .post(`${getApiBase()}/wcbreports/${report.DCNGuid}`, report)
    .then((results) => {
      dispatch(isSavingClaims(false));

      // [KS] CMO-1322 - Dynamic response for submitting invoices
      if (results.data?.Issues) {
        // if error
        callback &&
          callback({
            ...results.data,
            error: true
          });
      } else {
        callback && callback(formatWSBCRInitialValues(results.data));
      }
    })
    .catch((reason) => {
      dispatch(isSavingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const saveWSBCReport = (report, callback) => (dispatch, state) => {
  setAuthHeader(state);
  dispatch(isSavingClaims(true));
  delete report.MSPCodes;

  axios
    .put(`${getApiBase()}/wcbreports/${report.DCNGuid}/${report.InvoiceGuid}`, report)
    .then((results) => {
      dispatch(isSavingClaims(false));

      // [KS] CMO-1322 - Dynamic response for submitting invoices
      if (results.data?.Issues) {
        // if error
        callback &&
          callback({
            ...results.data,
            error: true
          });
      } else {
        callback && callback(formatWSBCRInitialValues(results.data));
      }
    })
    .catch((reason) => {
      dispatch(isSavingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage,
          lifeTime: 99999999
        })
      );
    });
};

export const getWSBCReport = (reportId, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.GETTING_WSBC_REPORT, results: true });

  axios
    .get(`${getApiBase()}/wcbreports/${DCNGuid}/${reportId}`)
    .then((results) => {
      dispatch({ type: actions.GETTING_WSBC_REPORT, results: false });

      const formattedResponse = {
        ...results.data,
        MSPCodes: formatMSPCodes(results.data?.MSPCodes)
      };
      callback && callback(formatWSBCRInitialValues(formattedResponse));
    })
    .catch((reason) => {
      dispatch({ type: actions.GETTING_WSBC_REPORT, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const prepareRecords = (claimRecord) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/claims/${DCNGuid}/prepare`, qs.stringify(claimRecord))
    .then((results) => {
      return results.data.claimList;
    })
    .catch((reason) => {
      console.log('!!!prepareBatchRecords', reason);
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getBatchRecords = (claimRecord, callback) => (dispatch, state) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.getBatchRecordsResponseActionForTests(claimRecord, callback);
  }

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch(fetchingGeneratedInvoices(true));
  axios
    .post(`${getApiBase()}/claims/${DCNGuid}/prepare`, qs.stringify(claimRecord))
    .then((results) => {
      dispatch(fetchingGeneratedInvoices(false));
      responseActions.getBatchRecordsResponseAction(results.data.claimList, callback);
    })
    .catch((reason) => {
      dispatch(fetchingGeneratedInvoices(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
      callback && callback(null);
    });
};

export const createGroupInvoices =
  (invoices = [], callback) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const DCNGuid = state()?.user?.details.DCNGuid;
    dispatch(isSavingClaims(true));

    axios
      .post(`${getApiBase()}/claims/${DCNGuid}/group`, invoices)
      .then((results) => {
        dispatch(isSavingClaims(false));
        callback && callback(results.data.claimList);
      })
      .catch((reason) => {
        dispatch(isSavingClaims(false));
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const createGroupInvoicesTemplate = (data = {}) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .post(`${getApiBase()}/groups/${DCNGuid}/${data.DoctorGuid}`, data)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!createGroupInvoicesTemplate', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const editGroupInvoicesTemplate = (data = {}) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .put(`${getApiBase()}/groups/${DCNGuid}/${data.DoctorGuid}/${data.TemplateGuid}`, data)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!editGroupInvoicesTemplate', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getGroupInvoicesTemplates = (practitionerGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .get(`${getApiBase()}/groups/${DCNGuid}/${practitionerGuid}`)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!getGroupInvoicesTemplates', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const deleteGroupInvoicesTemplate = (templateGuid, doctorGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .delete(`${getApiBase()}/groups/${DCNGuid}/${doctorGuid}/${templateGuid}`)
    .then(() => {
      return true;
    })
    .catch((reason) => {
      console.log('!!!deleteGroupInvoicesTemplate', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getEClaimProgress = (invoiceGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .get(`${getApiBase()}/eclaims/${DCNGuid}/${invoiceGuid}/progress`)
    .then((results) => results.data)
    .catch((reason) => {
      store.dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
    });
};

export const getEClaimResult = (invoiceGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const url = `${getApiBase()}/eclaims/${DCNGuid}/${invoiceGuid}/result`;
  // const url = `${getApiBase()}/eclaims/11111111-8925-2014-1111-091120141111/EE0C976D-E344-4872-9635-8D756BFB5396/result`;

  return axios
    .get(url)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log(reason);
    });
};

export const getEClaimInfo = (invoiceGuid) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .get(`${getApiBase()}/eclaims/${DCNGuid}/${invoiceGuid}`)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log(reason);
    });
};

export const createEClaim = (data) => {
  //sendNow - onSubmit = true, onSave = false

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .post(`${getApiBase()}/eclaims/${DCNGuid}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      return undefined;
    });
};

export const editEClaim = (data) => {
  //sendNow - onSubmit = true, onSave = false

  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .put(`${getApiBase()}/eclaims/${DCNGuid}/${data.ClaimGuid}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const revokeEClaim = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const dataForSubmit = { reason: data.reason };

  return axios
    .post(`${getApiBase()}/eclaims/${DCNGuid}/${data.claimGuid}/revoke`, dataForSubmit)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      console.log('!!!revokeEClaim', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const duplicateEClaim = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const dataForSubmit = { ClaimGuid: data.ClaimGuid };

  return axios
    .post(`${getApiBase()}/eclaims/${DCNGuid}/${data.ClaimGuid}/clone`, dataForSubmit)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      console.log('!!!duplicateEClaim', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const archiveEClaim = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;
  const dataForSubmit = { ClaimGuid: data.ClaimGuid };

  return axios
    .put(`${getApiBase()}/eclaims/${DCNGuid}/${data.ClaimGuid}/archive`, dataForSubmit)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      console.log('!!!archiveEClaim', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const saveBatchRecord = (claimRecord, batchRecords, callback) => (dispatch, state) => {
  //sendNow - onSubmit = true, onSave = false

  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.saveBatchRecordResponseActionForTests(claimRecord, callback);
  }

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details.DCNGuid;
  const batchRows = batchRecords?.map((i) => ({
    ...i,
    service_start: i.service_start ? toMilitaryTime(i.service_start) : -1,
    service_finish: i.service_finish ? toMilitaryTime(i.service_finish) : -1
    // fee: i.billed / i.units,  // [VB] no need anymore
  }));

  const body = qs.stringify({
    claim: claimRecord,
    batchRows,
    sendNow: claimRecord[teleplantInputs.sendNow.name]
  });

  dispatch(isSavingClaims(true));

  axios
    .post(`${getApiBase()}/claims/${DCNGuid}/batch`, body)
    .then((results) => {
      dispatch(isSavingClaims(false));
      responseActions.saveBatchRecordResponseAction(results.data, callback);

      //set claim info to highlight it in the DataTable/DataScroller
      dispatch(setClaimInfo(results.data.claimList[0]));
    })
    .catch((reason) => {
      dispatch(isSavingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const saveClaimRecord = (claimRecord, callback) => (dispatch, state) => {
  //sendNow - onSubmit = true, onSave = false

  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.saveClaimRecordResponseActionForTests(claimRecord.InvoiceGuid, callback);
  }

  delete claimRecord.CurrentUserPrefs;
  delete claimRecord.FeeCodeText;
  delete claimRecord.ICD9Text;
  delete claimRecord.AOIText;
  delete claimRecord.NOIText;
  delete claimRecord[commonInputs.patients.descriptionName];

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details ? currentState.user.details.DCNGuid : claimRecord.DCNGuid;

  if (Array.isArray(claimRecord.FeeCode)) {
    claimRecord.FeeCode = claimRecord.FeeCode.join(',');
  }
  if (Array.isArray(claimRecord.ICD9Code)) {
    claimRecord.ICD9Code = claimRecord.ICD9Code.join(',');
  }
  claimRecord.RecordType = claimRecord.InvoiceType.substring(0, 1);
  dispatch(isSavingClaims(true));

  axios
    .put(`${getApiBase()}/claims/${DCNGuid}/${claimRecord.InvoiceGuid}`, qs.stringify(claimRecord))
    .then((results) => {
      dispatch(isSavingClaims(false));
      return responseActions.saveClaimRecordResponseAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch(isSavingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// Create and save private invoice
export const createPrivateInvoice = (privateRecord) => {
  const state = store.getState();
  setAuthHeader(state);
  const DCNGuid = state?.user?.details?.DCNGuid;

  return axios
    .post(`${getApiBase()}/claims/${DCNGuid}/private`, privateRecord)
    .then((results) => {
      return results.data;
    })
    .catch((reason) => {
      console.log('!!!!createPrivateInvoice', reason);
      store.dispatch(setToastMessage({ type: 'error', message: t('Something_went_wrong') }));
    });
};

// Create and save private invoice
export const createPrivateRecord = (privateRecord, requestType, callback) => (dispatch, state) => {
  // requestType options: "create" and "save"

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.CREATING_PRIVATE_RECORD, results: true });
  const url = `${getApiBase()}/claims/${DCNGuid}/private`;
  const request = () => {
    if (requestType === 'create') return axios.post(url, privateRecord); // create invoice
    if (requestType === 'save') return axios.put(url, privateRecord); // save invoice
  };

  request()
    .then((results) => {
      dispatch({ type: actions.CREATING_PRIVATE_RECORD, results: false });
      callback && callback(results.data);
    })
    .catch((reason) => {
      dispatch({ type: actions.CREATING_PRIVATE_RECORD, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getInsurancePolicyInfo = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${data.patientGuid}/extended/${data.policyGuid}`)
    .then((response) => {
      return response.data;
    })
    .catch((reason) => {
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getPatientInsurancePolicies = (patientGuid, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  dispatch({ type: actions.GETTING_INSURANCE_POLISY, results: true });

  axios
    .get(`${getApiBase()}/patients/${DCNGuid}/${patientGuid}/extended`)
    .then((response) => {
      dispatch({ type: actions.GETTING_INSURANCE_POLISY, results: false });
      callback && callback(response.data);
    })
    .catch((reason) => {
      dispatch({ type: actions.GETTING_INSURANCE_POLISY, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const createInsurancePolicy = (data, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  axios
    .post(`${getApiBase()}/patients/${DCNGuid}/${data.PatientGuid}/extended`, data)
    .then((response) => {
      callback && callback(response.data);
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const editInsurancePolicy = (data, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;

  axios
    .put(`${getApiBase()}/patients/${DCNGuid}/${data.PatientGuid}/extended/${data.RowGuid}`, data)
    .then(() => {
      callback && callback();
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const deleteInsurancePolicy = (data) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState.user.details.DCNGuid;

  return axios
    .delete(`${getApiBase()}/patients/${DCNGuid}/${data.PatientGuid}/extended/${data.RowGuid}`)
    .then(() => true)
    .catch((error) => console.log('Delete Insurance Policy', error));
};

// Receive Payment
export const saveReceivePayment = (data, isNew, callback) => (dispatch, state) => {
  // isNew - create payment
  // !isNew - edit payment
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.SAVING_RECEIVE_PAYMENT, results: true });

  const baseUrl = `${getApiBase()}/payments/${DCNGuid}`;
  const request = isNew ? axios.post(baseUrl, data) : axios.put(`${baseUrl}/${data.PaymentGuid}`, data);

  request
    .then(() => {
      dispatch({ type: actions.SAVING_RECEIVE_PAYMENT, results: false });
      callback && callback();
    })
    .catch((reason) => {
      dispatch({ type: actions.SAVING_RECEIVE_PAYMENT, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

// Delete Payment
export const deletePayment = (id, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  dispatch({ type: actions.DELETING_PAYMENT, results: true });

  axios
    .delete(`${getApiBase()}/payments/${DCNGuid}/${id}`)
    .then(() => {
      dispatch({ type: actions.DELETING_PAYMENT, results: false });
      callback && callback();
    })
    .catch((reason) => {
      dispatch({ type: actions.DELETING_PAYMENT, results: false });
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const updateClaimList = (claimList) => (dispatch) => {
  dispatch({
    type: actions.SET_CLAIM_LIST,
    results: claimList
  });
};

export const setSelectedCodes = (value, fieldId) => (dispatch, state) => {
  const selectedCodes = state().claims.selectedCodes;
  const combined =
    value.length > 0
      ? {
          ...selectedCodes,
          [fieldId]: value
        }
      : value.length === 0 && fieldId
      ? {
          ...selectedCodes,
          [fieldId]: []
        }
      : {};

  dispatch({
    type: actions.CLAIMS_SELECTED_CODES,
    results: combined
  });
};

export const getPeriodFromDateInput = (value) => {
  const reply = {
    Period: '7',
    PeriodFrom: '',
    PeriodTo: '',
    ServiceDate: 'derived'
  };
  if (value.length > 3) {
    const year = value.substring(0, 4);
    switch (value.length) {
      case 4: // year
        reply.PeriodFrom = `${year}/01/01`;
        reply.PeriodTo = `${year}/12/31`;
        break;
      case 6: // year/month
        const m6 = value.substring(4, 6);
        const mEnd = moment(new Date(`${year}/${m6}/01`), 'YYYY/MM/DD', true)
          .add(1, 'month')
          .subtract(1, 'day');
        reply.PeriodFrom = `${year}/${m6}/01`;
        reply.PeriodTo = mEnd.format('YYYY/MM/DD');
        break;
      case 8:
        const m8 = value.substring(4, 6);
        const d8 = value.substring(6, 8);
        const fullDate = moment(new Date(`${year}/${m8}/${d8}`), 'YYYY/MM/DD', true);
        reply.PeriodFrom = fullDate.format('YYYY/MM/DD');
        reply.PeriodTo = fullDate.format('YYYY/MM/DD');
        break;

      default:
        break;
    }
  }
  return reply;
};

export const setPrivateRecord = (privateRecord) => (dispatch) => {
  return dispatch({
    type: actions.SET_PRIVATE_RECORD,
    results: privateRecord
  });
};

export const setClaimRecord = (record) => (dispatch) => {
  return dispatch({
    type: actions.SET_CLAIM_RECORD,
    results: record
  });
};

export const updateClaimRecord = (data) => (dispatch, state) => {
  const isDirty = state().core.dirty;
  !isDirty && dispatch(setDirty());

  return dispatch({
    type: actions.UPDATE_CLAIM_RECORD,
    results: data
  });
};

export const updatePrivateRecord =
  (value, cleanForm = false, setIsDirty = true) =>
  (dispatch, state) => {
    if (cleanForm) {
      dispatch(setClean());
    } else {
      const isDirty = state().core.dirty;
      !isDirty && setIsDirty && dispatch(setDirty());
    }

    return dispatch({
      type: actions.UPDATE_PRIVATE_RECORD,
      results: value
    });
  };

export const setPatientsForClaimDetails = (patient, createInitial = false) => {
  const initialPatient = createInitial
    ? [
        {
          FirstName: patient.FirstName,
          LastName: patient.LastName,
          PatientGuid: patient.PatientGuid,
          PHN: patient.PHN,
          Sex: patient.Sex,
          Age: patient.Age,
          BirthDay: formatDate(patient.BirthDay),
          value: patient.PatientGuid,
          label: `${patient.LastName.toUpperCase()}, ${patient.FirstName.toUpperCase()}  (${patient.Age}${patient.Sex})`
        }
      ]
    : patient;

  return {
    type: actions.SET_PATIENTS_FOR_CLAIM_DETAILS,
    results: initialPatient
  };
};

export const shouldResetForm = (reset) => {
  return {
    type: actions.SHOULD_RESET_CLAIMS_FORM,
    results: reset
  };
};

export const fetchingGeneratedInvoices = (fetching) => {
  return {
    type: actions.IS_FETCHING_GENERATED_INVOICES,
    results: fetching
  };
};

export const setCreateClaimPanelCollapsed = (value) => {
  return {
    type: actions.CREATE_CLAIM_PANEL_COLLAPSED,
    results: value
  };
};

export const getPatientClaimData = (patientGuid, callback) => (dispatch, state) => {
  if (process.env.REACT_APP_CYPRESS) {
    return responseActions.getPatientClaimDataResponceActionForTests(patientGuid, callback);
  }

  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const url = `${getApiBase()}/claims/${DCNGuid}/${patientGuid}/claims`;

  dispatch(isFetchingClaims(true));

  axios
    .get(url)
    .then((results) => {
      dispatch(isFetchingClaims(false));
      responseActions.getPatientClaimDataResponceAction(results.data, callback);
    })
    .catch((reason) => {
      dispatch(isFetchingClaims(false));
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const setClaimsData = (data = {}) => {
  return {
    type: actions.SET_CLAIMS_DATA,
    results: data
  };
};

export const resetWSBCClaimData =
  (claimGuid, resetClaimNumber = true) =>
  (dispatch, state) => {
    const type = state().claims.claimRecord.InvoiceType;
    return dispatch({
      type: actions.RESET_WSBC_CLAIM_DATA,
      results: { claimGuid, resetClaimNumber, type }
    });
  };

export const setValuesForWSBC = (data) => {
  return {
    type: actions.SET_VALUES_FOR_WSBC,
    results: data
  };
};

export const setValuesForICBC = (data) => {
  return {
    type: actions.SET_VALUES_FOR_ICBC,
    results: data
  };
};

export const resetICBCClaimData = (claimGuid) => {
  return {
    type: actions.RESET_ICBC_CLAIM_DATA,
    results: claimGuid
  };
};

export const setFilterByUserForInvoicesViewPanel = (params) => {
  return {
    type: actions.FILTER_BY_PATIENT_FOR_CLAIMS,
    results: params
  };
};

export const isFetchingClaims = (params) => {
  return {
    type: actions.IS_FETCHING_CLAIMS,
    results: params
  };
};

export const isSavingClaims = (params) => {
  return {
    type: actions.IS_SAVING_CLAIMS,
    results: params
  };
};

export const isFetchingClaimHistory = (params) => {
  return {
    type: actions.IS_FETCHING_CLAIM_HISTORY,
    results: params
  };
};

export const setClaimList = (type, params) => {
  return {
    type,
    results: params
  };
};

// [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
export const setDataTableExpandedRows = (data) => {
  return {
    type: actions.SET_EXPANDED_ROWS,
    results: data
  };
};

export const clearError = () => (dispatch, state) => {
  dispatch(isFetchingClaims(false));
};

export const setActiveTabIndexForInvoicesCatalog = (index, type) => (dispatch) => {
  return dispatch({
    type: actions.SET_ACTIVE_TAB_INDEX_FOR_INVOICES_CATALOG,
    results: { index, type }
  });
};

export const searchForCodes = async (params) => {
  // interface ISearchForCodesProps {
  //   getCodesFor: "teleplan" | "private" | "eclaim" | "appt" // required param to define which request should be done
  //   practitionerGuid?: string // Need for services "getClinicServices"
  //   catalogType?: "tec" | "pbc" // Need for eClaims "getEClaimsServices"
  //   codeType?: "feecodes" | "icd9" | "aoi" | "noi" | "referral", // Need for teleplan codes "getCodesByType"
  //   type?: "MSP" | "WSBC" | "ICBC" | "S" | "SP" | "D" // Need for teleplan codes (getCodesByType) or for services (getClinicServices)
  //   query?: string, // Search value
  //   speciality?: string
  //   page?: number,
  //   pageSize?: number,
  //   sortby?: string

  //   isGetInitForEdit?: // Need for teleplan codes "getCodesByType". TODO: need remove this prop
  // }

  const isTeleplan = params.getCodesFor === 'teleplan';
  const isPrivate = params.getCodesFor === 'private';
  const isEClaim = params.getCodesFor === 'eclaim';
  const isEvent = params.getCodesFor === 'appt';

  if (isTeleplan) return await getCodesByType(params);
  if (isPrivate) return await getClinicServices(params);
  if (isEvent) return await getClinicServices(params);
  if (isEClaim) return await getEClaimsServices(params);
};

export const getEClaimsServices = ({
  catalogType, // "tec" | "pbc"
  speciality = '0',
  query,
  page = 1,
  pageSize = codePickerPageSize,
  sortby = ''
}) => {
  const currentState = store.getState();
  setAuthHeader(currentState);

  const url = new URL(`${getApiBase()}/lookup/catalogues/${catalogType}_${speciality}/${page}/${pageSize}`);
  if (sortby) url.searchParams.set('sortby', sortby);
  if (query) url.searchParams.set('q', query);

  return axios
    .get(url)
    .then((res) => {
      return getExactMatchesFirst(query, res.data.codeList);
    })
    .catch((reason) => {
      console.log('!!!getEClaimsServices', reason);
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getCodesByType = ({ codeType, query, page = 1, pageSize = codePickerPageSize, type, speciality = '0', isGetInitForEdit = false }) => {
  // Mock data for tests
  if (process.env.REACT_APP_CYPRESS) return responseActions.getCodesByTypeResponceAction(codeType, query, isGetInitForEdit);

  const currentState = store.getState();
  setAuthHeader(currentState);

  const currentType = type ? type.charAt(0).toUpperCase() : '';
  const url = `${getApiBase()}/lookup/${codeType}/${page}/${pageSize}/?q=${query}&spec=${speciality}&type=${currentType}`;

  return axios
    .get(url)
    .then((res) => {
      const data = Array.isArray(res.data) ? res.data : res.data.codeList;
      return getExactMatchesFirst(query, data);
    })
    .catch((error) => {
      console.log('!!!getCodesByType', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getClinicServices = ({ practitionerGuid, query, page = 1, type = 'SP', pageSize = codePickerPageSize }) => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  if (!practitionerGuid || practitionerGuid === NA_DoctorGuid || practitionerGuid === multipleDoctorGuid) practitionerGuid = DCNGuid;

  return axios
    .get(`${getApiBase()}/lookup/services/${DCNGuid}/${practitionerGuid}/${page}/${pageSize}/?q=${query}&type=${type}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log('!!!getClinicServices', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getReferralCodesByLetter = (props) => {
  const { letter, pageNumber, pageSize } = props;
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  const baseUrl = `${getApiBase()}/lookup/referral/${DCNGuid}`;
  const url = new URL(baseUrl);

  if (pageNumber) url.pathname += `/${pageNumber}`;
  if (pageSize) url.pathname += `/${pageSize}`;
  if (letter) url.pathname += `/${letter}`;

  const finalUrl = url.toString();

  return axios
    .get(finalUrl)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      console.log('!!!getReferralCodesByLetter', error);
      store.dispatch(
        setToastMessage({
          type: 'error',
          lifeTime: 10000,
          message: t('Something_went_wrong')
        })
      );
    });
};

export const getCatalogCategories = async (speciality) => {
  if (process.env.REACT_APP_CYPRESS) {
    return Promise.resolve(invoicesCatalogCategories);
  }

  try {
    const state = store.getState();
    setAuthHeader(state);
    const results = await axios.get(`${getApiBase()}/lookup/catalogues/${speciality}`);
    return results.data;
  } catch (error) {
    console.log('!!!getCatalogCategories', error);
  }
};

export const setCatalogCategories = (categories) => {
  responseActions.getAllInvoicesCatalogCategoriesResponseAction(categories);
};

export const getPrivateInvoicesCatalogCategories = (doctorGuid) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState.user.details.DCNGuid;
  const catalogCategories = currentState.claims.catalogCategories;

  if (doctorGuid === NA_DoctorGuid || doctorGuid === multipleDoctorGuid) doctorGuid = DCNGuid;

  // [KS] Update local storage every time when user logs in (check CMO-1276)
  axios
    .get(`${getApiBase()}/lookup/catalogues/services/${DCNGuid}/${doctorGuid}`)
    .then((results) => {
      return dispatch({
        type: actions.GET_ALL_CATALOG_CATEGORIES,
        results: {
          ...catalogCategories,
          privateServices: results.data.services
        }
      });
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getRecentCodesList = (patientGuid = '') => {
  const currentState = store.getState();
  setAuthHeader(currentState);
  const DCNGuid = currentState?.user?.details?.DCNGuid;
  const url = `${getApiBase()}/lookup/catalogues/${DCNGuid}/recent${patientGuid ? `/${patientGuid}` : ''}`;

  return axios
    .get(url)
    .then((results) => ({ ...results.data, patientGuid }))
    .catch((reason) => {
      store.dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const getInvoicesCatalogMostRecentCodes =
  ({ patientGuid = '', callback, loading = true, updateRedux = true, DCNGuid }) =>
  (dispatch, state) => {
    // Mock data for tests
    if (process.env.REACT_APP_CYPRESS) {
      return responseActions.getMostRecentCodesResponseActionForTest(patientGuid, callback, updateRedux);
    }

    setAuthHeader(state);
    const currentState = state();
    const currentDCNGuid = DCNGuid || currentState?.user?.details?.DCNGuid;
    const url = `${getApiBase()}/lookup/catalogues/${currentDCNGuid}/recent${patientGuid ? `/${patientGuid}` : ''}`;

    loading && dispatch(gettingRecentCodes(true));

    axios
      .get(url)
      .then((results) => {
        loading && loading && dispatch(gettingRecentCodes(false));
        responseActions.getMostRecentCodesResponseAction(patientGuid, results.data, callback, updateRedux);
      })
      .catch((reason) => {
        callback && callback();
        loading && loading && dispatch(gettingRecentCodes(false));
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const gettingRecentCodes = (results) => (dispatch) => {
  dispatch({ type: actions.IS_FETCHING_INVICES_MOST_RECENT_CODES, results });
};

export const getFacilityCodes =
  ({ query = '', callback, page = 1, pagesize = 50 }) =>
  (dispatch, state) => {
    setAuthHeader(state);
    const url = `${getApiBase()}/lookup/facility/${page}/${pagesize}?sortby=&q=${query}`;

    axios
      .get(url)
      .then((results) => callback(results.data))
      .catch((reason) => {
        dispatch(
          setToastMessage({
            type: 'error',
            message: reason?.response?.data?.ExceptionMessage
          })
        );
      });
  };

export const sendByEmail = (claimNumber) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  axios
    .get(`${getApiBase()}/claims/${DCNGuid}/email/${claimNumber}`)
    .then(() => {})
    .catch(() => dispatch(setToastMessage({ type: 'error', message: `${t('Email_is_missing')}.` })));
};

export const getEClaimLocations = (practitionerGuid, type, callback) => (dispatch, state) => {
  setAuthHeader(state);
  const currentState = state();
  const DCNGuid = currentState?.user?.details?.DCNGuid;

  axios
    .get(`${getApiBase()}/users/${DCNGuid}/${practitionerGuid}/locations/${type}`)
    .then((response) => {
      callback && callback(response.data);
    })
    .catch((reason) => {
      dispatch(
        setToastMessage({
          type: 'error',
          message: reason?.response?.data?.ExceptionMessage
        })
      );
    });
};

export const updateInvoicesQuickPickCodesList =
  (data, codeType = undefined) =>
  (dispatch) => {
    return dispatch({
      type: actions.UPDATE_INVOICES_CATALOG_MOST_RECENT_CODES,
      results: { data, codeType }
    });
  };

export const setMissingCodesToRecentList =
  ({ codes = {}, recentCodesList }) =>
  (dispatch, state) => {
    // "codes" should have same stucture as "catalogMostRecentCodes".
    // "codes" example structure:
    // {
    //   "feecodes_msp": [],
    //   "icd9": [
    //     {
    //       "value": "01Z",
    //       "label": "01Z: Lenka",
    //       "text": "Lenka",
    //       "amount": null,
    //       "order": 0,
    //       "min_per_unit": null,
    //       "portion": null,
    //       "dateAdded": "0001-01-01T00:00:00"
    //     }
    //   ],
    //   "serviceDate": [
    //     {
    //       "amount": null,
    //       "label": "11/20/2022",
    //       "order": 0,
    //       "value": "2022-11-20",
    //       "weekday": "Wednesday",
    //       "isWeekend": false
    //     }
    //   ]
    // }

    const updatedCodes = mapValues(codes, (value) => {
      // Check if value is not an array
      if (value && !isArray(value)) {
        // If not an array, convert it to an array
        value = castArray(value);
      }
      // Remove undefined and null values from the array
      return compact(value);
    });

    const currentState = state();
    const recentCodes = recentCodesList || currentState.claims.catalogMostRecentCodes;

    // Customizer function to merge arrays and remove duplicates based on the 'value' property
    const customizer = (recentCodesItem, updatedCodesItem) => {
      if (isArray(recentCodesItem)) {
        // Check if an item from updatedCodesItem is not already present in recentCodesItem
        const uniqueUpdatedCodesItem = updatedCodesItem.filter((item) => !recentCodesItem.find((existingItem) => existingItem.value === item.value));
        // Merge arrays and remove duplicates based on the 'value' property
        return unionBy(uniqueUpdatedCodesItem, recentCodesItem, 'value');
      }
    };

    const updatedRecentList = mergeWith({}, recentCodes, updatedCodes, customizer);
    const sortedData = {
      ...updatedRecentList,
      serviceDate: orderBy(updatedRecentList.serviceDate, ['value'], ['desc']) // Sort the serviceDate array in descending order based on the 'value' field
    };

    return dispatch({
      type: actions.UPDATE_INVOICES_CATALOG_MOST_RECENT_CODES,
      results: { data: sortedData }
    });
  };

export const updateClaimsState = (updatedState) => (dispatch) => {
  return dispatch({
    type: actions.UPDATE_CLAIMS_STATE,
    results: updatedState
  });
};

export const setResetClaimForm = (value) => (dispatch) => {
  return dispatch({
    type: actions.RESET_CLAIM_FORM,
    results: value
  });
};

export const setPaymentDetails = (value) => (dispatch) => {
  return dispatch({
    type: actions.SET_PAYMENT_DETAILS,
    results: value
  });
};

export const setPatientPickerQueryValue = (value) => (dispatch) => {
  return dispatch({
    type: actions.SET_PATIENT_PICKER_QUERY_VALUE,
    results: value
  });
};

export const updateWSBCRecord =
  (record = {}) =>
  (dispatch) => {
    return dispatch({
      type: actions.UPDATE_WSBC_RECORD,
      results: record
    });
  };

export const updateTeleplanInvoice =
  (record = {}) =>
  (dispatch) => {
    return dispatch({
      type: actions.UPDATE_TELEPLAN_INVOICE,
      results: record
    });
  };

export const setShowMissingPatientDataDialog = (value) => (dispatch) => {
  return dispatch({
    type: actions.SHOW_MISSING_PATIENT_DATA_DIALOG,
    results: value
  });
};

export const setShowTotalOutstandingDialog = (value) => (dispatch) => {
  return dispatch({
    type: actions.SHOW_TOTAL_OUTSTANDING_DIALOG,
    results: value
  });
};
