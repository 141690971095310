import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../../../service/localization/i18n';

const Header = () => {
  const { details } = useSelector((state) => state.user);
  const speciality = details.Speciality;
  const title = speciality === '0' ? `${t('Physicians_report')} 8/11` : `${t('Chiropractors_report')} 8C/11C`;
  return (
    <div className="flex justify-content-between w-full">
      <span className="font-semibold">{t('WorkSafeBC')}</span>
      <span className="font-semibold">{`${title}`}</span>
    </div>
  );
};

export default Header;
