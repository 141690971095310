import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Message } from 'primereact/message';
import ChangePassword from '../../../../components/Widgets/ChangePassword/ChangePassword';

import { setLoginView } from '../../actions/auth.actions.creators';
import { t } from '../../../../service/localization/i18n';

const PasswordUpdate = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.details);

  const onSuccessSubmit = () => {
    // Redirect to login page after successful password update
    dispatch(setLoginView('login'));
  };

  return (
    <div className="login-body">
      <div className="login-panel grid h-auto">
        <div className="login-panel-header login-panel-header-text">{t('Change_password')}</div>
        <div className="login-panel-content col-12 lg:col-12">
          <Message
            icon="pi pi-exclamation-triangle"
            className="w-full mb-3"
            severity="warn"
            text="It's time to update your password. Please enter your new password below to ensure your account remains secure."
          />
          <ChangePassword
            dcnGuid={user.DCNGuid}
            doctorGuid={user.UserGuid}
            onSuccessSubmit={onSuccessSubmit}
            onCancel={() => dispatch(setLoginView('login'))}
          />
        </div>
        <div style={{ fontSize: 'small', padding: '1em' }}>
          This system is restricted to authorized users. If you're unauthorized, terminate access now.
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdate;
