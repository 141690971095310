import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { GenericPHN } from '../../../../../../../components/Inputs';
import { invoiceTypes } from '../../../helpers/defaultValues';
import { inputs } from '../../../helpers/inputs';

const SubmitWithGenericPHN = () => {
  const { control } = useFormContext();
  const payor = useWatch({ control, name: inputs.payor.name });

  if (payor === invoiceTypes.wsbc) {
    return (
      <div className="pb-2">
        <GenericPHN control={control} />
      </div>
    );
  }

  return null;
};

export default SubmitWithGenericPHN;
