import { t } from '../../service/localization/i18n';

export const provinces_list = [
  { label: t('Alberta'), value: 'AB' },
  { label: t('British_Columbia'), value: 'BC' },
  { label: t('Manitoba'), value: 'MB' },
  { label: t('New_Brunswick'), value: 'NB' },
  { label: t('Newfoundland_and_Labrador'), value: 'NF' },
  { label: t('Northwest_Territories'), value: 'NT' },
  { label: t('Nova_Scotia'), value: 'NS' },
  { label: t('Nunavut'), value: 'NU' },
  { label: t('Ontario'), value: 'ON' },
  { label: t('Prince_Edward_Island'), value: 'PE' },
  { label: t('Quebec'), value: 'QC' },
  { label: t('Saskatchewan'), value: 'SK' },
  { label: t('Yukon'), value: 'YT' }
];

export const provincesListWithEmptyOption = [{ label: t('N_A'), value: '' }, ...provinces_list];

export const genderList = [
  { label: t('Male.2'), value: 'M' },
  { label: t('Female.2'), value: 'F' }
];
