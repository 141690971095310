import React from 'react';
import classes from './DemoDetailsView.module.scss';

// DONT FORGET RENAME ALL VARIABLES, FILES AND PARAMETERS WHICH HAVE a "demo" OR "Demo" prefix

const DemoDetailsBodyLayout = ({ data }) => {
  return <div>Body layout</div>;
};

export default DemoDetailsBodyLayout;
