import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';

const EntitiesTable = ({ entities, allowEdit = false, updateEntityPermission, onEntityChange }) => {
  const handlePermissionChange = (module, permissionType, event) => {
    const currentPermissions = entities.find((entity) => entity.Entity === module).Permissions;

    const newPermissions = {
      ...currentPermissions,
      [permissionType]: permissionType === 'CanSee' ? event.value : event.checked
    };

    if (permissionType === 'CanSee' && event.value) {
      Object.keys(newPermissions).forEach((key) => {
        if (key !== 'CanSee') {
          newPermissions[key] = false;
        }
      });
    }

    onEntityChange?.();

    updateEntityPermission(module, newPermissions);
  };

  return (
    <div className="card mt-3">
      <DataTable value={entities} tableStyle={{ minWidth: '50rem' }} scrollable scrollHeight="flex" responsiveLayout="scroll">
        <Column
          field="EntityName"
          header="Entity"
          style={{ width: '12%' }}
          body={(rowData) => {
            let moduleName = rowData.EntityName;

            if (moduleName === 'EClaims') {
              moduleName = 'eClaims';
            } else if (moduleName === 'UserSettings') {
              moduleName = 'User Settings';
            } else if (moduleName === 'ClinicSettings') {
              moduleName = 'Clinic Settings';
            }

            return <span>{moduleName}</span>;
          }}
        />
        <Column
          header="Access Granted"
          style={{ width: '10%' }}
          body={(rowData) => (
            <InputSwitch
              checked={rowData.Permissions.CanSee}
              disabled={!allowEdit}
              onChange={allowEdit ? (e) => handlePermissionChange(rowData.Entity, 'CanSee', e) : null}
            />
          )}
        />
        <Column
          header="Read"
          style={{ width: '10%' }}
          body={(rowData) => (
            <Checkbox
              checked={rowData.Permissions.CanRead}
              onChange={allowEdit ? (e) => handlePermissionChange(rowData.Entity, 'CanRead', e) : null}
              disabled={!rowData.Permissions.CanSee || !allowEdit}
            />
          )}
        />
        <Column
          header="Create"
          style={{ width: '10%' }}
          body={(rowData) => (
            <Checkbox
              checked={rowData.Permissions.CanCreate}
              onChange={allowEdit ? (e) => handlePermissionChange(rowData.Entity, 'CanCreate', e) : null}
              disabled={!rowData.Permissions.CanSee || !allowEdit}
            />
          )}
        />
        <Column
          header="Edit"
          style={{ width: '10%' }}
          body={(rowData) => (
            <Checkbox
              checked={rowData.Permissions.CanEdit}
              onChange={allowEdit ? (e) => handlePermissionChange(rowData.Entity, 'CanEdit', e) : null}
              disabled={!rowData.Permissions.CanSee || !allowEdit}
            />
          )}
        />
        <Column
          header="Delete"
          style={{ width: '10%' }}
          body={(rowData) => (
            <Checkbox
              checked={rowData.Permissions.CanDelete}
              onChange={allowEdit ? (e) => handlePermissionChange(rowData.Entity, 'CanDelete', e) : null}
              disabled={!rowData.Permissions.CanSee || !allowEdit}
            />
          )}
        />
      </DataTable>
    </div>
  );
};

export default EntitiesTable;
