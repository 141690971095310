import { t } from '../../../../../service/localization/i18n';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const inputs = {
  dcnguid: {
    name: 'DCNGuid'
  },

  rowGuid: commonInputs.rowGuid,

  speciality: commonInputs.speciality,

  status: {
    name: 'Status'
  },

  patientGuid: {
    name: 'PatientGuid',
    label: t('Patient.1')
  },

  patientList: {
    name: 'PatientList'
  },

  practitioner: {
    name: commonInputs.practitionerGuid.name,
    label: t('Practitioner')
  },

  location: {
    name: 'Location',
    label: t('Location')
  },

  payee: {
    name: 'Payee',
    label: t('Pay_To')
  },

  accidentType: {
    name: 'AccidentType',
    label: t('Accident_Type')
  },

  accidentDate: {
    name: 'AccidentDate',
    label: t('Accident_Date')
  },

  prescriberType: {
    name: 'PrescriberType',
    label: t('Prescriber_Type')
  },

  lastName: {
    name: 'PrescriberLastName',
    label: t('Prescriber_Last_Name')
  },

  firstName: {
    name: 'PrescriberFirstName',
    label: t('Prescriber_First_Name')
  },

  authorizationForm: {
    name: 'AuthorizationForm',
    label: t('Authorization_Form')
  },

  termsAndConditions: {
    name: 'TermsAndConditions',
    label: t('Terms_and_Conditions')
  },

  patient: {
    name: 'PatientDetails',
    label: t('Patient.1')
  },

  serviceDate: commonInputs.serviceDate,

  primaryPolicy: {
    ...commonInputs.primaryPolicy,
    [commonInputs.isPrimary.name]: true
  },

  secondaryPolicy: {
    ...commonInputs.secondaryPolicy,
    [commonInputs.isPrimary.name]: false
  },

  services: {
    name: 'Services',
    label: t('Services')
  },

  service: {
    name: 'service',
    label: t('Service')
  },

  duration: {
    name: 'duration',
    label: t('Duration')
  },

  amount: {
    name: 'amount',
    label: t('Fee')
  },

  lessMSP: {
    name: 'lessMSP',
    label: t('Less_MSP')
  },

  claimGuid: commonInputs.claimGuid,
  claimNumber: commonInputs.claimNumber,
  sendNow: commonInputs.sendNow,
  fantom: commonInputs.fantom
};
