import MailsLayout from "../../mails/components/MailsLayout";
import MessagesTable from "./MessagesTable";

const Messages = () => {
    return (
        <MailsLayout>
            {window.innerWidth < 768 && <h3 style={{ textAlign: 'center' }}>Messages</h3>}
            <MessagesTable />
        </MailsLayout>);
};

export default Messages;
