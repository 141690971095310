import React from 'react';

const DescriptionLayout = ({ name, description }) => {
  if (!description) return null;

  const descriptions = Array.isArray(description) ? description : [description];

  return (
    <div id={`${name}_description`} className="mb-3">
      <div className="flex flex-column">
        {descriptions.map((i, index) => (
          <span id={`${name}_description_${index}`} key={i} className="pb-1 input_description_styles">
            {i}
          </span>
        ))}
      </div>
    </div>
  );
};

export default DescriptionLayout;
