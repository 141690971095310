import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { localStateInitValues } from '../../helpers/initialState';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { t } from '../../../../../../service/localization/i18n';

const CatalogsDialog = () => {
  const { localState, setLocalState } = useFormContext();

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, catalogsDialog: localStateInitValues.catalogsDialog }));
  };

  return (
    <Dialog
      id={elementIDs.catalogsDialog}
      header={t('Catalogs')}
      visible={localState.catalogsDialog.showDialog}
      breakpoints={{ '1024px': '100vw' }}
      style={{ width: '80vw' }}
      onHide={onHideDialog}
    >
      Coming soon
    </Dialog>
  );
};

export default CatalogsDialog;
