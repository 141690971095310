import React, { useEffect } from 'react';

import { Column } from 'primereact/column';
import TimeLayout from './layouts/TimeLayout';
import { DataTable } from 'primereact/datatable';
import TableHeader from './layouts/TableHeader';
import PayerLayout from './layouts/PayerLayout';
import StatusLayout from './layouts/StatusLayout';
import DxCodeLayout from './layouts/DxCodeLayout';
import FeeCodeFilter from './layouts/FeeCodeFilter';
import FeeCodeLayout from './layouts/FeeCodeLayout';
import PaginatorLeft from './layouts/PaginatorLeft';
import PaginatorRight from './layouts/PaginatorRight';
import ServiceDateFilter from './layouts/ServiceDateFilter';
import ServiceDateLayout from './layouts/ServiceDateLayout';
import ReferralCodeLayout from './layouts/ReferralCodeLayout';
import SequensNumberLayout from './layouts/SequensNumberLayout';
import CircularProgress from '../../../../Misc/Loader/CircularProgress/CircularProgress';

import { useTable } from '../../hooks/useTable';
import { defaultRequestParams } from '../../helpers/defaultValues';
import { calculateLast } from '../../../../../modules/utils/calculate';
import { usePatientBillingHistoryContext } from '../../hooks/usePatientBillingHistoryContext';
import { t } from '../../../../../service/localization/i18n';

const PatientBillingHistoryTable = () => {
  const { state } = usePatientBillingHistoryContext();
  const { onSelectionChange, fetchData, onSort, onPage, tableStyle } = useTable();
  const sortField = state.sortby.split('_')[0];
  const sortOrder = state.sortby.split('_')[1] === 'asc' ? 1 : -1;
  const last = calculateLast({ page: state.page, pageSize: state.pageSize, totalRecords: state.totalRecords });

  // Get init data
  useEffect(() => {
    const patientGuid = state.filters?.patient?.PatientGuid;
    patientGuid && fetchData({ patientGuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {state.loading && <CircularProgress />}
      <DataTable
        dataKey="InvoiceGuid"
        value={state.claimList}
        emptyMessage={state.emptyMessage || t('No_records_found')}
        selectionMode="single"
        selection={state.selectedItem}
        onSelectionChange={onSelectionChange}
        header={<TableHeader />}
        lazy
        // filterDisplay="row"
        // stripedRows
        removableSort
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={onSort}
        paginator
        pageLinkSize={3}
        first={state.first}
        totalRecords={state.totalRecords}
        rows={defaultRequestParams.pageSize}
        paginatorLeft={<PaginatorLeft />}
        paginatorRight={<PaginatorRight />}
        currentPageReportTemplate={`${state.first} to ${last} of ${state.totalRecords}`}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        onPage={onPage}
        scrollable
        scrollHeight="flex"
        tableStyle={tableStyle}
        style={{ height: `auto`, maxHeight: 'calc(100vh - 180px)' }}
      >
        <Column
          sortable
          // filter
          // showFilterMenu={false}
          // filterHeaderClassName="px-0"
          // filterElement={<ServiceDateFilter />}
          field="ServiceDate"
          header={t('Date_of_service')}
          style={{ minWidth: '180px' }}
          body={(rowData) => <ServiceDateLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="InvoiceType"
          header={t('Payer')}
          style={{ minWidth: '150px' }}
          body={(rowData) => <PayerLayout rowData={rowData} />}
        />

        <Column
          sortable
          // filter
          // showFilterMenu={false}
          // filterHeaderClassName="px-0"
          // filterElement={<FeeCodeFilter />}
          field="FeeCode"
          header={t('Fee_item')}
          style={{ minWidth: '130px' }}
          body={(rowData) => <FeeCodeLayout rowData={rowData} />}
        />

        <Column sortable field="Status" header={t('Status')} style={{ minWidth: '150px' }} body={(rowData) => <StatusLayout rowData={rowData} />} />

        <Column
          sortable
          field="InvoiceType"
          header={t('Payer')}
          style={{ minWidth: '130px' }}
          body={(rowData) => <PayerLayout rowData={rowData} />}
        />

        <Column field="Units" header={t('Units')} style={{ minWidth: '80px' }} />

        <Column
          sortable
          field="ICD9Code"
          header={t('Dx')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <DxCodeLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RefNumber"
          header={t('Referral')}
          style={{ minWidth: '100px', maxWidth: '150px' }}
          body={(rowData) => <ReferralCodeLayout rowData={rowData} />}
        />

        <Column
          field="Time"
          header={t('Time')}
          style={{ minWidth: '100px', maxWidth: '180px' }}
          body={(rowData) => <TimeLayout rowData={rowData} />}
        />

        <Column
          sortable
          field="RecordNo"
          header={t('Record Id')}
          style={{ minWidth: '120px' }}
          body={(rowData) => <SequensNumberLayout rowData={rowData} />}
        />
      </DataTable>
    </>
  );
};

export default PatientBillingHistoryTable;
