import React from 'react';

import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';

import { t } from '../../service/localization/i18n';
import classes from './Table.module.scss';
import cx from 'classnames';

// Filters data example
// const testFilters = [
//   { field: 'FirstName', label: 'First Name', value: 'Harry' },
//   { field: 'Phone', label: 'Phone', value: '(604) 211-0123' },
//   { field: 'Number', label: 'Number', value: '57392' },
// ];

const Chips = (props) => {
  return (
    <div className="flex align-items-center">
      {props.filters?.length > 1 && (
        <Button className="p-button-text mr-3" label={t('Clear_all')} style={{ minWidth: '80px' }} onClick={props.clearAllFilters} />
      )}

      <div
        className={cx('flex align-items-center chipsHorizontalScroll', classes.chipsScroller)}
        style={{ width: `${props.rootWidth - (props.defaultModeContentWidth + 160)}px` }}
      >
        {props.filters?.map((i) => {
          if (i === undefined) return null;

          const isValueBoolean = typeof i.value === 'boolean';
          const label = isValueBoolean ? i.label : `${i.label}: ${i.value}`;
          return <Chip key={i.label?.replaceAll(' ', '')} label={label} className="mr-2" removable onRemove={() => props.onChipRemove(i)} />;
        })}
      </div>
    </div>
  );
};

export default Chips;
