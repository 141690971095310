import React from 'react';
import { InputText } from 'primereact/inputtext';
import cx from 'classnames';

// interface IGender {
//   value: string,
//   onChange: () => void,
//   placeholder?: string,
//   name?: string
//   onBlur?: () => void,
//   required?: boolean,
//   errorMessage?: string,
//   ref?: any
// };

const NameInput = React.forwardRef((props, ref) => {
  const name = props.input.name;

  const handleChange = (value) => {
    props.onChange && props.onChange(value?.toUpperCase());
  };

  const handleBlur = (value) => {
    props.onBlur && props.onBlur(value?.toUpperCase());
  };

  return (
    <InputText
      ref={ref}
      className={cx({ 'p-invalid': props.errorMessage && props.required })}
      style={{ width: 'inherit' }}
      id={name}
      type="text"
      value={props.value}
      placeholder={props.placeholder || ''}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={(e) => handleBlur(e.target.value)}
    />
  );
});

export default NameInput;
