import { useFormContext } from 'react-hook-form';
import { useCodePickerActions } from './useCodePickerActions';
import { chain, isEmpty } from 'lodash';
import { useCatalogTabs } from './useCatalogTabs';
import { commonInputs } from '../../../../config/commonInputsConfig';

export const useCatalogs = () => {
  const { watch, localState } = useFormContext();
  const { catalogTabs } = useCatalogTabs();
  const input = isEmpty(localState?.focusFieldParams)
    ? catalogTabs?.find((i) => i.name === commonInputs.mostRecent.name)
    : localState?.focusFieldParams;
  const { onCodeSelect } = useCodePickerActions(input);

  const getSelectedCodes = (activeTab) => {
    // if (!activeTab?.codeType) return [];
    // return watch(activeTab?.codeType)?.toReversed() || [];

    if (!activeTab?.name) return [];
    const code = watch(activeTab?.name);
    const codeDescription = watch(activeTab?.codeDescription);
    return code && codeDescription ? [{ value: code, label: codeDescription }] : [];
  };

  const catalogsTableValues = (catalogsState) => {
    if (catalogsState.trail[0]?.label === 'All') {
      const grouped = chain(catalogsState.tableData?.codeList)
        .groupBy('category')
        .map((i) => [{ value: i[0].category_label, isCategory: true }, ...i])
        .value()
        .flat(1);

      return grouped;
    }

    return catalogsState.tableData?.codeList;
  };

  const onSelectCodeFromCatalogsTable = (code) => {
    onCodeSelect(code);
  };

  return {
    getSelectedCodes,
    catalogsTableValues,
    onSelectCodeFromCatalogsTable
  };
};
