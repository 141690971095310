import React from 'react';
import TodayCard from './TodayCard';
import Last7DaysCard from './Last7DaysCard';
import Last30DaysCard from './Last30DaysCard';
import Last365DaysCard from './Last365DaysCard';

const CashFlowCards = () => {
  return (
    <div className="grid">
      <TodayCard />
      <Last7DaysCard />
      <Last30DaysCard />
      <Last365DaysCard />
    </div>
  );
};

export default CashFlowCards;
