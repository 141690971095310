import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'primereact/button';
import { t } from '../../../../../../service/localization/i18n';

const SomethingWentWrong = () => {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
  };

  return (
    <div>
      <div>Oops, something went wrong</div>
      <Button className="w-9rem mt-5" label={t('Close')} onClick={onClose} />
    </div>
  );
};

export default SomethingWentWrong;
