import React from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from 'primereact/skeleton';
import AddressLayout from '../../../../../common/components/AddressLayout';

import { formatPhoneNumber } from '../../../../../utils/formatPhoneNumber';
import { inputs } from '../../claimDetailsHelpers/inputs';
import { t } from '../../../../../../service/localization/i18n';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { isPhysician } from '../../../../../config/specialitiesConfig';

const PayeeSection = ({ data, isFetching }) => {
  const members = useSelector((state) => state.clinic.members);
  const payTo = data[inputs().payTo.name] ? data[inputs().payTo.name] : {};
  const currentPractitioner = members.find((member) => member.DoctorGuid === data.DoctorGuid);
  const isPhysicianPractitiner = currentPractitioner ? isPhysician(currentPractitioner.Speciality) : false;

  return (
    <>
      <div className="company-name">{t('Payee').toUpperCase()}</div>

      {isFetching ? (
        <>
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </>
      ) : (
        <div id={elementIDs.payeeSection}>
          <div className="flex col-12 p-0 mb-3 font-bold">
            {payTo?.LastName ? `${payTo?.LastName?.toUpperCase()}, ${payTo?.FirstName?.toUpperCase()}` : payTo?.ClinicName}
          </div>

          <div className="addressLayoutWrap">
            <div className="addressWrap mb-0">
              <AddressLayout data={payTo} />

              {isPhysicianPractitiner && (payTo?.Phone || payTo?.Email) && (
                <div>
                  {payTo?.Phone && <div className="mb-1">{formatPhoneNumber(payTo?.Phone)}</div>}
                  <div className="mb-1">{payTo?.Email}</div>
                </div>
              )}
            </div>
          </div>

          {/* GST number */}
          {payTo.GSTNumber && <div>{`${t('GST')} # ${payTo.GSTNumber}`}</div>}
        </div>
      )}
    </>
  );
};

export default PayeeSection;
