import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import CommonConfirmDialog from '../../../../../common/components/CommonConfirmDialog';

import { patientFullNameWithAge } from '../../../../../utils/patientFullName';
import { archiveEClaim } from '../../../../actions/claims.action.creators';
import { localStateInitValues } from '../../helpers/initialState';
import { inputs } from '../../helpers/inputs';
import { t } from '../../../../../../service/localization/i18n';

const ArchiveConfirmationDialog = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { localState, setLocalState } = useFormContext();
  const { showDialog, eClaim } = localState.archiveEClaimDialog;
  const patient = eClaim?.[inputs.patient.name]?.[0];
  const patientName = patientFullNameWithAge(patient);
  const claimNumber = eClaim?.ClaimNumber;

  const onHideDialog = () => {
    setLocalState((prevState) => ({ ...prevState, archiveEClaimDialog: localStateInitValues.archiveEClaimDialog }));
  };

  const onAccept = async () => {
    setLoading(true);
    const result = await archiveEClaim(eClaim);
    setLoading(false);
    if (result) history.goBack();
  };

  return (
    <CommonConfirmDialog
      loading={loading}
      visible={showDialog}
      header={t('Archive_eClaim')}
      bodyText={String.format(t('Are_you_sure_you_want_to_archive_invoice'), claimNumber, patientName)}
      accept={onAccept}
      reject={onHideDialog}
      onHide={onHideDialog}
    />
  );
};

export default ArchiveConfirmationDialog;
