import React from 'react';
import { useFormContext } from 'react-hook-form';

import ClearAllDxButton from './ClearAllDxButton';
import ClearAllTimeButton from './ClearAllTimeButton';
import ClearAllReferralButton from './ClearAllReferralButton';
import ClearAllCommentsButton from './ClearAllCommentsButton';

import { steps } from '../../../../../helpers/steps';

const ClearAllButton = () => {
  const { localState } = useFormContext();

  if (localState.step === steps.groupServicesPicker) return <ClearAllTimeButton />;
  if (localState.step === steps.groupDxPicker) return <ClearAllDxButton />;
  if (localState.step === steps.groupReferralPicker) return <ClearAllReferralButton />;
  if (localState.step === steps.groupComments) return <ClearAllCommentsButton />;

  return null;
};

export default ClearAllButton;
