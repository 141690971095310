import { commonInputs } from '../../config/commonInputsConfig';
import { invoiceTypes } from '../components/TeleplanInvoice/helpers/defaultValues';

export const getCategoryType = (type, invoiceType) => {
  let reply = type;

  if (type === commonInputs.feeCodes.codeType) {
    const isMSP = invoiceType === invoiceTypes.msp;
    const isWSBC = invoiceType === invoiceTypes.wsbc;
    const isICBC = invoiceType === invoiceTypes.icbc;
    const suffix = isMSP ? 'msp' : isWSBC ? 'wsbc' : isICBC ? 'icbc' : '';
    reply = `${type}_${suffix}`;
  }

  if (reply === 'privateServices') reply = 'feecodes_pp';

  return reply;
};
