import { statusOptionsAction } from '../claims/actions/claims.action.creators';

export const statusOptionsFilter = (value, statuses, dispatch) => {
  switch (value) {
    case '0':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '1':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '2':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '3':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '4':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '5':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '6':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '7':
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
    case '8':
      dispatch(statusOptionsAction(statuses.privateStatuses));
      break;
    case '9':
      dispatch(statusOptionsAction(statuses.eclaimStatuses));
      break;
    case '10':
      dispatch(statusOptionsAction(statuses.teleplanStatuses));
      break;
    default:
      dispatch(statusOptionsAction(statuses.allStatuses));
      break;
  }
};
