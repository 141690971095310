/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuLayout from './MenuLayout';
import MenuItemRender from './MenuItemRender';
import GlobalSearch from '../GlobalSearch';

import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const CreateWSBCReport = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user.details);

  const items = useMemo(() => {
    const items = [
      {
        label: <div className="font-bold">{`Create WSBC Report`}</div>,
        items: [
          {
            id: elementIDs.createWSBCReportButton,
            label: t('WSBC_Report'),
            icon: 'pi pi-plus',
            command: () => history.push(`${routes.newWSBCReport.path}`),
            template: (item) => <MenuItemRender item={item} />
          }
        ]
      }
    ];

    items.push({
      template: () => (
        <div className="pt-3 pb-2 px-2">
          <GlobalSearch name="wsbcr" />
        </div>
      )
    });

    return items;
  }, [history]);

  if (!user.canBillWCB) {
    return null;
  }

  return <MenuLayout model={items} />;
};

export default CreateWSBCReport;
