import React, { useContext } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { formatDate } from '../../../utils/formatDate';
import Context from '../../../../Context';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const HeaderInfo = ({ declinedStatus, showStatus = true }) => {
  const { isNew, privateRecord, isMobile } = useContext(Context);

  return (
    <div className={declinedStatus ? '' : 'my-3'}>
      {/* [KS] CMO-992 - Rename buttons on Edit of the Declined and Archived Claim screen */}
      {declinedStatus && !showStatus && <div className="large-text mb-3">{t('Based_on_Declined_Claim')}</div>}

      <div className="flex align-items-center col-12 p-0">
        <div className="flex col-11 p-0">
          {showStatus && (
            <div className="mr-3">
              <span className={cx('customer-badge-mobile py-1', `cm_status_${privateRecord?.Status}`)}>
                {isNew ? t('New') : privateRecord?.StatusText}
              </span>
            </div>
          )}

          <div className="mr-4">
            <span className="mr-2">{t('Invoices.3')}:</span>
            <b>{isNew ? t('N_A').toLowerCase() : privateRecord?.RecordNo}</b>
          </div>
        </div>

        {!isNew && (
          <div className="flex justify-content-end col-1 p-0">
            <Tooltip target=".target-icon" position={isMobile ? 'left' : 'top'} style={{ minWidth: '195px' }}>
              <div>{`${t('Date_created')}: ${formatDate(privateRecord.DateCreated)}`}</div>
              {privateRecord?.SDate && privateRecord?.Status !== 0 && `${t('Sent')}: ${formatDate(privateRecord?.SDate)}`}
              <div>{`${t('Date_updated')}: ${formatDate(privateRecord?.DateUpdated)}`}</div>
            </Tooltip>
            <i className="pi pi-info-circle target-icon" />
          </div>
        )}
      </div>

      <div className="mt-3">
        {(privateRecord?.Status === 3 || privateRecord?.Status === 6) && (
          <>
            {privateRecord?.MSPCodes.map((i, index) => {
              const key = `msc_${i.InvoiceGuid}${index}`;
              return (
                <div className="mb-2 p-error" key={key}>
                  {i}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderInfo;
