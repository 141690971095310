import React from 'react';

import { Chip } from 'primereact/chip';

import { t } from '../../../../service/localization/i18n';
import { elementIDs } from '../../../../modules/config/elementIDsConfig';
import { patientFullNameWithAge } from '../../../../modules/utils/patientFullName';
import { useCatalogsContext } from '../../hooks/useCatalogsContext';
import { catalogPatientChipsWrap } from '../../config/styles';

const PatientChips = () => {
  const { patients, onPatientChipRemove, removablePatientChip = true } = useCatalogsContext();

  const onChipRemove = (patient) => {
    const filteredPatients = patients.filter((i) => i.PatientGuid !== patient.PatientGuid);
    onPatientChipRemove(filteredPatients);
  };

  if (!patients?.length) {
    return (
      <span id={elementIDs.noPatientSelected} className="catalogs_no_patient_selected">
        {t('No_patient_selected')}
      </span>
    );
  }

  return (
    <>
      {patients?.map((i) => {
        const id = `${elementIDs.patientChip}_${i.PatientGuid?.toUpperCase()?.replaceAll('-', '')}`;
        const patientName = patientFullNameWithAge(i);

        return (
          <span id={id} key={i.PatientGuid}>
            <Chip className="mr-2 w-max" label={patientName} removable={removablePatientChip} icon="pi pi-user" onRemove={() => onChipRemove(i)} />
          </span>
        );
      })}
    </>
  );
};

const PatientChipsLayout = () => {
  const { patientChipsLayout } = useCatalogsContext();
  const patientChipsLayoutProp = typeof patientChipsLayout === 'function' ? patientChipsLayout() : patientChipsLayout;

  return (
    <div className="flex surface-overlay w-full overflow-auto scrollbar-thin" style={{ height: `${catalogPatientChipsWrap}px` }}>
      <div className="white-space-nowrap">{patientChipsLayout ? patientChipsLayoutProp : <PatientChips />}</div>
    </div>
  );
};

export default PatientChipsLayout;
