import { t } from '../../../../../service/localization/i18n';

export const columnValues = {
  // matchMode - Filtering is enabled by setting the filter property as true in column object. Default match mode is "startsWith" and this can be configured using filterMatchMode property of column object that also accepts "contains", "endsWith", "equals", "in" and "custom".
  // More info - https://www.primefaces.org/primereact-v6/#/datatable/filter | https://www.primefaces.org/primereact/datatable/filter/

  firstName: {
    id: 'FirstName',
    field: 'FirstName',
    filter: 'FirstName',
    header: t('First_Name'),
    placeholder: t('Search_by_first_name'),
    matchMode: 'startsWith'
  },

  lastName: {
    id: 'LastName',
    field: 'LastName',
    filter: 'LastName',
    header: t('Last_Name'),
    placeholder: t('Search_by_last_name'),
    matchMode: 'startsWith'
  },

  number: {
    id: 'PractitionerNumber',
    field: 'PractitionerNumber',
    filter: 'PractitionerNumber',
    header: t('Number'),
    placeholder: t('Search_by_number'),
    matchMode: 'startsWith'
  },

  phone: {
    id: 'Phone',
    field: 'Phone',
    filter: 'Phone',
    header: t('Phone'),
    placeholder: t('Search_by_phone'),
    matchMode: 'contains'
  },

  email: {
    id: 'Email',
    field: 'Email',
    filter: 'Email',
    header: t('Email'),
    placeholder: t('Search_by_email'),
    matchMode: 'contains'
  }
};
