import { phoneNumberLength } from '../config/defaultValuesConfig';

export const addZerosToPhoneNumber = (phoneNumber) => {
  let formattedPhoneNumber = '' + phoneNumber;
  while (formattedPhoneNumber?.length !== phoneNumberLength) {
    formattedPhoneNumber = '0' + formattedPhoneNumber;
  }

  return formattedPhoneNumber;
};
