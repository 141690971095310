import { t } from '../../../../../service/localization/i18n';
import { anatomicList } from '../../../helpers/mapper';

export const findAnatomicCode = (code) => anatomicList.find((i) => i.label === code);

export const formTypeValues = {
  firstReport: '8',
  nextReport: '11'
};

export const formTypeDropdownOptions = [
  { label: t('First_Report_8'), value: formTypeValues.firstReport },
  { label: t('Conditions_or_Treatment_Has_Changed_11'), value: formTypeValues.nextReport }
];

export const patientPeriodDropdownOptions = [
  { label: t('N_A'), value: '0' },
  { label: `0-6 ${t('Months').toLowerCase()}`, value: '1' },
  { label: `7-12 ${t('Months').toLowerCase()}`, value: '2' },
  { label: `> 12 ${t('Months').toLowerCase()}`, value: '9' }
];

export const estimatedTimeDropdownOptions = [
  { label: t('N_A'), value: '' },
  { label: t('At_work'), value: '0' },
  { label: `1-6 ${t('Days').toLowerCase()}`, value: '1' },
  { label: `7-13 ${t('Days').toLowerCase()}`, value: '2' },
  { label: `14-20 ${t('Days').toLowerCase()}`, value: '3' },
  { label: `> 20 ${t('Days').toLowerCase()}`, value: '9' }
];

export const anatomicCodesDropdownOptions = [
  { label: findAnatomicCode('N').name, value: findAnatomicCode('N').label },
  { label: findAnatomicCode('L').name, value: findAnatomicCode('L').label },
  { label: findAnatomicCode('R').name, value: findAnatomicCode('R').label },
  { label: findAnatomicCode('B').name, value: findAnatomicCode('B').label }
];

export const rehabilitationDropdownOptions = [
  { label: t('No'), value: 'N' },
  { label: t('Work_Conditioning_Program'), value: 'C' },
  { label: t('Other'), value: 'O' }
];

export const feeCodesDropdownOptions = {
  chiropractor: {
    firstReport: [
      {
        label: '19134',
        value: '19134',
        description: `Chiropractor's First Report (E-Form 8C)`
      },
      {
        label: '19145',
        value: '19145',
        description: `Chiropractor's First Report (E-Form 8C) "no charge" fee item (for re-submission purposes)`
      }
    ],

    nextReport: [
      {
        label: '19135',
        value: '19135',
        description: `Chiropractor's Progress Report (E-Form 11C)`
      },
      {
        label: '19146',
        value: '19146',
        description: `Chiropractor's Progress Report (E-Form 11C) "no charge" fee item (for re-submission purposes)`
      }
    ]
  },
  // ,[
  //   { label: '19134', value: '19134', description: `Chiropractor's First Report (E-Form 8C)` },
  //   { label: '19135', value: '19135', description: `Chiropractor's Progress Report (E-Form 11C)` },
  //],

  physician: {
    firstReport: [
      {
        label: '19927',
        value: '19927',
        description: `First Report Of Injury (Form 8) Requested, Received within 10 Business Days`
      },
      {
        label: '19937',
        value: '19937',
        description: `First Report (E-Form 8) Received within 6 Business Days`
      }
    ],

    nextReport: [
      {
        label: '19940',
        value: '19940',
        description: `Progress Report (E-Form 11) Received within 6 Business Days`
      },
      {
        label: '19559',
        value: '19559',
        description: `Progress Report (Form 11) Requested, Received within 10 Business Days`
      }
    ]
  }
};
