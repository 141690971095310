import React from 'react';
import { currencyFormat } from '../../utils/currencyFormat';

const PrivateInvoiceServices = ({ claimRecord }) => {
  return (
    <div className="flex flex-column align-items-center h-full medium-size-text">
      {claimRecord?.LineDetails?.map((i, index) => {
        return (
          <div className="mb-2 w-full" key={`private-service-${index}`}>
            <div>{i.Name}</div>
            <div>{`${currencyFormat(i.FeeAmount)} x ${i.NumUnits} = ${currencyFormat(i.FeeAmount * i.NumUnits)}`}</div>
          </div>
        );
      })}
    </div>
  );
};

export default PrivateInvoiceServices;
