// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_PATIENTS: 'GET_PATIENTS',
  IS_FETCHING_PATIENTS: 'IS_FETCHING_PATIENTS',
  IS_FETCHING_PATIENTS_WITH_MISSING_DATA: 'IS_FETCHING_PATIENTS_WITH_MISSING_DATA',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  SET_PATIENT_INFO: 'SET_PATIENT_INFO',
  SET_PATIENT_ID_FOR_HIGHTLIGHT_IN_TABLE: 'SET_PATIENT_ID_FOR_HIGHTLIGHT_IN_TABLE',
  IS_DELETING_PATIENT: 'IS_DELETING_PATIENT',
  FETCH_PATIENTS_REQUEST: 'FETCH_PATIENTS_REQUEST',
  // for DataScroll
  FETCH_PATIENT_LIST_REQUEST: 'FETCH_PATIENT_LIST_REQUEST',
  FETCH_PATIENT_LIST_FAILURE: 'FETCH_PATIENT_LIST_FAILURE',

  FETCH_PATIENT_DETAILS_SUCCESS: 'FETCH_PATIENT_DETAILS_SUCCESS',
  CREATE_PATIENT_REQUEST: 'CREATE_PATIENT_REQUEST',
  CREATE_PATIENT_FAILURE: 'CREATE_PATIENT_FAILURE',

  EDIT_PATIENT_SUCCESS: 'EDIT_PATIENT_SUCCESS',

  SET_PATIENT_LIST: 'SET_PATIENT_LIST',
  SET_PATIENTS_COMPLETE_LIST: 'SET_PATIENTS_COMPLETE_LIST',
  SET_PATIENTS_TOTAL_RECORDS: 'SET_PATIENTS_TOTAL_RECORDS',

  // notes

  FETCH_PATIENT_NOTES_REQUEST: 'FETCH_PATIENT_NOTES_REQUEST',
  FETCH_PATIENT_NOTES_SUCCESS: 'FETCH_PATIENT_NOTES_SUCCESS',
  FETCH_PATIENT_NOTES_FAILURE: 'FETCH_PATIENT_NOTES_FAILURE',

  EDIT_PATIENT_NOTE_SUCCESS: 'EDIT_PATIENT_NOTE_SUCCESS',
  EDIT_PATIENT_NOTE_FAILURE: 'EDIT_PATIENT_NOTE_FAILURE',
  DELETE_PATIENT_NOTE_SUCCESS: 'DELETE_PATIENT_NOTE_SUCCESS',
  DELETE_PATIENT_NOTE_FAILURE: 'DELETE_PATIENT_NOTE_FAILURE',
  SAVING_PATIENT_DETAILS: 'SAVING_PATIENT_DETAILS',
  IS_CREATING_PATIENT: 'IS_CREATING_PATIENT',

  // internal
  PATIENT_FILTERS: 'PATIENT_FILTERS',
  SETUP_PATIENTS_AUTOSUGGEST: 'SETUP_PATIENTS_AUTOSUGGEST',
  SET_ACTIVE_PATIENT_TAB: 'SET_ACTIVE_PATIENT_TAB',

  CHECK_ELIGIBILITY_ONLY: 'CHECK_ELIGIBILITY_ONLY',
  CHECK_NAME_AND_ELIGIBILITY: 'CHECK_NAME_AND_ELIGIBILITY',
  IS_FETCHING_PATIENT_ELIGIBILITY: 'IS_FETCHING_PATIENT_ELIGIBILITY',
  IS_FETCHING_PHN_VALIDATION: 'IS_FETCHING_PHN_VALIDATION',

  SHOW_NEW_PATIENT_SUCCESS_PAGE: 'SHOW_NEW_PATIENT_SUCCESS_PAGE',

  SET_ELIGIBILITY: 'SET_ELIGIBILITY',

  SET_PATIENTS_DEFAULT_TABLE_VALUES: 'SET_PATIENTS_DEFAULT_TABLE_VALUES',
  PATIENTS_DUPLICATE_CHECKING: 'PATIENTS_DUPLICATE_CHECKING',
  IS_UPDATING_PHN: 'IS_UPDATING_PHN',
  PATIENT_FORM_ACTIVE_TAB_INDEX: 'PATIENT_FORM_ACTIVE_TAB_INDEX'
};
