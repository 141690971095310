import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CheckEligibilityButtons from '../../ActionButtons/CheckEligibilityButtons';
// import { Messages } from 'primereact/messages';

// import { isEmpty } from 'lodash';
// import { formatDate } from '../../../../../../utils/formatDate';
// import { t } from '../../../../../../../service/localization/i18n';
// import { elementIDs } from '../../../../../../config/elementIDsConfig';

const EligibilityButtonsSection = () => {
  // const { localState } = useFormContext();
  // const eligibility = localState?.eligibility;
  // const [currentEligibility, setCurrentEligibility] = useState(eligibility);
  // const message = useRef(null);

  // useEffect(() => {
  //   const showMessage = !isEmpty(eligibility) && eligibility?.ELIG_ON_DOS && (eligibility?.ELIG_ON_DOS !== currentEligibility?.ELIG_ON_DOS || eligibility?.CHECK_ON_DATE !== currentEligibility?.CHECK_ON_DATE);

  //   if (showMessage) {
  //     setCurrentEligibility(eligibility);
  //     const checkOnDate = formatDate(eligibility?.CHECK_ON_DATE);

  //     if (eligibility?.ELIG_ON_DOS?.toUpperCase() === "YES") {
  //       message.current?.show({
  //         sticky: true,
  //         severity: 'success',
  //         summary: `${t("Active_on")} ${checkOnDate}`
  //       });
  //     };

  //     if (eligibility?.ELIG_ON_DOS?.toUpperCase() === "NO") {
  //       message.current?.show({
  //         sticky: true,
  //         severity: 'error',
  //         summary: `${t("Inactive_on")} ${checkOnDate}`
  //       });
  //     };

  //     if (eligibility?.ELIG_ON_DOS === "") {
  //       message.current?.show({
  //         sticky: true,
  //         severity: 'warn',
  //         summary: `${t("Undetermined_on")} ${checkOnDate}`
  //       });
  //     };
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [eligibility?.CHECK_ON_DATE, eligibility?.ELIG_ON_DOS]);

  return (
    <>
      {/* <Messages
        id={elementIDs.eligibilityMessage}
        className='first_visit_eligibility_message'
        ref={message}
        onRemove={() => setCurrentEligibility(null)}
      /> */}
      <CheckEligibilityButtons />
    </>
  );
};

export default EligibilityButtonsSection;
