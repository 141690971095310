import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import Context from '../../../../../Context';
import { inputs } from '../../../../../modules/claims/components/WSBCReport/helpers/inputs';
import { t } from '../../../../../service/localization/i18n';

const BillingInformation = () => {
  const { members } = useSelector((state) => state.clinic);
  const { data } = useContext(Context);
  const practitioner = members.find((i) => i.DoctorGuid === data[inputs.practitioner.name]);

  return (
    <table className="mt-3 text-sm demographicsInfoTablePrint">
      <tbody>
        <tr>
          <td style={{ width: '50%' }}>
            <div className="flex">
              <span className="font-semibold pr-2" style={{ width: '100px' }}>
                {`${t('Payee_number.1')}:`}
              </span>
              <span className="coloredText">{practitioner?.PayeeNumber || ''}</span>
            </div>
          </td>
          <td style={{ width: '50%' }}>
            <div className="flex">
              <span className="font-semibold pr-2" style={{ width: '130px' }}>
                {`${t('Practitioner_number')}:`}
              </span>
              <span className="coloredText">{practitioner?.PractitionerNumber || ''}</span>
            </div>
          </td>
        </tr>

        <tr>
          <td style={{ width: '50%' }}>
            <div className="flex">
              <span className="font-semibold pr-2" style={{ width: '100px' }}>
                {`${t('Payee_name')}:`}
              </span>
              <span className="coloredText">{practitioner?.PayeeNumber || ''}</span>
            </div>
          </td>
          <td style={{ width: '50%' }}>
            <div className="flex">
              <span className="font-semibold pr-2" style={{ width: '130px' }}>
                {`${t('Practitioner_name')}:`}
              </span>
              <span className="white-space-nowrap overflow-hidden text-overflow-ellipsis coloredText" style={{ width: '230px' }}>
                {`${practitioner?.LastName}, ${practitioner?.FirstName}`}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BillingInformation;
