import { isEmpty } from 'lodash';

//[KS] CMO-1106 - Eligibility returns different name but we do not alert the user
export const checkIfPatientDemographicsAreDifferent = ({ eligibility, formikValues }) => {
  let checkIfDifferent = false;

  if (!isEmpty(eligibility)) {
    const currentFullName = `${formikValues.LastName} ${formikValues.FirstName} ${formikValues.MidName}`?.trim()?.toUpperCase();
    const eligibilityFullName = eligibility?.NAME?.trim()?.toUpperCase();

    if (currentFullName !== eligibilityFullName || (formikValues.Sex?.length && formikValues.Sex !== eligibility.GENDER?.substring(0, 1))) {
      checkIfDifferent = true;
    }
  }

  return checkIfDifferent;
};
