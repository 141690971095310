import React from 'react';

import Payee from '../Inputs/Payee';
import Location from '../Inputs/Location';
import ServiceDate from '../Inputs/ServiceDate';
import Practitioner from '../Inputs/Practitioner';
import ServiceDatesList from '../../Layouts/ServiceDatesList';

const Services = () => {
  return (
    <div>
      <div className="flex flex-column lg:flex-row align-items-center gap-3 w-full">
        <div className="eclaim_step_two_left_gap"></div>

        <div className="flex flex-column sm:flex-row gap-3 w-full">
          <div className="eclaim_step_two_input_wrap">
            <ServiceDate />
          </div>
          <div className="eclaim_step_two_input_wrap">
            <Practitioner />
          </div>
        </div>

        <div className="flex flex-column sm:flex-row gap-3 w-full">
          <div className="eclaim_step_two_input_wrap">
            <Location />
          </div>
          <div className="eclaim_step_two_input_wrap">
            <Payee />
          </div>
        </div>

        <div className="eclaim_step_two_right_gap"></div>
      </div>

      <div className="hidden lg:flex lg:flex-row align-items-center w-full">
        <div className="eclaim_step_two_left_gap"></div>
        <ServiceDatesList />
        <div className="eclaim_step_two_right_gap"></div>
      </div>
    </div>
  );
};

export default Services;
