import React, { useRef } from 'react';

import { CodePicker, InputWrapper } from '../../../../../../../../../components/Inputs';

import { useValidateGoupPicker } from '../../../hooks/useValidateGroupPicker';
import { useGroupPickerInputs } from '../../../hooks/useGroupPickerInputs';
import { feeCodeEditorWidth, rowInputHeight } from '../../../../../config/teleplanGroupStyles';
import { useFeeCode } from '../../../../../hooks/useFeeCode';
import { inputs } from '../../../../../helpers/inputs';
import { t } from '../../../../../../../../../service/localization/i18n';
import cx from 'classnames';

const FeeCodeEditor = ({ rowOptions }) => {
  const { onFeeChange, toggleCatalogsForGroup } = useGroupPickerInputs();
  const { feeCodeItemTemplate } = useFeeCode();
  const { getErrorMessage } = useValidateGoupPicker();
  const ref = useRef(null);
  const name = inputs.feeCodes.name;
  const rowId = rowOptions.rowData[inputs.groupRowId.name];
  const feeCodes = rowOptions.rowData[inputs.feeCodes.codeType];
  const speciality = rowOptions.rowData[inputs.speciality.name];
  const description = feeCodes?.length ? feeCodes[0]?.label : null;
  const errorMessage = getErrorMessage(rowId, name);

  const descriptionLayout = (
    <div
      className="white-space-nowrap overflow-hidden text-overflow-ellipsis input_description_styles"
      style={{ width: `calc(${feeCodeEditorWidth} - 20px)` }}
    >
      {description}
    </div>
  );

  return (
    <InputWrapper
      hideLabel
      name={name}
      label={inputs.referral.label}
      errorMessage={errorMessage}
      description={!errorMessage ? descriptionLayout : null}
      style={{ height: rowInputHeight, width: '100%' }}
    >
      <div className="flex flex-column w-full" style={{ columnGap: '5px' }}>
        <div className="flex flex-column w-full">
          <CodePicker
            name={name}
            ref={ref}
            className={cx('w-full', 'teleplan_group_code_picker')}
            value={feeCodes}
            isInvalid={!!errorMessage || (!!errorMessage && !feeCodes?.length)}
            placeholder={feeCodes?.length ? '' : t('Search_for_codes')}
            maxEntry={1}
            showCatalogsButton
            catalogsButtonTooltip={t('Find_fee_code_in_the_catalog')}
            searchParams={{ speciality, codeType: inputs.feeCodes.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={() => toggleCatalogsForGroup(inputs.feeCodes, rowOptions)}
            onChange={(e) => onFeeChange(e.value, rowOptions)}
            itemTemplate={feeCodeItemTemplate}
          />
        </div>
      </div>
    </InputWrapper>
  );
};

export default FeeCodeEditor;
