import React from 'react';

const StepsWrap = (props) => {
  return (
    <div className="flex justify-content-center p-3">
      <div className="flex justify-content-center w-full">{props.children}</div>
    </div>
  );
};

export default StepsWrap;
