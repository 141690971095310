import React from 'react';
import { useFormContext } from 'react-hook-form';
import WSBCReportForm from '../WSBCReportForm/WSBCReportForm';
import FormWithCatalogsWrap from '../../../../../../components/Wraps/FormWithCatalogsWrap/FormWithCatalogsWrap';
import FormActionButtons from '../ActionButtons/FormActionButtons';
import WSBCReportCatalogs from '../WSBCReportCatalogs/WSBCReportCatalogs';
import { elementIDs } from '../../../../../config/elementIDsConfig';

const StepOne = () => {
  const { localState } = useFormContext();

  return (
    <div id={elementIDs.WSBCReportForm} className="flex">
      <FormWithCatalogsWrap
        hideRightCard={!localState.showCatalogs.desktop}
        leftCardContent={<WSBCReportForm />}
        leftCardActionButtons={<FormActionButtons />}
        rightCardContent={<WSBCReportCatalogs />}
      />
    </div>
  );
};

export default StepOne;
