import ClaimService from '../../../service/ClaimService';
import { store } from '../../../store';
import { setToastMessage } from '../../core/actions/core.action.creators';
import { isFetchingClaimHistory } from '../actions/claims.action.creators';
import { t } from '../../../service/localization/i18n';

export const setRequestHistory = (data, setClaimHistory, setPopupVisible, setHeader, resetHighlighted = true) => {
  // [VB] use Promise to show dialog only when data has arrived
  // show spinner
  // document.body.style.cursor = "progress";
  store.dispatch(isFetchingClaimHistory(true));
  ClaimService.getClaimHistory(data.InvoiceGuid, setClaimHistory)
    .then(() => {
      // hide spinner
      // document.body.style.cursor = "default";
      store.dispatch(isFetchingClaimHistory(false));
      setPopupVisible(true);
      setHeader(`${t('Claim_History_for')} #${data.SequenceNumber} (${data.FullName})`);
    })
    .catch((reason) => {
      return store.dispatch(setToastMessage({ type: 'error', message: reason?.response?.data?.ExceptionMessage }));
    });
};
