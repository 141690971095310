import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './CircularProgress.scss';

const CircularProgress = ({ className = '', wrapClassName = 'circular_progress_wrap' }) => {
  return (
    <div className={wrapClassName}>
      <ProgressSpinner className={className} strokeWidth="3" />
    </div>
  );
};

export default CircularProgress;
