import React from 'react';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { useUpdateRecords } from '../../../hooks/useUpdateRecords';
import { t } from '../../../../../../../service/localization/i18n';

const UnitsBodyTemplate = ({ rowData }) => {
  const field = 'units';
  const units = rowData[field];
  const { isRequiredInput } = useUpdateRecords();
  const isRequired = isRequiredInput(rowData.id, field);

  if (isRequired) {
    return (
      <div className="flex flex-column justify-content-center align-items-end lg:align-items-start h-full">
        <span id={elementIDs.invoiceRecordsUnitsTemplate} className="p-error">
          {t('Required')}
        </span>
      </div>
    );
  }

  if (units < 0 || units === '') {
    return (
      <span id={elementIDs.invoiceRecordsUnitsTemplate} style={{ color: 'var(--primary-color)' }}>
        ―
      </span>
    );
  }

  return (
    <span id={elementIDs.invoiceRecordsUnitsTemplate} style={{ color: 'var(--primary-color)' }}>
      {units}
    </span>
  );
};

export default UnitsBodyTemplate;
