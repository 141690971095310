import React, { useEffect, useState } from 'react';

import PrivateInvoiceSend from '../components/Layouts/Send/PrivateInvoice/PrivateInvoiceSend';
import ClaimService from '../service/ClaimService';
import classes from './PrintInvoice.module.scss';
import cx from 'classnames';

export const PrintInvoicePage = (props) => {
  const [data, setData] = useState();
  useEffect(() => {
    const pathname = document.location.href;
    if (pathname && pathname.indexOf('/') > -1) {
      console.log('inside PrintInvoicePage');
      const path = pathname.replace('#/', '').split('/');
      const invoice_id = path.at(-1);
      const dcn = path.at(-2);
      if (invoice_id?.length > 0 && dcn?.length > 0) {
        ClaimService.getClaimInfoNoState(dcn, invoice_id).then((results) => {
          setData(results.data);
        });
      } else {
        console.log(path);
      }
    }
  }, []);

  return <div className={cx('p-3', classes.root)}>{data && <PrivateInvoiceSend data={data} />}</div>;
};
