import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { inputs } from '../../helpers/inputs';
import { elementIDs } from '../../../../../config/elementIDsConfig';
import { getInvalidDurationMessageForPsychiatryCodes, getPsychiatryCodesWithWrongDuration } from '../../helpers/claimFormValidation';
import { localStateInitValues } from '../../helpers/teleplanInvoiceInitialState';
import { parseMilitaryTime } from '../../../../../utils/formatTime';
import { t } from '../../../../../../service/localization/i18n';
import { ignoreDurationForPsychiatryCodesList } from '../../helpers/codesCheckList';
import { useActionButtons } from '../../hooks/useActionButtons';

const DurationForPsychiatryCodesDialog = () => {
  const { localState, setLocalState, getValues } = useFormContext();
  const { showDialog, options } = localState.durationForPsychiatryCodesDialog;
  const { onReview, onReviewCreate: onCreate } = useActionButtons();

  if (!showDialog) return null;

  const currentFeeCodes = getValues(inputs.feeCodes.codeType);
  const notMatchDurationRows = localState.records.filter((i) => {
    const feeCodes = currentFeeCodes?.filter((code) => code.value === i.fee_code);
    const startTime = parseMilitaryTime(i?.service_start);
    const endTime = parseMilitaryTime(i?.service_finish);
    const psychiatryCodesList = getPsychiatryCodesWithWrongDuration({ feeCodes, startTime, endTime });
    return psychiatryCodesList?.length;
  });
  const showIgnoreButton = notMatchDurationRows?.some((i) => {
    return ignoreDurationForPsychiatryCodesList.some((x) => x === i.fee_code);
  });

  const onHide = () => {
    setLocalState((prevState) => ({
      ...prevState,
      durationForPsychiatryCodesDialog: localStateInitValues.durationForPsychiatryCodesDialog
    }));
  };

  // VER-677
  const onIgnore = () => {
    if (options.isCreateClicked) {
      onCreate({ ignoreDurationValidatonForPsychiatry: true });
    } else {
      onReview({ ignoreDurationValidatonForPsychiatry: true });
    }
    onHide();
  };

  const ignoreButton = showIgnoreButton ? <Button type="button" className="p-button-outlined" label={t('Ignore')} onClick={onIgnore} /> : null;
  const actionButtons = (
    <>
      {ignoreButton}
      <Button label={t('Ok')} onClick={onHide} />
    </>
  );

  return (
    <Dialog
      id={elementIDs.durationForPsychiatryCodesDialog}
      header={t('Warning')}
      visible={showDialog}
      breakpoints={{ '1366px': '50vw', '960px': '60vw', '768px': '95vw' }}
      style={{ width: '35vw' }}
      baseZIndex={1000000}
      footer={actionButtons}
      onHide={onHide}
    >
      <div className="text-lg flex flex-column gap-2">
        {notMatchDurationRows?.map((i) => {
          const feeCodesList = currentFeeCodes?.filter((code) => code.value === i.fee_code);
          const startTime = parseMilitaryTime(i?.service_start);
          const endTime = parseMilitaryTime(i?.service_finish);
          const message = getInvalidDurationMessageForPsychiatryCodes({ feeCodes: feeCodesList, startTime, endTime });

          return (
            <div key={i[inputs.groupRowId.name]}>
              {/* Add bullet point before each message */}
              <span>&#8226; </span>
              {message}
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

export default DurationForPsychiatryCodesDialog;
