import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputWrapper } from '../../../../components/Inputs';
import { setLoginView } from '../../actions/auth.actions.creators';

import { userVerify, getAffiliates } from '../../actions/auth.actions.creators';
import { setToastMessage } from '../../../core/actions/core.action.creators';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';

const UserVerify = () => {
  const dispatch = useDispatch();
  const { userVerifyRequest, logoutRequest, gettingAffiliates, details } = useSelector((state) => state.user);
  const inputLength = 4;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = async (data) => {
    const result = await userVerify(data.code);
    reset();
    if (result) dispatch(getAffiliates(details));
  };

  const onLogout = () => {
    dispatch(setLoginView('login'));
    sessionStorage.removeItem('bureau');
    sessionStorage.removeItem('bau');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="code"
        control={control}
        rules={{
          required: t('Mandatory_field.1'),
          minLength: {
            value: inputLength,
            message: 'Minimum length is 4 characters'
          }
        }}
        render={({ field, fieldState }) => (
          <InputWrapper name="code" label="Code" required errorMessage={errors?.code?.message}>
            <InputText
              id={field.name}
              {...field}
              autoFocus
              keyfilter="int"
              maxLength={inputLength}
              className={cx({ 'p-invalid': fieldState.error })}
            />
          </InputWrapper>
        )}
      />

      <div className="flex gap-3">
        <Button
          id="cancel"
          className="p-button-outlined"
          label={t('Cancel')}
          type="button"
          disabled={logoutRequest || userVerifyRequest}
          onClick={onLogout}
        />
        <Button id="submit" label={t('Submit')} type="submit" loading={userVerifyRequest || gettingAffiliates} disabled={logoutRequest} />
      </div>
    </form>
  );
};

export default UserVerify;
