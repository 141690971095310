import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { ScrollTop } from 'primereact/scrolltop';
import { ContextMenu } from 'primereact/contextmenu';
import { Toast } from 'primereact/toast';

import Sort from './Sort/Sort';
import Filters from './Filters/Filters';
import TableTitle from './TableTitle';
import TableHeader from './TableHeader';
import TableSettings from './TableSettings';
import ClaimService from '../../service/ClaimService';
import CircularProgress from '../Misc/Loader/CircularProgress/CircularProgress';

import { defaultPageSize, showInCardPageSize } from '../../modules/config/defaultValuesConfig';
import { prefsCodes } from '../../modules/config/prefsCodesConfig';
import { differenceBy, findIndex, sortBy, unionBy } from 'lodash';
import { t } from '../../service/localization/i18n';
import classes from './Table.module.scss';
import cx from 'classnames';
import './Table.scss';

const Table = React.forwardRef((props, ref) => {
  const { currentUserPrefs } = useSelector((state) => state.preferences);

  const [selectedColumns, setSelectedColumns] = useState(props.columns);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showExpandColumn, setShowExpandColumn] = useState(props.showExpand);
  const [showCheckboxSelection, setShowCheckboxSelection] = useState(props.showSelection);
  const [isMultipleSelect, setIsMultipleSelect] = useState(false);
  const [autoscrollToHighlightedElement, setAutoscrollToHighlightedElement] = useState(true);
  const dispatch = useDispatch();
  const growl = useRef(null);

  const dt = useRef(null);
  const cm = useRef(null);
  const displayPrefs = currentUserPrefs?.prefs?.find((i) => i.label === 'display');
  const dataTablePrefs = displayPrefs?.content?.find((i) => i.label === prefsCodes.dataTable);
  const defaultRowOption = props.showInCard ? showInCardPageSize : defaultPageSize;
  const rowOptions = [defaultRowOption, 40, 60];
  const showSpinner = props.fetchingData;
  const dataTableRef = ref ? ref : dt;
  const isSelectionColumnFrozen = selectedColumns?.some((i) => i.frozen);
  const isMobile = window.innerWidth < 768;
  const first = props.first || '{first}';
  const last = props.first
    ? props.first + props.rows - 1 < props.totalRecords
      ? props.first + props.value?.length - 1
      : props.totalRecords
    : 'last';
  const currentPageReportTemplate = `${first} to ${last} of ${Number(props.totalRecords)?.toLocaleString() || '{totalRecords}'}`;
  const getClassName = document.getElementsByClassName('p-highlight p-selectable-row');

  // Auto scroll to highlighted element
  useEffect(() => {
    if (props.preSelected?.length > 0) {
      if (autoscrollToHighlightedElement) {
        props.preSelected && setSelectedRow(props.preSelected);

        if (getClassName?.length) {
          if (isMobile && !props.showInCard) {
            scrollToHighlightedElement();
          } else {
            // const getClassName = document.getElementsByClassName('p-highlight p-selectable-row');
            !props.showInCard && getClassName[1]?.scrollIntoView({ block: 'center' });
            props.setPreSelected && props.setPreSelected();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.preSelected, autoscrollToHighlightedElement, getClassName]);

  // Set columns to display
  useEffect(() => {
    // If preferences exist
    if (dataTablePrefs && dataTablePrefs?.value[props.name]) {
      // Get list of columns from preferences
      const preferencesColumns = props.columns.filter((i) => dataTablePrefs?.value[props.name].columns?.includes(i.field));

      // Find columns that should be always displayed
      const displayAlwaysColumns = differenceBy(props.columns, props.columnsForToggle, 'id');

      // Merge preferencesColumns and displayAlwaysColumns
      const mergedColumns = unionBy(preferencesColumns, displayAlwaysColumns, 'id');

      // Sort the merged result based on the order of the columns array
      const sortedColumns = sortBy(mergedColumns, (item) => findIndex(props.columns, { id: item.id }));

      return setSelectedColumns(sortedColumns);
    }

    // If preferences NOT exist, then set column list
    return setSelectedColumns(props.columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters]);

  const exportDone = (e) => {
    if (e && e.LinkToCsv && growl) {
      growl.current.show({
        severity: 'info',
        summary: 'You data export is ready',
        sticky: true,
        detail: (
          <div>
            <a href={e.LinkToCsv} style={{ textDecoration: 'underline' }} target="_blank">
              Report (CSV)
            </a>
            <br />
            <a href={e.LinkToXls} style={{ textDecoration: 'underline' }} target="_blank">
              Report (Excel)
            </a>
          </div>
        )
      });
    }
  };
  const onSettingsClick = () => setShowSettingsDialog(true);
  const onExportClick = () => ClaimService.getPatientReport(exportDone, dispatch);

  const scrollToHighlightedElement = () => {
    const element = document.getElementsByClassName('p-highlight p-selectable-row');
    if (element?.length) {
      const offset = 240;
      const highlightedElement = element[0];
      const elementPosition = highlightedElement && highlightedElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;
      window.scrollTo({
        top: offsetPosition
        // behavior: "smooth"
      });

      // [KS] CMO-1206 - [Mobile] Do not autoscroll to the top when user scrolling screen with the data table
      setAutoscrollToHighlightedElement(false);
    }
  };

  const longPressEvent = (triggerTime, rowData) => {
    if (isMultipleSelect) {
      const _isSelected = selectedRow?.some((i) => i[props.dataKey] === rowData[props.dataKey]);
      const updatedSelectedRows = _isSelected ? selectedRow.filter((i) => i[props.dataKey] !== rowData[props.dataKey]) : [...selectedRow, rowData];

      !updatedSelectedRows.length && setIsMultipleSelect(false);
      return setSelectedRow(updatedSelectedRows);
    }

    if (triggerTime < 500 && !isMultipleSelect) return setSelectedRow([rowData]);

    return setIsMultipleSelect(true);
  };

  const onContextMenu = (e) => {
    cm.current.show(e.originalEvent);
    setSelectedRowData(e.data);
  };

  const handlePage = (e) => {
    // dataTableRef?.current?.resetScroll(); // Avalible only in vertions 7.2.1+ !!!
    !props.showInCard && window.scrollTo({ top: 0 });
    props.onPage(e);
  };

  const expandAll = () => {
    let _expandedRows = {};
    props.value.forEach((i) => (_expandedRows[`${i[props.dataKey]}`] = true));

    props.setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    props.setExpandedRows({});
  };

  const onExpand = () => {
    props.setIsExpanded((isExpanded) => !isExpanded);
    props.isExpanded ? collapseAll() : expandAll();
  };

  const columnsTemplate = () => {
    return selectedColumns.map((i) => {
      return (
        <Column
          key={i.id}
          id={i.id}
          field={i.field}
          header={i.header}
          sortable={i.sortable}
          filter={i.filter}
          frozen={i.frozen}
          filterPlaceholder={i.filterPlaceholder}
          body={i.body}
          filterElement={i.filterElement}
          showFilterMenu={false}
          style={i.style || { minWidth: props.frozenWidth }}
        />
      );
    });
  };

  const expandAllColumnTemplate = () => {
    return (
      <Button
        className="p-button-text p-button-rounded"
        tooltip={props.isExpanded ? t('Collapse_all') : t('Expand_all')}
        tooltipOptions={{ position: 'top' }}
        icon={props.isExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'}
        onClick={onExpand}
      />
    );
  };

  const defaultSortTemplate = () => {
    if (typeof props.defaultSortTemplate === 'function') return props.defaultSortTemplate();
    return props.defaultSortTemplate;
  };

  const onSelectMobileRow = (row) => {
    autoscrollToHighlightedElement && setAutoscrollToHighlightedElement(false);
    setSelectedRow(row);
  };

  const tableHeader = (
    <TableHeader
      {...props}
      ref={dataTableRef}
      isMobile={isMobile}
      selectedRow={selectedRow}
      dataTableName={props.name}
      dataTableValue={props.value}
      isMultipleSelect={isMultipleSelect}
      selectedColumns={selectedColumns}
      onSettingsClick={onSettingsClick}
      onExportClick={onExportClick}
      setSelectedRow={setSelectedRow}
      setIsMultipleSelect={setIsMultipleSelect}
      setShowCheckboxSelection={setShowCheckboxSelection}
    />
  );

  const dataTableProps = {
    ref: dataTableRef,
    id: props.id,
    value: props.value,
    header: props.header?.type ? props.header : tableHeader,
    footer: props.footer,
    // stripedRows: props.stripedRows,
    resizableColumns: props.resizableColumns,
    emptyMessage: props.emptyMessage || t('No_records_found'),

    // scrolling
    scrollHeight: props.showInCard ? props.scrollHeight : null,
    // scrollHeight: props.scrollHeight, // Autoscroll to the highlighted item is not available when data table only is scrollable. Current theme vertion is 7.2.0

    // filters
    globalFilter: props.globalFilter,
    filterDisplay: props.filterDisplay,
    globalFilterFields: props.globalFilterFields,

    // sorting
    onSort: props.onSort,
    sortField: props.sortField,
    sortOrder: props.sortOrder,

    // selection mode
    dataKey: props.dataKey,
    selectionMode: props.selectionMode,
    // dragSelection: props.dragSelection,
    selection: selectedRow,

    // paginator
    lazy: props.lazy,
    paginator: props.paginator,
    first: props.first,
    totalRecords: props.totalRecords,
    rows: props.rows,
    rowsPerPageOptions: props.rowOptions || rowOptions,
    pageLinkSize: isMobile || props.showInCard ? 2 : 4,
    paginatorTemplate: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    currentPageReportTemplate,
    paginatorLeft: props.paginatorLeft,
    paginatorRight: props.paginatorRight,
    onPage: props.onPage ? (e) => handlePage(e) : false
  };

  const mobileDataTableLayout = (
    <DataTable
      className={props.showInCard ? 'dataTableInCard' : cx(classes.mobileDataTable, 'dataTable')}
      {...dataTableProps}
      scrollable={props.scrollable}
    >
      {selectedColumns.map((i, index) => {
        return (
          <Column
            key={i.field}
            field={i.field}
            body={(rowData) => props.mobileRowTemplate({ rowData, longPressEvent, setSelectedRow: onSelectMobileRow })}
            style={index === 0 ? {} : { display: 'none' }}
          />
        );
      })}
    </DataTable>
  );

  const mobileDataTable = () => {
    if (props.showInCard) return mobileDataTableLayout;

    return (
      <div
      // className={classes.mobileDataTableWrap} // Autoscroll to the highlighted item is not available when data table only is scrollable. Current theme vertion is 7.2.0
      >
        {mobileDataTableLayout}
      </div>
    );
  };

  const desktopDataTable = (
    <div className={classes.desktopDataTableWrap}>
      <DataTable
        {...dataTableProps}
        // className={cx('dataTable', classes.desktopDataTable)}
        onSelectionChange={(e) => setSelectedRow(e.value)}
        // context menu
        contextMenuSelection={selectedRow}
        onContextMenu={props.rowActionButtonsMenuModel ? onContextMenu : null}
        onContextMenuSelectionChange={(e) => setSelectedRow(e.value)}
        // expanded row
        expandedRows={props.expandedRows}
        onRowToggle={(e) => props.setExpandedRows(e.data || {})}
        rowExpansionTemplate={(data) => props.rowExpansionTemplate(data)}
        // scroll
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"

        // !!! Frozen columns not avalible in v6.6.0. Uncomment this code after update PR to v7.0.0+ !!!
        // frozenWidth={props.frozenWidth} // Width of the frozen part in scrollable DataTable
      >
        {/* Expander */}
        {showExpandColumn && (
          <Column className={classes.expanderColumn} expander filter showFilterMenu={false} filterElement={expandAllColumnTemplate()} />
        )}

        {/* Checkbox row selection */}
        {showCheckboxSelection && <Column frozen={isSelectionColumnFrozen} selectionMode="multiple" className={classes.expanderColumn} />}

        {/* Columns */}
        {columnsTemplate()}

        {/* Column with action buttons */}
        {props.rowActionButtons && (
          <Column
            className={classes.actionButtonsColumn}
            body={props.rowActionButtons}
            alignFrozen="right"
            filter={props.defaultSortTemplate}
            showFilterMenu={false}
            filterElement={props.defaultSortTemplate && defaultSortTemplate()}
            frozen
          />
        )}
      </DataTable>
    </div>
  );

  const dataTable = () => {
    if (props.showInCard) return mobileDataTable();

    return (
      <>
        {mobileDataTable()}
        {desktopDataTable}
      </>
    );
  };

  return (
    <>
      {/* Option buttons */}
      {!props.showInCard && <TableTitle pageLabel={props.pageLabel} tableDescription={props.tableDescription} />}

      <>
        {/* Context menu */}
        {props.rowActionButtonsMenuModel && (
          <ContextMenu ref={cm} model={props.rowActionButtonsMenuModel(selectedRowData)} onHide={() => setSelectedRow([])} />
        )}

        {/* Table */}
        {dataTable()}
      </>

      {/* Sidebar filters */}
      <Filters filters={props.filters} onChipRemove={props.onChipRemove} clearAllFilters={props.clearAllFilters}>
        {props.sidebarFilters}
      </Filters>

      {/* Sidebar sorting (only mobile view) */}
      <Sort
        onSort={props.onSort}
        columns={props.columns}
        sortOrder={props.sortOrder}
        sortField={props.sortField}
        isFetchingData={props.fetchingData}
        defaultSortTemplate={props.defaultSortTemplate}
      />

      {/* Data table settings dialog */}
      <TableSettings
        columns={props.columns}
        columnsForToggle={props.columnsForToggle}
        dataTableName={props.name}
        selectedColumns={selectedColumns}
        showExpand={props.showExpand}
        showSettingsDialog={showSettingsDialog}
        setShowExpandColumn={setShowExpandColumn}
        setSelectedColumns={setSelectedColumns}
        setShowSettingsDialog={setShowSettingsDialog}
      />

      {/* Spinner */}
      {showSpinner && <CircularProgress />}

      {/* Scroll top */}
      {!props.showInCard && <ScrollTop className="customScrolltop" icon="pi pi-arrow-up" />}

      <Toast ref={growl} baseZIndex={1000000} sticky={true} />
    </>
  );
});

export default Table;
