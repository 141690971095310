import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import PatientLayout from './rowTemplates/PatientLayout';
import GroupPickerTableHeader from './GroupPickerTableHeader/GroupPickerTableHeader';

import { uniqBy } from 'lodash';
import { inputs } from '../../../../helpers/inputs';
import { t } from '../../../../../../../../service/localization/i18n';
import { steps } from '../../../../helpers/steps';

const GroupPickerTable = ({ columns }) => {
  const refTable = useRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const { localState, setLocalState } = useFormContext();
  const isReferralStep = localState.step === steps.groupReferralPicker;
  const isCommentStep = localState.step === steps.groupComments;

  const onRowReorder = (e) => {
    setLocalState((prevState) => {
      // Set that order was changed on group time picker page
      const isOrderChanged = prevState.step === steps.groupDxPicker;
      return { ...prevState, groupRecords: e.value, isOrderChanged };
    });
  };

  const numberBodyTemplate = (_, { rowIndex }) => {
    //    return localState.groupRecords?.length - rowIndex;
    return rowIndex + 1;
  };

  const patientsBodyTemplate = (rowData, { rowIndex }) => <PatientLayout rowOptions={{ rowData, rowIndex }} />;

  // Need for alternative layout of data table
  // const feeCodesBodyTemplate = (rowData) => <FeeCodeTemplate rowOptions={{ rowData }} />;

  const dynamicColumns = () => {
    return columns?.map((i) => {
      return <Column key={i.header} frozen={i.frozen} alignFrozen={i.alignFrozen} style={i?.style || {}} header={i.header} body={i.body} />;
    });
  };

  const tableHeaderLayout = () => <GroupPickerTableHeader />;

  const uniqueGroupRecords = uniqBy(localState.groupRecords, (item) => item[inputs.patient.name][0]?.PatientGuid);
  const tableValue = isReferralStep ? uniqueGroupRecords : localState.groupRecords;

  return (
    <DataTable
      ref={refTable}
      className="py-5 group_picker_data_table"
      //value={tableValue}
      value={localState.groupRecords}
      reorderableRows
      onRowReorder={onRowReorder}
      selectionMode="single"
      selection={selectedRow}
      onSelectionChange={(e) => setSelectedRow(e.value)}
      // stripedRows
      scrollable
      scrollHeight="flex"
      responsiveLayout="scroll"
      emptyMessage={t('No_records_found')}
      header={tableHeaderLayout}
    >
      <Column frozen alignFrozen="left" rowReorder={!isReferralStep && !isCommentStep} style={{ minWidth: '40px', maxWidth: '40px' }} />

      <Column frozen alignFrozen="left" style={{ minWidth: '40px', maxWidth: '40px' }} header="No." body={numberBodyTemplate} />

      <Column
        frozen
        alignFrozen="left"
        style={{ minWidth: '270px', maxWidth: '270px' }}
        header={t('Patients')}
        className="font-bold"
        body={patientsBodyTemplate}
      />

      {dynamicColumns()}
    </DataTable>
  );
};

export default GroupPickerTable;
