import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { InputMask } from 'primereact/inputmask';
import Context from '../../../Context';

import { getPeriodFromDateInput, setClaimFilters } from '../../claims/actions/claims.action.creators';
import { findDiff } from '../../../Helper';

import classes from '../../_lego/LegoView.module.scss';
import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';

const DateInput = ({ initialValue, invalidFields, setFieldFilter, field, fieldType, filters }) => {
  const hint = 'YYYY/MM/DD';
  const dispatch = useDispatch();
  const { sortby } = useContext(Context);
  const [value, setValue] = useState(initialValue);
  const [placeholder, setPlaceholder] = useState(hint);

  //set initial value
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const isYearSame = moment(filters.PeriodTo).isSame(filters.PeriodFrom, 'year');
    const dateDiff = moment(filters.PeriodTo).diff(filters.PeriodFrom, 'days');

    if ((dateDiff === 364 || dateDiff === 365) && isYearSame) {
      const year = moment(filters.PeriodFrom).get('year');
      const newYear = `${year}/MM/DD`;
      dispatch(setClaimFilters(newYear, 'ServiceDate'));
    }
  }, [filters.Period, filters.PeriodFrom, filters.PeriodTo, dispatch]);

  //set placeholder
  useEffect(() => {
    if (filters && ((filters.PeriodFrom && filters.PeriodFrom !== '') || (filters.PeriodTo && filters.PeriodTo !== ''))) {
      setPlaceholder('Custom');
    } else {
      setPlaceholder(hint);
    }
  }, [filters]);

  // logic below to helpp with jumping focus - CMO-125
  const onSetFieldFilter = (e) => {
    //const suspects = ['YYYY', 'YYYYMM', 'YYYYMMDD', hint]; //accept partial date
    const suspects = ['YYYYMMDD', hint]; //accept only full date
    const value = findDiff(e.target.value, hint);
    const real_value = findDiff(hint, e.target.value);
    const filter_value = filters[field] ? filters[field].replace(/\//g, '') : '';
    setValue(e.target.value);
    if (value && suspects.indexOf(value) > -1) {
      //prevent onBlur API request
      if (real_value !== filter_value && e.target.value !== initialValue) {
        if (field === 'ServiceDate') {
          const period = getPeriodFromDateInput(real_value);
          const combined = { ...filters, ...period };
          debouncedRequest(e, field, fieldType, combined, sortby);
        } else {
          debouncedRequest(e, field, fieldType, filters, sortby);
        }
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRequest = useCallback(
    _.debounce((e, field, fieldType, filters, sortby) => {
      setFieldFilter(e, field, fieldType, filters, sortby);
    }, 1500),
    []
  );

  return (
    <InputMask
      className={cx(classes.filterByInput, value.length > 0 && invalidFields[field])}
      id={'flt_' + field}
      mask="9999/99/99"
      slotChar={hint}
      placeholder={placeholder}
      value={value}
      autoClear={false}
      onChange={(e) => onSetFieldFilter(e, field, fieldType)}
    />
  );
};

export default DateInput;
