import { useFormContext, useWatch } from 'react-hook-form';
import { commonInputs } from '../../../../config/commonInputsConfig';
import { inputs } from '../helpers/inputs';
import QuickPick from '../modules/WSBCReportCatalogs/QuickPick/QuickPick';

export const useCatalogTabs = () => {
  const { control, setLocalState } = useFormContext();
  const speciality = useWatch({ control, name: inputs.speciality.name });

  const catalogTabs = [
    {
      ...commonInputs.mostRecent,
      hideSearchForMobileView: true,
      tabLayout: () => <QuickPick />
    },

    {
      name: inputs.icd9.name,
      label: inputs.icd9.label,
      codeType: inputs.icd9.codeType,
      codeDescription: inputs.icd9.codeDescription,
      searchAllType: 'icd9_all',
      categoryType: 'icd9',
      requestParams: { codeType: commonInputs.icd9.codeType, speciality, getCodesFor: 'teleplan' }
    },

    {
      name: inputs.aoi.name,
      label: inputs.aoi.label,
      codeType: inputs.aoi.codeType,
      codeDescription: inputs.aoi.codeDescription,
      searchAllType: 'aoi_all',
      categoryType: 'aoi',
      requestParams: { codeType: commonInputs.aoi.codeType, speciality, getCodesFor: 'teleplan' }
    },

    {
      name: inputs.noi.name,
      label: inputs.noi.label,
      codeType: inputs.noi.codeType,
      codeDescription: inputs.noi.codeDescription,
      searchAllType: 'noi_all',
      categoryType: 'noi',
      requestParams: { codeType: commonInputs.noi.codeType, speciality, getCodesFor: 'teleplan' }
    }
  ];

  const onTabChange = (e) => {
    setLocalState((prevState) => ({
      ...prevState,
      catalogIndex: e.index,
      focusFieldParams: catalogTabs[e.index],
      searchValue: ''
    }));
  };

  return { catalogTabs, onTabChange };
};
