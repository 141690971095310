import { t } from '../../../../service/localization/i18n';

export const inputs = {
  firstName: {
    name: 'FirstName',
    label: t('First_Name')
  },
  lastName: {
    name: 'LastName',
    label: t('Last_Name')
  },
  practitionerNumber: {
    name: 'PractitionerNumber',
    label: t('Practitioner_Number')
  },
  specialization: {
    name: 'Specialization',
    label: t('Field')
  },
  phone: {
    name: 'Phone',
    label: t('Phone')
  },
  addressLine: {
    name: 'AddressLine',
    label: t('Street Address')
  },
  city: {
    name: 'City',
    label: t('City')
  },
  province: {
    name: 'Province',
    label: t('Province')
  },
  zip: {
    name: 'Zip',
    label: t('Postal_Code')
  }
};
