import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CashFlowCard from './CashFlowCard';
import CashFlowLineChart from './CashFlowLineChart';
import { getCashFlow, setCashFlowQuery } from '../../../cashFlow/actions/cashFlow.axtion.creators';
import moment from 'moment';

const Last7DaysCard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const query = useSelector((state) => state.cashFlow.queryParams?.query);

  const fetchData = async () => {
    setLoading(true);
    const responseData = await getCashFlow({ type: 2 });
    setLoading(false);
    setData(responseData);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    const periodFrom = moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD');
    const periodTo = moment().endOf('day').format('YYYY-MM-DD');
    dispatch(setCashFlowQuery({ ...query, PeriodFrom: periodFrom, PeriodTo: periodTo }));
  };

  return (
    <CashFlowCard title="Last 7 days" subtitle="Billed (CAD)" data={data} loading={loading} onClick={onClick}>
      <CashFlowLineChart data={data} />
    </CashFlowCard>
  );
};

export default Last7DaysCard;
