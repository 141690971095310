import React from 'react';
import BadgeWrap from '../../../Wraps/BadgesWraps/BadgeWrap';
import { t } from '../../../../service/localization/i18n';
import './LFP.scss';

const LFPBadge = () => {
  return <BadgeWrap className="lfp-badge">{t('LFP')}</BadgeWrap>;
};

export default LFPBadge;
