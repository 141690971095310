import { useSelector } from 'react-redux';
import { getCodesForCatalog } from '../../../service/Lookup';
import { searchForCodes } from '../../../modules/claims/actions/claims.action.creators';
import { recursion } from '../helpers/recursion';
import { chain } from 'lodash';
import { t } from '../../../service/localization/i18n';

export const useRequests = () => {
  const user = useSelector((state) => state.user.details);
  const { catalogCategories } = useSelector((state) => state.claims);

  const getCatalogCodesList = async ({ catalogsState, menuItem, catalog, pageSize, page, query = undefined }) => {
    if (catalogsState?.activeTab?.getCatalogCodes)
      return catalogsState?.activeTab?.getCatalogCodes({ catalogsState, menuItem, pageSize, page, query });

    const activeSubtab = catalogsState?.currentCategory?.tabs?.[catalogsState?.activeSubTabIndex];
    const category = catalog ? catalog : menuItem ? menuItem.value : catalogsState.categoryValue;
    const dcnguid = category?.includes('_recent') || !activeSubtab?.generic ? user.DCNGuid : false; // "generic" property in Tab object: true means no need to send DCNGuid, false - means need to send DCNGuid
    const currentPage = page || (catalogsState?.tableData?.page ? catalogsState?.tableData?.page + 1 : 1);
    const currentPageSize = pageSize || catalogsState?.tableData?.pageSize || 25;
    const currentQuery = query !== undefined ? query : catalogsState?.searchValue;
    const type = activeSubtab?.type;
    const speciality = catalogsState?.activeTab?.requestParams?.speciality;

    const response = await getCodesForCatalog({
      catalog: category,
      page: currentPage,
      pageSize: currentPageSize,
      query: currentQuery,
      dcnguid,
      type,
      speciality
    });

    if (response) {
      return response.data;
    } else {
      return {};
    }
  };

  const onSearchCodes = async ({ query, activeTab, catalogsState, setCatalogsState, slideMenuRef }) => {
    // If search value is empty
    if (!query.length) {
      const activeSubTabIndex = catalogsState.activeSubTabIndex;
      const currentCategory = catalogsState.currentCategory;
      const categories = currentCategory?.tabs[activeSubTabIndex]?.categories;
      const category = categories?.[0];

      setCatalogsState((prevState) => ({
        ...prevState,
        tableData: { codeList: [] },
        searchValue: '',
        emptyMessage: `${t('Loading')}...`,
        categoryValue: category.value,
        drillDown: [],
        trail: []
      }));

      if (activeTab?.tabLayout) {
        return setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList: activeTab?.defaultTableData || [] }
        }));
      }

      const results = await getCatalogCodesList({ catalogsState, menuItem: category, page: 1, query: '' });

      setCatalogsState((prevState) => ({
        ...prevState,
        emptyMessage: `${t('No_records_found')}`
      }));

      if (results) {
        recursion({
          arr: categories,
          catalogsState,
          setCatalogsState,
          slideMenuRef,
          query: '',
          page: 1,
          getCatalogCodesList
        });

        setCatalogsState((prevState) => ({
          ...prevState,
          menuModel: categories,
          tableData: results
        }));
      }
    }

    // If search value is NOT empty
    if (query.length) getCodes({ query, activeTab, catalogsState, setCatalogsState, slideMenuRef });
  };

  const getCodes = async ({ query, activeTab, catalogsState, setCatalogsState, slideMenuRef }) => {
    const { getCodesFor, codeType, speciality, practitionerGuid, catalogType } = activeTab.requestParams;
    const activeSubtab = catalogsState?.currentCategory?.tabs?.[catalogsState?.activeSubTabIndex];
    const isDoctorOfBC = activeSubtab?.value === 'dobc';
    const requestType = isDoctorOfBC && getCodesFor !== 'teleplan' ? 'teleplan' : getCodesFor;

    setCatalogsState((prevState) => ({
      ...prevState,
      searchLoader: true,
      tableData: { codeList: [] },
      emptyMessage: `${t('Loading')}...`
    }));

    const response = await searchForCodes({
      getCodesFor: requestType,
      practitionerGuid,
      catalogType,
      codeType,
      type: activeSubtab?.type,
      query,
      speciality,
      page: 1,
      pageSize: 500
    });

    if (response) {
      const codeList = response;
      const category = isDoctorOfBC ? 'feecodes' : activeTab?.codeType;
      const allCategoryLabel = t('All');
      const currentCategory = activeTab?.categoryType;
      const currentCategories = catalogCategories[currentCategory]?.tabs[catalogsState?.activeSubTabIndex]?.categories;

      // Action for custom catalogs data table
      if (activeTab?.tabLayout) {
        return setCatalogsState((prevState) => ({
          ...prevState,
          searchLoader: false,
          tableData: { codeList },
          emptyMessage: `${t('No_records_found')}`
        }));
      }

      // Action for rest catalogs data table
      if (codeList?.length) {
        const allCategories = {
          value: `${category}_all`,
          label: allCategoryLabel,
          end: true,
          recursion: false,
          callback: () => {
            getCodes({ query, activeTab, catalogsState, setCatalogsState, slideMenuRef });
            setCatalogsState((prevState) => ({ ...prevState, mobileContent: 'table' }));
          }
        };

        let menuItems = [allCategories];

        if (category === 'feecodes') {
          // For Fee Codes catalogs
          const filterMenuItems = (arr) => {
            arr?.forEach((i) => {
              if (i.end && codeList.some((x) => x.category === i.value) && i.value !== 'dobc_all') {
                menuItems = [...menuItems, i];
              } else {
                filterMenuItems(i.categories);
              }
            });
          };

          filterMenuItems(currentCategories);
        } else {
          // For NOT Fee Codes catalogs
          const grouped = chain(codeList).groupBy('category_label').value();
          const formatGrouped = Object.keys(grouped).map((i) => {
            return { label: i, value: grouped[i][0].category, end: true, command: () => '' };
          });

          menuItems = [allCategories, ...formatGrouped];
        }

        recursion({
          arr: menuItems,
          catalogsState,
          setCatalogsState,
          slideMenuRef,
          query,
          getCatalogCodesList
        });

        setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList },
          menuModel: menuItems,
          searchLoader: false,
          trail: [{ label: allCategoryLabel }]
        }));

        // Remove all active classes for menu item list
        document.querySelectorAll('.p-menuitem-highlight').forEach((i) => i.classList.remove('p-menuitem-highlight'));

        // Set active class for menu item
        slideMenuRef?.current?.slideMenuContent?.children[0]?.children[0]?.classList.add('p-menuitem-highlight');
      } else {
        const recentCategory = currentCategories?.find((i) => i.value.indexOf('_recent'));
        setCatalogsState((prevState) => ({
          ...prevState,
          tableData: { codeList: [] },
          menuModel: [recentCategory],
          searchLoader: false,
          emptyMessage: `${t('No_records_found')}`
        }));
      }
    }
  };

  return {
    onSearchCodes,
    getCatalogCodesList
  };
};
