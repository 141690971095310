import { store } from '../../../../../store';
import { inputs } from './inputs';
import { defaultGuid } from '../../../../config/defaultValuesConfig';

export const getDefaultValues = ({ initValues = {} } = {}) => {
  const clinic = store.getState().clinic.details;

  const commonValues = {
    [inputs.dcnguid.name]: clinic.DCNGuid,
    [inputs.memberGuid.name]: defaultGuid,
    [inputs.insurerGuid.name]: defaultGuid,

    [inputs.patient.name]: [], // Patient autocomplete input

    [inputs.insurerID.name]: 'NA',
    [inputs.insurerNameEN.name]: '',
    [inputs.policyNumber.name]: '',
    [inputs.memberID.name]: '',
    [inputs.benefitType.name]: '',
    [inputs.relationship.name]: 'NA',
    [inputs.insuredType.name]: 'NA',
    [inputs.birthOrder.name]: 0,

    [inputs.memberFirstName.name]: '',
    [inputs.memberLastName.name]: '',
    [inputs.memberMidName.name]: '',
    [inputs.memberGender.name]: '',
    [inputs.memberDOB.name]: null,

    [inputs.status.name]: 0,
    [inputs.isPrimary.name]: false,
    [inputs.validFrom.name]: null,
    [inputs.validTo.name]: null
  };

  return { ...commonValues, ...initValues };
};
