import React from 'react';
import PatientPoliciesProvider from './PatientPoliciesProvider/PatientPoliciesProvider';
import PatientPoliciesLayout from './modules/PatientPoliciesLayout';

// interface IPatientPolicies {
//   patientGuid?: string // Pass `patientGuid` or `patient` property. No need pass them both
//   patient?: { PatientGuid: string } // Patient details object. Pass `patientGuid` or `patient` property. No need pass them both
// }

const PatientPolicies = (props) => {
  return (
    <PatientPoliciesProvider {...props}>
      <PatientPoliciesLayout />
    </PatientPoliciesProvider>
  );
};

export default PatientPolicies;
