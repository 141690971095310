export const colorsCollection = [
  // {"blue-200":"#abc9fb"},{"green-200":"#a0e6ba"},{"yellow-200":"#f6de95"},{"cyan-200":"#94e0ed"},
  // {"pink-200":"#f7b0d3"},{"indigo-200":"#bcbdf9"},{"teal-200":"#9ae0d9"},{"orange-200":"#fcc39b"},
  // {"purple-200":"#dab6fc"},
  { 'blue-400': '#609af8' },
  { 'green-400': '#4cd07d' },
  { 'yellow-400': '#eec137' },
  { 'cyan-400': '#35c4dc' },
  { 'pink-400': '#f06bac' },
  { 'indigo-400': '#8183f4' },
  { 'teal-400': '#41c5b7' },
  { 'orange-400': '#fa8e42' },
  { 'purple-400': '#b975f9' },
  { 'blue-600': '#326fd1' },
  { 'green-600': '#1da750' },
  { 'yellow-600': '#c79807' },
  { 'cyan-600': '#059bb4' },
  { 'pink-600': '#c93d82' },
  { 'indigo-600': '#5457cd' },
  { 'teal-600': '#119c8d' },
  { 'orange-600': '#d46213' },
  { 'purple-600': '#8f48d2' },
  { 'blue-800': '#204887' },
  { 'green-800': '#136c34' },
  { 'yellow-800': '#816204' },
  { 'cyan-800': '#036475' },
  { 'pink-800': '#822854' },
  { 'indigo-800': '#363885' },
  { 'teal-800': '#0b655b' },
  { 'orange-800': '#893f0c' },
  { 'purple-800': '#5c2f88' }
];
