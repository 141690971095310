import { useState } from 'react';
import { usePayeeReportsContext } from './usePayeeReportsContext';
import { getReports } from '../../../actions/reports.action.creators';
import { groupBy, sortBy } from 'lodash';
import { formatData } from '../../helpers/formatChartData';

export const usePaidReport = () => {
  const { setReportsState } = usePayeeReportsContext();
  const [loading, setLoading] = useState(false);

  const getChartData = async () => {
    setLoading(true);
    // Get reports data and wait for the response
    const response = await getReports({ reportType: '2' });
    setLoading(false);

    if (response) {
      // Group the reports by practitioner
      const groupedReports = groupBy(response, 'Payee');

      const payeesWithReports = Object.entries(groupedReports).map(([payeeNumber, reports]) => ({
        PayeeNumber: payeeNumber,
        Reports: reports
      }));

      // Sort the practitioners with reports by last name
      const sortedPayeesWithReports = sortBy(payeesWithReports, 'Payee');
      // Find the first practitioner with reports
      const firstPayeeWithReports = sortedPayeesWithReports?.find((i) => i.Reports?.length);

      // Format the data from the first practitioner with reports
      const formattedData = formatData(firstPayeeWithReports?.Reports);

      // Update the state with the sorted practitioners with reports
      setReportsState((prevState) => ({
        ...prevState,
        paidReports: {
          ...prevState.paidReports,
          payeesWithReports: sortedPayeesWithReports,
          filteredByPayeeNumberValue: firstPayeeWithReports,
          paidReportsChartData: formattedData
        }
      }));
    }
  };

  const onPayeeNumberFilterChange = (value) => {
    setReportsState((prevState) => {
      return {
        ...prevState,
        paidReports: {
          ...prevState.paidReports,
          filteredByPayeeNumberValue: value,
          paidReportsChartData: formatData(value?.Reports)
        }
      };
    });
  };

  return {
    loading,
    getChartData,
    onPayeeNumberFilterChange
  };
};
