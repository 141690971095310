import React from 'react';
import { Controller, useWatch, useFormContext } from 'react-hook-form';

import { CodePicker } from '../../../../../../../components/Inputs';
import { InputWrapper } from '../../../../../../../components/Inputs';

import { inputs } from '../../../helpers/inputs';
import { useCodePickerActions } from '../../../hooks/useCodePickerActions';
import { icd9InputRequirements } from '../../../helpers/validationSchema';
import { elementIDs } from '../../../../../../config/elementIDsConfig';
import { icd9CodeMaxEntry } from '../../../../../../config/defaultValuesConfig';
import { useToggleCatalogs } from '../../../hooks/useToggleCatalogs';

const ICD9Code = () => {
  const name = inputs.icd9.name;
  const description = inputs.icd9.codeDescription;
  const { watch, errors, control, isMobile } = useFormContext();
  const { onFocus, onChange } = useCodePickerActions(inputs.icd9);
  const { toggleCatalogs } = useToggleCatalogs();
  const dxCodes = watch(inputs.icd9.codeType);
  const speciality = watch(inputs.speciality.name);
  const saveAsDraft = useWatch({ control, name: inputs.saveAsDraft.name });
  const required = icd9InputRequirements(saveAsDraft);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper
          name={name}
          label={inputs.icd9.label}
          required={required}
          errorMessage={errors[name]?.message}
          description={watch(description)}
          style={{ height: 'auto', paddingBottom: '10px' }}
        >
          <CodePicker
            name={name}
            ref={field.ref}
            id={elementIDs.icd9Autocomplete}
            inputId={elementIDs.icd9AutocompleteInput}
            isInvalid={!!errors[name]?.message}
            value={dxCodes}
            maxEntry={icd9CodeMaxEntry}
            showCatalogsButton={isMobile}
            searchParams={{ speciality, codeType: inputs.icd9.codeType, getCodesFor: 'teleplan' }}
            toggleCatalogs={() => toggleCatalogs('mobile', name)}
            onFocus={onFocus}
            onChange={onChange}
          />
        </InputWrapper>
      )}
    />
  );
};

export default ICD9Code;
